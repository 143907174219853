/* globals Enums */
(function () {
  'use strict';
  const PACKAGER_SERVICE_MAP = Object.freeze({
    allocationTree: 'allocationTreePackager',
    binTypeFcWeeklyPlan: 'weeklyPackager',
    create: 'createPackager',
    edit: 'editPackager',
    manualBacklog: 'manualBacklogPackager',
    network: 'networkPackager',
    networkPlanGenerator: 'networkPlanGeneratorPackager',
    networkPlanner: 'networkPlannerPackager',
    networkPlannerEdits: 'networkPlannerEditsPackager',
    networkPlannerSummary: 'networkPlannerSummaryPackager',
    networkPlannerViewer: 'networkPlannerViewerPackager',
    plan: 'planPackager',
    planViewer: 'planViewerPackager',
    plStFcWeeklyPlan: 'weeklyPackager',
    ratio: 'ratioPackager'
  });

  class PackagerFactoryService {
    static get $inject () {
      return ['$injector'];
    }

    constructor ($injector) {
      this.$injector = $injector;
    }

    get packagerType () {
      return PACKAGER_SERVICE_MAP;
    }

    collect (packagerServiceName, ...parameters) {
      if (!_.includes(_.values(PACKAGER_SERVICE_MAP), packagerServiceName)) {
        throw new Error(`PackagerFactory: "${packagerServiceName}" is an unknown packager service.`);
      }
      const packager = this.$injector.get(packagerServiceName);
      if (_.isNil(packager)) {
        throw new Error(`PackagerFactory: no service available for "${packagerServiceName}".`);
      }
      return packager.collect(...parameters);
    }

    getEditPackagerType (planType, editType) {
      if (_.has(PACKAGER_SERVICE_MAP, planType)) {
        return PACKAGER_SERVICE_MAP[planType];
      }
      if (editType === Enums.DataPackage.EditType.RATIO) {
        return PACKAGER_SERVICE_MAP.ratio;
      }
      return PACKAGER_SERVICE_MAP.edit;
    }
  }

  angular.module('application.services').service('packagerFactory', PackagerFactoryService);
})();
