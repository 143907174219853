/* globals Comparison, Configuration, Enumeration, Enums, Grain */
(function () {
  'use strict';

  class Granularities {
    constructor (...granularities) {
      if (!Array.isArray(granularities)) {
        granularities = [];
      }
      this.granularities = _.uniqBy(_.flatten(granularities).map((grain) => Grain.create(grain)), (grain) => grain.id);
    }

    static create (...granularities) {
      return new Granularities(...granularities);
    }

    add (fromIndex, instance) {
      if (instance instanceof Granularities) {
        if (!_.isFinite(fromIndex)) {
          fromIndex = this.size();
        }
        instance.granularities.forEach((grain, index) => this.addGrain(fromIndex + index, grain));
      }
      return this;
    }

    addGrain (index, grainToAdd) {
      if (!_.isFinite(index)) {
        index = this.size();
      }
      if (!this.hasGrain(grainToAdd)) {
        this.granularities.splice(index, 0, grainToAdd);
      }
      return this;
    }

    addImplicitGrains () {
      // Add any implicit grains (e.g., grouping grains) implied by the explicit grains
      const list = [];
      this.granularities.forEach((grain) => list.push(grain, ...this._filterInactiveGroupGrains(grain.getGroupGrains())));
      this.granularities = _.uniqBy(list, (grain) => grain.id);
      return this;
    }

    _filterInactiveGroupGrains (groupGrains) {
      return groupGrains.filter((grain) => Configuration.activation.isAvailable(`GroupBy_${grain.id}`));
    }

    // Aligns the filters with this set of grains
    // Modifies the filters object in-place
    align (filters) {
      const grains = _.map(this.values(), 'id');
      _.keys(filters).forEach((key) => {
        if (!_.includes(grains, key)) {
          delete filters[key];
        }
      });
      return filters || {};
    }

    clear () {
      this.granularities = [];
    }

    clone () {
      return Granularities.create(this.values());
    }

    equals (comparison) {
      return comparison instanceof Granularities && _.isEqual(this.granularities, comparison.granularities);
    }

    hasGrain (grainToCheck) {
      return _.some(this.granularities, (grain) => Comparison.areIdentityEqual(grain, grainToCheck));
    }

    isEmpty () {
      return _.isEmpty(this.granularities);
    }

    names (...filters) {
      return this.values(...filters).map((grain) => grain.name);
    }

    remove (instance) {
      if (instance instanceof Granularities) {
        instance.granularities.forEach((grain) => this.removeGrain(grain));
      }
      return this;
    }

    removeGrain (grainToRemove) {
      _.remove(this.granularities, (grain) => Comparison.areIdentityEqual(grain, grainToRemove));
      return this;
    }

    size () {
      return this.granularities.length;
    }

    values (...filters) {
      filters = filters.filter((value) => !_.isNil(value)); // Remove nil values as this gets called with undefined in normal operation
      if (_.isEmpty(filters)) {
        return this.granularities;
      }
      if (!filters.every((filter) => Enumeration.isMemberValue(Enums.GrainFilter, filter))) {
        throw new Error('Granularities: filters must be statically defined');
      }
      return _.filter(this.granularities, (grain) => _.every(filters, (filter) => grain[filter]()));
    }
  }

  // Add `add*Grain` and `remove*Grain` methods from the prototype.
  _.forOwn(Grain.known, function (value, key) {
    Granularities.prototype[_.camelCase(`add-${key}Grain`)] = function (index) {
      return this.addGrain(index, value);
    };
    Granularities.prototype[_.camelCase(`remove-${key}Grain`)] = function () {
      return this.removeGrain(value);
    };
  });

  window.Granularities = Object.freeze(Granularities);
})();
