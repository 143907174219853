/* globals DateUtils, Enums, Filename, Ready, XLSX */
'use strict';
(function () {
  class UsageMetricsController {
    static get $inject () {
      return ['alerts', 'overlay', 'portalStore', '$scope', 'trader'];
    }

    constructor (alerts, overlay, portalStore, $scope, trader) {
      this.alerts = alerts;
      this.overlay = overlay;
      this.portalStore = portalStore;
      this.$scope = $scope;
      this.trader = trader;
      this.$onInit();
    }

    // TODO: Refactor this in the getUsageMetricsData() method in Portal Store as this is
    //       the call pattern for the API and the controller should not be concerned with this.
    _fetchData () {
      const metricName = this.$scope.model.usageMetric.metricName.toLowerCase();
      const queryOptions = {
        endDate: this.$scope.model.endDate,
        filters: {},
        startDate: this.$scope.model.startDate
      };
      return this.portalStore.getUsageMetricsData(metricName, queryOptions).then((data) =>
        this.portalStore.getUsageMetricsData(metricName, queryOptions, { executionId: data.executionId })).then((response) =>
        this.portalStore.fetchS3Object(response.resultsLocation.bucket, response.resultsLocation.key));
    }

    $onInit () {
      this.$scope.model = {
        endDate: DateUtils.of().subtract(1, 'days').format(Enums.DateFormat.IsoDate),
        startDate: DateUtils.of().subtract(1, 'days').format(Enums.DateFormat.IsoDate),
        usageMetric: undefined,
        usageMetricsDataList: [],
        usageMetricsHeadersList: []
      };

      this.$scope.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.$scope.model.usageMetricsDataList),
        isNoSelection: () => _.isNil(this.$scope.model.usageMetric)
      });

      this.$scope.settings = {
        isValid: () => !_.isNil(this.$scope.model.usageMetric)
      };

      this.$scope.actions = {
        changeUsageMetric: (usageMetric) => {
          this.$scope.model.usageMetric = usageMetric;
          this.$scope.model.usageMetricsHeadersList = _.get(usageMetric, 'columns');
        },
        download: () => {
          this.overlay.show('Loading');
          this._fetchData().then((workbook) =>
            this.trader.download(
              workbook.Sheets[Enums.XlsxSheet.DEFAULT],
              Filename.create(this.$scope.model.usageMetric.metricName, this.$scope.model.startDate, this.$scope.model.endDate),
              this.trader.toExtensionType(Enums.DocumentFormat.CSV),
              { dateNF: Enums.DateFormat.XlsxDateNF }
            ))
            .catch((error) => {
              this.overlay.hide();
              this.alerts.error(error);
            })
            .finally(() => this.overlay.hide());
        },
        submit: () => {
          this.overlay.show('Loading');
          this._fetchData().then((workbook) =>
            this.$scope.model.usageMetricsDataList = XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.DEFAULT]))
            .catch((error) => {
              this.overlay.hide();
              this.alerts.error(error);
            })
            .finally(() => this.overlay.hide());
        }
      };
    }
  }

  angular.module('application.controllers').controller('UsageMetricsController', UsageMetricsController);
})();
