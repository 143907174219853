/* globals AbstractSourceBasedModel, Granularities */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'displayName',
    'id',
    {
      source: 'editDetails.groupName',
      target: 'groupName'
    },
    {
      source: 'editDetails.types',
      target: 'editTypes'
    },
    {
      deserialize: (grains) => Granularities.create(grains),
      source: 'grainComponents',
      target: 'grains'
    }
  ]);

  class FlowDefinition extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    static create (source) {
      return Object.freeze(new FlowDefinition(source));
    }

    get name () {
      return this.displayName;
    }

    isEditable (editType) {
      return _.isString(editType) ? _.includes(this.editTypes, editType) : !_.isEmpty(this.editTypes);
    }
  }

  window.FlowDefinition = Object.freeze(FlowDefinition);
})();
