/* globals AbstractSourceBasedModelModal, Configuration */
(function () {
  'use strict';

  /**
   * Component for the product line's modals.
   * @name application.components.product-line-modal
   * @example
   * <product-line-modal/>
   */
  class ProductLineModalController extends AbstractSourceBasedModelModal {
    static get $inject () {
      return ['$authentication'];
    }

    constructor ($authentication) {
      super('productLine');
      this.$authentication = $authentication;
    }

    getDeleteConfirmationMessage () {
      return `Are you sure you want to delete PL <strong>${this.productLine.name}</strong>? This action cannot be undone.`;
    }

    isDeleteAllowed () {
      return this.$authentication.hasAdministratorAccess() && this.isEdit() && this.productLine.isActive && this.productLine.isEditable;
    }

    deleteProductLine () {
      this.modalInstance.close({ mode: 'delete', productLine: this.productLine });
    }

    saveProductLine () {
      // Write tags-input collections back to model in sorted order and de-duplicated
      this.setProperty(
        'definition',
        {
          gl: {
            [this.definitionFilter.gl.selected.key]: _.uniq(_.sortBy(_.sop.convert(this.definitionFilter.gl.values).toFiniteNumbers()))
          },
          iog: {
            [this.definitionFilter.iog.selected.key]: _.uniq(_.sortBy(_.sop.convert(this.definitionFilter.iog.values).toFiniteNumbers()))
          }
        }
      );
      this.modalInstance.close({ mode: 'save', productLine: this.productLine });
    }

    tagAdded (property, tag) {
      this[property].push(tag.text);
      this.setProperty(property, this[property]);
    }

    tagRemoved (property, tag) {
      _.pull(this[property], tag.text);
      this.setProperty(property, this[property]);
    }

    $onInit () {
      super.$onInit();

      this.definitionFilter = {
        gl: {
          values: []
        },
        iog: {
          values: []
        },
        options: [
          { displayName: 'Includes', key: 'include' },
          { displayName: 'Excludes', key: 'exclude' }
        ]
      };
      this.definitionFilter.gl.selected = this.definitionFilter.options[0];
      this.definitionFilter.iog.selected = this.definitionFilter.options[0];

      if (_.isEmpty(this.productLine.scopeCode)) {
        // Assign the current scope to a product line that has an empty scopeCode
        this.setProperty('scopeCode', Configuration.scopes.current().code);
      }

      // Collect the GL and IOG list values from the product line
      this.definitionFilter.gl.values = _.sop.convert(this.productLine.definition.gl.include).toStrings();
      this.definitionFilter.iog.values = _.sop.convert(this.productLine.definition.iog.include).toStrings();

      if (!_.isEmpty(this.productLine.definition.gl.exclude)) {
        this.definitionFilter.gl.selected = this.definitionFilter.options[1];
        this.definitionFilter.gl.values = _.sop.convert(this.productLine.definition.gl.exclude).toStrings();
      }
      if (!_.isEmpty(this.productLine.definition.iog.exclude)) {
        this.definitionFilter.iog.selected = this.definitionFilter.options[1];
        this.definitionFilter.iog.values = _.sop.convert(this.productLine.definition.iog.exclude).toStrings();
      }

      // Collect the Plan Types from the product line
      this.planTypes = _.clone(this.productLine.planTypes);

      // Setup the Product Line Owner from the product line
      this.productLineOwner = _.clone(this.productLine.productLineOwner);

      // Setup tags input collection
      this.tags = _.clone(this.productLine.tags);
    }
  }

  angular.module('application.components')
    .component('productLineModal', {
      bindings: {
        modalInstance: '<',
        resolve: '<'
      },
      controller: ProductLineModalController,
      controllerAs: 'modalCtrl',
      templateUrl: 'templates/components/product-line-modal.component.html'
    });
})();
