/* globals Configuration */
(function () {
  'use strict';
  const METRIC_FAMILY_GROUP_COLSPAN = 2;

  const getColumn = (value, colspan = 1, rowspan = 1) => ({ colspan, rowspan, value });

  const getColumnsForRowIndex = (rowIndex, metricFamilyGroups, metricsByGroup, metricsCountByChart) => {
    const columns = [];
    metricFamilyGroups.forEach((group) => {
      const metrics = metricsByGroup[group];
      const metric = metrics[rowIndex];
      if (_.isNil(metric)) {
        return;
      }
      if (!_.has(metricsCountByChart, metric.chartId)) {
        const metricsCount = metrics.filter((metricByGroup) => metricByGroup.chartId === metric.chartId).length;
        columns.push(getColumn(_.startCase(metric.chartId), 1, metricsCount));
        metricsCountByChart[metric.chartId] = metricsCount;
      }
      columns.push(getColumn(`${_.startCase(metric.id)} (${metric.type}s)`));
    });
    return columns;
  };

  const getMetricsForGroup = (dashboardConfig, group) => {
    const metrics = [];
    dashboardConfig.metricsData[group].metricFamilies.forEach((metricFamily) => metrics.push(...metricFamily.metrics));
    return _.sortBy(metrics, [(metric) => dashboardConfig.metricsData[group].chartIds.indexOf(metric.chartId), 'order']);
  };

  class DashboardConfigurationModalController {
    $onInit () {
      this.scope = _.defaultTo(this.resolve.scope, Configuration.scopes.current().portalCode);
      const dashboardConfig = Configuration.metricsDashboard.forScope(this.scope);
      const metricFamilyGroups = Configuration.metricsDashboard.metricFamilyGroups.filter((group) => Object.keys(dashboardConfig.metricsData).includes(group));
      this.tableData = {
        bodyRows: [],
        headerRows: [
          metricFamilyGroups.map((group) => getColumn(group, METRIC_FAMILY_GROUP_COLSPAN)),
          _.range(metricFamilyGroups.length * METRIC_FAMILY_GROUP_COLSPAN).map((index) => index % 2 === 0 ? getColumn('Chart') : getColumn('Metric'))
        ]
      };
      let maxMetricsInGroup = 0;
      const metricsByGroup = {};
      metricFamilyGroups.forEach((group) => {
        metricsByGroup[group] = getMetricsForGroup(dashboardConfig, group);
        maxMetricsInGroup = metricsByGroup[group].length > maxMetricsInGroup ? metricsByGroup[group].length : maxMetricsInGroup;
      });
      const metricsCountByChart = {};
      for (let index = 0; index < maxMetricsInGroup; index++) {
        this.tableData.bodyRows.push(getColumnsForRowIndex(index, metricFamilyGroups, metricsByGroup, metricsCountByChart));
      }
    }
  }
  angular.module('application.components')
    .component('dashboardConfigurationModal', {
      bindings: {
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with the following properties:
         *   scope - string (optional) scope for which dashboard configuration should be displayed.
         */
        resolve: '<'
      },
      controller: DashboardConfigurationModalController,
      templateUrl: 'templates/components/dashboard-configuration-modal.component.html'
    });
})();
