/* globals AbstractDataViewController, AllocationTreeEdit, Comparison, Configuration, Enums, Filename, Granularities, Identity, PlanMetadata */
(function () {
  'use strict';
  const ALLOCATION_TREE_TYPES = Configuration.allocationTreeTypes;
  const EDITS_SUCCESS_MESSAGE = 'Allocation Tree updated successfully. Changes will be available in Plan Editor for the planning cycle starting';
  const EDITS_FAILURE_MESSAGE = 'Unable to update Allocation Tree';
  const NO_EDITS_ERROR_MESSAGE = 'No changes were made. Please select the appropriate checkboxes representing Add/Remove actions and then click "Save Changes".';

  class AllocationTreeController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', 'allocationTree', '$authentication', 'overlay', 'packagerFactory', 'planConfiguration', '$q', '$scope', 'trader', 'transformerFactory'];
    }

    constructor (alerts, allocationTree, $authentication, overlay, packagerFactory, planConfiguration, $q, $scope, trader, transformerFactory) {
      super($scope);
      this.alerts = alerts;
      this.allocationTree = allocationTree;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.planConfiguration = planConfiguration;
      this.$q = $q;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.$onInit();
    }

    _fetchData () {
      return this.packagerFactory.collect(
        this.packagerFactory.packagerType.allocationTree,
        this.$scope.model.metrics.selection,
        _.pickBy(this.$scope.model.filters, (selections, filter) => this._isGrainFilterVisible(filter)),
        this.$scope.model.allocationTreeType.selected.treeType);
    }

    _getVisibleFilters () {
      return _.pickBy(this.$scope.model.filters, (selections, filter) => this._isGrainFilterVisible(filter));
    }

    _isGrainFilterVisible (filter) {
      if (_.isNil(this.$scope.model.granularity.allGrains) || _.isNil(this.$scope.model.metrics.selection)) {
        return false;
      }
      const isOneOfTheGrains = _.some(this.$scope.model.granularity.allGrains.values(), (grain) => Comparison.areIdentityEqual(grain, filter));
      const isTransferFlow = Configuration.flowNeedles.transferLikeFlowsRegEx.test(_.get(this.$scope.model.metrics.selection, 'id'));
      return isOneOfTheGrains && (filter !== 'secondaryNode' || isTransferFlow);
    }

    _isInvalidFilterSelection () {
      return _.isEmpty(this.$scope.model.filters) || _.some(this.$scope.model.filters, (selections, filter) => this._isGrainFilterVisible(filter) && (_.isEmpty(selections) || _.every(selections, _.isEmpty)));
    }

    _updateAllocationTree (allocationTreeEdit) {
      this.allocationTree.update(allocationTreeEdit)
        .then((response) => {
          if (!_.isNil(response.treeMetaData)) {
            this.alerts.success(`${EDITS_SUCCESS_MESSAGE} ${response.treeMetaData.localDate}.`);
          } else {
            this.alerts.danger({ details: response.failures, message: EDITS_FAILURE_MESSAGE });
          }
          this.$scope.allocationTreeData = undefined;
        })
        .catch((error) => this.alerts.danger({ details: error, message: `${EDITS_FAILURE_MESSAGE}: ${_.get(error, 'data.message')}` }))
        .finally(() => this.overlay.hide());
    }

    download () {
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Exporting');
      this.$q.resolve(this._fetchData().ready())
        .then((grid) => this.transformerFactory.toDocument(
          this.transformerFactory.transformerType.allocationTree,
          Enums.DocumentFormat.XLSX,
          [grid]
        ))
        .then((workbook) => this.trader.download(
          workbook,
          Filename.create(`AllocationTree_${Configuration.scopes.current().code}`),
          this.trader.toExtensionType(Enums.DocumentFormat.XLSX)
        ))
        .finally(() => this.overlay.hide());
    }

    isSettingsValid () {
      return !_.isNil(this.$scope.model.allocationTreeType.selected) &&
        !_.isNil(this.$scope.model.metrics.selection) &&
        !this._isInvalidFilterSelection();
    }

    submit () {
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Fetching allocation tree');
      this.$q.resolve(this._fetchData().ready())
        .then((data) => {
          // TODO: As of now, Allocation Tree Editor supports edits one flow at a time. As a result, "data" received here from the Allocation Tree Packager is a single package, not a list of packages.
          // Pushing this single data package into "$scope.data" list adds unnecessary index [0] / head reading complexities. That is why "data" is being assigned to "$scope.allocationTreeData" and not pushed into "$scope.data".
          // If / when Allocation Tree editing is supported for multiple flows, "data" should be a list of data packages and addData() from the abstract should be used here.
          this.$scope.allocationTreeData = data;
          this.collapseSettings(true);
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      const activeTreeTypes = _.filter(
        ALLOCATION_TREE_TYPES,
        (treeType) => Configuration.activation.isAvailable(treeType.id)
      );
      super.$onInit(
        ['METRIC_SELECTION', 'FILTER_SELECTION'],
        {
          'model.allocationTreeType.list': () => activeTreeTypes,
          'model.allocationTreeType.selected': () => _.head(activeTreeTypes),
          'model.configuration': () => ({}),
          'model.granularity.allGrains': () => Granularities.create(),
          'model.metrics': () => ({})
        }
      );

      this.$scope.methods.changeAllocationTreeType = (selection) => {
        if (_.isNil(selection)) {
          // If no Allocation Tree Type is available, do nothing.
          return;
        }
        this.$scope.model.allocationTreeType.selected = selection;
        this.$scope.model.metrics.selection = undefined;
        this.$scope.model.filters = {};
        // Setting this.$scope.model.configuration = { ... } instead of this.$scope.model.configuration.type = ' ... ' is intentional.
        // The latter does not change the reference of "this.$scope.model.configuration" and as a result does not trigger $onChanges on item selectors.
        this.$scope.model.configuration = PlanMetadata.create({ type: selection.planType });
        this.planConfiguration.planDefinition().get(selection.planType).then((data) => this.$scope.model.granularity.allGrains = data.planGranularity);
      };
      this.$scope.methods.changeAllocationTreeType(this.$scope.model.allocationTreeType.selected);

      this.$scope.methods.changeFilterSelection = (list, filter) => this.$scope.model.filters[filter] = list;

      this.$scope.methods.changeFlowSelection = (flow) => this.$scope.model.metrics.selection = flow;

      this.$scope.methods.getAllocationTreeTypeDisplayName = (allocationTreeType) => allocationTreeType.name;

      this.$scope.methods.isValidMetricSelection = () => !_.isNil(this.$scope.model.metrics.selection);

      this.$scope.methods.isGrainFilterVisible = (filter) => this._isGrainFilterVisible(filter);

      this.$scope.methods.onFileSelected = (file) => {
        this.overlay.show('Uploading');
        this.trader.upload(file).then((workbook) => {
          const allocationTreeEdit = this.transformerFactory.toPackages(this.transformerFactory.transformerType.allocationTree, workbook);
          if (_.isEmpty(allocationTreeEdit)) {
            this.overlay.hide();
            return;
          }
          this._updateAllocationTree(allocationTreeEdit);
        });
      };

      this.$scope.methods.saveOnlineEdits = () => {
        if (_.every(this.$scope.allocationTreeData.records, (record) => !record.add && !record.remove)) {
          this.alerts.danger(NO_EDITS_ERROR_MESSAGE);
          return;
        }
        const allocationTreeEdit = AllocationTreeEdit.create({
          edits: _.filter(this.$scope.allocationTreeData.records, (record) => record.add || record.remove),
          flow: Identity.of(this.$scope.model.metrics.selection),
          scope: Configuration.scopes.current().code,
          type: this.$scope.model.allocationTreeType.selected.treeType,
          updatedBy: this.$authentication.profile().alias
        });
        this.overlay.show('Saving changes');
        this._updateAllocationTree(allocationTreeEdit);
      };
    }
  }

  angular.module('application.controllers').controller('AllocationTreeController', AllocationTreeController);
})();
