/* globals Configuration, DateUtils, Enumeration, Enums, Summary */
(function () {
  'use strict';
  const REFRESH_ICON = Enumeration
    .create('STATIC', 'SPINNER')
    .asExactValue('fa-refresh', 'fa-refresh fa-spin');

  class PipelineServiceWorkflowsController {
    static get $inject () {
      return ['$scope', '$routeParams'];
    }

    constructor ($scope, $routeParams) {
      this.$scope = $scope;
      this.$routeParams = $routeParams;
      this.$onInit();
    }

    _constructSummary () {
      this.$scope.model.summary.clear();
      this.$scope.model.summary.addLeft('Completed', this.$scope.model.completedCount, 'success');
      this.$scope.model.summary.addMiddle('No Executions', this.$scope.model.noExecutionsCount, 'warning');
      this.$scope.model.summary.addRight('Delayed', this.$scope.model.delayedCount, 'danger');
      this.$scope.model.summary.constructList();
    }

    _refreshComplete () {
      this.$scope.settings.isRefreshing = false;
      this.$scope.model.refreshIcon = REFRESH_ICON.STATIC;
    }

    _refreshInProgress () {
      this.$scope.settings.isRefreshing = true;
      this.$scope.model.refreshIcon = REFRESH_ICON.SPINNER;
    }

    $onInit () {
      const TZ = Configuration.scopes.current().timeZone;

      this.$scope.model = {
        // Auto refresh rate: 5 minutes.
        autoRefreshTimeout: 300000,
        completedCount: 0,
        datasetDate: DateUtils.of(TZ).subtract(1, Enums.TimeUnit.DAY).format(Enums.DateFormat.IsoDate),
        datasetMaxDate: DateUtils.of(TZ).format(Enums.DateFormat.IsoDate),
        delayedCount: 0,
        filters: {},
        noExecutionsCount: 0,
        refreshIcon: REFRESH_ICON.SPINNER,
        summary: Summary.create()
      };

      this.$scope.actions = {
        autoRefreshWorkflowsList: () => {
          this.$scope.$broadcast(Enums.BroadcastChannel.AUTO_REFRESH);
          this._refreshInProgress();
        },
        changeDatasetDate: (date) => {
          if (date === this.$scope.model.datasetDate) {
            return;
          }
          this.$scope.model.datasetDate = date;
          this.$scope.model.completedCount = 0;
          this.$scope.model.delayedCount = 0;
          this.$scope.model.noExecutionsCount = 0;
          this.$scope.model.summary.clear();
          this.$scope.$broadcast(Enums.BroadcastChannel.RESET_COUNTDOWN);
          this._refreshInProgress();
        },
        onWorkflowsRefresh: (completedCount, noExecutionsCount, delayedCount) => {
          this.$scope.model.completedCount = completedCount;
          this.$scope.model.noExecutionsCount = noExecutionsCount;
          this.$scope.model.delayedCount = delayedCount;
          this._constructSummary();
          this._refreshComplete();
        },
        refreshWorkflowsList: () => {
          this.$scope.model.summary.clear();
          this.$scope.$broadcast(Enums.BroadcastChannel.AUTO_REFRESH);
          this.$scope.$broadcast(Enums.BroadcastChannel.RESET_COUNTDOWN);
          this._refreshInProgress();
        }
      };
      this.$scope.settings = {
        // Skip defaulting actuals delay check filter when route params are being used.
        actualsDelayCheck: _.isEmpty(this.$routeParams),
        autoRefresh: false,
        isRefreshing: true
      };

      if (!_.isEmpty(this.$routeParams.datasetDate)) {
        this.$scope.model.datasetDate = this.$routeParams.datasetDate;
      }

      ['dataset', 'namespace', 'scope', 'status'].forEach((property) => {
        if (!_.isEmpty(this.$routeParams[property])) {
          this.$scope.model.filters[property] = this.$routeParams[property];
        }
      });
    }
  }
  angular.module('application.controllers').controller('PipelineServiceWorkflowsController', PipelineServiceWorkflowsController);
})();
