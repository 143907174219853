/* globals AbstractDataViewController, Comparison, Configuration, DataPackageList, DateUtils, Enumeration, Enums, Filename, Grain, Granularities, Mapper, PlanMetadata, Ready */
(function () {
  'use strict';

  /**
   * TT: https://tt.amazon.com/0420915201
   * Edits to Prefinal draft can take a few minutes to go through and planners tend to multitask while that happens. When an edit succeeds,
   * a success alert message is displayed and the default auto-close for success messages is 30 seconds. Planners can miss that success
   * message as they are multitasking, which causes a delay in their workflow as they then have to check in the Executions modal the status.
   * Disabling the auto-close by setting it to 0 (zero) so the planners never miss the success message.
   */
  const DISABLE_ALERT_AUTO_CLOSE = 0;

  const EXECUTION_ACTION_KEYS = Enumeration.create('PUBLISH', 'TRIGGER_CAPACITY', 'TRIGGER_CAPACITY_OFFLINE', 'TRIGGER_FC_GASTANK').asStringValue();

  const INVALID_EDITS_ERROR_MESSAGE = 'The system is unable to save your edits due to some invalid edits. Please correct any invalid edits and then click "Save to Prefinal".';
  const INVALID_FILE_FORMAT = 'The system is unable to interpret the file you uploaded. Please address all error messages and upload again.';
  const NO_EDITS_ERROR_MESSAGE = 'The system is unable to save your edits due to no edits being entered. Please make an edit and then click "Save to Prefinal".';
  const TRIGGER_CAPACITY_SUCCESS_MESSAGE = 'Successfully submitted a trigger capacity request for Prefinal draft. You will receive an email notification from Capacity Flow Forecaster when it completes.';

  const generateTriggerCapacityOfflineSuccessMessage = (emailList) => `Successfully submitted a trigger capacity (offline) request for Prefinal draft. An email notification will be sent to <a href="https://email-list.amazon.com/email-list/expand-list/${emailList}" target="_blank" rel="noopener">${emailList}@amazon.com</a> from Capacity Flow Forecaster when it completes.`;
  const generateTriggerFCGasTankSuccessMessage = (planDisplayName) => `Successfully submitted a trigger FC GasTank request for ${planDisplayName}.`;

  class PlanEditorController extends AbstractDataViewController {
    static get $inject () {
      return [
        'alerts',
        '$authentication',
        'orchestrator',
        'overlay',
        'packagerFactory',
        'planConfiguration',
        'planStore',
        '$q',
        '$scope',
        'share',
        'trader',
        'transformerFactory',
        '$uibModal',
        'usageMetrics',
        'view'
      ];
    }

    constructor (
      alerts,
      $authentication,
      orchestrator,
      overlay,
      packagerFactory,
      planConfiguration,
      planStore,
      $q,
      $scope,
      share,
      trader,
      transformerFactory,
      $uibModal,
      usageMetrics,
      view
    ) {
      super($scope, share, view);
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.orchestrator = orchestrator;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.planConfiguration = planConfiguration;
      this.planStore = planStore;
      this.$q = $q;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.$uibModal = $uibModal;
      this.usageMetrics = usageMetrics;
      this.$onInit();
    }

    _alignFilters () {
      if (!_.isNil(this.$scope.model.granularity.allGrains)) {
        this.$scope.model.granularity.allGrains.align(this.$scope.model.filters);
      }
    }

    _alignGrains () {
      // Filter out any grains that are not directly being edited with the exception of the
      // Metric and Draft grains, which are used for display purposes.
      if (!_.isNil(this.$scope.model.granularity.grains)) {
        this.$scope.model.granularity.grains.clone().values().forEach((grain) => {
          if (!this.$scope.model.groupBy.hasGrain(grain) && !grain.isMetricGrain() && !grain.equals(Grain.known.draft)) {
            this.$scope.model.granularity.grains.removeGrain(grain);
          }
        });
      }
    }

    _calculateDateRange (planMetadata, ratioMetadata) {
      // 1. Weekly edits can only be performed for the current week onward
      let startDate = DateUtils.toSunday();
      if (this._isFcWeeklyPlanSelected(planMetadata)) {
        // 2. FC Weekly plans are restricted to no editing of the current week
        startDate = DateUtils.fromOffset(DateUtils.toSunday(), 1, Enums.TimeUnit.WEEK);
      } else if (planMetadata.isDailyPlan()) {
        // 3. Daily plans can only be edited for the current day onwards
        // See: https://sim.amazon.com/issues/SOP-6321 for requirement change of Ratio edits that unified this across edit types
        startDate = DateUtils.format(Enums.DateFormat.IsoDate);
      }
      // 4. Use the above start date or the plan date, whichever is greater
      // See: https://issues.amazon.com/SOP-8463 for requirement change to allow future plan editing
      startDate = DateUtils.max(startDate, planMetadata.date);

      // If ratioMetadata provides an endDate, use that. If not, calculate endDate via the plan horizon.
      const endDate = _.get(ratioMetadata, 'endDate', DateUtils.fromOffset(planMetadata.date, this.$scope.model.granularity.horizon - 1, Enums.TimeUnit.DAY));
      return DateUtils.expansion(startDate, endDate, this.$scope.model.granularity.time);
    }

    _collectWorkbook (isCreate) {
      if (isCreate) {
        return this.packagerFactory.collect(this.packagerFactory.packagerType.create, this.$scope.model.create.planType);
      }
      return this._fetchLatestMetadata()
        .then((dateRange) => this.$q.all(this.$scope.model.metrics.list.map((metric) => this._fetchGrids(metric, this.$scope.model, dateRange, Enums.UserAction.DOWNLOAD)))
          .then((dataPackages) => this.$q.all(_.flatten(dataPackages).map((dataPackage) => dataPackage.ready()))));
    }

    _constructQuerySummary () {
      this.querySummary.clear();
      if (this.$scope.viewName) {
        this.querySummary.add('View', this.$scope.viewName);
        delete this.$scope.viewName;
      }
      this.querySummary.add('Prefinal Plan', this.$scope.model.primary.plan.displayName(Enums.Plan.DisplayFormat.FULL));
      this.querySummary.add('Edit Type', `Editing as: <strong>${_.capitalize(this.$scope.model.editType)}</strong>`, 'dataset', 'edit');
      this.querySummary.constructList();
    }

    _downloadWorkbook (format, grids, editType) {
      return this.transformerFactory.toDocument(
        this.transformerFactory.transformerType.edit,
        format,
        grids,
        [
          {
            key: 'granularity',
            serialize: (granularity, source) => granularity.grains.names(source.downloadGrainFilter)
          },
          { key: 'dates' }
        ],
        [
          {
            key: 'granularity',
            serialize: (granularity, source, row) => granularity.grains.values(source.downloadGrainFilter).map((grain) => row.granularity[grain.id]),
            source: 'pkg'
          },
          {
            key: 'values',
            source: 'row'
          }
        ],
        {
          edit: true
        }
      ).then((workbook) => this.trader.download(
        workbook,
        Filename.create().forPlan(_.head(grids).plan).add(editType),
        this.trader.toExtensionType(format)
      ));
    }

    _fetchGrids (metric, configuration, dateRange, action) {
      this.$scope.model.granularity.grains.addDraftGrain();
      const packagerType = this.packagerFactory.getEditPackagerType(configuration.primary.plan.type, this.$scope.model.editType);
      return this.packagerFactory.collect(packagerType, dateRange, metric, configuration, action);
    }

    _fetchLatestMetadata () {
      return this.planStore.planMetadata(this.$scope.model.primary.plan)
        .then((planMetadata) => {
          Object.assign(this.$scope.model.primary.plan, planMetadata);
          // If the user wants to do ratio edits then the ratio metadata needs to be fetched and the date range determined
          // based on the combination of the plan metadata and the ratio metadata: https://issues.amazon.com/issues/SOP-7919
          if (this.$scope.model.editType === Enums.DataPackage.EditType.RATIO) {
            return this.planStore.ratioMetadata(planMetadata)
              .then((ratioMetadata) => this._calculateDateRange(planMetadata, ratioMetadata));
          }
          return this.$q.resolve(this._calculateDateRange(planMetadata));
        });
    }

    _generateScopeMismatchErrorMessage (attemptedScope) {
      return `The plan you are uploading is for "${attemptedScope}", but the currently selected scope is "${Configuration.scopes.current().code}". Please switch to "${attemptedScope}" and refresh your browser if you want to upload this plan.`;
    }

    _isFcWeeklyPlanSelected (plan = this.$scope.model.primary.plan) {
      return !_.isNil(plan) && plan.isFcWeeklyPlan();
    }

    _logPlanEdit (wrappedPromiseFn, plan = this.$scope.model.primary.plan) {
      const planMetric = this.usageMetrics.createUsageMetric('planEditor')
        .forMethod(Enums.UsageMetrics.save)
        .withOptionalString('plan', plan.type)
        .withOptionalString('planVersion', plan.version);

      return this.usageMetrics.timeAndExecute('planEditor', Enums.UsageMetrics.save, () =>
        wrappedPromiseFn()
          .then(planMetric.withCounter(Enums.UsageMetrics.success))
          .catch((error) => {
            planMetric.withCounter(Enums.UsageMetrics.failure);
            throw error;
          })
          .finally(() => planMetric.publish())
      );
    }

    _upsertPlan (pkgs) {
      return DataPackageList.areCreate(pkgs) ? this.orchestrator.create(pkgs) : this.orchestrator.edit(pkgs);
    }

    download (format, packageType) {
      this._alignGrains();
      this._alignFilters();
      this.overlay.show('Fetching metrics');
      const isCreate = packageType === Enums.DataPackage.PackageType.CREATE;
      this._collectWorkbook(isCreate)
        .then((grids) => this._downloadWorkbook(format, _.flatten(grids), isCreate ? Enums.DataPackage.EditType.UNIT : this.$scope.model.editType))
        .catch((error) => this.alerts.error(error, Enums.AlertImportance.INTERRUPT))
        .finally(() => this.overlay.hide());
    }

    isSettingsValid () {
      if (_.isNil(this.$scope.model.primary.plan) || _.isEmpty(this.$scope.model.metrics.list) || _.isNil(this.$scope.model.editType)) {
        return false;
      }

      if (this._isFcWeeklyPlanSelected() && (_.isEmpty(this.$scope.model.groupBy) || _.isEmpty(this.$scope.model.allocations))) {
        return false;
      }

      return this.$scope.model.primary.plan.isValid() && _.isString(this.$scope.model.granularity.time) && _.isString(this.$scope.model.granularity.plan);
    }

    resetState () {
      delete this.$scope.model.allocations;
      delete this.$scope.model.editType;
      this.$scope.model.filters = {};
      this.$scope.model.granularity = {};
      this.$scope.model.gridGrouping = Granularities.create();
      this.$scope.model.groupBy = Granularities.create();
      delete this.$scope.model.groupByFilters;
      delete this.$scope.model.groupByIdentifier;
      this.$scope.model.metrics.list = [];
      delete this.$scope.model.primary.plan;
    }

    setStep (step) {
      if (
        (step === this.$scope.STEPS.FLOW_SELECTION && !this.$scope.methods.isValidPlanSelection()) ||
        (step === this.$scope.STEPS.FILTER_SELECTION && !this.$scope.methods.isValidMetricSelection())
      ) {
        return;
      }
      super.setStep(step);
    }

    submit () {
      this.clearData();
      this._alignGrains();
      this._alignFilters();
      this.overlay.show('Fetching latest plan');
      this._fetchLatestMetadata()
        .then((dateRange) => {
          this._constructQuerySummary();
          this.overlay.message('Fetching records');
          return this.$q.all(this.$scope.model.metrics.list.map((metric) => this._fetchGrids(metric, this.$scope.model, dateRange, Enums.UserAction.VIEW)));
        })
        .then((data) => {
          this.addData(..._.flatten(data));
          this.collapseSettings(true);
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      super.$onInit(
        ['INITIAL_SELECTION', 'FLOW_SELECTION', 'FILTER_SELECTION'],
        {
          'model.create': () => ({}),
          'model.granularity': () => ({}),
          'model.gridGrouping': () => Granularities.create(),
          'model.groupBy': () => Granularities.create(),
          'model.groupByFilters': () => undefined,
          'model.groupByIdentifier': () => undefined,
          'model.metrics': () => ({}),
          'model.primary': () => ({}),
          'settings.create': () => ({})
        }
      );
      this.$scope.methods.changeAllocationTreeView = (selection) => {
        if (!this.isSettingsLocked()) {
          // Reset allocations if the settings are not locked
          delete this.$scope.model.allocations;
        }
        if (_.isNil(selection)) {
          this.$scope.model.groupBy = Granularities.create();
          this.$scope.model.gridGrouping = Granularities.create();
          delete this.$scope.model.groupByFilters;
          delete this.$scope.model.groupByIdentifier;
          return;
        }
        this.$scope.model.gridGrouping = selection.gridGrouping;
        this.$scope.model.groupBy = selection.groupBy;
        this.$scope.model.groupByFilters = selection.groupByFilters;
        this.$scope.model.groupByIdentifier = selection.id;
      };

      this.$scope.methods.changeEditType = (editType) => {
        this.$scope.model.editType = editType;
        this.$scope.model.metrics.list.map((metric) => {
          metric.dataType = this.$scope.model.editType === Enums.DataPackage.EditType.RATIO ? Enums.DataPackage.MetricDataType.DECIMAL : Enums.DataPackage.MetricDataType.INTEGER;
          return metric;
        });
      };

      this.$scope.methods.changeFlowSelection = (metricList) => {
        this.$scope.model.metrics.list = metricList;
        this.$scope.model.groupBy = _.isEmpty(metricList) ? Granularities.create() : _.head(metricList).grains.clone();

        // Clear the allocation item selections and "How to View" selection since the
        // Allocation Tree Item Selector clears selections when the flow changes.
        // This keeps behavior the same across different flows.
        delete this.$scope.model.groupByIdentifier;
        delete this.$scope.model.allocations;
      };

      this.$scope.methods.changePlan = (plan) => {
        if (!this.isSettingsLocked()) {
          // Reset the state if the settings are not locked
          this.clearData();
          this.resetState();
        }
        this.$scope.model.primary.plan = plan;
        this.planConfiguration.planDefinition().get(plan.type, plan.date).then((data) => {
          this.$scope.model.granularity.allGrains = data.planGranularity;
          this.$scope.model.granularity.grains = data.planGranularity;
          this.$scope.model.granularity.horizon = data.planHorizonInDays;
          this.$scope.model.granularity.time = data.timeGranularityName;
          this.$scope.model.granularity.plan = data.planGranularityName;
        });

        // uib-tabset does not support dynamic tabs and thus the 'active' tab does not get updated
        // when a plan changes and the granularities are changed. Wait for updated grains to arrive and then
        // set the active tab.
        Ready.once(
          this.$scope,
          () => !_.isNil(this.$scope.model.granularity.allGrains),
          () => this.$scope.activeTab = this._isFcWeeklyPlanSelected() ? 'allocations' : _.minBy(this.$scope.model.granularity.allGrains.granularities, 'filterIndex').id
        );
      };

      this.$scope.methods.isAllocationSelectionVisible = () => !_.isNil(this.$scope.model.groupByIdentifier);

      this.$scope.methods.isGrainFilterVisible = (filter) => {
        if (!_.isString(filter) || _.isNil(this.$scope.model.granularity.allGrains) || this._isFcWeeklyPlanSelected()) {
          return false;
        }
        return this.$scope.model.granularity.allGrains.values().some((grain) => Comparison.areIdentityEqual(grain, filter));
      };

      this.$scope.methods.isFcWeeklyPlanSelected = this._isFcWeeklyPlanSelected.bind(this);

      this.$scope.methods.isUploadValid = () => !_.isNil(this.$scope.workbook);

      this.$scope.methods.isValidMetricSelection = () => this.$scope.methods.isValidPlanSelection() && !_.isEmpty(this.$scope.model.metrics.list);

      this.$scope.methods.isValidPlanSelection = () => !_.isNil(this.$scope.model.primary.plan) && this.$scope.model.primary.plan.isValid();

      this.$scope.methods.onFileSelected = (file) => {
        this.clearData();
        this.trader.upload(file).then((workbook) => {
          const dataPackages = this.transformerFactory.toPackages(this.transformerFactory.transformerType.edit, workbook);
          if (_.isEmpty(dataPackages)) {
            return;
          }

          const head = _.head(dataPackages);
          if (Configuration.scopes.current().code !== head.plan.scope) {
            this.alerts.danger(this._generateScopeMismatchErrorMessage(head.plan.scope), Enums.AlertImportance.INTERRUPT);
            return;
          }

          this.resetState();
          Mapper.apply(
            head,
            this.$scope.model,
            [
              'filters',
              'granularity',
              {
                source: 'metrics',
                target: 'metrics.list'
              },
              {
                deserialize: this.$scope.methods.changeEditType,
                key: 'editType'
              },
              {
                source: 'plan',
                target: 'primary.plan'
              }
            ]
          );
          this._constructQuerySummary();
          this.addData(...dataPackages);
          this.collapseSettings(true);
        }).catch((error) => this.alerts.danger({ details: error, message: INVALID_FILE_FORMAT }, Enums.AlertImportance.INTERRUPT));
      };

      this.$scope.methods.openExecutions = () => {
        const modalInstance = this.$uibModal.open({
          component: 'planModificationModal',
          resolve: {
            modalData: {
              plan: this.$scope.model.primary.plan,
              uuid: this.$scope.model.uuid
            }
          },
          size: Enums.ModalSize.EXTRA_LARGE
        });

        modalInstance.result.then((result) => {
          switch (_.get(result, 'action')) {
            case EXECUTION_ACTION_KEYS.PUBLISH:
              this.overlay.show('Publishing Plan');
              this.usageMetrics.timeAndExecute('planEditor', Enums.UsageMetrics.publish, () => result.promise
                .then(() => {
                  this.$scope.methods.openExecutions();
                  this.$scope.methods.submit();
                })
                .catch((error) => {
                  this.alerts.error(error, Enums.AlertImportance.INTERRUPT);
                  throw error;
                })
                .finally(() => this.overlay.hide())
              );
              break;
            case EXECUTION_ACTION_KEYS.TRIGGER_CAPACITY:
              result.promise
                .then(() => this.alerts.success(TRIGGER_CAPACITY_SUCCESS_MESSAGE))
                .catch((error) => this.alerts.error(error, Enums.AlertImportance.INTERRUPT));
              break;
            case EXECUTION_ACTION_KEYS.TRIGGER_CAPACITY_OFFLINE:
              result.promise
                .then((response) => this.alerts.success(generateTriggerCapacityOfflineSuccessMessage(response)))
                .catch((error) => this.alerts.error(error, Enums.AlertImportance.INTERRUPT));
              break;
            case EXECUTION_ACTION_KEYS.TRIGGER_FC_GASTANK:
              result.promise
                .then(() => this.alerts.success(generateTriggerFCGasTankSuccessMessage(this.$scope.model.primary.plan.displayName())))
                .catch((error) => this.alerts.error(error, Enums.AlertImportance.INTERRUPT));
              break;
          }
        }).catch(_.noop);
      };

      this.$scope.methods.save = (dataPackages) => {
        if (!DataPackageList.containsEdits(dataPackages)) {
          this.alerts.danger(NO_EDITS_ERROR_MESSAGE, Enums.AlertImportance.INTERRUPT);
          return;
        }
        if (!DataPackageList.containsValidEdits(dataPackages)) {
          this.alerts.danger(INVALID_EDITS_ERROR_MESSAGE, Enums.AlertImportance.INTERRUPT);
          return;
        }

        this.overlay.show('Saving edits');

        this._logPlanEdit(() => this._upsertPlan(dataPackages)
          .then((execution) => this.orchestrator.pollModification(this.$scope.model.primary.plan, _.head(execution).uuid))
          .then(() => {
            this.alerts.success({
              autoClose: DISABLE_ALERT_AUTO_CLOSE,
              message: 'Successfully saved edits to Prefinal draft.'
            });
            this.submit();
          })
          .catch((error) => {
            this.alerts.error(error, Enums.AlertImportance.INTERRUPT);
            throw error;
          })
          .finally(() => this.overlay.hide())
        );
      };

      this.$scope.settings.create.isSelectionValid = () => !_.isNil(this.$scope.model.create.planType);

      this.registerShare(
        [
          'model.allocations',
          'model.editType',
          'model.filters',
          'model.groupByIdentifier',
          'model.metrics.list',
          {
            deserialize: (value) => PlanMetadata.create(value),
            key: 'model.primary.plan',
            serialize: (value) => _.pick(value.source, ['draft', 'scenario', 'subScenario', 'type'])
          }
        ]
      );

      this.registerView(
        [
          {
            // Reset state to ensure it is in an expected configuration before applying the user view
            action: () => {
              this.clearData();
              this.resetState();
            },
            persist: false
          },
          'model.allocations',
          'model.editType',
          'model.filters',
          'model.groupByIdentifier',
          'model.metrics.list',
          {
            deserialize: (value) => PlanMetadata.create(value),
            key: 'model.primary.plan',
            serialize: (value) => _.pick(value.source, ['draft', 'scenario', 'subScenario', 'type'])
          },
          {
            persist: false,
            source: 'name',
            target: 'viewName'
          }
        ]
      );
    }
  }
  angular.module('application.controllers').controller('PlanEditorController', PlanEditorController);
})();
