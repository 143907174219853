/* globals AbstractSourceBasedModel, Enums */
(function () {
  'use strict';

  const SHORTCUT_IDENTITY_PROPERTIES = Object.freeze([
    'baseUrl',
    'dataKey',
    'scope',
    'type'
  ]);
  const PROPERTIES = Object.freeze(
    SHORTCUT_IDENTITY_PROPERTIES.concat([
      'dataLabel',
      'name'
    ])
  );

  class Shortcut extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
    }

    /*
     * As of now, all shortcuts are of type USER_VIEW.
     * If the shortcuts concept is extended to ShareableUrls via Bookmarks, shareable shortcuts can be created
     * by calling Shortcut.createByProperties() with type Enums.ShortcutType.SHAREABLE_URL.
     */
    static createByProperties (scope, baseUrl, dataKey, type = Enums.ShortcutType.USER_VIEW) {
      return new Shortcut({ baseUrl, dataKey, scope, type });
    }

    /*
     * As of now, all shortcuts are of type USER_VIEW.
     * If the shortcuts concept is extended to ShareableUrls via Bookmarks, shareable shortcuts can be created
     * by calling Shortcut.createBySource() with a source containing 'type' property set to Enums.ShortcutType.SHAREABLE_URL.
     */
    static createBySource (source) {
      return new Shortcut(_.defaults(source, { type: Enums.ShortcutType.USER_VIEW }));
    }

    static isComplete (shortcut) {
      return _.every(PROPERTIES, (property) => _.has(shortcut, property) && !_.isEmpty(shortcut[property]));
    }

    static isValid (shortcut) {
      return _.every(SHORTCUT_IDENTITY_PROPERTIES, (property) => _.has(shortcut, property) && !_.isEmpty(shortcut[property]));
    }

    equals (compare) {
      return !_.isNil(compare) && _.isEqual(this.identity(), compare.identity());
    }

    identity () {
      return _.join([this.scope, this.baseUrl, this.dataKey, this.type], '_');
    }

    isComplete () {
      return Shortcut.isComplete(this);
    }

    isValid () {
      return Shortcut.isValid(this);
    }

    setName (name) {
      this.assign({ name });
    }
  }

  window.Shortcut = Object.freeze(Shortcut);
})();
