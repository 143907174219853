/* globals PlanMetadata */
(function () {
  'use strict';
  const BASE_PLAN_EXPORT_NAME = 'S&OP Plan';
  const DEFAULT_EXTENSION = '';
  const DEFAULT_PART_JOINER = ' - ';

  class Filename {
    constructor (...parts) {
      this.extension = DEFAULT_EXTENSION;
      this.joiner = DEFAULT_PART_JOINER;
      this.parts = [];
      this.add(..._.flatten(parts));
    }

    static create (...parts) {
      return new Filename(...parts);
    }

    static from (path) {
      // This is based on the assumption that the last part of the path contains the file name and file extension.
      // This is agreed on with Model Marketplace backend service.
      return this.create(path.substring(path.lastIndexOf('/') + 1, path.lastIndexOf('.')))
        .withExtension(path.substring(path.lastIndexOf('.') + 1));
    }

    add (...parts) {
      const filteredParts = _.filter(parts, (part) => _.isString(part) && !_.isEmpty(part));
      if (!_.isEmpty(filteredParts)) {
        this.parts.push(...filteredParts);
      }
      return this;
    }

    forPlan (planMetadata) {
      if (!(planMetadata instanceof PlanMetadata)) {
        throw new Error('Filename: planMetadata must be an instance of PlanMetadata');
      }
      this.parts.length = 0;
      this.add(
        `${BASE_PLAN_EXPORT_NAME} (${planMetadata.scope})`,
        `${planMetadata.type} (${planMetadata.draft})`,
        planMetadata.date,
        `${planMetadata.scenario} (${planMetadata.subScenario})`,
        _.isNil(planMetadata.version) ? undefined : `v${planMetadata.version}`);
      return this;
    }

    joinBy (joiner) {
      if (!_.isString(joiner)) {
        throw new Error('Filename: joiner must be a string');
      }
      this.joiner = joiner;
      return this;
    }

    withExtension (extension) {
      if (!_.isString(extension)) {
        throw new Error('Filename: extension must be a string');
      }
      this.extension = extension;
      return this;
    }

    toString () {
      let filename = this.parts.join(this.joiner);
      if (!_.startsWith(this.extension, '.') && !_.isEmpty(this.extension)) {
        filename += '.';
      }
      filename += this.extension.toLowerCase();
      return _.sop.removeIllegalFileSystemChars(filename);
    }
  }

  window.Filename = Object.freeze(Filename);
})();
