/* globals Configuration, Mapper */
(function () {
  'use strict';
  const manager = Mapper.create();
  let shareableData = {};

  class ShareService {
    static get $inject () {
      return ['$location', 'portalStore', '$q', '$window'];
    }

    constructor ($location, portalStore, $q, $window) {
      this.$location = $location;
      this.portalStore = portalStore;
      this.$q = $q;
      this.$window = $window;
    }

    constructScopedUrl (target = '/') {
      return `${this.$window.location.origin}/#!/scope/${Configuration.scopes.current().portalCode}${target}`;
    }

    create () {
      const configuration = manager.collect(this.$location.path());
      if (!_.isEmpty(configuration)) {
        configuration.baseUrl = this.$location.url();
        return this.portalStore.createShareToken(configuration).then((uuid) => this.constructScopedUrl(`/share?st=${uuid}`));
      }
      return this.$q.resolve(this.constructScopedUrl(this.$location.url()));
    }

    register (root, properties, permitCreateCallback, postApplyCallback) {
      const key = this.$location.path();
      manager.register(key, root, properties, permitCreateCallback, postApplyCallback);
      if (!_.isEmpty(shareableData)) {
        manager.apply(key, shareableData);
        shareableData = {};
      }
    }

    setShareableData (data) {
      shareableData = data;
    }
  }

  angular.module('application.services').service('share', ShareService);
})();
