/* globals DateUtils, Enums, AbstractSourceBasedModel */
(function () {
  'use strict';

  const PLANNING = Object.freeze({
    [Enums.WarehousePlanType.CAPACITY_PLAN]: 'Capacity',
    [Enums.WarehousePlanType.DAILY_PLAN]: 'Daily',
    [Enums.WarehousePlanType.NETWORK_PLAN]: 'Network',
    [Enums.WarehousePlanType.WEEKLY_PLAN]: 'Weekly'
  });

  const PROPERTIES = Object.freeze([
    'automatedUpdate',
    'createdDate',
    'enabledPlans',
    'groups',
    'inboundArcs',
    'nodeRegion',
    'operationalEndDate',
    'operationalStartDate',
    'organizationalUnitCode',
    'outboundArcs',
    'planTypes',
    'scope',
    'timeZone',
    'updatedDate',
    'version',
    'warehouseId'
  ]);

  const PROPERTY_MATCH_ALIASES = Object.freeze({
    id: 'warehouseId',
    name: 'warehouseId',
    org: 'scope'
  });

  const TEMPLATE = Object.freeze({
    automatedUpdate: false,
    groups: [],
    inboundArcs: [],
    organizationalUnitCode: '',
    outboundArcs: [],
    planTypes: [],
    scope: '',
    timeZone: '',
    warehouseId: ''
  });

  class Warehouse extends AbstractSourceBasedModel {
    constructor (source) {
      super(_.defaults(source, TEMPLATE), PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.setEnabledPlans();
      this.unsetOperationalCategory();
    }

    get isActive () {
      return DateUtils.isInRange(this.operationalStartDate, this.operationalEndDate, undefined, Enums.TimeUnit.DAY);
    }

    get isVirtual () {
      return false;
    }

    alias (property) {
      return _.get(PROPERTY_MATCH_ALIASES, property, property);
    }

    isExisting () {
      return !_.isNil(this.version);
    }

    isValid () {
      return !_.isEmpty(this.warehouseId)
        && !_.isEmpty(this.organizationalUnitCode)
        && !_.isEmpty(this.scope)
        && !_.isEmpty(this.timeZone);
    }

    isEnabledForPlanning (planType) {
      return _.includes(this.planTypes, planType);
    }

    setEnabledPlans () {
      this.enabledPlans = _.isEmpty(this.planTypes) ? ['-'] : _.map(this.planTypes, (planType) => PLANNING[planType]).sort();
    }

    // As Warehouse Registry upsert API is in backward compatable mode,
    // We have to unset the Operational Category field specifically in order to not use the older version of the API.
    // This part can be removed in the near future.
    unsetOperationalCategory () {
      this.remove(['operationalCategory']);
    }
  }

  window.Warehouse = Object.freeze(Warehouse);
})();
