(function () {
  'use strict';
  const BROADCAST_PROFILE_UPDATE = 'profile-update';

  class UserProfileService {
    static get $inject () {
      return ['$authentication', 'portalStore', '$q', '$rootScope', 'shortcut'];
    }

    constructor ($authentication, portalStore, $q, $rootScope, shortcut) {
      this.$authentication = $authentication;
      this.portalStore = portalStore;
      this.$q = $q;
      this.$rootScope = $rootScope;
      this.shortcut = shortcut;
      this.profileUpdateInProgress = false;
      this.userProfile = {};
    }

    _isAuthenticatedUserAlias (options) {
      const alias = _.get(options, 'alias');
      // Return true when options has no lookup alias or the alias matches the authenticated user alias.
      return _.isNil(alias) || alias === this.$authentication.profile().alias;
    }

    fetch (options) {
      if (!_.isEmpty(this.userProfile) && this._isAuthenticatedUserAlias(options)) {
        // Return the cached user profile if its non-empty and fetch request is being made for the authenticated user.
        return this.$q.resolve(this.userProfile);
      }
      return this.portalStore.getUserProfile(options)
        .then((profile) => {
          if (this._isAuthenticatedUserAlias(options)) {
            // Update the cached user profile only if fetch request is being made for the authenticated user.
            this.userProfile = profile;
          }
          return profile;
        });
    }

    getMostVisitedScope (options) {
      return this.fetch(options).then((profile) => profile.getMostVisitedScope());
    }

    getRecentLogins (options) {
      return this.fetch(options).then((profile) => profile.isEmpty() ? {} : {
        mostRecentLogin: profile.getMostRecentLogin(),
        mostRecentLoginsByScope: profile.getMostRecentLoginsByScope(),
        previousLogin: profile.getPreviousLogin()
      });
    }

    getShortcuts (options) {
      return this.fetch(options)
        .then((profile) => profile.getShortcuts().map((shortcut) => {
          const route = this.shortcut.getRouteByPath(shortcut.baseUrl);
          shortcut.routeIcon = route.icon;
          shortcut.routeName = route.name;
          return shortcut;
        }));
    }

    hasShortcut (shortcut) {
      return this.userProfile.hasShortcut(shortcut);
    }

    isProfileUpdateInProgress () {
      return this.profileUpdateInProgress;
    }

    isShortcutsListFull () {
      return this.userProfile.isShortcutsListFull();
    }

    update () {
      const updateOptions = {
        mostRecentLogin: this.userProfile.mostRecentLogin
      };
      return this.portalStore.updateUserProfile(this.userProfile.source, updateOptions)
        .then(() => this.$rootScope.$broadcast(BROADCAST_PROFILE_UPDATE))
        .finally(() => this.profileUpdateInProgress = false);
    }

    updateMostRecentLogin () {
      this.profileUpdateInProgress = true;
      return this.fetch()
        .then((profile) => {
          profile.setMostRecentLogin();
          return this.update();
        });
    }

    updateShortcuts (shortcuts) {
      this.profileUpdateInProgress = true;
      return this.fetch()
        .then((profile) => {
          profile.setShortcuts(shortcuts);
          return this.update();
        });
    }

    /*
     * Adds listener for user profile update.
     */
    $onProfileUpdate (listener) {
      this.$rootScope.$on(BROADCAST_PROFILE_UPDATE, listener);
    }
  }

  angular.module('application.services').service('userProfile', UserProfileService);
})();
