/* globals AbstractSourceBasedModel, Configuration, DateUtils, Enums, Shortcut */
(function () {
  'use strict';
  const MAX_SHORTCUTS = 10;

  const PROPERTIES = Object.freeze([
    'shortcuts',
    'mostRecentLogin',
    'mostRecentLoginsByScope',
    'previousLogin',
    'scopeVisits'
  ]);

  function getMostVisitedItem (source, property) {
    const data = _.get(source, property);
    if (_.isNil(data)) {
      return;
    }
    return _.maxBy(Object.keys(data), (key) => data[key]);
  }

  function updateItemVisits (source, path) {
    const currentItemVisits = _.get(source, path, 0);
    _.set(source, path, currentItemVisits + 1);
  }

  class UserProfile extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
    }

    static get maxShortcutsPermitted () {
      return MAX_SHORTCUTS;
    }

    getMostRecentLogin (scope) {
      const mostRecentLogin = _.isNil(scope) ? _.get(this.source, ['mostRecentLogin']) : _.get(this.source, ['mostRecentLoginsByScope', scope]);
      return DateUtils.format(mostRecentLogin, Enums.DateFormat.GmtOffsetDateTime);
    }

    getMostRecentLoginsByScope () {
      return _.orderBy(_.map(_.get(this.source, 'mostRecentLoginsByScope'), (value, key) => ({
        epoch: value,
        login: DateUtils.format(value, Enums.DateFormat.GmtOffsetDateTime),
        scope: key
      })), ['epoch'], ['desc']);
    }

    getMostVisitedScope () {
      return getMostVisitedItem(this.source, 'scopeVisits');
    }

    getPreviousLogin () {
      return DateUtils.format(_.get(this.source, 'previousLogin'), Enums.DateFormat.GmtOffsetDateTime);
    }

    getShortcuts () {
      return _.map(_.get(this.source, 'shortcuts'), (shortcut) => Shortcut.createBySource(shortcut));
    }

    hasShortcut (shortcut) {
      return this.getShortcuts().find((shortcutInProfile) => shortcutInProfile.equals(shortcut));
    }

    isEmpty () {
      return _.isEmpty(this.source);
    }

    isShortcutsListFull () {
      return _.size(_.get(this.source, 'shortcuts')) >= MAX_SHORTCUTS;
    }

    setMostRecentLogin (scope = Configuration.scopes.current().portalCode, loginEpoch = DateUtils.epoch()) {
      _.set(this.source, 'previousLogin', _.get(this.source, 'mostRecentLogin'));
      _.set(this.source, 'mostRecentLogin', loginEpoch);
      _.set(this.source, ['mostRecentLoginsByScope', scope], loginEpoch);
      this.updateScopeVisits(scope);
      this.applySource();
    }

    setShortcuts (shortcuts) {
      this.assign({ shortcuts: _.map(shortcuts, 'source') });
    }

    updateScopeVisits (scope = Configuration.scopes.current().portalCode) {
      updateItemVisits(this.source, ['scopeVisits', scope]);
    }
  }

  window.UserProfile = Object.freeze(UserProfile);
})();
