/* globals Configuration, Enumeration, Enums, KatalMetrics, Validate */
(function () {
  'use strict';
  const EMPTY_ROUTE = '/';
  const SLASH_CHARACTER = '/';
  const NO_VALUE_SENTINEL = '-';
  // The expression can be played around with here: https://regex101.com/r/oVnAMp/4
  const VALID_METRIC_STRING_REGEX = /^[\w\d._/-]+$/;
  const METRIC_TYPE = Enumeration
    .create('COUNTER', 'STRING', 'TIMER')
    .asExactValue('counters', 'strings', 'timers');

  function sanitizeServiceName (serviceName) {
    if (Validate.isBlank(serviceName)) {
      throw new Error(`UsageMetric: serviceName must be a non-blank string: "${serviceName}"`);
    }

    if (serviceName === EMPTY_ROUTE) {
      throw new Error('UsageMetric: serviceName cannot be the empty route ("/")');
    }

    if (_.isNil(serviceName.match(VALID_METRIC_STRING_REGEX))) {
      throw new Error(`UsageMetric: serviceName contains invalid characters: "${serviceName}"`);
    }

    // Remove any leading slash from the service name
    if (serviceName.startsWith(SLASH_CHARACTER)) {
      serviceName = serviceName.slice(1);
    }
    // Remove any trailing slash from the service name
    if (serviceName.endsWith(SLASH_CHARACTER)) {
      serviceName = serviceName.slice(0, -1);
    }

    // The serviceName field of Katal Metrics cannot contain a slash. Replacing slashes with '.'
    // The expression can be played around with here: https://regex101.com/r/oVnAMp/3
    return _.replace(serviceName, /\//g, '.');
  }


  class UsageMetric {
    constructor (katalDriver, serviceName, errorHandler, relatedMetricKey, relatedMetricValue) {
      if (_.isNil(katalDriver)) {
        throw new Error(`UsageMetric: katalDriver must not be nil: "${katalDriver}"`);
      }

      if (!_.isString(serviceName) || Validate.isBlank(serviceName)) {
        throw new Error(`UsageMetric: serviceName must be a non-blank string: "${serviceName}"`);
      }

      if (!_.isFunction(errorHandler)) {
        throw new Error(`UsageMetric: errorHandler must be a function: "${errorHandler}"`);
      }
      this.katalDriver = katalDriver;
      this.serviceName = serviceName;
      this.errorHandler = errorHandler;
      this.relatedMetricKey = relatedMetricKey;
      this.relatedMetricValue = relatedMetricValue;
      this.metricsToPublish = {
        counters: [],
        strings: [],
        timers: []
      };

      let metricsContext = new KatalMetrics.Context.Builder()
        .withSite(`SandopPortal${_.capitalize(Configuration.environment.origin().designator)}`)
        .withServiceName(sanitizeServiceName(serviceName));

      // If the related metric granularity has been provided, define it: https://katal.corp.amazon.com/#/metrics/dev-config.
      if (!_.isNil(relatedMetricKey) && !_.isNil(relatedMetricValue)) {
        metricsContext = metricsContext.withRelatedMetrics(new KatalMetrics.Metric.String(relatedMetricKey, relatedMetricValue));
      }
      this.metricsPublisher = new KatalMetrics.Publisher(katalDriver, errorHandler.bind(this), metricsContext.build());
    }

    _with (type, name, value, isMonitor) {
      if (!_.isString(name) || Validate.isBlank(name)) {
        throw new Error(`UsageMetric: name must be a non-blank string: "${name}"`);
      }
      // String data, by the nature of being a string, is not avaiable in PMET and thus the
      // option to send the metric to PMET (monitor) is not avaiable.
      this.metricsToPublish[type].push( type !== METRIC_TYPE.STRING ? { isMonitor, name, value } : { name, value });
      return this;
    }

    static create (katalDriver, serviceName, errorHandler, relatedMetricKey, relatedMetricValue) {
      return new UsageMetric(katalDriver, serviceName, errorHandler, relatedMetricKey, relatedMetricValue);
    }

    publishingEnabled () {
      return !Configuration.environment.current().isUnknown();
    }

    forMethod (methodName) {
      if (!_.isString(methodName) || Validate.isBlank(methodName)) {
        throw new Error(`UsageMetric: methodName must be a non-blank string: "${methodName}"`);
      }
      this.actionMetricsPublisher = this.metricsPublisher.newChildActionPublisherForMethod(methodName);
      return this;
    }

    publish () {
      if (_.isNil(this.actionMetricsPublisher)) {
        throw new Error(`UsageMetric: Must have a defined method name: "${this.actionMetricsPublisher}"`);
      }

      if (_.isEmpty(this.metricsToPublish.counters) && _.isEmpty(this.metricsToPublish.strings) && _.isEmpty(this.metricsToPublish.timers)) {
        throw new Error(`UsageMetric: Must add metrics before attempting to publish: "counters: ${this.metricsToPublish.strings.length}, strings: ${this.metricsToPublish.strings.length}, timers: ${this.metricsToPublish.strings.length}"`);
      }

      if (this.publishingEnabled()) {
        // Flush counters list
        this.metricsToPublish.counters.forEach((data) => this.actionMetricsPublisher[data.isMonitor ? 'publishCounterMonitor' : 'publishCounter'](data.name, data.value));

        // Flush strings list - truncate string prior to publishing via https://katal.corp.amazon.com/#/metrics/schema -> Validation
        this.metricsToPublish.strings.forEach((data) => this.actionMetricsPublisher.publishStringTruncate(data.name, data.value));

        // Flush timers list
        this.metricsToPublish.timers.forEach((data) => this.actionMetricsPublisher[data.isMonitor ? 'publishTimerMonitor' : 'publishTimer'](data.name, data.value));
      }

      this.metricsToPublish.counters.length = 0;
      this.metricsToPublish.strings.length = 0;
      this.metricsToPublish.timers.length = 0;
      return this;
    }

    withCounter (name, value = 1, isMonitor = true) {
      if (!_.isFinite(value)) {
        throw new Error(`UsageMetric: counter value must be finite for: "${name}"`);
      }
      return this._with(METRIC_TYPE.COUNTER, name, value, isMonitor);
    }

    withString (name, value) {
      if (Validate.isBlank(value)) {
        throw new Error(`UsageMetric: value must be a non-blank string for: "${name}"`);
      }
      return this._with(METRIC_TYPE.STRING, name, value);
    }

    // Used when missing string should not interrupt metric processing, instead return the sentinel
    withOptionalString (name, value) {
      if (Validate.isBlank(value)) {
        return this._with(METRIC_TYPE.STRING, name, NO_VALUE_SENTINEL);
      }
      return this._with(METRIC_TYPE.STRING, name, value);
    }

    withTimer (name, value, isMonitor = true) {
      if (!_.isFinite(value)) {
        throw new Error(`UsageMetric: timer value must be finite for: "${name}"`);
      }
      return this._with(METRIC_TYPE.TIMER, name, value, isMonitor);
    }

    withPortalScope (scope) {
      scope = _.get(Configuration.scopes.find(scope), 'portalCode', Configuration.scopes.current().portalCode);
      return this.withString(Enums.UsageMetrics.scope, scope);
    }

    withScope (scope = Configuration.scopes.current()) {
      scope = Configuration.scopes.find(scope);
      if (_.isNil(scope)) {
        throw new Error(`UsageMetric: undefined scope for service: "${this.serviceName}"`);
      }
      return this.withString(Enums.UsageMetrics.scope, scope.code);
    }

    withAlias (alias = NO_VALUE_SENTINEL) {
      if (Validate.isBlank(alias)) {
        throw new Error(`UsageMetric: undefined alias for service: "${this.serviceName}"`);
      }
      return this.withString(Enums.UsageMetrics.alias, alias);
    }
  }

  window.UsageMetric = Object.freeze(UsageMetric);
})();
