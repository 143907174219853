/* globals AbstractElementComponent */
(function () {
  'use strict';
  class UsageMetricToViewSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', 'portalStore'];
    }

    constructor ($element, portalStore) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.usageMetricsList),
        isLoadingFn: () => this.loading,
        isNoSelectionFn: () => _.isNil(this.selectedUsageMetric)
      });
      this.portalStore = portalStore;
      this.loading = true;
      this.usageMetricsList = [];
      this.selectedUsageMetric = undefined;
    }

    _loadData () {
      this.loading = true;
      this.usageMetricsList.length = 0;
      this.selectedUsageMetric = undefined;
      this.portalStore.getUsageMetricsList()
        .then((usageMetricsList) => this.usageMetricsList = usageMetricsList)
        .finally(() => this.loading = false);
    }

    isDisabled () {
      return _.isEmpty(this.usageMetricsList) || super.isDisabled();
    }

    onSelected (usageMetric) {
      this.selectedUsageMetric = usageMetric;
      this.onSelectionChange({ usageMetric: this.selectedUsageMetric });
    }
  }

  angular.module('application.components')
    .component('usageMetricToViewSelector', {
      bindings: { onSelectionChange: '&' },
      controller: UsageMetricToViewSelectorController,
      templateUrl: 'templates/components/usage-metric-to-view-selector.component.html'
    });
})();
