/* globals AbstractElementComponent, Configuration, Enumeration, Ready, Shortcut, UserProfile, Validate */
(function () {
  'use strict';
  const LISTED_SHORTCUT_ROLE = Enumeration.create('BUTTON', 'NONE').asStringValue(_.toLower);
  const SHORTCUTS_ACTION = Enumeration.create('ADD', 'DEFAULT', 'REORDER', 'UPDATE').asStringValue();
  const SHORTCUT_STEP_STATUS = Enumeration.create('DEFAULT', 'SUCCESS').asStringValue(_.toLower);
  const USER_VIEW_ROUTES = Object.freeze([
    Configuration.routes.weeklyPlanViewer,
    Configuration.routes.dailyPlanViewer,
    Configuration.routes.networkViewer,
    Configuration.routes.planEditor
  ]);

  function getScopeRouteKey (scope, route) {
    return `${scope}_${route.code}`;
  }

  function getShortcutNameSuggestion (viewName, routeCode) {
    return viewName.includes(routeCode) ? viewName : `${routeCode} ${viewName}`;
  }

  class ShortcutsController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', 'shortcut', 'userProfile', 'view'];
    }

    constructor (alerts, $authentication, overlay, shortcut, userProfile, view) {
      super();
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.shortcut = shortcut;
      this.userProfile = userProfile;
      this.view = view;
      this.maxShortcutsPermitted = UserProfile.maxShortcutsPermitted;
      this.userViews = {};

      this.state = Ready.create({
        isDataEmptyFn: () => !_.isUndefined(this.shortcuts) && _.isEmpty(this.shortcuts),
        isLoadingFn: () => this.loading
      });
    }

    _fetchShortcuts (options) {
      this.shortcuts = undefined;
      this.loading = true;
      this.userProfile.getShortcuts(options)
        .then((shortcuts) => this.shortcuts = shortcuts)
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to load shortcuts for user '${this.alias}'. Please check error details before retrying.` }))
        .finally(() => this.loading = false);
    }

    _fetchUserViews (scope = this.selection.scope, route = this.selection.route) {
      this.onShortcutViewChange();
      if (_.isNil(scope) || _.isNil(route)) {
        this.shortcutViews = [];
        return;
      }
      if (!_.isUndefined(this.userViews[getScopeRouteKey(scope, route)])) {
        this.shortcutViews = this.userViews[getScopeRouteKey(scope, route)];
        return;
      }
      this.shortcutViews = [];
      // Make the view.allViews() call with options containing alias only for user lookup.
      const options = this.isMyProfile ? undefined : { alias: this.alias };
      this.view.allViews(route.path, scope, options)
        .then((views) => {
          const privateViews = _.sortBy(_.filter(views.personal, (view) => !view.isPublic), ['name']);
          const publicViews = _.sortBy(views.public, ['name']);
          _.concat(privateViews, publicViews).forEach((view) => {
            view = _.pick(view, ['identifier', 'name']);
            this.shortcutViews.push(view);
          });
          this.userViews[getScopeRouteKey(scope, route)] = this.shortcutViews;
        })
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to load views for scope ${scope} and user ${this.alias}. Please check error details before retrying.` }));
    }

    _loadData () {
      this.tenants = Configuration.scopes.uniqueTenantsFromScopes(this.scopes);
      this.mode = SHORTCUTS_ACTION.DEFAULT;
      this.isMyProfile = this.alias === this.$authentication.profile().alias;
      if (!this.isMyProfile) {
        // Make the userProfile.getShortcuts() call right away if alias does not match the authenticated user alias.
        this._fetchShortcuts({ alias: this.alias });
        return;
      }
      // Check for user profile update in progress and add a listener if alias matches the authenticated user alias.
      if (!this.userProfile.isProfileUpdateInProgress()) {
        this._fetchShortcuts();
      }
      this.userProfile.$onProfileUpdate(() => this._fetchShortcuts());
    }

    _updateShortcuts (shortcuts = this.shortcuts) {
      this.overlay.show('Updating shortcuts');
      this.userProfile.updateShortcuts(shortcuts)
        .then(() => this.alerts.success('Successfully updated shortcuts.'))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to update shortcuts. Please check error details before retrying.' }))
        .finally(() => {
          this.cancelShortcutAction();
          this.overlay.hide();
        });
    }

    addShortcut () {
      const newShortcut = Shortcut.createBySource(this.newShortcut.source);
      if (!Shortcut.isComplete(newShortcut)) {
        this.alerts.warning('Shortcut details not complete! Please provide all shortcut details and try again.');
        return;
      }
      const existingShortcut = this.userProfile.hasShortcut(newShortcut);
      if (existingShortcut) {
        this.alerts.warning(`Duplicate shortcuts not allowed! You already have a shortcut named '${existingShortcut.name}' for the user view '${existingShortcut.dataLabel}' on the ${existingShortcut.scope} ${this.newShortcut.routeName} page.`);
        return;
      }
      this.shortcuts.push(newShortcut);
      this._updateShortcuts();
    }

    cancelShortcutAction () {
      this.mode = SHORTCUTS_ACTION.DEFAULT;
      this.selectedShortcut = undefined;
    }

    createShortcut () {
      if (this.userProfile.isShortcutsListFull()) {
        this.alerts.warning(`Your shortcuts list is full! You can have a maximum of ${this.maxShortcutsPermitted} shortcuts.`);
        return;
      }
      this.mode = SHORTCUTS_ACTION.ADD;
      this.newShortcut = {
        source: {}
      };
      this.selectedShortcut = undefined;
      this.selection = {};
      this.shortcutViews = [];
      this.stepStatus = {};
      this.onShortcutTenantChange();
    }

    deleteShortcut () {
      _.pull(this.shortcuts, this.selectedShortcut);
      this._updateShortcuts();
    }

    getDeleteConfirmationMessage () {
      return `Are you sure you want to delete the Shortcut <strong>${this.selectedShortcut.name}</strong>? This action cannot be undone.`;
    }

    getListedShortcutRole () {
      return this.isMyProfile && (this.isDefaultModeEnabled() || this.isUpdateModeEnabled()) ? LISTED_SHORTCUT_ROLE.BUTTON : LISTED_SHORTCUT_ROLE.NONE;
    }

    getRouteIconClass (icon) {
      return `fa fa-fw ${icon}`;
    }

    getShortcutsCount () {
      return _.size(this.shortcuts);
    }

    getStepStatus (step) {
      return `panel-${this.stepStatus[step] || SHORTCUT_STEP_STATUS.DEFAULT}`;
    }

    isAddModeEnabled () {
      return this.mode === SHORTCUTS_ACTION.ADD;
    }

    isCancelSaveFooterVisible () {
      return this.isAddModeEnabled() || this.isUpdateModeEnabled();
    }

    isCreateButtonDisabled () {
      return this.loading || !this.isDefaultModeEnabled();
    }

    isCreateButtonVisible () {
      return this.isMyProfile && !this.loading;
    }

    isDefaultModeEnabled () {
      return this.mode === SHORTCUTS_ACTION.DEFAULT;
    }

    isReorderButtonDisabled () {
      return this.loading || !this.isDefaultModeEnabled();
    }

    isReorderButtonVisible () {
      return this.isMyProfile && !this.loading && this.getShortcutsCount() > 1;
    }

    isReorderModeEnabled () {
      return this.mode === SHORTCUTS_ACTION.REORDER;
    }

    isSelectedShortcut (shortcut) {
      return shortcut.equals(this.selectedShortcut);
    }

    isShortcutNameDisabled () {
      return _.isNil(this.selection.scope) || _.isNil(this.selection.route) || _.isNil(this.selection.view);
    }

    isUpdateModeEnabled () {
      return this.mode === SHORTCUTS_ACTION.UPDATE;
    }

    loadShortcut (event, shortcut) {
      event.stopPropagation();
      this.shortcut.openShortcut(shortcut);
    }

    onSelectShortcut (shortcut) {
      if (!this.isMyProfile || this.isAddModeEnabled() || this.isReorderModeEnabled()) {
        return;
      }
      this.selectedShortcut = shortcut;
      this.updatedShortcutName = shortcut.name;
      this.mode = SHORTCUTS_ACTION.UPDATE;
    }

    onShortcutTenantChange (selectedTenant) {
      if (_.isNil(selectedTenant)) {
        const currentTenant = Configuration.scopes.current().tenant;
        selectedTenant = this.tenants.find((tenant) => tenant.is(currentTenant)) || _.head(this.tenants);
      }
      this.selection.tenant = selectedTenant;
      this.newShortcut.tenant = selectedTenant;
      const scopesByTenant = [];
      Configuration.scopes.allByTenant(selectedTenant).forEach((scope) => {
        scopesByTenant.push(scope.portalCode);
        if (scope.isComposite()) {
          scope.subscopes.forEach((subscope) => scopesByTenant.push(subscope.portalCode));
        }
      });
      this.scopesByTenant = scopesByTenant.filter((scope) => _.includes(this.scopes, scope)).sort();
      this.onShortcutScopeChange();
    }

    onShortcutScopeChange (selectedScope) {
      if (_.isNil(selectedScope)) {
        const currentScope = Configuration.scopes.current().portalCode;
        selectedScope = this.scopesByTenant.find((scope) => scope === currentScope) || _.head(this.scopesByTenant);
      }
      this.selection.scope = selectedScope;
      this.newShortcut.source.scope = selectedScope;
      this.stepStatus['1'] = SHORTCUT_STEP_STATUS.SUCCESS;
      this.shortcutRoutes = USER_VIEW_ROUTES.filter((route) => route.isAvailableForScope(selectedScope));
      this.onShortcutPageChange();
    }

    onShortcutPageChange (selectedRoute) {
      if (_.isNil(selectedRoute)) {
        delete this.selection.route;
        delete this.newShortcut.source.baseUrl;
        delete this.newShortcut.routeIcon;
        delete this.newShortcut.routeName;
        this.stepStatus['2'] = SHORTCUT_STEP_STATUS.DEFAULT;
      } else {
        this.selection.route = selectedRoute;
        this.newShortcut.source.baseUrl = selectedRoute.path;
        this.newShortcut.routeIcon = selectedRoute.icon;
        this.newShortcut.routeName = selectedRoute.name;
        this.stepStatus['2'] = SHORTCUT_STEP_STATUS.SUCCESS;
      }
      this._fetchUserViews();
    }

    onShortcutViewChange (selectedView) {
      if (_.isNil(selectedView)) {
        delete this.selection.view;
        delete this.newShortcut.source.name;
        this.stepStatus['3'] = SHORTCUT_STEP_STATUS.DEFAULT;
        this.stepStatus['4'] = SHORTCUT_STEP_STATUS.DEFAULT;
        return;
      }
      this.selection.view = selectedView;
      this.newShortcut.source.dataKey = selectedView.identifier;
      this.newShortcut.source.dataLabel = selectedView.name;
      this.stepStatus['3'] = SHORTCUT_STEP_STATUS.SUCCESS;
      this.newShortcut.source.name = getShortcutNameSuggestion(selectedView.name, this.selection.route.code);
      this.stepStatus['4'] = SHORTCUT_STEP_STATUS.SUCCESS;
    }

    onShortcutNameChange () {
      this.stepStatus['4'] = Validate.isBlank(this.newShortcut.source.name) ? SHORTCUT_STEP_STATUS.DEFAULT : SHORTCUT_STEP_STATUS.SUCCESS;
    }

    onShortcutsReorder () {
      this.shortcutsReorderClone.forEach((shortcut, index) => shortcut.rankDelta = shortcut.rank - index);
    }

    reorderShortcuts () {
      this.shortcutsReorderClone = _.map(this.shortcuts, (shortcut, index) => {
        shortcut.rank = index;
        shortcut.rankDelta = 0;
        return shortcut;
      });
      this.mode = SHORTCUTS_ACTION.REORDER;
    }

    shortcutTenantDisplayNameFn (shortcutTenant) {
      return shortcutTenant.name;
    }

    shortcutRouteDisplayNameFn (shortcutRoute) {
      return `${shortcutRoute.name} (${shortcutRoute.code})`;
    }

    shortcutViewDisplayNameFn (shortcutView) {
      return shortcutView.name;
    }

    updateReorderedShortcuts () {
      if (this.shortcutsReorderClone.every((shortcut, index) => shortcut.rank === index)) {
        this.alerts.warning('None of the shortcuts were reordered. Please reorder/shuffle shortcuts and try again.');
        return;
      }
      this._updateShortcuts(this.shortcutsReorderClone);
    }

    updateShortcut () {
      if (Validate.isBlank(this.updatedShortcutName)) {
        this.alerts.warning('Shortcut name cannot be blank. Please enter a shortcut name and try again.');
        return;
      }
      if (this.updatedShortcutName === this.selectedShortcut.name) {
        this.alerts.warning('No changes were made to the shortcut name. Please update the shortcut name and try again.');
        return;
      }
      this.selectedShortcut.setName(this.updatedShortcutName);
      this._updateShortcuts();
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('shortcuts', {
      bindings: {
        alias: '<',
        scopes: '<'
      },
      controller: ShortcutsController,
      controllerAs: 'ctrl',
      templateUrl: 'templates/components/shortcuts.component.html'
    });
})();
