/* globals AWS, AWSBase, Configuration, JwtDecode */
(function () {
  'use strict';
  const PORTAL_AUTH_SERVICE = Configuration.services.portalAuth;
  // The following values are based off the WRITE/READ-ROLE prefix from auth-config-content.ts in SandopPortalServiceLambdaCDK
  const AUTH_TOKEN = 'USER-AUTH-TOKEN';
  const MANAGED_SERVICES = Object.freeze(_.filter(Configuration.services, (service) => !_.isNil(service.token)));
  // 1 minute
  const RENEW_TOKEN_DELTA_MILLISECONDS = 60000;

  /**
   * AuthZ wrapping class used for fetching AWS credentials for all service calls.
   */
  class PortalAuthService {
    static get $inject () {
      return ['$authentication', '$http', '$interval', 'midway', '$q'];
    }

    constructor ($authentication, $http, $interval, midway, $q) {
      this.$authentication = $authentication;
      this.$http = $http;
      this.$interval = $interval;
      this.midway = midway;
      this.$q = $q;

      // Caches service credentials using 'service.token' as the key.
      this._tokens = {};
      // Queues and Requests will allow for deferred fetching of credentials to prevent duplicate calls for the same credentials.
      this._queues = {};
      this._requests = {};
      // Credentials provider for the 'request' service.
      this.credentials = {
        empty: () => this.$q.resolve({}),
        // @VisibleForTesting - TestHelper.setupPortalAuth uses this method to ensure credentials are available for service unit tests.
        set: {}
      };

      // On initialization we have to determine if the user is authenticated and load their stored auth tokens if so.
      if (this.$authentication.isAuthenticated()) {
        const userProfile = this.$authentication.profile();
        // Load the stored Midway identity tokens and AWS credentials for the portal auth service.
        this._tokens[AUTH_TOKEN] = userProfile[AUTH_TOKEN];
        this._tokens[PORTAL_AUTH_SERVICE.token] = userProfile[PORTAL_AUTH_SERVICE.token];
        // Re-start the auto renew timer.
        this._startAutoRenewTimer(userProfile.expiration);
      }

      // Map all service keys to a method to retrieve AWS credentials.
      _.forOwn(MANAGED_SERVICES, (service) => {
        this.credentials[service.key] = () => this._defer(service.token);
        this.credentials.set[service.key] = (credentials) => this._tokens[service.token] = credentials;
        this._queues[service.token] = [];
      });
      this.credentials.portalAuth = () => this.$q.resolve(this._tokens[PORTAL_AUTH_SERVICE.token]);
      this._queues[AUTH_TOKEN] = [];

      this.$authentication.$onLogoutConfirmed(() => {
        // Clear out all cached credentials on logout.
        this._tokens.length = 0;
        this._clearQueues();
      });
    }

    /**
     * Returns the x-sandop-authorization-token for service headers.
     *
     * @returns the x-sandop-authorization-token mapped to the Midway identity token.
     */
    authorizationHeaders () {
      return {
        'X-SandOP-Authorization-Token': this._tokens[AUTH_TOKEN]
      };
    }

    /**
     * Authorizes the user through the Portal Auth Service after having authenticated through Midway.
     *
     * @param midwayToken Midway identity token.
     */
    authorize (midwayToken) {
      // Initialize AWS credentials for the Portal Auth service and start a renew timer.
      const portalAuthCredentials = this._initializePortalAuthCredentials();

      // Cache the Midway identity token for backend calls - this also allows 'getAwsCredentials' to be called in the '_defer' method.
      this._tokens[AUTH_TOKEN] = midwayToken;

      // If user is already authenticated (just renewing credentials) then update the stored user profile.
      if (this.$authentication.isAuthenticated()) {
        this._updateUserProfile(portalAuthCredentials, midwayToken);
        return;
      }

      // Get user roles and login.
      return this.getUserRoles().then((userProfile) => {
        userProfile.alias = JwtDecode.extractUser(midwayToken);
        // Historically, the full name of the user was fetched through Ldap. That is no longer the case with Bindles - keeping around for other components that expect 'name'.
        // SIM: https://sim.amazon.com/issues/SOP-14228
        userProfile.name = userProfile.alias;
        this._updateUserProfile(portalAuthCredentials, midwayToken, userProfile);
        // login confirmed will broadcast a 'login event' and will redirect the user to their last accessed page.
        this.$authentication.loginConfirmed(userProfile);

        // Process and clear everything on the AUTH_TOKEN queue.
        this._queues[AUTH_TOKEN].forEach((fn) => fn());
        this._queues[AUTH_TOKEN].length = 0;
      });
    }

    /**
     * Constructs an AWS Base with credentials for the Portal Auth Service.
     *
     * @returns an AWS Base.
     */
    aws () {
      // Creates an AWS Base to make API calls using 'this' as the service credentials provider.
      return AWSBase.aws(this.$http, this).withAwsCredentials(PORTAL_AUTH_SERVICE.key);
    }

    /**
     * Gets the assumed IAM role AWS credentials for the given service.
     *
     * @param service service key to fetch AWS credentials for.
     * @returns {*}
     */
    getAwsCredentials (service) {
      const scope = Configuration.scopes.current();
      return this.aws()
        .for('getAwsCredentials', service, scope.tenant.id, scope.code)
        .get();
    }

    /**
     * Gets the IPT roles a user is authorized for (Ex: sandop-us-read).
     *
     * @returns List of User Roles, Scope, and Expiration duration.
     */
    getUserRoles () {
      return this.aws()
        .for('getUserRoles')
        .get();
    }

    /**
     * Renews the Portal Auth Service credentials.
     */
    renew () {
      // Re-initialize AWS Config and cache the credentials for the Portal Auth service.
      return this.midway.fetchMidwayToken()
        .then((midwayToken) => this.authorize(midwayToken));
    }

    _clearQueues () {
      this._queues = _.mapValues(this._queues, () => []);
    }

    _defer (service) {
      const deferred = this.$q.defer();
      const authQueuedFn = () => {
        // If the cached credentials have not expired then resolve the cached credentials.
        if (_.has(this._tokens, service) && Date.parse(this._tokens[service].expiration) >= Date.now()) {
          deferred.resolve(this._tokens[service]);
          return;
        }

        // No service token -> queue promise request - this will get cleared when the request's promise below resolves.
        this._queues[service].push(() => deferred.resolve(this._tokens[service]));
        // Exit early if there is already an existing call to fetch the services credentials.
        if (!_.isNil(this._requests[service])) {
          return;
        }

        // No service request -> make request.
        this._requests[service] = this.getAwsCredentials(service).then((serviceToken) => {
          // Cache the services AWS credentials.
          this._tokens[service] = serviceToken;

          // Process and clear everything on the service queue - this will invoke the 'deferred.resolve' call up above.
          this._queues[service].forEach((fn) => fn());
          this._queues[service].length = 0;

          // Remove the service request - this ensures the method exits early through the above if-statement.
          this._requests[service] = undefined;
        });
      };

      // Queue the service's credentials if the user has already been authenticated.
      if (this.$authentication.isAuthenticated()) {
        authQueuedFn();
      } else {
        // No Auth Token -> queue promise request - this will get cleared in the 'authorize' method above.
        this._queues[AUTH_TOKEN].push(authQueuedFn);
      }
      // The deferred promise will only resolve after 'deferred.resolve' is called.
      return deferred.promise;
    }

    _initializePortalAuthCredentials () {
      // Converting keys to lower camel case as AWS.config...Credentials has capitalized keys { SecretAccessKey: 'secretAccessKey' } => { secretAccessKey: 'secretAccessKey' }.
      this._tokens[PORTAL_AUTH_SERVICE.token] = _.mapKeys(AWS.config.credentials.data.Credentials, (value, key) => _.lowerFirst(key));
      return this._tokens[PORTAL_AUTH_SERVICE.token];
    }

    _updateUserProfile (portalAuthServiceCredentials, midwayToken, userProfile = this.$authentication.profile()) {
      userProfile[AUTH_TOKEN] = midwayToken;
      userProfile[PORTAL_AUTH_SERVICE.token] = portalAuthServiceCredentials;
      userProfile.expiration = JwtDecode.extractExpiration(midwayToken);
      this.$authentication.profile(userProfile);
      // Stop and start auto-renewal timer on the token
      this._startAutoRenewTimer(userProfile.expiration);
    }

    _startAutoRenewTimer (userTokenExpiration) {
      // Cancel the timer, if one exists, before starting a new timer.
      if (!_.isNil(this._timer)) {
        this.$interval.cancel(this._timer);
      }
      // This is the milliseconds from now till the userTokenExpiration time with a 1 minute window.
      const interval = Date.parse(userTokenExpiration) - RENEW_TOKEN_DELTA_MILLISECONDS - Date.now();
      this._timer = this.$interval(() => this.renew(), Math.max(interval, RENEW_TOKEN_DELTA_MILLISECONDS));
    }
  }

  angular.module('application.services').service('portalAuth', PortalAuthService);
})();
