/* globals Cell, ClassDecorator, Configuration, Display, Filename, Grid, HeaderCell */
(function () {
  'use strict';
  class NetworkGridController {
    static get $inject () {
      return ['trader', 'transformerFactory'];
    }

    constructor (trader, transformerFactory) {
      this.transformerFactory = transformerFactory;
      this.gridData = {};
      this.masterset = {};
      this.actions = {
        download: (format) => this.transformerFactory.toDocument(
          this.transformerFactory.transformerType.plan,
          format,
          [this.masterset],
          [
            {
              key: 'granularity',
              serialize: (granularity) => granularity.grains.names()
            },
            'Data Type',
            { key: 'dates' }
          ],
          [
            {
              key: 'granularity',
              serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
              source: 'pkg'
            },
            {
              key: 'dataType',
              source: 'row'
            },
            {
              key: 'values',
              source: 'row'
            }
          ]
        ).then((workbook) =>
          trader.download(
            workbook,
            Filename
              .create('Network Viewer')
              .add(
                Configuration.scopes.current().code,
                this.masterset.title,
                `${_.head(this.masterset.dates)} to ${_.last(this.masterset.dates)}`
              ),
            trader.toExtensionType(format)
          )
        )
      };
    }

    _bodyRowSupplier () {
      const rows = [];
      Grid.rowspanCalculator(this.masterset.records, this.masterset.granularity.grains.values(this.package.viewGrainFilter));
      this.masterset.records.forEach((row) => {
        const rowData = {
          data: [],
          headers: []
        };
        this.masterset.granularity.grains.values(this.package.viewGrainFilter).forEach((grain) => {
          if (!_.isNil(row.rowspan) && row.rowspan[grain.id] > 0) {
            rowData.headers.push(
              HeaderCell
                .create(
                  row.granularity[grain.id],
                  row.granularity[grain.id],
                  ClassDecorator.highlight(row),
                  ClassDecorator.horizontalDividers(row),
                  ClassDecorator.metricPriority(row.metric),
                  ClassDecorator.width(grain)
                )
                .setRowspan(row.rowspan[grain.id])
            );
          }
        });
        const dataType = _.defaultTo(row.metric.subType, row.dataType);
        row.values.forEach((value, index) => {
          rowData.data.push(
            Cell.create(
              Display.data(value, row.metric.dataType),
              value,
              'text-right',
              ClassDecorator.alarm(row.metric.threshold, value),
              ClassDecorator.cumulativeEndOfWeek(this.package.dates[index], dataType, value),
              ClassDecorator.dataset(dataType, value),
              ClassDecorator.horizontalDividers(row),
              ClassDecorator.verticalDividers(index, this.package.historicalDividerIndex),
              ClassDecorator.width()
            )
          );
        });
        rows.push(rowData);
      });
      return rows;
    }

    $onInit () {
      this.masterset = this.package;

      this.gridData.header = Grid.header(
        this.package.title,
        () => Grid.defaultHeaderRowSupplier(this.package),
        { stickyHeaderColumns: true }
      );

      this.gridData.body = Grid.body(this.masterset.records.length, this._bodyRowSupplier.bind(this));
    }
  }

  angular.module('application.components')
    .component('networkGrid', {
      bindings: {
        package: '<'
      },
      controller: NetworkGridController,
      templateUrl: 'templates/components/network-grid.component.html'
    });
})();
