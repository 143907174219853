/* globals AbstractSourceBasedModel, Enumeration */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'displayName',
    'grainType',
    /**
     * [
     *   {
     *     displayName: String,
     *     id: String
     *   }, ...
     * ]
     */
    'values'
  ]);

  const SORT_TYPE_ID_TO_NAME_MAP = Enumeration
    .create('heavybulk', 'noncon')
    .asExactValue('Heavy / Bulk', 'Non-Conveyable');

  class GrainDefinition extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    static create (source) {
      return Object.freeze(new GrainDefinition(source));
    }

    static getSortTypeName (id) {
      return SORT_TYPE_ID_TO_NAME_MAP[id] || _.capitalize(id);
    }

    static isUnknownGrain (grain) {
      return _.get(grain, 'name', '').toUpperCase() === 'UNKNOWN';
    }
  }

  window.GrainDefinition = Object.freeze(GrainDefinition);
})();
