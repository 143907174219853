/* globals AbstractSourceBasedModel, Enumeration */
(function () {
  'use strict';

  const MODEL_TYPE = Enumeration
    .create('DATA_PROCESSING_MODEL', 'PREDICTION_MODEL')
    .asStringValue((key) => _.camelCase(_.lowerCase(key)));

  const PROPERTIES = Object.freeze([
    'dataSources',
    'modelName',
    'modelParameters',
    'modelType'
  ]);

  class MarketplaceModelDefinition extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.text = _.startCase(source.modelName);
      this.name = _.startCase(source.modelName);
    }

    static type () {
      return MODEL_TYPE;
    }
  }

  window.MarketplaceModelDefinition = Object.freeze(MarketplaceModelDefinition);
})();
