/* globals AbstractServiceEndpoint, Configuration, MarketplaceModelDefinition, MarketplaceRun, NetworkPlanValue */
(function () {
  'use strict';
  const MODELRUNS_CHANGE_EVENT_KEY = 'modelruns-change';

  class ModelMarketplaceService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['$q', 'request', '$rootScope'];
    }

    constructor ($q, request, $rootScope) {
      super(request, Configuration.services.modelMarketplace);
      this.$q = $q;
      this.$rootScope = $rootScope;
    }

    $onModelRunsChange (handler) {
      return this.$rootScope.$on(MODELRUNS_CHANGE_EVENT_KEY, handler);
    }

    models (modelType) {
      return this.aws()
        .withScope()
        .for('models')
        .withParams({ modelType })
        .get()
        .then((response) => _.map(response.models, (definition) => MarketplaceModelDefinition.create(definition)));
    }

    generateNetworkPlan (body) {
      return this.aws()
        .withScope()
        .for('networkPlanGeneration')
        .withBody(body)
        .post()
        .then((response) => _.map(response.planData[body.targetPlanType]), (planValue) => NetworkPlanValue.create(planValue));
    }

    getRuns (startDate, endDate) {
      const options = { endDate, startDate };

      return this.aws()
        .withScope()
        .for('modelRuns')
        .withParams(options)
        .get()
        .then((response) => _.map(response.modelMarketplaceRuns, (execution) => MarketplaceRun.create(execution)));
    }

    downloadFromS3 (bucket, key) {
      return this.s3().get(bucket, key);
    }

    s3Location () {
      return this.aws()
        .withScope()
        .for('s3Location')
        .get();
    }

    uploadToS3 (files) {
      const uploadedS3Locations = {};
      return this.s3Location()
        .then((s3Location) => this.$q.all(_.map(files, (file, fileType) => {
          const key = _.isEmpty(s3Location.key) ? file.name : `${s3Location.key}/${file.name}`;
          return this.s3()
            .put(s3Location.bucket, key, file)
            .then(() => uploadedS3Locations[fileType] = { bucket: s3Location.bucket, key: key });
        })).then(() => uploadedS3Locations));
    }

    run (body) {
      return this.aws()
        .withScope()
        .for('triggerModelMarketplace')
        .withBody(body)
        .post().then((response) => {
          this.$rootScope.$broadcast(MODELRUNS_CHANGE_EVENT_KEY);
          return response;
        });
    }
  }

  angular.module('application.services').service('modelMarketplace', ModelMarketplaceService);
})();
