/* globals DateUtils, Enumeration, Enums, Summary */
(function () {
  'use strict';

  const SUMMARY_TYPES = Enumeration.create('PLAN_VIEWER').asStringValue(_.camelCase);

  class PlanSummaryController {
    static get $inject () {
      return ['alerts', 'planStore', '$q'];
    }

    constructor (alerts, planStore, $q) {
      this.alerts = alerts;
      this.planStore = planStore;
      this.$q =$q;
    }

    // pLabel holds the previous year label. P represents Previous year (1 year ago). PP represents Previous Previous year (2 years ago)
    _addYoYSummary (show, side, pLabel) {
      if (!show) {
        return;
      }
      const dateRangeFrom = side === 'Left' ? _.first(this.interface.dateRange) : _.nth(this.interface.dateRange, this.interface.actuals.dateRangeLength);
      const offset = side === 'Left' ? this.interface.actuals.dateRangeLength - 1 : this.interface.dateRange.length - this.interface.actuals.dateRangeLength - 1;
      const startDate = DateUtils.priorYearDate(dateRangeFrom, pLabel === 'P' ? 1 : 2);
      const endDate = DateUtils.fromOffset(startDate, offset, Enums.TimeUnit.WEEK);
      // 'dateRangeFrom' can be nil for the Right side if the Left side (Actuals) cover the entire date range.
      const datesRangeMessage = _.isUndefined(dateRangeFrom) ? '-' : `From: <strong>${startDate}</strong> to <strong>${endDate}</strong>`;
      this.summary[`add${side}`](`YoY Actuals - ${pLabel}Y`, datesRangeMessage, 'dataset', `actuals${pLabel}YoY`);
    }

    _constructPlanViewerSummary () {
      if (this.interface.settings.forecasts) {
        this._fetchMetadata(this.interface.forecast);
      }
      this.interface.settings.forecastComparisons.forEach((comparison, index) => {
        if (comparison) {
          this._fetchMetadata(this.interface.forecastComparisons[index]);
        }
      });

      // If promises is an empty list, then() executes immediately.
      // Otherwise wait until all promises in the list have resolved.
      this.$q.all(this.promises)
        .then(() => {
          if (this.interface.viewName) {
            this.summary.add('View', this.interface.viewName);
            delete this.interface.viewName;
          }
          if (!this.interface.settings.forecasts) {
            this.summary.add('Plan', this.interface.forecast.plan.displayName());
          }
          if (this.interface.settings.actuals) {
            this.summary.addLeft('Actuals', `From <strong>${this.interface.actuals.startDate.date}</strong> to <strong>${this.interface.actuals.endDate.date}</strong>`, 'dataset', 'actual');
          }
          if (this.interface.settings.forecasts) {
            this.summary.addRight('Forecast', this.interface.forecast.plan.displayName(Enums.Plan.DisplayFormat.FULL_WITH_TIMESTAMP), 'dataset', 'primary');
          }
          this.interface.settings.actualsComparisons.forEach((comparison, index) => {
            if (!comparison) {
              return;
            }
            this.summary.addLeft(
              `Comparison ${index + 1}`,
              `${this.interface.actualsComparisons[index].plan.historicalSubScenario}`,
              'dataset',
              this.interface.actualsComparisons[index].datasetClass
            );
            if (this.interface.settings.forecasts && !this.interface.settings.forecastComparisons[index]) {
              this.summary.addRight(`Comparison ${index + 1}`, '-');
            }
          });
          this.interface.settings.forecastComparisons.forEach((comparison, index) => {
            if (!comparison) {
              return;
            }
            this.summary.addRight(
              `Comparison ${index + 1}`,
              this.interface.forecastComparisons[index].plan.displayName(Enums.Plan.DisplayFormat.FULL_WITH_TIMESTAMP),
              'dataset',
              this.interface.forecastComparisons[index].datasetClass
            );
            if (this.interface.settings.actuals && !this.interface.settings.actualsComparisons[index]) {
              this.summary.addLeft(`Comparison ${index + 1}`, '-');
            }
          });
          if (this.interface.settings.includeYoYActuals.PY) {
            this._addYoYSummary(this.interface.settings.actuals, 'Left', 'P');
            this._addYoYSummary(this.interface.settings.forecasts, 'Right', 'P');
          }
          if (this.interface.settings.includeYoYActuals.PPY) {
            this._addYoYSummary(this.interface.settings.actuals, 'Left', 'PP');
            this._addYoYSummary(this.interface.settings.forecasts, 'Right', 'PP');
          }
          this.summary.constructList();
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to create plan summary' }));
    }

    // The harboring object (planInterface) is used instead of the plan object to allow
    // its plan reference to be overwritten potentially.
    _fetchMetadata (planInterface) {
      // Only fetch metadata if it is stale (nil lastUpdatedAt).
      if (!_.isNil(planInterface.plan.lastUpdatedAt)) {
        return;
      }

      this.promises.push(this.planStore.planMetadata(planInterface.plan, { version: planInterface.plan.version })
        .then((planMetadata) => {
          if (planMetadata.isValid()) {
            planInterface.plan = planMetadata;
          }
        }));
    }

    $onInit () {
      this.summary = Summary.create();
      this.promises = [];
      switch (this.type) {
        case SUMMARY_TYPES.PLAN_VIEWER:
          this._constructPlanViewerSummary();
          break;
      }
    }
  }

  angular.module('application.components')
    .component('planSummary', {
      bindings: {
        /*
         * @interface is expected to be an object containing all summary specific properties
         * depending on the case selected. The summary will be formulated from these properties.
         */
        interface: '<',
        /*
         * @type is a string that determines which type of summary to construct.
         * Must be one of the summary types defined in the SUMMARY_TYPES enumeration.
         */
        type: '@'
      },
      controller: PlanSummaryController,
      templateUrl: 'templates/components/plan-summary.component.html'
    });
})();
