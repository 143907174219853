/* globals Configuration, Display, Ready */
(function () {
  'use strict';
  const BM_TILES_ROUTE = Configuration.routes.networkViewer;

  const METRIC_FAMILY_GROUP_ALIAS_MAP = Object.freeze({
    Inbound: 'Big Gulp',
    Outbound: 'Big Push',
    Returns: 'C&V Returns'
  });

  const METRIC_GROUP_ICON_MAP = Object.freeze({
    Inbound: 'fa-sign-in',
    Outbound: 'fa-sign-out',
    Returns: 'fa-reply'
  });

  const VIEW_OPTIONS_MAP = Object.freeze({
    BmTiles: 'BM Tiles',
    Dashboard: 'Dashboard'
  });

  class HomeController {
    static get $inject () {
      return ['alerts', '$location', 'metricsService', '$scope', 'share'];
    }

    constructor (alerts, $location, metricsService, $scope, share) {
      this.alerts = alerts;
      this.$location = $location;
      this.metricsService = metricsService;
      this.$scope = $scope;
      this.share = share;
      this.currentScope = Configuration.scopes.current().portalCode;
      this.$onInit();
    }

    _getMetricFamilyGroups () {
      if (!this.$scope.settings.areTilesVisible()) {
        return;
      }
      this.metricsService.networkViewerMetricFamilyGroupIds()
        .then((metricFamilyGroups) => {
          this.$scope.model.metricFamilyGroups = _.map(metricFamilyGroups, (group) => Object.freeze({
            alias: _.get(METRIC_FAMILY_GROUP_ALIAS_MAP, group, group),
            icon: _.get(METRIC_GROUP_ICON_MAP, group, BM_TILES_ROUTE.icon),
            id: group,
            title: group
          }));
          if (_.isEmpty(this.$scope.model.metricFamilyGroups)) {
            return;
          }
          const dimensions = Display.computeBootstrapRowDimensions(this.$scope.model.metricFamilyGroups.length);
          this.$scope.model.tileWidth = dimensions.column.width;
          this.$scope.model.sectionWidth = dimensions.row.width;
          this.$scope.model.sectionOffset = dimensions.row.offset;
        })
        .catch((error) => {
          this.alerts.error(error);
          this.$scope.model.metricFamilyGroups = [];
        });
    }

    _isNetworkViewerAvailableForScope () {
      return BM_TILES_ROUTE.isAvailableForScope(this.currentScope);
    }

    $onInit () {
      this.$scope.state = Ready.create({
        isLoadingFn: () => _.isNil(this.$scope.model.metricFamilyGroups)
      });
      this.$scope.model = {
        sectionOffset: 0,
        sectionWidth: 12,
        tileWidth: 0,
        viewOptions: {
          list: [VIEW_OPTIONS_MAP.BmTiles, VIEW_OPTIONS_MAP.Dashboard],
          selected: VIEW_OPTIONS_MAP.BmTiles
        }
      };
      this.$scope.actions = {
        redirectToNetworkViewer: (group, view) => {
          const configuration = {
            model: {
              metricFamilyGroup: group
            },
            states: {
              settings: {
                metricView: view === 'metric'
              }
            }
          };
          this.share.setShareableData(configuration);
          this.$location.path(BM_TILES_ROUTE.path);
        }
      };
      this.$scope.settings = {
        areTilesVisible: () => this._isNetworkViewerAvailableForScope(),
        isDashboardSwitchVisible: () => !_.isNil(Configuration.metricsDashboard.forScope(this.currentScope)),
        isViewOptionDashboard: () => this.$scope.model.viewOptions.selected === VIEW_OPTIONS_MAP.Dashboard,
        isViewOptionTiles: () => this.$scope.model.viewOptions.selected === VIEW_OPTIONS_MAP.BmTiles
      };

      this._getMetricFamilyGroups();
    }
  }

  angular.module('application.controllers').controller('HomeController', HomeController);
})();
