/* globals Configuration */
(function () {
  'use strict';
  const GAMMA_BINDLES_STAGE = '-gamma';
  class AuthorizationController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.$scope = $scope;
      this.$onInit();
    }

    $onInit () {
      this.$scope.allTenants = Configuration.tenants.all();
      this.$scope.scopeRolesByTenant = {};
      this.$scope.allTenants.forEach((tenant) => {
        const nonCompositeScopes = Configuration.scopes.allByTenant(tenant, true).filter((scope) => !scope.isComposite());
        this.$scope.scopeRolesByTenant[tenant.id] = _.map(_.sortBy(nonCompositeScopes, ['portalCode']), (scope) => ({
          code: scope.portalCode,
          name: scope.name,
          readRole: Configuration.authorization.roles.read(scope.portalCode)
        }));
      });
      // Bindles only supports Gamma and Prod.
      const stage = Configuration.environment.current().isProd() ? '' : GAMMA_BINDLES_STAGE;
      this.$scope.actions = {
        getPermissionsHelpLink: () => Configuration.endpoints.static.userPermissionsHelp,
        getPermissionsLink: (role) => Configuration.endpoints.staticOf.permissionsBindle(role, stage)
      };
    }
  }
  angular.module('application.controllers').controller('AuthorizationController', AuthorizationController);
})();
