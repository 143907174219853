/* globals AbstractDataViewController, Configuration, DateUtils, Enums, Filename, Granularities, PlanMetadata */
(function () {
  'use strict';
  const DAYS_BACK_TO_MIN_DATE = -91,
        DEFAULT_ACTUALS_DAYS_BACK = -8,
        PROGRESSIVE_LOADING_GRIDS_TO_ADD = 15,
        PROGRESSIVE_LOADING_DELAY = 1000,
        SUMMARY_METRIC_FAMILY = 'Summary';

  const getDailyPlanMetadata = (cadenceDate) => ({
    date: cadenceDate,
    draft: Enums.Plan.DraftType.FINAL,
    scenario: PlanMetadata.defaultScenario.scenario,
    subScenario: PlanMetadata.defaultScenario.subScenario,
    type: Enums.Plan.PlanType.DAILY_PLAN
  });

  const getDailyOrderPlanMetadata = (cadenceDate) => ({
    date: cadenceDate,
    draft: Enums.Plan.DraftType.FINAL,
    scenario: PlanMetadata.defaultScenario.scenario,
    subScenario: PlanMetadata.defaultScenario.subScenario,
    type: Enums.Plan.PlanType.DAILY_ORDER_PLAN
  });

  const fetchPlanMap = (metricFamilies, baseMetadata, summaryMetadata) => _.reduce(
    metricFamilies,
    (map, metricFamily) => {
      map[metricFamily] = _.isEqual(metricFamily, SUMMARY_METRIC_FAMILY) && !_.isEmpty(summaryMetadata) ? summaryMetadata : baseMetadata;
      return map;
    },
    {}
  );

  // Used to track the master data set for progressive loading
  const masterset = [];

  class NetworkViewerController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', 'dataPipeline', '$filter', '$interval', 'metricsService', 'overlay', 'packagerFactory', 'planStore', '$q', '$scope', 'share', 'trader', 'transformerFactory', 'usageMetrics', 'view'];
    }

    constructor (alerts, dataPipeline, $filter, $interval, metricsService, overlay, packagerFactory, planStore, $q, $scope, share, trader, transformerFactory, usageMetrics, view) {
      super($scope, share, view);
      this.alerts = alerts;
      this.dataPipeline = dataPipeline;
      this.$filter = $filter;
      this.$interval = $interval;
      this.metricsService = metricsService;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.planStore = planStore;
      this.$q = $q;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.usageMetrics = usageMetrics;
      this.$onInit();
    }

    _addGridsToDisplay () {
      if (_.isEmpty(masterset)) {
        return;
      }

      if (this.$scope.data.length === masterset.length) {
        this.$interval.cancel(this.$scope.progressiveLoadingInterval);
        delete this.$scope.progressiveLoadingInterval;
        masterset.length = 0;
        return;
      }

      const startIndex = this.$scope.data.length;
      this.addData(...masterset.slice(startIndex, startIndex + PROGRESSIVE_LOADING_GRIDS_TO_ADD));

      if (!this.$scope.progressiveLoadingInterval) {
        this.$scope.progressiveLoadingInterval = this.$interval(this._addGridsToDisplay.bind(this), PROGRESSIVE_LOADING_DELAY);
      }
    }

    _checkDataDelayStatus () {
      const scope = Configuration.scopes.current();
      const checkStatusDate = DateUtils.fromOffset(undefined, -1, Enums.TimeUnit.DAY);
      // Delay checks are configured at region level
      this.dataPipeline.getDataDelayStatus(scope.region.code, checkStatusDate)
        .then((delayStatus) => {
          if (delayStatus.isDelayed) {
            this.alerts.danger(delayStatus.delayMessage);
          }
        });
    }

    _constructQuerySummary () {
      this.querySummary.clear();

      if (this.$scope.viewName) {
        this.querySummary.add('View', this.$scope.viewName);
        delete this.$scope.viewName;
      }
      if (this.$scope.settings.actuals) {
        this.querySummary.add('Actuals', `From: <strong>${this.$scope.model.actuals.date}</strong>`, 'dataset', 'actual');
      }
      _.forEach(this.$scope.model.primary.plans, (plan) =>
        this.querySummary.add(
          `Primary Plan: ${plan.displayName()} (${plan.scope})`,
          `Last Updated: <strong>${DateUtils.format(plan.lastUpdatedAt, Enums.DateFormat.GmtOffsetDateTime)}</strong> (${plan.displayName(Enums.Plan.DisplayFormat.VERSION)})`,
          'dataset',
          'primary'
        )
      );
      if (this.$scope.settings.comparison) {
        _.forEach(this.$scope.model.comparison.plans, (plan) =>
          this.querySummary.add(
            `Compare Plan: ${plan.displayName()} (${plan.scope})`,
            `Last Updated: <strong>${DateUtils.format(plan.lastUpdatedAt, Enums.DateFormat.GmtOffsetDateTime)}</strong> (${plan.displayName(Enums.Plan.DisplayFormat.VERSION)})`,
            'dataset',
            'comparison'
          )
        );
      }
      this.querySummary.add('Table Count', this.$scope.totalGridCount);
      this.querySummary.constructList();
    }

    _fetchGrids () {
      const metricFamilies = _.keys(_.groupBy(this.$scope.model.metricCategories, 'metricFamilyId'));
      return this._fetchPlanMetadata(_.includes(metricFamilies, SUMMARY_METRIC_FAMILY))
        .then((metadata) => {
          this.$scope.model.primary.planMap = fetchPlanMap(metricFamilies, metadata.primary, metadata.primarySummary);
          this.$scope.model.primary.plans = metadata.primary.concat(metadata.primarySummary);

          if (this.$scope.settings.comparison) {
            this.$scope.model.comparison.planMap = fetchPlanMap(metricFamilies, metadata.comparison, metadata.comparisonSummary);
            this.$scope.model.comparison.plans = metadata.comparison.concat(metadata.comparisonSummary);
          }

          // If no primary planMetadata is found, throw an error
          if (_.isEmpty(this.$scope.model.primary.plans)) {
            throw new Error('Plan Store: No primary plan was found.');
          }

          // Compute date expansion as per TT: https://tt.amazon.com/0151084868
          const startDates = [_.head(this.$scope.model.primary.plans).lastUpdatedAt];
          if (this.$scope.settings.comparison && !_.isEmpty(this.$scope.model.comparison.plans)) {
            startDates.push(_.head(this.$scope.model.comparison.plans).lastUpdatedAt);
          }
          if (this.$scope.settings.actuals) {
            startDates.push(this.$scope.model.actuals.date);
          }
          const dateRange = DateUtils.expansion(
            DateUtils.min(startDates).format(Enums.DateFormat.IsoDate),
            _.head(this.$scope.model.primary.plans).endDate,
            this.$scope.model.granularity.time
          );

          return this.packagerFactory.collect(
            this.packagerFactory.packagerType.network,
            dateRange,
            this.metricsService.groupByMetricFamily(this.$scope.model.metricCategories),
            this.$scope.model,
            this.$scope.settings
          );
        });
    }

    _fetchMetadata (scopes, baseMetadata, model) {
      return scopes.map((scope) =>
        this.planStore.planMetadata(
          // Compute expected cadence date for plan to make request to Plan Store
          baseMetadata(DateUtils.toSunday(model.date).format(Enums.DateFormat.IsoDate)),
          {
            alertUser: true,
            lastUpdateByTimeInISO: model.lastUpdateByTimeInISO,
            scope: scope
          }
        )
      );
    }

    _fetchPlanMetadata (includeSummary) {
      // Historically, the Network Viewer would iterate over all sub scopes when fetching plan metadata in a composite scope.
      // As part of the Pan EU project, though, this was no longer necessary as all plans are now at the EU composite level.
      // As EU is the only composite scope enabled for the Network Viewer page, iterating through scope codes is no longer necessary.
      // SIM: https://sim.amazon.com/issues/SOP-13683
      const currentScope = Configuration.scopes.current().code;
      const scopes = [currentScope];

      const primaries = this._fetchMetadata(scopes, getDailyPlanMetadata, this.$scope.model.primary);
      const comparisons = this.$scope.settings.comparison ? this._fetchMetadata(scopes, getDailyPlanMetadata, this.$scope.model.comparison) : [];

      // Set summary metrics metadata.
      let primarySummaries = [];
      let comparisonSummaries = [];
      if (includeSummary) {
        primarySummaries = this._fetchMetadata(scopes, getDailyOrderPlanMetadata, this.$scope.model.primary);
        if (this.$scope.settings.comparison) {
          comparisonSummaries = this._fetchMetadata(scopes, getDailyOrderPlanMetadata, this.$scope.model.comparison);
        }
      }
      return this.$q.all({
        comparison: this.$q.all(comparisons).then((list) => _.reject(list, (metadata) => metadata.isSourceEmpty())),
        comparisonSummary: this.$q.all(comparisonSummaries).then((list) => _.reject(list, (metadata) => metadata.isSourceEmpty())),
        primary: this.$q.all(primaries).then((list) => _.reject(list, (metadata) => metadata.isSourceEmpty())),
        primarySummary: this.$q.all(primarySummaries).then((list) => _.reject(list, (metadata) => metadata.isSourceEmpty()))
      });
    }

    _setActualsDates () {
      if (this.isSettingsLocked()) {
        return;
      }
      let earlierDate = this.$scope.model.primary.date;
      if (this.$scope.settings.comparison && DateUtils.difference(this.$scope.model.primary.date, this.$scope.model.comparison.date) > 0) {
        earlierDate = this.$scope.model.comparison.date;
      }
      this.$scope.model.actuals.date = DateUtils.fromOffset(earlierDate, DEFAULT_ACTUALS_DAYS_BACK, Enums.TimeUnit.DAY);
      this.$scope.model.actuals.maxDate = earlierDate;
      this.$scope.model.actuals.minDate = DateUtils.fromOffset(earlierDate, DAYS_BACK_TO_MIN_DATE, Enums.TimeUnit.DAY);
    }

    clearData () {
      super.clearData();
      masterset.length = 0;
    }

    download (format) {
      // If the settings are not in a valid configuration for submitting then no action should be performed
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Fetching metrics');
      this.usageMetrics.timeAndExecute('networkViewer', Enums.UsageMetrics.download, () => this._fetchGrids()
        .then((grids) => this.transformerFactory.toDocument(
          this.transformerFactory.transformerType.plan,
          format,
          grids,
          [
            {
              key: 'granularity',
              serialize: (granularity) => granularity.grains.names()
            },
            'Data Type',
            { key: 'dates' }
          ],
          [
            {
              key: 'granularity',
              serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
              source: 'pkg'
            },
            {
              key: 'dataType',
              source: 'row'
            },
            {
              key: 'values',
              source: 'row'
            }
          ]
        ))
        .then((workbook) => this.trader.download(
          workbook,
          Filename.create().forPlan(_.head(this.$scope.model.primary.plans)),
          this.trader.toExtensionType(format)
        ))
        .finally(() => this.overlay.hide())
      );
    }

    isSettingsValid () {
      return !_.isEmpty(this.$scope.model.aggregates.nodeGroup) &&
        !_.isEmpty(this.$scope.model.aggregates.node) &&
        (!_.isEmpty(this.$scope.model.filters.nodeGroup) || !_.isEmpty(this.$scope.model.filters.node)) &&
        !_.isEmpty(this.$scope.model.metricCategories) &&
        !_.isEmpty(this.$scope.model.metricFamilyGroup) &&
        !_.isNil(this.$scope.model.primary.lastUpdateByTimeInISO);
    }

    submit () {
      // If the settings are not in a valid configuration for submitting then no action should be performed
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Fetching metrics');
      this.usageMetrics.timeAndExecute('networkViewer', Enums.UsageMetrics.submit, () => this._fetchGrids()
        .then((grids) => {
          masterset.push(...grids);
          this.$scope.totalGridCount = masterset.length;
          this._constructQuerySummary();
          this._addGridsToDisplay();
          this.collapseSettings(true);
        })
        .finally(() => this.overlay.hide())
      );
    }

    $onInit () {
      const TODAY = DateUtils.format(Enums.DateFormat.IsoDate);
      super.$onInit(
        ['PLAN_SELECTION', 'FILTER_SELECTION'],
        {
          'model.actuals': () => ({}),
          'model.actuals.date': () => DateUtils.fromOffset(TODAY, DEFAULT_ACTUALS_DAYS_BACK, Enums.TimeUnit.DAY),
          'model.actuals.maxDate': () => TODAY,
          'model.actuals.minDate': () => DateUtils.fromOffset(TODAY, DAYS_BACK_TO_MIN_DATE, Enums.TimeUnit.DAY),
          'model.aggregates': () => ({}),
          'model.comparison': () => ({}),
          'model.configuration': () => ({}),
          'model.granularity': () => ({}),
          'model.granularity.grains': () => Granularities.create().addEntityGrain().addMetricGrain(),
          'model.granularity.plan': () => 'WAREHOUSE',
          'model.granularity.time': () => Enums.TimeGranularity.DAILY,
          'model.metricFamilyGroup': () => 'Inbound',
          'model.metricFamilyGroupOptions': () => this.metricsService.networkViewerMetricFamilyGroupIds(),
          'model.primary': () => ({}),
          'settings.actuals': () => true,
          'settings.comparison': () => false,
          'settings.cumulative': () => false,
          'settings.drillDown': () => Configuration.scopes.current().isComposite(),
          'settings.metricView': () => false,
          'settings.missInformation': () => true,
          totalGridCount: () => 0
        }
      );
      this.$scope.methods.onComparisonDateSelectionChange = (selectedDate) => {
        if (this.$scope.model.comparison.date !== selectedDate) {
          this.$scope.model.comparison.date = selectedDate;
          this.$scope.model.comparison.lastUpdateByTimeInISO = DateUtils.compose(selectedDate).toISOString();
        }
        this._setActualsDates();
      };

      this.$scope.methods.onPrimaryDateSelectionChange = (selectedDate) => {
        if (this.$scope.model.primary.date !== selectedDate) {
          this.$scope.model.primary.date = selectedDate;
          this.$scope.model.primary.lastUpdateByTimeInISO = DateUtils.compose(selectedDate).toISOString();
        }
        this._setActualsDates();

        // This causes various item selectors to refetch data that is versioned by the selected date.
        if (!_.isNil(selectedDate) && this.$scope.model.configuration.date !== selectedDate) {
          this.$scope.model.configuration = {
            date: selectedDate,
            type: this.metricsService.NETWORK_VIEWER_NAMESPACE
          };
        }
      };

      this.$scope.methods.onSelectMetricFamilyGroup = (group) => {
        if (_.isString(group) && this.$scope.model.metricFamilyGroup !== group) {
          this.$scope.model.metricCategories = undefined;
          this.$scope.model.metricFamilyGroup = group;
        }
      };

      this.registerShare([
        {
          // Backwards compatibility with existing user views that have the settings hierarchy
          mutation: (source) => this.$scope.methods.mutation.reassign(source, 'settings', 'states.settings'),
          persist: false
        },
        'model.filters',
        {
          key: 'model.filters.nodeGroup',
          serialize: (value) => _.map(value, (value) => _.pick(value, ['id', 'name']))
        },
        {
          deserialize: this.$scope.methods.onSelectMetricFamilyGroup.bind(this),
          key: 'model.metricFamilyGroup'
        },
        'model.metricCategories',
        'model.primary.date',
        'model.primary.lastUpdateByTimeInISO',
        'settings.actuals',
        'settings.comparison',
        'settings.cumulative',
        'settings.drillDown',
        'settings.missInformation',
        'settings.metricView',
        {
          key: 'model.actuals.date',
          persist: () => this.$scope.settings.actuals
        },
        {
          key: 'model.comparison.date',
          persist: () => this.$scope.settings.comparison
        },
        {
          key: 'model.comparison.lastUpdateByTimeInISO',
          persist: () => this.$scope.settings.comparison
        }
      ]);

      this.registerView([
        {
          // Backwards compatibility with existing user views that have the settings hierarchy
          mutation: (source) => this.$scope.methods.mutation.reassign(source, 'settings', 'states.settings'),
          persist: false
        },
        'model.filters',
        {
          key: 'model.filters.nodeGroup',
          serialize: (value) => _.map(value, (value) => _.pick(value, ['id', 'name']))
        },
        {
          deserialize: this.$scope.methods.onSelectMetricFamilyGroup.bind(this),
          key: 'model.metricFamilyGroup'
        },
        'model.metricCategories',
        'settings.actuals',
        'settings.comparison',
        'settings.cumulative',
        'settings.drillDown',
        'settings.missInformation',
        'settings.metricView',
        {
          // Do not use the 'TODAY' constant here. A user who crosses the date boundary without refreshing the page and then loads a user view will get the wrong date.
          // Isn't very likely to happen but keeping this as DateUtils.format(Enums.DateFormat.IsoDate) will prevent that from occurring.
          deserialize: () => DateUtils.format(Enums.DateFormat.IsoDate),
          key: 'model.primary.date',
          persist: false
        },
        {
          deserialize: () => DateUtils.of().toISOString(),
          key: 'model.primary.lastUpdateByTimeInISO',
          persist: false
        },
        {
          deserialize: (value) => {
            _.set(this.$scope, 'model.actuals.date', DateUtils.fromOffset(this.$scope.model.primary.date, -value, Enums.TimeUnit.DAY));
          },
          key: 'model.actuals.daysDifference',
          persist: () => this.$scope.settings.actuals,
          recover: () => this.$scope.settings.actuals,
          serialize: () => DateUtils.difference(this.$scope.model.primary.date, this.$scope.model.actuals.date, Enums.TimeUnit.DAY)
        },
        {
          deserialize: (value) => {
            _.set(this.$scope, 'model.comparison.date', DateUtils.fromOffset(this.$scope.model.primary.date, -value, Enums.TimeUnit.DAY));
          },
          key: 'model.comparison.daysDifference',
          persist: () => this.$scope.settings.comparison,
          recover: () => this.$scope.settings.comparison,
          serialize: () => DateUtils.difference(this.$scope.model.primary.date, this.$scope.model.comparison.date, Enums.TimeUnit.DAY)
        },
        {
          deserialize: () => DateUtils.compose(this.$scope.model.comparison.date).toISOString(),
          key: 'model.comparison.lastUpdateByTimeInISO',
          persist: false,
          recover: () => this.$scope.settings.comparison
        },
        {
          persist: false,
          source: 'name',
          target: 'viewName'
        }
      ]);

      this._checkDataDelayStatus();
    }
  }
  angular.module('application.controllers').controller('NetworkViewerController', NetworkViewerController);
})();
