/* globals Enums */
(function () {
  'use strict';

  const DEFAULT_TASK_STATUS_CSS_CLASS = 'default';
  const TASK_STATUS_CSS_CLASS_MAP = Object.freeze({
    [Enums.TaskStatus.ABORTED]: 'warning',
    [Enums.TaskStatus.DELAYED]: 'danger',
    [Enums.TaskStatus.FAILED]: 'danger',
    [Enums.TaskStatus.FAILURE]: 'danger',
    [Enums.TaskStatus.IN_PROGRESS]: 'info',
    [Enums.TaskStatus.NO_EXECUTIONS]: 'warning',
    [Enums.TaskStatus.RUNNING]: 'info',
    [Enums.TaskStatus.SKIPPED]: 'disabled',
    [Enums.TaskStatus.SUCCEEDED]: 'success',
    [Enums.TaskStatus.SUCCESS]: 'success',
    [Enums.TaskStatus.SUCCESS_WITH_FAILURE]: 'warning',
    [Enums.TaskStatus.TIMED_OUT]: 'danger'
  });

  class TaskStatus {
    static statusClass (taskStatus, prefix) {
      const selector = TASK_STATUS_CSS_CLASS_MAP[taskStatus] || DEFAULT_TASK_STATUS_CSS_CLASS;
      return _.isEmpty(prefix) ? selector : `${prefix}-${selector}`;
    }
  }

  // Add "isX" static function for each member of Enums.TaskStatus
  _.forEach(Enums.TaskStatus, (value, key) => {
    const property = _.camelCase(`IS_${key}`);
    TaskStatus[property] = (taskStatus) => Enums.TaskStatus[key] === taskStatus;
  });

  window.TaskStatus = Object.freeze(TaskStatus);
})();
