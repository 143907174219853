/* globals AbstractElementComponent, Ready, Shortcut */
(function () {
  'use strict';

  class ViewsListController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', 'shortcut', 'view', '$window'];
    }

    constructor (alerts, $authentication, overlay, shortcut, view, $window) {
      super();
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.shortcut = shortcut;
      this.view = view;
      this.$window = $window;

      this.state = Ready.create({
        isDataEmptyFn: () => !_.isUndefined(this.userViews) && _.isEmpty(this.userViews),
        isLoadingFn: () => this.loading
      });
    }

    _fetchUserViews () {
      this.loading = true;
      // Make the view.allViews() call with options containing alias only for user lookup.
      const options = this.isMyProfile ? undefined : { alias: this.alias };
      this.view.allViews(null, this.scope, options)
        .then((views) => {
          this.userViews = [];
          views.personal.forEach((view) => {
            if (_.isNil(view.baseUrl)) {
              return;
            }
            view.page = _.get(this.shortcut.getRouteByPath(view.baseUrl), 'name');
            view.isPublicLabel = view.isPublic ? 'Yes' : 'No';
            this.userViews.push(view);
          });
          this.onLoadViews({ count: this.userViews.length });
          this.loading = false;
        })
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to load views for scope ${this.scope} and user ${this.alias}. Please check error details before retrying.` }))
        .finally(() => this.loading = false);
    }

    _loadData () {
      if (!this.enableLoading) {
        return;
      }
      this.isMyProfile = this.alias === this.$authentication.profile().alias;
      this.userViews = undefined;
      this._fetchUserViews();
    }

    deleteView (viewToDelete) {
      this.overlay.show(`Deleting user view '${viewToDelete.name}' on the ${this.scope} ${viewToDelete.page} page`);
      this.view.delete(viewToDelete)
        .then(() => this.alerts.success(`User View '${viewToDelete.name}' deleted successfully.`))
        .then(() => _.remove(this.userViews, (view) => viewToDelete.identifier === view.identifier))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to delete user view. Please check error details before retrying.' }))
        .finally(() => this.overlay.hide());
    }

    getDeleteConfirmationMessage (viewName, page, isPublic) {
      const confirmationMessage = isPublic
        ? `View <strong>${viewName}</strong> on the ${page} page is a public view visible to all ${this.scope} users. Are you sure you want to delete the view?`
        : `Are you sure you want to delete view <strong>${viewName}</strong> on the ${page} page?`;
      return `${confirmationMessage} This action cannot be undone.`;
    }

    getViewTooltip (view) {
      if (this.$authentication.hasDeveloperAccess()) {
        return view.identifier;
      }
    }

    loadUserView (event, baseUrl, identifier) {
      this.stopPropagation(event);
      this.shortcut.openShortcut(Shortcut.createByProperties(this.scope, baseUrl, identifier));
    }

    stopPropagation (event) {
      event.stopPropagation();
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('viewsList', {
      bindings: {
        alias: '<',
        enableLoading: '<',
        onLoadViews: '&',
        scope: '@'
      },
      controller: ViewsListController,
      templateUrl: 'templates/components/views-list.component.html'
    });
})();
