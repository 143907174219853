'use strict';
angular.module('application.components')
  .component('stModelSearchInput', {
    bindings: {
      onValueChange: '&',
      property: '@',
      value: '<'
    },
    templateUrl: 'templates/components/st-model-search-input.component.html'
  });
