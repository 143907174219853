/* globals Configuration */
/***
 * This file defines various configuration blocks that are intended to be temporary solutions related to
 * the Allocation Tree until a more optimal strategy for acquiring this information is made available.
 * This entire file can be viewed as a series of TODOs to find better ways to acquire this configuration.
 ***/
(function () {
  'use strict';
  const FLOW_NEEDLES = Configuration.flowNeedles;

  const ALLOCATION_TREE_TYPES = Object.freeze([
    Object.freeze({
      id: 'AllocationTreeEditor_BinTypeFcWeeklyPlan',
      name: 'BE, NIG, BT, FC',
      planType: 'binTypeFcWeeklyPlan',
      treeType: 'bin-type-allocation-tree'
    }),
    Object.freeze({
      id: 'AllocationTreeEditor_PlStFcWeeklyPlan',
      name: 'PL, ST, FC',
      planType: 'plStFcWeeklyPlan',
      treeType: 'allocation-tree'
    })
  ]);

  const ALLOCATION_TREE_VIEW_OPTIONS = Object.freeze({
    binTypeFcWeeklyPlan: Object.freeze([
      Object.freeze({
        for: (metricId) => !_.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'node'],
        groupByFilters: () => ({}),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_FC',
        name: 'BE, NIG, FC'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'node', 'secondaryNode'],
        groupByFilters: (secondaryScope) => ({ secondaryScope: [secondaryScope] }),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_Arc_Inbound',
        name: 'BE, NIG, Arc (Inbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'node', 'secondaryNode'],
        groupByFilters: (scope) => ({ scope: [scope] }),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_Arc_Outbound',
        name: 'BE, NIG, Arc (Outbound)'
      }),
      Object.freeze({
        for: (metricId) => !_.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup', 'binType'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'binType', 'node'],
        groupByFilters: () => ({}),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_BT_FC',
        name: 'BE, NIG, BT, FC'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup', 'binType'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'binType', 'node', 'secondaryNode'],
        groupByFilters: (secondaryScope) => ({ secondaryScope: [secondaryScope] }),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_BT_Arc_Inbound',
        name: 'BE, NIG, BT, Arc (Inbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer),
        gridGrouping: ['businessEntity', 'networkInventoryGroup', 'binType'],
        groupBy: ['businessEntity', 'networkInventoryGroup', 'binType', 'node', 'secondaryNode'],
        groupByFilters: (scope) => ({ scope: [scope] }),
        id: 'PlanEditorGroupByOption_BinTypeFcWeeklyPlan_BE_NIG_BT_Arc_Outbound',
        name: 'BE, NIG, BT, Arc (Outbound)'
      })
    ]),
    plStFcWeeklyPlan: Object.freeze([
      Object.freeze({
        for: (metricId) => !_.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['planningGroup'],
        groupBy: ['planningGroup', 'node'],
        groupByFilters: () => ({}),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_PlanningGroups',
        name: 'Planning Groups'
      }),
      Object.freeze({
        for: (metricId) => !_.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['sortType'],
        groupBy: ['sortType', 'node'],
        groupByFilters: () => ({}),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_FC',
        name: 'ST, FC'
      }),
      Object.freeze({
        for: (metricId) => !_.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['productLine', 'sortType'],
        groupBy: ['productLine', 'sortType', 'node'],
        groupByFilters: () => ({}),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_PL_ST_FC',
        name: 'PL, ST, FC'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['sortType'],
        groupBy: ['sortType', 'node', 'secondaryNode'],
        groupByFilters: (secondaryScope) => ({ secondaryScope: [secondaryScope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_Arc_Inbound',
        name: 'ST, Arc (Inbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['sortType'],
        groupBy: ['sortType', 'node', 'secondaryNode'],
        groupByFilters: (scope) => ({ scope: [scope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_Arc_Outbound',
        name: 'ST, Arc (Outbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['sortType', 'businessEntityGroup'],
        groupBy: ['sortType', 'businessEntityGroup', 'node', 'secondaryNode'],
        groupByFilters: (secondaryScope) => ({ secondaryScope: [secondaryScope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_BEG_Arc_Inbound',
        name: 'ST, BEG, Arc (Inbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['sortType', 'businessEntityGroup'],
        groupBy: ['sortType', 'businessEntityGroup', 'node', 'secondaryNode'],
        groupByFilters: (scope) => ({ scope: [scope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_ST_BEG_Arc_Outbound',
        name: 'ST, BEG, Arc (Outbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['productLine', 'sortType'],
        groupBy: ['productLine', 'sortType', 'node', 'secondaryNode'],
        groupByFilters: (secondaryScope) => ({ secondaryScope: [secondaryScope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_PL_ST_Arc_Inbound',
        name: 'PL, ST, Arc (Inbound)'
      }),
      Object.freeze({
        for: (metricId) => _.sop.includesSome(metricId, FLOW_NEEDLES.mod, FLOW_NEEDLES.transfer, FLOW_NEEDLES.transshipment),
        gridGrouping: ['productLine', 'sortType'],
        groupBy: ['productLine', 'sortType', 'node', 'secondaryNode'],
        groupByFilters: (scope) => ({ scope: [scope] }),
        id: 'PlanEditorGroupByOption_PlStFcWeeklyPlan_PL_ST_Arc_Outbound',
        name: 'PL, ST, Arc (Outbound)'
      })
    ])
  });

  Configuration
    .defineProperty('allocationTreeTypes', ALLOCATION_TREE_TYPES)
    .defineProperty('allocationTreeViewOptions', ALLOCATION_TREE_VIEW_OPTIONS);
})();
