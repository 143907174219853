/* globals DateUtils, Enums */
(function () {
  'use strict';
  const ONE_DAY_IN_MILLISECONDS = 86400000;

  // Message type 'success' also exists but was skipped here as there would be no sensible
  // reason for users to select it for an Announcement. 'info' can be used instead.
  const MESSAGE_TYPES = Object.freeze([
    {
      text: 'Default',
      value: 'info'
    },
    {
      text: 'Warning',
      value: 'warning'
    },
    {
      text: 'Critical',
      value: 'danger'
    }
  ]);

  function template (values = {}) {
    const epoch = DateUtils.epoch();
    return _.defaults(values, {
      details: '',
      endTime: epoch + ONE_DAY_IN_MILLISECONDS,
      message: 'This is an announcement message.<br>HTML tags can be used to make text <b>bold</b> or <i>italic</i> or create a <a href="https://portal.sandop.a2z.com" target="_blank" rel="noopener">hyperlink</a>',
      messageType: 'info',
      paths: [],
      scopes: [],
      startTime: epoch,
      updatedBy: ''
    });
  }

  class AnnouncementModalController {
    static get $inject () {
      return ['$authentication'];
    }

    constructor ($authentication) {
      this.$authentication = $authentication;
      this.allMessageTypes = MESSAGE_TYPES;
      this.startDateSelection = undefined;
      this.startTimeSelection = undefined;
      this.endDateSelection = undefined;
      this.endTimeSelection = undefined;
    }

    areInputsInvalid () {
      return this.isMessageInvalid() || this.isScopesSelectionInvalid() || this.isPathsSelectionInvalid() || this.isDateRangeInvalid();
    }

    isMessageInvalid () {
      return _.isEmpty(this.announcement.message);
    }

    isScopesSelectionInvalid () {
      return _.isEmpty(this.announcement.scopes) && !this.announcement.selectAllScopes;
    }

    isPathsSelectionInvalid () {
      return _.isEmpty(this.announcement.paths) && !this.announcement.selectAllPaths;
    }

    isDateRangeInvalid () {
      return this.announcement.endTime <= this.announcement.startTime;
    }

    isCreate () {
      return this.resolve.action === 'create';
    }

    isUpdate () {
      return this.resolve.action === 'update';
    }

    messageTypeDisplayNameFn (messageType) {
      return messageType.text;
    }

    onMessageTypeChange (selection) {
      this.selectedMessageType = selection;
      this.announcement.messageType = selection.value;
    }

    onPathsSelectionChange (list) {
      this.announcement.selectAllPaths = _.isEmpty(_.xor(list, this.pathsList));
      this.announcement.paths = this.announcement.selectAllPaths ? [] : _.map(Array.from(list), 'id');
    }

    onScopesSelectionChange (list) {
      this.announcement.selectAllScopes = _.isEmpty(_.xor(list, this.scopesList));
      this.announcement.scopes = this.announcement.selectAllScopes ? [] : _.map(Array.from(list), 'id');
    }

    onStartDateSelectionChange (selectedDate) {
      if (this.startDateSelection !== selectedDate) {
        this.startDateSelection = selectedDate;
        this.startTimeSelection = DateUtils.format(
          `${selectedDate} ${DateUtils.format(this.startTimeSelection, Enums.DateFormat.HourMinuteTime)}`,
          Enums.DateFormat.ISO8601);
      }
    }

    onStartTimeSelectionChange (selectedTime) {
      this.startTimeSelection = selectedTime;
      this.announcement.startTime = DateUtils.epoch(this.startTimeSelection);
    }

    onEndDateSelectionChange (selectedDate) {
      if (this.endDateSelection !== selectedDate) {
        this.endDateSelection = selectedDate;
        this.endTimeSelection = DateUtils.format(
          `${selectedDate} ${DateUtils.format(this.endTimeSelection, Enums.DateFormat.HourMinuteTime)}`,
          Enums.DateFormat.ISO8601);
      }
    }

    onEndTimeSelectionChange (selectedTime) {
      this.endTimeSelection = selectedTime;
      this.announcement.endTime = DateUtils.epoch(this.endTimeSelection);
    }

    saveAnnouncement () {
      this.announcement.updatedBy = this.$authentication.profile().alias;
      this.modalInstance.close(this.announcement);
    }

    $onInit () {
      if (this.isCreate() && _.isNil(this.resolve.selectedAnnouncement)) {
        this.announcement = template();
      } else {
        const selectedAnnouncement = this.resolve.selectedAnnouncement;
        this.announcement = template({
          details: selectedAnnouncement.details,
          endTime: selectedAnnouncement.endTime,
          message: selectedAnnouncement.message,
          messageType: selectedAnnouncement.messageType,
          paths: selectedAnnouncement.paths,
          scopes: selectedAnnouncement.scopes,
          startTime: selectedAnnouncement.startTime
        });
        if (this.isUpdate()) {
          this.announcement.key = selectedAnnouncement.key;
        }
      }
      this.selectedMessageType = _.find(MESSAGE_TYPES, ['value', this.announcement.messageType]);
      this.startDateSelection = DateUtils.format(this.announcement.startTime, Enums.DateFormat.IsoDate);
      this.startTimeSelection = DateUtils.format(this.announcement.startTime, Enums.DateFormat.ISO8601);
      this.endDateSelection = DateUtils.format(this.announcement.endTime, Enums.DateFormat.IsoDate);
      this.endTimeSelection = DateUtils.format(this.announcement.endTime, Enums.DateFormat.ISO8601);
    }
  }
  angular.module('application.components')
    .component('announcementModal', {
      bindings: {
        modalInstance: '<',
        resolve: '<'
      },
      controller: AnnouncementModalController,
      templateUrl: 'templates/components/announcement-modal.component.html'
    });
})();
