/* globals AbstractPackagerService, Configuration, DataCell, DataPackage, DataRow, Enums, Granularities */
(function () {

  class NetworkPlannerSummaryPackagerService extends AbstractPackagerService {

    static get $inject () {
      return ['$q'];
    }

    constructor ($q) {
      super($q);
    }

    _isActual (index) {
      return index < Configuration.networkPlannerDateRange.actuals;
    }

    _filterRecords (records, granularity, granularityValue) {
      return _(records)
        .filter((record) => record.granularity.draft === Configuration.networkPlannerDrafts.EDITS)
        .map((record) => DataRow.create({
          granularity: { [granularity]: granularityValue },
          type: Enums.DataType.PRIMARY,
          values: record.cells
        })).value();
    }

    _getValues (rows) {
      return _.map(_.head(rows).cells, (dataCell, index) => DataCell.create({
        dataSource: dataCell.dataSource,
        dataType: this._isActual(index) ? Enums.DataType.ACTUAL : Enums.DataType.PRIMARY,
        value: _.round(_.sumBy(rows, (row) => row.cells[index].value))
      }));
    }

    _getRecords (dataPackages, granularity) {
      return _(dataPackages)
        .flatMap((dataPackage) => this._filterRecords(dataPackage.records, granularity, dataPackage.filters[granularity]))
        .groupBy((record) => record.granularity[granularity])
        .map((rows, granularityValue) => DataRow.create({
          dataType: Enums.DataType.PRIMARY,
          granularity: { [granularity]: granularityValue },
          metric: { dataType: Enums.DataType.PRIMARY },
          type: Enums.DataType.PRIMARY,
          values: this._getValues(rows)
        })).value();
    }

    /**
     * Constructs summary view of edits for a given set of dataPackages.
     *
     * @dataPackages DataPackagesList
     * @config object containing:
     *  @displayName String the display name of the plan
     *  @granularity String the granularity to group the dataPackages at
     *
     * @returns DataPackage
     */
    collect (dataPackages, config) {
      const head = _.head(dataPackages);
      const records = this._getRecords(dataPackages, config.granularity);
      const totals = _.isEmpty(records) ? [] : [DataRow.create({
        granularity: { [config.granularity]: Enums.AggregateType.TOTALS },
        metric: { dataType: Enums.DataType.PRIMARY },
        type: Enums.DataType.PRIMARY,
        values: this._getValues(records)
      })];
      return DataPackage.create({
        dates: head.dates,
        editType: Enums.DataPackage.EditType.UNIT,
        flow: head.flow,
        granularity: {
          grains: Granularities.create()[_.camelCase(`add_${config.granularity}Grain`)](),
          totalsGrains: Granularities.create()[_.camelCase(`add_${config.granularity}Grain`)]()
        },
        metrics: head.metrics,
        plan: head.plan,
        records: records,
        title: `Summary ${config.displayName}`,
        totals: totals
      }, this.$q);
    }
  }

  angular.module('application.services').service('networkPlannerSummaryPackager', NetworkPlannerSummaryPackagerService);
})();
