/* globals AbstractElementComponent, Configuration, PlanMetadata */
(function () {
  'use strict';

  /*
   * TODO: As of now, PlanMetadata isCapacityPlan() method is being used to verify plan types against a hardcoded list
   *       of plans being classified as Capacity plans. This is a temporarily solution and would exist on the Portal side
   *       until a namespace/tenant property is added in Plan Definition that classifies a plan as Capacity plan.
   */
  function capacityPlansFilter (planDefinition) {
    return (Configuration.scopes.current().isOfTenantCapacity() && PlanMetadata.isCapacityPlan(planDefinition.planType))
      || (!Configuration.scopes.current().isOfTenantCapacity() && !PlanMetadata.isCapacityPlan(planDefinition.planType));
  }

  class CreatePlanTypeSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', 'planConfiguration'];
    }

    constructor ($element, planConfiguration) {
      super($element);
      this.planConfiguration = planConfiguration;
    }

    _loadData () {
      this.planConfiguration.planDefinition().list()
        .then((list) => {
          this.options = _.filter(list, (planDefinition) => planDefinition.isBringYourOwnPlan() && capacityPlansFilter(planDefinition));
          this.onSelectionChangeHandler(_.head(this.options));
        })
        .catch(() => this.options = []);
    }

    onSelectionChangeHandler (selection) {
      this.selected = selection;
      this.onSelectionChange({ selection: this.selected.planType });
    }
  }

  angular.module('application.components')
    .component('createPlanTypeSelector', {
      bindings: {
        onSelectionChange: '&'
      },
      controller: CreatePlanTypeSelectorController,
      templateUrl: 'templates/components/create-plan-type-selector.component.html'
    });
})();
