/* globals AbstractPlanViewerProviderService, Configuration, DateUtils, Enumeration, Enums, Name, PlanMetadata, Ready */
(function () {
  'use strict';
  const DEFAULT_COMPARISONS = 2;
  // This Array represents the amount of weeks back to pull a plan from for the first and second comparisons respectively.
  const DEFAULT_COMPARISON_WEEKS_BACK = Object.freeze([1, 3]);
  const DEFAULT_WEEKS_BACK = Enumeration
    .create('FORECASTS', 'ACTUALS_FORECASTS')
    .asIndexValue(0);
  const PLAN_VIEWER_ID = 'weeklyPlanViewer';
  const UNDEFINED_STRING = 'Undefined';

  /*
   * TODO: As of now, PlanMetadata isCapacityPlan() method is being used to verify plan types against a hardcoded list
   *       of plans being classified as Capacity plans. This is a temporarily solution and would exist on the Portal side
   *       until a namespace/tenant property is added in Plan Definition that classifies a plan as Capacity plan.
   */
  function capacityPlansFilter (plan) {
    return (Configuration.scopes.current().isOfTenantCapacity() && plan.isCapacityPlan())
      || (!Configuration.scopes.current().isOfTenantCapacity() && !plan.isCapacityPlan());
  }

  class WeeklyPlanViewerProviderService extends AbstractPlanViewerProviderService {
    static get $inject () {
      return ['alerts'];
    }

    constructor (alerts) {
      super();
      this.alerts = alerts;
    }

    get defaultComparisons () {
      return DEFAULT_COMPARISONS;
    }

    get defaultDatesBack () {
      return DEFAULT_WEEKS_BACK;
    }

    get id () {
      return PLAN_VIEWER_ID;
    }

    get stateDefinition () {
      const activeHistoricalSupportPlans = Configuration.historicalSupportPlans.filter((plan) => plan.id.startsWith(_.upperFirst(this.id)) && Configuration.activation.isAvailable(plan.id));
      return {
        'model.actuals.dateRangeLength': () => this.defaultDatesBack.ACTUALS_FORECASTS,
        'model.actuals.datesBack': () => this.defaultDatesBack.ACTUALS_FORECASTS,
        'model.actuals.endDate': () => ({}),
        'model.actuals.startDate': () => ({}),
        'model.actualsComparisons': () => _.times(this.defaultComparisons, (index) => ({ datasetClass: `actual-comparison ${Name.ofOrdinal(index + 1)}` })),
        'model.forecast.maxDate': () => DateUtils.fromOffset(DateUtils.toSunday(), 1),
        'model.forecastComparisons': () => _.times(this.defaultComparisons, (index) => ({ datasetClass: `comparison ${Name.ofOrdinal(index + 1)}` })),
        'model.historicalForecast': () => ({}),
        'model.historicalSupportPlans.list': () => activeHistoricalSupportPlans,
        'model.historicalSupportPlans.selected': () => _.head(activeHistoricalSupportPlans),
        'model.modes.comparison.list': () => Enumeration.values(Enums.DataPackage.ComparisonMode),
        'model.modes.comparison.selected': () => Enums.DataPackage.ComparisonMode.UNIT,
        'model.modes.data.list': () => Enumeration.values(Enums.DataPackage.DataMode),
        'model.modes.data.selected': () => Enums.DataPackage.DataMode.UNIT,
        'model.modes.view.list': () => Enumeration.values(Enums.DataPackage.ViewMode),
        'model.modes.view.selected': () => Enums.DataPackage.ViewMode.GRID,
        'model.timeUnit': () => Enums.TimeUnit.WEEK,
        'model.viewOptions.list': () => Enumeration.values(this.viewOption),
        'model.viewOptions.selected': () => this.viewOption.ACTUALS_FORECASTS,
        'settings.actuals': () => true,
        'settings.actualsComparisonFilterFn': () => undefined,
        'settings.actualsComparisonInitialSelections': () => Array(this.defaultComparisons).fill(),
        'settings.actualsComparisons': () => Array(this.defaultComparisons).fill(false),
        'settings.comparisonDatesBack': () => Configuration.planHorizonInWeeks[Configuration.scopes.current().code],
        'settings.comparisonDefaultSelections': () => DEFAULT_COMPARISON_WEEKS_BACK,
        'settings.forecastComparisonFilterFn': () => undefined,
        'settings.forecastComparisons': () => Array(this.defaultComparisons).fill(false),
        'settings.forecastFilterFn': () => ((plan) => !plan.isDailyPlan() && !plan.isHistoricalPlan() && capacityPlansFilter(plan)),
        'settings.forecasts': () => true
      };
    }

    get timeGrain () {
      return Enums.TimeGranularity.WEEKLY;
    }

    get timeUnit () {
      return Enums.TimeUnit.WEEK;
    }

    // Sundays/Mondays when FcWeekly forecast plans are not available yet, having no FcWeekly plan selected as Forecast plan blocks the historical plan selectors from loading and being selected.
    // As a result, a pseudo plan is created to unblock Actuals and Historical Plan comparisons/analysis on Sundays/Mondays.
    // Typically, this plan will not be used to fetch forecasts. But if saved as a user view in the time window Sundays - Mondays and applied outside of that time window,
    // the expected behavior is to apply the user view in 'ACTUALS_FORECASTS' mode as the forecast plans are now available.
    // In the 'ACTUALS_FORECASTS' mode, this pseudo plan will be compared (draft, scenario, subscenario, type) to the list of plans fetched from Plan Store when applying the user view. As a result,
    // even if it acts like a pseudo plan in a non user view scenario, it should always have the properties [ draft, scenario, subscenario, type ] for a valid comparison with list of plans.
    _createPseudoPlan (scope, plan) {
      return PlanMetadata.create({
        date: scope.model.forecast.date,
        draft: Enums.Plan.DraftType.FINAL,
        scenario: PlanMetadata.defaultScenario.scenario,
        schemaName: _.isNil(plan) ? UNDEFINED_STRING : plan.schemaName,
        scope: Configuration.scopes.current().code,
        subScenario: PlanMetadata.defaultScenario.subScenario,
        type: _.isNil(plan) ? UNDEFINED_STRING : plan.planType
      });
    }

    _setHistoricalForecastDate (scope) {
      // Clear the Historical forecast plans so they do not get interpreted as initial plan by the selector.
      _.forEach(scope.model.actualsComparisons, (comparison) => comparison.plan = undefined);
      scope.model.historicalForecast.date = DateUtils.fromOffset(scope.model.actuals.endDate.date, 1);
    }

    configureMethods (controller) {
      super.configureMethods(controller);
      const scope = controller.$scope;
      Object.assign(scope.methods, {
        changeActualsComparisonPlan: (plan, index) => {
          scope.model.actualsComparisons[index].plan = plan;
          if (_.isUndefined(plan) && scope.settings.actualsComparisons[index]) {
            scope.settings.actualsComparisons[index] = false;
            if (controller.isSettingsLocked()) {
              this.alerts.info('Actuals comparison not available due to unavailability of historical plans');
            }
          }
          if (!_.isUndefined(plan)) {
            scope.model.historicalForecast.startDate = DateUtils.fromOffset(plan.date, -1 * scope.settings.comparisonDatesBack);
            scope.model.historicalForecast.endDate = DateUtils.fromOffset(plan.date, -1);
          }
        },
        changeForecastDate: (date) => {
          if (controller.isSettingsLocked() || date === scope.model.forecast.date) {
            return;
          }
          scope.model.forecast.date = date;

          // Actuals start date, end date and the configured Min Max dates need to be reset based on the updated Forecast date.
          this.resetActualsDates(scope);

          // If the forecast plan is non-nil, create a plan copy with just 'draft', 'scenario', 'subScenario' and 'type' properties. This goes in as the initial plan to the plan-to-view selector. If a similar plan is present for the newly selected date, it would get selected. This assures that the same plan type (if available) stays selected as we change dates and the Flow / Group By / Filters selections are not reset in the process. SIM: https://issues.amazon.com/issues/SOP-8488.
          scope.model.forecast.plan = !_.isUndefined(scope.model.forecast.plan) ? PlanMetadata.create(_.pick(scope.model.forecast.plan, this.savedViewPlanFields)) : undefined;

          // Clear the actuals compare filter to avoid 'historicalPlanSelector' components reloading twice (once for date change. again for filters change).
          // Actuals compare filter will be set once the Forecast plan is set (triggered by date change).
          scope.settings.actualsComparisonFilterFn = undefined;

          // Reset all comparison.date to make sure historicalDateSelector defaults to the expected '1 Week Ago' / 2nd item in the list.
          // Without this, historicalWeekDateSelector defaults to initialDate which might not be '1 Week Ago' / 2nd item in the list.
          scope.model.forecastComparisons.forEach((comparison) => comparison.date = undefined);
        },
        changeForecastPlan: (plan) => {
          // If no forecast plans are available to select, 'plan' will be undefined.
          if (_.isUndefined(plan)) {
            // If 'plan' is undefined and settings are locked, view / shared url cannot be applied as is or at all.
            if (controller.isSettingsLocked()) {
              if (!_.isNil(scope.model.forecast.fallbackDate)) {
                // If no forecast plans were found for the original forecast date and fallbackDate is non nil, this is first attempt of applying the Saved View.
                // Try again by using fallbackDate as the forecast date.
                scope.model.forecast.date = scope.model.forecast.fallbackDate;

                // Update actual dates and forecast comparison dates to reflect the forecast fallback date change.
                this.resetActualsDates(scope, scope.model.actuals.datesBack);
                scope.model.forecastComparisons.forEach((comparison) => comparison.date = DateUtils.fromOffset(scope.model.forecast.date, -comparison.weeksDifference));

                // Make fallbackDate undefined so the forecast date fallback attempt happens only once.
                scope.model.forecast.fallbackDate = undefined;
                this.alerts.info('Forecast plans are unavailable in the current week. Falling back to the previous week');
                return;
              }
              if (scope.methods.isViewOptionSelected(this.viewOption.ACTUALS) && scope.settings.actuals) {
                // If selected viewOptions is already 'ACTUALS', this is coming from a Shared Url with 'ACTUALS' mode selection.
                scope.methods.selectViewOption(this.viewOption.ACTUALS, scope.model.forecast.date, scope.model.actuals.datesBack);
                return;
              }
              // If 'plan' is undefined and no fallbackDate is available, view / shared url cannot be applied.
              this.alerts.info({
                details: 'Forecast plans are unavailable.',
                message: 'View / Shared Url could not be applied.'
              });
              scope.settings.forecastComparisons = Array(this.defaultComparisons).fill(false);
            }
            scope.model.forecast.plan = undefined;
            scope.settings.actualsComparisonFilterFn = () => false;
            scope.settings.forecastComparisonFilterFn = () => false;
            return;
          }

          if (!controller.isSettingsLocked()) {
            if (plan.schemaName !== _.get(scope.model.forecast.plan, 'schemaName')) {
              // If the plan schema has changed, clear the comparison plans
              scope.model.actualsComparisons.forEach((comparison) => comparison.plan = undefined);
              scope.model.forecastComparisons.forEach((comparison) => comparison.plan = undefined);
            }

            if (plan.type !== _.get(scope.model.forecast.plan, 'type')) {
              // If the plan type has changed, clear settings that are filtered by the plan type
              scope.model.metricFamilies = undefined;
              scope.model.filters = {};
              scope.model.granularity = {};

              // uib-tabset does not support dynamic tabs and thus the 'active' tab does not get updated
              // when a plan changes and the granularities are changed. Wait for updated grains to arrive and then
              // set the active tab.
              Ready.once(
                controller.$scope,
                () => !_.isNil(scope.model.granularity.allGrains),
                () => scope.activeTab = _.get(_.minBy(scope.model.granularity.allGrains.granularities, 'filterIndex'), 'id')
              );
            }
          }

          scope.model.forecast.plan = plan;
          scope.settings.actualsComparisonFilterFn = (comparisonPlan) =>
            comparisonPlan.type === plan.type &&
            comparisonPlan.scenario === plan.scenario &&
            comparisonPlan.isWeeklyHistoricalPlan();
          scope.settings.forecastComparisonFilterFn = (comparisonPlan) =>
            comparisonPlan.schemaName === plan.schemaName &&
            !comparisonPlan.isHistoricalPlan() &&
            capacityPlansFilter(comparisonPlan);
        },
        changeHistoricalSupportPlan: (plan) => {
          if (plan === scope.model.historicalSupportPlans.selected) {
            return;
          }
          scope.model.historicalSupportPlans.selected = plan;
          scope.methods.changeForecastPlan(this._createPseudoPlan(scope, plan));
        },
        isActualsComparePlanSelectable: (index) => scope.settings.actualsComparisons[index],
        isActualsCompareToggleDisabled: (index) => {
          if (!scope.settings.actuals) {
            return true;
          }
          if (scope.settings.actualsComparisons[index]) {
            return !_.isNil(scope.settings.actualsComparisons[index + 1]) && scope.settings.actualsComparisons[index + 1];
          }
          return !_.isNil(scope.settings.actualsComparisons[index - 1]) && !scope.settings.actualsComparisons[index - 1];
        },
        isChartingAvailable: () => true,
        isCompareModeEnabled: () => {
          const isCompareModeEnabled = scope.model.modes.data.selected === Enums.DataPackage.DataMode.UNIT &&
            (
              scope.methods.isActualsComparePlanSelectable(0) ||
              scope.methods.isForecastComparePlanSelectable(0) ||
              scope.settings.includeYoYActuals.PY ||
              scope.settings.includeYoYActuals.PPY
            );
          if (!isCompareModeEnabled) {
            scope.model.modes.comparison.selected = Enums.DataPackage.ComparisonMode.UNIT;
          }
          return isCompareModeEnabled;
        },
        isHistoricalPlanDateRangeVisible: (index) => scope.settings.actualsComparisons[index] && !_.isNil(scope.model.actualsComparisons[index].plan),
        isValidActualsComparisonPlanSelection: () => {
          if (controller.isSettingsLocked()) {
            return _.every(
              scope.settings.actualsComparisons,
              (comparison, index) => {
                const selection = scope.settings.actualsComparisonInitialSelections[index] || scope.settings.comparisonDefaultSelections[index];
                return !comparison || (!_.isNil(scope.model.actualsComparisons[index].plan) && scope.model.actualsComparisons[index].plan.historicalWeek === selection);
              }
            );
          }
          return _.every(
            scope.settings.actualsComparisons,
            (comparison, index) => !comparison || (!_.isNil(scope.model.actualsComparisons[index].plan) && scope.model.actualsComparisons[index].plan.isValid())
          );
        },
        isValidPlanSelection: () => !_.isNil(scope.model.forecast.plan) && (scope.model.forecast.plan.isValid() || !scope.settings.forecasts) &&
          scope.methods.isValidActualsComparisonPlanSelection() && scope.methods.isValidForecastComparisonPlanSelection(),
        selectViewOption: (viewOption, forecastDate = DateUtils.toSunday().format(Enums.DateFormat.IsoDate), weeksBack = scope.settings.comparisonDatesBack) => {
          let pseudoPlan;
          switch (viewOption) {
            case this.viewOption.ACTUALS:
              scope.settings.actuals = true;
              scope.settings.forecasts = false;
              // When 'ACTUALS' mode is manually selected or for Saved Views, Forecast date is the current week's Sunday.
              // For Shared Urls, Forecast date is the date saved in the Shared Url data.
              scope.model.forecast.date = forecastDate;
              pseudoPlan = this._createPseudoPlan(scope, scope.model.historicalSupportPlans.selected);
              scope.methods.changeForecastPlan(pseudoPlan);
              // When 'ACTUALS' mode is manually selected or for Saved Views, weeksBack is set to a default value.
              // For Shared Urls, weeksBack should be the value saved in the Shared Url data.
              this.resetActualsDates(scope, weeksBack, viewOption);
              // Unblock valid plan selection check by turning off Forecast comparisons
              scope.settings.forecastComparisons = Array(this.defaultComparisons).fill(false);
              break;
            case this.viewOption.ACTUALS_FORECASTS:
              scope.settings.actuals = true;
              scope.settings.forecasts = true;
              scope.model.forecast.plan = scope.methods.isViewOptionSelected(this.viewOption.ACTUALS) ? undefined : scope.model.forecast.plan;
              // Actuals start date, end date and the configured Min Max dates need to be reset based on the Forecast date and view option.
              this.resetActualsDates(scope, this.defaultDatesBack.ACTUALS_FORECASTS, viewOption);
              break;
            case this.viewOption.FORECASTS:
              scope.settings.actuals = false;
              scope.settings.forecasts = true;
              scope.model.forecast.plan = scope.methods.isViewOptionSelected(this.viewOption.ACTUALS) ? undefined : scope.model.forecast.plan;
              // Actuals start date, end date and the configured Min Max dates need to be reset based on the Forecast date and view option.
              this.resetActualsDates(scope, this.defaultDatesBack.FORECASTS, viewOption);
              break;
          }
          scope.model.viewOptions.selected = viewOption;
        }
      });
    }

    getRegisterShareDefinition (scope) {
      return [
        {
          mutation: (source) => {
            // Backwards compatibility for rangeInWeeks => dateRangeLength
            scope.methods.mutation.reassign(source, 'model.actuals.dateRangeLength', 'model.actuals.rangeInWeeks');
            // Backwards compatibility for weeksBack => datesBack
            scope.methods.mutation.reassign(source, 'model.actuals.datesBack', 'model.actuals.weeksBack');
            // Old views: Backwards compatibility for modes.view => modes.data
            // New views: modes.data is non-nil. modes.data represents Data Mode (Unit/Percent) and modes.view represents View Mode (Grid/Chart)
            scope.methods.mutation.recycle(source, 'model.modes.data.selected', 'model.modes.view.selected', Enums.DataPackage.ViewMode.GRID);
            // Backwards compatibility for dateRangeLength
            scope.methods.mutation.fallback(source, 'model.actuals.dateRangeLength', 'model.actuals.datesBack');
          },
          persist: false
        },
        'model.actuals.dateRangeLength',
        'model.actuals.datesBack',
        'model.chartInputs',
        'model.forecast.date',
        'model.forecastComparisons[].date',
        'model.historicalForecast.date',
        'model.modes.comparison.selected',
        'model.modes.data.selected',
        'model.modes.view.selected',
        'model.viewOptions.selected',
        'settings.actualsComparisons',
        'settings.forecastComparisons',
        {
          key: 'settings.actualsComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          key: 'settings.forecastComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          key: 'settings.actualsComparisonInitialSelections',
          serialize: () => _.map(scope.settings.actualsComparisons, (comparison, index) => comparison ? scope.model.actualsComparisons[index].plan.historicalWeek : undefined)
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.model.actuals.datesBack),
          key: 'model.actuals.startDate.date',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.settings.comparisonDatesBack),
          key: 'model.actuals.startDate.minDate',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1),
          key: 'model.actuals.startDate.maxDate',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.actuals.startDate.date, scope.model.actuals.dateRangeLength - 1),
          key: 'model.actuals.endDate.date',
          persist: false
        },
        {
          deserialize: () => scope.model.actuals.startDate.maxDate,
          key: 'model.actuals.endDate.minDate',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(DateUtils.toSunday(), -1),
          key: 'model.actuals.endDate.maxDate',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecast.plan',
          serialize: (value) => value.source
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecastComparisons[].plan',
          // Forecast comparison plans can be undefined in 'ACTUALS' mode.
          serialize: (value) => _.get(value, 'source')
        }
      ];
    }

    getRegisterViewDefinition (scope) {
      return [
        {
          mutation: (source) => {
            // Backwards compatibility for weeksBack => datesBack
            scope.methods.mutation.reassign(source, 'model.actuals.datesBack', 'model.actuals.weeksBack');
            // Old views: Backwards compatibility for modes.view => modes.data
            // New views: modes.data is non-nil. modes.data represents Data Mode (Unit/Percent) and modes.view represents View Mode (Grid/Chart)
            scope.methods.mutation.recycle(source, 'model.modes.data.selected', 'model.modes.view.selected', Enums.DataPackage.ViewMode.GRID);
          },
          persist: false
        },
        'model.actuals.datesBack',
        'model.chartInputs',
        'model.modes.comparison.selected',
        'model.modes.data.selected',
        'model.modes.view.selected',
        'settings.actualsComparisons',
        'settings.forecastComparisons',
        {
          key: 'settings.actualsComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          key: 'settings.forecastComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          key: 'settings.actualsComparisonInitialSelections',
          serialize: () => _.map(scope.settings.actualsComparisons, (comparison, index) => comparison ? scope.model.actualsComparisons[index].plan.historicalWeek : undefined)
        },
        {
          deserialize: () => {
            const thisSunday = DateUtils.toSunday().format(Enums.DateFormat.IsoDate);
            // Only Saved Views should set the fallbackDate. This should never be done for Shareable URLs.
            scope.model.forecast.fallbackDate = DateUtils.fromOffset(thisSunday, -1);
            return thisSunday;
          },
          key: 'model.forecast.date',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecast.plan',
          serialize: (value) => _.pick(value.source, this.savedViewPlanFields)
        },
        {
          deserialize: () => DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
          key: 'model.forecastComparisons[].date',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecastComparisons[].plan',
          // Forecast comparison plans can be undefined in 'ACTUALS' mode.
          serialize: (value) => _.pick(_.get(value, 'source'), this.savedViewPlanFields)
        },
        {
          deserialize: (value, index) => {
            scope.model.forecastComparisons[index].date = DateUtils.fromOffset(scope.model.forecast.date, -value);
            return value;
          },
          key: 'model.forecastComparisons[].weeksDifference',
          serialize: (value, index) => DateUtils.difference(scope.model.forecast.date, scope.model.forecastComparisons[index].date, Enums.TimeUnit.WEEK)
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.model.actuals.datesBack),
          key: 'model.actuals.startDate.date',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.settings.comparisonDatesBack),
          key: 'model.actuals.startDate.minDate',
          persist: false
        },
        {
          deserialize: () => {
            const value = DateUtils.fromOffset(scope.model.forecast.date, -1);
            scope.model.actuals.dateRangeLength = scope.model.actuals.datesBack;
            scope.model.actuals.endDate.minDate = value;
            scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.comparisonDatesBack);
            scope.model.actuals.endDate.date = scope.model.actuals.endDate.maxDate;
            this._setHistoricalForecastDate(scope);
            return value;
          },
          key: 'model.actuals.startDate.maxDate',
          persist: false
        },
        {
          persist: false,
          source: 'name',
          target: 'viewName'
        }
      ];
    }

    resetActualsDates (scope, datesBack, viewOption = scope.model.viewOptions.selected) {
      datesBack = _.defaultTo(datesBack, viewOption === this.viewOption.ACTUALS_FORECASTS ? this.defaultDatesBack.ACTUALS_FORECASTS : this.defaultDatesBack.FORECASTS);
      switch (viewOption) {
        case this.viewOption.ACTUALS:
          scope.model.actuals.startDate.minDate = this._calculateActualsMinStartDate(scope.model.forecast.date, scope.settings.comparisonDatesBack);
          scope.model.actuals.startDate.maxDate = null;
          scope.model.actuals.startDate.date = DateUtils.fromOffset(scope.model.forecast.date, -1 * datesBack, this.timeUnit);
          scope.model.actuals.endDate.minDate = null;
          scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.comparisonDatesBack);
          scope.model.actuals.endDate.date = scope.model.actuals.endDate.maxDate;
          scope.model.actuals.datesBack = datesBack;
          scope.model.actuals.dateRangeLength = datesBack;
          break;
        case this.viewOption.ACTUALS_FORECASTS:
          scope.model.actuals.startDate.minDate = this._calculateActualsMinStartDate(scope.model.forecast.date, scope.settings.comparisonDatesBack);
          scope.model.actuals.startDate.maxDate = DateUtils.fromOffset(scope.model.forecast.date, -1, this.timeUnit);
          scope.model.actuals.startDate.date = DateUtils.fromOffset(scope.model.forecast.date, -1 * datesBack, this.timeUnit);
          scope.model.actuals.endDate.minDate = scope.model.actuals.startDate.maxDate;
          scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.comparisonDatesBack);
          scope.model.actuals.endDate.date = scope.model.actuals.endDate.maxDate;
          scope.model.actuals.datesBack = datesBack;
          scope.model.actuals.dateRangeLength = this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);
          break;
        case this.viewOption.FORECASTS:
          scope.model.actuals.startDate.minDate = scope.model.forecast.date;
          scope.model.actuals.startDate.maxDate = scope.model.forecast.date;
          scope.model.actuals.startDate.date = scope.model.forecast.date;
          scope.model.actuals.endDate.minDate = scope.model.forecast.date;
          scope.model.actuals.endDate.maxDate = scope.model.forecast.date;
          scope.model.actuals.endDate.date = scope.model.forecast.date;
          scope.model.actuals.datesBack = 0;
          scope.model.actuals.dateRangeLength = 0;
      }
      this._setHistoricalForecastDate(scope);
    }
  }

  angular.module('application.services').service('weeklyPlanViewerProvider', WeeklyPlanViewerProviderService);
})();
