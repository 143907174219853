/* globals Configuration */
(function () {
  'use strict';
  const ENTER_KEY_CODE = 13;

  function isEnterKeyCode (event) {
    return event.keyCode === ENTER_KEY_CODE;
  }

  class UserProfileController {
    static get $inject () {
      return ['alerts', '$authentication', 'bastion', 'pageTitle', '$scope'];
    }

    constructor (alerts, $authentication, bastion, pageTitle, $scope) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.bastion = bastion;
      this.pageTitle = pageTitle;
      this.$scope = $scope;
      this.$onInit();
    }

    _updatePageTitle (title) {
      this.pageTitle.update(Configuration.routes.profile, title);
    }

    _updateUserAuthProfile (profile) {
      const isProfileEmpty = _.isEmpty(profile);
      this.$scope.model.userAuthProfile = !isProfileEmpty ? profile : {
        alias: this.$scope.model.lookupUser,
        name: this.$scope.model.lookupUser
      };
      return isProfileEmpty;
    }

    _userLookup (lookupUser = this.$scope.model.lookupUser) {
      this.$scope.settings.fetchingUserLookupData = true;
      this.bastion.userLookup(lookupUser)
        .then((authProfile) => {
          const isProfileEmpty = this._updateUserAuthProfile(authProfile);
          this.$scope.settings.isAliasValid = !isProfileEmpty;
          this._updatePageTitle(isProfileEmpty ? lookupUser : authProfile.name);
          this.$scope.settings.isAliasEditModeOn = isProfileEmpty;
          if (isProfileEmpty) {
            this.alerts.warning(`'${lookupUser}' is not a valid Amazon alias. Please try another alias.`);
          }
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => {
          this.$scope.settings.fetchingUserLookupData = false;
          this.$scope.settings.isMyProfile = false;
        });
    }

    $onInit () {
      this.$scope.methods = {};

      this.$scope.methods.disableEditMode = () => {
        this.$scope.model.lookupUser = this.$scope.model.userAuthProfile.alias;
        this.$scope.settings.isAliasEditModeOn = false;
      };

      this.$scope.methods.enableEditMode = () => {
        this.$scope.settings.isAliasEditModeOn = true;
      };

      this.$scope.methods.hasDeveloperAccess = () => this.$authentication.hasDeveloperAccess();

      this.$scope.methods.isCancelIconVisible = () => this.$scope.methods.hasDeveloperAccess() &&
        this.$scope.settings.isAliasEditModeOn &&
        !this.$scope.settings.fetchingUserLookupData;

      this.$scope.methods.isEditIconVisible = () => this.$scope.methods.hasDeveloperAccess() && !this.$scope.settings.isAliasEditModeOn;

      this.$scope.methods.onAliasChange = (event) => {
        if (!isEnterKeyCode(event)) {
          return;
        }
        if (_.isEmpty(this.$scope.model.lookupUser)) {
          return;
        }
        if (this.$scope.model.lookupUser === this.$scope.model.myAuthProfile.alias) {
          this.$scope.methods.switchToMyProfile();
          return;
        }
        this._userLookup();
      };

      this.$scope.methods.switchToMyProfile = () => {
        this._updateUserAuthProfile(this.$scope.model.myAuthProfile);
        this.$scope.methods.disableEditMode();
        this._updatePageTitle(this.$scope.model.myAuthProfile.name);
        this.$scope.settings.isAliasValid = true;
        this.$scope.settings.isMyProfile = true;
      };

      this.$scope.model = {
        myAuthProfile: this.$authentication.profile()
      };
      this.$scope.model.lookupUser = this.$scope.model.myAuthProfile.alias;
      this.$scope.model.allTenants = Configuration.tenants.all().map((tenant) => ({
        text: tenant.name,
        value: tenant.id
      }));
      this.$scope.model.selectedTenant = Configuration.scopes.current().tenant.id;
      this._updateUserAuthProfile(this.$scope.model.myAuthProfile);

      this.$scope.settings = {
        fetchingUserLookupData: false,
        isAliasEditModeOn: false,
        isAliasValid: true,
        isMyProfile: true
      };
    }
  }
  angular.module('application.controllers').controller('UserProfileController', UserProfileController);
})();
