/* globals DateUtils, Enumeration, Enums, Validate */
(function () {
  'use strict';
  const EMPTY_STRING = '';
  const SINGLE_SPACE = ' ';

  class ClassDecorator {
    static aggregate (rowGranularity) {
      if (_.values(rowGranularity).some((grainValue) => Enumeration.isMemberValue(Enums.AggregateType, grainValue))) {
        return 'text-strong';
      }
    }

    static alarm (threshold, value) {
      if (!_.isNil(threshold) && _.isFinite(value)) {
        if (value < threshold.lowerBound || value > threshold.upperBound) {
          return 'text-alarm';
        }
      }
    }

    static assemble (...classes) {
      // Flatten array then remove non-string and empty values from the list
      return _.chain(classes).flatten().filter((value) => _.isString(value) && !Validate.isBlank(value)).value();
    }

    static cumulativeEndOfWeek (date, dataType, value) {
      if (
        _.isFinite(value) &&
        dataType === Enums.DataPackage.RecordType.CUMULATIVE &&
        DateUtils.isSaturday(date)
      ) {
        return 'cumulative-end-of-week';
      }
    }

    static dataset (dataType, value) {
      if (_.isFinite(value)) {
        return ['dataset', dataType];
      }
    }

    static editable (index, startIndex, endIndex) {
      if (_.inRange(index, startIndex, endIndex)) {
        return 'editable';
      }
      return 'non-editable';
    }

    static highlight (row) {
      if (!_.isNil(row) && row.isGroup) {
        return 'highlight';
      }
      return 'granularity';
    }

    static horizontalDividers (row) {
      if (row.isStartOfMetricFamily) {
        return 'metric-family-divider';
      }
      if (row.isStartOfMetricCategory) {
        return 'metric-category-divider';
      }
    }

    static metricPriority (metric) {
      if (!_.isNil(metric) && metric.isBacklogMetric) {
        return 'text-strong';
      }
    }

    static noPadding (dataType) {
      if (dataType === Enums.DataPackage.RecordType.EDITS || _.isNil(dataType)) {
        return 'no-padding';
      }
    }

    static preview (dataType, value) {
      if (dataType === Enums.DataPackage.RecordType.PREVIEW && (_.isNil(value) || _.isNaN(value))) {
        return 'text-preview';
      }
    }

    static toClassString (classes) {
      if (_.isString(classes)) {
        return classes;
      }
      if (Array.isArray(classes) && !_.isEmpty(classes)) {
        return classes.join(SINGLE_SPACE);
      }
      return EMPTY_STRING;
    }

    static today (date) {
      if (DateUtils.isToday(date)) {
        return 'today';
      }
    }

    static verticalDividers (index, cutoff) {
      if (cutoff === index + 1) {
        return 'historical-divider';
      }
    }

    static width (obj) {
      if (_.isEmpty(obj)) {
        return `${Enums.DisplayWidth.SMALL}-cell-width`;
      }
      if (_.isString(_.get(obj, 'displayWidth'))) {
        return `${obj.displayWidth}-cell-width`;
      }
      return `${Enums.DisplayWidth.LARGE}-cell-width`;
    }
  }

  window.ClassDecorator = Object.freeze(ClassDecorator);
})();
