/* globals AbstractPlanViewerProviderService, Configuration, DateUtils, Enumeration, Enums, Name, PlanMetadata, Ready */
(function () {
  // TODO: When Daily Plan Viewer was initially being developed, much of the code was copied from the Weekly Plan Viewer Provider
  //       and tweaked to support the features available in Daily Plan Viewer. As part of that strategy, code was allowed to be
  //       duplicated with the intention of reducing overhead when enabling new features for Daily Plan Viewer. As feature parity
  //       is better established between the two viewers, all common logic should be refactored into either the Abstract Plan Viewer Provider
  //       or the Plan Viewer Controller. (https://sim.amazon.com/issues/SOP-9853)
  'use strict';
  const CAPACITY_SHARED_VIEW_PLAN_FIELDS = Object.freeze(['date', 'draft', 'scenario', 'scope', 'subScenario', 'type']);
  const CAPACITY_TENANT = 'CAPACITY';
  const DAYS_IN_WEEK = 7;
  const DEFAULT_COMPARISONS = 2;
  // This Array represents the amount of weeks back to pull a plan from for the first and second comparisons respectively.
  const DEFAULT_COMPARISON_WEEKS_BACK = Object.freeze([1, 3]);
  const DEFAULT_DATES_BACK = Enumeration
    .create('FORECASTS', 'ACTUALS_FORECASTS')
    .asExactValue(0, 7);
  const FEATURES_ENABLED_MAP = Object.freeze({
    'ACTUAL_COMPARISONS': false,
    'SUBTOTALS': false,
    'YEAR_OVER_YEAR': false
  });
  // Although the daily plan horizons are 13 weeks (17 for EU scopes), this translates into 91 days (119 for EU scopes) and thus
  // 91 columns (119 for EU scopes). This represents a significant load on the browser and so the maximum number of forecasts to
  // be displayed is 3 weeks or 21 days, which was deemed sufficient by users. (https://sim.amazon.com/issues/SOP-10018)
  // Note: This value must be greater than 0 and not exceed the minimum possible daily plan horizon value i.e. 91 days.
  const FORECAST_DISPLAY_LIMIT = 21;
  const PLAN_VIEWER_ID = 'dailyPlanViewer';
  const UNDEFINED_STRING = 'Undefined';

  class DailyPlanViewerProviderService extends AbstractPlanViewerProviderService {
    static get $inject () {
      return ['alerts', 'planConfiguration'];
    }

    constructor (alerts, planConfiguration) {
      super();
      this.alerts = alerts;
      this.planConfiguration = planConfiguration;
    }

    get defaultComparisons () {
      return DEFAULT_COMPARISONS;
    }

    get defaultDatesBack () {
      return DEFAULT_DATES_BACK;
    }

    get id () {
      return PLAN_VIEWER_ID;
    }

    get stateDefinition () {
      const activeHistoricalSupportPlans = Configuration.historicalSupportPlans.filter((plan) => plan.id.startsWith(_.upperFirst(this.id)) && Configuration.activation.isAvailable(plan.id));
      return {
        'model.actuals.dateRangeLength': () => this.defaultDatesBack.ACTUALS_FORECASTS,
        'model.actuals.datesBack': () => this.defaultDatesBack.ACTUALS_FORECASTS,
        'model.actuals.endDate': () => ({}),
        'model.actuals.startDate': () => ({}),
        'model.actualsComparisons': () => [],
        'model.forecast.maxDate': () => DateUtils.fromOffset(DateUtils.toSunday(), 1),
        'model.forecastComparisons': () => _.times(this.defaultComparisons, (index) => ({ datasetClass: `comparison ${Name.ofOrdinal(index + 1)}` })),
        'model.historicalSupportPlans.list': () => activeHistoricalSupportPlans,
        'model.modes.comparison.list': () => Enumeration.values(Enums.DataPackage.ComparisonMode),
        'model.modes.comparison.selected': () => Enums.DataPackage.ComparisonMode.UNIT,
        'model.modes.data.list': () => Enumeration.values(Enums.DataPackage.DataMode),
        'model.modes.data.selected': () => Enums.DataPackage.DataMode.UNIT,
        'model.modes.view.list': () => Enumeration.values(Enums.DataPackage.ViewMode),
        'model.modes.view.selected': () => Enums.DataPackage.ViewMode.GRID,
        'model.timeUnit': () => Enums.TimeUnit.DAY,
        'model.viewOptions.list': () => this._isTenantCapacity() ? [this.viewOption.ACTUALS] : [this.viewOption.ACTUALS_FORECASTS, this.viewOption.FORECASTS],
        'model.viewOptions.selected': () => this._isTenantCapacity() ? this.viewOption.ACTUALS : this.viewOption.ACTUALS_FORECASTS,
        'settings.actuals': () => true,
        'settings.actualsComparisons': () => Array(this.defaultComparisons).fill(false),
        'settings.comparisonDatesBack': () => undefined,
        'settings.comparisonDefaultSelections': () => DEFAULT_COMPARISON_WEEKS_BACK,
        'settings.forecastComparisonFilterFn': () => undefined,
        'settings.forecastComparisons': () => Array(this.defaultComparisons).fill(false),
        'settings.forecastDisplayLimit': () => FORECAST_DISPLAY_LIMIT,
        'settings.forecastFilterFn': () => ((plan) => plan.isDailyPlan() && !plan.isHistoricalPlan()),
        'settings.forecasts': () => !this._isTenantCapacity()
      };
    }

    get timeGrain () {
      return Enums.TimeGranularity.DAILY;
    }

    get timeUnit () {
      return Enums.TimeUnit.DAY;
    }

    _createPseudoPlan (plan) {
      return PlanMetadata.create({
        date: DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
        draft: Enums.Plan.DraftType.FINAL,
        scenario: PlanMetadata.defaultScenario.scenario,
        schemaName: _.isNil(plan) ? UNDEFINED_STRING : plan.schemaName,
        scope: Configuration.scopes.current().code,
        subScenario: PlanMetadata.defaultScenario.subScenario,
        type: _.isNil(plan) ? UNDEFINED_STRING : plan.planType
      });
    }

    // This is being used to accommodate the Daily Capacity Actuals usecase.
    // This should be removed if/when there is either a Actuals Viewer or a Daily Capacity Plan.
    // https://sim.amazon.com/issues/SOP-13296
    _isTenantCapacity () {
      return Configuration.scopes.current().tenant.id === CAPACITY_TENANT;
    }

    configureMethods (controller) {
      super.configureMethods(controller);
      const scope = controller.$scope;
      Object.assign(scope.methods, {
        changeActualsDateRange: (startDate, endDate) => {
          // This is being used to accommodate the Daily Capacity Actuals usecase.
          // A refactoring of the date logic across the Plan Viewer codebase is in
          // order due to all of the cases and considerations that are in place.
          // https://sim.amazon.com/issues/SOP-13366
          if (this._isTenantCapacity()) {
            scope.model.actuals.startDate.date = startDate;
            scope.model.actuals.endDate.date = endDate;
            scope.model.actuals.datesBack = DateUtils.difference(scope.model.forecast.date, scope.model.actuals.startDate.date, this.timeUnit);
            scope.model.actuals.dateRangeLength = this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);
            return;
          }
          super.changeActualsStartDate(scope, startDate);
          super.changeActualsEndDate(scope, endDate);

        },
        changeForecastDate: (date) => {
          if (controller.isSettingsLocked() || date === scope.model.forecast.date) {
            return;
          }
          scope.model.forecast.date = date;

          // Actuals start date, end date and the configured Min Max dates need to be reset based on the updated Forecast date.
          this.resetActualsDates(scope);

          // If the forecast plan is non-nil, create a plan copy with just 'draft', 'scenario', 'subScenario' and 'type' properties.
          // This goes in as the initial plan to the plan-to-view selector. If a similar plan is present for the newly selected date, it would get selected.
          // This assures that the same plan type (if available) stays selected as we change dates and the Flow / Group By / Filters selections are not reset in the process.
          // SIM: https://issues.amazon.com/issues/SOP-8488.
          scope.model.forecast.plan = !_.isUndefined(scope.model.forecast.plan) ? PlanMetadata.create(_.pick(scope.model.forecast.plan, this.savedViewPlanFields)) : undefined;

          // Reset all comparison.date to make sure historicalWeekDateSelector defaults to the expected '1 Week Ago' / 2nd item in the list.
          // Without this, historicalWeekDateSelector defaults to initialDate which might not be '1 Week Ago' / 2nd item in the list.
          scope.model.forecastComparisons.forEach((comparison) => comparison.date = undefined);
        },
        changeForecastPlan: (plan) => {
          // If no forecast plans are available to select, 'plan' will be undefined.
          if (_.isUndefined(plan)) {
            // If 'plan' is undefined and settings are locked, view / shared url cannot be applied as is or at all.
            if (controller.isSettingsLocked()) {
              if (!_.isNil(scope.model.forecast.fallbackDate)) {
                // If no forecast plans were found for the original forecast date and fallbackDate is non nil, this is first attempt of applying the Saved View. Try again by using fallbackDate as the forecast date.
                scope.model.forecast.date = scope.model.forecast.fallbackDate;
                this.resetActualsDates(scope, scope.model.actuals.datesBack);
                scope.model.forecastComparisons.forEach((comparison) => comparison.date = DateUtils.fromOffset(scope.model.forecast.date, -1 * comparison.datesDifference));

                // Make fallbackDate undefined so the forecast date fallback attempt happens only once.
                scope.model.forecast.fallbackDate = undefined;
                this.alerts.info('Forecast plans are unavailable in the current week. Falling back to the previous week');
                return;
              }
              // If 'plan' is undefined and no fallbackDate is available, view / shared url cannot be applied.
              this.alerts.info({
                details: 'Forecast plans are unavailable.',
                message: 'View / Shared Url could not be applied.'
              });
              scope.settings.forecastComparisons = Array(this.defaultComparisons).fill(false);
            }
            scope.model.forecast.plan = undefined;
            scope.settings.forecastComparisonFilterFn = () => false;
            return;
          }

          if (!controller.isSettingsLocked()) {
            if (plan.type !== _.get(scope.model.forecast.plan, 'type')) {
              // If the plan type has changed, clear the comparison plans and any settings that are filtered by the plan type.
              scope.settings.comparisonDatesBack = undefined;
              scope.model.forecastComparisons.forEach((comparison) => comparison.plan = undefined);
              scope.model.metricFamilies = undefined;
              scope.model.filters = {};
              scope.model.granularity = {};

              // uib-tabset does not support dynamic tabs and thus the "active" tab does not get updated
              // when a plan changes and the granularities are changed. Thus the controller should wait for
              // updated grains to arrive and then set the active tab.
              Ready.once(
                controller.$scope,
                () => !_.isNil(scope.model.granularity.allGrains),
                () => scope.activeTab = _.minBy(scope.model.granularity.allGrains.granularities, 'filterIndex').id
              );
            }
          }

          scope.model.forecast.plan = plan;
          scope.settings.forecastComparisonFilterFn = (comparisonPlan) => comparisonPlan.type === plan.type && !plan.isHistoricalPlan();
          // If "comparisonDatesBack" is defined, skip calling for Plan Definition to define it.
          if (!_.isNil(scope.settings.comparisonDatesBack)) {
            return;
          }
          // TODO: Incorporate the remaining Plan Definition attributes into both the plan viewers, and move the existing getPlanDefinition()
          //       call into the providers instead of in the group-grain-by-selector. (https://sim.amazon.com/issues/SOP-10057)
          this.planConfiguration.planDefinition().get(plan.type)
            .then((data) => {
              scope.settings.comparisonDatesBack = data.planHorizonInDays / DAYS_IN_WEEK;
              this.resetActualsDates(scope);
            })
            .catch((error) => this.alerts.danger({
              details: error,
              message: 'Unable to load the planning horizon of the selected plan.'
            }));
        },
        changeHistoricalSupportPlan: (plan) => {
          if (plan === scope.model.historicalSupportPlans.selected) {
            return;
          }
          scope.model.historicalSupportPlans.selected = plan;
          scope.methods.changeForecastPlan(this._createPseudoPlan(plan));
        },
        isChartingAvailable: () => true,
        isCompareModeEnabled: () => {
          const isCompareModeEnabled = scope.model.modes.data.selected === Enums.DataPackage.DataMode.UNIT && scope.methods.isForecastComparePlanSelectable(0);
          if (!isCompareModeEnabled) {
            scope.model.modes.comparison.selected = Enums.DataPackage.ComparisonMode.UNIT;
          }
          return isCompareModeEnabled;
        },
        isFeatureEnabled: (feature) => _.defaultTo(FEATURES_ENABLED_MAP[feature], false),
        isValidPlanSelection: () => !_.isNil(scope.model.forecast.plan) && (scope.model.forecast.plan.isValid() || !scope.settings.forecasts) && scope.methods.isValidForecastComparisonPlanSelection(),
        selectViewOption: (viewOption, forecastDate = DateUtils.toSunday().format(Enums.DateFormat.IsoDate), weeksBack = scope.settings.comparisonDatesBack) => {
          let pseudoPlan;
          switch (viewOption) {
            case this.viewOption.ACTUALS:
              scope.settings.actuals = true;
              scope.settings.forecasts = false;
              // When 'ACTUALS' mode is manually selected or for Saved Views, Forecast date is the current week's Sunday.
              // For Shared Urls, Forecast date is the date saved in the Shared Url data.
              scope.model.forecast.date = forecastDate;
              pseudoPlan = this._createPseudoPlan(scope.model.historicalSupportPlans.selected);
              scope.methods.changeForecastPlan(pseudoPlan);
              // When 'ACTUALS' mode is manually selected or for Saved Views, weeksBack is set to a default value.
              // For Shared Urls, weeksBack should be the value saved in the Shared Url data.
              this.resetActualsDates(scope, weeksBack, viewOption);
              // Unblock valid plan selection check by turning off Forecast comparisons
              scope.settings.forecastComparisons = Array(this.defaultComparisons).fill(false);
              break;
            case this.viewOption.ACTUALS_FORECASTS:
              scope.settings.actuals = true;
              scope.settings.forecasts = true;
              scope.model.forecast.plan = scope.methods.isViewOptionSelected(this.viewOption.ACTUALS) ? undefined : scope.model.forecast.plan;
              // Actuals start date, end date and the configured Min Max dates need to be reset based on the Forecast date and view option.
              this.resetActualsDates(scope, this.defaultDatesBack.ACTUALS_FORECASTS, viewOption);
              break;
            case this.viewOption.FORECASTS:
              scope.settings.actuals = false;
              scope.settings.forecasts = true;
              scope.model.forecast.plan = scope.methods.isViewOptionSelected(this.viewOption.ACTUALS) ? undefined : scope.model.forecast.plan;
              // Actuals start date, end date and the configured Min Max dates need to be reset based on the Forecast date and view option.
              this.resetActualsDates(scope, this.defaultDatesBack.FORECASTS, viewOption);
              break;
          }
          scope.model.viewOptions.selected = viewOption;
        }
      });

      // This is hack that is required to facilitate initialization of Daily Plan Viewer when
      // only the "Actuals" View Option is available as is the case for Capacity Daily Actuals.
      // Under normal circumstances, initialization is kicked off via the PlanToViewSelector's
      // Plan Store call returning, but in this case that call never happens and thus onSelectionChange()
      // is also never hit.
      // This should be removed if/when there is either a Actuals Viewer or a Daily Capacity Plan.
      // https://sim.amazon.com/issues/SOP-13296
      if (this._isTenantCapacity()) {
        scope.methods.changeHistoricalSupportPlan(_.head(scope.model.historicalSupportPlans.list));
      }
    }

    getRegisterShareDefinition (scope) {
      return [
        'model.actuals.dateRangeLength',
        'model.actuals.datesBack',
        'model.chartInputs',
        'model.forecast.date',
        'model.forecastComparisons[].date',
        'model.modes.comparison.selected',
        'model.modes.data.selected',
        'model.modes.view.selected',
        'model.viewOptions.selected',
        'settings.comparisonDatesBack',
        'settings.forecastComparisons',
        {
          key: 'settings.forecastComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.model.actuals.datesBack, this.timeUnit),
          key: 'model.actuals.startDate.date',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.settings.forecastDisplayLimit, this.timeUnit),
          key: 'model.actuals.startDate.minDate',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1, this.timeUnit),
          key: 'model.actuals.startDate.maxDate',
          persist: false
        },
        {
          // Actuals start and end date are the same (the forecast date) when the view option is "FORECASTS".
          deserialize: () => scope.model.actuals.dateRangeLength === 0 ? scope.model.actuals.startDate.date : DateUtils.fromOffset(scope.model.actuals.startDate.date, scope.model.actuals.dateRangeLength - 1, this.timeUnit),
          key: 'model.actuals.endDate.date',
          persist: false
        },
        {
          deserialize: () => scope.model.actuals.startDate.maxDate,
          key: 'model.actuals.endDate.minDate',
          persist: false
        },
        {
          deserialize: () => this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.forecastDisplayLimit - 1),
          key: 'model.actuals.endDate.maxDate',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecast.plan',
          serialize: (value) => value.source
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecastComparisons[].plan',
          // Forecast comparison plans can be undefined if a plan is missing. This ensures a ShareableUrl
          // can be created when a comparison plan is missing but comparisons are not enabled.
          serialize: (value) => _.get(value, 'source')
        }
      ];
    }

    getRegisterViewDefinition (scope) {
      return [
        'model.actuals.datesBack',
        'model.chartInputs',
        'model.modes.comparison.selected',
        'model.modes.data.selected',
        'model.modes.view.selected',
        'settings.comparisonDatesBack',
        'settings.forecastComparisons',
        {
          key: 'settings.forecastComparisonFilterFn',
          persist: false,
          unset: true
        },
        {
          deserialize: () => {
            const thisSunday = DateUtils.toSunday().format(Enums.DateFormat.IsoDate);
            // Only Saved Views should set the fallbackDate. This should never be done for Shareable URLs.
            scope.model.forecast.fallbackDate = DateUtils.fromOffset(thisSunday, -1);
            return thisSunday;
          },
          key: 'model.forecast.date',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecast.plan',
          serialize: (value) => _.pick(_.get(value, 'source'), this._isTenantCapacity() ? CAPACITY_SHARED_VIEW_PLAN_FIELDS : this.savedViewPlanFields)
        },
        {
          deserialize: () => DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
          key: 'model.forecastComparisons[].date',
          persist: false
        },
        {
          deserialize: (value) => PlanMetadata.create(value),
          key: 'model.forecastComparisons[].plan',
          // Forecast comparison plans can be undefined if a plan is missing. This ensures a Saved View
          // can be created when a comparison plan is missing but comparisons are not enabled.
          serialize: (value) => _.pick(_.get(value, 'source'), this.savedViewPlanFields)
        },
        {
          deserialize: (value, index) => {
            scope.model.forecastComparisons[index].date = DateUtils.fromOffset(scope.model.forecast.date, -1 * value);
            return value;
          },
          key: 'model.forecastComparisons[].datesDifference',
          serialize: (value, index) => DateUtils.difference(scope.model.forecast.date, scope.model.forecastComparisons[index].date, Enums.TimeUnit.WEEK)
        },
        {
          deserialize: () => scope.model.actuals.datesBack,
          key: 'model.actuals.dateRangeLength',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.model.actuals.datesBack, this.timeUnit),
          key: 'model.actuals.startDate.date',
          persist: false
        },
        {
          deserialize: () => this._isTenantCapacity() ? null : DateUtils.fromOffset(scope.model.forecast.date, -1 * scope.settings.forecastDisplayLimit, this.timeUnit),
          key: 'model.actuals.startDate.minDate',
          persist: false
        },
        {
          deserialize: () => DateUtils.fromOffset(scope.model.forecast.date, -1, this.timeUnit),
          key: 'model.actuals.startDate.maxDate',
          persist: false
        },
        {
          // Actuals start and end date are the same (the forecast date) when the view option is "FORECASTS".
          deserialize: () => scope.model.actuals.dateRangeLength === 0 ? scope.model.actuals.startDate.date : DateUtils.fromOffset(scope.model.actuals.startDate.date, scope.model.actuals.dateRangeLength - 1, this.timeUnit),
          key: 'model.actuals.endDate.date',
          persist: false
        },
        {
          deserialize: () => scope.model.actuals.startDate.maxDate,
          key: 'model.actuals.endDate.minDate',
          persist: false
        },
        {
          deserialize: () => this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.forecastDisplayLimit - 1),
          key: 'model.actuals.endDate.maxDate',
          persist: false
        },
        {
          persist: false,
          source: 'name',
          target: 'viewName'
        }
      ];
    }

    resetActualsDates (scope, datesBack, viewOption = scope.model.viewOptions.selected) {
      datesBack = _.defaultTo(datesBack, viewOption === this.viewOption.ACTUALS_FORECASTS ? this.defaultDatesBack.ACTUALS_FORECASTS : this.defaultDatesBack.FORECASTS);
      switch (viewOption) {
        case this.viewOption.ACTUALS:
          // This is being used to accommodate the Daily Capacity Actuals usecase.
          // A refactoring of the date logic across the Plan Viewer codebase is in
          // order due to all of the cases and considerations that are in place.
          // https://sim.amazon.com/issues/SOP-13366
          scope.model.actuals.startDate.minDate = this._isTenantCapacity() ? null : this._calculateActualsMinStartDate(DateUtils.toSunday().format(Enums.DateFormat.IsoDate), scope.settings.forecastDisplayLimit);
          scope.model.actuals.startDate.maxDate = null;
          scope.model.actuals.startDate.date = this._calculateActualsMinStartDate(DateUtils.toSunday().format(Enums.DateFormat.IsoDate), scope.settings.forecastDisplayLimit);
          scope.model.actuals.endDate.minDate = null;
          scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(DateUtils.toSunday().format(Enums.DateFormat.IsoDate), scope.settings.comparisonDatesBack);
          scope.model.actuals.endDate.date = scope.model.actuals.endDate.maxDate;
          scope.model.actuals.datesBack = datesBack;
          scope.model.actuals.dateRangeLength = this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);
          break;
        case this.viewOption.FORECASTS:
          scope.model.actuals.startDate.minDate = scope.model.forecast.date;
          scope.model.actuals.startDate.maxDate = scope.model.forecast.date;
          scope.model.actuals.startDate.date = scope.model.forecast.date;
          scope.model.actuals.endDate.minDate = scope.model.forecast.date;
          scope.model.actuals.endDate.maxDate = scope.model.forecast.date;
          scope.model.actuals.endDate.date = scope.model.forecast.date;
          scope.model.actuals.datesBack = 0;
          scope.model.actuals.dateRangeLength = 0;
          break;
        case this.viewOption.ACTUALS_FORECASTS:
          scope.model.actuals.startDate.minDate = this._calculateActualsMinStartDate(scope.model.forecast.date, scope.settings.forecastDisplayLimit);
          scope.model.actuals.startDate.maxDate = DateUtils.fromOffset(scope.model.forecast.date, -1, this.timeUnit);
          scope.model.actuals.startDate.date = DateUtils.fromOffset(scope.model.forecast.date, -1 * datesBack, this.timeUnit);
          scope.model.actuals.endDate.minDate = scope.model.actuals.startDate.maxDate;
          scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(scope.model.forecast.date, scope.settings.forecastDisplayLimit - 1);
          scope.model.actuals.endDate.date = DateUtils.fromOffset(scope.model.forecast.date, -1, this.timeUnit);
          scope.model.actuals.datesBack = datesBack;
          scope.model.actuals.dateRangeLength = this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);
      }
    }
  }

  angular.module('application.services').service('dailyPlanViewerProvider', DailyPlanViewerProviderService);
})();
