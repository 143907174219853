/* globals Enums, Ready, Validate */
(function () {
  'use strict';
  const VIEW_NAME_MAX_LENGTH = 30;
  const VIEW_NAME_LENGTH_WARNING = `View name cannot exceed ${VIEW_NAME_MAX_LENGTH} characters`;
  const UPDATE_WARNING = 'You are about to update the existing view with new selections.';

  class ViewSelectorController {
    static get $inject () {
      return ['alerts', '$authentication', '$location', 'overlay', 'shortcut', '$uibModal', 'view'];
    }

    constructor (alerts, $authentication, $location, overlay, shortcut, $uibModal, view) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.$location = $location;
      this.overlay = overlay;
      this.shortcut = shortcut;
      this.$uibModal = $uibModal;
      this.view = view;

      this.state = Ready.create({
        isDataEmptyFn: () => !this.loading && _.isEmpty(this.allViews.private) && _.isEmpty(this.allViews.public),
        isLoadingFn: () => this.loading,
        isPrivateDataEmptyFn: () => !this.loading && _.isEmpty(this.allViews.private),
        isPublicDataEmptyFn: () => !this.loading && _.isEmpty(this.allViews.public)
      });
    }

    _isViewNameTooLong (viewName = this.newViewName) {
      return viewName.length > VIEW_NAME_MAX_LENGTH;
    }

    isSaveEnabled () {
      return !Validate.isBlank(this.newViewName) && !this._isViewNameTooLong() && this.isCreateEnabled();
    }

    isViewOwner (view) {
      return view.owner === this.$authentication.profile().alias;
    }

    openViewModal (event, mode, selectedView) {
      this.showDropDown = false;
      event.stopPropagation();
      this.$uibModal.open({
        component: 'viewModal',
        resolve: {
          mode: () => mode,
          selectedView: () => selectedView
        },
        size: Enums.ModalSize.LARGE
      }).result
        .then((modalResponse) => {
          this[modalResponse.mode](modalResponse.view, modalResponse.view.isPublic !== selectedView.isPublic);
        })
        .catch(_.noop);
    }

    onSelected (selection) {
      if (_.isNil(selection)) {
        return;
      }
      this.showDropDown = false;
      this.view.apply(selection);
    }

    onViewNameChange () {
      if (this._isViewNameTooLong()) {
        this.warningMessage = VIEW_NAME_LENGTH_WARNING;
        this.showWarningMessage = true;
        return;
      }
      if (_.find(this.allViews.private, { name: this.newViewName }) && !this.showWarningMessage) {
        this.warningMessage = `View "${this.newViewName}" already exists. ${UPDATE_WARNING}`;
        this.showWarningMessage = true;
        return;
      }
      this.showWarningMessage = false;
    }

    create (viewName) {
      // If 'showWarningMessage' is still true, user has confirmed the Update action.
      const action = this.showWarningMessage ? { error: 'update', overlay: 'Updating', success: 'updated' } : { error: 'create', overlay: 'Creating', success: 'created' };
      this.showDropDown = false;
      this.overlay.show(`${action.overlay} view: ${viewName}`);
      this.view.create(viewName)
        .then(() => this.alerts.success(`View: ${viewName} ${action.success} successfully.`))
        .then(() => this.$onInit())
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to ${action.error} view. Please refresh your browser and try again.` }))
        .finally(() => this.overlay.hide());
    }

    delete (viewToDelete) {
      this.overlay.show(`Deleting view: ${viewToDelete.name}`);
      this.view.delete(viewToDelete)
        .then(() => this.alerts.success(`View: ${viewToDelete.name} deleted successfully.`))
        .then(() => this.$onInit())
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to delete view. Please refresh your browser and try again.' }))
        .finally(() => this.overlay.hide());
    }

    update (updatedView, hasViewTypeChanged) {
      if (hasViewTypeChanged && !updatedView.isPublic && _.find(this.allViews.private, { name: updatedView.name })) {
        this.alerts.warning(`Unable to make public view "${updatedView.name}" private. A private view with the same name already exists.`);
        return;
      }
      if (hasViewTypeChanged && updatedView.isPublic && _.find(this.allViews.public, { name: updatedView.name })) {
        this.alerts.warning(`Unable to make private view "${updatedView.name}" public. A public view with the same name already exists.`);
        return;
      }
      this.overlay.show(`Updating view: ${updatedView.name}`);
      this.view.update(updatedView)
        .then(() => this.alerts.success(`View: ${updatedView.name} updated successfully.`))
        .then(() => this.$onInit())
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to update view. Please refresh your browser and try again.' }))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      this.loading = true;
      this.showDropDown = false;
      this.showWarningMessage = false;
      this.allViews = {
        private: undefined,
        public: undefined
      };
      this.newViewName = undefined;
      this.view.allViews(this.$location.path())
        .then((views) => {
          this.allViews.private = _.sortBy(_.filter(views.personal, (view) => !view.isPublic), ['name']);
          this.allViews.public = _.sortBy(views.public, ['name']);
          const shortcutViewId = this.shortcut.getShortcutData();
          if (shortcutViewId) {
            const lookupView = _.find(_.concat(this.allViews.private, this.allViews.public), { identifier: shortcutViewId });
            this.onSelected(lookupView);
          }
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to load views. Please refresh your browser and try again.' }))
        .finally(() => this.loading = false);
    }
  }

  angular.module('application.components').component('viewSelector', {
    bindings: {
      isCreateEnabled: '&'
    },
    controller: ViewSelectorController,
    templateUrl: 'templates/components/view-selector.component.html'
  });
})();
