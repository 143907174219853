/* globals Configuration */
(function () {
  'use strict';

  class AbstractServiceEndpoint {
    constructor (request, service) {
      if (_.isNil(request)) {
        throw new Error('AbstractServiceEndpoint: request must not be nil');
      }
      if (_.isNil(service)) {
        throw new Error('AbstractServiceEndpoint: service must not be nil');
      }
      if (!_.has(Configuration.services, service.key)) {
        throw new Error(`AbstractServiceEndpoint: unknown service key: "${service.key}"`);
      }
      this.request = request;
      this.serviceKey = service.key;
    }

    aws () {
      return this.request.awsService(this.serviceKey);
    }

    cloudWatch () {
      return this.request.cloudWatch(this.serviceKey);
    }

    s3 () {
      return this.request.s3(this.serviceKey);
    }
  }

  window.AbstractServiceEndpoint = Object.freeze(AbstractServiceEndpoint);
})();
