/* globals Configuration */
/***
 * This file defines various configuration blocks that are intended to be temporary solutions related to
 * the Network Planner until a more optimal strategy for acquiring this information is made available.
 * This entire file can be viewed as a series of TODOs to find better ways to acquire this configuration.
 ***/
(function () {

  const NETWORK_PLANNER_DATE_RANGE = Object.freeze({
    actuals: 3,
    forecasts: 13,
    timeGranularity: 'WEEKLY',
    timeUnit: 'WEEK'
  });

  const NETWORK_PLANNER_FLOWS = Object.freeze([
    Object.freeze({
      id: 'NewVendorFreight'
    })
  ]);

  const NETWORK_PLANNER_PLANS = Object.freeze([
    Object.freeze({
      flow: 'NewVendorFreight',
      groupBy: Object.freeze(['origin']),
      isBaselineUploadable: true,
      isComparable: false,
      isDerived: false,
      isEditable: true,
      isSummary: false,
      splitBy: 'networkInventoryGroupNetworkPlanning',
      type: 'cscOriginLagNigWeeklyPlan'
    }),
    Object.freeze({
      flow: 'NewVendorFreight',
      isBaselineUploadable: false,
      isComparable: false,
      isDerived: false,
      isEditable: false,
      isSummary: false,
      type: 'shipmentArrivalsOriginLagNigWeeklyPlan'
    }),
    Object.freeze({
      flow: 'NewVendorFreight',
      groupBy: Object.freeze(['origin']),
      isBaselineUploadable: true,
      isComparable: false,
      isDerived: false,
      isEditable: true,
      isSummary: false,
      splitBy: 'networkInventoryGroupNetworkPlanning',
      type: 'fillRateOriginLagNigWeeklyPlan'
    }),
    Object.freeze({
      actualsFilters: Object.freeze({
        businessEntity: ['FBA']
      }),
      actualsGranularity: Object.freeze({
        grains: ['networkInventoryGroup'],
        plan: 'BUSINESSENTITY-NETWORKINVENTORYGROUP-BINTYPE-WAREHOUSE'
      }),
      actualsSplitBy: 'networkInventoryGroup',
      dependencies: ['cscOriginLagNigWeeklyPlan', 'fillRateOriginLagNigWeeklyPlan'],
      flow: 'NewVendorFreight',
      groupBy: Object.freeze([]),
      isBaselineUploadable: false,
      isComparable: true,
      isDerived: true,
      isEditable: true,
      isSummary: false,
      splitBy: 'networkInventoryGroupNetworkPlanning',
      type: 'totalFbaNvfNigWeeklyPlan'
    }),
    Object.freeze({
      flow: 'NewVendorFreight',
      isSummary: true,
      summaryGranularity: 'networkInventoryGroupNetworkPlanning',
      summaryPlans: ['totalFbaNvfNigWeeklyPlan', 'cscOriginLagNigWeeklyPlan'],
      type: 'Summary'
    })
  ]);

  const NETWORK_PLANNER_SETTINGS = Object.freeze({
    decimalPlacesRatio: 2,
    displayOnlyEdits: false
  });

  const NETWORK_PLANNER_DRAFTS = Object.freeze({
    BASELINE: 'Baseline',
    COMPARISON: 'Comparison',
    EDITS: 'Edits'
  });

  const NETWORK_PLANNER_DRAFT_ORDER = Object.freeze({
    [NETWORK_PLANNER_DRAFTS.BASELINE]: 0,
    [NETWORK_PLANNER_DRAFTS.COMPARISON]: 2,
    [NETWORK_PLANNER_DRAFTS.EDITS]: 1
  });

  Configuration
    .defineProperty('networkPlannerDateRange', NETWORK_PLANNER_DATE_RANGE)
    .defineProperty('networkPlannerDraftOrder', NETWORK_PLANNER_DRAFT_ORDER)
    .defineProperty('networkPlannerDrafts', NETWORK_PLANNER_DRAFTS)
    .defineProperty('networkPlannerFlows', NETWORK_PLANNER_FLOWS)
    .defineProperty('networkPlannerPlans', NETWORK_PLANNER_PLANS)
    .defineProperty('networkPlannerSettings', NETWORK_PLANNER_SETTINGS);
})();
