/* globals AbstractSourceBasedModel */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'emailConfiguration',
    'isEnabled',
    'scope',
    'ticketingConfiguration',
    'workflowList'
  ]);

  class DataDelayCheckConfiguration extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }
  }

  window.DataDelayCheckConfiguration = Object.freeze(DataDelayCheckConfiguration);
})();
