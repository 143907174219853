/* globals Enums */
(function () {
  'use strict';
  class AlertsBarController {
    static get $inject () {
      return ['alerts', '$uibModal'];
    }

    constructor (alerts, $uibModal) {
      this.alerts = alerts;
      this.$uibModal = $uibModal;
    }

    close (alert) {
      /**
       * Prior to the 'dismiss-on-timeout' feature, this method would take an index parameter and simply splice the alert off the queue based on index.
       * With the introduction of 'dismiss-on-timeout', this method might get called twice for the same alert when the user closes the alert manually
       * before the alert auto-closes; first call on user's manual close action and second call when the 'dismiss-on-timeout' countdown hits zero in the
       * background (even after the alert has closed). As a result, the alert needs to be removed from the queue based on reference, which ensures the
       * second call is a noop (i.e., it does not remove some other alert by mistake).
       */
      _.remove(this.queue, (item) => item === alert);
    }

    _add (alert) {
      if (_.some(this.queue, (displayedAlert) => displayedAlert.equals(alert))) {
        // Duplicate alert encountered that is already being displayed. Ignore the duplicate.
        return;
      }
      const displayedAlert = _.find(this.queue, (displayedAlert) => displayedAlert.like(alert));
      if (!_.isNil(displayedAlert)) {
        // Similar alert encountered. Increment count of existing alert and ignore new one.
        ++displayedAlert.occurrences;
        return;
      }

      if (alert.importance === Enums.AlertImportance.INTERRUPT) {
        this.$uibModal.open({
          controller: function () {
            this.alert = alert;
          },
          controllerAs: '$ctrl',
          templateUrl: 'templates/components/alerts-bar-modal.component.html'
        });
        return;
      }

      this.queue.push(alert);
    }

    $onInit () {
      this.queue = [];
      this.alerts.$on((event, alert) => this._add(alert));
      this.alerts.$onClear(() => this.queue.length = 0);
      this.alerts.all().forEach(this._add);
    }
  }

  angular.module('application.components')
    .component('alertsBar', {
      controller: AlertsBarController,
      templateUrl: 'templates/components/alerts-bar.component.html'
    });
})();
