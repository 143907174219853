/* globals AbstractDateSelectorComponent, DateUtils, Enums */
(function () {
  'use strict';

  /**
   * Date: this.date
   * Date: this.backup
   * String: this.initialDate
   * String: this.maxDate
   * String: this.minDate
   */
  class DateSelectorController extends AbstractDateSelectorComponent {
    setInitialDates () {
      const now = this.toDate(DateUtils.of());
      this.configuration.options.minDate = _.isNull(this.minDate)
        ? null
        : this.toDate(this.minDate, AbstractDateSelectorComponent.dataEpochDate());
      this.configuration.options.maxDate = _.isNull(this.maxDate)
        ? null
        : this.toDate(this.maxDate, now);
      const dateValue = this.isNullable && _.isNil(this.initialDate)
        ? null
        : this.toDate(this.initialDate, now);
      this.changeDate(dateValue);
    }

    changeDate (date) {
      if (date instanceof Date) {
        this.date = this.adjustDateForWeekSelectionMode(date);
        this.backup = this.date;
        this.onSelectionChange({ date: this.fromDate(this.date).format(Enums.DateFormat.IsoDate) });
        return;
      }
      if (this.isNullable) {
        this.onSelectionChange({ date: null });
        return;
      }
      // If user clears date value and isNullable is not true, preserve date value using backup
      this.date = this.backup;
    }

    $onInit () {
      this.popup = { opened: false };
      this.initConfigurationBindings({
        options: {
          showWeeks: true,
          startingDay: 0
        }
      });
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('dateSelector', {
      bindings: AbstractDateSelectorComponent.bindings({
        // initialDate is expected as a "yyyy-MM-dd" date string, not a Date object, as will be received from integrated services.
        initialDate: '<',
        // providing a max date of null removes the default max date of today
        maxDate: '<',
        // providing a min date of null removes the default min date of today
        minDate: '<',
        onSelectionChange: '&'
      }),
      controller: DateSelectorController,
      templateUrl: 'templates/components/date-selector.component.html'
    });
})();
