/* globals Ready, Configuration, Enums, DateUtils */
(function () {
  'use strict';

  class PipelineServiceWorkflowExecutionsListController {
    static get $inject () {
      return ['dataPipeline'];
    }

    constructor (dataPipeline) {
      this.dataPipeline = dataPipeline;
      this.timeZone = Configuration.scopes.current().timeZone;
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.executionsList),
        isLoadingFn: () => this.loading
      });
    }

    $onInit () {
      this.loading = true;
      this.executionsList = [];
      this.dataPipeline.getWorkflowExecutions(this.workflow.scope, this.datasetDate, this.workflow.namespace, this.workflow.dataset).then((executionsList) => {
        this.executionsList = executionsList.map((execution) => {
          const formattedDateTime = DateUtils.of(execution.datasetDateTime).tz(this.timeZone).format(Enums.DateFormat.TimeZoneAbbrDateTime);
          return _.set(execution, 'datasetDateTime', formattedDateTime);
        });
      }).finally(() => this.loading = false);
    }
  }

  angular.module('application.components')
    .component('pipelineServiceWorkflowExecutionsList', {
      bindings: {
        /*
         * @datasetDate is the date that the workflow ran for.
         */
        datasetDate: '<',
        /*
         * @workflow is an object of type DataPipelineWorkflow and requires the following properties:
         *    scope - string the scope code of the workflow
         *    namespace - string the namespace of the workflow
         *    dataset - string the dataset of the workflow
         */
        workflow: '<'
      },
      controller: PipelineServiceWorkflowExecutionsListController,
      templateUrl: 'templates/components/pipeline-service-workflow-executions-list.component.html'
    });
})();
