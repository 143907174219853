/* globals AbstractElementComponent */
(function () {
  'use strict';
  class DownloadButtonController extends AbstractElementComponent {
    onClick (format) {
      if (!this.isDisabled()) {
        this.onSelection({ format });
      }
    }

    showSplitButton () {
      return this.supportCsv || this.supportFlatXlsx || this.supportXlsx;
    }
  }

  angular.module('application.components')
    .component('downloadButton', {
      bindings: {
        // Must be one of: CSV, FlatXLSX, XLSX
        defaultSelection: '@',
        iconButton: '<',
        onSelection: '&',
        supportCsv: '<',
        supportFlatXlsx: '<',
        supportXlsx: '<'
      },
      controller: DownloadButtonController,
      templateUrl: 'templates/components/download-button.component.html'
    });
})();
