/* globals Binding, DateUtils, Enumeration, Enums, Ready */
(function () {
  'use strict';
  // TODO: Move to Enumerations as 'Enums.ChartType'
  const CHART_TYPE = Enumeration
    .create('LINE_CHART', 'MULTI_BAR_CHART', 'PIE_CHART')
    .asStringValue(_.camelCase);
  const CHARTS_DISABLED_MESSAGE = 'Charts are disabled when data is a mix of <strong>Units</strong> and <strong>Percent</strong>/<strong>Delta</strong>. Please make sure all the data is in <strong>Units</strong> to enable charts.';
  const INPUT_KEY_DELIMITER = ' / ';
  // Max charts inputs number derived based on user feedback & performance testing.
  const MAX_CHART_INPUTS = 5;

  function getNoChartDataMessage (chartInputKey) {
    return `No chart data available for <strong>${chartInputKey}</strong>`;
  }

  class GridController {
    static get $inject () {
      return ['alerts', '$attrs'];
    }

    constructor (alerts, $attrs) {
      this.alerts = alerts;
      this.$attrs = $attrs;
      this.state = Ready.create({
        isDataEmptyFn: () => _.isNil(this.body) || this.body.length <= 0,
        isFooterDataEmptyFn: () => _.isNil(this.footer) || this.footer.length <= 0,
        isHeaderDataEmptyFn: () => _.isNil(this.header) || this.header.length <= 0,
        isLoadingFn: () => _.isNil(this.body)
      });
    }

    _displayChart () {
      this.showChart = true;
      this.onChartInputChange();
      this.chartRefresh = DateUtils.epoch();
    }

    _initializeChart () {
      // Initialize chart configuration. Property 'title' will be set on chartConfiguration as per chart input selections.
      this.chartConfiguration = this.chartData.options;
      if (_.isEmpty(this.chartData.inputSelections)) {
        // No input selections received. Set first item as the default selection for all input labels.
        this.chartData.inputLabels.forEach((inputLabel) => _.head(this.chartInputs).selection[inputLabel] = _.head(this.chartData.inputOptions[inputLabel]));
        // Incremented here for the first chart input. Gets incremented by addChartInput() when remaining inputs are added.
        this.activeChartInputs++;
        return;
      }
      // Input selections received. Set them as default selections.
      this.chartData.inputSelections.forEach((input, index) => {
        if (_.isEmpty(input)) {
          return;
        }
        this.addChartInput();
        _.forEach(input, (selection, inputLabel) => this.onChartInputChange(this.chartInputs[index], inputLabel, selection));
        this.chartInputs[index].expanded = false;
      });
    }

    _resetBody () {
      if (!_.isNil(this.$attrs.gridVirtualScroll)) {
        // If grid-virtual-scroll is designated on the grid then we do not want the base _resetBody to ever run.
        // Due to how a component is initialized the $onInit for this component is executed before the grid-virtual-scroll
        // is executed to override the _resetBody method.
        return;
      }
      if (this.state.isDataEmpty()) {
        this.bodyRows.length = 0;
        return;
      }
      this.bodyRows = this.body.rowSupplier();
    }

    _resetHeader () {
      if (this.state.isHeaderDataEmpty()) {
        this.headerRows.length = 0;
        return;
      }
      this.headerRows = this.header.rowSupplier();
    }

    _resetFooter () {
      if (this.state.isFooterDataEmpty()) {
        this.footerRows.length = 0;
        return;
      }
      this.footerRows = this.footer.rowSupplier();
    }

    _setChartTitle (chartInputKey = _.get(_.head(this.chartInputs), 'key')) {
      if (this.activeChartInputs === 1) {
        // For a single chart input, chart title is comprised of a single chart input key.
        this.chartConfiguration.title = chartInputKey;
        return;
      }
      const titleList = [];
      this.chartInputs.forEach((input) => {
        if (!_.isNil(input.key)) {
          titleList.push(...input.key.split(INPUT_KEY_DELIMITER));
        }
      });
      // For a multiple chart inputs, chart title is comprised of a multiple chart input keys joined with unique key substrings.
      this.chartConfiguration.title = _.uniq(titleList).join(INPUT_KEY_DELIMITER);
    }

    _setChartPlottingData (chartInputKey = _.get(_.head(this.chartInputs), 'key')) {
      this.chartPlottingData = [];
      if (this.activeChartInputs === 1) {
        // Single chart input scenario.
        if (_.isNil(this.chartData.data[chartInputKey])) {
          this.alerts.warning(getNoChartDataMessage(chartInputKey));
        }
        this.chartPlottingData = _.defaultTo(this.chartData.data[chartInputKey], []);
        return;
      }
      // Multiple chart inputs scenario.
      this.chartInputs.forEach((input) => {
        if (_.isNil(input.key)) {
          return;
        }
        const chartPlottingData = this.chartData.data[input.key];
        if (_.isNil(chartPlottingData)) {
          this.alerts.warning(getNoChartDataMessage(input.key));
          return;
        }
        chartPlottingData.forEach((item) => {
          // Append chart input key to plot data keys in the multiple inputs scenario.
          this.chartPlottingData.push(Object.assign(_.clone(item), { key: `${item.key} (${input.key})` }));
        });
      });
    }

    addChartInput () {
      this.chartInputs[this.activeChartInputs].visible = true;
      this.chartInputs[this.activeChartInputs].expanded = true;
      this.activeChartInputs++;
    }

    callResetBody () {
      return this._resetBody();
    }

    isAddVisible (index) {
      return index > 0 && index === this.activeChartInputs && !this.chartInputs[index].visible && !_.isNil(this.chartInputs[index - 1].key);
    }

    isBarChartVisible () {
      return this.showChart && this.showChartType === CHART_TYPE.MULTI_BAR_CHART;
    }

    isBarChartIconVisible () {
      return this.state.isReady() && this.isChartingAvailable && this.showChartType !== CHART_TYPE.MULTI_BAR_CHART;
    }

    isChartInputVisible (chartInput, inputLabel) {
      return chartInput.key !== Enums.AggregateType.TOTALS || chartInput.selection[inputLabel] === Enums.AggregateType.TOTALS;
    }

    isDownloadDisabled () {
      return this.state.isDataEmpty() && this.state.isFooterDataEmpty();
    }

    isGridVisible () {
      return !this.showChart;
    }

    isLineChartVisible () {
      return this.showChart && this.showChartType === CHART_TYPE.LINE_CHART;
    }

    isLineChartIconVisible () {
      return this.state.isReady() && this.isChartingAvailable && this.showChartType !== CHART_TYPE.LINE_CHART;
    }

    isRemoveVisible (index) {
      return index > 0 && index + 1 === this.activeChartInputs;
    }

    onChartInputChange (chartInput = _.head(this.chartInputs), inputLabel, selection) {
      if (!_.isNil(inputLabel)) {
        if (selection === chartInput.selection[inputLabel]) {
          // New selection value is the same as the existing selection value. Do nothing.
          return;
        }
        chartInput.selection[inputLabel] = selection;
      }
      const allSelections = _.map(this.chartData.inputLabels, (label) => chartInput.selection[label]);
      if (_.some(allSelections, _.isNil)) {
        // Chart input is incomplete as all the input values of the chart input have not been selected yet.
        // Skip onChartInputSelectionChange() callback and plotting the chart.
        return;
      }
      if (!_.isNil(inputLabel)) {
        // Make the onChartInputSelectionChange() callback since this is an input change from user interaction and chart input is complete.
        this.onChartInputSelectionChange({ inputs: _.map(this.chartInputs, 'selection') });
      }
      const chartInputKey = selection === Enums.AggregateType.TOTALS ? selection : allSelections.join(INPUT_KEY_DELIMITER);
      chartInput.key = chartInputKey;
      chartInput.visible = true;
      this._setChartTitle(chartInputKey);
      this._setChartPlottingData(chartInputKey);
    }

    removeChartInput (index) {
      this.chartInputs[index] = {
        expanded: false,
        selection: {},
        visible: false
      };
      this.activeChartInputs--;
      this._setChartTitle();
      this._setChartPlottingData();
      // Make the onChartInputSelectionChange() callback to update the input change.
      this.onChartInputSelectionChange({ inputs: _.map(this.chartInputs, 'selection') });
    }

    showBarChart () {
      if (this.disableCharts) {
        this.alerts.warning(CHARTS_DISABLED_MESSAGE);
        return;
      }
      this.showChartType = CHART_TYPE.MULTI_BAR_CHART;
      this._displayChart();
    }

    showGrid () {
      this.showChart = false;
      this.showChartType = undefined;
    }

    showLineChart () {
      if (this.disableCharts) {
        this.alerts.warning(CHARTS_DISABLED_MESSAGE);
        return;
      }
      this.showChartType = CHART_TYPE.LINE_CHART;
      this._displayChart();
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }
      if (Binding.changes.has(changes.header)) {
        this._resetHeader();
      }
      if (Binding.changes.has(changes.body)) {
        this._resetBody();
      }
      if (Binding.changes.has(changes.footer)) {
        this._resetFooter();
      }
      if (Binding.changes.has(changes.chartData)) {
        this._initializeChart();
        if (this.viewMode === Enums.DataPackage.ViewMode.CHART) {
          this.showBarChart();
        }
      }
    }

    $onInit () {
      this.showChart = false;
      this.chartInputs = _.map(_.range(MAX_CHART_INPUTS), () => ({
        expanded: false,
        // selection is a map of current selections per input label.
        selection: {},
        visible: false
      }));
      this.activeChartInputs = 0;
      this.bodyRows = [];
      this.footerRows = [];
      this.headerRows = [];
      this._resetHeader();
      this._resetBody();
      this._resetFooter();
      this.state.isInitialized(true);
    }

    overwriteResetBody (fn) {
      this._resetBody = fn;
    }
  }

  angular.module('application.components')
    .component('grid', {
      /*
       * @header, @body, and @footer all support the same @rowSupplier function:
       *   @rowSupplier Function(rowStart, rowEnd) ==> returns array of data rows with a row defined as:
       *     @headers [] ==> row header fields:
       *       @class Mixed provided to ng-class
       *       @colspan String
       *       @rowspan String
       *       @title String
       *       @value String
       *     @data [] ==> row data fields:
       *       @class Mixed provided to ng-class
       *       @title String
       *       @value String
       */
      bindings: {
        /*
         * @body is an object containing accessor for data:
         *   @length Number: total number of rows in the dataset
         *   @rowSupplier Function: See @rowSupplier definition above
         */
        body: '<',
        /*
         * @chartData (optional) is an object containing chart data
         *   @data is an object that contains chart plotting data in the following format:
         *     {
         *       'PL1 / ST1 / FC1': [
         *         {
         *           color: '#...',  // Optional. If not provided, chart library picks a random color.
         *           key: 'Primary',
         *           values: [
         *             { x: '1', y: 100 },
         *             { x: '2', y: 80 },
         *             ...
         *           ]
         *         }
         *       ]
         *     }
         *   @inputSelections is an array containing the chart inputs in the following format:
         *     [
         *       { FC: 'FC1', PL: 'PL1', ST: 'ST1' },
         *       { FC: 'FC2', PL: 'PL1', ST: 'ST1' }
         *     ]
         *   @inputLabels is an array containing the input labels like 'PL', 'ST', 'FC', etc.
         *   @inputOptions is an object containing input label to input options mapping in the following format:
         *     {
         *       FC: ['FC1', 'FC2', 'FC3', ...],
         *       PL: ['PL1', 'PL2', 'PL3', ... , 'Totals'],
         *       ST: ['ST1', 'ST2', 'ST3', ...]
         *     }
         *   @options is an object containing the chart configuration in the following format:
         *     {
         *       chart: {
         *         interactiveLayer: {
         *           tooltip: {
         *             headerFormatter: () => { ... }
         *           }
         *         },
         *         xAxis: {
         *           axisLabel: ' ... ',
         *           tickFormat: () => { ... }
         *         },
         *         yAxis: {
         *           axisLabel: ' ... '
         *         }
         *       }
         *     }
         */
        chartData: '<',
        /*
         * @disableCharts Boolean: If true, the chart action icon buttons are disabled. If false, they are enabled. (defaults to false)
         */
        disableCharts: '<',
        /*
         * @download Function: a callback for downloading the contents of a grid
         */
        download: '&',
        /*
         * @footer is an optional attribute that is an array containing footer data with each row defined as:
         *   @length Number: total number of footer rows
         *   @rowSupplier Function: See @rowSupplier definition above
         */
        footer: '<',
        /*
         * @header is an object containing header data:
         *   @id String: the unique, HTML compliant, identifier of the grid to be used as a bookmark attribute
         *   @length Number: number of header rows in table
         *   @rowSupplier Function: See @rowSupplier definition above
         *   @stickyHeaderRows Boolean: flag indicating whether to make table header rows sticky (default is true)
         *   @stickyHeaderColumns Boolean: flag indicating whether to make table header columns sticky (default is false)
         *   @title String: title of the grid
         */
        header: '<',
        /*
         * @isChartingAvailable Boolean: If true, the chart action icon buttons are displayed/visible. If false, they are hidden. (defaults to false)
         */
        isChartingAvailable: '<',
        /*
         * @isDownloadAvailable Boolean: If true, the download button component is displayed/visible. If false, it is hidden (defaults to false)
         */
        isDownloadAvailable: '<',
        /*
         * @onChartInputSelectionChange Function: a callback for change in chart input selections
         */
        onChartInputSelectionChange: '&',
        /*
         * @viewMode String: Represents the default view mode in which the data should be presented. (Grid / Chart)
         */
        viewMode: '<'
      },
      controller: GridController,
      templateUrl: 'templates/components/grid.component.html'
    });
})();
