/* globals Validate */
(function () {
  'use strict';

  class OverlayService {
    static get $inject () {
      return ['$location'];
    }

    constructor ($location) {
      this.$location = $location;
      this.isOverlayDisplayed = false;
    }

    isDisplayed () {
      return this.isOverlayDisplayed;
    }

    isTrackingProgress () {
      return this.isDisplayed() && this.showProgress.max > 0;
    }

    hide () {
      if (!this.isDisplayed()) {
        return;
      }
      this.isOverlayDisplayed = false;
      delete this.showMessage;
      delete this.showProgress;
      delete this.showStartTime;
    }

    message (message) {
      if (this.isDisplayed() && !Validate.isBlank(message)) {
        this.showMessage = message;
      }
      return this.showMessage;
    }

    progress (value) {
      if (this.isDisplayed() && this.isTrackingProgress() && value >= 0 && value <= this.showProgress.max) {
        this.showProgress.value = value;
      }
      return this.showProgress;
    }

    show (message, progressMax = 0) {
      if (this.isDisplayed() || Validate.isBlank(message) || progressMax < 0) {
        return;
      }
      this.isOverlayDisplayed = true;
      this.showMessage = message;
      this.showProgress = {
        max: progressMax,
        type: 'success',
        value: 0
      };
      this.showStartTime = new Date();
    }
  }

  angular.module('application.services').service('overlay', OverlayService);
})();
