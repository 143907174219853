/* globals AbstractElementComponent, Binding, Comparison, Configuration, Enums */
(function () {
  'use strict';

  /*
   * TODO: As of now, PlanMetadata isCapacityPlan() method is being used to verify plan types against a hardcoded list
   *       of plans being classified as Capacity plans. This is a temporarily solution and would exist on the Portal side
   *       until a namespace/tenant property is added in Plan Definition that classifies a plan as Capacity plan.
   */
  function capacityPlansFilter (plan) {
    return (Configuration.scopes.current().isOfTenantCapacity() && plan.isCapacityPlan())
      || (!Configuration.scopes.current().isOfTenantCapacity() && !plan.isCapacityPlan());
  }

  class PlanToEditSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$authentication', '$element', 'planConfiguration', 'planStore', '$q'];
    }

    constructor ($authentication, $element, planConfiguration, planStore, $q) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.plansList),
        isLoadingFn: () => this.loading,
        isNoSelectionFn: () => _.isNil(this.selectedPlan)
      });
      this.$authentication = $authentication;
      this.planConfiguration = planConfiguration;
      this.planStore = planStore;
      this.$q = $q;
      this.loading = true;
      this.plansList = [];
      this.selectedPlan = undefined;
      this.selectedPlanName = undefined;
    }

    _updateInitialPlanSelection () {
      if (_.isNil(this.initialPlan) || _.isEmpty(this.plansList)) {
        return;
      }

      // The uploading of XLSX presents a special issue with how this component works. We do not want to modify this metadata since it is valid and indicates
      // what download version the user is editing. In the case of the Create scenario there is also no plan metadata available in the list so we should do
      // nothing in this scenario as a valid plan metadata object can only come from an XLSX upload.
      if (this.initialPlan.isValid()) {
        return;
      }

      // For editing we do not want to attempt to load a plan that is no longer in the list and we always want to update to the
      // latest version of a plan. The initial plan is always found in the list whether the value comes from a Shareable URL or User View.
      const selectedPlan = _.find(this.plansList, (plan) => Comparison.arePlansEqual(this.initialPlan, plan));
      this.onSelected(selectedPlan);
    }

    _loadData () {
      this.loading = true;
      this.plansList.length = 0;
      this.selectedPlan = undefined;
      this.selectedPlanName = undefined;
      this.$q.all([this.planStore.editable(), this.planConfiguration.planDefinition().list()])
        .then((data) => {
          const planMetadataList = data[0];
          const planDefinitionList = data[1];
          const filteredPlanList = planMetadataList.filter((planMetadata) => {
            const planDefinition = planDefinitionList.find((definition) => definition.planType === planMetadata.type);
            planMetadata.name = planDefinition.displayName;
            return capacityPlansFilter(planMetadata) && this.$authentication.hasAccess(planDefinition.writeAccessRole());
          });
          this.plansList.push(...filteredPlanList);
          this._updateInitialPlanSelection();
        })
        .finally(() => this.loading = false);
    }

    isDisabled () {
      return _.isEmpty(this.plansList) || super.isDisabled();
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      // Do not perform any further action if the change is that the initialPlan is the selectedPlan
      if (!_.isNil(this.selectedPlan) && Binding.changes.current(changes.initialPlan) === this.selectedPlan) {
        return;
      }

      this._updateInitialPlanSelection();
    }

    onSelected (plan) {
      this.selectedPlan = plan;
      this.selectedPlanName = plan.displayName(Enums.Plan.DisplayFormat.LONG);
      this.onSelectionChange({ plan });
    }
  }

  angular.module('application.components')
    .component('planToEditSelector', {
      bindings: {
        initialPlan: '<',
        onSelectionChange: '&'
      },
      controller: PlanToEditSelectorController,
      templateUrl: 'templates/components/plan-to-edit-selector.component.html'
    });
})();
