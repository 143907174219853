/* globals Grain */
(function () {
  'use strict';

  class GrainMappingGroup {
    constructor (granularityKeys, name, grainMappings) {
      this.name = name;
      this.grainMappings = grainMappings;

      if (_.size(granularityKeys) !== 2 || !_.every(granularityKeys, (granularityKey) => granularityKey instanceof Grain)) {
        throw new Error(`GrainMappingGroup: granularityKeys must be a list of two Grain instances: "${granularityKeys}"`);
      }
      this.grainA = _.head(granularityKeys);
      this.grainB = _.last(granularityKeys);
    }

    static create (granularityKeys, name = '', grainMappings = []) {
      return new GrainMappingGroup(granularityKeys, name, grainMappings);
    }

    addMapping (grainAValue, grainBValue) {
      if (this.hasMapping(grainAValue, grainBValue)) {
        return;
      }
      this.grainMappings.push({ [this.grainA.id]: grainAValue, [this.grainB.id]: grainBValue });
    }

    hasMapping (grainAValue, grainBValue) {
      return _.some(this.grainMappings, { [this.grainA.id]: grainAValue, [this.grainB.id]: grainBValue });
    }

    removeMapping (grainAValue, grainBValue) {
      _.remove(this.grainMappings, { [this.grainA.id]: grainAValue, [this.grainB.id]: grainBValue });
    }
  }
  window.GrainMappingGroup = Object.freeze(GrainMappingGroup);
})();
