/* globals AbstractPackagerService, Comparison, DataPackage, DateUtils, Enums, Grid, Name */
(function () {
  'use strict';

  const DATASET_SORT_ORDER = Object.freeze(['Baseline', 'Prefinal', 'Final', 'Edits', 'Preview']);
  const DATASET_KEYS = Object.freeze(DATASET_SORT_ORDER.map((key) => _.lowerCase(key)));

  class DataPackager {
    constructor (metric, grains, selections, dates, action) {
      this.action = action;
      this.dates = dates;
      this.grains = grains;
      this.metric = metric;
      this.selections = selections;
    }

    transform (promises) {
      return promises
        .then(this.populate.bind(this))
        .then(this.resize.bind(this))
        .then(this.addEditsRow.bind(this))
        .then(this.addPreviewRow.bind(this))
        .then(this.setMetricReferences.bind(this))
        .then(this.collapse.bind(this))
        .then(this.concatenate.bind(this))
        .then(this.sort.bind(this))
        // If parsing the dataset fails, return an empty list so DataPackage.records is always an array
        .catch(() => []);
    }

    populate (datasets) {
      DATASET_KEYS.forEach((key) => {
        if (_.isNil(datasets[key])) {
          return;
        }
        // If data was returned from Plan-Store, copy it to the pre-constructed grid.
        if (!_.isEmpty(datasets[key].records)) {
          const newValuesLength = datasets[key].dates.length;
          this.selections.forEach((selection) => {
            const existingData = datasets[key].records.find((record) => _.isEqual(record.granularity, selection.granularity));
            selection.values = _.isNil(existingData) ? Array(newValuesLength).fill(null) : existingData.values;
          });
        }
        // If no records were returned, and thus no dates were returned, display the
        // initially calculated date range.
        if (_.isEmpty(datasets[key].dates)) {
          datasets[key].dates = this.dates;
        }
        // Copy the pre-constructed grid to each dataset.
        datasets[key].records = _.cloneDeep(this.selections);
      });
      return datasets;
    }

    resize (datasets) {
      DATASET_KEYS.forEach((key) => {
        if (_.isNil(datasets[key])) {
          return;
        }
        const result = DateUtils.dateArrayComparator(this.dates, datasets[key].dates);
        const options = {
          append: result.postfix.count,
          prepend: result.prefix.count,
          property: 'values'
        };
        Grid.resize(datasets[key].records, options);
      });
      return datasets;
    }

    addEditsRow (datasets) {
      datasets.edits = _.cloneDeep(datasets.prefinal);
      datasets.edits.records.forEach((record) => record.values.fill(null));
      return datasets;
    }

    addPreviewRow (datasets) {
      if (this.action === Enums.UserAction.VIEW) {
        datasets.preview = _.cloneDeep(datasets.prefinal);
      }
      return datasets;
    }

    setMetricReferences (datasets) {
      DATASET_KEYS.forEach((key) => _.forEach(_.get(datasets[key], 'records'), (row) => row.metric = this.metric));
      return datasets;
    }

    collapse (datasets) {
      DATASET_KEYS.forEach((key) => {
        if (_.isNil(datasets[key])) {
          return;
        }
        datasets[key] = {
          dates: datasets[key].dates,
          records: datasets[key].records.map((record) => {
            record.granularity.metric = Name.ofMetric(record.metric);
            record.granularity.draft = _.capitalize(key);
            record.dataType = _.capitalize(key);
            return record;
          })
        };
      });
      return datasets;
    }

    concatenate (datasets) {
      return _.concat(
        _.get(datasets.baseline, 'records', []),
        datasets.prefinal.records,
        datasets.edits.records,
        this.action === Enums.UserAction.VIEW ? datasets.preview.records : [],
        _.get(datasets.final, 'records', []));
    }

    sort (dataset) {
      return Comparison.sort(dataset, DATASET_SORT_ORDER, this.grains.values(Enums.GrainFilter.IS_NATIVE), this.metrics);
    }
  }

  class EditPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['planStore', '$q'];
    }

    constructor (planStore, $q) {
      super($q);
      this.planStore = planStore;
    }

    _getMetric (metric, grains, filters, plan, draft) {
      const getMetadataPromise = draft === Enums.Plan.DraftType.PRE_FINAL
        ? this.$q.resolve(plan)
        : this.planStore.planMetadata(plan, { draft });
      return getMetadataPromise
        .then((metadata) => {
          if (_.isEmpty(metadata.source)) {
            return;
          }
          const body = {
            filters: filters,
            groupBy: grains.values(Enums.GrainFilter.IS_NATIVE, Enums.GrainFilter.IS_EDITABLE),
            periodEndDate: metadata.endDate,
            periodStartDate: metadata.date
          };
          return this.planStore.plan(metadata, body, { draft: draft, flow: metric.id, version: metadata.version });
        })
        // If a draft has metadata but no data swallow the error so other draft calls succeed.
        .catch(_.noop);
    }

    collect (dates, metric, configuration, action) {
      const transformer = new DataPackager(
        metric,
        configuration.granularity.grains,
        this.generateSelectionCrossProduct(configuration.filters, dates.length),
        dates,
        action);
      const promises = {
        baseline: this._getMetric(metric, configuration.granularity.grains, configuration.filters, configuration.primary.plan, Enums.Plan.DraftType.BASELINE),
        final: this._getMetric(metric, configuration.granularity.grains, configuration.filters, configuration.primary.plan, Enums.Plan.DraftType.FINAL),
        prefinal: this._getMetric(metric, configuration.granularity.grains, configuration.filters, configuration.primary.plan, Enums.Plan.DraftType.PRE_FINAL)
      };

      return DataPackage.create({
        dates: dates,
        editType: configuration.editType,
        filters: configuration.filters,
        flow: metric,
        granularity: configuration.granularity,
        groupBy: configuration.groupBy.values(),
        metrics: configuration.metrics.list,
        plan: configuration.primary.plan,
        records: transformer.transform(this.$q.all(promises)),
        title: Name.ofMetric(metric),
        totals: [],
        type: Enums.DataPackage.PackageType.EDIT,
        viewGrainFilter: Enums.GrainFilter.IS_VIEW_EDIT
      }, this.$q);
    }
  }

  angular.module('application.services').service('editPackager', EditPackagerService);
})();
