/* global AbstractPackagerService, Comparison, DataPackage, DateUtils, Enums, Grid, Name */
(function () {
  'use strict';

  const DATASET_SORT_ORDER = Object.freeze(['Baseline', 'Prefinal', 'Edits', 'Preview']);
  const DATASET_KEYS = Object.freeze(DATASET_SORT_ORDER.map((key) => _.lowerCase(key)));

  const extractGrainKey = (grain) => _.reduce(grain.split('-'), (accumulator, value) => {
    value = value.split(':');
    accumulator[value[0]] = value[1];
    return accumulator;
  }, {});

  const grainKey = (...planGranularityItems) => _.map(_.assign({}, ...planGranularityItems), (value, key) => `${key}:${value}`).join('-');

  class DataPackager {
    constructor (metric, grains, dates, action) {
      this.action = action;
      this.dates = dates;
      this.grains = grains;
      // metric.displayType is always set to 'decimal', this should not affect the metric reference in the controller
      this.metric = _.clone(metric);
    }

    transform (promises) {
      return promises
        .then(this.groupByGranularity.bind(this))
        .then(this.sortByDate.bind(this))
        .then(this.addEditsRow.bind(this))
        .then(this.addPreviewRow.bind(this))
        .then(this.collapse.bind(this))
        .then(this.resize.bind(this))
        .then(this.concatenate.bind(this))
        .then(this.sort.bind(this))
        // If parsing the dataset fails, return an empty list so DataPackage.records is always an array
        .catch(() => []);
    }

    groupByGranularity (datasets) {
      const group = (dataset) => {
        if (_.isNil(dataset)) {
          return;
        }
        const set = {};
        dataset.ratioDataset.ratioDatumSet.forEach((algorithm) => {
          algorithm.destinationRatioList.forEach((destinationRatio) => {
            const key = grainKey(
              algorithm.sourceGranularity.planGranularityItems,
              destinationRatio.destGranularity.planGranularityItems
            );
            set[key] = set[key] || { values: [] };
            set[key].values.push({
              date: destinationRatio.destGranularity.timeGranularityItems.forecastDay || algorithm.sourceGranularity.timeGranularityItems.forecastWeek,
              ratio: destinationRatio.ratio
            });
          });
        });
        return set;
      };
      datasets.baseline = group(datasets.baseline);
      datasets.prefinal = group(datasets.prefinal);
      return datasets;
    }

    // See: https://sim.amazon.com/issues/SOP-4760 and https://issues.amazon.com/issues/SOP-7920.
    // Ratio Store is not amiable to sorting server-side or providing contiguous datasets that align with all dates declared in metadata.
    sortByDate (datasets) {
      const dateIndexHash = _.transform(this.dates, (hash, value, index) => hash[value] = index, {});

      ['baseline', 'prefinal'].forEach((key) => {
        if (_.isNil(datasets[key])) {
          return;
        }
        const dateSortedDataset = {};
        _.forEach(datasets[key], (record, grainKey) => {
          const sortedValues = _.fill(Array(this.dates.length), null);
          _.forEach(record.values, (value) => {
            if (_.has(dateIndexHash, value.date)) {
              sortedValues[dateIndexHash[value.date]] = value.ratio;
            }
          });
          dateSortedDataset[grainKey] = {
            dates: this.dates,
            values: sortedValues
          };
        });
        datasets[key] = dateSortedDataset;
      });
      return datasets;
    }

    addEditsRow (datasets) {
      datasets.edits = _.cloneDeep(datasets.prefinal);
      _.forEach(datasets.edits, (record, key) => datasets.edits[key].values = record.values.map(() => null));
      return datasets;
    }

    addPreviewRow (datasets) {
      if (this.action === Enums.UserAction.VIEW) {
        datasets.preview = _.cloneDeep(datasets.prefinal);
      }
      return datasets;
    }

    collapse (datasets) {
      DATASET_KEYS.forEach((key) => {
        datasets[key] = _.map(datasets[key], (record, grainKey) => {
          const granularity = extractGrainKey(grainKey);
          granularity.draft = _.capitalize(key);
          granularity.metric = Name.ofMetric(this.metric);
          return {
            dataType: _.capitalize(key),
            dates: record.dates,
            granularity: granularity,
            metric: Object.assign(this.metric, { dataType: Enums.DataPackage.MetricDataType.DECIMAL }),
            values: record.values
          };
        });
      });
      return datasets;
    }

    resize (datasets) {
      DATASET_KEYS.forEach((key) => {
        if (_.isEmpty(datasets[key])) {
          return;
        }
        const result = DateUtils.dateArrayComparator(this.dates, _.head(datasets[key]).dates);
        const options = {
          append: result.postfix.count,
          prepend: result.prefix.count,
          property: 'values'
        };
        Grid.resize(datasets[key], options);
      });
      return datasets;
    }

    concatenate (datasets) {
      return _.concat(
        datasets.baseline,
        datasets.prefinal,
        datasets.edits,
        this.action === Enums.UserAction.VIEW ? datasets.preview : []
      );
    }

    sort (dataset) {
      return Comparison.sort(dataset, DATASET_SORT_ORDER, this.grains.values(Enums.GrainFilter.IS_NATIVE), this.metrics);
    }
  }

  class RatioPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['planStore', '$q'];
    }

    constructor (planStore, $q) {
      super($q);
      this.planStore = planStore;
    }

    _getRatio (metric, filters, plan, draft) {
      const getMetadataPromise = draft === Enums.Plan.DraftType.PRE_FINAL
        ? this.$q.resolve(plan)
        : this.planStore.planMetadata(plan, { draft });
      return getMetadataPromise
        .then((metadata) => {
          if (!_.has(metadata, 'ratioID')) {
            return;
          }
          return this.planStore.ratioByID({
            flow: metric.id,
            ratioID: metadata.ratioID,
            ratioQueryOptions: { filters }
          });
        })
        // If a draft has metadata but no data swallow the error so other draft calls succeed.
        .catch(_.noop);
    }

    collect (dates, metric, configuration, action) {
      const transformer = new DataPackager(metric, configuration.granularity.grains, dates, action);
      const promises = {
        baseline: this._getRatio(metric, configuration.filters, configuration.primary.plan, Enums.Plan.DraftType.BASELINE),
        prefinal: this._getRatio(metric, configuration.filters, configuration.primary.plan, Enums.Plan.DraftType.PRE_FINAL)
      };
      return DataPackage.create({
        dates: dates,
        downloadGrainFilter: Enums.GrainFilter.IS_EDITABLE,
        editType: configuration.editType,
        filters: configuration.filters,
        flow: metric,
        granularity: configuration.granularity,
        groupBy: [],
        metrics: configuration.metrics.list,
        plan: configuration.primary.plan,
        records: transformer.transform(this.$q.all(promises)),
        title: Name.ofMetric(metric),
        totals: [],
        type: Enums.DataPackage.PackageType.EDIT,
        viewGrainFilter: Enums.GrainFilter.IS_EDITABLE
      }, this.$q);
    }
  }

  angular.module('application.services').service('ratioPackager', RatioPackagerService);
})();
