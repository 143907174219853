/* globals Mapper */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'edits',
    'flow',
    'scope',
    'type',
    'updatedBy'
  ]);

  class AllocationTreeEdit {
    constructor (source) {
      if (_.isNil(source)) {
        throw new Error('AllocationTreeEdit: source must not be nil');
      }
      Mapper.apply(source, this, PROPERTIES);
    }

    static create (source) {
      return new AllocationTreeEdit(source);
    }
  }

  window.AllocationTreeEdit = Object.freeze(AllocationTreeEdit);
})();
