/* globals AbstractElementComponent, Binding, Configuration, DateUtils, Enums */
(function () {
  'use strict';
  const ETL_TRIGGER_TYPE = 'ETL';

  class PipelineServiceWorkflowsListController extends AbstractElementComponent {
    static get $inject () {
      return ['dataPipeline', '$element', '$q', '$scope'];
    }

    constructor (dataPipeline, $element, $q, $scope) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.pipelineServiceWorkflowsList),
        isLoadingFn: () => this.loading
      });
      this.dataPipeline = dataPipeline;
      this.$scope = $scope;
      this.$q = $q;
      this.allWorkflows = [];
      this.dataDelayCheckWorkflows = [];
      this.datasetFilter = '';
    }

    _loadAllWorkflows (scope) {
      return this.dataPipeline.getWorkflowStatus(this.datasetDate, scope);
    }

    _loadData () {
      if (_.isEmpty(this.datasetDate)) {
        return;
      }
      if (!_.isNil(this.initialFilters) && _.isString(this.initialFilters.dataset)) {
        this.datasetFilter = this.initialFilters.dataset;
      }
      this.loading = true;
      this.pipelineServiceWorkflowsList = [];
      const currentScope = Configuration.scopes.current();
      const scopes = _.defaultTo(currentScope.subscopes, [currentScope]);
      this.$q.all({ delayStatus: this._getDelayStatus(scopes), workflows: this._getWorkflows(scopes) })
        .then((data) => {
          const workflows = _.flatten(data.workflows);
          const delayedWorkflows = _(data.delayStatus).map('delayedWorkflows').flatten().value();
          this.allWorkflows = _.unionWith(delayedWorkflows, workflows, (delayedWorkflow, workflow) => (
            delayedWorkflow.dataset === workflow.dataset && delayedWorkflow.namespace === workflow.namespace && delayedWorkflow.scope === workflow.scope
          ));
          this.dataDelayCheckWorkflows = _.filter(this.allWorkflows, (workflow) => workflow.namespace === 'Actuals');
        })
        .finally(() => {
          this.loading = false;
          this._loadDataPipelineWorkflowsList();
        });
    }

    _getDelayStatus (scopes) {
      return this.$q.all(_.map(scopes, (scope) => this.dataPipeline.getDataDelayStatus(scope.code, this.datasetDate)));
    }

    _getWorkflows (scopes) {
      return this.$q.all(_.map(scopes, (scope) => this._loadAllWorkflows(scope)));
    }

    _loadDataPipelineWorkflowsList () {
      this.pipelineServiceWorkflowsList = this.actualsDelayCheck ? this.dataDelayCheckWorkflows : this.allWorkflows;
      this.onWorkflowsRefresh({
        completed: _.filter(this.pipelineServiceWorkflowsList, (workflow) => workflow.isSuccess()).length,
        delayed: _.filter(this.pipelineServiceWorkflowsList, (workflow) => workflow.isDelayed()).length,
        noExecutions: _.filter(this.pipelineServiceWorkflowsList, (workflow) => workflow.isNoExecution()).length
      });
      this.additionalDatasetDates = _.reverse(DateUtils.expansion(
        DateUtils.fromOffset(this.datasetDate, -7, Enums.TimeUnit.DAY),
        DateUtils.fromOffset(this.datasetDate, -1, Enums.TimeUnit.DAY),
        Enums.TimeUnit.DAY
      ));
    }

    getAdditionalDateRangeLabel () {
      return `${_.last(this.additionalDatasetDates)} to ${_.first(this.additionalDatasetDates)}`;
    }

    isAddToFiltersIconVisible (showFilterAction, dataset) {
      return showFilterAction && dataset !== this.datasetFilter;
    }

    isRemoveFromFiltersIconVisible (showFilterAction, dataset) {
      return showFilterAction && dataset === this.datasetFilter;
    }

    sortTriggersHandler (workflow) {
      return !_.isEmpty(workflow.etlTriggerUrl) ? ETL_TRIGGER_TYPE : '';
    }

    updateDatasetFilters (event, dataset) {
      event.stopPropagation();
      this.datasetFilter = this.datasetFilter === dataset ? '' : dataset;
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }
      if (Binding.changes.only(changes, 'actualsDelayCheck')) {
        // Do not perform a data reload if actualsDelayCheck is the only change.
        this._loadDataPipelineWorkflowsList();
        return;
      }
      this._loadData();
    }

    $onInit () {
      this.$scope.$on(Enums.BroadcastChannel.AUTO_REFRESH, this._loadData.bind(this));
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('pipelineServiceWorkflowsList', {
      bindings: {
        /*
         * @actualsDelayCheck boolean flag specifying whether the pipelineServiceWorkflowsList should be filtered for data delay check workflows.
         */
        actualsDelayCheck: '<',
        /*
         * @datasetDate is the date that workflows should be retrieved for.
         */
        datasetDate: '<',
        /*
         * @initialFilters supports:
         *   dataset
         *   namespace
         *   scope
         *   status
         */
        initialFilters: '<',
        /*
         * @onWorkflowsRefresh is a callback to the parent whenever the workflows have been refreshed.
         * It takes as named arguments: @completed, @delayed, dand @noExecutions
         */
        onWorkflowsRefresh: '&'
      },
      controller: PipelineServiceWorkflowsListController,
      templateUrl: 'templates/components/pipeline-service-workflows-list.component.html'
    });
})();
