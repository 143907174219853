/* globals AbstractElementComponent, Binding, Configuration, Grain, Granularities */
(function () {
  'use strict';
  const HOW_TO_VIEW_PLACEHOLDER = 'Make a selection...';

  class AllocationTreeViewSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element) {
      super($element);
    }

    _getSelections (planType, metricId) {
      return Configuration.allocationTreeViewOptions[planType]
        .filter((option) => option.for(metricId) && Configuration.activation.isAvailable(option.id))
        .map((option) => ({
          gridGrouping: Granularities.create(_.map(option.gridGrouping, (grain) => Grain.create(grain))),
          groupBy: Granularities.create(_.map(option.groupBy, (grain) => Grain.create(grain))),
          groupByFilters: option.groupByFilters(Configuration.scopes.current().code),
          id: option.id,
          name: option.name
        }));
    }

    _initialSelection () {
      if (!_.isNil(this.groupBy) && this.groupBy.id === this.initialSelectionIdentifier) {
        // Do nothing if the currently selected option is the same as the initialSelectionIdentifier.
        return;
      }
      const identifierInGroupByOptions = _.find(this.groupByOptions, { id: this.initialSelectionIdentifier });
      if (_.isNil(this.groupBy) && _.isNil(identifierInGroupByOptions)) {
        // Set placeholder label and do nothing if this component is loading for the first time or flow was changed such that 'groupByOptions' does not contain 'initialSelectionIdentifier'.
        this.groupBy = {
          name: HOW_TO_VIEW_PLACEHOLDER
        };
        return;
      }
      const initialSelection = identifierInGroupByOptions || _.head(this.groupByOptions);
      this.onSelectionChangeHandler(initialSelection);
    }

    _loadData () {
      if (_.isNil(this.planType) || !_.has(this.metric, 'id')) {
        // Pending configuration.
        this.groupByOptions = undefined;
        this.onSelectionChangeHandler();
        return;
      }

      this.groupByOptions = this._getSelections(this.planType, this.metric.id);
      this._initialSelection();
    }

    displayNameFn (value) {
      return _.get(value, 'name');
    }

    onSelectionChangeHandler (selection) {
      this.groupBy = selection;
      this.onSelectionChange({ selection: this.groupBy });
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (Binding.changes.only(changes, 'initialSelectionIdentifier')) {
        // Do not perform a data reload if the initialSelectionIdentifier is the only change.
        this._initialSelection();
        return;
      }

      if (Binding.changes.none(changes.metric, changes.planType)) {
        // Do not perform a data reload if the metric or planType have not changed.
        return;
      }

      if (Binding.changes.has(changes.metric)) {
        // Reset groupBy if 'metric' has changed.
        this.groupBy = undefined;
      }

      this._loadData();
    }
  }

  angular.module('application.components')
    .component('allocationTreeViewSelector', {
      bindings: {
        /*
         * @initialSelectionIdentifier defines the initial selection preference.
         */
        initialSelectionIdentifier: '<',
        /*
         * @metric the metric to fetch selections for.
         */
        metric: '<',
        /*
         * @onSelectionChange is a callback to the parent whenever the current selection changes.
         */
        onSelectionChange: '&',
        /*
         * @planType the plan type to fetch selections for.
         */
        planType: '<'
      },
      controller: AllocationTreeViewSelectorController,
      templateUrl: 'templates/components/allocation-tree-view-selector.component.html'
    });
})();
