'use strict';
/**
 * Directive to set the smart table search predicate with initial filter values
 *
 * @name application.directives.stSetSearch
 * @example
 * <table st-table st-set-search="$ctrl.initialFilters"/>
 */
angular.module('application.directives')
  .directive('stSetSearch', function () {
    return {
      link: function (scope, element, attrs, stTable) {
        const filters = scope.stSetSearch,
              tableState = stTable.tableState(),
              predicate = {};
        _.keys(filters).forEach(function (key) {
          const value = filters[key];
          if (_.isString(value) && !_.isEmpty(value)) {
            predicate[key] = value;
          }
        });
        tableState.search.predicateObject = predicate;
      },
      require: '^stTable',
      restrict: 'A',
      scope: {
        stSetSearch: '<'
      }
    };
  });
