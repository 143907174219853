/* globals AbstractSourceBasedModel */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'date',
    'metaData',
    'value'
  ]);

  class NetworkPlanValue extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }
  }

  window.NetworkPlanValue = Object.freeze(NetworkPlanValue);
})();
