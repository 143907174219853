/* globals jwt_decode */
(function () {
  'use strict';
  const SECONDS_TO_MILLISECONDS = 1000;

  /**
   * Helper class for handling JWTs: https://jwt.io/.
   */
  class JwtDecode {

    /**
     * Decodes a JWT token using the jwt-decode angular library: https://www.npmjs.com/package/jwt-decode.
     *
     * @param token JWT.
     * @returns the decoded payloaded of the JWT.
     */
    static decode (token) {
      return jwt_decode(token);
    }

    /**
     * Decodes a JWT token and returns the user field.
     *
     * @param token JWT.
     * @returns the alias of the user associated with the token.
     */
    static extractUser (token) {
      // 'sub' is commonly the user of a JWT: https://auth0.com/docs/secure/tokens/json-web-tokens/json-web-token-claims.
      return this.decode(token).sub;
    }

    /**
     * Decodes a JWT token and returns the expiration field.
     *
     * @param token JWT.
     * @returns the expiration of the token.
     */
    static extractExpiration (token) {
      // 'exp' is commonly the expiration of a JWT: https://auth0.com/docs/secure/tokens/json-web-tokens/json-web-token-claims.
      return new Date(this.decode(token).exp * SECONDS_TO_MILLISECONDS).toISOString();
    }
  }

  window.JwtDecode = Object.freeze(JwtDecode);
})();
