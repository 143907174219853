(function () {
  'use strict';
  const KEYS = [ 'base', 'compare1', 'compare2', 'compare3', 'compare4' ];
  const COLORS = {
    base: '#2ca02c',
    compare1: '#77BDFF',
    compare2: '#1717FC',
    compare3: '#C389FA',
    compare4: '#8200FF'
  };
  const X = [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  const Y = {
    base: [142184395, 153872132, 149255216, 128758228, 130045363, 145834268, 171337078, 168448409, 168850513, 177875429, 185312593, 185503982, 172659847],
    compare1: [145663840, 161524653, 170899818, 144707529, 154586285, 168654410, 171337078, 171433333, 171502350, 171426751, 171520686, 172483451, 152474754],
    compare2: [136385528, 141103898, 148566971, 154402847, 149270877, 184313645, 178420257, 180454907, 185707430, 166750518, 149937556, 148501775, 157290266],
    compare3: [146385528, 151103898, 158566971, 164402847, 159270877, 194313645, 188420257, 190454907, 195707430, 176750518, 159937556, 158501775, 167290266],
    compare4: [156385528, 161103898, 168566971, 174402847, 169270877, 194313645, 198420257, 190454907, 195707430, 186750518, 169937556, 168501775, 177290266]
  };
  const LINE_BAR_CHART_CONFIG = {
    interactiveLayer: { tooltip: { headerFormatter: (d) => `Week ${d}` } },
    xAxis: { axisLabel: 'TIME (weeks)', tickFormat: (d) => `Wk ${d}` },
    yAxis: { axisLabel: 'UNITS' }
  };
  const PIE_DONUT_DATA = Object.freeze([
    { key: 'Asia', value: 4581757408 },
    { key: 'Africa', value: 1216130000 },
    { key: 'Europe', value: 738849000 },
    { key: 'North America', value: 579024000 },
    { key: 'South America', value: 422535000 },
    { key: 'Oceania', value: 38304000 },
    { key: 'Antarctica', value: 1106 }
  ]);

  function getValues (key) {
    const values = [];
    X.forEach((xValue, index) => values.push({ x: xValue, y: Y[key][index] }));
    return values;
  }

  function getData (numberOfKeys) {
    const data = [];
    if (numberOfKeys > KEYS.length) {
      return data;
    }
    _.range(numberOfKeys).forEach((index) => data.push({
      color: COLORS[KEYS[index]],
      key: _.startCase(KEYS[index]),
      values: getValues(KEYS[index])
    }));
    return data;
  }

  class ChartLibraryController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      $scope.charts = {
        donutChart: {
          configuration: {
            caption: 'World Population (2018)',
            chart: {
              donut: true
            },
            subtitle: 'A Donut chart is displayed as the "donut" property is set to true',
            title: 'Donut Chart'
          },
          data: PIE_DONUT_DATA
        },
        lineChart: {
          configuration: {
            chart: LINE_BAR_CHART_CONFIG,
            title: 'Line Chart Title'
          },
          data1: getData(1),
          data2: getData(3),
          data3: getData(KEYS.length)
        },
        multiBarChart: {
          configuration1: {
            chart: LINE_BAR_CHART_CONFIG,
            title: 'MultiBar Chart Title'
          },
          configuration2: {
            chart: Object.assign({ showControls: true }, LINE_BAR_CHART_CONFIG),
            title: 'MultiBar Chart Title'
          },
          configuration3: {
            chart: Object.assign({ showControls: true, stacked: true }, LINE_BAR_CHART_CONFIG),
            title: 'MultiBar Chart Title'
          },
          data1: getData(1),
          data2: getData(3),
          data3: getData(KEYS.length)
        },
        pieChart: {
          configuration: {
            caption: 'World Population (2018)',
            subtitle: 'A Pie chart is displayed as the "donut" property is set to false',
            title: 'Pie Chart'
          },
          data: PIE_DONUT_DATA
        }
      };
    }
  }
  angular.module('application.controllers').controller('ChartLibraryController', ChartLibraryController);
})();
