/* globals GrainDefinition */
(function () {
  'use strict';
  class GrainItemFactoryService {
    static get $inject () {
      return ['planConfiguration'];
    }

    constructor (planConfiguration) {
      this.planConfiguration = planConfiguration;
    }

    collect (grainType, configuration) {
      // Do nothing if the configuration is undefined
      if (_.isEmpty(configuration)) {
        return;
      }

      return {
        items: () => this.planConfiguration.grainDefinition(grainType, configuration.date)
          .then((definition) => {
            const items = definition.values.map((value) => ({
              id: value.id,
              isActive: _.get(value, 'isActive', true),
              isVirtual: _.get(value, 'isVirtual', false),
              name: value.displayName
            }));
            if (configuration.excludeInactive) {
              _.remove(items, (item) => !item.isActive);
            }
            if (configuration.excludeUnknown) {
              _.remove(items, (item) => GrainDefinition.isUnknownGrain(item));
            }
            if (configuration.excludeVirtual) {
              _.remove(items, (item) => item.isVirtual && !GrainDefinition.isUnknownGrain(item));
            }
            const id = 'all';
            const name = 'All';
            return [{ id, items, name }];
          }),
        preferences: {
          hive: configuration.type,
          key: grainType
        }
      };
    }
  }

  angular.module('application.services').service('grainItemFactory', GrainItemFactoryService);
})();
