(function () {
  'use strict';

  function convertPlanDefinitionsListToMap (planDefinitionsList) {
    const planDefinitionsMap = {};
    _.forEach(planDefinitionsList, (planDefinition) => planDefinitionsMap[planDefinition.planType] = planDefinition);
    return planDefinitionsMap;
  }

  class PlanDefinitionService {
    static get $inject () {
      return ['planConfiguration', '$q'];
    }

    constructor (planConfiguration, $q) {
      this.planConfiguration = planConfiguration;
      this.$q = $q;
      this.allDefinitionsPopulated = false;
      this.planDefinitionsByType = {};
    }

    _fetchAllPlanDefinitions (asOfDate, returnAsMap) {
      if (_.isNil(asOfDate) && this.allDefinitionsPopulated) {
        // Return the cached plan definitions if they are fully populated and the request is not for a specific asOfDate.
        const response = returnAsMap ? this.planDefinitionsByType : _.values(this.planDefinitionsByType);
        return this.$q.resolve(response);
      }
      return this.planConfiguration.planDefinition().list(asOfDate)
        .then((planDefinitionsList) => {
          const planDefinitionsMap = convertPlanDefinitionsListToMap(planDefinitionsList);
          if (_.isNil(asOfDate)) {
            // Update the cached plan definitions only if the request is not for a specific asOfDate.
            this.planDefinitionsByType = planDefinitionsMap;
            this.allDefinitionsPopulated = true;
          }
          return returnAsMap ? planDefinitionsMap: planDefinitionsList;
        });
    }

    asList (asOfDate) {
      return this._fetchAllPlanDefinitions(asOfDate);
    }

    asMap (asOfDate) {
      return this._fetchAllPlanDefinitions(asOfDate, true);
    }

    get (planType, asOfDate) {
      if (_.isNil(asOfDate) && this.planDefinitionsByType[planType]) {
        // Return the cached plan definition if it is available and the request is not for a specific asOfDate.
        return this.$q.resolve(this.planDefinitionsByType[planType]);
      }
      return this.planConfiguration.planDefinition().get(planType, asOfDate)
        .then((planDefinition) => {
          if (_.isNil(asOfDate)) {
            // Update the cached plan definitions only if the request is not for a specific asOfDate.
            this.planDefinitionsByType[planType] = planDefinition;
          }
          return planDefinition;
        });
    }
  }

  angular.module('application.services').service('planDefinition', PlanDefinitionService);
})();
