/* globals Configuration */
/***
 * This file defines various configuration blocks that are intended to be temporary solutions related to
 * the Metrics Dashboard until a more optimal strategy for acquiring this information is made available.
 * This entire file can be viewed as a series of TODOs to find better ways to acquire this configuration.
 ***/
(function () {
  'use strict';

  // Object holding data mode enum strings
  const DATA_MODE = Object.freeze({
    DAYS_OF: 'DaysOf',
    PERCENT: 'Percent',
    UNITS: 'Units'
  });
  // Superset of all data modes available for metrics dashboard charts
  const DATA_MODES = Object.freeze([DATA_MODE.UNITS, DATA_MODE.PERCENT, DATA_MODE.DAYS_OF]);
  // Superset of all metric family groups available for metrics dashboard charts
  const METRIC_FAMILY_GROUPS = Object.freeze(['Inbound', 'Outbound']);
  const DASHBOARD_CONFIG = Object.freeze({
    // All metric configs for plans with DAILY time granularity
    DAILY: {
      // Charts data for the Daily Flash Email metrics. This config will be mainly used by current recipients of the flash emails: all NA scopes, EU5 scopes, IN and JP.
      FLASH_METRICS_CONFIG: Object.freeze({
        aggregateGroupBy: ['nodeGroup'],
        groupBy: ['node'],
        metricsData: {
          Inbound: {
            chartIds: ['NewVendorFreight', 'NewTransferIn'],
            metricFamilies: [
              {
                id: 'NewVendorFreight',
                metrics: [
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'NewVendorFreight', order: 1, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'NewVendorFreight', order: 2, type: 'Forecast' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.PERCENT, id: 'NewVendorFreightPercent', order: 3, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'VendorReceipts', order: 4, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'VendorReceipts', order: 5, type: 'Forecast' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.PERCENT, id: 'VendorReceiptsPercent', order: 6, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'TotalEndVendorFreightBacklog', order: 7, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.UNITS, id: 'TotalEndVendorFreightBacklog', order: 8, type: 'Forecast' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndVendorFreightBacklog', order: 9, type: 'Actual' },
                  { chartId: 'NewVendorFreight', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndVendorFreightBacklog', order: 10, type: 'Forecast' }
                ],
                planType: 'dailyPlan'
              },
              {
                id: 'NewTransferIn',
                metrics: [
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'NewTransferIn', order: 1, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'NewTransferIn', order: 2, type: 'Forecast' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.PERCENT, id: 'NewTransferInPercent', order: 3, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'TransferInReceipts', order: 4, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'TransferInReceipts', order: 5, type: 'Forecast' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.PERCENT, id: 'TransferInReceiptsPercent', order: 6, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'TotalTransferInBacklog', order: 7, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.UNITS, id: 'TotalTransferInBacklog', order: 8, type: 'Forecast' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndTransferInBacklog', order: 9, type: 'Actual' },
                  { chartId: 'NewTransferIn', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndTransferInBacklog', order: 10, type: 'Forecast' }
                ],
                planType: 'dailyPlan'
              }
            ]
          },
          Outbound: {
            chartIds: ['NewWorkableAssignments'],
            metricFamilies: [
              {
                id: 'Summary',
                metrics: [
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'UnitsOrdered', order: 1, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'LastYearUnitsOrdered', order: 2, type: 'Actual' }
                ],
                planType: 'dailyOrderPlan'
              },
              {
                id: 'NewWorkableAssignments',
                metrics: [
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'NewWorkableAssignments', order: 3, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'NewWorkableAssignments', order: 4, type: 'Forecast' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.PERCENT, id: 'NewWorkableAssignmentsPercent', order: 5, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'ShipmentsIncludingFRACS', order: 6, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'ShipmentsIncludingFRACS', order: 7, type: 'Forecast' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.PERCENT, id: 'ShipmentsIncludingFRACSPercent', order: 8, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'EndWorkable', order: 9, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.UNITS, id: 'EndWorkable', order: 10, type: 'Forecast' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndWorkable', order: 11, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.DAYS_OF, id: 'DaysOfEndWorkable', order: 12, type: 'Forecast' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.PERCENT, id: 'WorkableAssignmentsFastTrackPercentOfNWA', order: 13, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.PERCENT, id: 'WorkableAssignmentsMultisPercentOfNWA', order: 14, type: 'Actual' },
                  { chartId: 'NewWorkableAssignments', dataMode: DATA_MODE.PERCENT, id: 'WorkableAssignmentsGiftWrapPercentOfNWA', order: 15, type: 'Actual' }
                ],
                planType: 'dailyPlan'
              }
            ]
          }
        },
        namespace: 'NetworkViewer',
        operationalCategory: 'dailyPlan',
        planGranularity: 'WAREHOUSE',
        timeGranularity: 'DAILY'
      })
    },
    // All metric configs for plans with WEEKLY time granularity.
    WEEKLY: {}
  });

  const DASHBOARD_CONFIG_BY_SCOPE = Object.freeze({
    CA: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    DE: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    ES: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    FR: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    GB: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    IN: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    IT: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    JP: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    MX: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG,
    US: DASHBOARD_CONFIG.DAILY.FLASH_METRICS_CONFIG
  });

  const CONFIG_DATA = Object.freeze({
    dataMode: DATA_MODE,
    dataModes: DATA_MODES,
    forScope: (scope) => DASHBOARD_CONFIG_BY_SCOPE[scope],
    metricFamilyGroups: METRIC_FAMILY_GROUPS
  });

  Configuration
    .defineProperty('metricsDashboard', CONFIG_DATA);
})();
