/* globals Enums */
(function () {
  'use strict';
  const PREFERENCES_PROMOTED_MESSAGE = 'Your personal preferences were promoted to default preferences successfully.';

  class ManagePreferencesController {
    static get $inject () {
      return ['alerts', 'overlay', 'preferences', '$uibModal'];
    }

    constructor (alerts, overlay, preferences, $uibModal) {
      this.alerts = alerts;
      this.overlay = overlay;
      this.preferences = preferences;
      this.$uibModal = $uibModal;
    }

    _promotePreferences (preferences) {
      this.overlay.show('Promoting personal preferences...');
      this.preferences
        .set(this.preferences.DEFAULTS, preferences, this.configuration.hive, this.configuration.key)
        .then(() => {
          this.alerts.success(PREFERENCES_PROMOTED_MESSAGE);
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to promote preferences' }))
        .finally(() => this.overlay.hide());
    }

    openPreferencesModal () {
      this.$uibModal.open({
        component: 'preferencesModal',
        resolve: {
          configuration: () => this.configuration
        },
        size: Enums.ModalSize.LARGE
      }).result
        .then((preferences) => {
          this._promotePreferences(_.pick(preferences, ['updatedBy', 'updatedOn', 'selections']));
        })
        .catch(_.noop);
    }
  }

  angular.module('application.components')
    .component('managePreferences', {
      bindings: {
        /*
         * @configuration - object for specifying the hive and key for saving personal preferences.
         *   {
         *      hive: 'String',
         *      key: 'String'
         *   }
         */
        configuration: '<'
      },
      controller: ManagePreferencesController,
      templateUrl: 'templates/components/manage-preferences.component.html'
    });
})();
