/* globals AbstractElementComponent */
(function () {
  'use strict';
  class OptionSwitchController extends AbstractElementComponent {
    getDisplayValue (option) {
      return option.html || option.text || option;
    }

    getValue (option) {
      return _.has(option, 'value') ? option.value : option;
    }

    onSelectionChange (value) {
      this.ngModel.$setViewValue(value);
    }

    $onInit () {
      this.ngModel.$render = () => this.selection = this.ngModel.$viewValue;
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('optionSwitch', {
      bindings: {
        options: '<'
      },
      controller: OptionSwitchController,
      require: {
        ngModel: '^ngModel'
      },
      templateUrl: 'templates/components/option-switch.component.html'
    });
})();
