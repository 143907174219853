/* globals Configuration, DateUtils, Enums, KatalMetricsDriverSushi, UsageMetric, Validate */
(function () {
  'use strict';
  const EMPTY_ROUTE = '/';
  const UNTRACKED_ROUTES = Object.freeze([
    '/authenticate'
  ]);
  // The expression can be played around with here: https://regex101.com/r/oVnAMp/4
  const VALID_METRIC_STRING_REGEX = /^[\w\d._/-]+$/;

  function isValidRoute (route) {
    // A blank route can happen on initial application loading and it should be ignored.
    // We do not want to record the initial route ('/') being hit since it is not an actual page
    if (Validate.isBlank(route) || route === EMPTY_ROUTE) {
      return false;
    }

    // If the route contains any illegal characters, a metric publish should not be attempted
    // to mitigate an exception. The regular expression matches for Alphanumerics and '.', '_', '/', and '-'.
    if (_.isNil(route.match(VALID_METRIC_STRING_REGEX))) {
      return false;
    }

    // If the route is one of the nontracked routes, a metric should not be published for it.
    if (UNTRACKED_ROUTES.includes(route)) {
      return false;
    }
    return true;
  }

  class UsageMetricsService {
    static get $inject () {
      return ['alerts', '$authentication', '$log'];
    }

    constructor (alerts, $authentication, $log) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.$log = $log;

      const currentEnvironment = Configuration.environment.current().isProd() ? 'prod' : 'test';

      this._metricsDriver = new KatalMetricsDriverSushi.Builder()
        .withDomainRealm(currentEnvironment, 'USAmazon')
        .withErrorHandler(this._metricsErrorHandler.bind(this))
        .build();
    }

    _metricsErrorHandler (error) {
      this.$log.error(error);
    }

    createUsageMetric (serviceName, relatedMetricKey, relatedMetricValue) {
      return UsageMetric.create(this._metricsDriver, serviceName, this._metricsErrorHandler, relatedMetricKey, relatedMetricValue)
        .withPortalScope()
        .withAlias(this.$authentication.profile().alias);
    }

    authenticate () {
      return this.createUsageMetric('startUp')
        .forMethod('onLoginConfirmed')
        .withCounter('authentication')
        .publish();

    }

    pageView (route) {
      if (!isValidRoute(route)) {
        return;
      }

      return this.createUsageMetric(route)
        .forMethod('onPageInit')
        .withCounter('pageView')
        .publish();
    }

    // Wrapping an function block in timeAndExecute will asynchronously submit a latency
    // metric in milliseconds to Katal after the origin block's execution
    // TimeAndExecute swallows passed in data and errors, which should be handled in the function wrapped by it
    timeAndExecute (serviceName, methodName, wrappedPromiseFn) {
      if (!_.isFunction(wrappedPromiseFn)) {
        throw new Error(`UsageMetric: timeAndExecute is not wrapped around a function for service: "${serviceName}"`);
      }

      const startTime = DateUtils.epoch();
      const usageMetric = this.createUsageMetric(serviceName)
        .forMethod(methodName);

      return wrappedPromiseFn()
        .then(() => {
          usageMetric.withTimer(Enums.UsageMetrics.latency, (DateUtils.epoch() - startTime))
            .withCounter(Enums.UsageMetrics.success)
            .publish();
        })
        .catch((error) => {
          this.alerts.error(error);
          usageMetric.withTimer(Enums.UsageMetrics.latency, (DateUtils.epoch() - startTime))
            .withOptionalString(Enums.UsageMetrics.errorMessage, error.message)
            .withCounter(Enums.UsageMetrics.failure)
            .publish();
        });
    }
  }

  angular.module('application.services').service('usageMetrics', UsageMetricsService);
})();
