/* globals Configuration */
(function () {
  'use strict';

  function getRolePrefix (scopeCode) {
    // If user is in Antarctica scope, the method `Configuration.scopes.find(scopeCode)` returns `undefined`
    // which breaks the logic of getting the role prefix, so returning the scope code instead.
    if (Configuration.scopes.isInAntarctica()) {
      return _.kebabCase(scopeCode);
    }
    const scope = Configuration.scopes.find(scopeCode);
    // All tenant's - except for S&OP, have the tenant included in the scope's portal code.
    return scope.isOfTenantSandop() ? _.kebabCase(`${scope.tenant.id}-${scope.code}`) : _.kebabCase(scope.portalCode);
  }

  const ROLES = Object.freeze({
    administrator: (scope) => `${getRolePrefix(scope)}-admin`,
    developer: () => 'dev',
    fcWrite: (scope) => `${getRolePrefix(scope)}-fc-write`,
    read: (scope) => `${getRolePrefix(scope)}-read`,
    write: (scope) => `${getRolePrefix(scope)}-write`
  });

  const ACCESS = Object.freeze({
    administrator: Object.freeze([
      ROLES.developer,
      ROLES.administrator
    ]),
    // An empty set of roles indicating anyone in any state of authorization is permitted
    anonymous: Object.freeze([]),
    developer: Object.freeze([
      ROLES.developer
    ]),
    fcWrite: Object.freeze([
      ROLES.developer,
      ROLES.administrator,
      ROLES.write,
      ROLES.fcWrite
    ]),
    read: Object.freeze([
      ROLES.developer,
      ROLES.read
    ]),
    write: Object.freeze([
      ROLES.developer,
      ROLES.administrator,
      ROLES.write
    ])
  });

  // IPT uses OpenId Connect (https://openid.net/connect/) through Midway and AWS web identity federation (https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_providers_oidc.html) to assume the following IAM roles for AuthZ checks.
  // Ideally these should be made available through CFN.
  const AWS_CREDENTIALS_CONFIG = Object.freeze({
    beta: Object.freeze({
      region: 'us-west-2',
      roleArn: 'arn:aws:iam::544637462031:role/PortalServiceLambda-MidwayWebIdentityRole'
    }),
    // provide the configured credentials for the current environment, defaulting to beta if local environment.
    currentCredentialsConfig: function () {
      return _.get(this, Configuration.environment.current().designator, this.beta);
    },
    gamma: Object.freeze({
      region: 'us-east-1',
      roleArn: 'arn:aws:iam::869376168331:role/PortalServiceLambda-MidwayWebIdentityRole'
    }),
    prod: Object.freeze({
      region: 'us-west-2',
      roleArn: 'arn:aws:iam::748197535920:role/PortalServiceLambda-MidwayWebIdentityRole'
    })
  });

  Configuration
    .defineProperty(
      'authorization',
      {
        access: ACCESS,
        awsCredentialsConfig: AWS_CREDENTIALS_CONFIG,
        roles: ROLES
      }
    );
})();
