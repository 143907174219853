/* globals AbstractSourceBasedModel, Enums, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'dataset',
    'datasetDateTime',
    'etlTriggerUrl',
    'namespace',
    'scope',
    'status'
  ]);

  class DataPipelineWorkflow extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.setDisplayStatus();
    }

    _noExecutionStatus () {
      return this.isDelayed() ? Enums.TaskStatus.DELAYED : Enums.TaskStatus.NO_EXECUTIONS;
    }

    isDelayed () {
      return TaskStatus.isDelayed(this.status.toUpperCase());
    }

    isNoExecution () {
      return !this.isSuccess() && !this.isDelayed();
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.status.toUpperCase());
    }

    setDisplayStatus () {
      this.displayStatus = _.startCase(this.status.toLowerCase());
    }

    statusClass (prefix) {
      const statusClass = this.isSuccess() ? Enums.TaskStatus.SUCCESS : this._noExecutionStatus();
      return TaskStatus.statusClass(statusClass, prefix);
    }
  }

  window.DataPipelineWorkflow = Object.freeze(DataPipelineWorkflow);
})();
