/* globals AbstractPackagerService, Configuration, DataPackage, DateUtils, Enums, Grain, Name, PlanMetadata, TimeGranularity */
(function () {
  'use strict';

  const calculateDateRange = (granularity, date) => {
    let startDate = _.defaultTo(date, DateUtils.toSunday().format(Enums.DateFormat.IsoDate));
    let endDate = DateUtils.fromOffset(
      startDate,
      granularity.planHorizonInDays - TimeGranularity.getTimeUnitCount(granularity.timeGranularityName, 1),
      Enums.TimeUnit.DAY
    );
    // Daily Plans should not allow forecasts for past dates
    if (TimeGranularity.isDaily(granularity.timeGranularityName)) {
      endDate = DateUtils.fromOffset(
        startDate,
        granularity.planHorizonInDays - 1,
        Enums.TimeUnit.DAY
      );
      startDate = DateUtils.max(date, DateUtils.format(Enums.DateFormat.IsoDate));
    }
    return DateUtils.expansion(
      startDate,
      endDate,
      granularity.timeGranularityName
    );
  };

  class CreatePackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['alerts', 'planConfiguration', '$q', 'warehouses'];
    }

    constructor (alerts, planConfiguration, $q, warehouses) {
      super($q);
      this.alerts = alerts;
      this.planConfiguration = planConfiguration;
      this.warehouses = warehouses;
    }

    _getPossibleGrainValues (grains, asOfDate, planType) {
      return this.$q.all(
        grains.reduce((grainMap, grain) => {
          if (grain.equals(Grain.known.node)) {
            return _.set(grainMap, grain.name, this.warehouses.warehouse().list({ planTypes: planType })
              .then((warehouses) => _.map(warehouses.filter((warehouse) => !_.isEmpty(warehouse.planTypes)), 'warehouseId'))
              .catch(() => this._missingGrainsWarning(grain.name)));
          }
          if (grain.equals(Grain.known.productLine)) {
            return _.set(grainMap, grain.name, this.planConfiguration.productLine().list({ asOfDate }, { planType })
              .then((productLines) => _.map(productLines, 'name'))
              .catch(() => this._missingGrainsWarning(grain.name)));
          }
          return _.set(grainMap, grain.name, this.planConfiguration.grainDefinition(grain.id, asOfDate)
            .then((grain) => _.map(grain.values, 'id'))
            .catch(() => this._missingGrainsWarning(grain.name)));
        }, {})
      );
    }

    _missingGrainsWarning (grainName) {
      this.alerts.warning(`Unable to fetch grain values for the following grain: ${grainName}`);
      return [];
    }

    collect (planType, draft = Enums.Plan.DraftType.PRE_FINAL, date) {
      return this.planConfiguration.planDefinition()
        .get(planType)
        .then((data) => {
          const dates = calculateDateRange(data, date);
          const metrics = data.flows.filter((flow) => flow.isEditable());
          return this.$q.all(metrics.map((metric) => this._getPossibleGrainValues(metric.grains.values(), _.head(dates), planType)))
            .then((possibleGrainValues) => metrics.map((metric, index) => DataPackage.create({
              dates: dates,
              editType: Enums.DataPackage.EditType.UNIT,
              filters: {},
              flow: metric,
              granularity: {
                allGrains: data.planGranularity,
                grains: metric.grains,
                plan: data.planGranularityName,
                possibleGrainValues: possibleGrainValues[index],
                time: data.timeGranularityName
              },
              groupBy: [],
              metrics: metrics,
              plan: PlanMetadata.create({
                date: _.defaultTo(date, DateUtils.toSunday().format(Enums.DateFormat.IsoDate)),
                draft: draft,
                endDate: _.last(dates),
                scenario: Enums.Plan.Scenario.PROD,
                schemaName: data.schemaName,
                scope: Configuration.scopes.current().code,
                subScenario: Enums.Plan.Scenario.PROD,
                type: planType,
                version: Enums.DataPackage.PackageType.CREATE
              }),
              records: [],
              title: Name.ofMetric(metric),
              totals: [],
              type: Enums.DataPackage.PackageType.CREATE
            }, this.$q)));
        });
    }
  }

  angular.module('application.services').service('createPackager', CreatePackagerService);
})();
