/* globals AbstractElementComponent, Configuration */
(function () {
  'use strict';
  const SYSTEM_USERS = Object.freeze(['OrchestratorNetworkTrigger', 'system']);

  class AbstractPhonetoolComponent extends AbstractElementComponent {
    static bindings (additionalBindings) {
      return _.defaults(additionalBindings, {
        /*
         * @userAlias must be a valid amazon user alias.
         */
        userAlias: '<',
        /*
         * @userName (Optional) when provided, is used as a mouse-hover title on the displayed phonetool text/picture. Defaults to userAlias.
         */
        userName: '<'
      });
    }

    getBadgephotosUrl () {
      return Configuration.endpoints.staticOf.badgephotos(this.userAlias);
    }

    getPhonetoolUrl () {
      return Configuration.endpoints.staticOf.phonetool(this.userAlias);
    }

    isSystemUser () {
      return _.includes(SYSTEM_USERS, this.userAlias);
    }
  }

  window.AbstractPhonetoolComponent = Object.freeze(AbstractPhonetoolComponent);
})();
