/* globals Enums */
(function () {
  'use strict';

  class JsonViewerController {
    static get $inject () {
      return ['$uibModal'];
    }

    constructor ($uibModal) {
      this.$uibModal = $uibModal;
    }

    openJsonViewerModal () {
      this.$uibModal
        .open({
          component: 'jsonViewerModal',
          resolve: {
            data: () => this.jsonData,
            title: () => this.modalTitle
          },
          size: Enums.ModalSize.EXTRA_LARGE
        })
        .result
        .catch(_.noop);
    }
  }

  angular.module('application.components')
    .component('jsonViewer', {
      bindings: {
        jsonData: '<',
        linkText: '@',
        modalTitle: '@'
      },
      controller: JsonViewerController,
      templateUrl: 'templates/components/json-viewer.component.html'
    });
})();
