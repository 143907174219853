/* globals AbstractServiceEndpoint, Configuration, UserProfile, UserView, XLSX */
(function () {
  'use strict';

  const USER_PREFERENCE_BODY_OPTIONS = Object.freeze({
    ignore: [
      'granularities',
      'manualBacklog',
      'metricCategories',
      'metricFamilies',
      'node',
      'nodeGroup',
      'productLine',
      'secondaryNode',
      'sortType'
    ]
  });

  class AnnouncementRequest extends AbstractServiceEndpoint {
    constructor (request) {
      super(request, Configuration.services.portalStore);
    }

    static create (request) {
      return new AnnouncementRequest(request);
    }

    list (options) {
      return this.aws()
        .withPortalScope()
        .for('announcement')
        .withParams(options)
        .get()
        .then(
          // Replace paths that are an alias
          (response) => _.map(
            response.announcements,
            (announcement) => {
              // The backend may provide messageType in lowercase or UPPERCASE, force to lowercase.
              _.set(announcement, 'messageType', _.toLower(announcement.messageType));
              _.set(announcement, 'paths', _.map(announcement.paths, (path) => Configuration.routing.getPath(path)));
              return announcement;
            }
          )
        );
    }

    upsert (announcement, options) {
      // The backend needs messageType to be in UPPERCASE for automatic deserialization to an enum.
      _.set(announcement, 'messageType', _.toUpper(announcement.messageType));
      return this.aws()
        .withPortalScope()
        .for('announcement')
        .withParams(options)
        .withBody(announcement, { doNotCompactArrays: true })
        .put();
    }
  }

  class UserPreferenceRequest extends AbstractServiceEndpoint {
    constructor (request) {
      super(request, Configuration.services.portalStore);
    }

    static create (request) {
      return new UserPreferenceRequest(request);
    }

    list (options) {
      return this.aws()
        .withPortalScope()
        .for('preferences', 'user')
        .withParams(options)
        .get();
    }

    update (preferences, options) {
      return this.aws()
        .withPortalScope()
        .for('preferences', 'user')
        .withParams(options)
        .withBody(preferences, USER_PREFERENCE_BODY_OPTIONS)
        .put();
    }

    updateScopeDefaults (preferences, options) {
      return this.aws()
        .withPortalScope()
        .for('preferences')
        .withParams(options)
        .withBody(preferences, USER_PREFERENCE_BODY_OPTIONS)
        .put();
    }
  }

  class UserProfileRequest extends AbstractServiceEndpoint {
    constructor (request) {
      super(request, Configuration.services.portalStore);
    }

    static create (request) {
      return new UserProfileRequest(request);
    }

    get (options) {
      return this.aws()
        .withPortalScope()
        .for('userProfile')
        .withParams(options)
        .get()
        .then((profileData) => UserProfile.create(profileData));
    }

    update (profile, options) {
      return this.aws()
        .withPortalScope()
        .for('userProfile')
        .withParams(options)
        .withBody(profile, { doNotCompactArrays: true })
        .put();
    }
  }

  class ShareableUrlRequest extends AbstractServiceEndpoint {
    constructor (request) {
      super(request, Configuration.services.portalStore);
    }

    static create (request) {
      return new ShareableUrlRequest(request);
    }

    create (configuration, options) {
      return this.aws()
        .withPortalScope()
        .for('shareableUrl')
        .withParams(options)
        .withBody(configuration, { doNotCompactArrays: true })
        .post()
        .then((uuid) => uuid.id);
    }

    get (token, options) {
      return this.aws()
        .withPortalScope()
        .for('shareableUrl', token)
        .withParams(options)
        .get()
        // Replace the baseUrl if it is an alias
        .then((data) => _.set(data, 'baseUrl', Configuration.routing.getPath(data.baseUrl)));
    }
  }

  class UserViewRequest extends AbstractServiceEndpoint {
    constructor ($authentication, request) {
      super(request, Configuration.services.portalStore);
      this.$authentication = $authentication;
    }

    static create ($authentication, request) {
      return new UserViewRequest($authentication, request);
    }

    delete (view, options) {
      return this.aws()
        .withPortalScope()
        .for('view', 'user', view.identifier)
        .withParams(options)
        .withBody(view.source, { doNotCompactArrays: true })
        .delete();
    }

    list (scope, options) {
      return this.aws()
        .withPortalScope(scope)
        .for('view', 'user')
        .withParams(options)
        .get()
        .then(
          // Views come back in a hash containing both the user's personal views and the defaults (shared for the organization) views.
          // The list is written as:
          // {
          //   "personal": [
          //     { "id1": { "name": "value1", ... } },
          //     { "id2": { "name": "value2", ... } },
          //   ]
          //   "public": [
          //      { ... }
          //   ]
          // }
          (views) => ({
            // If user views are being fetched by an IPT Dev using alias lookup, views owner should be the lookup alias.
            // If user views are being fetched by the user who owns them, views owner should be the authenticated user.
            personal: _.map(views.personal, (body, identifier) =>
              UserView.create(_.defaults(body, { owner: _.get(options, 'alias', this.$authentication.profile().alias) }), identifier)),
            public: _.map(views.public, (body, identifier) =>
              UserView.create(body, identifier, true))
          })
        );
    }

    upsert (name, configuration, options = {}) {
      const view = UserView.create(_.cloneDeep(configuration), name, options.isPublic);
      return this.aws()
        .withPortalScope()
        .for('view', 'user', view.identifier)
        .withParams(options)
        .withBody(view.source, { doNotCompactArrays: true })
        .put();
    }
  }

  class PortalStoreService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['$authentication', '$q', 'request'];
    }

    constructor ($authentication, $q, request) {
      super(request, Configuration.services.portalStore);
      this.$q = $q;
      this.$authentication = $authentication;
    }

    createLogStream (groupName, streamName) {
      return this.cloudWatch()
        .post(groupName, streamName);
    }

    createShareToken (configuration, options) {
      return ShareableUrlRequest
        .create(this.request)
        .create(configuration, options);
    }

    deleteUserView (view, options) {
      return UserViewRequest
        .create(this.$authentication, this.request)
        .delete(view, options);
    }

    fetchS3Object (bucket, key) {
      return this.s3()
        .get(bucket, key)
        .then((response) => XLSX.read(new Uint8Array(response.Body), { cellDates: true, cellText: false, type: 'array' }));
    }

    getAnnouncements (options) {
      return AnnouncementRequest
        .create(this.request)
        .list(options);
    }

    getShareableUrlData (token, options) {
      return ShareableUrlRequest
        .create(this.request)
        .get(token, options);
    }

    // This is a temporary solution until the getUsageMetricsList()
    // API has been added to Portal Store.
    // SIM: https://sim.amazon.com/issues/SOP-8529
    getUsageMetricsList () {
      return this.$q.resolve([
        {
          columns: [
            'timestamp',
            'scope',
            'alias'
          ],
          metricName: 'Authentications'
        }
      ]);
    }

    getUsageMetricsData (metric, configuration, options) {
      return this.aws()
        .withPortalScope()
        .for('usage', metric)
        .withParams(options)
        .withBody(configuration)
        .post();
    }

    getUserPreferences (options) {
      return UserPreferenceRequest
        .create(this.request)
        .list(options);
    }

    getUserProfile (options) {
      return UserProfileRequest
        .create(this.request)
        .get(options);
    }

    getUserViews (scope, options) {
      return UserViewRequest
        .create(this.$authentication, this.request)
        .list(scope, options);
    }


    // TODO: Implement a batch solution to enqueue log event items over a set duration and
    //       send them at once as to reduce unnessesary request overhead.
    putLogEvent (groupName, streamName, logEvent, sequenceToken) {
      return this.cloudWatch()
        .put(groupName, streamName, [logEvent], sequenceToken);
    }

    updateScopeDefaults (preferences, options) {
      return UserPreferenceRequest
        .create(this.request)
        .updateScopeDefaults(preferences, options);
    }

    updateUserPreferences (preferences, options) {
      return UserPreferenceRequest
        .create(this.request)
        .update(preferences, options);
    }

    updateUserProfile (profile, options) {
      return UserProfileRequest
        .create(this.request)
        .update(profile, options);
    }

    upsertAnnouncement (announcement, options) {
      return AnnouncementRequest
        .create(this.request)
        .upsert(announcement, options);
    }

    upsertUserView (name, configuration, options = {}) {
      return UserViewRequest
        .create(this.$authentication, this.request)
        .upsert(name, configuration, options);
    }
  }

  angular.module('application.services').service('portalStore', PortalStoreService);
})();
