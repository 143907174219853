(function () {
  'use strict';

  const CONSTRAINT_VALIDATION_DATA = Object.freeze({
    BIN_GROUP_MAPPINGS: {
      columns: ['Index', 'FC', 'Bin Type', 'Bin Group']
    },
    CAPACITY_SCALING_FACTOR: {
      columns: ['Index', 'FC', 'Bin Type', 'Week', 'Sort Type', 'Min Scaling Factor', 'Max Scaling Factor']
    },
    CROSSDOCK_INBOUND_TARGET: {
      columns: ['Index', 'IDX FC', 'Sort Type', 'Product Line Group', 'Week', 'Ratio']
    },
    IDEAL_FACTOR: {
      columns: ['Index', 'GL', 'Sort Type', 'FC', 'Ideal Factor']
    },
    INITIAL_PLAN_ADJUST_TOLERANCE: {
      columns: ['Index', 'PL', 'Flow', 'Sort Type', 'Source FC', 'Destination FC', 'Week', 'Adjust Tolerance', 'Reason Code']
    },
    NEW_FC_RAMP_UP_CURVE: {
      columns: ['Index', 'Flow', 'FC', 'Sort Type', 'Date', 'Units']
    },
    VOLUME_LOWER_BOUND: {
      columns: ['Index', 'FC', 'Flow', 'Week', 'Throughput', 'Sort Type', 'Source']
    },
    VOLUME_UPPER_BOUND: {
      columns: ['Index', 'FC', 'Flow', 'Week', 'Throughput', 'Sort Type', 'Source']
    }
  });

  class ConstraintSheetValidator {
    static isCorrectColumnNum (constraintType, columns) {
      return Object.keys(columns).length === CONSTRAINT_VALIDATION_DATA[constraintType].columns.length;
    }
  }

  window.ConstraintSheetValidator = Object.freeze(ConstraintSheetValidator);
})();
