/* globals accounting, AbstractMetricsDashboardChartsComponent, Configuration, DateUtils, Display, Enums, PlanMetadata */
(function () {
  'use strict';
  // Default days in the past (excluding today)
  const DEFAULT_PAST_DAYS = 15;
  // Default days in the future (including today)
  const DEFAULT_FUTURE_DAYS = 15;
  const MAX_WEEKS_BACK = 4;
  const MAX_DAYS_BACK = MAX_WEEKS_BACK * 7;
  const MAX_WEEKS_FORWARD = 2;
  const MAX_DAYS_FORWARD = MAX_WEEKS_FORWARD * 7;
  const AMAZON_NETWORK_NODE_GROUP = 'Amazon Network';
  const PRODUCT_LINE_PLAN_GRANULARITY = 'PRODUCTLINE';
  const SUMMARY_METRIC_FAMILY = 'Summary';
  let dashboardDataMode = {};
  let dashboardDataModes = [];
  let dashboardMetricFamilyGroups = [];
  let dashboardConfig = {};

  const fetchPlanMap = (dashboardMetricFamilies, planMetadata) => {
    const planMap = {};
    dashboardMetricFamilies.forEach((metricFamily) => planMap[metricFamily.id] = planMetadata[metricFamily.planType]);
    return planMap;
  };

  const getDashboardMetric = (allMetricFamilies, familyId, metricId, metricType) => {
    const metricFamily = allMetricFamilies.find((family) => family.id === familyId);
    return metricFamily.metrics.find((metric) => metric.id === metricId && metric.type === metricType);
  };

  const getDataModesForChart = (chartId) => {
    const chartDataModes = [];
    dashboardMetricFamilyGroups.forEach((metricFamilyGroup) => {
      const metricsData = dashboardConfig.metricsData[metricFamilyGroup];
      if (!metricsData.chartIds.includes(chartId)) {
        return;
      }
      metricsData.metricFamilies.forEach((metricFamily) =>
        chartDataModes.push(...metricFamily.metrics.filter((metric) => chartId === metric.chartId).map((metric) => metric.dataMode)));
    });
    return dashboardDataModes.filter((dataMode) => chartDataModes.includes(dataMode));
  };

  const getNodeGroupMappings = (nodes) => {
    if (_.isNil(nodes)) {
      return;
    }
    return {
      nodeGroup: {
        granularityKey: 'node',
        mapping: {
          [AMAZON_NETWORK_NODE_GROUP]: nodes
        }
      }
    };
  };

  const getMetricFamilyDataModeKey = (metricFamilyId, dataMode) => `${metricFamilyId}-${dataMode}`;

  const getPlanMetadata = (cadenceDate, planType) => ({
    date: cadenceDate,
    draft: Enums.Plan.DraftType.FINAL,
    scenario: PlanMetadata.defaultScenario.scenario,
    subScenario: PlanMetadata.defaultScenario.subScenario,
    type: planType
  });

  class MetricsDashboardDailyCharts extends AbstractMetricsDashboardChartsComponent {
    static get $inject () {
      return ['metricsService', 'planStore', '$q', 'warehouses'];
    }

    constructor (metricsService, planStore, $q, warehouses) {
      super();
      this.metricsService = metricsService;
      this.planStore = planStore;
      this.$q = $q;
      this.warehouses = warehouses;
      this.periodLabel = 'days';
      this.loadingInProgress = false;
      this.loadingComplete = false;
      this.chartIds = [];
      this.charts = {};
      dashboardDataMode = Configuration.metricsDashboard.dataMode;
      dashboardDataModes = Configuration.metricsDashboard.dataModes;
    }

    _convertMetricFamilyDataToChartData (metricFamilyData, dashboardMetricFamilies) {
      // chartData will be a map of keys (like "NVF-Units", "NVF-Percent" and "NVF-Days") and chart data to be plotted.
      const chartData = {};
      _.map(dashboardMetricFamilies, 'id').forEach((metricFamilyId) => {
        const metricFamily = metricFamilyData[metricFamilyId];
        const records = metricFamilyId === SUMMARY_METRIC_FAMILY ? metricFamily.records : metricFamily.aggregateRecords;
        _.forEach(_.head(records).metrics, (metric) => {
          const dashboardMetric = getDashboardMetric(dashboardMetricFamilies, metricFamilyId, metric.id, metric.type);
          Object.assign(metric, { chartId: dashboardMetric.chartId, dataMode: dashboardMetric.dataMode, order: dashboardMetric.order });
        });
        const sortedMetrics = _.sortBy(_.head(records).metrics, ['order']);
        sortedMetrics.forEach((metric) => {
          if (metric.dataMode === dashboardDataMode.PERCENT) {
            metric.values = _.map(metric.values, (value) => _.isNil(value) ? null : value * 100);
          }
          // Combination of chartId and data mode (Units, Percent, Days) create a unique data key like 'NewVendorFreight-Units'
          const dataKey = getMetricFamilyDataModeKey(metric.chartId, metric.dataMode);
          if (!_.has(chartData, dataKey)) {
            chartData[dataKey] = [];
          }
          chartData[dataKey].push({
            key: `${_.startCase(metric.id)} (${metric.type}s)`,
            values: _.map(metric.values, (value, index) => ({ x: index, y: _.isNil(value) ? null : _.round(value, _.indexOf(dashboardDataModes, metric.dataMode)) }))
          });
        });
      });
      return chartData;
    }

    _constructChartDataAndConfiguration (metricFamilyData, dashboardMetricFamilies) {
      this.chartData = this._convertMetricFamilyDataToChartData(metricFamilyData, dashboardMetricFamilies);
      const metricFamilyHead = _.head(Object.values(metricFamilyData));
      const allChartIds = [];
      dashboardMetricFamilyGroups.forEach((metricFamilyGroup) => allChartIds.push(...dashboardConfig.metricsData[metricFamilyGroup].chartIds));
      allChartIds.forEach((chartId) => {
        this.charts[chartId] = {
          chartDataModes: getDataModesForChart(chartId),
          currentDataMode: dashboardDataMode.UNITS,
          currentFuturePeriod: DEFAULT_FUTURE_DAYS,
          currentPastPeriod: DEFAULT_PAST_DAYS,
          periodDates: metricFamilyHead.periodStartDates
        };
        this._refreshChartData(chartId);
        this._setChartConfiguration(chartId);
      });
      this.chartIds = dashboardConfig.metricsData[this.metricFamilyGroup].chartIds;
    }

    _fetchMetadata (planType, today, lastUpdateByTimeInISO) {
      return this.planStore.planMetadata(
        // Compute expected cadence date for plan to make request to Plan Store
        getPlanMetadata(DateUtils.toSunday(today).format(Enums.DateFormat.IsoDate), planType),
        {
          alertUser: true,
          lastUpdateByTimeInISO: lastUpdateByTimeInISO,
          scope: this.scope
        }
      );
    }

    _fetchPlanMetadata (dashboardMetricFamilies, today, lastUpdateByTimeInISO) {
      const planTypes = _.uniq(_.map(dashboardMetricFamilies, 'planType'));
      const promises = {};
      planTypes.forEach((planType) => {
        const promise = [this._fetchMetadata(planType, today, lastUpdateByTimeInISO)];
        promises[planType] = this.$q.all(promise).then((list) => _.reject(list, (metadata) => metadata.isSourceEmpty()));
      });
      return this.$q.all(promises);
    }

    _getMetricFamily (metricFamily, startDate, endDate, asOfTime, filters, planMap) {
      const body = {
        aggregateGroupBy: dashboardConfig.aggregateGroupBy,
        aggregateOnly: dashboardConfig.namespace === this.metricsService.NETWORK_VIEWER_NAMESPACE && metricFamily.id !== SUMMARY_METRIC_FAMILY,
        asOfTime: asOfTime,
        filters: filters,
        groupBy: dashboardConfig.groupBy,
        mappings: getNodeGroupMappings(filters.node),
        periodEndDate: endDate,
        periodStartDate: startDate,
        planMetadata: planMap[metricFamily.id].map((plan) => plan.source)
      };
      let planGranularity = dashboardConfig.planGranularity;
      if (metricFamily.id === SUMMARY_METRIC_FAMILY) {
        delete body.aggregateGroupBy;
        delete body.mappings;
        body.filters = {};
        body.groupBy = ['metric'];
        planGranularity = PRODUCT_LINE_PLAN_GRANULARITY;
      }
      return this.metricsService
        .metricFamily(metricFamily, dashboardConfig.namespace, planGranularity, Enums.TimeGranularity.DAILY, body);
    }

    _loadData () {
      if (!this.isActive) {
        return;
      }
      this.scope = _.defaultTo(this.scope, Configuration.scopes.current().portalCode);
      dashboardConfig = Configuration.metricsDashboard.forScope(this.scope);
      dashboardMetricFamilyGroups = Configuration.metricsDashboard.metricFamilyGroups.filter((group) => Object.keys(dashboardConfig.metricsData).includes(group));
      const today = DateUtils.format(Enums.DateFormat.IsoDate);
      const startDate = DateUtils.fromOffset(today, -1 * (MAX_DAYS_BACK + 1), Enums.TimeUnit.DAY);
      const endDate = DateUtils.fromOffset(today, MAX_DAYS_FORWARD, Enums.TimeUnit.DAY);
      const asOfTime = DateUtils.compose().toISOString();
      const dashboardMetricFamilies = [];
      dashboardMetricFamilyGroups.forEach((metricFamilyGroup) => dashboardMetricFamilies.push(...dashboardConfig.metricsData[metricFamilyGroup].metricFamilies));
      this.loadingInProgress = true;
      let promises = {
        node: this.warehouses.warehouse().list({ asOfDate: today, operationalCategory: dashboardConfig.operationalCategory, scope: this.scope }),
        planMetadata: this._fetchPlanMetadata(dashboardMetricFamilies, today, asOfTime)
      };
      this.$q.all(promises)
        .then((data) => {
          const planMap = fetchPlanMap(dashboardMetricFamilies, data.planMetadata);
          const filters = { node: _.map(data.node, 'warehouseId') };
          promises = {};
          dashboardMetricFamilies.forEach((metricFamily) => promises[metricFamily.id] = this._getMetricFamily(metricFamily, startDate, endDate, asOfTime, filters, planMap));
          this.$q.all(promises)
            .then((metricFamilyData) => {
              this._constructChartDataAndConfiguration(metricFamilyData, dashboardMetricFamilies);
            })
            .finally(() => {
              this.loadingInProgress = false;
              this.loadingComplete = true;
              this.onChartsLoadingComplete();
            });
        });
    }

    _refreshChartData (chartId) {
      const chartDataList = _.defaultTo(_.cloneDeep(this.chartData[getMetricFamilyDataModeKey(chartId, this.charts[chartId].currentDataMode)]), []);
      // 'chartData' contains the entire 44 days of data (29 days of past data + 15 days of future data)
      // If 'currentPastPeriod' is, say 15, 'startIndex' needs to be set such that only the last 15 days of past data gets sliced, not the first 14 days.
      const startIndex = (MAX_DAYS_BACK + 1) - this.charts[chartId].currentPastPeriod;
      // If 'currentFuturePeriod' is, say 8, 'endIndex' needs to be set such that only the first 8 days of future data gets sliced, not the last 7 days.
      const endIndex = this.charts[chartId].periodDates.length - (MAX_DAYS_FORWARD + 1 - this.charts[chartId].currentFuturePeriod);
      chartDataList.forEach((data) => data.values = data.values.slice(startIndex, endIndex));
      this.charts[chartId].chartPlottingData = chartDataList;
    }

    _setChartConfiguration (chartId) {
      const currentDataMode = this.charts[chartId].currentDataMode;
      const dates = _.map(this.charts[chartId].periodDates, (date) => ({
        date: date,
        dateCompact: Display.date(date, 'MM-DD')
      }));
      const suffix = currentDataMode === dashboardDataMode.PERCENT ? '%' : '';
      this.charts[chartId].chartConfiguration = {
        chart: {
          interactiveLayer: {
            tooltip: {
              headerFormatter: (index) => dates[index].date,
              valueFormatter: (value) => _.isNil(value) ? 'N/A' : `${accounting.formatNumber(value, _.indexOf(dashboardDataModes, currentDataMode))}${suffix}`
            }
          },
          reduceXTicks: true,
          xAxis: {
            axisLabel: 'TIME (days)',
            tickFormat: (index) => dates[index].dateCompact
          },
          yAxis: {
            axisLabel: _.toUpper(currentDataMode),
            tickFormat: (value) => accounting.formatNumber(value, _.indexOf(dashboardDataModes, currentDataMode))
          }
        },
        title: _.startCase(chartId)
      };
    }

    _setChartTitle (chartId, title) {
      this.charts[chartId].chartConfiguration.title = title;
    }

    _setChartYaxisLabel (chartId, label) {
      this.charts[chartId].chartConfiguration.chart.yAxis.axisLabel = _.toUpper(label);
    }

    _setChartYaxisTickFormat (chartId) {
      const dataMode = this.charts[chartId].currentDataMode;
      this.charts[chartId].chartConfiguration.chart.yAxis.tickFormat = (value) => accounting.formatNumber(value, _.indexOf(dashboardDataModes, dataMode));
    }

    _setChartTooltipValueFormatter (chartId) {
      const currentDataMode = this.charts[chartId].currentDataMode;
      const suffix = currentDataMode === dashboardDataMode.PERCENT ? '%' : '';
      this.charts[chartId].chartConfiguration.chart.interactiveLayer.tooltip.valueFormatter = (value) =>
        _.isNil(value) ? 'N/A' : `${accounting.formatNumber(value, _.indexOf(dashboardDataModes, currentDataMode))}${suffix}`;
    }

    changeFuturePeriod (period, chartId) {
      this.charts[chartId].currentFuturePeriod = period;
      const pastFuturePeriodTotal = this.charts[chartId].currentPastPeriod + this.charts[chartId].currentFuturePeriod;
      if (pastFuturePeriodTotal > 30) {
        this.charts[chartId].currentPastPeriod -= pastFuturePeriodTotal - 30;
      }
      this._refreshChartData(chartId);
    }

    changePastPeriod (period, chartId) {
      this.charts[chartId].currentPastPeriod = period;
      const pastFuturePeriodTotal = this.charts[chartId].currentPastPeriod + this.charts[chartId].currentFuturePeriod;
      if (pastFuturePeriodTotal > 30) {
        this.charts[chartId].currentFuturePeriod -= pastFuturePeriodTotal - 30;
      }
      this._refreshChartData(chartId);
    }

    getDataModes (chartId) {
      return this.charts[chartId].chartDataModes;
    }

    getFuturePeriodOptions () {
      return _.range(MAX_WEEKS_FORWARD + 1).map((week) => (week * 7) + 1);
    }

    getPastPeriodOptions () {
      return _.range(1, MAX_WEEKS_BACK + 1).map((week) => (week * 7) + 1);
    }

    isCurrentDataMode (dataMode, chartId) {
      return this.charts[chartId].currentDataMode === dataMode;
    }

    isCurrentFuturePeriod (period, chartId) {
      return this.charts[chartId].currentFuturePeriod === period;
    }

    isCurrentPastPeriod (period, chartId) {
      return this.charts[chartId].currentPastPeriod === period;
    }

    switchToDataMode (dataMode, chartId) {
      this.charts[chartId].currentDataMode = dataMode;
      this._refreshChartData(chartId);
      this._setChartYaxisLabel(chartId, dataMode);
      this._setChartYaxisTickFormat(chartId);
      this._setChartTooltipValueFormatter(chartId);
    }

    $onChanges () {
      if (!this.state.isInitialized() || this.loadingInProgress) {
        return;
      }
      if (this.loadingComplete) {
        this.chartIds = dashboardConfig.metricsData[this.metricFamilyGroup].chartIds;
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('metricsDashboardDailyCharts', {
      bindings: AbstractMetricsDashboardChartsComponent.bindings(),
      controller: MetricsDashboardDailyCharts,
      templateUrl: AbstractMetricsDashboardChartsComponent.templateUrl
    });
})();
