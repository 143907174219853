/* globals AbstractPackagerService, Configuration, DataCell, DataPackage, DataRow, DateUtils, Enums, NetworkPlanValue */
(function () {
  const FORECAST = 'Forecast';

  class NetworkPlanGeneratorPackagerService extends AbstractPackagerService {

    constructor (modelMarketplace, $q) {
      super($q);
      this.modelMarketplace = modelMarketplace;
    }

    static get $inject () {
      return ['modelMarketplace', '$q'];
    }

    _convertToPlanValues (records, dates) {
      return records.map((row) => dates.map((date, index) => NetworkPlanValue.create({
        date: date,
        metaData: _(Object.keys(row.granularity))
          .filter((grain) => grain !== Enums.GrainIdentity.draft && grain !== Enums.GrainIdentity.metric)
          .transform((granularity, grain) => granularity[grain] = row.granularity[grain], {}).value(),
        value: row.cells[index].value
      }))).flat();
    }

    _getActuals (actuals, granularity, splitBy) {
      const filteredActuals = _.find(actuals, (dataPackage) => dataPackage.filters[splitBy] === granularity[splitBy]);
      if (!_.isNil(filteredActuals)) {
        return _.head(filteredActuals.records).cells.splice(0, Configuration.networkPlannerDateRange.actuals);
      }
      return Array(Configuration.networkPlannerDateRange.actuals).fill(DataCell.create({ dataType: Enums.DataType.ACTUAL }));
    }


    _convertToRecords (planValues, metric, actuals, splitBy) {
      return _(planValues)
        .groupBy((planValue) => Object.values(planValue.metaData))
        .transform((rows, values) => rows.push(DataRow.create({
          dataType: Enums.DataType.PRIMARY,
          granularity: _.head(values).metaData,
          metric: metric,
          values: [...this._getActuals(actuals, _.head(values).metaData, splitBy), ..._(values).sortBy('date').map('value').map(_.toNumber).value()]
        })), []).value();
    }

    /**
     * Constructs a DataPackage from an API call to planGeneration.
     *
     * @param plan the plan to generate
     * @param dependencies is an object that maps the dependent planTypes to DataPackages
     * @param granularity the granularity of the returned DataPackage
     * @param splitBy the grain to split the returned packages at
     * @param metric contains the flow id
     * @param actuals the actuals planData packages
     * @returns DataPackage
     */
    collect (plan, dependencies, granularity, splitBy, metric, actuals) {
      const actualsStartDate = DateUtils.fromOffset(plan.startDate, -Configuration.networkPlannerDateRange.actuals, Configuration.networkPlannerDateRange.timeUnit);
      const dates = DateUtils.expansion(actualsStartDate, plan.endDate, Configuration.networkPlannerDateRange.timeGranularity);
      const planDates = DateUtils.expansion(plan.startDate, plan.endDate, Configuration.networkPlannerDateRange.timeGranularity);
      const configuredDependencies = Configuration.networkPlannerPlans.find((configuredPlan) => configuredPlan.type === plan.type).dependencies;
      const planData = _.transform(configuredDependencies, (dependencyValue, dependency) => {
        dependencyValue[dependency] = this._convertToPlanValues(_.flatMap(dependencies[dependency], 'records'), planDates);
      }, {});
      return this.modelMarketplace.generateNetworkPlan({ endDate: plan.endDate, planData: planData, startDate: plan.startDate, targetPlanType: plan.type })
        .then((planValues) => this._convertToRecords(planValues, metric, actuals, splitBy))
        .then((allRecords) => _(allRecords)
          .filter((record) => !_.isEmpty(record.granularity[splitBy]))
          .groupBy(`granularity.${splitBy}`)
          .map((records, filter) => {
            const filters = { [splitBy]: filter };
            records.forEach((row) => row.cells.forEach((cell) => {
              if (cell.dataType === Enums.DataType.PRIMARY) {
                cell.type = FORECAST;
              }
            }));
            return DataPackage.create({ dates, filters, granularity, plan, records }, this.$q);
          }).value()
        );
    }
  }

  angular.module('application.services').service('networkPlanGeneratorPackager', NetworkPlanGeneratorPackagerService);
})();
