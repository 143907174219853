(function () {
  'use strict';
  const DEFAULT_TAB_SPACES = 2;

  class JsonViewerModalController {
    $onInit () {
      this.prettyJson = JSON.stringify(
        this.resolve.data,
        _.get(this.resolve, 'keys'),
        _.get(this.resolve, 'tabSpaces', DEFAULT_TAB_SPACES)
      );
    }
  }
  angular.module('application.components')
    .component('jsonViewerModal', {
      bindings: {
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with 4 properties:
         *   data - string the raw json text to be displayed.
         *   keys - array (optional) the list of keys to include. Defaults to undefined (all keys).
         *   tabSpaces - number (optional) the number of white spaces to use as a tab. Defaults to 2.
         *   title - string (optional) title displayed in the modal header section.
         */
        resolve: '<'
      },
      controller: JsonViewerModalController,
      templateUrl: 'templates/components/json-viewer-modal.component.html'
    });
})();
