/* globals AbstractDataViewController, Configuration, DateUtils, Enums, Filename, Granularities */
(function () {
  'use strict';

  const MANUAL_BACKLOG_KEY = 'ManualBacklog';

  class ManualBacklogController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', 'dataPipeline', 'overlay', 'packagerFactory', '$q', '$scope', 'share', 'trader', 'transformerFactory'];
    }

    constructor (alerts, dataPipeline, overlay, packagerFactory, $q, $scope, share, trader, transformerFactory) {
      super($scope, share);
      this.alerts = alerts;
      this.dataPipeline = dataPipeline;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.$q = $q;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.$onInit();
    }

    _calculateDateRange () {
      return DateUtils.expansion(this.$scope.model.startDate, this.$scope.model.endDate, Enums.TimeUnit.DAY);
    }

    _fetchGrids (metric, configuration, action) {
      return this.packagerFactory.collect(
        this.packagerFactory.packagerType.manualBacklog,
        this._calculateDateRange(),
        metric,
        configuration,
        action);
    }

    download () {
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Exporting');
      this.$q.all(this.$scope.model.metricCategories.map((metric) => this._fetchGrids(metric, this.$scope.model, Enums.UserAction.DOWNLOAD)
        .ready()))
        .then((grids) => this.transformerFactory.toDocument(
          this.transformerFactory.transformerType.manualBacklog,
          'XLSX',
          grids,
          [
            {
              key: 'granularity',
              serialize: (granularity) => granularity.grains.names()
            },
            { key: 'dates' }
          ],
          [
            {
              key: 'granularity',
              serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
              source: 'pkg'
            },
            {
              key: 'values',
              source: 'row'
            }
          ]
        ).catch((error) => this.alerts.danger({ details: error, message: 'Unable to export records' }))
        ).then((workbook) => this.trader.download(
          workbook,
          Filename.create('Manual Backlog'),
          this.trader.toExtensionType('XLSX')
        )).finally(() => this.overlay.hide());
    }

    isSettingsValid () {
      return !_.isNil(this.$scope.model.startDate) &&
        !_.isNil(this.$scope.model.endDate) &&
        !_.isEmpty(this.$scope.model.metricCategories) &&
        !_.isEmpty(this.$scope.model.filters.node) &&
        !_.isEmpty(this.$scope.model.filters.productLine) &&
        !_.isEmpty(this.$scope.model.filters.sortType);
    }

    submit () {
      if (!this.isSettingsValid()) {
        return;
      }
      this.clearData();
      this.overlay.show('Fetching manual backlogs');
      this.$q.all(this.$scope.model.metricCategories.map((metric) => this._fetchGrids(metric, this.$scope.model, Enums.UserAction.VIEW)))
        .then((data) => {
          this.addData(...data);
          this.collapseSettings(true);
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      super.$onInit(
        ['METRIC_SELECTION', 'FILTER_SELECTION'],
        {
          'model.configuration.type': () => MANUAL_BACKLOG_KEY,
          'model.endDate': () => DateUtils.of().subtract(1, Enums.TimeUnit.DAY).format(Enums.DateFormat.IsoDate),
          'model.granularity.grains': () => Granularities.create().addMetricGrain().addNodeGrain().addProductLineGrain().addSortTypeGrain(),
          'model.maxEndDate': () => DateUtils.of().add(1, Enums.TimeUnit.WEEK).format(Enums.DateFormat.IsoDate),
          'model.startDate': () => DateUtils.of().subtract(1, Enums.TimeUnit.DAY).format(Enums.DateFormat.IsoDate),
          'validationSets': () => ({})
        }
      );

      // While NYR has been largely automated, there are some elements/scopes that are a work in progress.
      // For some of these work in progress scopes, some NYR is still being recorded manually under virtual PLs
      // (https://issues.amazon.com/issues/SOP-7392).
      this.$scope.methods.isVirtualProductLinesAllowed = () => Configuration.activation.isAvailable('ManualBacklog_AllowVirtualPLs');

      this.$scope.methods.isUploadReady = () => !_.isEmpty(this.$scope.validationSets.metrics) &&
        !_.isEmpty(this.$scope.validationSets.nodes) &&
        !_.isEmpty(this.$scope.validationSets.productLines) &&
        !_.isEmpty(this.$scope.validationSets.sortTypes);

      this.$scope.methods.onFileSelected = (file) => {
        this.clearData();
        this.overlay.show('Uploading');
        this.trader.upload(file).then((workbook) => {
          const packages = this.transformerFactory.toPackages(
            this.transformerFactory.transformerType.manualBacklog,
            workbook,
            this.$scope.validationSets);
          if (_.isEmpty(packages)) {
            this.overlay.hide();
            return;
          }
          this.dataPipeline.putManualBacklog(...packages)
            .then(() => this.alerts.success('Records updated successfully'))
            .catch((error) => this.alerts.danger({ details: error, message: `Unable to save records: ${_.get(error, 'data.message')}` }))
            .finally(() => this.overlay.hide());
        });
      };

      this.registerShare(
        [
          'model.endDate',
          'model.filters',
          'model.maxEndDate',
          'model.metricCategories',
          'model.minStartDate',
          'model.startDate'
        ]
      );
    }
  }

  angular.module('application.controllers').controller('ManualBacklogController', ManualBacklogController);
})();
