/* globals Cell */
(function () {
  'use strict';
  const DEFAULT_COLSPAN = 1;
  const DEFAULT_ROWSPAN = 1;

  class HeaderCell extends Cell {
    constructor (value, title, ...classes) {
      super(value, title, ...classes);
      this.setColspan(DEFAULT_COLSPAN);
      this.setRowspan(DEFAULT_ROWSPAN);
    }

    static create (value, title, ...classes) {
      return new HeaderCell(value, title, ...classes);
    }

    incrementColspan () {
      ++this.colspan;
      return this;
    }

    incrementRowspan () {
      ++this.rowspan;
      return this;
    }

    setColspan (value) {
      if (!_.isFinite(value)) {
        throw new Error(`HeaderCell: colspan must be a finite value: "${value}"`);
      }
      return this.setProperties({ colspan: value });
    }

    setRowspan (value) {
      if (!_.isFinite(value)) {
        throw new Error(`HeaderCell: rowspan must be a finite value: "${value}"`);
      }
      return this.setProperties({ rowspan: value });
    }
  }

  window.HeaderCell = Object.freeze(HeaderCell);
})();
