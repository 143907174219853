/* globals AbstractItemSelectorComponent, Configuration */
(function () {
  'use strict';
  class RouteItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', '$q'];
    }

    constructor ($element, $q) {
      super($element);
      this.$q = $q;
    }

    _getRoutesAsItems (routes) {
      return _.map(routes, (route) => ({ id: route.path, name: route.name }));
    }

    _loadData () {
      const routes = _.filter(Configuration.routes, (route) => !route.exclude || _.isPlainObject(route.exclude));
      this.routesData = [{
        id: 'Home & Main',
        items: this._getRoutesAsItems(_.concat(
          _.filter(routes, (route) => route.isHome()),
          _.filter(routes, (route) => route.isMain())
        )),
        name: 'Home & Main'
      }, {
        id: 'Administration',
        items: this._getRoutesAsItems(_.filter(routes, (route) => route.isAdministration())),
        name: 'Administration'
      }];

      this.itemSelectorInterface = {
        canSelectAllItems: (initialSelections) => _.isEmpty(initialSelections),
        displayMode: 'compact',
        items: () => this.$q.resolve(this.routesData)
      };
    }
  }

  angular.module('application.components')
    .component('routeItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings(),
      controller: RouteItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
