/* globals AbstractSourceBasedModel, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'datasetDate',
    'endDate',
    'executionId',
    'parameters',
    'startDate',
    'status'
  ]);

  class DataIngestionWorkflowExecution extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.DataIngestionWorkflowExecution = Object.freeze(DataIngestionWorkflowExecution);
})();
