/* globals Alert, Enums */
(function () {
  'use strict';
  const BROADCAST_CHANNEL = 'alerts';
  const BROADCAST_CLEAR_ALERTS = 'alerts-clear';
  const EXCEPTION_PATTERN = Object.freeze({
    ALREADY_EXISTS: '[ALREADY_EXISTS]',
    BAD_REQUEST: '[BAD_REQUEST]',
    INTERNAL_ERROR: '[INTERNAL_ERROR]',
    INVALID_INPUT: '[INVALID_INPUT]',
    NOT_FOUND: '[NOT_FOUND_STR]',
    RETRY_ERROR: '[RETRY_ERROR_STR]'
  });
  const ERROR_MESSAGE = Object.freeze({
    ALREADY_EXISTS: ' matched an existing entry. To avoid duplication, your request has been rejected.',
    BAD_REQUEST: ' was improperly formatted. Please refresh your browser and try again.',
    CATCH_ALL: ' caused an unknown error. Please refresh your browser and try again.',
    GENERIC_ERROR: 'Unable to perform the operation due to a network error. Please refresh your browser and try again.',
    INTERNAL_ERROR: ' caused an internal service error. Please wait and try again later.',
    INVALID_INPUT: ' contained an invalid input that was rejected.',
    NOT_FOUND: ' could not be found. Please confirm the content you are requesting and try again.',
    RETRY_ERROR: ' has exceeded the maximum number of attempts and failed. Please wait and try again later.'
  });
  const DELETE_ALERT = Object.freeze({
    FAILURE_MESSAGE: 'Delete Failed!',
    SUBJECT_STR: 'Your delete request',
    SUCCESS_MESSAGE: 'Delete Succeeded.'
  });
  const SAVE_ALERT = Object.freeze({
    FAILURE_MESSAGE: 'Save Failed!',
    SUBJECT_STR: 'Your save request',
    SUCCESS_MESSAGE: 'Save Succeeded.'
  });
  const QUERY_ALERT = Object.freeze({
    FAILURE_MESSAGE: 'Query Failed!',
    SUBJECT_STR: 'Your query request',
    SUCCESS_MESSAGE: 'Query Succeeded.'
  });

  const failureMessageBuidler = (contentType, requestType, response) => {
    const alertString = `${contentType}: ${requestType.FAILURE_MESSAGE} ${requestType.SUBJECT_STR}`;
    const errorMessage = _.get(response, 'data.errorMessage');
    if (!_.isString(errorMessage)) {
      return ERROR_MESSAGE.GENERIC_ERROR;
    }

    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.ALREADY_EXISTS)) {
      return alertString.concat(ERROR_MESSAGE.ALREADY_EXISTS);
    }
    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.BAD_REQUEST)) {
      return alertString.concat(ERROR_MESSAGE.BAD_REQUEST);
    }
    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.INTERNAL_ERROR)) {
      return alertString.concat(ERROR_MESSAGE.INTERNAL_ERROR);
    }
    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.INVALID_INPUT)) {
      return alertString.concat(ERROR_MESSAGE.INVALID_INPUT);
    }
    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.NOT_FOUND)) {
      return alertString.concat(ERROR_MESSAGE.NOT_FOUND);
    }
    if (response.data.errorMessage.includes(EXCEPTION_PATTERN.RETRY_ERROR)) {
      return alertString.concat(ERROR_MESSAGE.RETRY_ERROR);
    }

    return alertString.concat(ERROR_MESSAGE.CATCH_ALL);
  };

  class GenericRequestAlert {
    constructor ($rootScope, queue, broadcast, requestType) {
      this.$rootScope = $rootScope;
      this.queue = queue;
      this.broadcast = broadcast;
      this.requestType = requestType;
    }

    /*
     * Broadcast a request failure alert.
     * @param contentType the subject of the request. E.g. 'Your FC', 'Your Prefereces', 'SAT2'... .
     * @param content the JSON blob.
     */
    failure (contentType, details) {
      this.broadcast(
        Enums.AlertType.DANGER,
        {
          details: details,
          message: failureMessageBuidler(contentType, this.requestType, details)
        }
      );
    }

    /*
     * Broadcast a request success alert.
     * @param contentType the subject of the request. Eg. 'Your FC', 'Your Prefereces', 'SAT2'... .
     */
    success (contentType) {
      this.broadcast(Enums.AlertType.SUCCESS, `${contentType}: ${this.requestType.SUCCESS_MESSAGE}`);
    }
  }

  class AlertsService {
    static get $inject () {
      return ['$rootScope'];
    }

    constructor ($rootScope) {
      this.$rootScope = $rootScope;
      this.queue = [];
    }

    _broadcast (type, data, importance) {
      const alert = Alert.create(type, data, importance);
      this.queue.push(alert);
      this.$rootScope.$broadcast(BROADCAST_CHANNEL, alert);
    }

    /*
     * Gets all alerts broadcast, in order, since application start.
     */
    all () {
      return this.queue;
    }

    /*
     * Clears all alerts from the queue.
     */
    clear () {
      this.queue.length = 0;
      this.$rootScope.$broadcast(BROADCAST_CLEAR_ALERTS);
    }

    /*
     * Broadcast a danger alert.
     * @param data the trusted html message of the alert box.
     * @param importance (optional) the importance level of the alert.
     */
    danger (data, importance) {
      this._broadcast(Enums.AlertType.DANGER, data, importance);
    }

    /*
     * Create a delete alert object.
     */
    delete () {
      return new GenericRequestAlert(this.$rootScope, this.queue, this._broadcast.bind(this), DELETE_ALERT);
    }

    /*
     * Broadcast a danger alert for an error.
     * @param error the error that was caught.
     * @param importance (optional) the importance level of the error.
     */
    error (error, importance) {
      this.danger(
        {
          details: error,
          message: _.has(error, 'data.errorMessage') ? error.data.errorMessage : ERROR_MESSAGE.GENERIC_ERROR
        },
        importance
      );
    }

    /*
     * Broadcast an information alert.
     * @param data the trusted html message of the alert box.
     * @param importance (optional) the importance level of the alert.
     */
    info (data, importance) {
      this._broadcast(Enums.AlertType.INFO, data, importance);
    }

    /*
     * Create a query alert object.
     */
    query () {
      return new GenericRequestAlert(this.$rootScope, this.queue, this._broadcast.bind(this), QUERY_ALERT);
    }

    /*
     * Create a save alert object.
     */
    save () {
      return new GenericRequestAlert(this.$rootScope, this.queue, this._broadcast.bind(this), SAVE_ALERT);
    }

    /*
     * Broadcast a success alert.
     * @param data the trusted html message of the alert box.
     * @param importance (optional) the importance level of the alert.
     */
    success (data, importance) {
      this._broadcast(Enums.AlertType.SUCCESS, data, importance);
    }

    /*
     * Broadcast a warning alert.
     * @param data the trusted html message of the alert box.
     * @param importance (optional) the importance level of the alert.
     */
    warning (data, importance) {
      this._broadcast(Enums.AlertType.WARNING, data, importance);
    }

    /*
     * Adds listener function as a listener to all alerts.
     * @param listener the callback function of the form: function (event, type, data).
     */
    $on (listener) {
      this.$rootScope.$on(BROADCAST_CHANNEL, listener);
    }

    /*
     * Adds listener function as a listener to all alerts on channel clear alerts.
     * @param listener the callback function of the form: function (event, type, data).
     */
    $onClear (listener) {
      this.$rootScope.$on(BROADCAST_CLEAR_ALERTS, listener);
    }
  }

  angular.module('application.services').service('alerts', AlertsService);
})();
