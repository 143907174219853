// This file should only contain pure functions that have no dependency on state or any object defined in the application.
(function () {
  'use strict';

  class Utilities {
    // Creates a cartesian cross product of all provided arrays. (https://en.wikipedia.org/wiki/Cartesian_product)
    static cartesianCrossProduct (...arrays) {
      if (_.isEmpty(arrays)) {
        return [];
      }
      return _.reduce(arrays,
        (accumulator, array) => _.flatMap(accumulator,
          (arrayInAccumulator) => _.map(array,
            (element) => arrayInAccumulator.concat([element]))), [[]]);
    }

    // Conversion utilites
    static convert (original) {
      return {
        // Converts a list of arbitrary types to a list of finite numbers (purging any value that converts to a non-number).
        toFiniteNumbers: () => _.filter(_.map(original, Number), _.isFinite),
        // Converts a list of arbitrary types to a list of strings.
        toStrings: () => _.map(original, _.toString)
      };
    }

    // Determines if two functions are equal to each other.
    static equalFunctions (value, other) {
      return _.isFunction(value) && _.isFunction(other) && value.toString() === other.toString();
    }

    // Returns the property value of a provided source object or the result from a provided method.
    static getPropertyOrMethod (source, property, defaultValue) {
      const propertyValue = _.get(source, property, defaultValue);
      return _.isFunction(propertyValue) ? source[property]() : propertyValue;
    }

    // Determines if any of the needles are included in the haystack.
    static includesSome (haystack, ...needles) {
      return _.some(needles, (needle) => _.includes(haystack, needle));
    }

    // Determines if the provided value is a Promise. (https://stackoverflow.com/questions/27746304/how-do-i-tell-if-an-object-is-a-promise)
    static isPromise (value) {
      return _.isFunction(_.get(value, 'then'));
    }

    // Searches for the first match in a list of properties on an object.
    static match (object, properties, pattern) {
      if (_.isNil(object) || _.isEmpty(properties) || _.isEmpty(pattern)) {
        return false;
      }
      const expression = new RegExp(pattern, 'i');
      return properties.findIndex((property) => expression.test(_.get(object, property, ''))) > -1;
    }

    // Gets value from target that might be a function.
    static optionalFunction (target) {
      if (_.isFunction(target)) {
        return target();
      }
      return target;
    }

    // Performance of _.omit is incredibly poor, use vanilla solution instead.
    static omit (sourceObj, keysToOmit) {
      if (_.isEmpty(keysToOmit)) {
        return _.clone(sourceObj);
      }
      const target = {};
      _.forEach(_.keys(sourceObj), (key) => {
        if (keysToOmit.indexOf(key) >= 0 || !Object.prototype.hasOwnProperty.call(sourceObj, key)) {
          return;
        }
        target[key] = sourceObj[key];
      });
      return target;
    }

    // Removes all occurances of <,>,:,”,/,\,|,?,*,[,]
    static removeIllegalFileSystemChars (value) {
      return _.replace(value, new RegExp('[\\\\/:*?"\\]\\[<>|]', 'g'), '');
    }
  }

  _.mixin({ sop: Object.freeze(Utilities) });
})();
