/* globals AbstractSourceBasedModel */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'metricId',
    'periodStartDate',
    'recordGranularity',
    'timeGranularity',
    'version',
    {
      source: 'recordMetadata.lastUpdatedAt',
      target: 'lastUpdatedAt'
    },
    {
      source: 'recordMetadata.lastUpdatedBy',
      target: 'lastUpdatedBy'
    },
    {
      source: 'records.granularity.productLine',
      target: 'granularity.productLine'
    },
    {
      source: 'records.granularity.sortType',
      target: 'granularity.sortType'
    },
    {
      source: 'value',
      target: 'value'
    },
    {
      source: 'records.granularity.warehouse',
      target: 'granularity.warehouse'
    }
  ]);

  class ManualBacklogRecord extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }
  }

  window.ManualBacklogRecord = Object.freeze(ManualBacklogRecord);
})();
