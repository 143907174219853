/* globals Validate */
(function () {
  'use strict';
  const ACTION_PROPERTY = 'action',
        ARRAY_TOKEN = '[]',
        DESERIALIZE_PROPERTY = 'deserialize',
        KEY_PROPERTY = 'key',
        MUTATION_PROPERTY = 'mutation',
        PERSIST_PROPERTY = 'persist',
        RECOVER_PROPERTY = 'recover',
        SERIALIZE_PROPERTY = 'serialize',
        SOURCE_KEY_PROPERTY = 'source',
        TARGET_KEY_PROPERTY = 'target',
        UNSET_PROPERTY = 'unset';

  const identity = (property, fallbackKey) => {
    fallbackKey = fallbackKey || KEY_PROPERTY;
    return property[KEY_PROPERTY] || property[fallbackKey] || property;
  };

  const isArrayKey = (key) => _.includes(key, ARRAY_TOKEN);

  const validateProperties = (properties) => {
    const errors = [];
    properties.forEach((property) => {
      const isValidString = _.isString(property) && !Validate.isBlank(property);
      const isValidObject =
        Validate.hasFunctionValue(property, ACTION_PROPERTY) ||
        Validate.hasFunctionValue(property, MUTATION_PROPERTY) ||
        (
          Validate.hasNonBlankStringValue(property, KEY_PROPERTY) ||
          (
            Validate.hasNonBlankStringValue(property, SOURCE_KEY_PROPERTY) &&
            Validate.hasNonBlankStringValue(property, TARGET_KEY_PROPERTY)
          )
        );
      if (!isValidString && !isValidObject) {
        errors.push(property);
      }
    });
    if (!_.isEmpty(errors)) {
      const message = ['Mapper: Invalid properties found:'];
      errors.forEach((error) => message.push(JSON.stringify(error)));
      throw new Error(message.join('\n\t'));
    }
  };

  const splitArrayKey = (key) => {
    const tokens = key.split(ARRAY_TOKEN);
    if (tokens.length !== 2 || Validate.isBlank(tokens[0]) || Validate.isBlank(tokens[1])) {
      throw new Error(`Mapper: Provided key must have 2 parts to be an Array Key: ${key}`);
    }
    return {
      arrayKey: tokens[0],
      itemKey: _.trimStart(tokens[1], '.')
    };
  };


  // If the value is a Promise, deep cloning it will render it useless, and thus
  // it should be passed directly. For all other types, a deep clone should be made
  // as to not share a reference between the target and source. (https://sim.amazon.com/issues/SOP-9923)
  const deepCloneValue = (value) => _.cloneDeepWith(value, (element) => {
    if (_.sop.isPromise(element)) {
      return element;
    }
  });

  const getValue = (property, transformFnKey, source, sourceKey) => {
    if (isArrayKey(sourceKey)) {
      const tokens = splitArrayKey(sourceKey);
      const list = _.get(source, tokens.arrayKey);
      let values = _.map(list, (item) => _.get(item, tokens.itemKey));
      if (_.has(property, transformFnKey)) {
        values = _.map(values, (value, index) => property[transformFnKey](value, index));
      }
      return values;
    }

    let value = _.get(source, sourceKey);
    if (_.has(property, transformFnKey)) {
      value = property[transformFnKey](value);
    }
    return value;
  };

  const setValue = (target, targetKey, value, unset = false) => {
    if (unset) {
      _.unset(target, targetKey);
      return;
    }
    if (_.isNil(value)) {
      return;
    }
    if (isArrayKey(targetKey)) {
      _.forEach(value, (val, index) => _.set(target, targetKey.replace(ARRAY_TOKEN, `[${index}]`), deepCloneValue(val)));
    } else {
      _.set(target, targetKey, deepCloneValue(value));
    }
  };

  class Mapper {
    constructor () {
      this.definitions = {};
    }

    static apply (source, target, definition, postApplyCallback) {
      if (_.isNil(source) || _.isNil(target) || !Array.isArray(definition) || _.isEmpty(definition)) {
        return target;
      }
      _.forEach(definition, (property) => {
        // Ensure property is allowed to be applied
        if (_.has(property, RECOVER_PROPERTY) && !_.sop.optionalFunction(property[RECOVER_PROPERTY])) {
          return;
        }
        // Handle action property that must exist on its own
        if (_.has(property, ACTION_PROPERTY) && _.isFunction(property[ACTION_PROPERTY])) {
          property[ACTION_PROPERTY]();
          return;
        }
        // Handle mutation property that must exist on its own
        if (_.has(property, MUTATION_PROPERTY) && _.isFunction(property[MUTATION_PROPERTY])) {
          property[MUTATION_PROPERTY](source, target);
          return;
        }
        const sourceKey = identity(property, SOURCE_KEY_PROPERTY);
        const targetKey = identity(property, TARGET_KEY_PROPERTY);
        const value = getValue(property, DESERIALIZE_PROPERTY, source, sourceKey);
        setValue(target, targetKey, value, _.get(property, UNSET_PROPERTY, false));
      });
      if (_.isFunction(postApplyCallback)) {
        postApplyCallback();
      }
      return target;
    }

    static collect (source, definition) {
      const target = {};
      if (_.isNil(source) || !Array.isArray(definition) || _.isEmpty(definition)) {
        return target;
      }
      _.forEach(definition, (property) => {
        // Ensure property is allowed to be collected
        if (_.has(property, PERSIST_PROPERTY) && !_.sop.optionalFunction(property[PERSIST_PROPERTY])) {
          return;
        }
        // Handle action property that must exist on its own
        if (_.has(property, ACTION_PROPERTY) && _.isFunction(property[ACTION_PROPERTY])) {
          property[ACTION_PROPERTY]();
          return;
        }
        const sourceKey = identity(property, SOURCE_KEY_PROPERTY);
        const targetKey = identity(property, TARGET_KEY_PROPERTY);
        const value = getValue(property, SERIALIZE_PROPERTY, source, sourceKey);
        setValue(target, targetKey, value);
      });
      return target;
    }

    static create () {
      return new Mapper();
    }

    define (key, definition) {
      if (!_.isEmpty(definition)) {
        this.definitions[key] = definition;
      }
      return this.definitions[key];
    }

    apply (key, context) {
      const definition = this.define(key);
      if (!_.isNil(definition)) {
        Mapper.apply(context, definition.root, definition.properties, definition.postApplyCallback);
      }
    }

    collect (key) {
      const definition = this.define(key);
      if (_.isNil(definition)) {
        // If there is no definition then there is nothing to collect
        return;
      }
      if (_.isFunction(definition.permitCreateCallback) && definition.permitCreateCallback() !== true) {
        // If there is a permitCreateCallback and it does not return true then do nothing
        return;
      }
      return Mapper.collect(definition.root, definition.properties);
    }

    register (key, root, properties, permitCreateCallback, postApplyCallback) {
      if (!_.isString(key) || Validate.isBlank(key)) {
        throw new Error('Mapper: key must be a non-blank string');
      }
      if (_.isNil(root)) {
        throw new Error('Mapper: root cannot be nil and must be an object');
      }
      if (_.isEmpty(properties)) {
        throw new Error('Mapper: properties cannot be nil or empty');
      }
      validateProperties(properties);
      this.define(
        key,
        { permitCreateCallback, postApplyCallback, properties, root }
      );
    }
  }

  window.Mapper = Object.freeze(Mapper);
})();
