/* globals AbstractSourceBasedModel, Enums, TaskStatus, Validate */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'attempts', // Number of completed task attempts
    'dependencies',
    'endTime', // Completion time of latest attempt; null if no attempt has finished
    'initialStartTime', // Start time of first attempt
    'lastReportedMessage',
    'logs', // Array of task logs ordered from earliest to latest attempt
    'startTime', // Start time of latest attempt
    'status', // Status of latest attempt
    'taskName',
    'taskUrl'
  ]);

  class DataIngestionWorkflowTask extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    get lastStatus () {
      if (TaskStatus.isSuccess(this.status) && this.attempts > 1) {
        return Enums.TaskStatus.SUCCESS_WITH_FAILURES;
      }
      return this.status;
    }

    hasDependencies () {
      return Array.isArray(this.dependencies) && !_.isEmpty(this.dependencies);
    }

    hasLastReportedMessage () {
      return _.isString(this.lastReportedMessage) && !Validate.isBlank(this.lastReportedMessage);
    }

    hasLogs () {
      return Array.isArray(this.logs) && !_.isEmpty(this.logs);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.DataIngestionWorkflowTask = Object.freeze(DataIngestionWorkflowTask);
})();
