/* globals Enums, AbstractSourceBasedModel */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'endDate',
    'paginationKeys',
    'ratioID',
    'ratioIdentifier',
    'scope',
    'startDate',
    'version'
  ]);

  class RatioMetadata extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);

      if (_.isNil(this.paginationKeys)) {
        this.paginationKeys = [];
      }
    }
  }

  window.RatioMetadata = Object.freeze(RatioMetadata);
})();
