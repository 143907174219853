/* globals AbstractElementComponent, Configuration */
(function () {
  'use strict';

  function extractScopeFromRole (role, roleType, scopes) {
    if (role.includes(roleType)) {
      const scope = role.slice(0, role.indexOf(roleType) - 1);
      scopes.push(_.toUpper(scope).replace('-', '_'));
    }
  }

  class UserProfileSummaryController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$authentication', 'userProfile'];
    }

    constructor (alerts, $authentication, userProfile) {
      super();
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.userProfile = userProfile;
    }

    _fetchUserProfileData (options) {
      this.userProfile.getRecentLogins(options)
        .then((recentLogins) => {
          this.mostRecentLogin = recentLogins.mostRecentLogin;
          this.previousLogin = recentLogins.previousLogin;
        });
      this.userProfile.getMostVisitedScope(options)
        .then((mostVisitedScope) => this.mostVisitedScope = mostVisitedScope);
    }

    _loadData () {
      this.adminForScopes = [];
      this.accessToTenants = [];
      const accessToScopes = [];
      _.forEach(this.roles, (role) => {
        extractScopeFromRole(role, 'admin', this.adminForScopes);
        extractScopeFromRole(role, 'read', accessToScopes);
      });
      this.accessToTenants = Configuration.scopes.uniqueTenantsFromScopes(accessToScopes).map((tenant) => _.unescape(tenant.name));
      this.hasPortalAccess = !_.isEmpty(this.accessToTenants);
      this.isAdmin = !_.isEmpty(this.adminForScopes);
      this.isDev = _.includes(this.roles, 'dev');
      this.recentLogins = undefined;
      if (this.alias !== this.$authentication.profile().alias) {
        // Fetch the user profile data right away if alias does not match the authenticated user alias.
        this._fetchUserProfileData({ alias: this.alias });
        return;
      }
      // Check for user profile update in progress and add a listener if alias matches the authenticated user alias.
      if (!this.userProfile.isProfileUpdateInProgress()) {
        this._fetchUserProfileData();
      }
      this.userProfile.$onProfileUpdate(() => this._fetchUserProfileData());
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('userProfileSummary', {
      bindings: {
        /*
         * @alias String Amazon user alias.
         */
        alias: '<',
        /*
         * @roles List of roles (strings) that the user has permissions for.
         */
        roles: '<'
      },
      controller: UserProfileSummaryController,
      templateUrl: 'templates/components/user-profile-summary.component.html'
    });
})();
