(function () {
  'use strict';
  /**
   * AWS Signature V4 HTTP Interceptor
   */
  class AwsSignatureV4HttpInterceptorService {
    static get $inject () {
      return ['awsSignatureV4'];
    }

    constructor (awsSignatureV4) {
      this.awsSignatureV4 = awsSignatureV4;
      this.request = this.request.bind(this);
    }

    request (config) {
      if (_.isNil(config.aws) || !this.awsSignatureV4.hasAwsCredentials(config.aws)) {
        return config;
      }

      const awsSigningConfiguration = _.defaults({}, config.aws);
      delete config.aws;
      // Re-use existing request transformers for generating the payload.
      if (config.transformRequest) {
        awsSigningConfiguration.payloadSerializer = () => config.transformRequest.reduce((prev, transformer) => transformer(prev), config.data);
      }
      // Create the authentication headers and merge them into the existing ones
      const signedHeaders = this.awsSignatureV4.sign(awsSigningConfiguration, config);
      _.merge(config.headers, signedHeaders);
      return config;
    }
  }

  angular.module('application.services').service('awsSignatureV4HttpInterceptor', AwsSignatureV4HttpInterceptorService);
})();
