/* globals AWS, Configuration, Identity, URLSearchParams */
(function () {
  'use strict';
  const MIDWAY_ENDPOINT = 'https://midway-auth.amazon.com/SSO';
  const SCOPE = 'openid';
  const RESPONSE_TYPE = 'id_token';
  const ROLE_SESSION_NAME = 'IPTAuthorizationSession';

  /**
   * Midway service wrapper class.
   */
  class MidwayService {
    static get $inject () {
      return ['$http', '$q', '$window'];
    }

    constructor ($http, $q, $window) {
      this.$http = $http;
      this.$q = $q;
      this.$window = $window;
      this.clientId = this.$window.location.host;
    }

    /**
     * Redirect the user to Midway for authentication. Midway will then redirect back to the provided redirect or the current location if no redirect is provided.
     *
     * @param redirect  redirect URL.
     */
    authenticate (redirect) {
      // If no redirect is provided then redirect back to current location.
      const redirectUrl = redirect || this.$window.location.href;
      this.$window.location.href = this._buildMidwayRequest(true, redirectUrl, this.clientId);
    }

    /**
     * Fetches a web identity token from Midway if authenticated else authenticates the user.
     *
     * @param redirect  redirect URL.
     * @returns Midway token if authenticated else redirect to Midway through the 'authenticate' method above.
     */
    fetchMidwayToken (redirect) {
      // If no redirect is provided then redirect back to current location.
      const redirectUrl = redirect || this.$window.location.href;
      return this.$http.get(this._buildMidwayRequest(false, redirectUrl, this.clientId), { withCredentials: true })
        // Initialize the AWS.config.credentials but then return the Midway token as the AWS credentials are global.
        .then((midwayResponse) => this.initializeAwsConfig(midwayResponse.data, Configuration.authorization.awsCredentialsConfig.currentCredentialsConfig()).then(() => midwayResponse.data))
        // Catch any Midway error and authenticate the user.
        // SIM: https://sim.amazon.com/issues/SOP-14182
        .catch(() => this.authenticate(redirectUrl));
    }

    /**
     * @VisibleForTesting
     * Initializes AWS config credentials.
     *
     * @param webIdentityToken  Midway web identity token.
     * @param configCredentials The roleArn and region configured for the given environment (beta, gamma, prod).
     * @returns AWS web identity credentials promise.
     */
    initializeAwsConfig (webIdentityToken, configCredentials) {
      // IPT uses OpenId Connect (https://openid.net/connect/) through Midway and AWS web identity federation (https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_providers_oidc.html) to assume the configured IAM roleArn.
      // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/WebIdentityCredentials.html#constructor-property
      AWS.config.region = configCredentials.region;
      AWS.config.credentials = new AWS.WebIdentityCredentials({
        RoleArn: configCredentials.roleArn,
        RoleSessionName: ROLE_SESSION_NAME,
        WebIdentityToken: webIdentityToken
      });
      return AWS.config.credentials.getPromise();
    }

    _buildMidwayRequest (isRedirected, redirectUrl, clientId) {
      const queryParams = {
        client_id: clientId,
        nonce: Identity.uuid(),
        redirect_uri: redirectUrl,
        response_type: RESPONSE_TYPE,
        scope: SCOPE
      };
      const queryString = new URLSearchParams(queryParams);
      return isRedirected ? `${MIDWAY_ENDPOINT}/redirect?${queryString}` : `${MIDWAY_ENDPOINT}?${queryString}`;
    }

  }

  angular.module('application.services').service('midway', MidwayService);
})();
