/* globals ClassDecorator, DOMRect, Validate */
(function () {
  'use strict';
  const EMPTY_STRING = '';

  const DEFAULT_INPUT_CONFIGURATION = Object.freeze({
    bindings: {
      'change keyup': (element, cell) =>
        () => {
          const rawValue = element.val();
          cell.set(rawValue === EMPTY_STRING ? undefined : rawValue);
          element.attr('value', rawValue);
        }
    },
    type: 'text'
  });

  const MANDATORY_ELEMENT_BINDINGS = Object.freeze({
    $destroy: (element) => () => element.off()
  });

  const TAG = Object.freeze({
    COL: '<col>',
    INPUT: '<input>',
    TD: '<td>',
    TH: '<th>',
    TR: '<tr>'
  });

  const addBindings = function (element, cell, bindings) {
    if (_.isNil(element) || _.isNil(cell) || _.isEmpty(bindings)) {
      return;
    }

    _.forEach(_.defaults(bindings, MANDATORY_ELEMENT_BINDINGS), (bindFn, bindTo) => element.on(bindTo, bindFn(element, cell)));
  };

  // cell can define an input property to customize the input element markup:
  //   input: {
  //     bindings: { key: function, ... }
  //     pattern: String
  //     type: String (default: 'text')
  //     value: String (default: cell.value)
  //     ...
  //   }
  const inputElement = function (cell) {
    const element = angular.element(TAG.INPUT);
    const input = Object.assign({}, DEFAULT_INPUT_CONFIGURATION, cell.input);

    element.attr('value', _.defaultTo(cell.value, EMPTY_STRING));
    _.forEach(input, (value, key) => {
      if (_.isString(key) && _.isString(value)) {
        element.attr(key, value);
      }
    });
    addBindings(element, cell, input.bindings);
    return element;
  };

  const tableCellElement = function (tag, cell, child) {
    const element = angular.element(tag);
    element.addClass(ClassDecorator.toClassString(cell.class));
    element.attr('colspan', cell.colspan);
    element.attr('rowspan', cell.rowspan);
    element.attr('title', cell.title);
    if (_.isNil(child)) {
      element.text(cell.value);
      if (cell.element) {
        cell.element = element;
      }
    } else {
      element.append(child);
    }
    addBindings(element, cell, cell.bindings);
    return element;
  };

  class Html {
    static cols (rows) {
      if (_.isEmpty(rows)) {
        return [];
      }
      // We look at the headers property from the first row and the data property from the second row.
      // This is due to the first row containing the week numbers and using colspan to span columns. The data property is then
      // not representative of the number of cells in a grid.
      const rowIndex = rows.length > 1 ? 1 : 0;
      const cells = [].concat(rows[0].headers).concat(rows[rowIndex].data);
      return _.map(cells, (cell) => angular.element(TAG.COL).addClass(ClassDecorator.toClassString(cell.class)));
    }

    static externalLink (link, text) {
      if (Validate.isBlank(link) || Validate.isBlank(text)) {
        throw new Error('Html: link and text of an external link must not be blank.');
      }
      return `<a href="${link}" target="_blank" rel="noopener">${text}</a>`;
    }

    static getViewportRect () {
      return Object.freeze(
        new DOMRect(
          window.scrollX,
          window.scrollY,
          window.innerWidth,
          window.innerHeight
        )
      );
    }

    static trs (rows) {
      if (_.isEmpty(rows)) {
        return [];
      }
      return _.map(rows, (row) => {
        const cells = [];
        const tr = angular.element(TAG.TR);
        _.forEach(row.headers, (cell) => cells.push(tableCellElement(TAG.TH, cell)));
        _.forEach(row.data, (cell) => cells.push(
          tableCellElement(
            TAG.TD,
            cell,
            cell.isEditable ? inputElement(cell) : undefined
          )
        ));
        _.forEach(cells, (cell) => tr.append(cell));
        return tr;
      });
    }
  }

  window.Html = Object.freeze(Html);
})();
