/* globals AbstractElementComponent */
(function () {
  'use strict';

  class ChartController extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element) {
      super($element, {
        isDataEmptyFn: () => (!_.isNil(this.data) && _.isEmpty(this.data)) || _.isNil(this.options),
        isLoadingFn: () => _.isNil(this.data)
      });
    }

    showDataOptions () {
      return this.showData || this.showOptions;
    }

    showDataOptionsDivider () {
      return this.showData && this.showOptions;
    }

    $onChanges () {
      if (!this.state.isInitialized() || _.isNil(this.api)) {
        return;
      }
      this.api.refresh();
    }
  }

  angular.module('application.components')
    .component('chart', {
      bindings: {
        data: '<',
        options: '<',
        refresh: '<',
        showData: '<',
        showOptions: '<'
      },
      controller: ChartController,
      templateUrl: 'templates/components/chart.component.html'
    });
})();
