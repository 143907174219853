/* globals AbstractItemSelectorComponent*/
(function () {
  'use strict';
  class MarketplaceModelItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'modelMarketplace'];
    }

    constructor ($element, modelMarketplace) {
      super($element);
      this.modelMarketplace = modelMarketplace;
    }

    _loadData () {
      this.itemSelectorInterface = {
        items: () => this._modelsByGroup()
      };
    }

    _modelsByGroup () {
      return this.modelMarketplace.models(this.marketplaceModelType).then(
        (models) => [
          {
            id: 'all',
            items: models,
            name: 'All'
          }]);
    }

    $onInit () {
      super.$onInit();
      this.disableSearch = true;
      this.disablePreferences = true;
      this.noDefaultSelections = true;
    }
  }

  angular.module('application.components')
    .component('marketplaceModelItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        /*
         * @marketplaceModelType is the group of models that should be fetched for selection.
         */
        marketplaceModelType: '<'
      }),
      controller: MarketplaceModelItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
