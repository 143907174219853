/* globals AbstractBinaryElementComponent */
(function () {
  'use strict';
  class BinarySwitchController extends AbstractBinaryElementComponent {
    showLabelLeft () {
      return !_.isEmpty(this.labelText) && this.labelPosition === 'left';
    }

    showLabelRight () {
      return !_.isEmpty(this.labelText) && (_.isEmpty(this.labelPosition) || this.labelPosition === 'right');
    }
  }

  angular.module('application.components')
    .component('binarySwitch', {
      bindings: {
        // Value to set when the control is disabled
        disabledValue: '<',
        labelClasses: '@',
        labelPosition: '@',
        labelText: '@'
      },
      controller: BinarySwitchController,
      require: {
        ngModel: '^ngModel'
      },
      templateUrl: 'templates/components/binary-switch.component.html'
    });
})();
