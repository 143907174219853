/* globals AbstractItemSelectorComponent, GrainDefinition */
(function () {
  'use strict';
  class ProductLineItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'planConfiguration'];
    }

    constructor ($element, planConfiguration) {
      super($element);
      this.planConfiguration = planConfiguration;
    }

    _applyFilters (list) {
      // SIM: https://issues.amazon.com/issues/SOP-13428 - composite scopes fetch PLs across all scopes.
      // While this is fine for the Product Line page, this results in repeated PLs for the PL filter list in the Plan Viewers.
      // Removing duplicates until the config store is updated to support a "unique" flag.
      const filteredList = _.uniqBy(list, 'name');
      if (!this.excludeVirtuals && !this.excludeUnknown) {
        return filteredList;
      }

      if (this.excludeVirtuals) {
        _.remove(filteredList, (productLine) => !GrainDefinition.isUnknownGrain(productLine) && productLine.isVirtual);
      }
      if (this.excludeUnknown) {
        _.remove(filteredList, (productLine) => GrainDefinition.isUnknownGrain(productLine));
      }
      return filteredList;
    }

    _loadData () {
      // If the configuration is not defined then do no further processing
      if (_.isEmpty(this.configuration)) {
        this.itemSelectorInterface = undefined;
        return;
      }

      this.itemSelectorInterface = {
        items: () => this._productLinesByGroups(),
        preferences: {
          hive: this.configuration.type,
          key: 'productLine'
        },
        selectionChangeTransform: (selectedItems) => ({ list: selectedItems.map((item) => item.name) })
      };
    }

    _productLinesByGroups () {
      return this.planConfiguration.productLine().list({ asOfDate: this.configuration.date }, { planType: this.configuration.type })
        .then((data) => {
          const groups = _.groupBy(this._applyFilters(data), 'group');
          return _.map(Object.keys(groups), (groupKey) => ({
            id: groupKey,
            items: groups[groupKey].map((item) => _.set(item, 'id', item.name)),
            name: groupKey
          }));
        });
    }
  }

  angular.module('application.components')
    .component('productLineItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        /*
         * @excludeUnknown (Optional) When true the "Unknown" PL will be excluded from list of selectable PLs.
         */
        excludeUnknown: '<',
        /*
         * @excludeVirtuals (Optional) When true all PLs with the isVirtual property set will be filtered out
         * other than the "Unknown" PL, which although virtual is required in cases where no other virtuals are (https://issues.amazon.com/SOP-8738).
         */
        excludeVirtuals: '<'
      }),
      controller: ProductLineItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
