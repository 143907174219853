'use strict';
angular.module('application.components')
  .component('spinner', {
    bindings: {
      labelClasses: '@',
      labelText: '@',
      spinnerClasses: '@'
    },
    templateUrl: 'templates/components/spinner.component.html'
  });
