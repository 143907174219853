/* globals Enums, PlanMetadata, Ready */
(function () {
  'use strict';

  class NetworkPlannerExecutionsModalController {
    static get $inject () {
      return ['alerts', 'orchestrator', 'overlay', '$authentication', '$q'];
    }

    constructor (alerts, orchestrator, overlay, $authentication, $q) {
      this.alerts = alerts;
      this.orchestrator = orchestrator;
      this.overlay = overlay;
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.executions),
        isLoadingFn: () => this.loading
      });
      this.$authentication = $authentication;
      this.$q = $q;
    }

    /**
     * Filter the prefinalDrafts from the Edit Tracking response
     * @param response the Edit Tracking response
     * @returns {PlanMetadata[]} an array of prefinalDrafts
     * @private
     */
    _filterPrefinalDrafts (response) {
      this.editTrackingResponse = response;
      return response.parents
        .filter((plan) => plan.draft === Enums.Plan.DraftType.PRE_FINAL)
        .map((plan) => PlanMetadata.create(plan));
    }

    /**
     * Build a map of <planType, Promote Promise>
     * @param {PlanMetadata[]} plans the prefinalDrafts
     * @returns {Object} a map of <planType, Promote Promise>
     * @private
     */
    _promote (plans) {
      this.prefinalDrafts = plans;
      return this.$q.all(_.transform(plans, (result, plan) => {
        result[plan.type] = this.orchestrator.promote(plan);
      }, {}));
    }

    /**
     * Poll the promotion statuses
     * @param {Object} promoteResponses a map of <planType, PromoteResponse>
     * @returns {Object} a map of <planType, PollPromise>
     * @private
     */
    _poll (promoteResponses) {
      return this.$q.all(_.transform(this.prefinalDrafts, (accumulator, plan) => {
        const uuid = _.head(promoteResponses[plan.type]).uuid;
        accumulator[plan.type] = this.orchestrator.pollModification(plan, uuid);
      }, {}));
    }

    /**
     * Update the Edit Tracking with <planId, [prefinalDrafts, finalDrafts]>
     * @param {Object} pollResponses a map of <planType, PollResponse>
     * @returns {Promise} Edit Tracking Promise
     * @private
     */
    _updateEditTracking (pollResponses) {
      const planRelations = [];
      this.finalDrafts = _.transform(this.prefinalDrafts, (result, plan) => {
        const source = _.cloneDeep(plan.source);
        source.draft = Enums.Plan.DraftType.FINAL;
        source.version = pollResponses[plan.type].destVersion;
        const finalPlan = PlanMetadata.create(source);
        result.push(finalPlan);
        // Build planRelations
        planRelations.push({ childPlan: finalPlan, parentPlans: [plan] });
      }, []);
      const parents = _.concat(this.prefinalDrafts, this.finalDrafts);
      const input = {
        parents: parents,
        planDate: this.editTrackingResponse.planDate,
        planningScenario: this.editTrackingResponse.planningScenario,
        planRelations: planRelations,
        user: this.$authentication.profile().alias
      };
      return this.orchestrator.updateEditTracking(input);
    }

    /**
     * Load execution data using Edit Tracking APIs
     * @private
     */
    _loadData () {
      this.loading = true;
      this.isPromoted = {};
      this.expanded = {};
      this.orchestrator.getEditTrackingExecutions({ planDate: this.planningWeek })
        .then((edits) => this.executions = edits.executions)
        .then(() => this.$q.all(_.transform(this.executions, (edits, edit) => edits[edit.planId] = this.orchestrator.getEditTracking({ planId: edit.planId }), {})))
        .then((edits) => this.edits = edits)
        .finally(() => this.loading = false);
    }

    /**
     * Gets display formatted draft.
     * @param draft
     * @returns formatted draft.
     */
    getDraft (draft) {
      return _(draft).capitalize().replace(Enums.GrainIdentity.draft, '');
    }

    /**
     * Gets modification status and sets isPromoted flag for planId.
     * @param planId
     * @returns PROMOTE if any parent plan is
     */
    getModification (planId) {
      this.isPromoted[planId] = _.some(this.edits[planId].parents, (plan) => plan.draft === Enums.Plan.DraftType.FINAL);
      return this.isPromoted[planId] ? 'PROMOTE': 'SUBMIT';
    }

    /**
     * Gets expanded flag.
     * @param execution
     * @returns boolean expanded flag.
     */
    getExpanded (execution) {
      return this.expanded[execution.planId];
    }

    /**
     * Toggles expanded value for execution.
     * @param execution
     */
    toggleExpanded (execution) {
      this.expanded[execution.planId] = !this.expanded[execution.planId];
    }

    /**
     * Promote prefinalDrafts associated with the planId to finalDrafts
     * @param {String} planId the selected planId
     */
    onPromote (planId) {
      this.modalInstance.close();
      this.overlay.show('Promoting Selected Plans');
      this.orchestrator.getEditTracking({ planId })
        .then((response) => this._filterPrefinalDrafts(response))
        .then((plans) => this._promote(plans))
        .then((promoteResponses) => this._poll(promoteResponses))
        .then((pollResponses) => this._updateEditTracking(pollResponses))
        .then((response) => this.alerts.success(`<b>Plans Promoted Successfully</b><br>
                                                    Plan ID: ${response.planId}<br>
                                                    Scenario: ${this.editTrackingResponse.planningScenario}<br>
                                                    Plan Date: ${this.editTrackingResponse.planDate}`))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to promote selected plans.' }))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      this.planDefinitions = this.resolve.planDefinitions;
      this.planningWeek = this.resolve.planningWeek;
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('networkPlannerExecutionsModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with one property:
         *    planningWeek - String - the planning week to fetch executions for.
         */
        resolve: '<'
      },
      controller: NetworkPlannerExecutionsModalController,
      templateUrl: 'templates/components/network-planner-executions-modal.component.html'
    });
})();
