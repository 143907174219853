/* globals Identity */
(function () {
  'use strict';
  class AbstractPackagerService {
    static get $inject () {
      return ['$q'];
    }

    constructor ($q) {
      this.$q = $q;
    }

    _extractSelectionIds (selections) {
      return _.map(selections, (selection) => selection.map(Identity.of));
    }

    /*
     * Generates all possible combinations of the passed selections.
     * @selections a list of filter objects.
     * @valueLength an int representing the length of the values list
     *
     * @returns a list of filter objects.
     * e.g. [ ... { node: [...], productLine: [...] ... }  ... ]
     */
    generateSelectionCrossProduct (selections, valueLength) {
      const crossProduct = _.sop.cartesianCrossProduct(...Object.values(this._extractSelectionIds(selections)));
      return _.map(crossProduct, (value) => ({
        granularity: _.transform(Object.keys(selections), (result, key, index) => result[key] = value[index], {}),
        values: Array(valueLength).fill(null)
      }));
    }

    /* PURE VIRTUAL - Should be implemented by sub-class.
     * Performs a call for data on behalf of the data-viewer controller, and converts the
     * response into data packages to be displayed in a grid or exported to a spreadsheet.
     *
     * @return a list of DataPackages.
     */
    collect () {
      throw new Error('AbstractPackager: collect() must be overwritten in a sub-class');
    }
  }

  window.AbstractPackagerService = Object.freeze(AbstractPackagerService);
})();
