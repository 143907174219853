/* globals Html */
(function () {
  'use strict';
  const BUFFER_TARGET_SIZE_FACTOR = 4,
        DEBOUNCE_MAXIMUM_DELAY_IN_MILLISECONDS = 200,
        DEBOUNCE_MINIMUM_DELAY_IN_MILLISECONDS = 50,
        // In order to be able to compute the size of the spacers and number of rows to add during initial setup of the grid
        // we need to define a standard value for the height of all TR's in the grid. Ideally we could get this information from
        // a TR element in either the THEAD or TBODY of the grid but at the time initialization happens there is no rendered
        // content in the grid, though there are TR elements in the THEAD but they do not have a computed height property yet.
        DEFAULT_FIXED_ROW_HEIGHT_IN_PIXELS = 29,
        ROW_ADDITION_SCALING_FACTOR = 2,
        TRIGGER_WINDOW_FACTOR = 1.5;

  /**
   * Improves large table performance by limiting the table rows attached to the DOM through virtualized scrolling.
   *
   * Directive must be attached to a grid component as it requires the grid controller to enable a data windowing strategy.
   *
   * @name application.directives.gridVirtualScroll
   * @example
   * <grid grid-virtual-scroll></grid>
   */
  angular.module('application.directives')
    .directive('gridVirtualScroll', ['$window', function ($window) {
      const WINDOW_ELEMENT = angular.element($window);

      return {
        link: function (scope, element, attrs, grid) {
          const GRID_ELEMENT = element[0];
          const ELASTIC_TOP_SPACER_ELEMENT = GRID_ELEMENT.getElementsByClassName('elastic-top-spacer')[0];
          const ELASTIC_BOTTOM_SPACER_ELEMENT = GRID_ELEMENT.getElementsByClassName('elastic-bottom-spacer')[0];
          const TBODY_ELEMENT = GRID_ELEMENT.getElementsByTagName('tbody')[0];


          const fixedRowHeight = DEFAULT_FIXED_ROW_HEIGHT_IN_PIXELS;
          let endDisplayRowIndex = 0,
              elasticBottomSpacerHeight = 0,
              elasticTopSpacerHeight = 0,
              maxRowsAvailable = 0,
              startDisplayRowIndex = 0;

          // Compute elastic spacer sizes and number of rows to include in tbody based on current scroll position.
          const computeExtents = function () {
            const tbodyRect = TBODY_ELEMENT.getBoundingClientRect(),
                  windowRect = Html.getViewportRect(),
                  maxRowsInViewport = Math.floor(windowRect.height / fixedRowHeight),
                  triggerDistance = windowRect.height * TRIGGER_WINDOW_FACTOR,
                  topGap = tbodyRect.top + triggerDistance,
                  bottomGap = windowRect.height + triggerDistance - tbodyRect.bottom;

            let rowsToAdd = maxRowsInViewport * ROW_ADDITION_SCALING_FACTOR,
                newStartIndex = startDisplayRowIndex,
                newEndIndex = endDisplayRowIndex;

            const targetBufferSize = Math.ceil(BUFFER_TARGET_SIZE_FACTOR * rowsToAdd);

            if (topGap > 0) {
              rowsToAdd += Math.floor(topGap / fixedRowHeight);
              newStartIndex = Math.max(0, Math.ceil(startDisplayRowIndex - rowsToAdd));
              newEndIndex = Math.min(maxRowsAvailable, newStartIndex + targetBufferSize);
            } else if (bottomGap > 0) {
              rowsToAdd += Math.floor(bottomGap / fixedRowHeight);
              newEndIndex = Math.min(maxRowsAvailable, Math.ceil(endDisplayRowIndex + rowsToAdd));
              newStartIndex = Math.max(0, newEndIndex - targetBufferSize);
            }

            if (newStartIndex !== startDisplayRowIndex || newEndIndex !== endDisplayRowIndex) {
              startDisplayRowIndex = newStartIndex;
              endDisplayRowIndex = newEndIndex;
              elasticTopSpacerHeight = Math.max(0, startDisplayRowIndex * fixedRowHeight);
              elasticBottomSpacerHeight = Math.max(0, (maxRowsAvailable - endDisplayRowIndex) * fixedRowHeight);
              const scrollY = $window.scrollY;
              ELASTIC_TOP_SPACER_ELEMENT.style.height = `${elasticTopSpacerHeight}px`;
              ELASTIC_BOTTOM_SPACER_ELEMENT.style.height = `${elasticBottomSpacerHeight}px`;
              if (scrollY !== $window.scrollY) {
                $window.scrollBy(0, scrollY - $window.scrollY);
              }
              grid.bodyRows = grid.body.rowSupplier(startDisplayRowIndex, endDisplayRowIndex);
            }
          };

          // Scroll and resize handler function
          const adjustVisibleRows = _.debounce(
            function () {
              computeExtents();
              scope.$apply();
            },
            DEBOUNCE_MINIMUM_DELAY_IN_MILLISECONDS,
            {
              maxWait: DEBOUNCE_MAXIMUM_DELAY_IN_MILLISECONDS
            }
          );

          // Overwrite the _resetBody method of the grid
          grid.overwriteResetBody(function () {
            WINDOW_ELEMENT.off('resize scroll', adjustVisibleRows);

            grid.bodyRows.length = 0;
            if (!grid.state.isReady() || grid.state.isDataEmpty()) {
              return;
            }

            // On an update to the dataset we need to reset state and reload the display data from source
            elasticBottomSpacerHeight = 0;
            elasticTopSpacerHeight = 0;
            startDisplayRowIndex = 0;
            endDisplayRowIndex = 0;
            maxRowsAvailable = grid.body.length;
            ELASTIC_TOP_SPACER_ELEMENT.style.height = '0';
            ELASTIC_BOTTOM_SPACER_ELEMENT.style.height = '0';

            computeExtents();

            WINDOW_ELEMENT.on('resize scroll', adjustVisibleRows);
          });

          scope.$on('$destroy', () => WINDOW_ELEMENT.off('resize scroll', adjustVisibleRows));

          // Call the _resetBody method of the grid after setup so it can execute in the scenario where the data promises returned and are ready
          // prior to this directive being setup, since the base _resetBody will not be called in that scenario.
          grid.callResetBody();
        },
        require: 'grid',
        restrict: 'A'
      };
    }]);
})();
