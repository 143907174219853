/* globals Configuration */
(function () {
  'use strict';
  const SERVICE_KEY = Configuration.services.bastion.key;
  // The following values are based off the WRITE/READ-ROLE prefix from AWS-Resources.cfg in SandopPortalAuthService.
  const AUTH_TOKEN = 'USER-AUTH-TOKEN';
  const MANAGED_SERVICES = Object.freeze(_.filter(Configuration.services, (service) => !_.isNil(service.token)));
  const QUEUE_TYPES = Object.freeze([AUTH_TOKEN].concat(_.map(MANAGED_SERVICES, 'token')));
  const RENEW_TOKEN_DELTA_MILLISECONDS = 60000; // 1 minute

  class BastionService {
    static get $inject () {
      return ['$authentication', '$http', '$interval', '$q', '$window'];
    }

    constructor ($authentication, $http, $interval, $q, $window) {
      this.$authentication = $authentication;
      this.$http = $http;
      this.$interval = $interval;
      this.$q = $q;
      this.$window = $window;

      this._queues = {};
      this._requests = {};
      this._tokens = {};

      QUEUE_TYPES.forEach((type) => this._queues[type] = []);
      this.$authentication.$onLogoutConfirmed(() => {
        // Clear all tokens and queues when the user is logged out, which happens when the profile expires or the scope is changed.
        this._stopAutoRenewTimer();
        this._tokens.length = 0;
        this._clearQueues();
      });

      // On initialization we have to determine if the user is authenticated and load their stored auth token if so
      if (this.$authentication.isAuthenticated()) {
        this._tokens[AUTH_TOKEN] = this.$authentication.profile()[AUTH_TOKEN];
      }

      this.credentials = {
        empty: () => this.$q.resolve({}),
        set: {}
      };
      _.forOwn(MANAGED_SERVICES, (service) => {
        this.credentials[service.key] = (region, scope) => this._defer(service.token, region, scope);
        this.credentials.set[service.key] = (credentials) => this._tokens[service.token] = credentials;
      });
    }

    _getServiceToken (service, region, scope) {
      return this.$http
        .get(
          [Configuration.endpoints.endpoint(SERVICE_KEY).url, 'aws', 'credentials', service, region, scope, this._tokens[AUTH_TOKEN]].join('/'),
          { withCredentials: true }
        )
        .then((response) => _.defaults(
          {
            region: region,
            userAuthToken: this._tokens[AUTH_TOKEN]
          },
          response.data
        ));
    }

    _defer (service, region, scope) {
      const deferred = this.$q.defer();
      const authQueuedFn = () => {
        if (_.has(this._tokens, service)) {
          // Check token is not expired
          if (Date.parse(this._tokens[service].expiration) >= Date.now()) {
            deferred.resolve(this._tokens[service]);
            return;
          }

          // Remove expired service token
          delete this._tokens[service];
        }

        // No service token -> queue promise request
        this._queues[service].push(() => deferred.resolve(this._tokens[service]));
        if (_.has(this._requests, service)) {
          return;
        }

        // No service request -> make request
        this._requests[service] = this._getServiceToken(service, region, scope).then((serviceToken) => {
          this._tokens[service] = serviceToken;

          // Process and clear everything on the service queue.
          this._queues[service].forEach((fn) => fn());
          this._queues[service].length = 0;

          // Remove the service request
          delete this._requests[service];
        });
      };

      if (_.has(this._tokens, AUTH_TOKEN)) {
        authQueuedFn();
      } else {
        // No Auth Token -> queue promise request
        this._queues[AUTH_TOKEN].push(authQueuedFn);
      }
      return deferred.promise;
    }

    _clearQueues () {
      QUEUE_TYPES.forEach((type) => this._queues[type].length = 0);
    }

    _startAutoRenewTimer (userTokenExpiration) {
      if (_.isNil(this._timer)) {
        // This is the milliseconds to renewal
        const interval = Date.parse(userTokenExpiration)
          - RENEW_TOKEN_DELTA_MILLISECONDS
          - Date.now();
        this._timer = this.$interval(() => this.renew(), interval);
      }
    }

    _stopAutoRenewTimer () {
      if (!_.isNil(this._timer)) {
        this.$interval.cancel(this._timer);
        delete this._timer;
      }
    }

    authenticate (redirect) {
      this.$window.location.href = `${Configuration.endpoints.endpoint(SERVICE_KEY).url}/authenticate/?redirect=${this.$window.encodeURIComponent(redirect)}`;
    }

    authenticated (authToken, userProfile) {
      this._tokens[AUTH_TOKEN] = authToken;
      userProfile[AUTH_TOKEN] = authToken;
      this.$authentication.loginConfirmed(userProfile);

      // A user has been authenticated.
      // Process and clear everything on the AUTH_TOKEN queue.
      this._queues[AUTH_TOKEN].forEach((fn) => fn());
      this._queues[AUTH_TOKEN].length = 0;

      // Stop and start auto-renewal timer on the token
      this._stopAutoRenewTimer();
      this._startAutoRenewTimer(userProfile.expiration);
    }

    authorizationHeaders () {
      return {
        'X-SandOP-Authorization-Token': this._tokens[AUTH_TOKEN]
      };
    }

    renew () {
      let authToken;
      this.$http
        .get(
          [Configuration.endpoints.endpoint(SERVICE_KEY).url, 'renew'].join('/'),
          { withCredentials: true }
        )
        .then((response) => {
          authToken = response.data.token;
          return this.user(authToken);
        })
        .then((profile) => this.authenticated(authToken, profile));
    }

    user (authToken) {
      return this.$http
        .get(
          [Configuration.endpoints.endpoint(SERVICE_KEY).url, 'user', authToken].join('/'),
          { withCredentials: true }
        )
        .then((response) => response.data);
    }

    userLookup (alias) {
      const scope = Configuration.scopes.current().portalCode;
      const authToken = this._tokens[AUTH_TOKEN];
      return this.$http
        .get(
          [Configuration.endpoints.endpoint(SERVICE_KEY).url, 'user', alias, scope, authToken].join('/'),
          { withCredentials: true }
        )
        .then((response) => response.data);
    }
  }

  angular.module('application.services').service('bastion', BastionService);
})();
