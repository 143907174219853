/* globals AbstractTransformerService, AllocationTreeEdit, AllocationTreeEditRecord, Configuration, Enumeration, Enums, XLSX */
(function () {
  'use strict';

  const WORKBOOK_METADATA_DEFINITION = Object.freeze([
    AbstractTransformerService.metadataProperty('editType'),
    AbstractTransformerService.metadataProperty('flow'),
    AbstractTransformerService.metadataProperty('granularity'),
    AbstractTransformerService.metadataProperty('scope')
  ]);

  class AllocationTreeTransformerService extends AbstractTransformerService {
    static get $inject () {
      return ['alerts', '$authentication', '$q'];
    }

    constructor (alerts, $authentication, $q) {
      super(alerts, $q, WORKBOOK_METADATA_DEFINITION);
      this.$authentication = $authentication;
    }

    _accumulatePackageRows (sheet, workbookMetadata) {
      const sheetEdits = _.filter(sheet, (row) => row.Action);
      const currentScope = Configuration.scopes.current().code;
      if (!this._isValidUpload(sheetEdits, currentScope, workbookMetadata.scope)) {
        return [];
      }

      return AllocationTreeEdit.create({
        edits: _.map(sheetEdits, (editRow) => {
          const editRecord = {};
          workbookMetadata.granularity.granularities.forEach((grain) => editRecord[grain.id] = editRow[grain.name]);
          editRecord.action = editRow.Action;
          return AllocationTreeEditRecord.create(editRecord);
        }),
        flow: workbookMetadata.flow,
        scope: currentScope,
        type: workbookMetadata.editType,
        updatedBy: this.$authentication.profile().alias
      });
    }

    _generateHeaderRow (pkg) {
      return _.flatten([
        'Flow',
        pkg.granularity.names(),
        'Exists',
        'Action'
      ]);
    }

    _generateBodyRows (pkg) {
      const content = [];
      pkg.records.forEach((allocationTreeEditRow) => {
        content.push(_.flatten([
          pkg.title,
          pkg.granularity.values().map((grain) => allocationTreeEditRow[grain.id]),
          allocationTreeEditRow.exists
        ]));
      });
      return content;
    }

    _isValidUpload (sheetEdits, currentScope, workbookScope) {
      if (_.isEmpty(sheetEdits)) {
        this.alerts.danger('The uploaded XLSX file contained no edits');
        return false;
      }

      if (currentScope !== workbookScope) {
        this.alerts.danger(`The file being uploaded is from scope "${workbookScope}", whereas the current scope is "${currentScope}". Please try again after changing the scope to "${workbookScope}" or upload the correct file.`);
        return false;
      }

      if (!_.every(sheetEdits, (editRow) => _.includes(Enumeration.keys(AllocationTreeEditRecord.action), editRow.Action))) {
        this.alerts.danger(`Uploaded file has invalid actions. Please make sure the Action column is either empty or has one of the following values: [${Enumeration.keys(AllocationTreeEditRecord.action)}]`);
        return false;
      }
      return true;
    }

    /* @Override
     * Converts dataPackages into a workbook.
     *
     * @return a workbook object to be exported.
     */
    convertToWorkbook (packages) {
      const workbook = XLSX.utils.book_new();
      const editPackages = _.cloneDeep(packages);
      XLSX.utils.book_append_sheet(workbook, this.convertToWorksheet(editPackages), Enums.XlsxSheet.EDITS);
      this.addWorkbookMetadata(workbook, _.first(editPackages));
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a worksheet.
     *
     * @return a workbook object to be exported.
     */
    convertToWorksheet (packages) {
      const content = [];
      packages.forEach((pkg, index) => {
        if (index === 0) {
          content.push(this._generateHeaderRow(pkg));
        }
        content.push(...this._generateBodyRows(pkg));
      });
      return XLSX.utils.aoa_to_sheet(content);
    }

    /* @Override
     * Derives an array of packages from a workbook
     *
     * @param workbook the workbook to be converted into packages
     * @return a package that can be displayed as grid/table
     */
    toPackages (workbook) {
      const workbookMetadata = this.extractWorkbookMetadata(workbook);
      if (_.isEmpty(workbookMetadata)) {
        return [];
      }
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.EDITS], { raw: true });
      return this._accumulatePackageRows(sheet, workbookMetadata);
    }
  }

  angular.module('application.services').service('allocationTreeTransformer', AllocationTreeTransformerService);
})();
