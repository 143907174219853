'use strict';
/**
 * Directive to clear smart table's filter when clear event is raised
 *
 * @name application.directives.stResetOnClear
 * @example
 * <input type="text" st-search="yourColumn" st-reset-on-clear/>
 */
angular.module('application.directives')
  .directive('stResetOnClear', function () {
    return {
      link: function (scope, element, attrs, stTable) {
        // If clear event is raised (when some global level parameters are changed)
        // we need to clear filter criteria on the table. Table will be showing
        // new data, the old filter is no more meaningful on new data
        scope.$on('clear', () => stTable.tableState().search.predicateObject = {});
      },
      require: '^stTable',
      restrict: 'EA'
    };
  });
