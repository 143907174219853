/* globals AbstractElementComponent */
(function () {
  'use strict';
  // Each data section is made of 2 columns that consist of term and definition.
  const COLUMNS_PER_DATA_SECTION = 2;

  class TermTableController extends AbstractElementComponent {
    _loadData () {
      const maxDataSections = _.maxBy(this.data, 'length').length;
      this.columns = maxDataSections * COLUMNS_PER_DATA_SECTION;
      this.data.forEach((row) => {
        const columnsInRow = row.length * COLUMNS_PER_DATA_SECTION;
        _.last(row).colspan = columnsInRow === this.columns ? 1 : this.columns - columnsInRow + 1;
      });
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('termTable', {
      bindings: {
        data: '<',
        header: '@'
      },
      controller: TermTableController,
      templateUrl: 'templates/components/term-table.component.html'
    });
})();
