/* globals AbstractServiceEndpoint, Configuration, TreeNode */
(function () {
  'use strict';

  const NO_ALLOCATION_TREE_DATA_MESSAGE = 'AllocationTreeService: Data from Allocation Tree Service is nil or empty';

  const buildTreeHelper = function (parent, flatData, hierarchicalOrder, level) {
    if (level === hierarchicalOrder.length) {
      return;
    }
    const grainToGroup = hierarchicalOrder[level].id,
          grouped = _.groupBy(flatData, grainToGroup),
          orderedKeys = _.sortBy(_.keys(grouped));
    _.forEach(orderedKeys, (granularityValue) => {
      const nextData = grouped[granularityValue],
            nextNode = TreeNode.create({
              grainId: grainToGroup,
              name: granularityValue,
              value: _.defaults({ [grainToGroup]: granularityValue }, parent.source.value)
            }, parent);
      nextNode.id = _.reduce(_.values(nextNode.source.value), (accumulator, value) => `${accumulator}${value}`);
      buildTreeHelper(nextNode, nextData, hierarchicalOrder, level + 1);
    });
    return parent;
  };

  class AllocationTreeService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['alerts', '$q', 'request'];
    }

    constructor (alerts, $q, request) {
      super(request, Configuration.services.allocationTree);
      this.alerts = alerts;
      this.$q = $q;
    }

    _getTreeData (body, options) {
      return this.aws()
        .for('readAllocationTree')
        .withParams(options)
        .withBody(body)
        .post();
    }

    _getTreeMetadata (body, options) {
      return this._getTreeData(_.defaultsDeep({ queryOptions: { describe: true } }, body), options).then((data) => data.treeMetaData);
    }

    _getPaginatedTreeData (body, options) {
      return this._getTreeMetadata(body, options).then(
        (metadata) => this.$q.all(
          metadata.paginationKeys.map(
            (key) => this._getTreeData(_.defaults({ paginationKey: key }, body), options)
          )
        )
      );
    }

    /**
     * Get an allocation tree.
     * @param scope the scope of the allocation tree
     * @param flow the flow of the allocation tree
     * @param filters the filters to apply to the tree
     * @param groupBy the aggregation of the allocation tree. The order of groupBy will be used as the hierarchical order
     * @param options optional arguments that are applied to the query
     * @param isRawData boolean representing if raw tree data should be returned
     * @returns an allocation tree
     */
    tree (scope, flow, filters, groupBy, options, isRawData = false) {
      const body = {
        flow: flow,
        queryOptions: {
          filters: filters,
          groupBy: groupBy.values()
        },
        scope: scope
      };
      return this._getPaginatedTreeData(body, options).then((data) => {
        if (isRawData) {
          return data;
        }
        if (_.isNil(data) || _.isEmpty(data)) {
          this.alerts.danger(NO_ALLOCATION_TREE_DATA_MESSAGE);
          throw new Error(NO_ALLOCATION_TREE_DATA_MESSAGE);
        }
        return buildTreeHelper(
          TreeNode.create({ value: {} }),
          _.flatten(_.map(data, (dataset) => _.get(dataset, 'data', []))),
          groupBy.values(),
          0
        );
      });
    }

    /**
     * Update the allocation tree.
     * @param treeData the tree data that needs to be updated
     * @returns an update response object with 'failures' array (non-empty if failure) and 'treeMetaData' object (non-null if success)
     */
    update (treeData) {
      return this.aws()
        .for('updateAllocationTree')
        .withBody(treeData, { doNotFilter: true })
        .post();
    }
  }

  angular.module('application.services').service('allocationTree', AllocationTreeService);
})();
