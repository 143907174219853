/* globals AbstractElementComponent, Binding, Configuration, Display */
(function () {
  'use strict';
  const VALID_STATUS = 'Valid';
  const VALIDATED_BY_STATUS = 'Validated by';
  const INVALID_STATUS = 'Invalid';
  const ROOT_CAUSES = Object.freeze([
    'Business Error',
    'Oversensitive Validations'
  ]);

  class DailyFlashEmailMetricsListController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$authentication', 'dataPipeline', '$element', 'overlay'];
    }

    constructor (alerts, $authentication, dataPipeline, $element, overlay) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.metrics),
        isLoadingFn: () => this.loading
      });
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.dataPipeline = dataPipeline;
      this.overlay = overlay;
      this.rootCauses = ROOT_CAUSES;
      this.invalidMetricsCount = 0;
    }

    _loadData () {
      if (_.isEmpty(this.datasetDate)) {
        return;
      }
      this.emailSent = true;
      this.emailSending = false;
      this.invalidMetricsCount = 0;
      this.loading = true;
      this.allMetrics = [];
      this.filteredMetrics = [];
      this.metrics = [];
      this.dataPipeline.getDailyFlashEmailMetrics(this.datasetDate, _.toUpper(this.emailVariant))
        .then((dailyFlashEmailMetrics) => {
          this.emailSent = dailyFlashEmailMetrics.emailSent;
          this.allMetrics = dailyFlashEmailMetrics.metrics;
          this.filteredMetrics = _.filter(dailyFlashEmailMetrics.metrics, (metric) => !metric.isValidated || !_.isEmpty(metric.approver));
          this.metrics = this.displayAllMetrics ? this.allMetrics : this.filteredMetrics;
          this.invalidMetricsCount = this.filteredMetrics.filter((metric) => !metric.isValidated).length;
          // The flash email is in the process of being sent if it has not been sent yet but has been validated.
          // Additionally, ensure there are metrics present to validate.
          this.emailSending = !this.emailSent && this.invalidMetricsCount === 0 && !_.isEmpty(this.allMetrics);
          if (this.emailSending) {
            this.alerts.info(`All <strong>${this.datasetDate} ${this.emailVariant}</strong> flash metrics have been validated, flash email is now being sent out.`);
          }
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch daily flash email metrics.' }))
        .finally(() => this.loading = false);
    }

    getNoDataMessage () {
      if (_.isEmpty(this.allMetrics)) {
        return `${this.emailVariant} metric validations have not run yet for ${this.datasetDate}`;
      }
      return `No invalid ${this.emailVariant} metrics for ${this.datasetDate}. The email has${this.emailSent ? ' ' : ' not '}been sent.`;
    }

    getOverrideConfirmationMessage (metric) {
      const value = this.getValue(metric.value, metric.valueDisplayType);
      return `Are you sure you want to validate the value <strong>${value}</strong> for metric <strong>${metric.name}</strong>?`;
    }

    getRelatedTTsSearchLink () {
      const workflow = `${Configuration.scopes.current().code}:NetworkViewer:DailyEmail:${_.capitalize(this.emailVariant)}`;
      const searchText = `[${this.datasetDate}] Workflow ${workflow} task DailyEmailValidationCheck has failed`;
      return Configuration.endpoints.staticOf.ttSearch(searchText);
    }

    getSendEmailConfirmationMessage () {
      return `Are you sure you want to send the <strong>${this.emailVariant}</strong> email for <strong>${this.datasetDate}</strong>?`;
    }

    getStatus (metric) {
      if (!metric.isValidated) {
        return INVALID_STATUS;
      }
      return _.isEmpty(metric.approver) ? VALID_STATUS : VALIDATED_BY_STATUS;
    }

    getThreshold (threshold, thresholdDisplayType) {
      return _.isEmpty(threshold) ? '-' : this.getValue(threshold, thresholdDisplayType);
    }

    getTrRoleType (metric) {
      const validRoleType = '';
      const invalidRoleType = 'button';
      return metric.isValidated ? validRoleType : invalidRoleType;
    }

    getValue (value, valueDisplayType) {
      return Display.data(_.toNumber(value), _.toLower(valueDisplayType));
    }

    getValueDisplayClass (metric) {
      const validClass = '';
      const validatedClass = 'validated-value font-bold';
      const invalidClass = 'invalid-value font-bold';
      if (!metric.isValidated) {
        return invalidClass;
      }
      return _.isEmpty(metric.approver) ? validClass : validatedClass;
    }

    hasApprover (metric) {
      return !_.isEmpty(metric.approver);
    }

    isValidateDisabled (metric) {
      return _.isEmpty(metric.rootCause);
    }

    validateMetric (metric) {
      metric.validate(this.$authentication.profile().alias, metric.rootCause);
      const body = {
        datasetDate: this.datasetDate,
        emailVariant: _.toUpper(this.emailVariant),
        metricList: [metric.source],
        scope: Configuration.scopes.current().code
      };
      this.overlay.show(`Validating metric: ${metric.name}`);
      this.dataPipeline.overrideDailyFlashEmailMetrics(body)
        .then(() => {
          this.overlay.hide();
          return this._loadData();
        })
        .catch((error) => {
          metric.invalidate();
          this.alerts.danger({ details: error, message: `Unable to validate metric ${metric.name}.` });
        })
        .finally(() => this.overlay.hide());
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }
      if (Binding.changes.only(changes, 'displayAllMetrics')) {
        // Do not load data if the displayAllMetrics flag is the only change.
        this.metrics = this.displayAllMetrics ? this.allMetrics : this.filteredMetrics;
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('dailyFlashEmailMetricsList', {
      bindings: {
        /*
        * @datasetDate is the date that anomaly results should be retrieved for.
        */
        datasetDate: '<',
        /*
        * @displayAllMetrics is a flag indicating if all metrics should be displayed.
        */
        displayAllMetrics: '<',
        /*
        * @emailVariant is the type (INBOUND/OUTBOUND) of the daily flash email metrics.
        */
        emailVariant: '<'
      },
      controller: DailyFlashEmailMetricsListController,
      templateUrl: 'templates/components/daily-flash-email-metrics-list.component.html'
    });
})();
