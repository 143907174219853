/* globals AbstractServiceEndpoint, Configuration, DateUtils, Enums, Name, Grain */
(function () {
  'use strict';

  function getIgnoredGrains (plan, metricFamily) {
    if (
      plan.isFcWeeklyPlan() &&
      !_.sop.includesSome(
        metricFamily,
        Configuration.flowNeedles.efnVendorReturns,
        Configuration.flowNeedles.mod,
        Configuration.flowNeedles.sourceCostOptimization,
        Configuration.flowNeedles.transfer,
        Configuration.flowNeedles.transshipment
      )
    ) {
      return [Grain.known.secondaryNode, Grain.known.secondaryNodeGroup, Grain.known.secondaryNodeRegion];
    }
    return [];
  }

  class MetricsService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['request'];
    }

    constructor (request) {
      super(request, Configuration.services.metricsService);
    }

    get ACTUALS_NAMESPACE () {
      return 'Actuals';
    }

    get NETWORK_VIEWER_NAMESPACE () {
      return 'NetworkViewer';
    }

    get NETWORK_VIEWER_SORT_CENTER_NAMESPACE () {
      return 'NetworkViewerSortCenter';
    }

    get MANUAL_BACKLOG_NAMESPACE () {
      return 'ManualBacklog';
    }

    config (namespace, asOfDate = DateUtils.format(Enums.DateFormat.IsoDate)) {
      return this.aws()
        .withScope()
        .for('metric', 'config', this.resolveNamespace(namespace))
        .withParams({ asOfDate })
        .get()
        // Reshape the data format coming from Metrics Service to the previous format exposed by PCS that is tragically entwined in the client.
        // TODO: Further refactoring should be done to better decouple usage in the client from the wire format.
        .then((data) => _.map(data.groups, (group) => ({
          displayName: group.display.name,
          id: group.id,
          metricFamilies: group.families.map((family) => ({
            displayName: family.display.name,
            displayOrder: family.display.order,
            id: family.id,
            metrics: family.metrics.map((metric) => ({
              category: {
                displayName: _.get(metric, 'display.category.name'),
                id: _.get(metric, 'display.category.id')
              },
              dataType: _.camelCase(metric.display.dataType),
              displayName: metric.display.name,
              displayOrder: metric.display.order,
              id: metric.id,
              isBacklogMetric: _.includes(metric.display.tags, 'BACKLOG'),
              isEditable: _.includes(metric.display.tags, 'EDITABLE'),
              source: metric.source,
              // This value is being used by defaulting logic in the network-grid.component.js where it expects this to be nil (not an empty string).
              subType: _.isString(metric.display.subType) ? _.camelCase(metric.display.subType) : undefined,
              threshold: metric.display.threshold,
              type: metric.type
            }))
          }))
        })));
    }

    filterFiltersByPlan (plan, metricFamily, filters) {
      return _.omit(filters, _.map(getIgnoredGrains(plan, metricFamily), 'id'));
    }

    filterGrainsByPlan (plan, metricFamily, grains) {
      // TODO: The following is a hack, as we dont have a way to map metricFamily to the grains it support.
      // The following block should be replaced by the example mentioned below:
      // Ex: return grains.filter(grain => metricFamily.supportedGrains.includes(grain));
      _.forEach(getIgnoredGrains(plan, metricFamily), (ignore) => grains = grains.filter((grain) => !grain.equals(ignore)));
      return grains;
    }

    groupByMetricFamily (categories) {
      // Group categories by their metric family
      categories = _.groupBy(categories, 'metricFamilyId');
      // Combine metrics across their metric families
      categories = _.values(
        _.mapValues(categories, (category) => _.reduce(
          category,
          (combined, next) => {
            combined.metrics.push(...next.metrics);
            return combined;
          },
          {
            displayOrder: _.head(category).metricFamilyDisplayOrder,
            id: _.head(category).metricFamilyId,
            metrics: []
          }
        ))
      );
      // Once the complete metric family has been assembled, order the metrics based off their displayOrder
      return categories.map((family) => _.set(family, 'metrics', _.sortBy(family.metrics, ['displayOrder'])));
    }


    /**
     * Gets actuals, derived actuals, and derived forecasts for a set of metrics in a metric family
     * See: https://w.amazon.com/bin/view/SandOP_Systems/Data_Actuals/Service_API/
     *
     * @param metricFamily the metric family to get data for
     *   @id the metric family identifier
     *   @metrics the list of metrics within the metric family to retrieve data for
     *     @id the metric identifier
     *     @type the type of metric (Actual / Forecast)
     * @param namespace the namespace of the metric family to look-up
     * @param recordGranularity the record granularity identifier
     * @param timeGranularity the time granularity identifier
     * @param body request body to specify filtering, grouping, and range
     * @param options optional parameters provided as query arguments to the service
     * @returns an object with a records property that is the actuals information for each metric
     * @example metricFamily(
     *   'NewTransferIn',
     *   'networkPlan',
     *   'PRODUCTLINE',
     *   'weekly',
     *   PlanMetadata,
     *   { metrics: [{*}] }
     * )
     */
    metricFamily (metricFamily, namespace, recordGranularity, timeGranularity, body = {}, options = {}) {
      const metrics = _.map(metricFamily.metrics, (metric) => _.pick(metric, ['id', 'type']));
      body.metrics = metrics;

      return this.aws().withScope()
        .for('metric', 'family', namespace, metricFamily.id, recordGranularity, timeGranularity)
        .withParams(options)
        .withBody(body, { ignore: ['mappings', 'metrics', 'planMetadata'] })
        .post();
    }

    metricCategoriesByFamily (namespace, asOfDate, metricFamilyGroupId) {
      return this.config(namespace, asOfDate).then((metricFamilyGroups) => {
        const group = _.find(metricFamilyGroups, (metricFamilyGroup) => metricFamilyGroup.id === metricFamilyGroupId);
        if (_.isNil(group)) {
          return [];
        }

        return _.map(group.metricFamilies, (metricFamily) => {
          const categories = _.groupBy(metricFamily.metrics, (metric) => metric.category.id);
          return {
            displayOrder: metricFamily.displayOrder,
            id: metricFamily.id,
            items: _.map(categories, (categoryMetrics, categoryId) => ({
              id: categoryId,
              metricFamilyDisplayOrder: metricFamily.displayOrder,
              metricFamilyId: metricFamily.id,
              metrics: categoryMetrics,
              name: _.head(categoryMetrics).category.displayName
            })),
            name: Name.ofMetricFamily(metricFamily)
          };
        });
      });
    }

    // TODO: This is used by both the metric-item-selector.component.js and the create-packager.service.js.
    // It returns a data structure for the Item Selector and that really shouldn't be known by the create-packager.
    metricsByGroups (namespace, asOfDate, filterFn) {
      return this.config(namespace, asOfDate).then((metricFamilyGroups) => {
        const groups = metricFamilyGroups.map((group) => ({
          id: group.id,
          items: group.metricFamilies.reduce((accumulator, currentValue) => {
            const metrics = _.filter(currentValue.metrics, filterFn).map((metric) => _.set(metric, 'name', Name.ofMetric(metric)));
            accumulator.push(...metrics);
            return accumulator;
          }, []),
          name: Name.ofMetricFamilyGroup(group)
        }));
        return _.filter(groups, (group) => !_.isEmpty(group.items));
      });
    }

    metricFamiliesByGroups (namespace, asOfDate) {
      return this.config(namespace, asOfDate).then((metricFamilyGroups) =>
        metricFamilyGroups.map((group) => ({
          id: group.id,
          items: _.flatten(_.transform(group.metricFamilies, (metricFamilies, family) => {
            const actualsMetrics = _.remove(family.metrics, (metric) => metric.type === 'Actual');
            const forecastMetrics = family.metrics;
            if (forecastMetrics.length > 1) {
              // At this time, Daily Plans have metric definitions consisting of families with multiple flows and no actuals.
              // SIM: https://sim.amazon.com/issues/SOP-9509
              // If a family is found with multiple flows and actuals, an exception should be thrown.
              if (!_.isEmpty(actualsMetrics)) {
                throw new Error('Metric Service: Metric families with more than one flow cannot have actuals metrics');
              }
              forecastMetrics.forEach((forecastMetric) => {
                const groupTemplate = {
                  displayOrder: family.displayOrder,
                  id: forecastMetric.id,
                  metricFamilyId: family.id,
                  metrics: [forecastMetric],
                  name: Name.ofMetric(forecastMetric)
                };
                metricFamilies.push([groupTemplate]);
              });
              return;
            }
            const groupTemplate = {
              displayOrder: family.displayOrder,
              id: family.id,
              metricFamilyId: family.id,
              metrics: [...forecastMetrics],
              name: Name.ofMetricFamily(family)
            };
            // No Actuals metrics in the family, simply return the forecast
            if (_.isEmpty(actualsMetrics)) {
              metricFamilies.push([groupTemplate]);
            }
            // If Actuals metrics exist in the metric family, clone the metric family and add the Actuals metric to the cloned family.
            metricFamilies.push(_.flatten(actualsMetrics.map((metric) => {
              const group = _.cloneDeep(groupTemplate);
              group.id += metric.id;
              // If more than one Actuals exists in the metric family and there is a forecast metric
              // then the display name is a combination of the metric family name and Actuals metric name.
              group.name = actualsMetrics.length > 1 && !_.isEmpty(forecastMetrics) ? Name.ofMetric(metric, family) : Name.ofMetric(metric);
              group.metrics.push(metric);
              return group;
            })));
          })),
          name: Name.ofMetricFamilyGroup(group)
        }))
      );
    }

    networkViewerMetricFamilyGroupIds (asOfDate) {
      return this.config(this.NETWORK_VIEWER_NAMESPACE, asOfDate).then((groups) => _.map(groups, 'id').sort());
    }

    resolveNamespace (namespace) {
      // Unknown namespaces are assumed to be Plan Types and those must be prefixed with 'Plan-' in order to be retrieved.
      return !_.includes([this.ACTUALS_NAMESPACE, this.MANUAL_BACKLOG_NAMESPACE, this.NETWORK_VIEWER_NAMESPACE, this.NETWORK_VIEWER_SORT_CENTER_NAMESPACE], namespace) ? `Plan-${namespace}` : namespace;
    }
  }

  angular.module('application.services').service('metricsService', MetricsService);
})();
