/* globals AbstractDataViewController, Configuration, DateUtils, Enums, Enumeration, Filename, Ready, Validate */
(function () {
  'use strict';

  const ENVIRONMENT_BUCKET_MAP = Enumeration.create(
    'local',
    'beta',
    'gamma',
    'prod'
  ).asExactValue(
    'rom-portal-local',
    'rom-portal-beta',
    'rom-portal-gamma',
    'rom-portal-prod'
  );

  const MODE = Enumeration
    .create('UPLOAD_INPUTS', 'SELECT_RUN_CONFIGURATION')
    .asStringValue((key) => _.startCase(_.lowerCase(key)));

  // Case insensitive regex that only allows alphanumeric characters, applied to ROM Scenario and SubScenario.
  const VALID_SCENARIO_SUBSCENARIO_REGEX = RegExp('^[a-z0-9]+$', 'i');

  // Since IN and BR are their own parent in scope configuration, mapping them to EU and NA respectively to keep things consistent with the regionalized buckets for ROM.
  // Long term fix SIM: https://issues.amazon.com/issues/SOP-12141
  const REGION_OVERRIDE_MAP = {
    AE: 'EU',
    AU: 'FE',
    BR: 'NA',
    EG: 'EU',
    IN: 'EU',
    JP: 'FE',
    SA: 'EU',
    SG: 'FE'
  };

  class ROMScenarioManagerController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', 'romScenarioConfiguration', '$scope', 'trader', '$uibModal'];
    }

    constructor (alerts, $authentication, overlay, romScenarioConfiguration, $scope, trader, $uibModal) {
      super($scope);
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.rom = romScenarioConfiguration;
      this.trader = trader;
      this.$uibModal = $uibModal;
      this.$onInit();
    }

    // Region name is used to form the bucket name to upload constraint file to.
    _getRegionName () {
      const regionCode = Configuration.scopes.current().region.code;
      return _.get(REGION_OVERRIDE_MAP, regionCode, regionCode).toLowerCase();
    }

    _mapConstraintTypeToRomInputDropdown () {
      _.forEach(this.$scope.model.constraintTypes, (value, key) => this.$scope.model.selectedInputForROM[key] = Ready.create({
        isNoSelectionFn: () => _.isEmpty(this.$scope.model.romInput[key])
      }));
    }

    _updateFileMapWithConstraintType (data, constraintType) {
      this.$scope.model.fileMap[constraintType] = {
        contents: _.map(data, (value) => ({
          name: value.name,
          timestamp: DateUtils.format(value.timestamp)
        }))
      };
    }

    _getAllUploadedFiles (constraintType, startDate) {
      this.overlay.show(`Getting files for ${constraintType}`);
      this.rom.getUploadedFilesByType(constraintType, Configuration.scopes.current().code, startDate)
        .then((data) => this._updateFileMapWithConstraintType(data.constraintFileInfos, constraintType))
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    _getConstraints () {
      this.overlay.show('Populating Constraint types');
      this.rom.getConstraintTypes({ scope: Configuration.scopes.current().code })
        .then((data) => {
          this.$scope.model.constraintTypes = data.constraintTypesMap;
          this._mapConstraintTypeToRomInputDropdown();
          this._generateFileMap(this.$scope.model.primaryWeekDate);
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    _getFileKey (fileName) {
      return `${Configuration.scopes.current().code}/${fileName}`;
    }

    _generateFileMap (startDate) {
      _.forEach(this.$scope.model.constraintTypes, (value, key) => this._getAllUploadedFiles(key, startDate));
    }

    _resetConstraints () {
      this.$scope.model.constraintTypes = {};
    }

    $onInit () {
      super.$onInit(
        ['INPUT_UPLOAD', 'RUN'],
        {
          'model.constraintTypes': () => ({}),
          'model.fileMap': () => [],
          'model.primaryWeekDate': () => DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
          'model.romInput': () => ({}),
          'model.selectedInputForROM': () => ({})
        }
      );

      this.$scope.settings.mode = {
        active: MODE.UPLOAD_INPUTS,
        isSelectRunConfiguration: () => this.$scope.settings.mode.active === MODE.SELECT_RUN_CONFIGURATION,
        isUploadInputs: () => this.$scope.settings.mode.active === MODE.UPLOAD_INPUTS,
        list: Enumeration.values(MODE)
      };

      this.$scope.uploadInput = Ready.create({
        isNoSelectionFn: () => _.isEmpty(this.$scope.model.selectedInputToUpload) || _.isNil(this.$scope.model.selectedInputToUpload)
      });

      this.$scope.methods.download = (startDate, constraintType, fileName) => {
        if (!Validate.isBlank(fileName)) {
          this.rom.getPresignedURL(constraintType, fileName, Configuration.scopes.current().code, this.$scope.model.primaryWeekDate)
            .then((data) => this.rom.downloadFromS3(data.presignedURL)
              .then((fileData) => this.trader.download(fileData, Filename.from(fileName), this.trader.toExtensionType(Enums.DocumentFormat.CSV)))
              .catch((error) => this.alerts.error(error)))
            .catch((error) => this.alerts.error(error));
        }
      };

      this.$scope.methods.submit = (planDate, constraintType, file) => {
        this.overlay.show('Uploading file');
        const bucketName = `${ENVIRONMENT_BUCKET_MAP[Configuration.environment.current().designator]}-${this._getRegionName()}`;
        this.rom.uploadToS3(bucketName, file, this._getFileKey(file.name))
          .then(() => this.rom.uploadConstraint(this.$authentication.profile().alias, bucketName, constraintType, file.name, this._getFileKey(file.name), Configuration.scopes.current().code, planDate)
            .then(() => this.alerts.success('File uploaded. Check Executions for the latest status.'))
            .catch((error) => this.alerts.error(error)))
          .catch((error) => this.alerts.error(error))
          .finally(() => this.overlay.hide());
      };

      this.$scope.methods.setInputUploadStep = () => this.$scope.methods.steps.set(this.$scope.STEPS.INPUT_UPLOAD);
      this.$scope.methods.validateScenarioSubscenario = () => !_.isEmpty(this.$scope.model.scenario) &&
        !_.isEmpty(this.$scope.model.subscenario) &&
        VALID_SCENARIO_SUBSCENARIO_REGEX.test(this.$scope.model.scenario) &&
        VALID_SCENARIO_SUBSCENARIO_REGEX.test(this.$scope.model.subscenario);
      this.$scope.methods.setRunConfigurationStep = () => this.$scope.methods.steps.set(this.$scope.STEPS.RUN);

      this.$scope.methods.openExecutions = () => {
        this.$uibModal.open({
          component: 'romUploadedConstraintDisplayModal',
          resolve: {
            modalData: {
              startDate: this.$scope.model.primaryWeekDate
            }
          },
          size: Enums.ModalSize.EXTRA_LARGE
        }).result.catch(_.noop);
      };
      this.$scope.methods.onWeeklyRunDateChange = (startDate) => {
        this._resetConstraints();
        this.$scope.model.primaryWeekDate = startDate;
        this._getConstraints();
      };
      this.$scope.methods.isRomRunForCurrentWeek = () => {
        const todayDate = DateUtils.of();
        const selectedDate = DateUtils.of(this.$scope.model.primaryWeekDate);
        return DateUtils.equals(todayDate, selectedDate, Enums.TimeUnit.WEEK);
      };

      this.$scope.methods.triggerROM = () => {
        const body = {
          promoted: !!this.$scope.model.promote,
          scenario: this.$scope.model.scenario,
          scope: Configuration.scopes.current().code,
          selectedConstraints: this.$scope.model.romInput,
          startDate: this.$scope.model.primaryWeekDate,
          subscenario: this.$scope.model.subscenario,
          userId: this.$authentication.profile().alias
        };
        this.overlay.show('Triggering ROM');
        this.rom.triggerROM(body)
          .then(() => this.alerts.success('Triggered ROM successfully'))
          .catch((error) => this.alerts.error(error))
          .finally(() => this.overlay.hide());
      };
      this._getConstraints();
    }
  }

  angular.module('application.controllers').controller('ROMScenarioManagerController', ROMScenarioManagerController);
})();
