/* globals AbstractSourceBasedModel, Enums */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'granularity',
    'groupRatioValue',
    'groupRatioType',
    'scopeCode',
    'updatedAt',
    'updatedBy'
  ]);

  const TEMPLATE = Object.freeze({
    granularity: {
      destinationGrain: {},
      sourceGrain: {}
    },
    groupRatioType: '',
    groupRatioValue: '',
    scopeCode: '',
    updatedAt: '',
    updatedBy: ''
  });

  class GrainMappingRatio extends AbstractSourceBasedModel {
    constructor (source) {
      super(_.defaults(source, TEMPLATE), PROPERTIES, Enums.ModelMutability.MUTABLE);
    }

    isValid () {
      return !_.some(this.granularity.sourceGrain, (grainValue) => _.isEmpty(grainValue)) &&
          !_.some(this.granularity.destinationGrain, (grainValue) => _.isEmpty(grainValue)) &&
          !_.isEmpty(this.groupRatioType) &&
          !_.isNil(this.groupRatioValue);
    }
  }
  window.GrainMappingRatio = Object.freeze(GrainMappingRatio);
})();
