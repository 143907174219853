/* globals DataPackage */
(function () {
  'use strict';
  class DataPackageList {
    /* Determines if the list of DataPackages is of type CREATE
     *
     * @param dataPackageList the array of DataPackage objects to check
     * @return Boolean indicating if the list of DataPackages is of type CREATE
     */
    static areCreate (dataPackageList) {
      if (_.isEmpty(dataPackageList)) {
        return false;
      }
      return _.some(dataPackageList, (dataPackage) => DataPackage.isCreate(dataPackage));
    }

    /* Checks to make sure every DataPackage in the list contains finite edits
     *
     * @param dataPackageList the array of DataPackage objects to check
     * @return Boolean indicating if all edits are finite
     */
    static containsValidEdits (dataPackageList) {
      if (_.isEmpty(dataPackageList)) {
        return false;
      }
      const packagesWithEdits = _.filter(dataPackageList, (dataPackage) => DataPackage.containsEdits(dataPackage));
      return !_.isEmpty(packagesWithEdits) && _.every(packagesWithEdits, (dataPackage) => DataPackage.containsValidEdits(dataPackage));
    }

    /* Checks to see if any DataPackage in the list contains an edit.
     *
     * @param dataPackageList the array of DataPackage objects to check
     * @return Boolean indicating if an edit exists in some DataPackage
     */
    static containsEdits (dataPackageList) {
      if (_.isEmpty(dataPackageList)) {
        return false;
      }
      return _.some(dataPackageList, (dataPackage) => DataPackage.containsEdits(dataPackage));
    }
  }

  window.DataPackageList = Object.freeze(DataPackageList);
})();
