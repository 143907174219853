/* globals AbstractDataViewController, Comparison, DateUtils, Enums, Filename, Granularities */
(function () {
  'use strict';

  class PlanViewerController extends AbstractDataViewController {
    static get $inject () {
      return ['overlay', 'packagerFactory', 'planViewerProvider', '$q', '$scope', 'share', 'trader', 'transformerFactory', 'usageMetrics', 'view'];
    }

    constructor (overlay, packagerFactory, planViewerProvider, $q, $scope, share, trader, transformerFactory, usageMetrics, view) {
      super($scope, share, view);
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.planViewerProvider = planViewerProvider;
      this.$q = $q;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.usageMetrics = usageMetrics;
      this.$onInit();
    }

    _alignFilters () {
      this.$scope.model.granularity.allGrains.align(this.$scope.model.filters);
    }

    _calculateDateRange (action) {
      if (!this.$scope.settings.forecasts) {
        return DateUtils.expansion(this.$scope.model.actuals.startDate.date, this.$scope.model.actuals.endDate.date, this.$scope.model.granularity.time);
      }
      const startDate = this.$scope.methods.getForecastDisplayStartDate();
      // The display limit exists due to browser perforance constraints and
      // therefore should not be applied to the download usecase.
      const endDate = action === Enums.UserAction.VIEW ? this.$scope.methods.getForecastDisplayEndDate() : this.$scope.model.forecast.plan.endDate;
      return DateUtils.expansion(startDate, endDate, this.$scope.model.granularity.time);
    }

    _constructSummaryInterface (dateRange) {
      this.$scope.summaryInterface = _.cloneDeep({
        actuals: this.$scope.model.actuals,
        actualsComparisons: this.$scope.model.actualsComparisons,
        dateRange: dateRange,
        forecast: this.$scope.model.forecast,
        forecastComparisons: this.$scope.model.forecastComparisons,
        settings: this.$scope.settings,
        viewName: this.$scope.viewName
      });
    }

    _hasFilters () {
      return !_.isEmpty(this.$scope.model.filters) && _.every(this.$scope.model.filters, (filter) => !_.isEmpty(filter));
    }

    download (format) {
      // If the settings are not in a valid configuration for submitting then no action should be performed
      if (!this.isSettingsValid()) {
        return;
      }
      this._alignFilters();
      this.overlay.show('Exporting');
      const dateRange = this._calculateDateRange(Enums.UserAction.DOWNLOAD);

      this.usageMetrics.timeAndExecute(this.planViewerProvider.id, Enums.UsageMetrics.download, () => this.$q.all(
        this.$scope.model.metricFamilies.map(
          (family) => this.packagerFactory.collect(this.packagerFactory.packagerType.planViewer, dateRange, family, this.$scope.model, this.$scope.settings).ready()
        ))
        .then((grids) => this.transformerFactory.toDocument(
          this.transformerFactory.transformerType.plan,
          format,
          grids,
          [
            {
              key: 'granularity',
              serialize: (granularity) => granularity.grains.names()
            },
            'Data Type',
            { key: 'dates' }
          ],
          [
            {
              key: 'granularity',
              serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
              source: 'pkg'
            },
            {
              key: 'dataType',
              source: 'row'
            },
            {
              key: 'values',
              source: 'row'
            }
          ]
        )).then((workbook) => this.trader.download(
          workbook,
          Filename.create().forPlan(this.$scope.model.forecast.plan),
          this.trader.toExtensionType(format)
        ))
        .finally(() => this.overlay.hide())
      );
    }

    isSettingsValid () {
      return this.$scope.methods.isValidMetricFamilySelection() &&
        !_.isNil(this.$scope.model.granularity.grains) &&
        !this.$scope.model.granularity.grains.isEmpty() &&
        _.isString(this.$scope.model.granularity.time) &&
        _.isString(this.$scope.model.granularity.plan) &&
        this._hasFilters();
    }

    setStep (step) {
      if (
        (step === this.$scope.STEPS.METRIC_FAMILY_SELECTION && !this.$scope.methods.isValidPlanSelection()) ||
        (_.includes([this.$scope.STEPS.GROUP_SELECTION, this.$scope.STEPS.FILTER_SELECTION], step) && !this.$scope.methods.isValidMetricFamilySelection())
      ) {
        return;
      }
      super.setStep(step);
    }

    submit () {
      // If the settings are not in a valid configuration for submitting then no action should be performed.
      if (!this.isSettingsValid()) {
        return;
      }
      // Clear existing data.
      this.clearData();
      this._alignFilters();
      const dateRange = this._calculateDateRange(Enums.UserAction.VIEW);
      this._constructSummaryInterface(dateRange);
      const addons = {
        historicalDividerIndex: this.$scope.settings.forecasts && this.$scope.settings.actuals
          ? this.$scope.model.actuals.dateRangeLength
          : 0
      };

      this.usageMetrics.timeAndExecute(this.planViewerProvider.id, Enums.UsageMetrics.submit, () => this.$q.all(
        this.$scope.model.metricFamilies.forEach((family) => {
          this.addData(
            Object.assign(
              this.packagerFactory.collect(this.packagerFactory.packagerType.planViewer, dateRange, family, this.$scope.model, this.$scope.settings),
              addons
            )
          );
        })
      ));
      this.collapseSettings(true);

      // This race() ensures that the digest cycle registers a change to the condition to display
      // the plan-summary component. Using either isDataEmpty() or setting displaySummary to false
      // and then true sequentially while in submit() results in no change being registered by the digest cycle.
      // Note: DataPackage.ready() is not used here as it performs mutations to the records for comparison modes
      //       Doing so here would result in the mutations being performed twice. (https://tt.amazon.com/0510517919)
      this.$q.race(this.$scope.data.map((dataPackage) => dataPackage.records)).finally(() => this.$scope.methods.displaySummary(true));
    }

    $onInit () {
      super.$onInit(
        ['PLAN_SELECTION', 'METRIC_FAMILY_SELECTION', 'GROUP_SELECTION', 'FILTER_SELECTION'],
        _.defaults(
          {
            'model.aggregateGroupByGrains': () => Granularities.create().addMetricGrain(),
            'model.granularity.allGrains': () => Granularities.create(),
            'settings.freezeHeaderColumns': () => true,
            // PPY stands for Previous Previous Year. Used to enable/disable fetching Actuals for same week 2 years ago
            'settings.includeYoYActuals.PPY': () => false,
            // PY stands for Previous Year. Used to enable/disable fetching Actuals for same week last year
            'settings.includeYoYActuals.PY': () => false,
            'settings.subtotals': () => false
          },
          this.planViewerProvider.stateDefinition
        )
      );
      this.$scope.methods.isFeatureEnabled = () => true;

      this.planViewerProvider.configureMethods(this);

      this.$scope.methods.isGrainFilterVisible = (filter) => !_.isNil(this.$scope.model.granularity.allGrains) &&
        _.some(this.$scope.model.granularity.allGrains.values(), (grain) => Comparison.areIdentityEqual(grain, filter));

      this.$scope.methods.isValidMetricFamilySelection = () => this.$scope.methods.isValidPlanSelection() && !_.isEmpty(this.$scope.model.metricFamilies);

      this.$scope.methods.isDailyTimeGrain = () => this.$scope.model.timeUnit === Enums.TimeGranularity.DAY;

      this.$scope.methods.isWeeklyTimeGrain = () => this.$scope.model.timeUnit === Enums.TimeGranularity.WEEK;

      this.$scope.methods.setGranularities = (grains, allGrains, planGrain, timeGrain) => {
        this.$scope.model.granularity.allGrains = allGrains;
        this.$scope.model.granularity.grains = grains;
        this.$scope.model.granularity.plan = planGrain;
        this.$scope.model.granularity.time = timeGrain;
      };

      this.registerShare(
        [
          'model.filters',
          'model.metricFamilies',
          'settings.includeYoYActuals',
          'settings.subtotals',
          {
            deserialize: (value = true) => {
              this.$scope.settings.actuals = value;
              if (!this.$scope.settings.actuals) {
                this.$scope.methods.selectViewOption(this.planViewerProvider.viewOption.FORECASTS);
              }
            },
            key: 'settings.actuals'
          },
          {
            deserialize: (value) => {
              ['grains', 'allGrains'].forEach((property) => value[property] = Granularities.create(value[property].granularities));
              return value;
            },
            key: 'model.granularity'
          }
        ].concat(this.planViewerProvider.getRegisterShareDefinition(this.$scope))
      );

      this.registerView(
        [
          {
            // Reset state to ensure it is in an expected configuration before applying the user view
            action: () => {
              this.clearData();
              this.resetState();
            },
            persist: false
          },
          'model.filters',
          'model.metricFamilies',
          'settings.includeYoYActuals',
          'settings.subtotals',
          {
            deserialize: (value = true) => {
              this.$scope.settings.actuals = value;
              if (!this.$scope.settings.actuals) {
                this.$scope.methods.selectViewOption(this.planViewerProvider.viewOption.FORECASTS);
              }
            },
            key: 'settings.actuals'
          },
          {
            deserialize: (value) => {
              ['grains', 'allGrains'].forEach((property) => value[property] = Granularities.create(value[property].granularities));
              return value;
            },
            key: 'model.granularity'
          }
        ].concat(this.planViewerProvider.getRegisterViewDefinition(this.$scope))
      );
    }
  }
  angular.module('application.controllers').controller('PlanViewerController', PlanViewerController);
})();
