/* globals Configuration, Display */
(function () {
  'use strict';

  const EMPTY_STRING = '';
  const SITE_NAME = 'IPT';
  const TITLE_PART_SEPARATOR = ': ';

  class PageTitleService {
    static get $inject () {
      return ['$authentication', '$rootScope'];
    }

    constructor ($authentication, $rootScope) {
      this.$authentication = $authentication;
      this.$rootScope = $rootScope;
      this.$rootScope.page = { title: SITE_NAME };
      this.$rootScope.view = { title: EMPTY_STRING };
    }

    _getViewTitle (route, userName, title) {
      if (route.isHome()) {
        return EMPTY_STRING;
      }
      if (route.isProfile()) {
        return `${Display.possessivize(_.defaultTo(userName, this.$authentication.profile().name))} ${title}`;
      }
      return title;
    }

    update (route, userName) {
      const title = _.get(route, 'name', EMPTY_STRING);
      const parts = [];
      const origin = Configuration.environment.origin();
      if (!origin.isProd()) {
        parts.push(origin.designation);
      }
      parts.push(Configuration.scopes.current().icon);
      parts.push(SITE_NAME);
      parts.push(title);
      this.$rootScope.page.title = parts.filter((part) => !_.isEmpty(part)).join(TITLE_PART_SEPARATOR);
      this.$rootScope.view.title = this._getViewTitle(route, userName, title);
    }
  }

  angular.module('application.services').service('pageTitle', PageTitleService);
})();
