/* globals ClassDecorator */
(function () {
  'use strict';

  const BINDINGS_PROPERTY = 'bindings';

  class Cell {
    constructor (value, title, ...classes) {
      this.class = ClassDecorator.assemble(...classes);
      this.value = value;
      this.title = title;
    }

    static create (value, title, ...classes) {
      return new Cell(value, title, ...classes);
    }

    setBindings (bindings) {
      return this.setProperties({ [BINDINGS_PROPERTY]: bindings });
    }

    setProperties (properties) {
      Object.assign(this, properties);
      return this;
    }

    addClass (className) {
      this.class.push(className);
      return this;
    }

    removeClass (className) {
      _.remove(this.class, (name) => className === name);
      return this;
    }
  }

  window.Cell = Object.freeze(Cell);
})();
