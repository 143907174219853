/* globals AbstractServiceEndpoint, Comparison, Configuration, DateUtils, Enums, PlanMetadata, RatioMetadata */
(function () {
  'use strict';

  const NO_FLOW_IDENTIFIER = 'NONE';

  const generatePlanNotFoundMessage = (planType, planDate, scope) =>
    `Plan Store did not return a valid ${_.startCase(planType)} for ${scope} on ${DateUtils.format(planDate, Enums.DateFormat.IsoDate)}. This could affect the data shown in the report. If you believe there should be a valid ${planType}, please cut a ticket to Plan Store.`;

  class PlanStoreService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['alerts', '$q', 'request'];
    }

    constructor (alerts, $q, request) {
      super(request, Configuration.services.planStore);
      this.alerts = alerts;
      this.$q = $q;
    }

    _getPlanData (plan, body, options) {
      // If the plan has a draft property then those should be included in the query to uniquely identify the plan, if not already defined by the caller.
      options = _.defaults(
        {},
        options,
        {
          draft: plan.draft,
          scope: Configuration.scopes.current().code
        }
      );
      return this.aws()
        .for(options.scope, 'plan', plan.date, plan.scenario, plan.subScenario, plan.type)
        .withParams(_.omit(options, 'scope'))
        .withBody(body)
        .post();
    }

    _getPlanMetadata (plan, body = {}, options = {}) {
      return this._getPlanData(plan, _.defaults({ describe: true }, body), _.assign({ flow: NO_FLOW_IDENTIFIER }, options))
        .then((data) => PlanMetadata.create(data.metadata))
        .catch((err) => {
          if (options.alertUser) {
            // This is specific to Network Viewer and is not used by other views
            this.alerts.warning(generatePlanNotFoundMessage(plan.type, options.lastUpdateByTimeInISO, options.scope));
          }
          if (err.status === 404) {
            return PlanMetadata.template();
          }
          throw err;
        });
    }

    _getPaginatedPlanData (plan, body, options) {
      return this._getPlanMetadata(plan, body, options).then(
        (metadata) => this.$q.all(
          metadata.paginationKeys.map(
            (key) => {
              const newOptions = _.defaults({ paginationKey: key, version: metadata.version }, options);
              // Ensure that plan store is not called with both lastUpdateByTimeInISO and version
              delete newOptions.lastUpdateByTimeInISO;
              return this._getPlanData(plan, body, newOptions);
            }
          )
        )
      );
    }

    _getRatioData (body, options) {
      return this.aws()
        .for('ratio', 'readByID')
        .withParams(options)
        .withBody(body)
        .post();
    }

    _getRatioMetadata (body) {
      return this._getRatioData(_.defaultsDeep({ ratioQueryOptions: { describe: true } }, body))
        .then((data) => RatioMetadata.create(data.ratioDataset.ratioMetadata));
    }

    _getPaginatedRatioData (body) {
      return this._getRatioMetadata(body).then((metadata) => {
        const promises = [];
        metadata.paginationKeys.forEach((key) => {
          promises.push(this._getRatioData(
            _.defaults({ paginationKey: key }, body),
            { version: metadata.version }
          ));
        });
        return this.$q.all(promises);
      });
    }

    editable () {
      // TODO: Change the below implementation to call plan store's latest plan API once it is implemented.
      // SIM: https://issues.amazon.com/issues/SOP-9707
      // This logic will break if no plan has been published in the last two weeks. This is a temporary measure
      // so UAT can start. This solution is NOT viable in production... except it is in production... *sigh*
      // SIM: https://issues.amazon.com/SOP-8463 - We now do 3 weeks (1 in the future!)
      const dates = [
        DateUtils.fromOffset(DateUtils.toSunday(), -1, 'w'),
        DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
        DateUtils.fromOffset(DateUtils.toSunday(), 1, 'w')
      ];

      return this.$q.all(_.map(dates, (date) => this.plans(date))).then((data) => {
        const lastWeeksPlans = data[0];
        const thisWeeksPlans = data[1];
        const nextWeeksPlans = data[2];

        // Display Rules:
        //   1. Take all plans from next weeks plans and add to the result set
        let results = [].concat(nextWeeksPlans);
        //   2. If a plan from this weeks plans is not in the result set, then add it to the result set
        results = results.concat(thisWeeksPlans.filter(
          (plan) => !results.find((resultPlan) => Comparison.arePlansEqual(plan, resultPlan))
        ));
        //   3. If a plan from last weeks plans is not in the result set, then add it to the result set
        results = results.concat(lastWeeksPlans.filter(
          (plan) => !results.find((resultPlan) => Comparison.arePlansEqual(plan, resultPlan))
        ));
        //   4. Filter to only pre-final draft plans
        return results.filter((plan) => plan.isPrefinalDraft());
      });
    }

    plan (plan, body, options) {
      return this._getPaginatedPlanData(plan, body, options).then((data) => {
        if (_.isNil(data) || _.isEmpty(data)) {
          // https://issues.amazon.com/issues/SOP-8156: This allows users to make inline edits for filter combinations that
          // produce no data.
          return {
            dates: [],
            records: []
          };
        }
        // https://issues.amazon.com/issues/SOP-2517: paginated plan store results do not throw a 404 when there is no data for the call,
        // but instead return a response with no records property defined. The below handles there being no records property defined.
        return {
          dates: _.uniq(_.flatten(_.map(data, (dataset) => _.get(dataset.records, 'forecastDates', [])))),
          metadata: data[0].metadata,
          metric: {
            id: _.reduce(
              data,
              (accumulator, dataset) => _.get(dataset.records, 'flow') || accumulator,
              'UNKNOWN'
            ),
            type: 'Forecast'
          },
          records: _.flatten(_.map(data, (dataset) => _.get(dataset.records, 'data', [])))
        };
      });
    }

    planMetadata (plan, options) {
      return this._getPlanMetadata(plan, {}, options);
    }

    plans (date, options) {
      return this.aws()
        .withScope()
        .for('plan', date)
        .withParams(options)
        .get()
        .then((plans) => _.map(plans, (plan) => PlanMetadata.create(plan)))
        .catch(() => []);
    }

    ratioByID (body) {
      return this._getPaginatedRatioData(body).then((data) => {
        if (_.isNil(data) || _.isEmpty(data)) {
          throw new Error('Data from Ratio Store is nil or empty');
        }
        // Ensure that the first index has a ratioDatumSet array
        _.defaultsDeep(_.head(data), { ratioDataset: { ratioDatumSet: [] } });
        return _.reduce(data, (accumulator, value) => {
          if (_.hasIn(value, 'ratioDataset.ratioDatumSet')) {
            accumulator.ratioDataset.ratioDatumSet = accumulator.ratioDataset.ratioDatumSet.concat(value.ratioDataset.ratioDatumSet);
          }
          return accumulator;
        });
      });
    }

    ratioMetadata (plan, option) {
      return this.planMetadata(plan, option).then((metadata) => {
        if (!_.has(metadata, 'ratioID')) {
          // If the plan does not have a ratioID then there are no ratios related to it
          return;
        }
        return this._getRatioMetadata({
          flow: NO_FLOW_IDENTIFIER,
          ratioID: metadata.ratioID
        });
      });
    }
  }

  angular.module('application.services').service('planStore', PlanStoreService);
})();
