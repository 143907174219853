/* globals AbstractSourceBasedModel, Enumeration, Enums */
(function () {
  'use strict';

  const ACTION = Enumeration.create('ADD', 'REMOVE').asStringValue();
  const EXISTS = Enumeration.create('YES', 'NO').asStringValue(_.capitalize);

  const PROPERTIES = Object.freeze([
    'action',
    'binType',
    'businessEntity',
    'networkInventoryGroup',
    'node',
    'productLine',
    'scope',
    'secondaryNode',
    'secondaryScope',
    'sortType'
  ]);

  /*
   * getter/setter pattern for 'add' and 'remove':
   * 'add' and 'remove' are properties since they are linked as ngModel="record.add" and ngModel="record.remove" to Add / Remove action checkboxes.
   * They can be read as record.add and record.remove (getters) by the respective checkboxes to decide if the checkboxes should be checked or unchecked.
   * When those checkbox selections are toggled (manually selected or unselected), the same properties will be set values (setters).
   *
   * 'setExists' vs. 'set exists':
   * This was a choice between using 'setExists' with 'this.exists' OR 'set exists' and not being able to use property 'exists' on 'this'
   */
  class AllocationTreeEditRecord extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
    }

    static get action () {
      return ACTION;
    }

    get add () {
      return this.source.action === ACTION.ADD;
    }

    get remove () {
      return this.source.action === ACTION.REMOVE;
    }

    set add (value) {
      this.source.action = !this.existsInTree() ? (value ? ACTION.ADD : undefined) : this.source.action;
    }

    set remove (value) {
      this.source.action = this.existsInTree() ? (value ? ACTION.REMOVE : undefined) : this.source.action;
    }

    existsInTree () {
      return this.exists === EXISTS.YES;
    }

    setExists (exists) {
      this.exists = exists ? EXISTS.YES : EXISTS.NO;
    }
  }

  window.AllocationTreeEditRecord = Object.freeze(AllocationTreeEditRecord);
})();
