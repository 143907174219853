/* globals AbstractElementComponent */
(function () {
  'use strict';

  class SingleItemSelector extends AbstractElementComponent {
    static get $inject () {
      return ['$attrs', '$element', '$q'];
    }

    constructor ($attrs, $element, $q) {
      super($element, {
        isDataEmptyFn: () => Array.isArray(this.options) && _.isEmpty(this.options),
        isLoadingFn: () => this.loading,
        isNoSelectionFn: () => _.isNil(this.selected)
      });
      this.$attrs = $attrs;
      this.$q = $q;
    }

    _loadData () {
      this.loading = true;
      this.$q.resolve(this.options)
        .then((options) => this.options = options || [])
        .finally(() => this.loading = false);
    }

    displayValue (option) {
      // AngularJS wraps '&' bindings in a function so even if displayFn is not provided, it will always be defined.
      // Instead of checking the binding, we need to check if the attribute is provided with $attrs.
      if (!_.isNil(this.$attrs.displayFn)) {
        return this.displayFn({ value: option });
      }
      return _.get(this.displayHash, option) || option.displayName || option;
    }

    isDisabled () {
      return super.isDisabled() || this.state.isLoading() || this.state.isDataEmpty();
    }

    onSelectionChange (option) {
      this.onSelection({ selection: option });
      this.selected = option;
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('singleItemSelector', {
      bindings: {
        displayFn: '&',
        displayHash: '<',
        helpTemplate: '@',
        onSelection: '&',
        options: '<',
        selected: '<'
      },
      controller: SingleItemSelector,
      templateUrl: 'templates/components/single-item-selector.component.html'
    });
})();
