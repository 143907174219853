(function () {
  'use strict';
  class OverlayController {
    static get $inject () {
      return ['overlay'];
    }

    constructor (overlay) {
      this.services = { overlay };
    }
  }

  angular.module('application.components')
    .component('overlay', {
      controller: OverlayController,
      templateUrl: 'templates/components/overlay.component.html'
    });
})();
