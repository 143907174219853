/* globals DateUtils, Enumeration, Enums, Filename, Ready */
(function () {
  'use strict';
  const MODEL_RUN_DISPLAY_MODES = Enumeration
    .create('JOB_DETAILS', 'METRICS')
    .asStringValue((key) => _.startCase(_.lowerCase(key)));

  class MarketplaceRunsTableController {
    static get $inject () {
      return ['alerts', 'modelMarketplace', '$scope', 'trader'];
    }

    constructor (alerts, modelMarketplace, $scope, trader) {
      this.alerts = alerts;
      this.modelMarketplace = modelMarketplace;
      this.$scope = $scope;
      this.trader = trader;
      this.modelMarketplaceRunList = [];
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.modelMarketplaceRunList),
        isLoadingFn: () => this.loading
      });
    }

    loadData () {
      // Get last two weeks' data
      const startModelRunDate = DateUtils.utc().subtract(2, Enums.TimeUnit.WEEK).format(Enums.DateFormat.IsoDate);
      const endModelRunDate = DateUtils.utc().format(Enums.DateFormat.IsoDate);

      this.loading = true;
      this.modelMarketplaceRunList.length = 0;
      // Clear any user input fields that should be cleared with new data being loaded
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.modelMarketplace.getRuns(startModelRunDate, endModelRunDate)
        .then((modelMarketplaceRuns) => this.modelMarketplaceRunList = modelMarketplaceRuns)
        .finally(() => this.loading = false);
    }

    areModelRunJobDetailsVisible () {
      return this.modelRunDisplayMode.active === MODEL_RUN_DISPLAY_MODES.JOB_DETAILS;
    }

    isBestModelVisible (runMetadata) {
      return runMetadata.isBackTest && !_.isNil(runMetadata.bestModel);
    }

    isRunOutputVisible (run) {
      return run.isSuccess() || run.isSuccessWithFailure();
    }

    showPlanMetaData (runMetadata) {
      return !runMetadata.isBackTest && runMetadata.publishToPlanStore;
    }

    downloadFromS3 (s3Object) {
      this.modelMarketplace.downloadFromS3(s3Object.bucket, s3Object.key)
        .then((response) => this.trader.download(
          response.Body,
          Filename.from(s3Object.key),
          this.trader.toExtensionType(Enums.DocumentFormat.ZIP)
        ));
    }

    showErrorMessage (errorMessage) {
      this.alerts.error(errorMessage, Enums.AlertImportance.INTERRUPT);
    }

    $onInit () {
      this.modelRunDisplayMode = {};
      this.modelRunDisplayMode.options = MODEL_RUN_DISPLAY_MODES;
      this.modelRunDisplayMode.active = MODEL_RUN_DISPLAY_MODES.JOB_DETAILS;
      this.modelMarketplace.$onModelRunsChange(this.loadData.bind(this));
      this.loadData();
    }

    formatDateTime (date) {
      return _.isNil(date) ? undefined : DateUtils.format(date, Enums.DateFormat.GmtOffsetDateTime);
    }

    formatDate (date) {
      return _.isNil(date) ? undefined : DateUtils.format(date, Enums.DateFormat.IsoDate);
    }
  }

  angular.module('application.components')
    .component('marketplaceRunsTable', {
      controller: MarketplaceRunsTableController,
      templateUrl: 'templates/components/marketplace-runs-table.component.html'
    });
})();
