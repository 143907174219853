/* globals AbstractSourceBasedModel */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'name',
    'threshold',
    'thresholdDisplayType'
  ]);

  class DailyFlashMetricValidator extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }
  }

  window.DailyFlashMetricValidator = Object.freeze(DailyFlashMetricValidator);
})();
