/* globals Configuration */
(function () {
  'use strict';

  const ROUTE_CATEGORY = Object.freeze({
    ADMINISTRATION: 'administration',
    HELP: 'help',
    HOME: 'home',
    MAIN: 'main',
    NONE: 'none',
    PROFILE: 'profile'
  });

  // Currently the capacity scopes act the same in terms of routing. If that changes, they should be listed individually.
  const CAPACITY_PORTAL_CODES = _.map(Configuration.scopes.allByTenant('CAPACITY', true), 'portalCode');

  function isInternalPath (path) {
    return !RegExp('^(//|http://|https://|mailto:)', 'i').test(path);
  }

  class Anchor {
    constructor (links, icon, text) {
      this.links = links;
      this.icon = icon;
      this.text = text;

      if (this.links.length === 1) {
        const link = _.head(this.links);
        this.icon = link.icon;
        this.text = link.text;
      }
    }

    static create (links, icon, text) {
      return Object.freeze(new Anchor(links, icon, text));
    }
  }
  Object.freeze(Anchor);

  class Route {
    constructor (code, name, path, icon, category, authorization, exclude = false, include = true) {
      this.code = code;
      this.name = name;
      this.path = path;
      this.icon = icon;
      this.category = category;
      this.authorization = Configuration.authorization.access[authorization];
      this.exclude = exclude;
      this.include = include;
    }

    static create (code, name, path, icon, category, authorization, exclude, include) {
      return Object.freeze(new Route(code, name, path, icon, category, authorization, exclude, include));
    }

    get link () {
      const paths = _.isString(this.path) ? { DEFAULT: this.path } : this.path;
      return _.transform(paths, (link, value, key) => link[key] = isInternalPath(value) ? `#!${value}` : value);
    }

    isAdministration () {
      return this.category === ROUTE_CATEGORY.ADMINISTRATION;
    }

    isHelp () {
      return this.category === ROUTE_CATEGORY.HELP;
    }

    isHome () {
      return this.category === ROUTE_CATEGORY.HOME;
    }

    isMain () {
      return this.category === ROUTE_CATEGORY.MAIN;
    }

    isProfile () {
      return this.category === ROUTE_CATEGORY.PROFILE;
    }

    generateLink (configMenuSection) {
      return Object.freeze({
        authorization: this.authorization,
        configMenuSection: configMenuSection,
        exclude: this.exclude,
        icon: this.icon,
        include: this.include,
        link: this.link,
        text: this.name
      });
    }

    generateRouteDefinition (controller, templateUrl, options) {
      return Object.freeze([
        this.path,
        Object.assign({
          authorization: this.authorization,
          controller: controller,
          route: this,
          templateUrl: templateUrl
        }, options)
      ]);
    }

    isAvailableForScope (scope) {
      if (_.isNil(this.exclude) || !_.has(this.exclude, 'scopes')) {
        return true;
      }
      return !this.exclude.scopes.includes(scope);
    }
  }
  Object.freeze(Route);

  const ROUTES = {};
  function addRoute (id, code, name, path, icon, category, authorization, exclude, include) {
    ROUTES[id] = Route.create(code, name, path, icon, category, authorization, exclude, include);
  }

  addRoute(
    'allocationTreeEditor', 'ATE', 'Allocation Tree Editor', '/administration/allocation-tree-editor', 'fa-tree', ROUTE_CATEGORY.ADMINISTRATION, 'fcWrite',
    { scopes: ['NA', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute('announcements', 'ANN', 'Announcements', '/administration/announcements', 'fa-bullhorn', ROUTE_CATEGORY.ADMINISTRATION, 'developer');
  addRoute('authenticate', undefined, undefined, '/authenticate', 'fa-lock', ROUTE_CATEGORY.NONE, 'anonymous');
  addRoute('authorization', undefined, undefined, '/authorization', 'fa-lock', ROUTE_CATEGORY.NONE, 'anonymous');
  addRoute(
    'chartLibrary', 'CHL', 'Chart Library', '/administration/chart-library', 'fa-line-chart', ROUTE_CATEGORY.ADMINISTRATION, 'developer',
    { environments: [Configuration.environment.designators.UNKNOWN, Configuration.environment.designators.GAMMA, Configuration.environment.designators.PROD] }
  );
  addRoute(
    'componentLibrary', 'COL', 'Component Library', '/administration/component-library', 'fa-book', ROUTE_CATEGORY.ADMINISTRATION, 'developer',
    { environments: [Configuration.environment.designators.UNKNOWN, Configuration.environment.designators.GAMMA, Configuration.environment.designators.PROD] }
  );
  addRoute('dailyFlashMetrics', 'DFM', 'Daily Flash Metrics', '/administration/daily-flash-metrics', 'fa-envelope', ROUTE_CATEGORY.ADMINISTRATION, 'administrator',
    { scopes: ['AE', 'AU', 'BR', 'EG', 'EU', 'NA', 'SA', 'SE', 'SG', 'TR', 'ZA', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute(
    'dailyPlanViewer', 'DPV', 'Daily Plan Viewer', '/daily-plan-viewer', 'fa-th', ROUTE_CATEGORY.MAIN, 'read',
    { scopes: ['NA', 'PHARMACY_US'] }
  );
  addRoute('developerTools', 'DET', 'Developer Tools', '/administration/developer-tools', 'fa-wrench', ROUTE_CATEGORY.ADMINISTRATION, 'developer',
    { environments: [Configuration.environment.designators.UNKNOWN, Configuration.environment.designators.GAMMA, Configuration.environment.designators.PROD] }
  );
  addRoute(
    'filterLibrary', 'FIL', 'Filter Library', '/administration/filter-library', 'fa-filter', ROUTE_CATEGORY.ADMINISTRATION, 'developer',
    { environments: [Configuration.environment.designators.UNKNOWN, Configuration.environment.designators.GAMMA, Configuration.environment.designators.PROD] }
  );
  addRoute('grainMappings', 'GRM', 'Grain Mappings', '/administration/grain-mapping-configuration', 'fa-map', ROUTE_CATEGORY.ADMINISTRATION, 'read',
    { scopes: ['PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute('home', 'HOM', 'Home', '/home', 'amazon-logo.png', ROUTE_CATEGORY.HOME, 'read');
  addRoute(
    'manualBacklog', 'MAB', 'Manual Backlog', '/administration/manual-backlog', 'fa-cubes', ROUTE_CATEGORY.ADMINISTRATION, 'fcWrite',
    { scopes: ['NA', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute(
    'modelMarketplace', 'MOM', 'Model Marketplace', '/administration/model-marketplace', 'fa-flask', ROUTE_CATEGORY.ADMINISTRATION, 'write',
    { scopes: ['NA', 'EU', ...CAPACITY_PORTAL_CODES, 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute(
    'networkPlanner', 'NPP', 'Network Planner', '/administration/network-planner', 'fa-wpforms', ROUTE_CATEGORY.ADMINISTRATION, 'write',
    { scopes: ['AE', 'AU', 'BR', 'DE', 'EG', 'ES', 'EU', 'FR', 'GB', 'IT', 'IN', 'JP', 'MX', 'NA', 'NL', 'PL', 'SA', 'SE', 'SG', 'TR', 'ZA', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute('genericNetworkViewer', 'GNV', 'GenericNetwork Viewer', '/generic-network-viewer', 'fa-sitemap', ROUTE_CATEGORY.MAIN, 'developer',
    // Defines all scopes excluding SORT_CENTER_US and SORT_CENTER_CA.
    { scopes: _.pull(_.map(Configuration.scopes.all(true), 'portalCode'), 'SORT_CENTER_US', 'SORT_CENTER_CA') });
  addRoute('networkViewer', 'NEV', 'Network Viewer', '/network-viewer', 'fa-sitemap', ROUTE_CATEGORY.MAIN, 'read', { scopes: ['NA', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] });
  addRoute('pipelineServiceWorkflows', 'DPS', 'Data Pipeline Service', '/administration/pipeline-service-workflows', 'fa-hourglass', ROUTE_CATEGORY.ADMINISTRATION, 'read',
    { scopes: ['PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute(
    'planAudit', 'PAT', 'Plan Audit Trail', '/plan-audit', 'fa-history', ROUTE_CATEGORY.ADMINISTRATION, 'read',
    { scopes: ['NA', 'EU', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute(
    'planEditor', 'PLE', 'Plan Editor', '/plan-editor', 'fa-pencil', ROUTE_CATEGORY.MAIN, 'fcWrite',
    { scopes: ['DE', 'ES', 'FR', 'IT', 'NA', 'NL', 'PL', 'SE'] }
  );
  addRoute(
    'planOrchestration', 'PLO', 'Plan Orchestration', '/plan-orchestration', 'fa-tasks', ROUTE_CATEGORY.MAIN, 'read',
    { scopes: ['NA', 'EU', ...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute('productLines', 'PRL', 'Product Lines', '/administration/product-lines', 'fa-list-ul', ROUTE_CATEGORY.ADMINISTRATION, 'read', { scopes: ['PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] });
  addRoute('profile', 'PRO', 'Profile', '/user-profile', 'fa-user', ROUTE_CATEGORY.PROFILE, 'read');
  addRoute('romScenarioManager', 'RSM', 'ROM Scenario Manager', '/administration/rom-scenario-manager', 'fa-upload', ROUTE_CATEGORY.ADMINISTRATION, 'read',
    { scopes: [...CAPACITY_PORTAL_CODES, 'PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  addRoute('scope', undefined, undefined, '/scope/:scope/:target*', 'fa-globe', ROUTE_CATEGORY.NONE, 'anonymous');
  addRoute('share', undefined, undefined, '/share', 'fa-share-alt', ROUTE_CATEGORY.NONE, 'read');
  addRoute('shortcut', undefined, undefined, '/shortcut', 'fa-bookmark', ROUTE_CATEGORY.NONE, 'read');
  addRoute('usageMetrics', 'USM', 'Usage Metrics', '/administration/usage-metrics', 'fa-bar-chart', ROUTE_CATEGORY.ADMINISTRATION, 'developer');
  addRoute('warehouses', 'WAR', 'Warehouses', '/administration/warehouses', 'fa-cogs', ROUTE_CATEGORY.ADMINISTRATION, 'read', { scopes: ['PHARMACY_US', 'SSD_US', 'SORT_CENTER_US', 'SORT_CENTER_CA'] });
  addRoute(
    'weeklyPlanViewer', 'WPV', 'Weekly Plan Viewer', '/weekly-plan-viewer', 'fa-th-large', ROUTE_CATEGORY.MAIN, 'read',
    { scopes: ['NA', 'SORT_CENTER_US', 'SORT_CENTER_CA'] }
  );
  // Help Routes.
  addRoute(
    'accessPermissions',
    'ACP',
    'Access Permissions',
    Configuration.endpoints.static.userPermissionsHelp,
    'fa-unlock-alt',
    ROUTE_CATEGORY.HELP,
    'anonymous'
  );
  addRoute(
    'dataAccess',
    'DAA',
    'Data Access',
    {
      DEFAULT: Configuration.endpoints.static.userIptBmDataAccessHelp,
      [ROUTES.weeklyPlanViewer.path]: Configuration.endpoints.static.userIptPlanViewerDataAccessHelp
    },
    'fa-database',
    ROUTE_CATEGORY.HELP,
    'anonymous',
    false,
    { routes: [ROUTES.networkViewer.path, ROUTES.weeklyPlanViewer.path] }
  );
  addRoute(
    'emailUs',
    'EMU',
    'Email Us',
    Configuration.endpoints.static.userFeedback,
    'fa-envelope',
    ROUTE_CATEGORY.HELP,
    'anonymous'
  );
  addRoute(
    'metricDefinitions',
    'MED',
    'Metric Definitions',
    Configuration.endpoints.static.userIptBmMetricDefinitionHelp,
    'fa-file-text',
    ROUTE_CATEGORY.HELP,
    'anonymous',
    false,
    { routes: [ROUTES.networkViewer.path] }
  );
  addRoute(
    'productLineHelp',
    'PLH',
    'Product Line Configuration Help',
    Configuration.endpoints.static.productLineHelp,
    'fa-file-text',
    ROUTE_CATEGORY.HELP,
    'anonymous',
    false,
    { routes: [ROUTES.productLines.path] }
  );
  addRoute(
    'releaseNotes',
    'REN',
    'Release Notes',
    Configuration.endpoints.static.releaseNotes,
    'fa-list-alt',
    ROUTE_CATEGORY.HELP,
    'anonymous'
  );
  addRoute(
    'submitIssue',
    'SUI',
    'Submit an Issue',
    Configuration.endpoints.static.userTT,
    'fa-bug',
    ROUTE_CATEGORY.HELP,
    'anonymous'
  );
  addRoute(
    'userDocumentation',
    'USD',
    'User Documentation',
    Configuration.endpoints.static.userHelp,
    'fa-book',
    ROUTE_CATEGORY.HELP,
    'anonymous'
  );

  Object.freeze(ROUTES);

  const PATH_ALIASES = Object.freeze({
    '/plan-viewer': ROUTES.weeklyPlanViewer.path,
    '/prospect-viewer': ROUTES.weeklyPlanViewer.path,
    getAliases: function (path) {
      const aliases = _(this).pickBy((value) => value === path).keys().value();
      aliases.push(path);
      return aliases;
    },
    getPath: function (path) {
      return _.get(this, path, path);
    }
  });

  const ANCHORS = Object.freeze({
    administration: Anchor.create(
      [
        ROUTES.pipelineServiceWorkflows.generateLink('default'),
        ROUTES.dailyFlashMetrics.generateLink('default'),
        ROUTES.networkPlanner.generateLink('default'),
        ROUTES.manualBacklog.generateLink('default'),
        ROUTES.planAudit.generateLink('default'),
        ROUTES.romScenarioManager.generateLink('default'),
        ROUTES.allocationTreeEditor.generateLink('configuration'),
        ROUTES.grainMappings.generateLink('configuration'),
        ROUTES.productLines.generateLink('configuration'),
        ROUTES.warehouses.generateLink('configuration'),
        ROUTES.announcements.generateLink('developer'),
        ROUTES.developerTools.generateLink('developer'),
        ROUTES.usageMetrics.generateLink('developer'),
        ROUTES.chartLibrary.generateLink('developer'),
        ROUTES.componentLibrary.generateLink('developer'),
        ROUTES.filterLibrary.generateLink('developer')
      ],
      'fa-cog',
      'Configuration'
    ),
    help: Anchor.create(
      [
        ROUTES.accessPermissions.generateLink(),
        ROUTES.userDocumentation.generateLink(),
        ROUTES.releaseNotes.generateLink(),
        ROUTES.dataAccess.generateLink(),
        ROUTES.metricDefinitions.generateLink(),
        ROUTES.productLineHelp.generateLink(),
        ROUTES.submitIssue.generateLink(),
        ROUTES.emailUs.generateLink()
      ],
      'fa-question-circle',
      'Help'
    ),
    home: ROUTES.home.generateLink(),
    main: [
      Anchor.create([ROUTES.modelMarketplace.generateLink()]),
      Anchor.create([ROUTES.planOrchestration.generateLink()]),
      Anchor.create([ROUTES.weeklyPlanViewer.generateLink()]),
      Anchor.create([ROUTES.dailyPlanViewer.generateLink()]),
      Anchor.create([ROUTES.networkViewer.generateLink()]),
      Anchor.create([ROUTES.genericNetworkViewer.generateLink()]),
      Anchor.create([ROUTES.planEditor.generateLink()])
    ],
    profile: ROUTES.profile.generateLink(),
    share: ROUTES.share.generateLink()
  });

  Configuration
    .defineProperty(
      'navigation',
      {
        anchors: ANCHORS
      }
    )
    .defineProperty('routes', ROUTES)
    .defineProperty('routing', PATH_ALIASES);
})();
