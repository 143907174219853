(function () {
  'use strict';
  const STATUS_LIST = Object.freeze(['scheduled', 'active', 'expired']);

  class AnnouncementsController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      $scope.model = {
        listType: STATUS_LIST[1],
        listTypes: _.map(STATUS_LIST, (status) => ({
          text: _.capitalize(status),
          value: status
        }))
      };
    }
  }
  angular.module('application.controllers').controller('AnnouncementsController', AnnouncementsController);
})();
