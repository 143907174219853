/* globals ClassDecorator, Display, Enums, HeaderCell */
(function () {
  'use strict';
  const set = (object, value, options) => {
    const property = _.get(options, 'set');

    if (!Array.isArray(object) && !_.isNil(property) && _.isString(property)) {
      object[property] = value;
      return true;
    }

    return false;
  };

  const values = (object, options) => {
    const property = _.get(options, 'property');

    if (!Array.isArray(object) && !_.isNil(property) && _.isString(property)) {
      return object[property];
    }

    return object;
  };

  const generateHeaderRowData = (dataPackage, valueDateFormat, titleDateFormat, isFirstRow) => {
    const row = {
      data: [],
      headers: []
    };

    if (isFirstRow) {
      // Add granularity column headers
      dataPackage.granularity.grains.values(dataPackage.viewGrainFilter).forEach((grain) =>
        row.headers.push(
          HeaderCell
            .create(
              grain.name,
              grain.name,
              ClassDecorator.width(grain)
            )
            .setRowspan(3)
        )
      );
      // Add week span column headers
      let currentWeek;
      dataPackage.dates.forEach((date) => {
        const nextWeekValue = Display.date(date, valueDateFormat);
        if (!_.isUndefined(currentWeek) && nextWeekValue === currentWeek.value) {
          // Increment the colspan for the currentWeek and continue iteration
          ++currentWeek.colspan;
          return;
        }
        currentWeek = HeaderCell.create(
          nextWeekValue,
          Display.date(date, titleDateFormat),
          'text-center',
          ClassDecorator.width()
        );
        row.data.push(currentWeek);
      });
    } else {
      // Add date column headers
      dataPackage.dates.forEach((date) =>
        row.data.push(
          HeaderCell.create(
            Display.date(date, valueDateFormat),
            Display.date(date, titleDateFormat),
            'text-center',
            ClassDecorator.today(date),
            ClassDecorator.width()
          )
        )
      );
    }
    return row;
  };

  class Grid {
    /* Generates a body object.
     *
     * @param length the number of rows available from the row supplier
     * @param rowSupplier the row supplier for the body
     *
     * @return an object with the standard body properties.
     */
    static body (length = 0, rowSupplier = _.noop) {
      return { length, rowSupplier };
    }

    /* Generates a standard set of header rows based on the data package.
     *
     * @param dataPackage the DataPackage to translate into a set of header rows
     *
     * @return an Array of header rows used in rendering a grid.
     */
    static defaultHeaderRowSupplier (dataPackage) {
      return [
        generateHeaderRowData(dataPackage, Enums.DateFormat.WkNumber, 'YYYY', true),
        generateHeaderRowData(dataPackage, 'ddd', 'dddd'),
        generateHeaderRowData(dataPackage, 'MM-DD', 'YYYY-MM-DD')
      ];
    }

    /* Generates a footer object.
     *
     * @param length the number of rows available from the row supplier
     * @param rowSupplier the row supplier for the footer
     *
     * @return an object with the standard footer properties.
     */
    static footer (length = 0, rowSupplier = _.noop) {
      return { length, rowSupplier };
    }

    /* Generates validated bounds of the grid display window.
     *
     * @param rowStart an integer representing the row start value (top border) of the grid window
     * @param rowEnd an integer representing the row end value (bottom border) of the grid window
     * @param columnStart an integer representing the column start value (left border) of the grid window
     * @param columnEnd an integer representing the column end value (right border) of the grid window
     * @param totalRowLength an integer representing the total number of rows in the grid
     * @param totalColumnLength an integer representing the total number of columns in the grid
     *
     * @return an object containing the validated values for rowStart, rowEnd, columnStart and columnEnd.
     */
    static getBounds (rowStart, rowEnd, columnStart, columnEnd, totalRowLength, totalColumnLength) {
      return {
        columnEnd: _.isNil(columnEnd) || columnEnd < 0 || columnEnd < columnStart || columnEnd > totalColumnLength ? totalColumnLength : columnEnd,
        columnStart: _.isNil(columnStart) || columnStart < 0 || columnStart > columnEnd ? 0 : columnStart,
        rowEnd: _.isNil(rowEnd) || rowEnd < 0 || rowEnd < rowStart || rowEnd > totalRowLength ? totalRowLength : rowEnd,
        rowStart: _.isNil(rowStart) || rowStart < 0 || rowStart > rowEnd ? 0 : rowStart
      };
    }

    /* Generates a header object.
     *
     * @param title the title of the grid
     * @param rowSupplier the row supplier for the header
     * @param options object hash to assign to the object
     *
     * @return an object with the standard header properties.
     */
    static header (title, rowSupplier = _.noop, options = {}) {
      return Object.assign({
        id: _.camelCase(title),
        rowSupplier: rowSupplier,
        title: title
      }, options);
    }

    /* Computes the percentiles for all values in a two-dimensional grid of data.
     *
     * @param data the two-dimensional array of data. Items in array can either be arrays or objects with an array property.
     * @param length the length of each row of data.
     * @param denominators the values to be used as denominators in calculations (defaults to the values returned by the totals function).
     * @param options a hash object of options:
     *   property: If objects are the items in the array then the property option must be defined for how to access the data rows.
     *   set: If the percentile arrays should be attached to the current object then the set option must be defined with a property name to set.
     * @return a two-dimensional grid of percentiles or an empty list if the percentiles are attached to the objects.
     *
     * Assumptions:
     *   all input data are either numbers or null values
     *   null is treated as 0 in addition operation
     *   each row of data has a fixed length
     *
     * In the following cases, the percentile value is a valid number:
     *   numerator[i, j] is zero
     *   numerator[i, j] is a finite number and denominator[j] is a finite number that is not zero
     */
    static percentiles (data, length, denominators, options) {
      const result = [];
      denominators = denominators || this.totals(data, length, options);
      data.forEach((row) => {
        const numerators = values(row, options);
        const quotients = denominators.map((denominator, i) => {
          const numerator = numerators[i];
          // If the numerator is zero then return 0 and if it is null then return null, even if the denominator is 0
          // This handles the case where a 0 total is computed due to only zero or null values being present
          if (_.isNull(numerator) || numerator === 0) {
            return numerator;
          }
          if (denominator !== 0 && _.isFinite(denominator) && _.isFinite(numerator)) {
            return numerator / denominator;
          }
          return NaN;
        });
        if (!set(row, quotients, options)) {
          result.push(quotients);
        }
      });
      return result;
    }

    /* Resizes the range of all rows in a two-dimensional grid of data. This method mutates the provided data object.
     *
     * @param data the two-dimensional array of data. Items in array can either be arrays or objects with an array property.
     * @param options a hash object of options:
     *   append: The number of cells to append to or remove from the end of each data row.
     *   prepend: The number of cells to prepend to or remove from the start of each data row.
     *   property: If objects are the items in the array then the property option must be defined for how to access the data rows.
     *   value: The value to insert in each cell that is appended or prepended (default is null).
     */
    static resize (data, options) {
      let appendValues, prependValues;
      options = _.defaults(options, {
        value: null
      });
      if (!_.isFinite(options.append)) {
        options.append = 0;
      }
      if (!_.isFinite(options.prepend)) {
        options.prepend = 0;
      }

      if (options.append === 0 && options.prepend === 0) {
        return data;
      }

      if (options.append > 0) {
        appendValues = _.fill(Array(options.append), options.value);
      }
      if (options.prepend > 0) {
        prependValues = _.fill(Array(options.prepend), options.value);
      }

      data.forEach((row) => {
        const array = values(row, options);
        if (options.prepend < 0) {
          array.splice(0, Math.abs(options.prepend));
        } else if (options.prepend > 0) {
          array.unshift(...prependValues);
        }

        if (options.append < 0) {
          array.splice(array.length - Math.abs(options.append));
        } else if (options.append > 0) {
          array.push(...appendValues);
        }
      });
    }

    /* Adds rowspan information to 'data' (array of objects) based on the grains information.
     *
     * @param data an array of objects. Each object has 'granularity' and 'values' as keys
     * @param grains an array of ordered objects each representing grain information:
     *   id: This is the id of the 'granularity' object and only required field for this method.
     *
     * Assumptions:
     *   data can never be null or undefined
     *   In the grains objects, 'id' value must be one of the properties in the data.granularity object.
     */
    static rowspanCalculator (data, grains) {
      data.forEach((entry) => delete entry.rowspan);
      const forceRowspan = _.fill(Array(data.length), false);
      // Traverse each column
      grains.forEach((grainEntry) => {
        const property = grainEntry.id;

        let previousValue = data[0].granularity[property],
            currentSpan = _.defaults(data[0], { rowspan: {} }).rowspan;
        currentSpan[property] = 0;

        // Index start from 1 instead of 0
        for (let i = 1; i < data.length; ++i) {
          const currentValue = data[i].granularity[property];

          // Update rowspan when meet different item or hit the end of parent row
          if (forceRowspan[i] || currentValue !== previousValue) {
            currentSpan[property] = i - currentSpan[property];
            currentSpan = _.defaults(data[i], { rowspan: {} }).rowspan;
            currentSpan[property] = i;
            forceRowspan[i] = true;
            previousValue = currentValue;
          }
        }

        // Take care of last couple identical items
        currentSpan[property] = data.length - currentSpan[property];
      });
    }

    /* Computes a totals array for a two-dimensional grid of data.
     *
     * @param data the two-dimensional array of data. Items in array can either be arrays or objects with an array property.
     * @param length the length of each row of data.
     * @param options a hash object of options:
     *   property: If objects are the items in the array then the property option must be defined for how to access the data rows.
     * @return an array of totals
     *
     * Assumptions:
     *   all input data are either numbers or null values
     *   length is greater than 0
     *   null is treated as 0 in addition operation
     *   each row of data has a fixed length
     */
    static totals (data, length, options) {
      if (_.isNil(data)) {
        return data;
      }
      return _.reduce(
        data,
        (sum, row) => values(row, options).map((element, i) => _.isFinite(element) ? element + sum[i] : sum[i]),
        _.fill(Array(length), null)
      );
    }
  }

  window.Grid = Object.freeze(Grid);
})();
