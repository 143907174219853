/* globals Configuration, Display */
'use strict';
angular.module('application.filters', [])
  .filter('dateFormat', () => Display.date)
  .filter('decimal', () => Display.decimal)
  .filter('default', () => Display.default)
  .filter('duration', () => Display.duration)
  .filter('hasAccess', ['$authentication', ($authentication) => (role) => $authentication.hasAccess(Configuration.authorization.access[role])])
  .filter('ignore', () => Display.ignore)
  .filter('integer', () => Display.integer)
  .filter('join', () => Display.join)
  .filter('percent', () => Display.percent)
  .filter('pluralize', () => Display.pluralize)
  .filter('plus', () => Display.plus)
  .filter('possessivize', () => Display.possessivize)
  .filter('startCase', () => _.startCase)
  .filter('stringify', () => Display.stringify)
  .filter('timeZone', () => Display.timeZone)
  .filter('toUpperFirst', () => (value) => _.upperFirst(_.lowerCase(value)))
  .filter('trust', ['$sce', ($sce) => $sce.trustAsHtml])
  .filter('tuples', () => Display.tuples)
  .filter('version', () => Display.version);
