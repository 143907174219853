/* globals Configuration */
(function () {
  'use strict';
  class ShortcutController {
    static get $inject () {
      return ['alerts', '$location', '$routeParams', 'shortcut'];
    }

    constructor (alerts, $location, $routeParams, shortcut) {
      const baseUrl = $routeParams.baseUrl;
      const id = $routeParams.id;
      if (_.isEmpty(id) || _.isEmpty(baseUrl) || _.isEmpty(shortcut.getRouteByPath(baseUrl))) {
        alerts.warning('Unable to process the shortcut. You have been redirected to the Home page.');
        $location.url(Configuration.routes.home.path);
        return;
      }
      shortcut.setShortcutData(id);
      $location.url(baseUrl);
    }
  }
  angular.module('application.controllers').controller('ShortcutController', ShortcutController);
})();
