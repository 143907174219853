/* globals AbstractPackagerService, Configuration, DataRow, Granularities */
(function () {
  class NetworkPlannerViewerPackagerService extends AbstractPackagerService {

    static get $inject () {
      return ['$q', 'packagerFactory'];
    }

    constructor ($q, packagerFactory) {
      super($q);
      this.packagerFactory = packagerFactory;
    }

    _isActual (index) {
      return index < Configuration.networkPlannerDateRange.actuals;
    }

    _merge (forecast, actuals) {
      return this.$q.all({ actuals, forecast })
        .then((records) => _.map(records.forecast, (row, rowIndex) => DataRow.create({
          dataType: row.dataType,
          granularity: row.granularity,
          metric: row.metric,
          type: row.type,
          values: _.map(row.cells, (cell, cellIndex) => this._isActual(cellIndex) ? records.actuals[rowIndex].cells[cellIndex] : cell)
        })));
    }

    /**
     * Collects plans/actuals for the Network Planner page.
     *
     * @param dateRange - dateRange
     * @param family - metric family
     * @param configuration is a hash containing request metadata:
     *   @forecast Object:
     *     @plan Object the forecast plan that forecast metrics should be requested for
     *   @forecastComparisons Array:
     *     @plan Object the comparison plan that forecast metrics should be requested for
     *   @actualsComparisons Array:
     *     @plan Object the actuals comparison plan that historic forecast metrics should be requested for
     *   @granularity Object the granularity that should be applied to each request
     *   @filters Object the filters that should be applied to each request
     * @param settings
     *   @actuals Boolean a flag indicating whether requesting actuals is required
     *   @actualsComparisons Array
     *     @comparison Boolean a flag indicating whether requesting actuals comparison plan data is required
     *   @forecastComparisons Array
     *     @comparison Boolean a flag indicating whether requesting comparison plan data is required
     * @param filterValue - grain value, currently being filtered on
     * @returns DataPackage
     */
    collect (dateRange, family, configuration, settings, filterValue) {
      const forecast = this.packagerFactory.collect(
        this.packagerFactory.packagerType.planViewer,
        dateRange,
        family,
        configuration,
        settings
      );

      const configuredPlan = Configuration.networkPlannerPlans.find((plan) => plan.type === configuration.forecast.plan.type);

      if (_.isNil(configuredPlan.actualsGranularity)) {
        return forecast;
      }

      const actualsConfiguration = _.cloneDeep(configuration);
      actualsConfiguration.granularity.grains = _.transform(configuredPlan.actualsGranularity.grains, (granularity, grain) => {
        granularity[_.camelCase(`add-${grain}Grain`)]();
      }, Granularities.create());
      actualsConfiguration.granularity.plan = configuredPlan.actualsGranularity.plan;
      actualsConfiguration.filters = _.cloneDeep(configuredPlan.actualsFilters);
      actualsConfiguration.filters[configuredPlan.actualsSplitBy] = [filterValue];
      const actualsSettings = _.cloneDeep(settings);
      actualsSettings.forecasts = false;
      const actuals = this.packagerFactory.collect(
        this.packagerFactory.packagerType.planViewer,
        dateRange,
        family,
        actualsConfiguration,
        actualsSettings
      );

      forecast.records = this._merge(forecast.records, actuals.records);
      return forecast;
    }
  }

  angular.module('application.services').service('networkPlannerViewerPackager', NetworkPlannerViewerPackagerService);
})();
