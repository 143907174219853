/* globals AbstractSourceBasedModel, OrchestratorExecutionStepTask, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'allowedModifications',
    'autoCompleteTime',
    'completedTime',
    'displayName',
    'plans',
    'startTime',
    'state',
    'status',
    'stepId',
    'stepName',
    'user'
  ]);

  class OrchestratorExecutionStep extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.tasks = {};
      _.forEach(source.tasks, (task, key) => this.tasks[key] = OrchestratorExecutionStepTask.create(task));
    }

    hasNextStep (index, totalSteps) {
      return index + 1 < totalSteps;
    }

    hasTasks () {
      return !_.isEmpty(this.tasks);
    }

    isInProgress () {
      return TaskStatus.isInProgress(this.status);
    }

    isScheduled () {
      return TaskStatus.isScheduled(this.status);
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.status);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.OrchestratorExecutionStep = Object.freeze(OrchestratorExecutionStep);
})();
