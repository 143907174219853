/* globals AbstractElementComponent, DateUtils, Enums, Filename, XLSX */
(function () {
  'use strict';
  const ONE_SECOND_IN_MILLISECONDS = 1000;
  const WEEKS_PER_YEAR = 52;
  const EXPECTED_INPUT_CSV_COLUMNS = Object.freeze({
    arrivals: Object.freeze([
      'arrival_country',
      'origin',
      'received_date',
      'lag_week',
      'received_quantity'
    ]),
    creations: Object.freeze([
      'arrival_country',
      'created_date',
      'origin',
      'planned_quantity'
    ])
  });
  const FILL_RATE_CSV_HEADERS = {
    // Model Marketplace compatible headers
    mmpCompatible: {
      headers: Object.freeze([
        'date',
        'sortType',
        'productLine',
        'value'
      ]),
      label: 'MMP Compatible'
    },
    standard: {
      headers: Object.freeze([
        'date',
        'origin',
        'lag',
        'fillRate'
      ]),
      label: 'Standard'
    }
  };
  const FILL_RATE_GENERATION_PERIODS = Object.freeze([
    { label: '4 yrs - MMP minimum', value: 4 },
    { label: '3 yrs', value: 3 },
    { label: '2 yrs', value: 2 },
    { label: '1 yr', value: 1 }
  ]);

  function toIsoDate (date) {
    return date.format(Enums.DateFormat.IsoDate);
  }

  function toUtcIsoDate (date) {
    return toIsoDate(date.utc());
  }

  class FillRateGeneratorController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', 'trader', '$window'];
    }

    constructor (alerts, trader, $window) {
      super();
      this.alerts = alerts;
      this.trader = trader;
      this.$window = $window;
    }

    _loadData () {
      this.columns = {
        arrivals: {},
        creations: {}
      };
      this.data = {
        arrivals: [],
        creations: [],
        fillRate: []
      };
      this.dateRange = {
        arrivals: {
          actual: {},
          forGeneration: {}
        },
        creations: {
          actual: {},
          forGeneration: {}
        }
      };
      this.generation = {
        endTime: 0,
        startTime: 0,
        timeTaken: 0
      };
      this.fillRateHeaders = {
        list: Object.freeze([FILL_RATE_CSV_HEADERS.mmpCompatible, FILL_RATE_CSV_HEADERS.standard]),
        selected: FILL_RATE_CSV_HEADERS.mmpCompatible
      };
      this.fillRateYears = {
        list: FILL_RATE_GENERATION_PERIODS,
        selected: _.head(FILL_RATE_GENERATION_PERIODS)
      };
      this.fillRateDateRange = '';
      this.isFillRateReady = false;
      this.generatingFillRate = false;
      this.uploading = {
        arrivals: false,
        creations: false
      };
    }

    _delay (delayInMilliseconds) {
      return new Promise((resolve) => this.$window.setTimeout(resolve, delayInMilliseconds));
    }

    _generateFillRate () {
      if (this.isDataUnavailable()) {
        return;
      }
      this.generatingFillRate = true;
      this.data.fillRate.push(this.fillRateHeaders.selected.headers);
      // 1 represents 'US'. This generator will only be used for scope US.
      const arrivalCountry = 1;
      // Holds an unique list of origin scopes. (like 'CA', 'CN', 'OTHER' and 'US')
      const uniqueOrigins = _.uniq(_.map(this.data.arrivals, 'origin'));
      // Date range (sunday to sunday dates) for which Fill Rate data will be generated.
      const dates = DateUtils.expansion(this.dateRange.arrivals.forGeneration.start, this.dateRange.arrivals.forGeneration.end, Enums.TimeGranularity.WEEKLY);
      dates.forEach((date) => {
        uniqueOrigins.forEach((origin) => {
          _.range(13).forEach((lag) => {
            // Filter rows for units received during the week of `date`, created by `origin` sellers, `lag` weeks ago.
            const filteredArrivalsData = _.filter(this.data.arrivals, (row) => row.arrival_country === arrivalCountry && row.lag_week === lag && row.origin === origin
              && toUtcIsoDate(DateUtils.of(row.received_date)) === date);
            const receivedFromLagWeek = _.sumBy(filteredArrivalsData, 'received_quantity');
            // Filter rows for units created from `origin` sellers in the week of `date` - `lag` weeks.
            const filteredCreationsData = _.filter(this.data.creations, (row) => row.arrival_country === arrivalCountry && row.origin === origin
              && toUtcIsoDate(DateUtils.of(row.created_date)) === DateUtils.fromOffset(date, -1 * lag));
            const shippedAtLagWeek = _.sumBy(filteredCreationsData, 'planned_quantity');
            // Fill Rate number rounded off after 8 decimal places.
            const fillRate = _.round((receivedFromLagWeek / shippedAtLagWeek) * 100, 8);
            this.data.fillRate.push([date, origin, lag, fillRate]);
          });
        });
      });
      this.generation.endTime = DateUtils.epoch();
      // Time taken to generate Fill Rate results in seconds.
      this.generation.timeTaken = Math.floor((this.generation.endTime - this.generation.startTime) / ONE_SECOND_IN_MILLISECONDS);
      this.fillRateDateRange = `${this.dateRange.arrivals.forGeneration.start} - ${this.dateRange.arrivals.forGeneration.end} (${this.fillRateYears.selected.value} years)`;
      this.generatingFillRate = false;
      this.isFillRateReady = true;
    }

    _populateDateRanges (inputType) {
      if (inputType === 'creations') {
        // Min date value from the Creations input file 'created_date' column.
        this.dateRange.creations.actual.start = toUtcIsoDate(DateUtils.of(_.minBy(this.data[inputType], 'created_date').created_date));
        // Max date value from the Creations input file 'created_date' column.
        this.dateRange.creations.actual.end = toUtcIsoDate(DateUtils.of(_.maxBy(this.data[inputType], 'created_date').created_date));
        return;
      }
      // Min date value from the Arrivals input file 'received_date' column.
      this.dateRange.arrivals.actual.start = toUtcIsoDate(DateUtils.of(_.minBy(this.data[inputType], 'received_date').received_date));
      // Max date value from the Arrivals input file 'received_date' column.
      this.dateRange.arrivals.actual.end = toUtcIsoDate(DateUtils.of(_.maxBy(this.data[inputType], 'received_date').received_date));
      // Ideal date range start, the historic date from selected "fill rate period" years back.
      const periodBasedStartDate = DateUtils.fromOffset(this.dateRange.arrivals.actual.end, -1 * this.fillRateYears.selected.value * WEEKS_PER_YEAR);
      // Arrivals date range start used in generation, max/newest date of minArrivalDate and periodBasedStartDate.
      this.dateRange.arrivals.forGeneration.start = toIsoDate(DateUtils.max(this.dateRange.arrivals.actual.start, periodBasedStartDate));
      this.dateRange.arrivals.forGeneration.end = this.dateRange.arrivals.actual.end;
      // Creations date range used in generation (based on Arrivals for generation dates).
      this.dateRange.creations.forGeneration.start = DateUtils.fromOffset(this.dateRange.arrivals.forGeneration.start, -12);
      this.dateRange.creations.forGeneration.end = this.dateRange.arrivals.forGeneration.end;
    }

    _validateInput (inputType) {
      const inputData = this.data[inputType];
      if (_.isEmpty(inputData)) {
        this.alerts.danger(`The CSV file has no data. Please upload a CSV with the expected ${_.startCase(inputType)} data.`);
        return false;
      }
      let isMissingColumn = false;
      EXPECTED_INPUT_CSV_COLUMNS[inputType].forEach((expectedColumn) => {
        if (Object.keys(_.head(inputData)).includes(expectedColumn)) {
          this.columns[inputType][expectedColumn] = true;
        } else {
          this.alerts.danger(`The CSV file is missing the expected "${expectedColumn}" column. Please make sure you are uploading a valid file.`);
          this.columns[inputType][expectedColumn] = false;
          isMissingColumn = true;
        }
      });
      if (isMissingColumn) {
        this.data[inputType] = [];
        return false;
      }
      return true;
    }

    downloadFillRateResults () {
      // Since FillRateGenerator is a quick solution / "throw away work" and will be discarded in a few months,
      // not creating and using a new transformer type and transformer service here (which is the norm).
      this.trader.download(
        XLSX.utils.aoa_to_sheet(this.data.fillRate, { dateNF: 'yyyy"-"mm"-"dd' }),
        Filename.create('FillRateActuals'),
        this.trader.toExtensionType(Enums.DocumentFormat.CSV)
      );
    }

    generateFillRateResults (skipDelay) {
      this.isFillRateReady = false;
      this.generatingFillRate = true;
      this.generation.startTime = DateUtils.epoch();
      // Adding the ability to skip delay for effective unit testing.
      if (skipDelay) {
        this._generateFillRate();
      } else {
        // Adding delay to allow <spinner> on the template to show up on 'Generate Fill Rate' button click.
        // Since FillRate generation is a client-side heavy process, it blocks showing/hiding of the <spinner>.
        this._delay(100).then(() => this._generateFillRate());
      }
    }

    getExpectedColumns (inputType) {
      return EXPECTED_INPUT_CSV_COLUMNS[inputType];
    }

    isArrivalsDataUnavailable () {
      return _.isEmpty(this.data.arrivals);
    }

    isCreationsDataUnavailable () {
      return _.isEmpty(this.data.creations);
    }

    isCreationsEndDateAsExpected () {
      const creationsEndDate = this.dateRange.creations.actual.end;
      const expectedDateRangeEnd = this.dateRange.creations.forGeneration.end;
      return _.isNil(creationsEndDate) || _.isNil(expectedDateRangeEnd) || DateUtils.difference(creationsEndDate, expectedDateRangeEnd, Enums.TimeUnit.DAY) >= 0;
    }

    isCreationsStartDateAsExpected () {
      const creationsStartDate = this.dateRange.creations.actual.start;
      const expectedDateRangeStart = this.dateRange.creations.forGeneration.start;
      return _.isNil(creationsStartDate) || _.isNil(expectedDateRangeStart) || DateUtils.difference(creationsStartDate, expectedDateRangeStart, Enums.TimeUnit.DAY) <= 0;
    }

    isDataUnavailable () {
      return this.isArrivalsDataUnavailable() || this.isCreationsDataUnavailable();
    }

    isGenerateDisabled () {
      return this.isDataUnavailable() || this.isFillRateReady;
    }

    onFileSelected (file, inputType) {
      this.uploading[inputType] = true;
      this.trader.upload(file).then((workbook) => {
        this.uploading[inputType] = false;
        // Since FillRateGenerator is a quick solution / "throw away work" and will be discarded in a few months,
        // not creating and using a new transformer type and transformer service here (which is the norm).
        const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.DEFAULT], { raw: true });
        this.data[inputType] = sheet;
        if (this._validateInput(inputType)) {
          this._populateDateRanges(inputType);
        }
      });
    }

    onFillRateSelectionChange (selection) {
      this.fillRateYears.selected = selection;
      this._populateDateRanges('arrivals');
      this._populateDateRanges('creations');
    }
  }

  angular.module('application.components')
    .component('fillRateGenerator', {
      controller: FillRateGeneratorController,
      templateUrl: 'templates/components/fill-rate-generator.component.html'
    });
})();
