/* globals WindowScrollObserver */
(function () {
  'use strict';
  const FIXED_COLUMNS_TABLE_PROPERTY = 'fixedColumnsTable';
  const FIXED_CORNER_TABLE_PROPERTY = 'fixedCornerTable';
  const FIXED_ROWS_TABLE_PROPERTY = 'fixedRowsTable';
  const FIXED_TITLE_TABLE_PROPERTY = 'fixedTitleTable';
  const TABLE_TITLE_HEIGHT = 50;

  const cloneTable = function (table) {
    const clone = table.cloneNode();
    clone.removeAttribute('sticky-table-headers');
    clone.removeAttribute('sticky-header-columns');
    clone.removeAttribute('sticky-header-rows');
    return clone;
  };

  const deleteStickyTable = function (state, tablePropertyKey) {
    const table = state[tablePropertyKey];
    if (table) {
      table.parentNode.removeChild(table);
      delete state[tablePropertyKey];
    }
  };

  /* Makes TH cells in THEAD, TBODY and TFOOT sections sticky.
   * Original table is shallow cloned, only the TH nodes are appended
   * to this cloned table as child nodes. The cloned table is then assigned
   * a Fixed position and moved respective to the original table's position
   * on horizontal scroll.
   *
   * Note: Above actions are performed only if 'state.makeColumnsSticky' is true
   *
   * @param state the tracked state variables
   * @param table DOM object of the table
   * @param tableRect object containing the position/dimensions of the table
   *
   */
  const stickyColumns = function (state, table, tableRect) {
    if (!state.makeColumnsSticky) {
      return;
    }

    if (tableRect.left < 0) {
      if (!state.fixedColumnsTable) {
        state.fixedColumnsTable = cloneTable(table);
        table.childNodes.forEach(function (tableSectionNode) {
          if (_.includes(['THEAD', 'TBODY', 'TFOOT'], tableSectionNode.tagName)) {
            const tableSectionNodeClone = tableSectionNode.cloneNode();
            tableSectionNode.childNodes.forEach(function (rowNode) {
              if (rowNode.tagName !== 'TR') {
                return;
              }

              const rowNodeClone = rowNode.cloneNode();
              for (let index = 0; index < rowNode.childNodes.length; ++index) {
                const cellNode = rowNode.childNodes[index];
                if (cellNode.tagName !== 'TH') {
                  // By the semantics of how we construct tables we only want the TH nodes that appear at the start of the row.
                  // Once we encounter a non-TH node then we can short circuit the loop as all remaining nodes are not included.
                  break;
                }
                const cellNodeRect = cellNode.getBoundingClientRect();
                const cellNodeClone = cellNode.cloneNode(true);
                cellNodeClone.style.height = `${cellNodeRect.height}px`;
                rowNodeClone.appendChild(cellNodeClone);
              }
              tableSectionNodeClone.appendChild(rowNodeClone);
            });
            state.fixedColumnsTable.appendChild(tableSectionNodeClone);
          }
        });
        state.fixedColumnsTable.style.position = 'fixed';
        state.fixedColumnsTable.style.width = 'auto';
        table.parentNode.appendChild(state.fixedColumnsTable);
      }
      state.fixedColumnsTable.style.left = '0';
      state.fixedColumnsTable.style.top = `${tableRect.top}px`;
    } else {
      deleteStickyTable(state, FIXED_COLUMNS_TABLE_PROPERTY);
    }
  };

  /* Makes corner section in the THEAD section sticky.
   * Original table is shallow cloned, only the TH nodes in the THEAD are appended
   * to this cloned table as child nodes. The cloned table is then assigned
   * a Fixed position and holds the top-left position on horizontal and vertical scroll.
   *
   * Note: Above actions are performed only if both
   * 'state.makeColumnsSticky' and 'state.makeRowsSticky' are true
   *
   * @param NAVBAR_HEIGHT the navbar height
   * @param state the tracked state variables
   * @param table DOM object of the table
   * @param tableRect object containing the position/dimensions of the table
   * @param thead DOM object of the THEAD node
   * @param theadRect object containing the position/dimensions of the THEAD node
   *
   */
  const stickyCornerOverlap = function (NAVBAR_HEIGHT, state, table, tableRect, thead, theadRect) {
    if (!state.makeColumnsSticky || !state.makeRowsSticky) {
      return;
    }

    if (tableRect.left < 0 && theadRect.top < NAVBAR_HEIGHT + TABLE_TITLE_HEIGHT && tableRect.bottom > NAVBAR_HEIGHT) {
      if (!state.fixedCornerTable) {
        state.fixedCornerTable = cloneTable(table);
        const theadNodeClone = thead.cloneNode();
        thead.childNodes.forEach(function (rowNode) {
          if (rowNode.tagName !== 'TR') {
            return;
          }

          const rowNodeClone = rowNode.cloneNode();
          for (let index = 0; index < rowNode.childNodes.length; ++index) {
            const cellNode = rowNode.childNodes[index];
            if (cellNode.tagName !== 'TH') {
              // By the semantics of how we construct tables we only want the TH nodes that appear at the start of the row.
              // Once we encounter a non-TH node then we can short circuit the loop as all remaining nodes are not included.
              break;
            }
            const cellNodeRect = cellNode.getBoundingClientRect();
            const cellNodeClone = cellNode.cloneNode(true);
            cellNodeClone.style.height = `${cellNodeRect.height}px`;
            rowNodeClone.appendChild(cellNodeClone);
          }
          theadNodeClone.appendChild(rowNodeClone);
        });
        state.fixedCornerTable.style.left = '0';
        state.fixedCornerTable.style.width = 'auto';
        state.fixedCornerTable.style.position = 'fixed';
        state.fixedCornerTable.style.zIndex = '1';
        state.fixedCornerTable.appendChild(theadNodeClone);
        table.parentNode.appendChild(state.fixedCornerTable);
      }

      const fixedCornerTableTop = Math.min(NAVBAR_HEIGHT + TABLE_TITLE_HEIGHT, tableRect.bottom - theadRect.height);
      state.fixedCornerTable.style.top = `${fixedCornerTableTop}px`;
    } else {
      deleteStickyTable(state, FIXED_CORNER_TABLE_PROPERTY);
    }
  };

  /* Makes THEAD section of the table sticky.
   * Original table is shallow cloned, only the COLGROUP and THEAD nodes are appended
   * to this cloned table as child nodes. The cloned table is then assigned
   * a Fixed position and holds a top position on horizontal and vertical scroll.
   *
   * Note: Above actions are performed only if 'state.makeRowsSticky' is true
   *
   * @param NAVBAR_HEIGHT the navbar height
   * @param state the tracked state variables
   * @param table DOM object of the table
   * @param tableRect object containing the position/dimensions of the table
   * @param thead DOM object of the THEAD node
   * @param theadRect object containing the position/dimensions of the THEAD node
   *
   */
  const stickyRows = function (NAVBAR_HEIGHT, state, table, tableRect, thead, theadRect) {
    if (!state.makeRowsSticky) {
      return;
    }

    if (theadRect.top < NAVBAR_HEIGHT + TABLE_TITLE_HEIGHT && tableRect.bottom > NAVBAR_HEIGHT) {
      if (!state.fixedRowsTable) {
        state.fixedRowsTable = cloneTable(table);
        table.childNodes.forEach(function (node) {
          if (node.tagName === 'COLGROUP') {
            state.fixedRowsTable.appendChild(node.cloneNode(true));
          }
        });
        state.fixedRowsTable.appendChild(thead.cloneNode(true));
        state.fixedRowsTable.style.position = 'fixed';
        table.parentNode.appendChild(state.fixedRowsTable);
      }

      const fixedRowsTableTop = Math.min(NAVBAR_HEIGHT + TABLE_TITLE_HEIGHT, tableRect.bottom - theadRect.height);
      state.fixedRowsTable.style.left = `${tableRect.left}px`;
      state.fixedRowsTable.style.width = `${tableRect.width}px`;
      state.fixedRowsTable.style.top = `${fixedRowsTableTop}px`;
    } else {
      deleteStickyTable(state, FIXED_ROWS_TABLE_PROPERTY);
    }
  };

  const stickyTitle = function (NAVBAR_HEIGHT, state, table, tableRect, thead) {
    if (tableRect.left < 0 || (tableRect.top - TABLE_TITLE_HEIGHT < NAVBAR_HEIGHT && tableRect.bottom > NAVBAR_HEIGHT)) {
      if (!state.fixedTitleTable) {
        state.fixedTitleTable = cloneTable(table);
        state.fixedTitleTable.style.border = '0';
        const theadNodeClone = thead.cloneNode();
        const rowNode = _.head(thead.childNodes);
        const rowNodeClone = rowNode.cloneNode();
        rowNodeClone.className = 'grid-caption';
        const cellNode = _.head(rowNode.childNodes);
        const cellNodeClone = cellNode.cloneNode(true);
        cellNodeClone.textContent = state.tableTitle;
        cellNodeClone.title = state.tableTitle;
        cellNodeClone.className = 'grid-title';
        cellNodeClone.style.height = `${TABLE_TITLE_HEIGHT}px`;
        rowNodeClone.appendChild(cellNodeClone);
        theadNodeClone.appendChild(rowNodeClone);
        state.fixedTitleTable.appendChild(theadNodeClone);
        state.fixedTitleTable.style.position = 'fixed';
        state.fixedTitleTable.style.zIndex = '1';
        table.parentNode.appendChild(state.fixedTitleTable);
      }

      const fixedTitleTableTop = tableRect.left < 0 && (tableRect.top - TABLE_TITLE_HEIGHT > NAVBAR_HEIGHT || tableRect.bottom < NAVBAR_HEIGHT)
        ? Math.min(tableRect.top - TABLE_TITLE_HEIGHT, tableRect.bottom - TABLE_TITLE_HEIGHT)
        : Math.min(NAVBAR_HEIGHT, tableRect.bottom - TABLE_TITLE_HEIGHT);
      state.fixedTitleTable.style.left = tableRect.left < 0 ? '0' : `${tableRect.left}px`;
      state.fixedTitleTable.style.top = `${fixedTitleTableTop}px`;
      state.fixedTitleTable.style.width = `${tableRect.width}px`;
    } else {
      deleteStickyTable(state, FIXED_TITLE_TABLE_PROPERTY);
    }
  };

  /**
   * Directive to make the header(THEAD) and/or header columns(TH) of a table sticky.
   *
   * Emulates Firefox's postion:sticky behavior on a thead element. This is necessary
   * because the behavior is not yet standardized across supported browsers (Chrome, Firefox).
   *
   * @name application.directives.stickyTableHeaders
   * @example
   * <table sticky-table-headers>...</table>
   */
  angular.module('application.directives')
    .directive('stickyTableHeaders', ['$window', function ($window) {
      const NAVBAR_HEIGHT = $window.document.querySelector('nav').offsetHeight;

      return {
        link: function (scope, element, attributes) {
          const TABLE_ELEMENT = element[0];

          scope.stickyHeaders = {
            fixedColumnsTable: null,
            fixedCornerTable: null,
            fixedRowsTable: null,
            fixedTitleTable: null,
            // defaults to false for all values other than 'true'
            makeColumnsSticky: attributes.stickyHeaderColumns === 'true',
            // defaults to true for all values other than 'false'
            makeRowsSticky: attributes.stickyHeaderRows !== 'false',
            tableTitle: attributes.stickyTableTitle
          };

          const createStickyHeaders = function () {
            if (!TABLE_ELEMENT.hasChildNodes()) {
              return;
            }

            const tableRect = TABLE_ELEMENT.getBoundingClientRect();
            stickyColumns(scope.stickyHeaders, TABLE_ELEMENT, tableRect);

            const thead = _.find(TABLE_ELEMENT.childNodes, ['tagName', 'THEAD']);
            if (_.isUndefined(thead)) {
              // stickyRows and stickyCornerOverlap are not executed if there is no thead
              return;
            }
            const theadRect = thead.getBoundingClientRect();
            stickyRows(NAVBAR_HEIGHT, scope.stickyHeaders, TABLE_ELEMENT, tableRect, thead, theadRect);
            stickyCornerOverlap(NAVBAR_HEIGHT, scope.stickyHeaders, TABLE_ELEMENT, tableRect, thead, theadRect);
            stickyTitle(NAVBAR_HEIGHT, scope.stickyHeaders, TABLE_ELEMENT, tableRect, thead);
          };

          const observerTarget = WindowScrollObserver.addTarget(
            TABLE_ELEMENT,
            createStickyHeaders,
            createStickyHeaders,
            function () {
              deleteStickyTable(scope.stickyHeaders, FIXED_COLUMNS_TABLE_PROPERTY);
              deleteStickyTable(scope.stickyHeaders, FIXED_CORNER_TABLE_PROPERTY);
              deleteStickyTable(scope.stickyHeaders, FIXED_ROWS_TABLE_PROPERTY);
              deleteStickyTable(scope.stickyHeaders, FIXED_TITLE_TABLE_PROPERTY);
            }
          );

          scope.$on('$destroy', () => WindowScrollObserver.removeTarget(observerTarget));
        },
        restrict: 'A'
      };
    }]);
})();
