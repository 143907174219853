/* globals AbstractElementComponent, DateUtils */
(function () {
  'use strict';

  /*
   * fromDate() and toDate() are required to transform the application's time zone to a Date object. Date objects
   * are in the client's local time zone and trying to use the moment methods of moment(date), moment.tz(date, timeZone),
   * or moment.toDate() all perform conversion between time zones, which is not what is desired since the intent is to
   * display date and time values in the applications' time zone.
   */
  function fromDate (target) {
    return DateUtils.of()
      .year(target.getFullYear())
      .month(target.getMonth())
      .date(target.getDate())
      .hour(target.getHours())
      .minute(target.getMinutes())
      .second(0)
      .millisecond(0);
  }

  function toDate (target) {
    const result = new Date();
    result.setFullYear(target.year(), target.month(), target.date());
    result.setHours(target.hour(), target.minute(), 0, 0);
    return result;
  }

  /**
   * Date: this.time
   * String: this.initialTime
   */
  class TimeSelectorController extends AbstractElementComponent {
    _setInitialTime () {
      const setTime = !_.isString(this.initialTime) || _.isEmpty(this.initialTime)
        ? DateUtils.of()
        : DateUtils.of(this.initialTime);
      this.time = toDate(setTime);
      this.changeTime(this.time);
    }

    changeTime (time) {
      if (time instanceof Date) {
        this.onSelectionChange({ time: fromDate(time).toISOString() });
      }
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._setInitialTime();
    }

    $onInit () {
      this._setInitialTime();
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('timeSelector', {
      bindings: {
        initialTime: '<',
        onSelectionChange: '&'
      },
      controller: TimeSelectorController,
      templateUrl: 'templates/components/time-selector.component.html'
    });
})();
