/* globals Configuration */
(function () {
  'use strict';
  const ENVIRONMENT_TEXT_SENTINEL = '*environment*';

  class BannerController {
    $onInit () {
      this.environment = Configuration.environment.current().designator;
      this.position = this.position || 'top-left';
      this.enabled = 'banner-enabled';
      this.displayText = this.text;
      if (this.text === ENVIRONMENT_TEXT_SENTINEL) {
        this.displayText = Configuration.environment.current().designation;
        // Only show the environment banner in non-localhost and non-production environments
        if (Configuration.environment.current().isLocal() || Configuration.environment.current().isProd()) {
          this.enabled = undefined;
          this.displayText = undefined;
        }
      }
    }
  }

  angular.module('application.components')
    .component('banner', {
      bindings: {
        position: '@',
        text: '@'
      },
      controller: BannerController,
      templateUrl: 'templates/components/banner.component.html'
    });
})();
