/* globals AbstractServiceEndpoint, Configuration */
(function () {
  'use strict';

  class ROMScenarioConfigurationService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['request', '$rootScope'];
    }

    constructor (request, $rootScope) {
      super(request, Configuration.services.romScenarioConfiguration);
      this.$rootScope = $rootScope;
    }

    getConstraintTypes (body) {
      return this.aws()
        .for('GetConstraintTypes')
        .withBody(body)
        .post();
    }

    getUploadConstraint (body) {
      return this.aws()
        .for('UploadConstraint')
        .withBody(body)
        .post();
    }

    getListConstraint (body) {
      return this.aws()
        .for('ListConstraints')
        .withBody(body)
        .post();
    }

    getUploadConstraintStatus (body) {
      return this.aws()
        .for('GetUploadConstraintStatus')
        .withBody(body)
        .post();
    }

    generatePresignedURL (body) {
      return this.aws()
        .for('DownloadConstraint')
        .withBody(body)
        .post();
    }

    triggerROM (body) {
      return this.aws()
        .for('TriggerROM')
        .withBody(body)
        .post();
    }

    uploadToS3 (bucket, file, fileName) {
      return this.s3().put(bucket, fileName, file);
    }

    uploadConstraint (author, bucket, constraintType, fileName, key, scope, startDate) {
      const body = {
        author: author,
        constraintType: constraintType,
        fileName: fileName,
        s3ObjectPath: { bucket, key },
        scope: scope,
        startDate: startDate
      };
      return this.getUploadConstraint(body);
    }

    downloadFromS3 (presignedURL) {
      return this.request
        .for(presignedURL)
        .get();
    }

    getPresignedURL (constraintType, fileName, scope, startDate) {
      return this.generatePresignedURL({ constraintType, fileName, scope, startDate });
    }

    getUploadedFilesByType (constraintType, scope, startDate) {
      return this.getListConstraint({ constraintType, scope, startDate });
    }
  }

  angular.module('application.services').service('romScenarioConfiguration', ROMScenarioConfigurationService);
})();
