'use strict';
/**
 * Enables a file input element to correctly bind to an ng-model.
 *
 * Directive must be attached to an input element of type="file" that has an ng-model defined.
 *
 * @name application.directives.bindToFiles
 * @example
 * <input type="file" bind-to-files ng-model="$ctrl.files" ng-change="$ctrl.fileSelectionHandler($ctrl.files)">
 */
angular.module('application.directives')
  .directive('bindToFiles', function () {
    return {
      link: function ($scope, element, attrs, ngModel) {
        // Bind to the input change event and return the files array when it changes
        // Only an input of type="file" has a files array available to it.
        element.on('change', function (event) {
          ngModel.$setViewValue(event.target.files);
        });
        element.on('$destroy', function () {
          element.off();
        });

        // Reset the input if the model is set to a falsey value
        $scope.$watch(
          function () {
            return ngModel.$viewValue;
          },
          function (value) {
            if (!value) {
              element.val('');
            }
          }
        );
      },
      require: 'ngModel',
      restrict: 'A',
      scope: false
    };
  });
