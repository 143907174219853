/* globals Enums */
(function () {
  'use strict';

  const DAYS_IN_WEEK = 7;

  class TimeGranularity {
    /* Determines count of time units based on @timeGranularity and @weeks.
     * If @timeGranularity is weekly, returns time units in weeks.
     * If @timeGranularity is daily, returns time units in days.
     *
     * @param timeGranularity String specifying daily or weekly
     * @param weeks Number of weeks to convert to time units
     *
     * Assumptions:
     *   weeks can never be null.
     *   weeks is a Number.
     *
     * @return number of time units.
     */
    static getTimeUnitCount (timeGranularity, weeks) {
      return _.isNil(timeGranularity) || this.isWeekly(timeGranularity) ? weeks : weeks * DAYS_IN_WEEK;
    }

    /* Determines if @value is daily.
     *
     * @param value String that defines the time granularity
     *
     * @return true if @value is "DAILY", false otherwise.
     */
    static isDaily (value) {
      return value === Enums.TimeGranularity.DAILY;
    }

    /* Determines if @value is weekly.
     *
     * @param value String that defines the time granularity
     *
     * @return true if @value is "WEEKLY", false otherwise.
     */
    static isWeekly (value) {
      return value === Enums.TimeGranularity.WEEKLY;
    }

    /* Converts @value to a corresponding time unit (week / day).
     *
     * @param timeGranularity String that defines the time granularity
     *
     * @return 'week' if @timeGranularity is "WEEKLY", 'day' otherwise.
     */
    static toTimeUnit (timeGranularity) {
      return this.isWeekly(timeGranularity) ? Enums.TimeUnit.WEEK : Enums.TimeUnit.DAY;
    }
  }

  window.TimeGranularity = Object.freeze(TimeGranularity);
})();
