/* globals Configuration, CryptoJS, DateUtils, Enums, Identity, AbstractSourceBasedModel */
(function () {
  'use strict';

  // NOTE on User View Identifiers
  // -----------------------------
  // SIM: https://issues.amazon.com/SOP-5321
  // It is by design that the server doesn't care about the identifier being used for a User View. However, the browser does interpret
  // the path and encode the parts that it must before sending the request to API Gateway. Characters with special meaning
  // (e.g., \, /, :, ?, #) are not encoded, nor interpreted and are treated as parts of the URL to either be stripped or observed by
  // API Gateway. In order to avoid encoding issues the identifier for a view is its MD5 hash of its name as a string of hex characters.
  // To be backwards compatible with existing values that are already defined in Portal Store we check whether the identifier is a valid MD5 hash string
  // and then fallback to the raw name value of the User View as the raw name is the proper identifier (pre-browser encoding) and will allow the user
  // to delete the existing view.

  // TODO: The properties defined by a caller shouldn't need to be listed on this object in order for them to be exposed ('model', 'settings', 'states').
  // We should look at the Proxy class for a dynamic get method that will call through to the underlying source when the attribute isn't defined on the UserView.
  const PROPERTIES = Object.freeze([
    'baseUrl',
    'model',
    'name',
    'owner',
    'settings',
    // Keeping the legacy 'states' value here so it gets translated out for legacy views
    'states'
  ]);

  class UserView extends AbstractSourceBasedModel {
    constructor (source, identifier, isPublic = false) {
      if (!_.has(source, 'name')) {
        // This is a new user view being upserted to Portal Store
        source.name = identifier;
        source.isPublic = isPublic;
        source.updatedDate = DateUtils.format(Enums.DateFormat.IsoDate);
        identifier = CryptoJS.MD5(identifier).toString(); // eslint-disable-line new-cap
      }

      if (!Identity.isMD5Hash(identifier)) {
        // The identifier is a pre-MD5 hashed viewId and the source name value should be used as the identifier so it will match the identifier existing in Portal Store.
        // This view can be deleted but it cannot be updated as any upsert will use an MD5 hash for the identifier.
        identifier = source.name;
      }

      if (!_.isBoolean(source.isPublic)) {
        source.isPublic = false;
      }

      if (_.isUndefined(source.updatedDate)) {
        source.updatedDate = DateUtils.format(Enums.DateFormat.IsoDate);
      }

      // Overwrite baseUrl with the current path if the path has been renamed
      source.baseUrl = Configuration.routing.getPath(source.baseUrl);

      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.identifier = identifier;
    }

    get description () {
      return this.source.description;
    }

    set description (description) {
      this.source.description = description;
      this.source.updatedDate = DateUtils.format(Enums.DateFormat.IsoDate);
    }

    get isPublic () {
      return this.source.isPublic;
    }

    set isPublic (isPublic) {
      this.source.isPublic = isPublic;
      this.source.updatedDate = DateUtils.format(Enums.DateFormat.IsoDate);
    }

    get updatedDate () {
      return this.source.updatedDate;
    }

    isMatch (baseUrl) {
      return this.baseUrl === baseUrl;
    }
  }

  window.UserView = Object.freeze(UserView);
})();
