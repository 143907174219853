/* globals DateUtils, Ready */
(function () {
  'use strict';
  const PREFERENCE_STATUS = Object.freeze({
    IN_USE: 'success',
    NOT_IN_USE: 'default'
  });
  const PROMOTE_CONFIRMATION_MESSAGE = 'Are you sure you want to promote your <strong>Personal</strong> preferences to <strong>Default</strong> preferences? Changes will take effect immediately.';

  class PreferencesModalController {
    static get $inject () {
      return ['alerts', '$authentication', 'preferences'];
    }

    constructor (alerts, $authentication, preferences) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.preferences = preferences;
      this.savedPreferences = {
        default: {
          collapsed: false,
          data: {},
          loading: true
        },
        personal: {
          collapsed: false,
          data: {},
          loading: true
        }
      };
      this.state = {
        default: Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.savedPreferences.default.data),
          isLoadingFn: () => this.savedPreferences.default.loading
        }),
        personal: Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.savedPreferences.personal.data),
          isLoadingFn: () => this.savedPreferences.personal.loading
        })
      };
      this.promoteMessage = PROMOTE_CONFIRMATION_MESSAGE;
    }

    _getSavedPreference (type, savedPreference) {
      this.preferences
        .get(type, this.resolve.configuration.hive, this.resolve.configuration.key)
        .then((preferences) => {
          if (!_.isNil(preferences) && (type === this.preferences.DEFAULTS || preferences.arePersonal)) {
            savedPreference.data = preferences;
          }
        })
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to fetch ${type} preferences` }))
        .finally(() => savedPreference.loading = false);
    }

    _loadData () {
      if (_.isUndefined(this.resolve.configuration)) {
        return;
      }
      this._getSavedPreference(this.preferences.USER, this.savedPreferences.personal);
      this._getSavedPreference(this.preferences.DEFAULTS, this.savedPreferences.default);
    }

    isPromoteDisabled () {
      return this.state.personal.isDataEmpty();
    }

    getPersonalPanelClass () {
      return this.state.personal.isDataEmpty() ? PREFERENCE_STATUS.NOT_IN_USE : PREFERENCE_STATUS.IN_USE;
    }

    getDefaultPanelClass () {
      return this.state.personal.isDataEmpty() && !this.state.default.isDataEmpty() ? PREFERENCE_STATUS.IN_USE : PREFERENCE_STATUS.NOT_IN_USE;
    }

    promotePreferences () {
      this.savedPreferences.personal.data.updatedBy = this.$authentication.profile().alias;
      this.savedPreferences.personal.data.updatedOn = DateUtils.epoch();
      this.modalInstance.close(this.savedPreferences.personal.data);
    }

    $onInit () {
      this._loadData();
    }
  }
  angular.module('application.components')
    .component('preferencesModal', {
      bindings: {
        modalInstance: '<',
        resolve: '<'
      },
      controller: PreferencesModalController,
      templateUrl: 'templates/components/preferences-modal.component.html'
    });
})();
