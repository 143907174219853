/* globals AbstractElementComponent, Configuration */
(function () {
  'use strict';
  class TenantLabelController extends AbstractElementComponent {
    _loadData () {
      const tenant = this.tenant || _.get(Configuration.scopes.find(this.scope), 'tenant');
      this.tenantTitle = _.unescape(_.get(tenant, 'name'));
      this.tenantLabel = _.get(tenant, this.type, this.tenantTitle);
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('tenantLabel', {
      bindings: {
        /*
         * @scope String scope portalCode value. Optional if tenant is provided.
         */
        scope: '<',
        /*
         * @tenant Object expected to be instance of class Tenant. Optional if scope is provided.
         */
        tenant: '<',
        /*
         * @type (Optional) String property name on Tenant object that should be used.
         * Possible values: 'name', 'code' or 'id'. Defaults to 'name'.
         */
        type: '@'
      },
      controller: TenantLabelController,
      templateUrl: 'templates/components/tenant-label.component.html'
    });
})();
