/* globals AbstractElementComponent, DateUtils, Enums */
(function () {
  'use strict';
  const ALT_INPUT_FORMATS = Object.freeze(['yyyy/M!/d!', 'M!-d!-yyyy', 'M!/d!/yyyy']);
  const APPLICATION_DATA_EPOCH_DATE = new Date(2015, 0, 1); // 2015-01-01 in the client's local time zone.
  const DEFAULT_INPUT_FORMAT = 'yyyy-M!-d!';

  class AbstractDateSelectorComponent extends AbstractElementComponent {
    static bindings (additionalBindings) {
      return _.defaults(additionalBindings, {
        // isCurrentWeekDisabled is exposed as a Boolean value.
        isCurrentWeekDisabled: '<',
        // isNullable is exposed as a Boolean value and allows for a null value to be selected.
        // Clearing the input or providing an initial date of nil will result in a null selection value.
        isNullable: '<',
        // isWeekSelectionMode is exposed as a Boolean value. If false or nil, day selection mode is used.
        isWeekSelectionMode: '<',
        onSelectionChange: '&'
      });
    }

    static dataEpochDate () {
      return APPLICATION_DATA_EPOCH_DATE;
    }

    adjustDateForWeekSelectionMode (date) {
      if (!this.isWeekSelectionMode) {
        return date;
      }
      const currentSunday = DateUtils.toSunday();
      const newSunday = DateUtils.toSunday(this.fromDate(date));
      if (this.isCurrentWeekDisabled && currentSunday.isSame(newSunday, Enums.TimeUnit.DAY)) {
        newSunday.subtract(1, Enums.TimeUnit.WEEK);
      }
      return this.toDate(newSunday);
    }

    /*
     * fromDate() and toDate() are required to transform the application's time zone to a Date object. Date objects
     * are in the client's local time zone and trying to use the moment methods of moment(date), moment.tz(date, timeZone),
     * or moment.toDate() all perform conversion between time zones, which is not what is desired since the intent is to
     * display date and time values in the applications' time zone.
     */
    fromDate (target) {
      return DateUtils.of()
        .year(target.getFullYear())
        .month(target.getMonth())
        .date(target.getDate())
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);
    }

    /*
     * Converts a date string (YYYY-MM-DD) or moment to a Date object.
     * @fallback must be a Date object
     */
    toDate (target, fallback) {
      if (_.isEmpty(target)) {
        return fallback;
      }
      const moment = DateUtils.of(target);
      const result = new Date();
      result.setFullYear(moment.year(), moment.month(), moment.date());
      result.setHours(0, 0, 0, 0);
      return result;
    }

    /*
     * Must be implemented by a sub-class.
     * Sets up an initial state for all dates like the selector date and configured min/max dates.
     */
    setInitialDates () {
      throw new Error('AbstractDateSelectorComponent: setInitialDates() must be overwritten in a sub-class');
    }

    initConfigurationBindings (additionalBindings) {
      this.configuration = _.defaults(additionalBindings, {
        // JavaScript Date string formats
        altInputFormats: ALT_INPUT_FORMATS,
        format: DEFAULT_INPUT_FORMAT
      });
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this.setInitialDates();
    }

    $onInit () {
      if (_.isNil(this.configuration)) {
        this.initConfigurationBindings();
      }
      this.setInitialDates();
      super.$onInit();
    }
  }

  window.AbstractDateSelectorComponent = Object.freeze(AbstractDateSelectorComponent);
})();

