/* globals Enumeration, Enums, Identity */
(function () {
  'use strict';
  const AUTO_CLOSE_TIMEOUT_DISABLED = 0;
  const DEFAULT_AUTO_CLOSE_TIMEOUT = 3600000; // 60 minutes
  const INFO_SUCCESS_AUTO_CLOSE_TIMEOUT = 30000; // 30 seconds

  function computeAutoCloseTimeout (value, alertType, importance) {
    if (value === AUTO_CLOSE_TIMEOUT_DISABLED
      || alertType === Enums.AlertType.DANGER
      || importance === Enums.AlertImportance.INTERRUPT
    ) {
      // Disable the auto-close behavior
      return undefined;
    }
    if (!_.isFinite(value) || value <= 0) {
      return _.includes([Enums.AlertType.INFO, Enums.AlertType.SUCCESS], alertType) ?
        INFO_SUCCESS_AUTO_CLOSE_TIMEOUT :
        DEFAULT_AUTO_CLOSE_TIMEOUT;
    }
    return value;
  }

  class Alert {
    constructor (type, data, importance) {
      this.details = data.details;
      this.id = data.id || Identity.uuid();
      this.importance = Enumeration.isMemberValueOrDefault(Enums.AlertImportance, importance, Enums.AlertImportance.NORMAL);
      this.message = data.message || data;
      this.occurrences = 1;
      this.type = `alert-${Enumeration.isMemberValueOrDefault(Enums.AlertType, type, Enums.AlertType.INFO)}`;
      this.autoCloseTimeout = computeAutoCloseTimeout(data.autoClose, type, importance);
    }

    static create (type, data, importance) {
      return new Alert(type, data, importance);
    }

    equals (compare) {
      return !_.isNil(compare) && this.id === compare.id;
    }

    hasMultipleOccurrences () {
      return this.occurrences > 1;
    }

    like (compare) {
      return !_.isNil(compare) &&
        this.importance === compare.importance &&
        this.message === compare.message &&
        this.type === compare.type;
    }
  }

  window.Alert = Object.freeze(Alert);
})();
