/* globals AbstractSourceBasedModel, DataPipelineWorkflow */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'datasetDate',
    'delayedWorkflows',
    'isDelayed',
    'scope',
    'simTicketingLink'
  ]);

  class DataPipelineDelayStatus extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.delayedWorkflows = this.delayedWorkflows.map((delayedWorkflow) => DataPipelineWorkflow.create(delayedWorkflow));
    }

    get delayMessage () {
      const delayMessage = `There are known data delays for <strong>${this.datasetDate}</strong>.`;
      if (_.isEmpty(this.simTicketingLink)) {
        return delayMessage;
      }
      const ttMessage = ` Please refer to the <a href="${this.simTicketingLink}" target="_blank" rel="noopener">Trouble Ticket</a> for more details.`;
      return `${delayMessage}${ttMessage}`;
    }
  }

  window.DataPipelineDelayStatus = Object.freeze(DataPipelineDelayStatus);
})();
