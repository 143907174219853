/* globals AbstractSourceBasedModel, Enums, Grain, GrainMappingGroup */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    {
      deserialize: (definition) => _.map(definition, (value) => Grain.known[value]),
      key: 'granularityKeys'
    },
    'groupType',
    'mapping'
  ]);

  const TEMPLATE = Object.freeze({
    granularityKeys: [],
    groupType: '',
    mapping: []
  });

  class GrainMapping extends AbstractSourceBasedModel {
    constructor (source) {
      super(_.defaults(source, TEMPLATE), PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.mapping = _.map(this.mapping, (grainMappings, name) => GrainMappingGroup.create(this.granularityKeys, name, grainMappings));
    }

    isValid () {
      return !_.isEmpty(this.granularityKeys) &&
        !_.isEmpty(this.groupType) &&
        !_.isEmpty(this.mapping);
    }
  }
  window.GrainMapping = Object.freeze(GrainMapping);
})();
