(function () {
  'use strict';

  class Configuration {
    defineProperty (key, hive) {
      if (_.has(this, key)) {
        throw new Error(`Configuration: key is already defined: ${key}`);
      }
      _.set(this, key, Object.freeze(hive));
      return this;
    }
  }

  window.Configuration = new Configuration();
})();
