/* globals Binding, DateUtils, Identity, Ready */
(function () {
  'use strict';
  const PERSONAL_PREFERENCE_ICON = 'star';
  const DEFAULT_PREFERENCE_ICON = 'star-o';
  const PREFERENCES_SET_MESSAGE = 'Your selections were successfully saved as your personal preference.';
  const PREFERENCES_UNSET_MESSAGE = 'Successfully removed your personal preference. Please refresh the page to apply the default preference.';

  class SavePreferencesController {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', 'preferences'];
    }

    constructor (alerts, $authentication, overlay, preferences) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.preferences = preferences;
      this.savedPersonalPreference = [];
      this.savedPreference = [];
      this.selectedItems = [];
      this.state = Ready.create();
    }

    _areCurrentSelectionsPersonal () {
      return !_.isEmpty(this.savedPersonalPreference) && !_.isEmpty(this.selectedItems) &&
        _.isEqual(this.savedPersonalPreference.sort(), this.selectedItems.sort());
    }

    _getIdentities (items) {
      if (_.isNil(items)) {
        return items;
      }
      if (!_.isSet(items) && !Array.isArray(items)) {
        return Identity.of(items);
      }
      const arrayOfItems = _.isSet(items) ? Array.from(items) : items;
      return _.map(arrayOfItems, (item) => Identity.of(item));
    }

    _loadData () {
      if (_.isUndefined(this.configuration)) {
        return;
      }
      this.preferences
        .get(this.preferences.USER, this.configuration.hive, this.configuration.key)
        .then((preferences) => {
          this.savedPreference = [];
          if (!_.isNil(preferences)) {
            this.savedPreference = preferences.selections;
            if (preferences.arePersonal) {
              this.savedPersonalPreference = preferences.selections;
            }
          }

          this.selectedItems = this._getIdentities(this.selections);
          this.preferencesIcon = this._areCurrentSelectionsPersonal() ? PERSONAL_PREFERENCE_ICON : DEFAULT_PREFERENCE_ICON;
        });
    }

    areCurrentSelectionsSameAsPreferences () {
      return !_.isEmpty(this.savedPreference) && !_.isEmpty(this.selectedItems) &&
        _.isEqual(this.savedPreference.sort(), this.selectedItems.sort());
    }

    togglePersonalPreferences () {
      if (this._areCurrentSelectionsPersonal()) {
        this.overlay.show('Removing personal preference');
        this.preferences
          .unset(this.preferences.USER, this.configuration.hive, this.configuration.key)
          .then(() => {
            this.alerts.success(PREFERENCES_UNSET_MESSAGE);
            this.preferencesIcon = DEFAULT_PREFERENCE_ICON;
            this.savedPreference = [];
            this.savedPersonalPreference = [];
          })
          .finally(() => this.overlay.hide());
        return;
      }
      const preferencesData = {
        selections: this.selectedItems,
        updatedBy: this.$authentication.profile().alias,
        updatedOn: DateUtils.epoch()
      };
      this.overlay.show('Saving personal preference');
      this.preferences
        .set(this.preferences.USER, preferencesData, this.configuration.hive, this.configuration.key)
        .then(() => {
          this.alerts.success(PREFERENCES_SET_MESSAGE);
          this.preferencesIcon = PERSONAL_PREFERENCE_ICON;
          this.savedPreference = preferencesData.selections;
          this.savedPersonalPreference = preferencesData.selections;
        })
        .finally(() => this.overlay.hide());
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (Binding.changes.has(changes.configuration)) {
        this._loadData();
        return;
      }

      this.selectedItems = this._getIdentities(this.selections);
      this.preferencesIcon = this._areCurrentSelectionsPersonal() ? PERSONAL_PREFERENCE_ICON : DEFAULT_PREFERENCE_ICON;
    }

    $onInit () {
      this._loadData();
      this.state.isInitialized(true);
    }
  }

  angular.module('application.components')
    .component('savePreferences', {
      bindings: {
        /*
         * @configuration - object for specifying the hive and key for saving personal preferences.
         *   {
         *      hive: 'String',
         *      key: 'String'
         *   }
         */
        configuration: '<',
        /*
         * @selections is an array or set of strings (IDs) that are currently selected.
         */
        selections: '<',
        /*
         * @selectionSize is the number of items that are currently selected. This is useful in triggering $onChanges
         * when 'selections' array/set does not change reference but items do get inserted/removed.
         */
        selectionSize: '<'
      },
      controller: SavePreferencesController,
      templateUrl: 'templates/components/save-preferences.component.html'
    });
})();
