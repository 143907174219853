/* globals AbstractServiceEndpoint, Configuration, Warehouse */
(function () {
  'use strict';
  const ADD_OPERATION = 'ADD';
  const DELETE_OPERATION = 'DELETE';
  const UPSERT_OPERATION = 'UPSERT';
  const WAREHOUSES_GROUP_CHANGE_EVENT_KEY = 'warehouses-group-change';
  const WAREHOUSES_WAREHOUSE_CHANGE_EVENT_KEY = 'warehouses-warehouse-change';

  class WarehouseRequest extends AbstractServiceEndpoint {
    constructor (request, $rootScope, warehouse, bastion) {
      super(request, Configuration.services.warehouseRegistry);
      this.$rootScope = $rootScope;
      this.warehouse = warehouse;
      this.bastion = bastion;
    }

    delete () {
      return this.aws()
        .withScope(this.warehouse.scope)
        .for('warehouse', this.warehouse.warehouseId)
        .withParams({ version: this.warehouse.version })
        .delete()
        .then((warehouse) => {
          warehouse = Warehouse.create(warehouse);
          this.$rootScope.$broadcast(WAREHOUSES_WAREHOUSE_CHANGE_EVENT_KEY, warehouse, DELETE_OPERATION);
          return warehouse;
        });
    }

    get () {
      return this.aws()
        .withVersion('v2')
        .withScope(this.warehouse.scope)
        .for('warehouse', this.warehouse.warehouseId)
        .get()
        .then((warehouse) => Warehouse.create(warehouse));
    }

    list (options) {
      return this.aws()
        .withVersion('v2')
        .withScope(_.get(options, 'scope'))
        .for('warehouse')
        .withParams(options)
        .get()
        .then((warehouses) => _.map(warehouses, (warehouse) => Warehouse.create(warehouse)));
    }

    upsert () {
      return this.aws()
        .withVersion('v2')
        .for('warehouse')
        .withBody(this.warehouse.source)
        .post()
        .then((warehouse) => {
          warehouse = Warehouse.create(warehouse);
          this.$rootScope.$broadcast(WAREHOUSES_WAREHOUSE_CHANGE_EVENT_KEY, warehouse, UPSERT_OPERATION);
          return warehouse;
        });
    }
  }

  class WarehouseRegistryService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['bastion', 'request', '$rootScope'];
    }

    constructor (bastion, request, $rootScope) {
      super(request, Configuration.services.warehouseRegistry);
      this.$rootScope = $rootScope;
      this.bastion = bastion;
    }

    $onGroupChange (handler) {
      return this.$rootScope.$on(WAREHOUSES_GROUP_CHANGE_EVENT_KEY, handler);
    }

    $onWarehouseChange (handler) {
      return this.$rootScope.$on(WAREHOUSES_WAREHOUSE_CHANGE_EVENT_KEY, handler);
    }

    addGroup (groupId, options) {
      return this.aws()
        .withScope()
        .for('group', groupId)
        .withParams(options)
        .withBody({})
        .put()
        .then((group) => {
          this.$rootScope.$broadcast(WAREHOUSES_GROUP_CHANGE_EVENT_KEY, group, ADD_OPERATION);
          return group;
        });
    }

    allGroups (options) {
      return this.aws().for('group').withParams(options).get();
    }

    deleteGroup (groupId, options) {
      return this.aws()
        .withScope()
        .for('group', groupId)
        .withParams(options)
        .delete()
        .then((group) => {
          this.$rootScope.$broadcast(WAREHOUSES_GROUP_CHANGE_EVENT_KEY, group, DELETE_OPERATION);
          return group;
        });
    }

    groups (options) {
      return this.aws()
        .withScope(_.get(options, 'scope'))
        .for('group')
        .withParams(options)
        .get();
    }

    warehouse (warehouse) {
      return new WarehouseRequest(this.request, this.$rootScope, warehouse, this.bastion);
    }
  }

  angular.module('application.services').service('warehouses', WarehouseRegistryService);
})();
