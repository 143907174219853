(function () {
  'use strict';
  class ScrollToController {
    static get $inject () {
      return ['$anchorScroll'];
    }

    constructor ($anchorScroll) {
      this.$anchorScroll = $anchorScroll;
    }

    scrollTo () {
      return this.$anchorScroll(this.destination);
    }
  }

  angular.module('application.components')
    .component('scrollTo', {
      bindings: {
        destination: '@',
        isVisible: '&'
      },
      controller: ScrollToController,
      templateUrl: 'templates/components/scroll-to.component.html'
    });
})();
