/* globals Enumeration, Enums, Mapper, Validate, XLSX */
(function () {
  'use strict';
  // ====== Transformers: More Than Meets The Eye ======
  //                       |_____
  //                       X_____\
  //               .-^-.  ||*| |*||  .-^-.
  //              /_\_/_\_|  |_|  |_/_\_/_\
  //              ||(@)| __\_____/__ |(@)||
  //              \/| | |::|\```/|::| | |\/
  //              /`---_|::|-+-+-|::|_---'\
  //             / /  \ |::|-|-|-|::| /  \ \
  //            /_/   /|`--'-+-+-`--'|\   \_\
  //            | \  / |===/_\ /_\===| \  / |
  //            |  \/  /---/-/-\-\  o\  \/  |
  //            | ||| | O / /   \ \   | ||| |
  //            | ||| ||-------------|o|||| |
  //            | ||| ||----\ | /----|o|||| |
  //            | _|| ||-----|||-----|o|||_ |
  //            \/|\/  |     |||     |o|\/|\/
  //            \_o/V  |----|||||----|-'V\o_/
  //            VVVV   |##  |   |  ##|   VVVV
  //                   |----|   |----|
  //                   ||__ |   | __||
  //                  [|'  `|] [|'  `|]
  //                  [|`--'|] [|`--'|]
  //                  /|__| |\ /| |__|\
  //                  ||  | || || |  ||
  //                  ||__|_|| ||_|__||
  //                  ||    || ||    ||
  //                  \|----|/ \|----|/
  //                  /______\ /______\
  //                  |__||__| |__||__|
  // ===================================================
  const CONTENT_FORMAT = Enumeration.create('ARRAY_OF_ARRAYS', 'JSON').asExactValue('aoa_to_sheet', 'json_to_sheet');
  const DOCUMENT_FORMAT_HANDLER = Object.freeze({
    [Enums.DocumentFormat.CSV]: 'convertToWorksheet',
    [Enums.DocumentFormat.FlatXLSX]: 'convertToFlatWorkbook',
    [Enums.DocumentFormat.XLSX]: 'convertToWorkbook'
  });

  class AbstractTransformerService {
    static get $inject () {
      return ['alerts', '$q'];
    }

    constructor (alerts, $q, workbookMetadataDefinition) {
      if (_.isNil(alerts)) {
        throw new Error('AbstractTransformer: alerts must not be nil');
      }

      if (_.isNil($q)) {
        throw new Error('AbstractTransformer: $q must not be nil');
      }

      this.alerts = alerts;
      this.$q = $q;
      this.workbookMetadataDefinition = workbookMetadataDefinition;
    }

    /* Constructs a Metadata Definition property
     *
     * @param key the key property
     * @param deserialize deserialize function, default to JSON.parse
     * @param serialize serialize function, defaults to JSON.stringify
     */
    static metadataProperty (key, deserialize = JSON.parse, serialize = JSON.stringify) {
      return { deserialize, key, serialize };
    }

    get contentFormats () {
      return CONTENT_FORMAT;
    }

    /* Adds a sheet to a provided workbook
     *
     * @param workbook the workbook object to be mutated
     * @param sheetName desired string name of the new sheet
     * @param content content object containing the various data to be defined in the new sheet
     * @param contentFormat format string that indicates the structure of the provided content
     */
    addSheetToWorkbook (workbook, sheetName, content, contentFormat) {
      if (_.isEmpty(workbook)) {
        throw new Error('AbstractTransformer: workbook must not be empty');
      }

      if (_.isNil(sheetName)) {
        throw new Error('AbstractTransformer: sheetName must not be nil');
      }

      if (_.isEmpty(content)) {
        throw new Error('AbstractTransformer: content must not be empty');
      }

      if (!Enumeration.isMemberValue(CONTENT_FORMAT, contentFormat)) {
        throw new Error('AbstractTransformer: contentFormat must be a recognized format');
      }

      XLSX.utils.book_append_sheet(workbook, XLSX.utils[contentFormat](content), sheetName);

      if (sheetName === Enums.XlsxSheet.METADATA) {
        XLSX.utils.book_set_sheet_visibility(workbook, sheetName, XLSX.utils.consts.SHEET_HIDDEN);
      }
    }

    /* Adds a metadata sheet to a provided workbook
     *
     * @param workbook the workbook object to be mutated
     * @param metadataObject the metadata object to collect the metadata from
     */
    addWorkbookMetadata (workbook, metadataObject) {
      if (_.isEmpty(workbook)) {
        throw new Error('AbstractTransformer: workbook must not be empty');
      }

      if (_.isNil(metadataObject)) {
        throw new Error('AbstractTransformer: metadataObject must not be nil');
      }

      if (_.isNil(this.workbookMetadataDefinition)) {
        throw new Error('AbstractTransformer: workbookMetadataDefinition must not be nil');
      }

      this.addSheetToWorkbook(workbook, Enums.XlsxSheet.METADATA, [Mapper.collect(metadataObject, this.workbookMetadataDefinition)], CONTENT_FORMAT.JSON);
    }

    /* PURE VIRTUAL - Should be implemented by sub-class.
     * Converts dataPackages into a flat-workbook, structured according to the provided suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToFlatWorkbook () {
      throw new Error('AbstractTransformer: convertToFlatWorkbook() must be overwritten in a sub-class');
    }

    /* PURE VIRTUAL - Should be implemented by sub-class.
     * Converts dataPackages into a workbook, structured according to the provided suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorkbook () {
      throw new Error('AbstractTransformer: convertToWorkbook() must be overwritten in a sub-class');
    }

    /* PURE VIRTUAL - Should be implemented by sub-class.
     * Converts dataPackages into a worksheet, structured according to the provided suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorksheet () {
      throw new Error('AbstractTransformer: convertToWorksheet() must be overwritten in a sub-class');
    }

    /* Validates and extracts the metadata from a workbook, based on the provided definitions
     *
     * @param workbook the workbook providing the metadata to extract
     * @return the extracted properties
     */
    extractWorkbookMetadata (workbook) {
      if (_.isEmpty(workbook)) {
        throw new Error('AbstractTransformer: workbook must not be empty');
      }

      if (_.isNil(this.workbookMetadataDefinition)) {
        throw new Error('AbstractTransformer: workbookMetadataDefinition must not be nil');
      }

      const sheet = _.head(XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.METADATA], { raw: true }));
      if (_.isNil(sheet)) {
        this.alerts.danger('The XLSX file is missing the required metadata sheet. Please download a new template and re-upload.');
        return [];
      }

      const missingWorkbookMetadata = Validate.checkMissingProperties(sheet, _.map(this.workbookMetadataDefinition, 'key'));
      if (!_.isEmpty(missingWorkbookMetadata)) {
        missingWorkbookMetadata.forEach((missingProperty) =>
          this.alerts.danger(`The XLSX file is missing the required "${missingProperty}" property. Please make sure you are uploading a valid file.`));
        return [];
      }
      return Mapper.apply(sheet, {}, this.workbookMetadataDefinition);
    }

    /* PURE VIRTUAL - Should be implemented by sub-class.
     * Derives an array of packages from a workbook
     *
     * @param workbook the workbook to be converted into packages
     * @return a list of packages that can be displayed as grids
     */
    toPackages () {
      throw new Error('AbstractTransformer: toPackages() must be overwritten in a sub-class');
    }

    /* Maps a UI format string to a document type object then derives
     * a workbook or worksheet from any number of metric family grids.
     *
     * @param format the format string.
     * @param packages an array of packages
     * @param headerSuppliers an array of objects that describe how to generate the header rows
     * @param bodySuppliers an array of objects that describe how to generate the body rows
     * @param options a hash of optional arguments. Supports:
     *        - metadata Boolean set to true in order to add the metadata information to the Workbook
     * @return the workbook to be exported.
     *
     * Assumptions:
     *   the length of packages is at least 1
     *   all packages have the same number of columns
     */
    toDocument (format, packages, headerSuppliers, bodySuppliers, options) {
      const handlerKey = DOCUMENT_FORMAT_HANDLER[format];
      if (_.isNil(handlerKey)) {
        throw new Error(`AbstractTransformer: format is not supported: "${format}"`);
      }
      return this.$q.resolve(this[handlerKey](packages, headerSuppliers, bodySuppliers, options));
    }
  }

  window.AbstractTransformerService = Object.freeze(AbstractTransformerService);
})();
