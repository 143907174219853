(function () {
  'use strict';
  const DEVELOPER_TOOLS = Object.freeze([
    'Fill Rate Generator'
  ]);

  class DeveloperToolsController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.$scope = $scope;
      this.$onInit();
    }

    $onInit () {
      this.$scope.model = {
        developerTools: {
          list: DEVELOPER_TOOLS,
          selected: _.head(DEVELOPER_TOOLS)
        }
      };

      this.$scope.actions = {
        changeDeveloperTool: (tool) => {
          this.$scope.model.developerTools.selected = tool;
        }
      };
    }
  }
  angular.module('application.controllers').controller('DeveloperToolsController', DeveloperToolsController);
})();
