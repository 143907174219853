/* globals AbstractSourceBasedModel, Configuration, Enums */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'datasetDate',
    'delayEmailSent',
    'inboundDelayed',
    'outboundDelayed',
    'scope',
    'scopedWorkflowStatusList',
    'ticketId'
  ]);

  class DataDelayCheckStatus extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    get delayMessage () {
      const delayMessage = `There are known data delays for <strong>${this.datasetDate}</strong>.`;
      if (_.isEmpty(this.ticketId)) {
        return delayMessage;
      }
      const ttLink = Configuration.endpoints.staticOf.ttLink(this.ticketId);
      const ttMessage = ` Please refer to the <a href="${ttLink}" target="_blank" rel="noopener">Trouble Ticket</a> for more details.`;
      return `${delayMessage}${ttMessage}`;
    }

    isDelayed (scope) {
      const workflows = _.head(_.filter(this.scopedWorkflowStatusList, (scopedWorkflows) => scopedWorkflows.scope === scope));
      return _.some(_.get(workflows, 'workflowList'), (workflow) => workflow.status === Enums.DataDelayCheckStatus.IN_DELAY);
    }
  }

  window.DataDelayCheckStatus = Object.freeze(DataDelayCheckStatus);
})();
