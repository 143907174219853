/* globals AbstractChartComponent */
(function () {
  'use strict';
  // TODO: Move to PieChart model is available/ready for use.
  const DEFAULT_OPTIONS = Object.freeze({
    chart: {
      donut: false,
      donutLabelsOutside: true,
      labelSunbeamLayout: false,
      labelType: 'percent',
      margin: {
        bottom: 40,
        left: 20,
        right: 20,
        top: 40
      },
      type: AbstractChartComponent.chartType.PIE_CHART,
      x: (d) => d.key,
      y: (d) => d.value
    }
  });

  class PieChartController extends AbstractChartComponent {
    constructor () {
      super();
    }

    _loadData () {
      this.validateConfiguration(this.configuration, AbstractChartComponent.chartType.PIE_CHART);
      this.options = _.defaultsDeep({}, this.configuration, DEFAULT_OPTIONS, this.getDefaultChartOptions());
      this.setTitleSubtitleCaption(this.options, this.configuration);
    }
  }

  angular.module('application.components')
    .component('pieChart', {
      /*
       * @data an array of objects containing:
       *   color (optional) the color code string to use for this key.
       *   key the unique key string representing the dataset. Also used in the chart legend.
       *   value the value corresponding to the above key.
       *   Example:
       *   [
       *     { color: '#2ca02c', key: 'Group 1', value: 10 },
       *     { color: '#77BDFF', key: 'Group 2', value: 20 },
       *     ...
       *   ]
       *
       * @configuration an object containing:
       *   chart (optional) an object containing chart properties to be overridden.
       *      donut (optional) the boolean to display Pie chart as a pie or a donut. Defaults to false.
       *      donutLabelsOutside (optional) the boolean to display labels inside or outside the Pie/Donut. Defaults to true.
       *      labelSunbeamLayout (optional) the boolean to display labels like sunbeams or not. Defaults to false.
       *      labelType (optional) the type of label to display. Can be "key", "value", "percent" or a function. Defaults to "percent".
       *   caption (optional) the caption of the chart. Defaults to ''.
       *   subtitle (optional) the subtitle of the chart. Defaults to ''.
       *   title (optional) the title of the chart. Defaults to ''.
       *
       * @showData (Optional) When true, a Data link is available to view the data used by the chart. Otherwise, no Data link is displayed.
       *
       * @showOptions (Optional) When true, a Configuration link is available to view the configuration used by the chart. Otherwise, no Configuration link is displayed.
       */
      bindings: AbstractChartComponent.bindings(),
      controller: PieChartController,
      templateUrl: AbstractChartComponent.templateUrl
    });
})();
