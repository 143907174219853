/* globals AbstractServiceEndpoint, Configuration,  */
(function () {
  'use strict';
  const FC_INVENTORY_PROCESS_TYPE = 'FC_Inventory';

  class GasTankAllocationService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['request'];
    }

    constructor (request) {
      super(request, Configuration.services.gasTankAllocationService);
    }

    triggerAllocation (plan) {
      return this.aws()
        .for('gastank')
        .withBody({
          planDate: plan.date,
          planDraft: plan.draft,
          planType: plan.type,
          // Currently, only the FC inventory process type is supported hence the harded-coded literal
          // here. In the future, a list of process types and their respective plans should be made
          // avalibale by the service itself.
          processType: FC_INVENTORY_PROCESS_TYPE,
          scopeCode: plan.scope
        })
        .post();
    }
  }

  angular.module('application.services').service('gasTankAllocationService', GasTankAllocationService);
})();
