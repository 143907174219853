/* globals AbstractPackagerService, Configuration, DataCell, DataRow, DataPackage, Enums */
(function () {
  const FORECAST = 'Forecast';

  class NetworkPlannerPackagerService extends AbstractPackagerService {

    static get $inject () {
      return ['$q'];
    }

    _addDraft (records, draft) {
      _.forEach(records, (row) => row.granularity.draft = draft);
    }

    _constructRecords (baseline, edits, comparison, configuration) {
      const granularity = configuration.granularity.grains;
      const groupBy = _.map(granularity.values(), 'id');
      groupBy.push(Enums.GrainIdentity.draft);
      const granularityOrder = granularity.values().map((grain) => `granularity.${grain.id}`);
      return _([baseline, edits, comparison])
        .reject(_.isNil)
        .flatMap((records) => this._groupBy(records, groupBy, configuration))
        .sortBy([...granularityOrder, (row) => Configuration.networkPlannerDraftOrder[row.granularity.draft]]).value();
    }

    _isActual (index) {
      return index < Configuration.networkPlannerDateRange.actuals;
    }

    _groupBy (records, groupBy, configuration) {
      return _(records)
        .groupBy((row) => _.transform(groupBy, (granularity, grain) => granularity.push(row.granularity[grain]), []))
        .map((rows) => DataRow.create({
          dataType: _.head(rows).dataType,
          granularity: _.transform(groupBy, (granularity, grain) => granularity[grain] = _.head(rows).granularity[grain], {}),
          metric: _.head(rows).metric,
          values: _.map(_.head(rows).cells, (dataCell, index) => {
            let value = _.sumBy(rows, (row) => row.cells[index].value);
            value = configuration.plan.type === Enums.Plan.PlanType.FILL_RATE_ORIGIN_LAG_NIG_WEEKLY_PLAN ?
              _.round(value, Configuration.networkPlannerSettings.decimalPlacesRatio) :
              _.round(value);
            return DataCell.create({
              dataSource: dataCell.dataSource,
              dataType: this._isActual(index) ? Enums.DataType.ACTUAL : dataCell.dataType,
              value: value
            });
          })
        })).value();
    }

    _makeEditable (editRecords) {
      this._addDraft(editRecords, Configuration.networkPlannerDrafts.EDITS);
      editRecords.forEach((row) => {
        row.dataType = Enums.DataPackage.RecordType.EDITS;
        row.cells.forEach((cell, index) => {
          if (!this._isActual(index)) {
            cell.dataType = Enums.DataPackage.RecordType.EDITS;
            cell.type = FORECAST;
          }
        });
      });
    }

    constructor ($q) {
      super($q);
    }

    /**
     * Groups baseline, edits, and comparison dataPackage records
     *
     * @param baselineRecords baseline draft plan records
     * @param editRecords editable records
     * @param comparisonRecords prefinal draft plan records to compare to edits/baseline
     * @param configuration is an object containing request metadata:
     *  @filters: the grain object (id, displayName) that the DataPackage is filtered on
     *  @granularity The Granularity of the DataPackage (grains, timeGranularity)
     *  @plan The PlanMetadata of the DataPackage
     *  @splitBy: the grain that the Network Planning data packages will be split by
     * @returns DataPackage containing baseline, edit and comparison records
     */
    collect (baselineRecords, editRecords, comparisonRecords, configuration) {
      this._addDraft(baselineRecords, Configuration.networkPlannerDrafts.BASELINE);
      this._makeEditable(editRecords, configuration);
      this._addDraft(comparisonRecords, Configuration.networkPlannerDrafts.COMPARISON);
      const records = this._constructRecords(baselineRecords, editRecords, comparisonRecords, configuration);
      const granularity = _.cloneDeep(configuration.granularity);
      granularity.grains.addDraftGrain();
      return DataPackage.create({
        dates: configuration.dates,
        filters: {
          [configuration.splitBy]: configuration.filter.id
        },
        granularity: granularity,
        plan: configuration.plan,
        records: records,
        title: configuration.filter.displayName,
        totals: []
      }, this.$q);
    }
  }

  angular.module('application.services').service('networkPlannerPackager', NetworkPlannerPackagerService);
})();
