/* globals AbstractPhonetoolComponent */
(function () {
  'use strict';

  class PhonetoolPictureController extends AbstractPhonetoolComponent {
    _loadData () {
      this.userName = _.isString(this.userName) ? this.userName : this.userAlias;
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('phonetoolPicture', {
      bindings: AbstractPhonetoolComponent.bindings(),
      controller: PhonetoolPictureController,
      templateUrl: 'templates/components/phonetool-picture.component.html'
    });
})();
