/* globals AbstractPackagerService, Comparison, DataPackage, DataRow, DateUtils, Enums, Granularities, Grid, Name */
(function () {
  'use strict';

  const AGGREGATE_RECORDS = 'aggregateRecords';
  const RECORDS = 'records';

  const getMetricFamilyData = function (metricFamily, startDate, endDate, filters, granularity, aggregateGroupByGrains, plan, isSubtotal) {
    const namespace = this.metricsService.resolveNamespace(plan.source.type);
    const body = {
      aggregateGroupBy: aggregateGroupByGrains,
      asOfTime: plan.lastUpdatedAt,
      filters: filters,
      groupBy: granularity.grains.values(),
      periodEndDate: endDate,
      periodStartDate: startDate,
      planMetadata: [plan.source]
    };
    if (!isSubtotal) {
      return [this.metricsService.metricFamily(metricFamily, namespace, granularity.plan, granularity.time, body)];
    }
    const subtotalGrains = Granularities.create();
    return granularity.grains.values().map((grain, index) => {
      subtotalGrains.addGrain(index, grain);
      body.groupBy = subtotalGrains.values();
      body.aggregateGroupBy = index === 0 ? aggregateGroupByGrains : undefined;
      return this.metricsService.metricFamily(metricFamily, namespace, granularity.plan, granularity.time, body);
    });
  };

  const fetchMetrics = function (dates, metricFamily, configuration, enabled) {
    const forecastsFamily = {
      id: metricFamily.metricFamilyId,
      metrics: _.filter(metricFamily.metrics, { type: 'Forecast' })
    };
    const actualsFamily = {
      id: metricFamily.metricFamilyId,
      metrics: _.filter(metricFamily.metrics, { type: 'Actual' })
    };
    const filters = configuration.filters;
    const granularity = configuration.granularity;
    const aggregateGroupByGrains = configuration.aggregateGroupByGrains.values().map((grain) => grain.id);
    const promises = {};

    const actualsStartDate = _.head(dates);
    const actualsEndDate = _.nth(dates, configuration.actuals.dateRangeLength - 1);

    if (enabled.forecasts && !_.isEmpty(forecastsFamily.metrics)) {
      getMetricFamilyData.call(
        this,
        forecastsFamily,
        configuration.forecast.plan.startDate,
        configuration.forecast.plan.endDate,
        filters,
        granularity,
        aggregateGroupByGrains,
        configuration.forecast.plan,
        enabled.subtotals
      ).forEach((promise, index) => promises[`forecast${index}`] = promise);

      _.flatten(configuration.forecastComparisons.map((comparison) =>
        getMetricFamilyData.call(
          this,
          forecastsFamily,
          comparison.plan.startDate,
          comparison.plan.endDate,
          filters,
          granularity,
          aggregateGroupByGrains,
          comparison.plan,
          enabled.subtotals
        ))).forEach((promise, index) => promises[`forecastComparisons${index}`] = promise);
    }

    if (enabled.actuals && !_.isEmpty(actualsFamily.metrics)) {
      getMetricFamilyData.call(
        this,
        actualsFamily,
        actualsStartDate,
        actualsEndDate,
        filters,
        granularity,
        aggregateGroupByGrains,
        configuration.forecast.plan,
        enabled.subtotals
      ).forEach((promise, index) => promises[`actuals${index}`] = promise);

      _.flatten(configuration.actualsComparisons.map((comparison) =>
        getMetricFamilyData.call(
          this,
          forecastsFamily,
          actualsStartDate,
          actualsEndDate,
          filters,
          granularity,
          aggregateGroupByGrains,
          comparison.plan,
          enabled.subtotals
        ))).forEach((promise, index) => promises[`actualsComparisons${index}`] = promise);
    }

    if (enabled.includeYoYActuals.PY) {
      const actualsStartDatePY = DateUtils.priorYearDate(actualsStartDate);
      const actualsEndDatePY = DateUtils.fromOffset(actualsStartDatePY, dates.length - 1, Enums.TimeUnit.WEEK);
      getMetricFamilyData.call(
        this,
        actualsFamily,
        actualsStartDatePY,
        actualsEndDatePY,
        filters,
        granularity,
        aggregateGroupByGrains,
        configuration.forecast.plan,
        enabled.subtotals
      ).forEach((promise, index) => promises[`actualsPYoY${index}`] = promise);
    }
    if (enabled.includeYoYActuals.PPY) {
      const actualsStartDatePPY = DateUtils.priorYearDate(actualsStartDate, 2);
      const actualsEndDatePPY = DateUtils.fromOffset(actualsStartDatePPY, dates.length - 1, Enums.TimeUnit.WEEK);
      getMetricFamilyData.call(
        this,
        actualsFamily,
        actualsStartDatePPY,
        actualsEndDatePPY,
        filters,
        granularity,
        aggregateGroupByGrains,
        configuration.forecast.plan,
        enabled.subtotals
      ).forEach((promise, index) => promises[`actualsPPYoY${index}`] = promise);
    }

    return this.$q.all(promises);
  };

  class DataPackager {
    constructor (dates, grains, metricFamily, configuration, enabled) {
      this.configuration = configuration;
      this.dates = dates;
      this.enabled = enabled;
      this.grains = grains;
      this.metricFamily = metricFamily;
    }

    transform (promise) {
      return promise
        .then((data) => ({
          actuals: this._aggregatePromises(data, 'actuals'),
          actualsComparisons: this._aggregatePromises(data, 'actualsComparisons'),
          actualsPPYoY: this._aggregatePromises(data, 'actualsPPYoY'),
          actualsPYoY: this._aggregatePromises(data, 'actualsPYoY'),
          forecast: this._aggregatePromises(data, 'forecast'),
          forecastComparisons: this._aggregatePromises(data, 'forecastComparisons')
        }))
        .then(this.setMetricReferences.bind(this))
        .then(this.collapse.bind(this))
        .then(this.resize.bind(this))
        .then(this.merge.bind(this))
        .then(this.concatenate.bind(this))
        .then(this.sort.bind(this));
    }

    _aggregatePromises (datasets, datasetName) {
      // If the key matches the pattern of exactly the datasetName or datasetName with some number appended to it, output its value.
      // If not, return undefined.
      // After the array is returned, remove all nil values (via _.compact).
      // This effectively aggregates all data related to a given key or set of keys.
      return _.compact(Object.keys(datasets).sort().map((key) => _.isNil(key.match(new RegExp(`^${datasetName}\\d*$`))) ? undefined : datasets[key]));
    }

    setMetricReferences (datasets) {
      // All datasets will hold references to metrics in Metric family
      const setMetricReference = (datasetList) => {
        const updateRecords = (recordSet) => {
          recordSet.forEach((record) => {
            record.metrics.forEach((item) => {
              item.metric = _.defaults(
                { displayName: Name.ofMetricFamily(this.metricFamily) },
                _.find(this.metricFamily.metrics, (familyMetric) => Comparison.areMetricsEqual(familyMetric, item))
              );
            });
          });
        };

        datasetList.forEach((dataset) => {
          updateRecords(dataset.records);
          updateRecords(dataset.aggregateRecords);
        });
      };

      setMetricReference(datasets.actuals);
      setMetricReference(datasets.actualsComparisons);
      setMetricReference(datasets.actualsPYoY);
      setMetricReference(datasets.actualsPPYoY);
      setMetricReference(datasets.forecast);
      setMetricReference(datasets.forecastComparisons);
      return datasets;
    }

    collapse (datasets) {
      // Collapse each dataset to a set of complete data records
      // This helper method is used to map datasets to their corresponding plan or actual calls.
      const calculateIndex = (index) => this.enabled.subtotals ? Math.floor(index / this.grains.values().length) : index;
      const flattenLists = (datasetsList, dataType) => {
        const createGridRows = (records, index, isAggregate = false) => _.flatten(_.map(records, (record) =>
          _.flatten(_.map(record.metrics, (item) => ({
            dataType: _.isString(dataType) ? dataType : dataType[calculateIndex(index)].datasetClass,
            // If the record type is aggregate, then the metric value should be set to 'Totals'.
            granularity: _.defaults({ metric: isAggregate ? Enums.AggregateType.TOTALS : Name.ofMetric(item.metric) }, record.granularity),
            metric: item.metric,
            values: item.values
          })))));
        // This method effectively aggregates all record sets of a given dataset into a single dataset. For example,
        // if a dataset produced three record sets via a subtotals call, this method will collapse them into one.
        const collapsedRecords = (collapsedDatasets, isAggregate) =>
          _.transform(collapsedDatasets,
            (records, dataset, index) => records[calculateIndex(index)].push(...createGridRows(dataset[isAggregate ? 'aggregateRecords' : 'records'], index, isAggregate)),
            _.map(Array(_.isString(dataType) ? 1 : dataType.length), () => [])
          );

        // If the provided dataset is empty, no collapsing should be performed.
        if (_.isEmpty(datasetsList)) {
          return [];
        }

        // Collapse the datasets in their expected sizes. For example, if two actual comparisons are selected by the user,
        // datasets.actualsComparisons would normally have a size of two. However, in the case of a user selecting
        // subtotals with three group-by-grains datasets.actualsComparisons will have a size of 2 * 3 = 6.
        // However, the following transform methods are not expecting this so these two sets of three are collapsed
        // into two sets of one.
        // [ a_1, a_2, a_3], [ ac1_1, ac1_2, ac1_3, ac2_1, ac2_2, ac2_3 ] -> [ a_123 ], [ ac1_123, ac2_123 ]
        const collapsedRecordsList = collapsedRecords(datasetsList, false);
        const collapsedAggregateRecordsList = collapsedRecords(datasetsList, true);
        return _.transform(datasetsList, (collapsedDatasets, dataset, index) =>
          collapsedDatasets[calculateIndex(index)] = _.assign(
            dataset,
            {
              aggregateRecords: collapsedAggregateRecordsList[calculateIndex(index)],
              records: collapsedRecordsList[calculateIndex(index)]
            }), _.map(Array(_.isString(dataType) ? 1 : dataType.length), () => []));
      };

      return {
        actuals: flattenLists(datasets.actuals, Enums.DataType.ACTUAL),
        actualsComparisons: flattenLists(datasets.actualsComparisons, this.configuration.actualsComparisons),
        actualsPPYoY: flattenLists(datasets.actualsPPYoY, Enums.DataType.PPYoY),
        actualsPYoY: flattenLists(datasets.actualsPYoY, Enums.DataType.PYoY),
        forecast: flattenLists(datasets.forecast, Enums.DataType.PRIMARY),
        forecastComparisons: flattenLists(datasets.forecastComparisons, this.configuration.forecastComparisons)
      };
    }

    resize (datasets) {
      const resizeDatasets = (datasets, plans, dates) => {
        const resizeRecords = (records, startDate, endDate) => {
          const dateOffset = DateUtils.dateArrayComparator(
            dates,
            DateUtils.expansion(startDate, endDate, this.configuration.granularity.time)
          );
          Grid.resize(
            records,
            {
              append: dateOffset.postfix.count,
              prepend: dateOffset.prefix.count,
              property: 'values'
            }
          );
        };

        datasets.forEach((dataset, index) => {
          // For Actuals and ActualsComparisons, startDate/endDate will be head/last of dates
          const startDate = _.get(plans[index], 'plan.startDate') || _.head(dates),
                endDate = _.get(plans[index], 'plan.endDate') || _.last(dates);
          resizeRecords(dataset.records, startDate, endDate, RECORDS);
          resizeRecords(dataset.aggregateRecords, startDate, endDate, AGGREGATE_RECORDS);
        });
      };
      resizeDatasets(datasets.actuals, [], this.dates.slice(0, this.configuration.actuals.dateRangeLength));
      resizeDatasets(datasets.actualsComparisons, [], this.dates.slice(0, this.configuration.actuals.dateRangeLength));
      resizeDatasets(datasets.forecast, [this.configuration.forecast], this.dates.slice(this.configuration.actuals.dateRangeLength));
      resizeDatasets(datasets.forecastComparisons, this.configuration.forecastComparisons, this.dates.slice(this.configuration.actuals.dateRangeLength));
      resizeDatasets(datasets.actualsPYoY, [], this.dates);
      resizeDatasets(datasets.actualsPPYoY, [], this.dates);
      return datasets;
    }

    merge (datasets) {
      const createValues = (record) => {
        if (_.isNil(record)) {
          return [];
        }
        return _.map(record.values, (value) => ({
          dataSource: record.metric.source,
          dataType: record.dataType,
          type: record.metric.type,
          value: value
        }));
      };

      const mergeValues = (firstPlanRecord, secondPlanRecord) => {
        const rangeInWeeks = this.configuration.actuals.dateRangeLength;
        if (_.isNil(firstPlanRecord) && rangeInWeeks > 0) {
          firstPlanRecord = {
            metric: {},
            values: _.fill(Array(rangeInWeeks), null)
          };
        }
        if (_.isNil(secondPlanRecord) && firstPlanRecord.values.length === rangeInWeeks) {
          secondPlanRecord = {
            metric: {},
            values: _.fill(Array(this.dates.length - rangeInWeeks), null)
          };
        }
        return _.concat(createValues(firstPlanRecord), createValues(secondPlanRecord));
      };

      const createMergedRecord = (record, mergedValues, dataType) => DataRow.create({
        dataType: dataType,
        granularity: record.granularity,
        metric: _.omit(record.metric, ['displayOrder', 'isEditable', 'source', 'type']),
        values: mergedValues
      });

      const mergePlanRecords = (firstPlanRecords, secondPlanRecords, dataType) => {
        const mergedRecords = [];
        if (_.isEmpty(firstPlanRecords) && _.isEmpty(secondPlanRecords)) {
          return mergedRecords;
        }
        if (_.isEmpty(firstPlanRecords)) {
          secondPlanRecords.forEach((record) => mergedRecords.push(createMergedRecord(record, mergeValues(undefined, record), dataType)));
          return mergedRecords;
        }
        if (_.isEmpty(secondPlanRecords)) {
          firstPlanRecords.forEach((record) => mergedRecords.push(createMergedRecord(record, mergeValues(record, undefined), dataType)));
          return mergedRecords;
        }

        firstPlanRecords.forEach((firstPlanRecord) => {
          const secondPlanRecord = _.find(secondPlanRecords, (record) => Comparison.areGranularitiesEqual(firstPlanRecord.granularity, record.granularity, { exclude: ['metric'] }));
          _.pull(secondPlanRecords, secondPlanRecord);
          mergedRecords.push(createMergedRecord(firstPlanRecord, mergeValues(firstPlanRecord, secondPlanRecord), dataType));
        });
        secondPlanRecords.forEach((record) => mergedRecords.push(createMergedRecord(record, mergeValues(undefined, record), dataType)));
        return mergedRecords;
      };

      const mergeDatasetsLists = (firstDatasetsList, secondDatasetsList, dataType) => {
        const mergedDatasets = [];
        for (let index = 0; index < Math.max(firstDatasetsList.length, secondDatasetsList.length); index++) {
          mergedDatasets.push({
            aggregateRecords: mergePlanRecords(_.get(firstDatasetsList[index], AGGREGATE_RECORDS), _.get(secondDatasetsList[index], AGGREGATE_RECORDS),
              dataType || `${Enums.DataType.COMPARISON} ${Name.ofOrdinal(index + 1)}`),
            records: mergePlanRecords(_.get(firstDatasetsList[index], RECORDS), _.get(secondDatasetsList[index], RECORDS),
              dataType || `${Enums.DataType.COMPARISON} ${Name.ofOrdinal(index + 1)}`)
          });
        }
        return mergedDatasets;
      };

      datasets.actualsAndForecast = mergeDatasetsLists(datasets.actuals, datasets.forecast, Enums.DataType.PRIMARY);
      datasets.comparisons = mergeDatasetsLists(datasets.actualsComparisons, datasets.forecastComparisons);
      datasets.actualsPYoY = mergeDatasetsLists(datasets.actualsPYoY, [], Enums.DataType.PYoY);
      datasets.actualsPPYoY = mergeDatasetsLists(datasets.actualsPPYoY, [], Enums.DataType.PPYoY);
      return datasets;
    }

    concatenate (datasets) {
      const concatenatedDataset = _.flatten(_.concat(datasets.actualsAndForecast, datasets.comparisons, datasets.actualsPYoY, datasets.actualsPPYoY));
      return {
        records: _.reduce(concatenatedDataset, (accumulator, value) => accumulator.concat(value.records), []),
        totals: _.reduce(concatenatedDataset, (accumulator, value) => accumulator.concat(value.aggregateRecords), [])
      };
    }

    sort (dataset) {
      const dataTypes = [
        Enums.DataType.PRIMARY,
        ..._.times(Math.max(this.configuration.actualsComparisons.length, this.configuration.forecastComparisons.length), (index) => `${Enums.DataType.COMPARISON} ${Name.ofOrdinal(index + 1)}`),
        Enums.DataType.PYoY,
        Enums.DataType.PPYoY
      ];
      return {
        records: Comparison.sort(dataset.records, dataTypes, this.grains.values(), this.metricFamily.metrics).map((record) => {
          // Each record is either a standard record or a subtotal record.
          // Standard records keep their provided granularity values, but subtotal records
          // will be missing some of theirs depending on the grain of the subtotal.
          // To handle the subtotal records, their missing grain values are set to 'Subtotal'.
          this.grains.values().forEach((grain) => _.defaults(record.granularity, { [grain.id]: Enums.AggregateType.SUBTOTAL }));
          return record;
        }),
        totals: Comparison.sort(dataset.totals, dataTypes, this.grains.values(), this.metricFamily.metrics)
      };
    }
  }

  class PlanViewerPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['metricsService', '$q'];
    }

    constructor (metricsService, $q) {
      super($q);
      this.metricsService = metricsService;
    }

    /**
     * Combines metric requests to different services from a metric family into one object
     *
     * @dates Array the range of dates being supported
     * @metricFamily Object the metric family
     * @configuration is a hash containing request metadata:
     *   @forecast Object:
     *     @plan Object the forecast plan that forecast metrics should be requested for
     *   @forecastComparisons Array:
     *     @plan Object the comparison plan that forecast metrics should be requested for
     *   @actualsComparisons Array:
     *     @plan Object the actuals comparison plan that historic forecast metrics should be requested for
     *   @granularity Object the granularity that should be applied to each request
     *   @filters Object the filters that should be applied to each request
     * @enabled
     *   @actuals Boolean a flag indicating whether requesting actuals is required
     *   @actualsComparisons Array
     *     @comparison Boolean a flag indicating whether requesting actuals comparison plan data is required
     *   @forecastComparisons Array
     *     @comparison Boolean a flag indicating whether requesting comparison plan data is required
     *
     * @returns Object representing the formed metadata and promise results:
     *   {
     *     dates: Array
     *     grains: Object
     *     metricFamily: Object,
     *     enabled: Object,
     *     grid: Promise --> Object
     *       @records: Array of data rows
     *       @totals: Array of data rows
     *   }
     */
    collect (dates, metricFamily, configuration, enabled) {
      // Make a deep clone of the configuration object as we need to modify the actualsComparisons and forecastComparisons properties.
      configuration = _.cloneDeep(configuration);
      configuration.actualsComparisons = _.filter(configuration.actualsComparisons, (comparison, index) => enabled.actualsComparisons[index]);
      configuration.forecastComparisons = _.filter(configuration.forecastComparisons, (comparison, index) => enabled.forecastComparisons[index] && enabled.forecasts);
      configuration.granularity.grains.granularities = this.metricsService.filterGrainsByPlan(configuration.forecast.plan, metricFamily.id, configuration.granularity.grains.granularities);
      // https://sim.amazon.com/issues/SOP-8297
      // Remove all aggregateGroupByGrains if any additional grains are provided as they do not need to be viewed, otherwise keep them.
      if (configuration.granularity.grains.size() > configuration.aggregateGroupByGrains.size()) {
        configuration.granularity.grains.remove(configuration.aggregateGroupByGrains);
      }
      configuration.filters = this.metricsService.filterFiltersByPlan(configuration.forecast.plan, metricFamily.id, configuration.filters);
      const transformer = new DataPackager(dates, configuration.granularity.grains, metricFamily, configuration, enabled),
            dataPromise = transformer.transform(fetchMetrics.call(this, dates, metricFamily, configuration, enabled));

      return DataPackage.create({
        chartInputs: configuration.chartInputs,
        dates: dates,
        enabled: enabled,
        granularity: _.set(configuration.granularity, 'totalsGrains', Granularities.create().addMetricGrain()),
        plan: configuration.forecast.plan,
        records: dataPromise.then((data) => data.records),
        showComparisonAs: _.get(configuration, 'modes.comparison.selected'),
        showDataAs: _.get(configuration, 'modes.data.selected'),
        showViewAs: _.get(configuration, 'modes.view.selected'),
        timeUnit: configuration.timeUnit,
        title: Name.ofMetricFamily(metricFamily),
        totals: dataPromise.then((data) => data.totals),
        type: Enums.DataPackage.PackageType.COMPACT_PLAN
      }, this.$q);
    }
  }

  angular.module('application.services').service('planViewerPackager', PlanViewerPackagerService);
})();
