/* globals AbstractServiceEndpoint, Configuration */
(function () {
  'use strict';
  class DataActualsService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['request'];
    }

    constructor (request) {
      super(request, Configuration.services.dataActuals);
    }

    /**
     * Gets actuals for given input. Output structure varies based on the input
     * @param metric
     * @param planGranularity
     * @param timeGranularity
     * @param options [startDate|endDate|warehouseId|productLine|groupBy|sortType]
     * @returns {*|{method, params, headers}}
     * @example actuals('arrivals', 'PRODUCTLINE', 'weekly', { startDate: '01/01/1900', endDate: '02/01/1900' })
     */
    fetchActuals (metric, planGranularity, timeGranularity, body, options) {
      return this.aws().withScope()
        .for('metric', metric, planGranularity, timeGranularity)
        .withParams(options)
        .withBody(body)
        .post();
    }
  }

  angular.module('application.services').service('dataActuals', DataActualsService);
})();
