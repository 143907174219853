/* globals DateUtils, Enums */
(function () {
  'use strict';
  const VIEW_TEMPLATE = Object.freeze({
    description: '',
    isPublic: false,
    name: ''
  });

  class ViewModalController {
    static get $inject () {
      return ['$authentication'];
    }

    constructor ($authentication) {
      this.$authentication = $authentication;
    }

    areInputsInvalid () {
      return this.isNameInvalid() || this.isDescriptionInvalid();
    }

    getDeleteConfirmationMessage () {
      return `Are you sure you want to delete the view <strong>${this.view.name}</strong>? This action cannot be undone.`;
    }

    isNameInvalid () {
      return this.isViewOwner() && _.isEmpty(this.view.name);
    }

    isDescriptionInvalid () {
      return this.isViewOwner() && _.isEmpty(this.view.description);
    }

    isCreate () {
      return this.resolve.mode === 'create';
    }

    isReadOnly () {
      return this.resolve.mode === 'readOnly';
    }

    isDeleteAllowed () {
      return this.isViewOwner() && !this.isCreate();
    }

    isViewOwner () {
      return this.view.owner === this.$authentication.profile().alias;
    }

    showPublicSection () {
      return this.$authentication.hasAdministratorAccess() || this.isReadOnly();
    }

    deleteView () {
      this.modalInstance.close({ mode: 'delete', view: this.view });
    }

    saveView () {
      this.modalInstance.close({ mode: this.resolve.mode, view: this.view });
    }

    $onInit () {
      this.view = !this.isCreate() ? _.cloneDeep(this.resolve.selectedView) :
        _.defaults({
          owner: this.$authentication.profile().alias,
          updatedDate: DateUtils.format(Enums.DateFormat.IsoDate)
        }, VIEW_TEMPLATE);
    }
  }
  angular.module('application.components')
    .component('viewModal', {
      bindings: {
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with two properties:
         *   mode - string one of the modes in which the modal will be opened ('create', 'update' or 'readOnly')
         *   selectedView - object holding the view to be loaded into the modal
         */
        resolve: '<'
      },
      controller: ViewModalController,
      templateUrl: 'templates/components/view-modal.component.html'
    });
})();
