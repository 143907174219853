/* globals Configuration, Ready, TaskStatus */
(function () {
  'use strict';

  class ROMUploadedConstraintDisplayModalController {
    static get $inject () {
      return ['romScenarioConfiguration'];
    }

    constructor (romScenarioConfiguration) {
      this.romScenarioConfiguration = romScenarioConfiguration;
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.constraints),
        isLoadingFn: () => this.loading
      });
    }

    loadData () {
      _.defaults(this, this.resolve.modalData);
      this.loading = true;
      this.constraints = [];

      const body = {
        scope: Configuration.scopes.current().code,
        startDate: this.startDate
      };
      this.romScenarioConfiguration.getUploadConstraintStatus(body)
        .then((data) => this.constraints = data.uploadConstraintStatusList)
        .finally(() => this.loading = false);
    }

    statusClass (status) {
      return TaskStatus.statusClass(status);
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this.loadData();
    }

    $onInit () {
      this.loadData();
      this.state.isInitialized(true);
    }
  }

  angular.module('application.components')
    .component('romUploadedConstraintDisplayModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with single property:
         *   startDate - date to retrieve constraints for.
         */
        resolve: '<'
      },
      controller: ROMUploadedConstraintDisplayModalController,
      templateUrl: 'templates/components/rom-uploaded-constraint-display-modal.component.html'
    });
})();
