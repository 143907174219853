/* globals AbstractTransformerService, Enums, Granularities, Name, PlanMetadata, XLSX */
(function () {
  'use strict';
  // ====== Transformers: More Than Meets The Eye ======
  //                          /[-])//  ___
  //                     __ --\ `_/~--|  / \
  //                   /_-/~~--~~ /~~~\\_\ /\
  //                   |  |___|===|_-- | \ \ \
  // _/~~~~~~~~|~~\,   ---|---\___/----|  \/\-\
  // ~\________|__/   / // \__ |  ||  / | |   | |
  //          ,~-|~~~~~\--, | \|--|/~|||  |   | |
  //          [3-|____---~~ _--'==;/ _,   |   |_|
  //                      /   /\__|_/  \  \__/--/
  //                     /---/_\  -___/ |  /,--|
  //                     /  /\/~--|   | |  \///
  //                    /  / |-__ \    |/
  //                   |--/ /      |-- | \
  //                  \^~~\\/\      \   \/- _
  //                   \    |  \     |~~\~~| \
  //                    \    \  \     \   \  | \
  //                      \    \ |     \   \    \
  //                       |~~|\/\|     \   \   |
  //                      |   |/         \_--_- |\
  //                      |  /            /   |/\/
  //                       ~~             /  /
  // Optimus Prime                       |__/
  // ===================================================
  const WORKBOOK_METADATA_DEFINITION = Object.freeze([
    AbstractTransformerService.metadataProperty('dates'),
    AbstractTransformerService.metadataProperty('editType'),
    AbstractTransformerService.metadataProperty('filters'),
    AbstractTransformerService.metadataProperty(
      'granularity',
      (value) => _.mapValues(JSON.parse(value), (property) => _.has(property, 'granularities') ? Granularities.create(property.granularities) : property)
    ),
    AbstractTransformerService.metadataProperty('groupBy'),
    AbstractTransformerService.metadataProperty('metrics'),
    AbstractTransformerService.metadataProperty(
      'plan',
      (value) => PlanMetadata.create(JSON.parse(value)),
      (value) => _.isNil(value) ? undefined : JSON.stringify(value)
    ),
    AbstractTransformerService.metadataProperty('type')
  ]);

  class PlanTransformerService extends AbstractTransformerService {
    static get $inject () {
      return ['alerts', '$q'];
    }

    constructor (alerts, $q) {
      super(alerts, $q, WORKBOOK_METADATA_DEFINITION);
    }

    _alignGrains (packages) {
      // Find the master set of granularities (the set that includes all grains). This set is guaranteed to be the granularity of one of the packages.
      const mostInclusiveGrainSet = _.maxBy(packages.map((pkg) => pkg.granularity.grains), (grain) => grain.values().length);
      packages.forEach((pkg) =>
        mostInclusiveGrainSet.values().forEach((grain) => {
          // Update all package granularities to the master set and set the grain value of missing grain to '-'.
          pkg.granularity.grains = _.cloneDeep(mostInclusiveGrainSet);
          pkg.records.forEach((record) => record.granularity[grain.id] = _.isNil(record.granularity[grain.id]) ? '-' : record.granularity[grain.id]);
        }));
    }

    _extractContent (pkg, gridRowType, suppliers) {
      return _.transform(pkg[gridRowType], (content, gridRow) => {
        const row = [];
        suppliers.forEach((supplier) => {
          if (supplier.source === 'pkg') {
            row.push(this._extractValue(supplier, pkg, gridRow));
          }
          if (supplier.source === 'row') {
            row.push(this._extractValue(supplier, gridRow));
          }
        });
        content.push(_.flatten(row));
      }, []);
    }

    _extractValue (supplier, source, row) {
      if (_.isString(supplier)) {
        return supplier;
      }
      let value = source[supplier.key];
      if (_.has(supplier, 'serialize')) {
        value = supplier.serialize(value, source, row);
      }
      return value;
    }

    _generateBodyRows (pkg, suppliers) {
      const content = [];
      content.push(...this._extractContent(pkg, 'records', suppliers));
      content.push(...this._extractContent(pkg, 'totals', suppliers));
      return content;
    }

    _generateHeaderRows (pkg, suppliers) {
      return suppliers.map((supplier) => this._extractValue(supplier, pkg)).flat();
    }

    /* @Override
     * Converts dataPackages into a flat-workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToFlatWorkbook (packages, headerSuppliers, bodySuppliers, workbook = XLSX.utils.book_new()) {
      const worksheet = this.convertToWorksheet(packages, headerSuppliers, bodySuppliers);
      XLSX.utils.book_append_sheet(workbook, worksheet, Enums.XlsxSheet.DATA);
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorkbook (packages, headerSuppliers, bodySuppliers) {
      const workbook = XLSX.utils.book_new();
      packages.forEach((pkg, index) =>
        XLSX.utils.book_append_sheet(
          workbook,
          this.convertToWorksheet([pkg], headerSuppliers, bodySuppliers),
          Name.ofSheet(pkg.title, index)));
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a worksheet, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorksheet (packages, headerSuppliers, bodySuppliers) {
      const content = [];

      this._alignGrains(packages);
      packages.forEach((pkg, index) => {
        // pkg.granularity references the granularity object defined in the controller.
        // If the metric grain is added it should not affect the granularity in the controller
        pkg.granularity = _.defaults({ grains: Granularities.create(pkg.granularity.grains.values()).addMetricGrain(0) }, pkg.granularity);
        if (index === 0) {
          content.push(this._generateHeaderRows(pkg, headerSuppliers));
        }
        content.push(...this._generateBodyRows(pkg, bodySuppliers));
      });
      return XLSX.utils.aoa_to_sheet(content);
    }
  }

  angular.module('application.services').service('planTransformer', PlanTransformerService);
})();
