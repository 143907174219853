(function () {
  'use strict';

  class Enumeration {
    /* Produces an instance of the Enumeration class.
     *
     * @param keys The keys in the enumeration. Must be unique, non-empty strings.
     *
     * @return an instance of the Enumeration class.
     */
    constructor (...keys) {
      if (_.isEmpty(keys)) {
        throw new Error('Enumeration: the list of keys must not be empty');
      }
      if (!keys.every((key) => _.isString(key) && !_.isEmpty(key))) {
        throw new Error('Enumeration: all keys must be non-empty strings');
      }
      if (keys.length !== _.uniq(keys).length) {
        throw new Error('Enumeration: each key must be unique');
      }
      this.keys = keys;
    }

    /* Produces an enumerable object. The values are the provided arguments.
     *
     * @param values The values for each key in the enumeration. Each value must be unique and be a 1-to-1 mapping with the number of keys.
     *
     * @return Immutable object with key-value pairs.
     */
    asExactValue (...values) {
      if (_.isEmpty(values)) {
        throw new Error('Enumeration: the list of values must not be empty');
      }
      if (this.keys.length !== values.length) {
        throw new Error('Enumeration: keys and values must have a 1-to-1 mapping');
      }
      if (values.length !== _.uniq(values).length) {
        throw new Error('Enumeration: each value must be unique');
      }
      return Object.freeze(_.reduce(this.keys, (enumeration, key, index) => _.set(enumeration, key, values[index]), {}));
    }

    /* Produces an enumerable object. The values are the index (modified by offset) of the keys in the order provided.
     *
     * @param offset The offset used when computing the value for each key.
     *
     * @return Immutable object with key-value pairs.
     */
    asIndexValue (offset = 1) {
      return Object.freeze(_.reduce(this.keys, (enumeration, key, index) => _.set(enumeration, key, index + offset), {}));
    }


    /* Produces an enumerable object. The values are the key augmented by the provided value function.
     *
     * @param valueFn The function by which the enumerable's value will be determined from the key. By default the value is just the key.
     *
     * @return Immutable object with key-value pairs.
     */
    asStringValue (valueFn = _.identity) {
      return Object.freeze(_.reduce(this.keys, (enumeration, key) => _.set(enumeration, key, valueFn(key)), {}));
    }

    /* Wrapper for the Enumeration class constructor.
     *
     * @param keys The keys in the enumeration. Must be unique, non-empty strings.
     *
     * @return an instance of the Enumeration class.
     */
    static create (...keys) {
      return new Enumeration(...keys);
    }

    /* Checks whether a key is a member key of the provided enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     * @param key The key to check against the enumeration.
     *
     * @return true if the key is a member key of the enumeration, false otherwise.
     */
    static isMemberKey (enumeration, key) {
      if (_.isNil(enumeration)) {
        throw new Error('Enumeration: enumeration must not be nil');
      }

      return _.includes(_.keys(enumeration), key);
    }

    /* Determines whether to return the key or defaultKey based on whether key
     * is a member key of the provided enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     * @param key The key to check against the enumeration.
     * @param defaultKey The fallback key to return if key is not in the enumeration.
     *
     * @return the key if the key is a member key of the enumeration, defaultKey otherwise.
     */
    static isMemberKeyOrDefault (enumeration, key, defaultKey) {
      if (_.isNil(enumeration)) {
        throw new Error('Enumeration: enumeration must not be nil');
      }

      if (!this.isMemberKey(enumeration, defaultKey)) {
        throw new Error(`Enumeration: defaultKey must be a key in the enumeration: "${defaultKey}"`);
      }

      return _.includes(_.keys(enumeration), key) ? key : defaultKey;
    }

    /* Checks whether a value is a member value of the provided enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     * @param value The value to check against the enumeration.
     *
     * @return true if the value is a member of the enumeration, false otherwise.
     */
    static isMemberValue (enumeration, value) {
      if (_.isNil(enumeration)) {
        throw new Error('Enumeration: enumeration must not be nil');
      }

      return _.includes(_.values(enumeration), value);
    }

    /* Determines whether to return the value or defaultValue based on whether value
     * is a member value of the provided enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     * @param value The value to check against the enumeration.
     * @param defaultValue The fallback value to return if value is not in the enumeration.
     *
     * @return the value if the value is a member of the enumeration, defaultValue otherwise.
     */
    static isMemberValueOrDefault (enumeration, value, defaultValue) {
      if (_.isNil(enumeration)) {
        throw new Error('Enumeration: enumeration must not be nil');
      }

      if (!this.isMemberValue(enumeration, defaultValue)) {
        throw new Error(`Enumeration: defaultValue must be a value in the enumeration: "${defaultValue}"`);
      }

      return _.includes(_.values(enumeration), value) ? value : defaultValue;
    }

    /* Collects the keys as a list from the enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     *
     * @return the list of keys in the enumeration.
     */
    static keys (enumeration) {
      return _.keys(enumeration);
    }

    /* Collects the values as a list from the enumeration.
     *
     * @param enumeration The frozen enumeration hash.
     *
     * @return the list of values in the enumeration.
     */
    static values (enumeration) {
      return _.values(enumeration);
    }
  }

  window.Enumeration = Object.freeze(Enumeration);
})();
