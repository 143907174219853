'use strict';
/**
 * @credit https://github.com/angular-ui/ui-indeterminate
 *
 * Provides an easy way to toggle a checkboxes indeterminate property
 *
 * @example <input type="checkbox" ng-indeterminate="isIndeterminate">
 */
angular.module('application.directives')
  .directive('ngIndeterminate', function () {
    return {
      compile: function (elem, attrs) {
        if (!_.isString(attrs.type) || attrs.type.toLowerCase() !== 'checkbox') {
          return angular.noop;
        }

        return function ($scope, elem, attrs) {
          $scope.$watch(attrs.ngIndeterminate, function (newValue) {
            elem[0].indeterminate = !!newValue;
          });
        };
      },
      restrict: 'A'
    };
  });
