/* globals Dictionary */
(function () {
  'use strict';

  const addSummaryColumn = (row, sectionList, index) => {
    const section = _.get(sectionList, index, Dictionary.create());
    if (!section.isEmpty()) {
      row.push(section);
    }
  };

  class Summary {
    constructor () {
      this.data = {
        left: Dictionary.create(),
        middle: Dictionary.create(),
        right: Dictionary.create()
      };
      this.list = [];
    }

    static create () {
      return new Summary();
    }

    /*
     * Creates an entire summary row.
     * The provided term and definition is added to the Left section of the Summary data. Middle and Right sections
     * are empty to make sure other Middle and Right sections don't get bumped up into this row.
     */
    add (term, definition, ...classes) {
      return this.addLeft(term, definition, ...classes)
        .addMiddle()
        .addRight();
    }

    addLeft (term, definition, ...classes) {
      this.data.left.add(term, definition, ...classes);
      return this;
    }

    addMiddle (term, definition, ...classes) {
      this.data.middle.add(term, definition, ...classes);
      return this;
    }

    addRight (term, definition, ...classes) {
      this.data.right.add(term, definition, ...classes);
      return this;
    }

    clear () {
      Object.keys(this.data).forEach((key) => this.data[key].clear());
      this.list.length = 0;
      return this;
    }

    constructList () {
      this.list.length = 0;
      const maxLength = Math.max(this.data.left.list.length, this.data.middle.list.length, this.data.right.list.length);
      _.range(maxLength).forEach((index) => {
        const row = [];
        Object.keys(this.data).forEach((key) => addSummaryColumn(row, this.data[key].list, index));
        this.list.push(row);
      });
    }

    isEmpty () {
      return _.isEmpty(this.list);
    }
  }

  window.Summary = Object.freeze(Summary);
})();
