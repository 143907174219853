/* globals AbstractElementComponent, Binding */
(function () {
  'use strict';
  class TreeItemSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$element'];
    }

    constructor (alerts, $element) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.data) || (_.isNil(this.data.parent) && _.isEmpty(this.data.children)),
        isLoadingFn: () => !_.isNil(this.interface) && _.isNil(this.data),
        isWaitingFn: () => _.isNil(this.interface) && _.isNil(this.data)
      });
      this.alerts = alerts;
    }

    _loadData () {
      if (_.isNil(this.interface) || this.state.isWaiting()) {
        return;
      }

      // Only set the selections array during a data load, which is triggered by the top-level component that has the interface reference.
      // This will propogate to all of the tree as the reference is inherited with one-way binding.
      this.selections = new Map();
      this.data = undefined;
      this.interface.items()
        .then((response) => {
          this.data = response;
          this._setInitialSelections();
        })
        .catch((error) => {
          // This makes sure the spinner goes away and a 'No data..' message is displayed instead.
          this.data = {};
          this.alerts.error(error);
        });
    }

    _selectedValues () {
      return Array.from(this.selections.values());
    }

    _selectNode (selectedNode) {
      selectedNode.select(selectedNode.isSelected, (node) => {
        if (!node.isLeaf()) {
          return;
        }
        if (selectedNode.isSelected) {
          this.selections.set(node.id, node.value);
        } else {
          this.selections.delete(node.id);
        }
      });
    }

    _setInitialSelections () {
      if (_.isEmpty(this.initialSelections) || _.isEmpty(this.data)) {
        return;
      }

      if (_.isEqual(this.initialSelections, this._selectedValues())) {
        // Do nothing if the initial selections are equivalent to the current selections
        return;
      }

      this.data.select(false);
      this.selections.clear();
      this.data.traverse((node) => {
        if (_.some(this.initialSelections, (value) => _.isEqual(node.value, value))) {
          node.isSelected = true;
          this._selectNode(node);
        }
      });
      this.onSelectionChange({ selections: this._selectedValues() });
    }

    $onChanges (changes) {
      if (!this.state.isInitialized() || this.state.isWaiting()) {
        return;
      }

      if (Binding.changes.only(changes, 'initialSelections')) {
        // Do not perform a data reload if initialSelections is the only change
        this._setInitialSelections();
        return;
      }

      if (Binding.changes.has(changes.interface)) {
        if (_.isNil(this.interface)) {
          this.data = undefined;
          return;
        }
        this._loadData();
      }
    }

    onNodeClick (selectedNode) {
      this._selectNode(selectedNode);
      this.onSelectionChange({ selections: this._selectedValues() });
    }
  }

  angular.module('application.components')
    .component('treeItemSelector', {
      bindings: {
        data: '<',
        initialSelections: '<',
        interface: '<',
        onSelectionChange: '&',
        // This array is shared by all nodes in the tree as it is passed by reference.
        selections: '<'
      },
      controller: TreeItemSelectorController,
      templateUrl: 'templates/components/tree-item-selector.component.html'
    });
})();
