/* globals AbstractElementComponent */
(function () {
  'use strict';
  class HistoricalPlanSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', 'planStore'];
    }

    constructor ($element, planStore) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.plans),
        isLoadingFn: () => this.loading,
        isNoSelectionFn: () => _.isNil(this.selectedPlan)
      });
      this.planStore = planStore;
      this.loading = true;
      this.plans = [];
      this.selectedPlan = undefined;
    }

    _loadData () {
      // Do not perform a data reload if no date is defined or the filters are missing
      if (_.isNil(this.date) || _.isNil(this.filterFn)) {
        return;
      }

      this.loading = true;
      this.selectedPlan = undefined;
      this.plans.length = 0;
      this.planStore.plans(this.date)
        .then((plans) => {
          plans = _.filter(plans, this.filterFn);
          this.plans.push(...plans);
          this.plans = _.sortBy(this.plans, ['historicalWeek']);
          if (_.isEmpty(this.plans)) {
            // If there are no plans, make the 'onSelectionChange' callback with an undefined plan. Informs the caller that no plans were available.
            this.onSelected();
            return;
          }
          if (!_.isNil(this.initialPlan)) {
            // A non-nil initial plan is provided indicating this is from a Shareable URL.
            this._setInitialPlan();
            return;
          }
          this._setInitialSelection();
        })
        .finally(() => this.loading = false);
    }

    _setInitialPlan () {
      if (!_.find(this.plans, (plan) => plan.equals(this.initialPlan))) {
        // Add the plan to the list of plans so it can be selected in the cascade of dropdowns.
        this.plans.push(this.initialPlan);
      }
      this.onSelected(this.initialPlan);
    }

    _setInitialSelection () {
      const initialSelection = this.initialSelection < this.plans.length ? this.initialSelection : 0;
      this.onSelected(_.nth(this.plans, initialSelection));
    }

    isDisabled () {
      return this.plans.length === 0 || super.isDisabled();
    }

    onSelected (plan) {
      this.selectedPlan = plan;
      this.onSelectionChange({ plan: this.selectedPlan });
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }

      this._loadData();
    }
  }

  angular.module('application.components')
    .component('historicalPlanSelector', {
      bindings: {
        date: '<',
        filterFn: '<',
        initialPlan: '<',
        // Nth plan to be selected from the list of plans. (will be ignored when initialPlan is provided)
        initialSelection: '<',
        onSelectionChange: '&'
      },
      controller: HistoricalPlanSelectorController,
      templateUrl: 'templates/components/historical-plan-selector.component.html'
    });
})();
