/* globals Ready */
(function () {
  'use strict';

  class AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element, stateFunctions) {
      this.$element = $element;
      this.state = Ready.create(stateFunctions);
    }

    isDisabled () {
      if (_.isNil(this.$element)) {
        throw new Error('AbstractElementComponent: $element service is undefined and disabled attribute checking is not supported');
      }

      return !_.isNil(this.$element.attr('disabled'));
    }

    $onInit () {
      // Define the _loadData method on an instance or in a sub-class to have it called during $onInit.
      if (_.isFunction(this._loadData)) {
        this._loadData();
      }
      this.state.isInitialized(true);
    }
  }

  window.AbstractElementComponent = Object.freeze(AbstractElementComponent);
})();
