/* globals DateUtils, Enumeration, Enums, Ready */
(function () {
  'use strict';
  const ANNOUNCEMENT_STATUS = Enumeration.create('SCHEDULED', 'ACTIVE', 'EXPIRED').asStringValue(_.toLower);
  // Announcement preview stays up for 10 seconds, irrespective of its alert type.
  const PREVIEW_AUTO_CLOSE_TIMEOUT = 10000;
  const DEACTIVATE_CONFIRMATION_MESSAGE = 'Are you sure you want to deactivate the announcement?';

  const getStatus = (announcement) => {
    const currentTime = DateUtils.epoch();
    if (currentTime < announcement.startTime) {
      return ANNOUNCEMENT_STATUS.SCHEDULED;
    } else if (currentTime > announcement.endTime) {
      return ANNOUNCEMENT_STATUS.EXPIRED;
    }
    return ANNOUNCEMENT_STATUS.ACTIVE;
  };

  class AnnouncementListController {
    static get $inject () {
      return ['alerts', 'overlay', 'portalStore', '$uibModal'];
    }

    constructor (alerts, overlay, portalStore, $uibModal) {
      this.alerts = alerts;
      this.overlay = overlay;
      this.portalStore = portalStore;
      this.$uibModal = $uibModal;
      this.deactivateConfirmationMessage = DEACTIVATE_CONFIRMATION_MESSAGE;
      this.state = Ready.create({
        isDataEmptyFn: () => _.size(this.announcements[this.listType]) <= 0,
        isLoadingFn: () => this.loading
      });
    }

    _loadData () {
      this.loading = true;
      this.announcements.scheduled.length = 0;
      this.announcements.active.length = 0;
      this.announcements.expired.length = 0;
      this.portalStore.getAnnouncements({ ignoreScopeFilter: true, ignoreTimeFilter: true })
        .then((announcements) => {
          announcements.forEach((announcement) => this.announcements[getStatus(announcement)].push(announcement));
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch Announcements' }))
        .finally(() => this.loading = false);
    }

    _openModal (action, selectedAnnouncement) {
      return this.$uibModal.open({
        component: 'announcementModal',
        resolve: {
          action: () => action,
          selectedAnnouncement: () => selectedAnnouncement
        },
        size: Enums.ModalSize.LARGE
      });
    }

    _upsertAnnouncement (announcement, action) {
      // ${ ... }ing and ${ ... }d work for actions: 'Create', 'Update', 'Deactivate'. Will not work for 'Add'.
      this.overlay.show(`${_.capitalize(action.slice(0, -1))}ing announcement...`);
      this.portalStore.upsertAnnouncement(announcement)
        .then(() => this.alerts.success(`Successfully ${action}d announcement`))
        .then(() => this._loadData())
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to ${action} announcement` }))
        .finally(() => this.overlay.hide());
    }

    isListTypeExpired () {
      return this.listType === 'expired';
    }

    expireAnnouncement (announcement) {
      // Setting end time to 1 second before current time for instant deactivation (will move to Expired list)
      announcement.endTime = DateUtils.epoch() - 1000;
      // If a future announcement is being unscheduled, reset startTime as well
      announcement.startTime = this.listType === 'scheduled' ? announcement.endTime : announcement.startTime;
      this._upsertAnnouncement(announcement, 'deactivate');
    }

    openAnnouncementModal (event, selectedAnnouncement, action = 'create') {
      this.stopPropagation(event);
      this._openModal(action, selectedAnnouncement)
        .result.then((updatedAnnouncement) => {
          this._upsertAnnouncement(updatedAnnouncement, action);
        }).catch(_.noop);
    }

    previewAnnouncement (event, announcement) {
      this.stopPropagation(event);
      this.alerts[announcement.messageType]({ autoClose: PREVIEW_AUTO_CLOSE_TIMEOUT, message: announcement.message });
    }

    stopPropagation (event) {
      event.stopPropagation();
    }

    $onInit () {
      this.announcements = {
        active: [],
        expired: [],
        scheduled: []
      };
      this.loading = false;
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('announcementList', {
      bindings: {
        listType: '<'
      },
      controller: AnnouncementListController,
      templateUrl: 'templates/components/announcement-list.component.html'
    });
})();
