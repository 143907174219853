/* globals AbstractDateSelectorComponent, DateUtils, Enums */
(function () {
  'use strict';

  /**
   * Date: this.backup.endDate
   * Date: this.backup.startDate
   * Date: this.endDate
   * Date: this.endDateOptions.maxDate
   * Date: this.endDateOptions.minDate
   * Date: this.startDate
   * Date: this.startDateOptions.maxDate
   * Date: this.startDateOptions.minDate
   * String: this.initialEndDate
   * String: this.initialStartDate
   * String: this.maxEndDate
   * String: this.maxStartDate
   * String: this.minEndDate
   * String: this.minStartDate
   */
  class DateRangeSelectorController extends AbstractDateSelectorComponent {
    setInitialDates () {
      const now = this.toDate(DateUtils.of());
      // 'null' value is permitted for the configured min start date as it can be without bounds.
      this.configuration.startDateOptions.minDate = _.isNull(this.minStartDate)
        ? null
        : this.toDate(this.minStartDate, AbstractDateSelectorComponent.dataEpochDate());
      // 'null' value is not permitted for the configured max start date as it needs to be bounded by endDate.
      this.configuration.startDateOptions.maxDate = this.toDate(this.maxStartDate, now);
      // 'null' value is not permitted for the configured min end date as it needs to be bounded by startDate.
      this.configuration.endDateOptions.minDate = this.toDate(this.minEndDate, now);
      // 'null' value is permitted for the configured max end date as it can be without bounds.
      this.configuration.endDateOptions.maxDate = _.isNull(this.maxEndDate) ? null : this.toDate(this.maxEndDate, now);
      const startDateValue = _.isNil(this.initialStartDate) && this.isNullable ? null : this.toDate(this.initialStartDate, now);
      const endDateValue = _.isNil(this.initialEndDate) && this.isNullable ? null : this.toDate(this.initialEndDate, now);
      this.settingInitialDates = true;
      this.changeStartDate(startDateValue);
      this.changeEndDate(endDateValue);
      this.settingInitialDates = false;
      this.onSelected();
    }

    changeStartDate (date) {
      const isValidDate = date instanceof Date;
      if (!isValidDate && !this.isNullable) {
        // Retrieve startDate from backup if start date is cleared manually and isNullable is not true.
        this.startDate = this.backup.startDate;
        return;
      }
      if (isValidDate) {
        this.startDate = this.adjustDateForWeekSelectionMode(date);
        this.backup.startDate = this.startDate;
        // Update the configured min end date based on the updated startDate.
        this.configuration.endDateOptions.minDate = this.ifInRange(
          this.minEndDate,
          this.maxEndDate,
          this.startDate,
          this.configuration.endDateOptions.minDate);
      }
      this.onSelected();
    }

    changeEndDate (date) {
      const isValidDate = date instanceof Date;
      if (!isValidDate && !this.isNullable) {
        // Retrieve endDate from backup if end date is cleared manually and isNullable is not true.
        this.endDate = this.backup.endDate;
        return;
      }
      if (isValidDate) {
        this.endDate = this.adjustDateForWeekSelectionMode(date);
        this.backup.endDate = this.endDate;
        // Update the configured max start date based on the updated endDate.
        this.configuration.startDateOptions.maxDate = this.ifInRange(
          this.minStartDate,
          this.maxStartDate,
          this.endDate,
          this.configuration.startDateOptions.maxDate);
      }
      this.onSelected();
    }

    ifInRange (minDate, maxDate, targetDate, fallback) {
      return DateUtils.isInRange(minDate, maxDate, this.fromDate(targetDate), Enums.TimeUnit.DAY) ? targetDate : fallback;
    }

    onSelected () {
      if (this.settingInitialDates) {
        return;
      }
      if (!this.isNullable && (_.isNil(this.startDate) || _.isNil(this.endDate))) {
        return;
      }
      const startDate = _.isNil(this.startDate) && this.isNullable ? null : this.fromDate(this.startDate).format(Enums.DateFormat.IsoDate);
      const endDate = _.isNil(this.endDate) && this.isNullable ? null : this.fromDate(this.endDate).format(Enums.DateFormat.IsoDate);
      this.onSelectionChange({ endDate, startDate });
    }

    $onInit () {
      this.initConfigurationBindings({
        endDateOptions: {
          showWeeks: true,
          startingDay: 0
        },
        format: AbstractDateSelectorComponent.defaultInputFormat,
        startDateOptions: {
          showWeeks: true,
          startingDay: 0
        }
      });
      this.backup = {};
      this.popup = {
        endDate: { opened: false },
        startDate: { opened: false }
      };
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('dateRangeSelector', {
      bindings: AbstractDateSelectorComponent.bindings({
        // initialEndDate is expected as a "yyyy-MM-dd" date string, not a Date object, as will be received from integrated services.
        initialEndDate: '<',
        // initialStartDate is expected as a "yyyy-MM-dd" date string, not a Date object, as will be received from integrated services.
        initialStartDate: '<',
        // null value removes max end date limit. undefined value sets max end date to Today
        maxEndDate: '<',
        // undefined value sets max start date to Today
        maxStartDate: '<',
        // undefined value sets min end date to Today
        minEndDate: '<',
        // null value removes min start date limit. undefined value sets min start date to Today
        minStartDate: '<'
      }),
      controller: DateRangeSelectorController,
      templateUrl: 'templates/components/date-range-selector.component.html'
    });
})();
