/* globals AbstractItemSelectorComponent, Binding */
(function () {
  'use strict';
  class FlowItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'planConfiguration', '$q'];
    }

    constructor ($element, planConfiguration, $q) {
      super(
        $element,
        { isWaitingFn: () => _.isEmpty(this.configuration) },
        () => false
      );

      this.planConfiguration = planConfiguration;
      this.$q = $q;
    }

    // SIM: https://issues.amazon.com/issues/EUFS-12
    _editableFlowsByGroups () {
      const editableFlows = this.availableFlows.filter((flow) => flow.isEditable(this.editType));
      const groups = _.groupBy(editableFlows, 'groupName');
      return _.map(groups, (flows, groupName) => ({
        id: groupName,
        items: flows,
        name: groupName
      }));
    }

    _loadData () {
      if (this.state.isWaiting()) {
        this.itemSelectorInterface = undefined;
        return;
      }

      this.availableFlows = [];
      this.itemSelectorInterface = {
        items: () => this.planConfiguration.planDefinition()
          .get(this.configuration.type, this.configuration.date)
          .then((planDefinition) => {
            this.availableFlows = planDefinition.flows;
            return this._editableFlowsByGroups();
          })
      };
    }

    $onChanges (changes) {
      super.$onChanges(changes);

      // SIM: https://sim.amazon.com/issues/SOP-10319
      // This is done instead of using the bindingChangesFunction provided by the abstract,
      // to prevent unnecessary calls due to a change of the edit type.
      if (Binding.changes.has(changes.editType) && !_.isEmpty(this.availableFlows)) {
        const deferred = this.$q.defer();
        deferred.resolve(this._editableFlowsByGroups());
        this.itemSelectorInterface = {
          items: () => deferred.promise
        };
      }
    }
  }

  angular.module('application.components')
    .component('flowItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        editType: '<'
      }),
      controller: FlowItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
