/* globals AbstractElementComponent, Configuration, Ready */
(function () {
  'use strict';

  class ScopeSelectorController {
    constructor (APPLICATION_SCOPE_UPDATE_EVENT_KEY, $authentication, $rootScope) {
      this.APPLICATION_SCOPE_UPDATE_EVENT_KEY = APPLICATION_SCOPE_UPDATE_EVENT_KEY;
      this.$authentication = $authentication;
      this.$rootScope = $rootScope;
      this.orderedScopeOptions = [];
      this.orderedTenantOptions = [];
    }

    static create (APPLICATION_SCOPE_UPDATE_EVENT_KEY, $authentication, $rootScope) {
      return new ScopeSelectorController(APPLICATION_SCOPE_UPDATE_EVENT_KEY, $authentication, $rootScope);
    }

    _getOrderedTenants () {
      if (!_.isEmpty(this.orderedTenantOptions)) {
        return this.orderedTenantOptions;
      }
      const userScopes = this.$authentication.profile().scopes;
      this.orderedTenantOptions = Configuration.scopes.uniqueTenantsFromScopes(userScopes);
      if (_.isNil(this.selectedTenant)) {
        let tenant = Configuration.scopes.current().tenant || _.head(this.orderedTenantOptions);
        // If tenant was extracted from a stale local storage scope that user no longer has access to, pick a tenant from the available tenant options.
        if (!this.orderedTenantOptions.find((tenantOption) => tenantOption.is(tenant))) {
          tenant = _.head(this.orderedTenantOptions);
        }
        this.onTenantSelection(tenant);
      }
      return this.orderedTenantOptions;
    }

    getSelectedTenantName () {
      return _.get(this.selectedTenant, 'name');
    }

    hasMultiTenantAccess () {
      return this.tenantOptions().length > 1;
    }

    isDataEmpty () {
      return _.isEmpty(this.scopeOptions());
    }

    isSelectedScope (scope) {
      return Configuration.scopes.current().is(scope);
    }

    isSelectedTenant (tenant) {
      return Configuration.scopes.current().tenant.is(tenant);
    }

    loadData () {
      if (this.$authentication.isAuthenticated()) {
        this._getOrderedTenants();
      }
      return this;
    }

    onScopeSelection (item) {
      this.$rootScope.$broadcast(this.APPLICATION_SCOPE_UPDATE_EVENT_KEY, item);
    }

    onTenantSelection (tenant) {
      this.selectedTenant = tenant;
    }

    scopeOptions () {
      if (!this.$authentication.isAuthenticated() || _.isEmpty(this.$authentication.profile().scopes)) {
        return [];
      }
      const selectedTenantId = _.get(this.selectedTenant, 'id');
      if (!_.isEmpty(this.orderedScopeOptions) && selectedTenantId === this.filterScopesByTenant) {
        return this.orderedScopeOptions;
      }
      this.filterScopesByTenant = selectedTenantId;
      // Scopes are sorted to improve usability for regions with sub-scopes: https://issues.amazon.com/issues/SOP-8255
      const scopes = _.sortBy(Configuration.scopes.allByTenant(this.filterScopesByTenant), [(scope) => scope.isComposite() ? -1 : 0, 'name']);
      const orderedScopes = [];
      scopes.forEach((scope) => {
        orderedScopes.push(scope);
        _.sortBy(scope.subscopes, 'name').forEach((subscope) => orderedScopes.push(subscope));
      });
      const userScopes = this.$authentication.profile().scopes;
      this.orderedScopeOptions = orderedScopes.filter((scope) => scope.isAny(userScopes));
      const currentScope = Configuration.scopes.current();
      if (!_.isEmpty(this.orderedScopeOptions) && _.get(currentScope, 'tenant.id') !== selectedTenantId) {
        const closestScopeInSelectedTenant = _.find(this.orderedScopeOptions, (scope) => scope.code === currentScope.code || scope.name === currentScope.name);
        this.onScopeSelection(closestScopeInSelectedTenant || _.head(this.orderedScopeOptions));
      }
      return this.orderedScopeOptions;
    }

    tenantOptions () {
      if (!this.$authentication.isAuthenticated()) {
        return [];
      }
      return this._getOrderedTenants();
    }
  }

  class ShortcutSelectorController {
    constructor (shortcut, userProfile) {
      this.shortcut = shortcut;
      this.userProfile = userProfile;
      this.state = Ready.create({
        isDataEmptyFn: () => !_.isUndefined(this.shortcuts) && _.isEmpty(this.shortcuts),
        isDataErrorFn: () => this.dataResponseError,
        isLoadingFn: () => this.loading
      });
    }

    static create (shortcut, userProfile) {
      return new ShortcutSelectorController(shortcut, userProfile);
    }

    _fetchShortcuts () {
      this.shortcuts = undefined;
      this.loading = true;
      this.dataResponseError = false;
      this.userProfile.getShortcuts()
        .then((shortcuts) => this.shortcuts = shortcuts)
        .catch(() => this.dataResponseError = true)
        .finally(() => this.loading = false);
    }

    getRouteIconClass (icon) {
      return `fa fa-fw ${icon}`;
    }

    loadData () {
      // Check for user profile update in progress and add a listener if alias matches the authenticated user alias.
      if (!this.userProfile.isProfileUpdateInProgress()) {
        this._fetchShortcuts();
      }
      this.userProfile.$onProfileUpdate(() => this._fetchShortcuts());
      return this;
    }

    openShortcut (shortcut) {
      return this.shortcut.openShortcut(shortcut);
    }
  }

  class SiteHeaderController extends AbstractElementComponent {
    static get $inject () {
      return ['APPLICATION_SCOPE_UPDATE_EVENT_KEY', '$authentication', '$element', '$location', '$navigation', '$rootScope', 'share', 'shortcut', 'userProfile'];
    }

    constructor (APPLICATION_SCOPE_UPDATE_EVENT_KEY, $authentication, $element, $location, $navigation, $rootScope, share, shortcut, userProfile) {
      super($element);
      this.APPLICATION_SCOPE_UPDATE_EVENT_KEY = APPLICATION_SCOPE_UPDATE_EVENT_KEY;
      this.$authentication = $authentication;
      this.$location = $location;
      this.$navigation = $navigation;
      this.$rootScope = $rootScope;
      this.share = share;
      this.shortcut = shortcut;
      this.userProfile = userProfile;
    }

    _loadData () {
      this.anchors = Configuration.navigation.anchors;
      this.scopeSelector = ScopeSelectorController
        .create(this.APPLICATION_SCOPE_UPDATE_EVENT_KEY, this.$authentication, this.$rootScope)
        .loadData();
      this.shortcutSelector = ShortcutSelectorController
        .create(this.shortcut, this.userProfile)
        .loadData();
    }

    availableLinks (anchor) {
      if (_.has(anchor, 'link')) {
        return this.isLinkAvailable(anchor.link) ? [anchor.link] : [];
      }
      if (_.has(anchor, 'links')) {
        return anchor.links.filter((link) => this.isLinkAvailable(link));
      }
      return [];
    }

    decorateLink (link) {
      return this.$navigation.decorateLink(this.getLink(link));
    }

    getLink (link) {
      return link[this.$location.path()] || link.DEFAULT;
    }

    isConfigMenuDividerVisible (index) {
      const isVisible = (link) => !_.isNil(link) && this.isLinkAvailable(link);
      const currentLink = this.anchors.administration.links[index];
      if (!isVisible(currentLink)) {
        return false;
      }
      const nextLink = _.head(this.anchors.administration.links.slice(index + 1).filter((link) => isVisible(link)));
      return !_.isNil(nextLink) && currentLink.configMenuSection !== nextLink.configMenuSection;
    }

    isLinkAvailable (link) {
      if (!this.$authentication.hasAccess(link.authorization)) {
        return false;
      }

      let available = true;
      if (_.isBoolean(link.exclude)) {
        available = available && !link.exclude;
      }
      if (_.isBoolean(link.include)) {
        available = available && link.include;
      }
      if (_.has(link.exclude, 'scopes')) {
        available = available && !link.exclude.scopes.includes(Configuration.scopes.current().portalCode);
      }
      if (_.has(link.exclude, 'environments')) {
        available = available && !link.exclude.environments.includes(Configuration.environment.current().designator);
      }
      if (_.has(link.include, 'routes')) {
        available = available && link.include.routes.includes(this.$location.path());
      }
      return available;
    }

    isMultipleLinkAnchor (anchor) {
      return this.availableLinks(anchor).length > 1;
    }

    isSingleLinkAnchor (anchor) {
      return this.availableLinks(anchor).length === 1;
    }

    shareUrl (isOpen) {
      if (isOpen) {
        this.urlLoading = true;
        this.shareableUrl = undefined;
        this.share.create()
          .then((url) => this.shareableUrl = url)
          .finally(() => this.urlLoading = false);
      }
    }

    showClock () {
      return !this.scopeSelector.isDataEmpty();
    }

    urlReadyState () {
      return this.urlLoading ? 'loading' : 'ready';
    }
  }

  angular.module('application.components')
    .component('siteHeader', {
      controller: SiteHeaderController,
      templateUrl: 'templates/components/site-header.component.html'
    });
})();
