(function () {
  'use strict';
  class ScopeController {
    static get $inject () {
      return ['APPLICATION_SCOPE_UPDATE_EVENT_KEY', '$location', '$rootScope', '$routeParams'];
    }

    constructor (APPLICATION_SCOPE_UPDATE_EVENT_KEY, $location, $rootScope, $routeParams) {
      $location.path($routeParams.target);
      $rootScope.$broadcast(APPLICATION_SCOPE_UPDATE_EVENT_KEY, $routeParams.scope);
    }
  }
  angular.module('application.controllers').controller('ScopeController', ScopeController);
})();
