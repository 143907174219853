/* globals AWS, Configuration, Identity */
(function () {
  'use strict';
  /**
   * An $http wrapper service this bundles standard HTTP request mechanics for the application and
   * centralizes the use of the portalAuth service for AWS signing and call authorization.
   *
   * @name application.services.request
   * @example
   *   request
   *     .for('https://service.domain.com', 'parts', 'of', 'path')
   *     .withAwsCredentials('service', 'region')
   *     .get({ p: 'parameter' });
   */
  const isIgnored = (key, options = {}) => _.includes(options.ignore, key);

  /**
   * Prunes the parameters object to remove null, empty, and undefined properties.
   * @param options an optional hash of options:
   *   ignore: Array of keys this should not be pruned.
   * @return the pruned parameters.
   */
  const filterParams = (parameters, options) => {
    if (!_.isEmpty(parameters)) {
      return _.pickBy(parameters, (value, key) => {
        if (isIgnored(key, options)) {
          return true;
        }
        if (_.isNil(value)) {
          return false;
        }
        if ((_.isObjectLike(value) || _.isString(value)) && _.isEmpty(value)) {
          return false;
        }
        return true;
      });
    }
  };

  /**
   * Prunes the payload object to remove null, empty, and undefined properties.
   * Compacts arrays to identity values when constituent objects are defined with an identity.
   * @param options an optional hash of options:
   *   ignore: Array of keys this should not be pruned.
   * @return the pruned payload.
   */
  const filterBody = (payload, options) => {
    const doNotFilter = _.get(options, 'doNotFilter', false),
          doNotCompactArrays = _.get(options, 'doNotCompactArrays', false);
    if (doNotFilter) {
      return payload;
    }

    const prunePayload = (payload) => {
      const newPayload = {};
      _.forEach(payload, (value, key) => {
        // Ignored properties should not be modified / pruned
        if (isIgnored(key, options)) {
          newPayload[key] = value;
          return;
        }
        // Empty fields should be ignored
        if (!_.isNumber(value) && !_.isBoolean(value) && !Array.isArray(value) && _.isEmpty(value)) {
          return;
        }
        if (Array.isArray(value)) {
          newPayload[key] = value;
          if (!doNotCompactArrays) {
            // Arrays should be flattened down to identity values
            newPayload[key] = value.map((obj) => Identity.of(obj));
          }
        } else if (_.isObjectLike(value)) {
          // Nested object properties should adhere to the constraints above
          newPayload[key] = prunePayload(value);
        } else {
          // Non empty fields should be kept
          newPayload[key] = value;
        }
      });
      return newPayload;
    };

    return prunePayload(payload);
  };

  const buildOptions = (credentials, params, headers, portalAuth) => {
    const options = { headers: {} };

    if (!_.isEmpty(credentials)) {
      options.aws = credentials;
      Object.assign(options.headers, portalAuth.authorizationHeaders());
    }

    if (!_.isEmpty(params)) {
      options.params = params;
    }

    if (!_.isEmpty(headers)) {
      Object.assign(options.headers, headers);
    }

    return options;
  };

  const returnData = (response) => response.data;

  class AuthenticatedRequest {
    constructor (portalAuth) {
      this.portalAuth = portalAuth;
    }

    withAwsCredentials (service, region, scope) {
      this.service = service;
      this.region = region;
      this.scope = scope;
      return this;
    }

    credentials () {
      if (_.isString(this.service) && _.isString(this.region) && _.isString(this.scope)) {
        return this.portalAuth.credentials[this.service]()
          // The AWS-SigV4-Signer requires calls to be regionalized.
          .then((credentials) => _.defaults({ region: this.region }, credentials));
      }
      return this.portalAuth.credentials.empty();
    }
  }

  class AWSRequest extends AuthenticatedRequest {
    constructor (portalAuth, serviceKey) {
      super(portalAuth);
      this.serviceKey = serviceKey;
    }

    getAWSCredentials (credentials) {
      return new AWS.Credentials(credentials);
    }

    credentials () {
      const awsService = Configuration.endpoints.endpoint(this.serviceKey);
      this.withAwsCredentials(this.serviceKey, awsService.region, Configuration.scopes.current().code);
      return super.credentials();
    }
  }

  class Builder extends AuthenticatedRequest {
    constructor ($http, portalAuth, url) {
      super(portalAuth);
      this.url = url;
      this.$http = $http;
    }

    withParams (params, options) {
      this.params = filterParams(params, options);
      return this;
    }

    withHeaders (headers, options) {
      this.headers = filterParams(headers, options);
      return this;
    }

    withBody (body, options) {
      this.body = filterBody(body, options);
      return this;
    }

    delete () {
      return this.credentials().then(
        (credentials) => this.$http.delete(
          this.url,
          Object.assign({ data: this.body }, buildOptions(credentials, this.params, this.headers, this.portalAuth))
        ).then(returnData)
      );
    }

    get () {
      return this.credentials().then(
        (credentials) => this.$http.get(
          this.url,
          buildOptions(credentials, this.params, this.headers, this.portalAuth)
        ).then(returnData)
      );
    }

    post () {
      return this.credentials().then(
        (credentials) => this.$http.post(
          this.url,
          this.body,
          buildOptions(credentials, this.params, this.headers, this.portalAuth)
        ).then(returnData)
      );
    }

    put () {
      return this.credentials().then(
        (credentials) => this.$http.put(
          this.url,
          this.body,
          buildOptions(credentials, this.params, this.headers, this.portalAuth)
        ).then(returnData)
      );
    }
  }

  class Base {
    constructor (baseUrl, $http, portalAuth) {
      this.baseUrl = baseUrl;
      this.portalAuth = portalAuth;
      this.$http = $http;
    }

    withPortalScope (scope) {
      this.includeScope = true;
      this.scope = _.get(Configuration.scopes.find(scope), 'portalCode', Configuration.scopes.current().portalCode);
      return this;
    }

    withScope (scope) {
      this.includeScope = true;
      this.scope = _.get(Configuration.scopes.find(scope), 'code', Configuration.scopes.current().code);
      return this;
    }

    withVersion (value) {
      if (_.isString(value)) {
        this.version = value;
      }
      return this;
    }

    withAwsCredentials (service) {
      this.service = service;
      return this;
    }

    for () {
      const scope = this.scope || Configuration.scopes.current().code;
      const urlParts = _.toArray(arguments);

      let awsService,
          baseUrl = this.baseUrl;
      if (this.includeScope) {
        urlParts.unshift(scope);
      }
      if (this.version) {
        urlParts.unshift(this.version);
      }
      if (_.isString(this.service)) {
        awsService = Configuration.endpoints.endpoint(this.service);
        baseUrl = awsService.url;
      }
      urlParts.unshift(baseUrl);
      const builder = new Builder(this.$http, this.portalAuth, urlParts.join('/'));
      if (!_.isNil(awsService)) {
        builder.withAwsCredentials(this.service, awsService.region, scope);
      }
      return builder;
    }

  }

  class CloudWatchRequest extends AWSRequest {
    constructor (portalAuth, serviceKey) {
      super(portalAuth, serviceKey);
    }

    post (logGroupName, logStreamName) {
      return this.credentials().then((credentials) => {
        const awsCredentials = this.getAWSCredentials(credentials);
        const cloudwatchlogs = new AWS.CloudWatchLogs({ credentials: awsCredentials, region: this.region });
        return cloudwatchlogs.createLogStream({ logGroupName, logStreamName }).promise();
      });
    }

    put (logGroupName, logStreamName, logEvents, sequenceToken) {
      return this.credentials().then((credentials) => {
        const awsCredentials = this.getAWSCredentials(credentials);
        const cloudwatchlogs = new AWS.CloudWatchLogs({ credentials: awsCredentials, region: this.region });
        return cloudwatchlogs.putLogEvents({ logEvents, logGroupName, logStreamName, sequenceToken }).promise();
      });
    }
  }

  class S3Request extends AWSRequest {
    constructor (portalAuth, serviceKey) {
      super(portalAuth, serviceKey);
    }

    put (bucket, key, file) {
      return this.credentials().then((credentials) => {
        const awsCredentials = this.getAWSCredentials(credentials);
        const s3 = new AWS.S3({ credentials: awsCredentials, region: this.region });
        return s3.putObject({ Body: file, Bucket: bucket, ContentType: file.type, Key: key }).promise();
      });
    }

    get (bucket, key) {
      return this.credentials().then((credentials) => {
        const awsCredentials = this.getAWSCredentials(credentials);
        const s3 = new AWS.S3({ credentials: awsCredentials, region: this.region });
        return s3.getObject({ Bucket: bucket, Key: key }).promise();
      });
    }
  }

  class RequestService {
    static get $inject () {
      return ['$http', 'portalAuth'];
    }

    constructor ($http, portalAuth) {
      this.portalAuth = portalAuth;
      this.$http = $http;
    }

    awsService (serviceKey) {
      return new Base(undefined, this.$http, this.portalAuth).withAwsCredentials(serviceKey);
    }

    base () {
      return new Base(_.toArray(arguments).join('/'), this.$http, this.portalAuth);
    }

    cloudWatch (serviceKey) {
      return new CloudWatchRequest(this.portalAuth, serviceKey);
    }

    for () {
      return new Builder( this.$http, this.portalAuth, _.toArray(arguments).join('/'));
    }

    s3 (serviceKey) {
      return new S3Request(this.portalAuth, serviceKey);
    }
  }

  /**
   * Helper class to allow the Portal Auth Service to make API calls through the Request Service.
   */
  class AWSBase {
    static aws ($http, portalAuth) {
      return new Base(undefined, $http, portalAuth);
    }
  }

  window.AWSBase = Object.freeze(AWSBase);
  angular.module('application.services').service('request', RequestService);
})();
