/* globals Binding, Configuration */
(function () {
  'use strict';
  class AllocationTreeItemSelectorController {
    static get $inject () {
      return ['allocationTree'];
    }

    constructor (allocationTree) {
      this.allocationTree = allocationTree;
    }

    $onChanges (changes) {
      if (_.isNil(this.flow) || _.isNil(this.groupBy) || this.groupBy.isEmpty() || _.isNil(this.groupByFilters)) {
        this.itemSelectorInterface = undefined;
        return;
      }

      // Do not perform any update if the flow and groupBy values have not changed
      if (Binding.changes.none(changes.flow, changes.groupBy, changes.groupByFilters)) {
        return;
      }

      this.itemSelectorInterface = {
        items: () => this.allocationTree.tree(Configuration.scopes.current().code, this.flow.id, this.groupByFilters, this.groupBy)
      };
    }
  }

  angular.module('application.components')
    .component('allocationTreeItemSelector', {
      bindings: {
        /*
         * @flow the flow to retrieve the allocation tree for.
         */
        flow: '<',
        /*
         * @groupBy list of grains to retrieve data from the allocation tree for.
         */
        groupBy: '<',
        /*
         * @groupByFilters object restricting which data is retrieved from the allocation tree.
         */
        groupByFilters: '<',
        /*
         * @initialSelections defines the initial selection preference.
         */
        initialSelections: '<',
        /*
         * @onSelectionChange is a callback to the parent whenever the current selection changes.
         */
        onSelectionChange: '&'
      },
      controller: AllocationTreeItemSelectorController,
      templateUrl: 'templates/components/allocation-tree-item-selector.component.html'
    });
})();
