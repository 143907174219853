/* globals Ready */
(function () {
  'use strict';
  const NON_GRAIN_COLUMNS = 3;

  class AllocationTreeEditorController {
    constructor () {
      this.state = Ready.create({
        isDataEmptyFn: () => _.isNil(this.data) || _.size(this.data.records) <= 0
      });
      this.selectAll = {};
    }

    toggleActionSelections (actionType) {
      this.data.records.forEach((record) => record[actionType] = this.selectAll[actionType]);
    }

    $onChanges () {
      if (_.isNil(this.data)) {
        return;
      }
      this.grains = _.filter(this.data.granularity.values(), (grain) => !grain.isScopeGrain());
      this.totalColumns = this.grains.length + NON_GRAIN_COLUMNS;
    }
  }

  angular.module('application.components')
    .component('allocationTreeEditor', {
      bindings: {
        data: '<'
      },
      controller: AllocationTreeEditorController,
      templateUrl: 'templates/components/allocation-tree-editor.component.html'
    });
})();
