/* globals Configuration */
(function () {
  'use strict';

  const BETA_DESIGNATOR = 'beta';
  const GAMMA_DESIGNATOR = 'gamma';
  const LOCAL_DESIGNATOR = 'local';
  const PROD_DESIGNATOR = 'prod';
  const UNKNOWN_DESIGNATOR = 'unknown';

  class Environment {
    constructor (designation, designator, inEnvironment) {
      this.designation = designation;
      this.designator = designator;
      this.inEnvironment = inEnvironment;
    }

    static create (designation, designator, inEnvironment) {
      return Object.freeze(new Environment(designation, designator, inEnvironment));
    }

    is (designator) {
      return this.designator === designator;
    }

    isBeta () {
      return this.is(BETA_DESIGNATOR);
    }

    isDev () {
      return this.isLocal() || this.isBeta();
    }

    isGamma () {
      return this.is(GAMMA_DESIGNATOR);
    }

    isLocal () {
      return this.is(LOCAL_DESIGNATOR);
    }

    isProd () {
      return this.is(PROD_DESIGNATOR);
    }

    isUnknown () {
      return this.is(UNKNOWN_DESIGNATOR);
    }
  }
  Object.freeze(Environment);

  const ENVIRONMENTS = Object.freeze({
    beta: Environment.create(_.capitalize(BETA_DESIGNATOR), BETA_DESIGNATOR, _.includes(['portal.beta.sandop.a2z.com', 'd3l8jd3hzbw3ah.cloudfront.net'], window.location.hostname)),
    gamma: Environment.create(_.capitalize(GAMMA_DESIGNATOR), GAMMA_DESIGNATOR, _.includes(['portal.gamma.sandop.a2z.com', 'd23b76i4jfeka3.cloudfront.net'], window.location.hostname)),
    local: Environment.create(_.capitalize(LOCAL_DESIGNATOR), LOCAL_DESIGNATOR, _.includes(['portal.local.sandop.a2z.com'], window.location.host)),
    prod: Environment.create(_.capitalize(PROD_DESIGNATOR), PROD_DESIGNATOR, _.includes(['portal.sandop.a2z.com', 'd1qs33tvzlz1du.cloudfront.net'], window.location.hostname)),
    unknown: Environment.create(_.capitalize(UNKNOWN_DESIGNATOR), UNKNOWN_DESIGNATOR, false)
  });

  let currentEnvironment, originEnvironment;

  function setEnvironment (environment) {
    currentEnvironment = environment;
    originEnvironment = environment;
  }

  if (ENVIRONMENTS.local.inEnvironment) {
    setEnvironment(ENVIRONMENTS.local);
  } else if (ENVIRONMENTS.prod.inEnvironment) {
    setEnvironment(ENVIRONMENTS.prod);
  } else if (ENVIRONMENTS.gamma.inEnvironment) {
    setEnvironment(ENVIRONMENTS.gamma);
  } else if (ENVIRONMENTS.beta.inEnvironment) {
    setEnvironment(ENVIRONMENTS.beta);
  } else {
    setEnvironment(ENVIRONMENTS.unknown);
  }

  class Environments {
    static get designators () {
      return Object.freeze({
        BETA: BETA_DESIGNATOR,
        GAMMA: GAMMA_DESIGNATOR,
        LOCAL: LOCAL_DESIGNATOR,
        PROD: PROD_DESIGNATOR,
        UNKNOWN: UNKNOWN_DESIGNATOR
      });
    }

    static current () {
      return currentEnvironment;
    }

    static isEnvironmentDesignator (designator) {
      return _.has(ENVIRONMENTS, designator);
    }

    static origin () {
      return originEnvironment;
    }

    static override (designator) {
      if (this.isEnvironmentDesignator(designator)) {
        currentEnvironment = ENVIRONMENTS[designator];
      }
    }

    static reset () {
      currentEnvironment = originEnvironment;
    }
  }

  // localhost can have the environment overridden to facilitate testing against all environments from localhost
  if (Environments.current().isLocal()) {
    Environments.override(Configuration.storage.session.get('environment'));
  }

  Configuration.defineProperty('environment', Environments);
})();
