/* globals Enumeration */
(function () {
  'use strict';
  const TRANSFORMER_TYPE = Enumeration.create('allocationTree', 'edit', 'manualBacklog', 'plan').asStringValue((enumerable) => enumerable.concat('Transformer'));

  class TransformerFactoryService {
    static get $inject () {
      return ['$injector'];
    }

    constructor ($injector) {
      this.$injector = $injector;
    }

    _getTransformer (transformerType) {
      if (!_.includes(_.values(TRANSFORMER_TYPE), transformerType)) {
        throw new Error(`TransformerFactory: "${transformerType}" is an unknown transformer service.`);
      }
      const transformer = this.$injector.get(transformerType);
      if (_.isNil(transformer)) {
        throw new Error(`TransformerFactory: no service available for "${transformerType}".`);
      }
      return transformer;
    }

    toDocument (transformerType, ...parameters) {
      return this._getTransformer(transformerType).toDocument(...parameters);
    }

    toPackages (transformerType, ...parameters) {
      return this._getTransformer(transformerType).toPackages(...parameters);
    }

    get transformerType () {
      return TRANSFORMER_TYPE;
    }
  }

  angular.module('application.services').service('transformerFactory', TransformerFactoryService);
})();
