/* globals Enums, GrainMappingRatioGroup, Ready, DateUtils, Configuration, Grain */
(function () {
  'use strict';

  class GrainMappingRatioGroupListController {
    static get $inject () {
      return ['alerts', '$authentication', 'overlay', 'planConfiguration', '$q', '$scope', '$timeout', '$uibModal', 'warehouses'];
    }

    constructor (alerts, $authentication, overlay, planConfiguration, $q, $scope, $timeout, $uibModal, warehouses) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.overlay = overlay;
      this.planConfiguration = planConfiguration;
      this.$q = $q;
      this.$scope = $scope;
      this.$timeout = $timeout;
      this.$uibModal = $uibModal;
      this.warehouses = warehouses;

      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.grainMappingRatio),
        isLoadingFn: () => this.loading
      });
    }

    _loadData () {
      this.loading = true;
      this.grainMappingRatio = [];
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.allowedGrainValues = {};
      this.ratioConfig = Configuration.ratiosMap.get(this.ratioType.id);
      this.planConfiguration[this.ratioType.id]()
        .list()
        .then((data) => this.grainMappingRatio = this._groupRatiosBySourceGrains(data))
        .finally(() => this.loading = false);
    }

    _getGrainValues (grain) {
      if (grain === Enums.GrainIdentity.node) {
        return this.warehouses.warehouse().list()
          .then((data) => _.map(data, 'warehouseId'))
          .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch Warehouses' }));
      }
      if (grain === Enums.GrainIdentity.productLine) {
        return this.planConfiguration.productLine().list({ asOfDate: DateUtils.format(Enums.DateFormat.IsoDate) })
          .then((data) => _.map(data, 'name'))
          .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch Product Lines' }));
      }
      return this.planConfiguration.grainDefinition(grain, DateUtils.format(Enums.DateFormat.IsoDate))
        .then((data) => _.map(data.values, 'id'))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to fetch ${Grain.known[grain].name}s` }));
    }

    _groupRatiosBySourceGrains (data){
      const granularityMap = new Map();
      data.forEach((groupMappingRatio) => {
        const sourceKey = this._getSourceKey(groupMappingRatio);
        if (!granularityMap.has(sourceKey)) {
          granularityMap.set(sourceKey, GrainMappingRatioGroup.create(groupMappingRatio.granularity, groupMappingRatio.groupRatioValue, this.ratioConfig));
          return;
        }
        const grainMappingRatioGroup = granularityMap.get(sourceKey);
        grainMappingRatioGroup.addRatio(groupMappingRatio.groupRatioValue, groupMappingRatio.granularity.destinationGrain);
        granularityMap.set(sourceKey, grainMappingRatioGroup);
      });
      return Array.from(granularityMap.values());
    }

    _getSourceKey (groupMappingRatio) {
      return this.ratioConfig.sourceGrain.map((grain) => groupMappingRatio.granularity.sourceGrain[grain]).join('-');
    }

    getSourcePath (grain) {
      return `sourceGrains.${grain}`;
    }

    isAddDisabled () {
      return this.loading || _.isEmpty(this.grainMappingRatio);
    }

    findRatio (sourceGrains) {
      return this.grainMappingRatio.find((ratioValue) => _.isEqual(ratioValue.sourceGrains, sourceGrains));
    }

    _loadAllowedGrainValues () {
      this.ratioConfig.sourceGrain.forEach((grain) => {
        this.$q.all({ grainValue: this._getGrainValues(grain) }).then((data) => this.allowedGrainValues[grain] = data.grainValue);
      });
      this.ratioConfig.destinationGrain.forEach((grain) => {
        this.$q.all({ grainValue: this._getGrainValues(grain) }).then((data) => this.allowedGrainValues[grain] = data.grainValue);
      });
    }

    openModal (event, isCreate, grainMappingRatioGroup = GrainMappingRatioGroup.create(this.grainMappingRatio.granularity, this.grainMappingRatio.groupRatioValue, this.ratioConfig)) {
      if (!_.isNil(event)) {
        event.stopPropagation();
      }
      this._loadAllowedGrainValues();
      this.$uibModal
        .open({
          component: 'grainMappingRatioGroupModal',
          resolve: {
            allowedGrainValues: () => this.allowedGrainValues,
            grainMappingRatioGroup: () => _.cloneDeep(grainMappingRatioGroup),
            isCreate: () => isCreate,
            ratioConfig: () => this.ratioConfig,
            type: () => this.ratioType
          },
          size: Enums.ModalSize.EXTRA_LARGE
        }).result.then((model) => {
          this.overlay.show(`Saving ${_.startCase(this.ratioType.name)}`);
          const grainMappingRatioGroup = this.findRatio(model.sourceGrains);
          if (_.isNil(grainMappingRatioGroup)) {
            const createPayload = [];
            model.ratioValues.forEach((ratioValue) => createPayload.push(this._getCreateOrUpdatePayload(model, ratioValue)));
            this._handleRatio('create', createPayload);
            return;
          }
          const grainMappingRatioPayloads = this._getGroupRatioPayload(model, grainMappingRatioGroup);
          if (!_.isEmpty(grainMappingRatioPayloads.deletePayload)) {
            this._handleRatio('delete', grainMappingRatioPayloads.deletePayload);
          }
          if (!_.isEmpty(grainMappingRatioPayloads.createPayload)) {
            this._handleRatio('create', grainMappingRatioPayloads.createPayload);
          }
          if (!_.isEmpty(grainMappingRatioPayloads.updatePayload)) {
            this._handleRatio('update', grainMappingRatioPayloads.updatePayload);
          }
        })
        .catch(_.noop)
        .finally(() => {
          this.$timeout(() => {
            this.overlay.hide();
            this._loadData();
          }, 2000);
        });
    }

    _handleRatio (operationType, payload) {
      this.planConfiguration[this.ratioType.id]()[operationType](payload)
        .then(() => this.alerts.success(`Successfully ${operationType}d ${_.startCase(this.ratioType.name)}s`))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to ${operationType} ${_.startCase(this.ratioType.name)}s` }));
    }

    _getGroupRatioPayload (model, grainMappingRatioGroup) {
      const grainMappingRatioPayloads = {
        createPayload: [],
        deletePayload: [],
        updatePayload: []
      };
      model.ratioValues.forEach((ratioValue) => {
        const existingGrainMappingRatio = grainMappingRatioGroup.findRatio(ratioValue.destinationKey);

        if (_.isNil(existingGrainMappingRatio)) {
          grainMappingRatioPayloads.createPayload.push(this._getCreateOrUpdatePayload(model, ratioValue));
          return;
        }
        if (existingGrainMappingRatio.ratio !== ratioValue.ratio) {
          grainMappingRatioPayloads.updatePayload.push(this._getCreateOrUpdatePayload(model, ratioValue));
        }
      });

      grainMappingRatioGroup.ratioValues.forEach((ratioValue) => {
        const grainMappingRatioModel = model.findRatio(ratioValue.destinationKey);

        if (_.isNil(grainMappingRatioModel)) {
          grainMappingRatioPayloads.deletePayload.push(this._getDeletePayload(model, ratioValue));
        }
      });
      return grainMappingRatioPayloads;
    }

    _getCreateOrUpdatePayload (model, ratioValue) {
      return {
        granularity: {
          destinationGrain: ratioValue.destinationKey,
          sourceGrain: model.sourceGrains
        },
        groupRatioType: this.ratioType.id,
        groupRatioValue: parseFloat(ratioValue.ratio),
        scopeCode: Configuration.scopes.current().code,
        updatedBy: this.$authentication.profile().alias
      };
    }

    _getDeletePayload (model, ratioValue) {
      return {
        destinationGrain: ratioValue.destinationKey,
        sourceGrain: model.sourceGrains
      };
    }

    $onInit () {
      this._loadData();
    }
  }

  /**
   * Component to display list of grain mapping ratio groups
   * @name application.components.grain-mapping-ratio-group-list
   * @example
   * <grain-mapping-ratio-group-list></grain-mapping-ratio-group-list>
   */
  angular.module('application.components')
    .component('grainMappingRatioGroupList', {
      bindings: {
        /*
         * @ratioType String that determines which type of grain mapping ratio group
         * is being displayed.
         */
        ratioType: '<'
      },
      controller: GrainMappingRatioGroupListController,
      templateUrl: 'templates/components/grain-mapping-ratio-group-list.component.html'
    });
})();
