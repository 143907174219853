(function () {
  'use strict';

  class Stateful {
    constructor (definition) {
      if (_.isNil(definition)) {
        throw new Error('Stateful: definition must not be nil');
      }
      this.definition = definition;
    }

    static create (definition) {
      return new Stateful(definition);
    }

    reset (target) {
      if (_.isNil(target)) {
        throw new Error('Stateful: target must not be nil');
      }

      _.forEach(this.definition, (value, key) => {
        let resetKey = true;
        let func = value;
        if (Array.isArray(value)) {
          if (value.length !== 2) {
            throw new Error(`Stateful: array definition must have 2 elements for key: "${key}"`);
          }

          // index 0 is initialization/reset function for key
          func = value[0];
          // index 1 is a Boolean stating whether the value is re-initialized if it does not exist
          resetKey = value[1];
        }

        if (!_.isFunction(func)) {
          throw new Error(`Stateful: non-function encountered for key: "${key}"`);
        }

        if (!_.isBoolean(resetKey)) {
          throw new Error(`Stateful: non-Boolean encountered for key: "${key}"`);
        }

        if (!resetKey && _.has(target, key)) {
          // Do not reset the key on target if target already has the key defined
          return;
        }

        _.set(target, key, func());
      });

      return this;
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#toJSON()_behavior
    toJSON () {
      return this.definition;
    }
  }

  window.Stateful = Object.freeze(Stateful);
})();
