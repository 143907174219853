/* globals Configuration */
/***
 * This file defines various configuration blocks that are intended to be temporary solutions
 * until a more optimal strategy for acquiring this information is made available.
 * This entire file can be viewed as a series of TODOs to find better ways to acquire this configuration.
 ***/
(function () {
  'use strict';
  Configuration
    // Defines the needles available for matching flow identifiers
    .defineProperty(
      'flowNeedles',
      {
        efnVendorReturns: 'EfnVendorReturns',
        mod: 'Mod',
        sourceCostOptimization: 'SourceCostOptimization',
        transfer: 'Transfer',
        transferLikeFlowsRegEx: /Mod|Transfer|Transshipment|SourceCostOptimization/,
        transshipment: 'Transshipment'
      }
    )
    // Defines the data ingestion workflow cut-off time for each scope in 24 hour clock in its time zone.
    .defineProperty(
      'dataIngestionWorkflowCutOffTimes',
      {
        AE: '07:00:00',
        AU: '07:00:00',
        BR: '07:00:00',
        CA: '07:00:00',
        DE: '08:15:00',
        EG: '07:00:00',
        ES: '08:15:00',
        FR: '08:15:00',
        GB: '08:15:00',
        IN: '07:00:00',
        IT: '08:15:00',
        JP: '07:00:00',
        MX: '07:00:00',
        SA: '07:00:00',
        SE: '08:15:00',
        SG: '07:00:00',
        TR: '08:15:00',
        US: '07:00:00'
      }
    )
    // Defines plan types that support historical plans for use in plan viewers.
    .defineProperty(
      'historicalSupportPlans',
      [
        Object.freeze({
          id: 'DailyPlanViewer_GasTankFcBtIgDailyActuals_HistoricalPlans',
          planType: 'gasTankFcBtIgDailyActuals',
          schemaName: 'BINTYPE-NETWORKINVENTORYGROUP-WAREHOUSE-DAILY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_BinType_HistoricalPlans',
          planType: 'binTypeFcWeeklyPlan',
          schemaName: 'BUSINESSENTITY-NETWORKINVENTORYGROUP-BINTYPE-WAREHOUSE-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_GasTankPloNigBtg_HistoricalPlans',
          planType: 'gasTankPloNigBtgWeeklyPlan',
          schemaName: 'PRODUCTLINEOWNER-NETWORKINVENTORYGROUP-BINTYPEGROUP-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_PlStFc_HistoricalPlans',
          planType: 'plStFcWeeklyPlan',
          schemaName: 'PRODUCTLINE-SORTTYPE-WAREHOUSE-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWeeklyInputPlan_HistoricalPlans',
          planType: 'subSameDayUnconstrainedOutboundFcWeeklyInputPlan',
          schemaName: 'WAREHOUSE-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWeeklyPlan_HistoricalPlans',
          planType: 'subSameDayUnconstrainedOutboundFcWeeklyPlan',
          schemaName: 'WAREHOUSE-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWtWeeklyInputPlan_HistoricalPlans',
          planType: 'subSameDayUnconstrainedOutboundFcWtWeeklyInputPlan',
          schemaName: 'WAREHOUSE-WINDOWTYPE-WEEKLY'
        }),
        Object.freeze({
          id: 'WeeklyPlanViewer_SubSameDayUnconstrainedOutboundFcWtWeeklyPlan_HistoricalPlans',
          planType: 'subSameDayUnconstrainedOutboundFcWtWeeklyPlan',
          schemaName: 'WAREHOUSE-WINDOWTYPE-WEEKLY'
        })
      ]
    )
    // Defines the number of weeks in a weekly plan for the scope.
    // EU scopes use 17 week plans while all other scopes use 13 week plans.
    // TODO: This value can come from the Plan Definition, which is defined as planHorizonInDays.
    .defineProperty(
      'planHorizonInWeeks',
      {
        AE: 13,
        AU: 13,
        BR: 13,
        CA: 17,
        DE: 17,
        EG: 13,
        ES: 17,
        EU: 17,
        FR: 17,
        GB: 17,
        IN: 13,
        IT: 17,
        JP: 13,
        MX: 13,
        NL: 17,
        PL: 17,
        SA: 13,
        SE: 17,
        SG: 13,
        SSD_US: 13,
        TR: 17,
        US: 17,
        ZA: 13
      }
    )
    // Defines grid data display overrides for enumerated plan types.
    // Currently this is only required for ACU plans to specify the number of decimal
    // places, but this could be extended via additional business requirements (https://sim.amazon.com/issues/SOP-12399)
    // Ideally, this belongs in a formal construct such as a Plan Definition.
    .defineProperty(
      'planDisplayOverrides',
      {
        acuBtIgFcWeeklyPlan: {
          decimalPlaces: 4
        },
        acuPlStWeeklyPlan: {
          decimalPlaces: 4
        }
      }
    )
    // Temporary mapping for scope to other scopes that can receive transfer volume.
    // This is a temporary measure as a workaround for PanEU.  See SIM: https://issues.amazon.com/issues/SOP-13492
    .defineProperty(
      'transferScopeMappings',
      {
        AE: ['AE'],
        AU: ['AU'],
        BR: ['BR'],
        CA: ['CA', 'US', 'MX'],
        DE: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        EG: ['EG'],
        ES: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        EU: ['EU', 'GB'],
        FR: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        GB: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        IN: ['IN'],
        IT: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        JP: ['JP'],
        MX: ['MX', 'CA', 'US'],
        NL: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        PL: ['DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PL', 'SE'],
        SA: ['SA'],
        SE: ['SE'],
        SG: ['SG'],
        TR: ['TR'],
        US: ['US', 'CA', 'MX'],
        ZA: ['ZA']
      }
    );
})();
