/* globals Configuration, DateUtils, Enums, Mapper, AbstractSourceBasedModel */
(function () {
  'use strict';
  const DEFAULT_DAILY_PLAN_DAYS_TO_END_DATE = 28;

  const MISSING_DRAFT_TYPE_SENTINEL = 'NoDraftType';

  const DEFAULT_SCENARIO = Object.freeze({
    scenario: Enums.Plan.Scenario.PROD,
    subScenario: Enums.Plan.Scenario.PROD
  });

  const PLAN_IDENTITY_PROPERTIES = Object.freeze([
    'date',
    'draft',
    'scenario',
    'scope',
    'subScenario',
    'type',
    'version'
  ]);

  const PROPERTIES = Object.freeze(
    PLAN_IDENTITY_PROPERTIES.concat([
      'client',
      'configVersion',
      'endDate',
      'id',
      'paginationKeys',
      'ratioID',
      'schemaName',
      'startDate'
    ])
  );

  class PlanMetadata extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);

      if (_.isNil(this.paginationKeys)) {
        this.paginationKeys = [];
      }

      if (_.isNil(this.draft)) {
        this.draft = MISSING_DRAFT_TYPE_SENTINEL;
      }

      if (_.isNil(this.scenario)) {
        this.scenario = DEFAULT_SCENARIO.scenario;
      }

      if (_.isNil(this.subScenario)) {
        this.subScenario = DEFAULT_SCENARIO.subScenario;
      }

      // https://issues.amazon.com/issues/SOP-3721: default to date for plans that are missing startDate property
      if (_.isNil(this.startDate)) {
        this.startDate = this.date;
      }

      // https://issues.amazon.com/issues/SOP-3721: default to relative date for plans that are missing endDate property
      if (_.isNil(this.endDate)) {
        this.endDate = this.isDailyPlan() ?
          DateUtils.fromOffset(this.startDate, DEFAULT_DAILY_PLAN_DAYS_TO_END_DATE - 1, Enums.TimeUnit.DAY) :
          DateUtils.fromOffset(this.startDate, Configuration.planHorizonInWeeks[this.scope] - 1);
      }
    }

    static get defaultScenario () {
      return DEFAULT_SCENARIO;
    }

    static identity (metadata) {
      return Mapper.collect(metadata, PLAN_IDENTITY_PROPERTIES);
    }

    static isCapacityPlan (planType) {
      return _.includes([
        Enums.Plan.PlanType.ACU_BT_IG_FC_WEEKLY_PLAN,
        Enums.Plan.PlanType.ACU_PL_ST_WEEKLY_PLAN,
        Enums.Plan.PlanType.ALLOCATED_CAPACITY_PLO_NIG_WEEKLY_PLAN,
        Enums.Plan.PlanType.BIAS_CORRECTED_PLO_NIG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.BSF_FC_PL_ST_BT_WEEKLY_PLAN,
        Enums.Plan.PlanType.BSF_PL_ST_BT_WEEKLY_PLAN,
        Enums.Plan.PlanType.BSF_PLO_NIG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.CAPACITY_BT_NIG_FC_WEEKLY_PLAN,
        Enums.Plan.PlanType.CAPACITY_BT_NIG_PLO_FC_WEEKLY_PLAN,
        Enums.Plan.PlanType.CAPACITY_BT_UT_FC_WEEKLY_PLAN,
        Enums.Plan.PlanType.CAPACITY_NCG_NIG_RATIO_WEEKLY_PLAN,
        Enums.Plan.PlanType.CONSTRAINT_IG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.FLEXIBLE_GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.GAS_TANK_FC_BT_IG_WEEKLY_PLAN,
        Enums.Plan.PlanType.GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.ITR_BT_IG_FC_WEEKLY_PLAN,
        Enums.Plan.PlanType.UNCONSTRAINED_BIAS_CORRECTED_PLO_NIG_BTG_WEEKLY_PLAN,
        Enums.Plan.PlanType.UNCONSTRAINED_GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN
      ], planType);
    }

    static isFinalDraft (draftType) {
      return Enums.Plan.DraftType.FINAL === draftType;
    }

    static isNetworkPlanningPlan (planType) {
      // As of now, this method only considers the FBA NVF plans from the Network Planning process since the remaining plans have not been created yet.
      // Once the remaining Network Planning plans are created, they can be added to this list to be considered as a plan in the Network Planning process.
      return _.includes([
        Enums.Plan.PlanType.CSC_ORIGIN_LAG_NIG_WEEKLY_PLAN,
        Enums.Plan.PlanType.FILL_RATE_ORIGIN_LAG_NIG_WEEKLY_PLAN,
        Enums.Plan.PlanType.SHIPMENT_ARRIVALS_ORIGIN_LAG_NIG_WEEKLY_PLAN,
        Enums.Plan.PlanType.TOTAL_FBA_NVF_NIG_WEEKLY_PLAN
      ], planType);
    }

    static isPlStFcWeeklyPlan (planType) {
      return Enums.Plan.PlanType.PL_ST_FC_WEEKLY_PLAN === planType;
    }

    static isValid (metadata) {
      // A valid PlanMetadata object contains all of the identity properties
      return !_.isNil(metadata) && _.every(PLAN_IDENTITY_PROPERTIES, (property) => _.has(metadata, property) && _.isString(metadata[property]) && !_.isEmpty(metadata[property]));
    }

    get historicalSubScenario () {
      if (!this.isWeeklyHistoricalPlan()) {
        return this.subScenario;
      }
      return _.startCase(this.subScenario)
        .slice(_.startCase(this.subScenario).indexOf(' ') + 1)
        .replace(' ', '-')
        .concat(' Forecast');
    }

    get historicalWeek () {
      return this.isWeeklyHistoricalPlan() ? _.toNumber(/\d{1,2}/.exec(this.subScenario)) : undefined;
    }

    get lastUpdatedAt () {
      return _.get(this.source.properties, 'lastUpdatedAt');
    }

    /*
     * Clears metadata fields unique to a plan's version.
     */
    clearVersionProperties () {
      this.client = undefined;
      this.source.client = undefined;
      this.source.properties = {};
      this.version = undefined;
      this.source.version = undefined;
    }

    displayName (format = Enums.Plan.DisplayFormat.SHORT) {
      let timestamp;
      const planName = this.name || _.startCase(this.type);
      switch (format) {
        case Enums.Plan.DisplayFormat.FULL:
          return `${this.date} | ${planName} | ${_.startCase(this.draft)} | ${this.scenario} / ${this.subScenario} | v${this.version}`;
        case Enums.Plan.DisplayFormat.FULL_WITH_TIMESTAMP:
          timestamp = _.isNil(this.lastUpdatedAt) ? '-' : DateUtils.of(this.lastUpdatedAt).format(Enums.DateFormat.GmtOffsetDateTime);
          return `${this.date} | ${planName} | ${_.startCase(this.draft)} | ${this.scenario} / ${this.subScenario} | v${this.version} (${timestamp})`;
        case Enums.Plan.DisplayFormat.LONG:
          return `${planName} | ${_.startCase(this.draft)} | ${this.scenario} / ${this.subScenario}`;
        case Enums.Plan.DisplayFormat.SHORT:
          return planName;
        case Enums.Plan.DisplayFormat.DRAFT:
          return _.startCase(this.draft);
        case Enums.Plan.DisplayFormat.SCENARIO:
          return `${this.scenario} / ${this.subScenario}`;
        case Enums.Plan.DisplayFormat.VERSION:
          return `v${this.version}`;
        default:
          throw new Error(`PlanMetadata: display format is not supported: "${format}"`);
      }
    }

    equals (compare) {
      return !_.isNil(compare) && _.isEqual(this.identity(), compare.identity());
    }

    identity () {
      return PlanMetadata.identity(this);
    }

    isAlpsDailyPlan () {
      return this.type === Enums.Plan.PlanType.ALPS_DAILY_PLAN;
    }

    isBaselineDraft () {
      return this.draft === Enums.Plan.DraftType.BASELINE;
    }

    isBinTypeFcWeeklyPlan () {
      return this.type === Enums.Plan.PlanType.BIN_TYPE_FC_WEEKLY_PLAN;
    }

    isCapacityPlan () {
      return PlanMetadata.isCapacityPlan(this.type);
    }

    isCapSignalBinTypeFcWeeklyPlan () {
      return this.type === Enums.Plan.PlanType.CAP_SIGNAL_BIN_TYPE_FC_WEEKLY_PLAN;
    }

    isCapSignalPlStFcWeeklyPlan () {
      return this.type === Enums.Plan.PlanType.CAP_SIGNAL_PL_ST_FC_WEEKLY_PLAN;
    }

    isDailyPlan () {
      return _.includes(this.schemaName, Enums.TimeGranularity.DAILY);
    }

    isFcWeeklyPlan () {
      return this.isBinTypeFcWeeklyPlan() || this.isPlStFcWeeklyPlan();
    }

    isHistoricalPlan () {
      return _.includes(_.toLower(this.subScenario), _.toLower('_historical'));
    }

    isLaborPlan () {
      return this.type === Enums.Plan.PlanType.LABOR_PLAN;
    }

    isNetworkPlanningPlan () {
      return PlanMetadata.isNetworkPlanningPlan(this.type);
    }

    isPlStFcWeeklyPlan () {
      return PlanMetadata.isPlStFcWeeklyPlan(this.type);
    }

    isPrefinalDraft () {
      return this.draft === Enums.Plan.DraftType.PRE_FINAL;
    }

    isValid () {
      return PlanMetadata.isValid(this);
    }

    isWeeklyHistoricalPlan () {
      // Looking for patterns like:
      // prod_1_week_Historical
      // prod_13_week_Historical
      // notProd_1_week_Historical
      // notProd_13_week_Historical
      return /^[a-zA-Z0-9]+_\d{1,2}_week_Historical$/.test(this.subScenario);
    }

    uniqueIdentifierKey (delimiter = '|') {
      const keyParts = [this.scope, this.date, this.type, this.draft, this.scenario, this.subScenario, this.version];
      return keyParts.join(delimiter);
    }
  }

  window.PlanMetadata = Object.freeze(PlanMetadata);
})();
