/* globals Configuration */
(function () {
  'use strict';
  class AuthenticateController {
    static get $inject () {
      return ['$location', 'midway', 'portalAuth'];
    }

    constructor ($location, midway, portalAuth) {
      // Authenticate users with Midway.
      midway.fetchMidwayToken()
        // Authorize users with the Portal Auth Service.
        .then((token) => portalAuth.authorize(token))
        // AuthN/Z failed, user is not authorized.
        .catch(() => $location.url(Configuration.routes.authorization.path));
    }
  }
  angular.module('application.controllers').controller('AuthenticateController', AuthenticateController);
})();
