/* globals AbstractElementComponent, Binding */
(function () {
  'use strict';
  class EditTypeSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', 'planConfiguration'];
    }

    constructor ($element, planConfiguration) {
      super($element, {
        isDataEmptyFn: () => Array.isArray(this.editTypes) && _.isEmpty(this.editTypes),
        isEditTypeSelectedFn: (editType) => editType === this.selectedEditType,
        isLoadingFn: () => this.loading,
        isWaitingFn: () => _.isNil(this.plan)
      });
      this.planConfiguration = planConfiguration;
      this.loading = false;
    }

    _initialSelection () {
      this.changeEditType(_.includes(this.editTypes, this.initialEditType) ? this.initialEditType : _.head(this.editTypes));
    }

    _loadData () {
      // If the plan is not provided we can not call PCS to get the list of editTypes
      if (this.state.isWaiting()) {
        return;
      }

      this.loading = true;
      this.planConfiguration.planDefinition().get(this.plan.type, this.plan.date)
        .then((definition) => {
          this.editTypes = definition.editTypes();
          this._initialSelection();
        })
        .catch(() => this.editTypes = [])
        .finally(() => {
          this.loading = false;
          if (_.isFunction(this.onLoadItems)) {
            this.onLoadItems({ editTypes: this.editTypes });
          }
        });
    }

    changeEditType (editType) {
      this.selectedEditType = editType;
      this.onSelectionChange({ editType });
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (Binding.changes.has(changes.plan)) {
        this._loadData();
      } else if (Binding.changes.has(changes.initialEditType)) {
        // Do nothing if the current initial edit type is the same as the selected edit type
        if (Binding.changes.current(changes.initialEditType) === this.selectedEditType) {
          return;
        }
        // On an initial edit type change we don't want to reload the list, just make a selection if available.
        this._initialSelection();
      }
    }
  }

  angular.module('application.components')
    .component('editTypeSelector', {
      bindings: {
        initialEditType: '<',
        onLoadItems: '&',
        onSelectionChange: '&',
        plan: '<'
      },
      controller: EditTypeSelectorController,
      templateUrl: 'templates/components/edit-type-selector.component.html'
    });
})();
