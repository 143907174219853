/* globals Mapper */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'dataSource',
    'dataType',
    'type'
  ]);

  class DataCell {
    constructor (source, rowDataType) {
      source = _.isPlainObject(source) ? _.defaults(source, { dataType: rowDataType }) : { dataType: rowDataType, value: source };
      Mapper.apply(source, this, PROPERTIES);
      // 'value' property should always be present on DataCell. For nil values, 'value' should be set to null. ('Mapper.apply' will skip setting 'value' property for nil values.)
      this.value = _.isUndefined(source.value) ? null : source.value;
    }

    static create (source, rowDataType) {
      return new DataCell(source, rowDataType);
    }

    hasValue () {
      return !_.isNil(this.value);
    }
  }

  window.DataCell = Object.freeze(DataCell);
})();
