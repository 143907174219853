/* globals AbstractElementComponent, Configuration, DateUtils */
(function () {
  'use strict';
  const ONE_SECOND_IN_MILLISECONDS = 1000;

  const DATE_TIME_DISPLAY_FORMAT = 'MMM Do HH:mm';
  const TIME_DISPLAY_FORMAT = 'HH:mm';

  class CountdownController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', '$interval'];
    }

    constructor ($element, $interval) {
      super($element);
      this.$interval = $interval;
    }

    _cancelInterval () {
      this.$interval.cancel(this.timer);
      delete this.timer;
    }

    _createInterval () {
      this.timer = this.$interval(this._updateClock.bind(this), ONE_SECOND_IN_MILLISECONDS);
    }

    _loadData () {
      this.timeZone = Configuration.scopes.current().timeZone;
      this._updateClock();
      this._createInterval();
    }

    _updateClock () {
      this.displayDateTime = DateUtils.of(this.timeZone)
        .format(this.hideDate ? TIME_DISPLAY_FORMAT : DATE_TIME_DISPLAY_FORMAT);
    }

    $onDestroy () {
      this._cancelInterval();
    }
  }

  angular.module('application.components')
    .component('clock', {
      bindings: {
        // If true, Date is not displayed alongside Time. Default: false
        hideDate: '<'
      },
      controller: CountdownController,
      templateUrl: 'templates/components/clock.component.html'
    });
})();
