/* globals Configuration, DateUtils */
(function () {
  'use strict';
  class SiteFooterController {
    buildVersion () {
      return Configuration.build.version.split('-')[1];
    }

    buildTimestamp () {
      return DateUtils.of(Configuration.build.timestamp).toISOString();
    }

    currentYear () {
      return (new Date()).getFullYear();
    }

    environment () {
      return Configuration.environment.current().designation;
    }
  }

  angular.module('application.components')
    .component('siteFooter', {
      controller: SiteFooterController,
      templateUrl: 'templates/components/site-footer.component.html'
    });
})();
