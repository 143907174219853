/* globals AbstractElementComponent, Configuration */
(function () {
  'use strict';

  class ViewsByScopeController extends AbstractElementComponent {
    constructor () {
      super();
      this.viewsListInfoByScope = {};
    }

    _loadData () {
      this.currentScope = this.initialScope || Configuration.scopes.current().code;
      this.userScopes = _.isArray(this.scopes) ? this.scopes.sort() : [];
    }

    changeScopeTab (scope) {
      _.set(this.viewsListInfoByScope, [scope, 'enableLoading'], true);
    }

    displayViewsCount (scope) {
      if (!_.has(this.viewsListInfoByScope, [scope, 'viewsCount'])) {
        return;
      }
      return `(${this.viewsListInfoByScope[scope].viewsCount})`;
    }

    updateViewsCount (scope, viewsCount) {
      this.viewsListInfoByScope[scope].viewsCount = viewsCount;
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('viewsByScope', {
      bindings: {
        alias: '<',
        initialScope: '@',
        scopes: '<'
      },
      controller: ViewsByScopeController,
      templateUrl: 'templates/components/views-by-scope.component.html'
    });
})();
