(function () {
  'use strict';
  class AlertController {
    toggleDetails () {
      this.alert.showDetails = !this.alert.showDetails;
    }

    $onInit () {
      this.alert.showDetails = false;
    }
  }

  angular.module('application.components')
    .component('alert', {
      bindings: {
        alert: '<'
      },
      controller: AlertController,
      templateUrl: 'templates/components/alert.component.html'
    });
})();
