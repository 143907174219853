(function () {
  'use strict';
  const EMPTY_PREFERENCES = { defaults: {}, user: {} };
  const DEFAULTS_PREFERENCE_TYPE_KEY = 'defaults';
  const USER_PREFERENCE_TYPE_KEY = 'user';
  const GLOBAL_HIVE_KEY = 'global';
  const GRANULARITIES_KEY = 'granularities';

  let preferences;
  let getPromises = [];

  const getPreferenceValue = (type, hive, key) => {
    let value = preferences[type][hive][key];
    // Correct for case of 'granularities' property under KEY to be backward compatible with granularity preferences.
    // For Example: user.plStFcWeeklyPlan.granularities.granularities = [ ... ]
    // New granularity preferences are being stored as: user.plStFcWeeklyPlan.granularities = [ ... ]
    if (key === GRANULARITIES_KEY) {
      value = _.get(value, GRANULARITIES_KEY, value);
    }
    return value;
  };

  const constructPreferencesResponse = (value, type) => ({
    arePersonal: type === USER_PREFERENCE_TYPE_KEY,
    selections: _.get(value, 'selections', value),
    updatedBy: value.updatedBy,
    updatedOn: value.updatedOn
  });

  const extractPreferences = (type, hive, key) => {
    // Check for the specific preference
    if (_.has(preferences, [type, hive, key])) {
      const value = getPreferenceValue(type, hive, key);
      return constructPreferencesResponse(value, type);
    }
    // Check if there is a global preference
    if (_.has(preferences, [type, GLOBAL_HIVE_KEY, key])) {
      const value = getPreferenceValue(type, GLOBAL_HIVE_KEY, key);
      return constructPreferencesResponse(value, type);
    }
    // Check in defaults if this is a user preference request
    if (type === USER_PREFERENCE_TYPE_KEY) {
      return extractPreferences(DEFAULTS_PREFERENCE_TYPE_KEY, hive, key);
    }
    return undefined;
  };

  const invalidate = (oldPreferences, portalStore, $timeout) => {
    preferences = undefined;
    portalStore.getUserPreferences().then(
      (result) => {
        preferences = result;
        _.defaults(preferences, EMPTY_PREFERENCES);
        _.forEach(getPromises, (entry) => entry.deferred.resolve(extractPreferences(entry.type, entry.hive, entry.key)));
        getPromises = [];
      },
      () => $timeout(invalidate, 5000, true, oldPreferences, portalStore, $timeout)
    );
  };

  class PreferencesService {
    static get $inject () {
      return ['portalStore', '$q', '$timeout'];
    }

    constructor (portalStore, $q, $timeout) {
      this.portalStore = portalStore;
      this.$q = $q;
      this.$timeout = $timeout;

      /*
      * Preference type.
      */
      this.DEFAULTS = DEFAULTS_PREFERENCE_TYPE_KEY;
      this.USER = USER_PREFERENCE_TYPE_KEY;
      this.UPDATE_METHOD = {
        defaults: portalStore.updateScopeDefaults.bind(portalStore),
        user: portalStore.updateUserPreferences.bind(portalStore)
      };

      invalidate(EMPTY_PREFERENCES, this.portalStore, this.$timeout);
    }

    /*
     * Gets the preference setting for the given type (DEFAULTS, USER), hive, key.
     * MERGED is a composite of the USER preferences with fallback to DEFAULT preferences.
     * Returns a promise that resolves to the requested preference object.
     */
    get (type, hive, key) {
      if (!_.includes([DEFAULTS_PREFERENCE_TYPE_KEY, USER_PREFERENCE_TYPE_KEY], type)) {
        throw new Error(`preferences.service: type is not in allowed range [defaults, user]: ${type}`);
      }
      const deferredGetEntry = {
        deferred: this.$q.defer(),
        hive: hive,
        key: key,
        type: type
      };
      if (!_.isNil(preferences)) {
        deferredGetEntry.deferred.resolve(extractPreferences(type, hive, key));
      } else {
        getPromises.push(deferredGetEntry);
      }
      return deferredGetEntry.deferred.promise;
    }

    /*
     * Sets all the preferences for the given type (DEFAULTS, USER), hive, key.
     * A falsey hive value is treated as a request to set the global hive.
     * Broadcasts on all keys in old and new preference objects.
     * Returns a promise that resolves when the preferences are known to be persisted.
     */
    set (type, newPlanPreferences, hive, key) {
      if (!_.includes([DEFAULTS_PREFERENCE_TYPE_KEY, USER_PREFERENCE_TYPE_KEY], type)) {
        throw new Error(`preferences.service: preferenceType is not in allowed range [defaults, user]: ${type}`);
      }
      const newPreferences = _.clone(preferences);
      hive = hive || GLOBAL_HIVE_KEY;
      _.set(newPreferences, [type, hive, key], _.clone(newPlanPreferences));
      return this.UPDATE_METHOD[type](newPreferences[type]).then(() => preferences = newPreferences);
    }

    /*
     * Unsets the 'key' from the preferences for the given type (DEFAULTS, USER), hive, key.
     * A falsey hive value is treated as a request to unset the global hive.
     * Returns a promise that resolves when the preferences are known to be persisted.
     */
    unset (type, hive, key) {
      if (!_.includes([DEFAULTS_PREFERENCE_TYPE_KEY, USER_PREFERENCE_TYPE_KEY], type)) {
        throw new Error(`preferences.service: preferenceType is not in allowed range [defaults, user]: ${type}`);
      }
      const newPreferences = _.clone(preferences);
      hive = hive || GLOBAL_HIVE_KEY;
      _.unset(newPreferences, [type, hive, key]);
      return this.UPDATE_METHOD[type](newPreferences[type]).then(() => preferences = newPreferences);
    }
  }

  angular.module('application.services').service('preferences', PreferencesService);
})();
