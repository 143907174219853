/* globals AbstractSourceBasedModelModal, Configuration, Enums */
(function () {
  'use strict';

  class WarehouseModalController extends AbstractSourceBasedModelModal {
    static get $inject () {
      return ['$authentication', 'warehouses'];
    }

    constructor ($authentication, warehouses) {
      super('warehouse');
      this.$authentication = $authentication;
      this.warehouses = warehouses;
    }

    deleteWarehouse () {
      this.modalInstance.close({ mode: 'delete', warehouse: this.warehouse });
    }

    getDeleteConfirmationMessage () {
      return `Are you sure you want to delete FC <strong>${this.warehouse.warehouseId}</strong>? This action cannot be undone.`;
    }

    isDeleteAllowed () {
      return this.$authentication.hasAdministratorAccess() && this.isEdit();
    }

    saveWarehouse () {
      const updatedPlanTypes = _.map(_.filter(this.planTypes, 'isSelected'), 'id').sort();
      this.setProperty('planTypes', updatedPlanTypes);

      // Write tags-input collections back to model
      this.setProperty('groups', this.tags.groups);
      this.setProperty('inboundArcs', this.tags.inboundArcs);
      this.setProperty('outboundArcs', this.tags.outboundArcs);
      this.modalInstance.close({ mode: 'save', warehouse: this.warehouse });
    }

    sortArcs (query) {
      return this.warehousesList.filter((warehouse) => warehouse.toLowerCase().includes(query.toLowerCase()));
    }

    sortGroups (query) {
      return this.groups.filter((group) => group.toLowerCase().includes(query.toLowerCase()));
    }

    $onInit () {
      super.$onInit();

      this.warehousesList = _.map(this.resolve.warehouseList, (warehouse) => warehouse.warehouseId);
      this.warehouses.allGroups().then((allGroups) => this.groups = _.map(allGroups, (group) => group.groupId));

      this.planTypes = [
        {
          id: Enums.WarehousePlanType.CAPACITY_PLAN,
          isSelected: this.warehouse.isEnabledForPlanning(Enums.WarehousePlanType.CAPACITY_PLAN),
          name: 'Capacity'
        },
        {
          id: Enums.WarehousePlanType.DAILY_PLAN,
          isSelected: this.warehouse.isEnabledForPlanning(Enums.WarehousePlanType.DAILY_PLAN),
          name: 'Daily'
        },
        {
          id: Enums.WarehousePlanType.NETWORK_PLAN,
          isSelected: this.warehouse.isEnabledForPlanning(Enums.WarehousePlanType.NETWORK_PLAN),
          name: 'Network'
        },
        {
          id: Enums.WarehousePlanType.WEEKLY_PLAN,
          isSelected: this.warehouse.isEnabledForPlanning(Enums.WarehousePlanType.WEEKLY_PLAN),
          name: 'Weekly'
        }
      ];

      // Setup tags-input collections
      this.tags = {
        groups: _.clone(this.warehouse.groups),
        inboundArcs: _.clone(this.warehouse.inboundArcs),
        outboundArcs: _.clone(this.warehouse.outboundArcs)
      };

      if (this.isAdd()) {
        const scope = Configuration.scopes.current();
        this.setProperty('organizationalUnitCode', scope.code);
        this.setProperty('scope', scope.code);
        this.setProperty('timeZone', scope.timeZone);
      }
    }
  }

  /**
   * Component for the warehouse modals.
   * @name application.components.warehouse-modal
   * @example
   * <warehouse-modal/>
   */
  angular.module('application.components')
    .component('warehouseModal', {
      bindings: {
        modalInstance: '<',
        resolve: '<'
      },
      controller: WarehouseModalController,
      controllerAs: 'modalCtrl',
      templateUrl: 'templates/components/warehouse-modal.component.html'
    });
})();
