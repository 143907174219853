/* globals ClassDecorator */
(function () {
  'use strict';

  class Term {
    constructor (term, definition, ...classes) {
      this.class = ClassDecorator.toClassString(ClassDecorator.assemble(...classes));
      this.definition = definition;
      this.term = term;
    }

    static create (term, definition, ...classes) {
      return new Term(term, definition, ...classes);
    }

    isEmpty () {
      return _.isNil(this.term) && _.isNil(this.definition);
    }
  }

  class Dictionary {
    constructor () {
      this.list = [];
    }

    static create () {
      return new Dictionary();
    }

    add (term, definition, ...classes) {
      this.list.push(Term.create(term, definition, ...classes));
      return this;
    }

    clear () {
      this.list.length = 0;
      return this;
    }

    isEmpty () {
      return _.isEmpty(this.list);
    }
  }

  window.Dictionary = Object.freeze(Dictionary);
})();
