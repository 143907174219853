/* globals AbstractSourceBasedModel, Enums, Identity, Name */
(function () {
  'use strict';

  const TREE_NODE_TYPE = Object.freeze({
    GROUP: 'group',
    LEAF: 'leaf',
    ROOT: 'root'
  });

  const PROPERTIES = Object.freeze([
    'displayRank',
    'value'
  ]);

  class TreeNode extends AbstractSourceBasedModel {
    constructor (source, parent) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.id = Identity.of(this.source);
      this.name = Name.ofIdentity(this.source);
      this.children = [];
      this.parent = parent;
      this.source = source;
      this.isActive = _.sop.getPropertyOrMethod(this.source, 'isActive', true);
      this.isFiltered = false;
      this.isHidden = false;
      this.isIndeterminate = false;
      this.isSelected = false;
      this.isVirtual = _.sop.getPropertyOrMethod(this.source, 'isVirtual', false);
      this.selectedChildrenCount = 0;
      this.type = TREE_NODE_TYPE.ROOT;
      if (!_.isNil(this.parent)) {
        this.type = TREE_NODE_TYPE.LEAF;
        this.parent.addChildren(this);
      }
    }

    addChildren (...children) {
      children = _.filter(_.flatten(children), (child) => child instanceof TreeNode);
      if (!_.isEmpty(children)) {
        if (this.isLeaf()) {
          this.type = TREE_NODE_TYPE.GROUP;
        }
        this.children.push(...children);
      }
      return this;
    }

    applyToSiblings (callback) {
      if (!_.isNil(this.parent)) {
        _.forEach(this.parent.children, (sibling) => {
          if (this.equals(sibling)) {
            return;
          }
          callback(sibling);
        });
      }
      return this;
    }

    ascend (callback) {
      callback(this);
      if (!_.isNil(this.parent)) {
        this.parent.ascend(callback);
      }
      return this;
    }

    assignGroups (maxGroupDepth = 0, groupDepth = 0, groupIdentity = 0) {
      this.group = groupIdentity;
      const notAtMaxGroupDepth = groupDepth < maxGroupDepth;

      let nextGroupDepth = groupDepth,
          nextGroupIdentity = groupIdentity;
      if (notAtMaxGroupDepth) {
        nextGroupDepth += 1;
      }
      this.children.forEach((child) => {
        if (notAtMaxGroupDepth) {
          nextGroupIdentity += 1;
        }
        nextGroupIdentity = child.assignGroups(maxGroupDepth, nextGroupDepth, nextGroupIdentity);
      });
      return nextGroupIdentity;
    }

    assignLevels (level = 1) {
      this.level = level;
      const nextLevel = level + 1;
      this.children.forEach((child) => child.assignLevels(nextLevel));
      return this;
    }

    equals (compare) {
      return this === compare;
    }

    filter (callback) {
      this.traverse((node) => {
        if (node.isLeaf()) {
          node.isFiltered = callback(node);
        } else {
          node.isFiltered = _.every(node.children, 'isFiltered');
        }
      });
      return this;
    }

    getLeafNodes () {
      return this.getNodes((node) => node.isLeaf());
    }

    getNodes (filterFn) {
      const nodes = [];
      this.traverse((node) => {
        if (_.isFunction(filterFn) ? filterFn(node) : true) {
          nodes.push(node);
        }
      });
      return nodes;
    }

    getSelectedNodes () {
      return this.getNodes((node) => node.isSelected);
    }

    hasChildren () {
      return !_.isEmpty(this.children);
    }

    hide (value) {
      this.isHidden = !!value;
      return this;
    }

    isGroup () {
      return this.type === TREE_NODE_TYPE.GROUP;
    }

    isLeaf () {
      return this.type === TREE_NODE_TYPE.LEAF;
    }

    isMatch (property, pattern) {
      if (_.isFunction(this.source.isMatch)) {
        return this.source.isMatch(property, pattern);
      }
      return _.sop.match(this, [property, `source.${property}`], pattern);
    }

    isRoot () {
      return this.type === TREE_NODE_TYPE.ROOT;
    }

    resetSelectedChildrenCount () {
      this.selectedChildrenCount = this.children.filter((child) => child.isSelected).length;
    }

    select (value, callback = _.noop) {
      // This method traverses first down and then up the tree when a select occurs.
      this.traverse((node) => {
        if (node.isFiltered) {
          return;
        }
        node.isSelected = value;
        node.isIndeterminate = false;
        node.resetSelectedChildrenCount();
        callback(node);
      });
      this.resetSelectedChildrenCount();
      if (!_.isNil(this.parent)) {
        this.parent.ascend((node) => {
          node.isSelected = _.every(node.children, 'isSelected');
          node.resetSelectedChildrenCount();
          node.isIndeterminate = !node.isSelected && (_.some(node.children, 'isIndeterminate') || _.some(node.children, 'isSelected'));
          callback(node);
        });
      }
      return this;
    }

    setExpansion (state) {
      if (this.hasChildren()) {
        this.isExpanded = !!state;
        if (this.isExpanded) {
          this.applyToSiblings((node) => node.isExpanded = false);
        }
      }
      return this;
    }

    sortChildren (properties = ['displayRank', 'name']) {
      this.children = _.sortBy(this.children, properties);
      return this;
    }

    toggleExpansion () {
      if (!this.hasChildren()) {
        return;
      }
      this.setExpansion(!this.isExpanded);
      return this;
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#toJSON()_behavior
    toJSON () {
      // TreeNode has a circular structure because the children point to the parent. The default toJSON implementation throws
      // "TypeError: Converting circular structure to JSON" during stringify of this object.
      return {
        children: this.children.map((child) => child.toJSON()),
        source: this.source
      };
    }

    traverse (callback) {
      this.children.forEach((child) => child.traverse(callback));
      callback(this);
      return this;
    }
  }

  window.TreeNode = Object.freeze(TreeNode);
})();
