/* globals AbstractServiceEndpoint, clearInterval, Configuration, DataPackage, Enums, Identity, OrchestratorExecution, PlanMetadata, setInterval */
(function () {
  'use strict';
  const CREATE_MODIFICATION = 'CREATE';
  const EDIT_MODIFICATION = 'EDIT';
  const PROMOTE_MODIFICATION = 'PROMOTE';
  const ADD_ACTION = 'ADD';
  const POLL_WINDOW = 1000;

  const getPlanIdentity = (plan) => {
    // Orchestrator does not use the same object for PlanMetadata as Plan Store
    // This results in:
    //   1. The date property being mapped to planDate
    //   2. The type property being mapped to planType
    const identity = PlanMetadata.identity(plan);
    identity.planDate = identity.date;
    delete identity.date;
    identity.planType = identity.type;
    delete identity.type;
    return identity;
  };

  const editValue = (dataPackage, granularityIds) => {
    const editValues = [];
    if (!DataPackage.hasRecordsArray(dataPackage)) {
      return editValues;
    }
    dataPackage.records.forEach((record) => {
      // No values need to be saved from this record
      if (!DataPackage.rowContainsValidEdits(record)) {
        return;
      }
      editValues.push({
        flow: Identity.of(record.metric),
        granularity: _.pick(record.granularity, granularityIds),
        records: _.reduce(record.values, (accumulator, value, index) => {
          if (_.isFinite(value)) {
            accumulator[dataPackage.dates[index]] = value;
          }
          return accumulator;
        }, {})
      });
    });
    return editValues;
  };

  const modificationBody = (dataPackageList, modification, action) => {
    const head = _.head(dataPackageList);
    const granularityIds = _.map(head.granularity.grains.values(Enums.GrainFilter.IS_NATIVE), 'id');
    return {
      action: action,
      editType: head.editType,
      editValues: _.reduce(dataPackageList, (accumulator, dataPackage) => accumulator.concat(editValue(dataPackage, granularityIds)), []),
      filters: head.filters,
      groupBy: head.groupBy,
      modification: modification,
      planMetadata: head.plan.source
    };
  };

  class OrchestratorService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['$q', 'request'];
    }

    constructor ($q, request) {
      super(request, Configuration.services.orchestrator);
      this.$q = $q;
    }

    _modifyPlan (plan, modification, action, uuid) {
      return this.aws()
        .withScope()
        .for('modifyPlan', modification)
        .withParams({ editAction: action, uuid: uuid })
        .withBody(getPlanIdentity(plan))
        .post();
    }

    _upsertPlan (dataPackageList, modification, action) {
      const plan = _.head(dataPackageList).plan;
      let status;
      return this._modifyPlan(plan, modification, action)
        .then((data) => {
          status = _.head(data);
          return this.request
            .for(status.s3Location)
            .withBody(modificationBody(dataPackageList, modification, action))
            .put();
        })
        .then(() => this._modifyPlan(plan, modification, action, status.uuid));
    }

    audit (plan, filters) {
      return this.aws()
        .withScope()
        .for('planModificationAudit', plan.type, plan.scenario, plan.subScenario)
        .withBody(filters)
        .post();
    }

    create (dataPackageList) {
      return this._upsertPlan(dataPackageList, CREATE_MODIFICATION, ADD_ACTION);
    }

    edit (dataPackageList) {
      return this._upsertPlan(dataPackageList, EDIT_MODIFICATION, ADD_ACTION);
    }

    execution (weekDate, scenario, options) {
      return this.aws()
        .withScope()
        .for('orchestration', weekDate, 'execution', scenario)
        .withParams(options)
        .get()
        .then((result) => OrchestratorExecution.create(result.execution));
    }

    getEditTracking (input, options) {
      return this.aws()
        .withScope()
        .for('editTracking')
        .withParams(options)
        .withBody({ input })
        .post();
    }

    clonePlan (body) {
      return this.aws()
        .for('clonePlan')
        .withBody(body)
        .post();
    }

    getEditTrackingExecutions (options) {
      return this.aws()
        .withScope()
        .for('editTracking')
        .withParams(options)
        .get();
    }

    modificationStatus (plan, uuid) {
      return this.aws()
        .withScope()
        .for('planModificationStatus')
        .withParams({ uuid })
        .withBody(getPlanIdentity(plan))
        .put();
    }

    pollModification (plan, uuid) {
      const deferred = this.$q.defer();
      const timer = setInterval(() => {
        this.modificationStatus(plan, uuid)
          .then((modification) => {
            modification = _.head(modification);
            if (modification.status === 'ERROR') {
              clearInterval(timer);
              deferred.reject(modification.cause);
            } else if (modification.status === 'DONE') {
              clearInterval(timer);
              deferred.resolve(modification);
            }
          })
          .catch((err) => {
            clearInterval(timer);
            deferred.reject(err);
          });
      }, POLL_WINDOW);

      return deferred.promise;
    }

    promote (plan) {
      return this._modifyPlan(plan, PROMOTE_MODIFICATION);
    }

    updateEditTracking (input, options) {
      return this.aws()
        .withScope()
        .for('editTracking')
        .withParams(options)
        .withBody({ input }, { doNotCompactArrays: true })
        .put();
    }
  }

  angular.module('application.services').service('orchestrator', OrchestratorService);
})();
