/* globals DateUtils, Enums */
(function () {
  'use strict';
  const EMAIL_VARIANTS = Object.freeze(['Inbound', 'Outbound']);

  class DailyFlashMetricsController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.$scope = $scope;
      this.$onInit();
    }

    $onInit () {
      this.$scope.model = {
        datasetDate: DateUtils.fromOffset(undefined, -1, Enums.TimeUnit.DAY),
        datasetMaxDate: DateUtils.format(Enums.DateFormat.IsoDate),
        emailVariants: {
          list: EMAIL_VARIANTS,
          selected: _.head(EMAIL_VARIANTS)
        }
      };

      this.$scope.actions = {
        changeDatasetDate: (date) => {
          if (date === this.$scope.model.datasetDate) {
            return;
          }
          this.$scope.model.datasetDate = date;
        }
      };

      this.$scope.settings = {
        displayAllMetrics: false
      };
    }
  }
  angular.module('application.controllers').controller('DailyFlashMetricsController', DailyFlashMetricsController);
})();
