/* globals AbstractSourceBasedModel, DateUtils, Enums */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'businessEntity',
    {
      deserialize: (definition) => {
        // Sort the numeric values in ascending order and de-duplicate
        ['gl.exclude', 'gl.include', 'iog.exclude', 'iog.include'].forEach(
          (key) => _.set(definition, key, _.uniq(_.sortBy(_.get(definition, key, []))))
        );
        return definition;
      },
      key: 'definition'
    },
    'description',
    'endTime',
    'group',
    'isEditable',
    'isPredicted',
    'isVirtual',
    'lastUpdated',
    'name',
    'overrideName',
    'planTypes',
    'productLineOwner',
    'replacedBy',
    'scopeCode',
    'startTime',
    'tags'
  ]);

  const TEMPLATE = Object.freeze({
    businessEntity: '',
    definition: {
      gl: {
        exclude: [],
        include: []
      },
      iog: {
        exclude: [],
        include: []
      }
    },
    description: '',
    group: '',
    isEditable: true,
    isPredicted: false,
    isVirtual: false,
    name: '',
    planTypes: [],
    productLineOwner: '',
    scopeCode: '',
    tags: []
  });

  class ProductLine extends AbstractSourceBasedModel {
    constructor (source) {
      super(_.defaults(source, TEMPLATE), PROPERTIES, Enums.ModelMutability.MUTABLE);
    }

    get isActive () {
      return DateUtils.isInRange(this.startTime, this.endTime, DateUtils.of(), Enums.TimeUnit.DAY);
    }

    isExisting () {
      return !_.isNil(this.lastUpdated);
    }

    isValid () {
      return !_.isEmpty(this.businessEntity) &&
        !_.isEmpty(this.group) &&
        !_.isEmpty(this.name) &&
        !_.isEmpty(this.scopeCode);
    }
  }

  window.ProductLine = Object.freeze(ProductLine);
})();
