(function () {
  'use strict';
  const SUPPORTED_ICONS = Object.freeze(['fa-sliders', 'fa-bars']);

  class HamburgerDropdownController {
    $onInit () {
      if (!_.includes(SUPPORTED_ICONS, this.icon)) {
        throw new Error('Hamburger: icon is not supported');
      }
    }
  }

  angular.module('application.components')
    .component('hamburgerDropdown', {
      bindings: {
        icon: '@',
        iconTitle: '@'
      },
      controller: HamburgerDropdownController,
      templateUrl: 'templates/components/hamburger-dropdown.component.html',
      transclude: {
        hamburgerMenu: 'hamburgerMenu'
      }
    });
})();
