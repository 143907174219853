/* globals AbstractPackagerService, Comparison, DataCell, DataPackage, DataRow, DateUtils, Enums, Granularities, Grid, Name */
(function () {
  'use strict';

  const getMetricFamilyData = function (metricFamily, startDate, endDate, filters, granularity, plan) {
    const namespace = this.metricsService.resolveNamespace(plan.source.type);
    const body = {
      aggregateGroupBy: ['metric'],
      asOfTime: plan.lastUpdatedAt,
      filters: filters,
      groupBy: granularity.grains.values(Enums.GrainFilter.IS_NATIVE),
      periodEndDate: endDate,
      periodStartDate: startDate,
      planMetadata: [plan.source]
    };
    return this.metricsService.metricFamily(metricFamily, namespace, granularity.plan, granularity.time, body);
  };

  const fetchMetrics = function (startDate, endDate, metricGrouping, configuration, enabled) {
    const forecastsFamily = {
            id: metricGrouping.metricFamilyId,
            metrics: _.filter(metricGrouping.metrics, { type: 'Forecast' })
          },
          actualsFamily = {
            id: metricGrouping.metricFamilyId,
            metrics: _.filter(metricGrouping.metrics, { type: 'Actual' })
          },
          filters = configuration.filters,
          granularity = configuration.granularity,
          promises = {
            comparisons: []
          };
    // Only request data if there are forecast metrics in the metricGrouping
    if (!_.isEmpty(forecastsFamily.metrics)) {
      promises.primary = getMetricFamilyData.call(
        this,
        forecastsFamily,
        configuration.primary.plan.startDate,
        configuration.primary.plan.endDate,
        filters,
        granularity,
        configuration.primary.plan
      );

      enabled.comparisons.forEach((comparisonToggle, index) => {
        if (!comparisonToggle) {
          return;
        }
        const comparison = configuration.comparisons[index];
        promises.comparisons.push(
          getMetricFamilyData.call(
            this,
            forecastsFamily,
            comparison.plan.startDate,
            comparison.plan.endDate,
            filters,
            granularity,
            comparison.plan
          )
        );
      });
    }

    if (!_.isEmpty(actualsFamily.metrics) && enabled.actuals) {
      promises.actuals = getMetricFamilyData.call(
        this,
        actualsFamily,
        startDate,
        endDate,
        filters,
        granularity,
        configuration.primary.plan
      );
    }

    return this.$q.all([promises.primary, promises.actuals, ...promises.comparisons]);
  };

  class DataPackager {
    constructor (dates, grains, metricGrouping, configuration) {
      this.configuration = configuration;
      this.dates = dates;
      this.grains = grains;
      this.metricGrouping = metricGrouping;
    }

    transform (promise) {
      return promise
        .then((data) => ({
          actuals: _.isNil(data[1]) ? [] : [data[1]],
          comparisons: data.splice(2),
          primary: _.isNil(data[0]) ? [] : [data[0]]
        }))
        .then(this.setMetricReferences.bind(this))
        .then(this.collapse.bind(this))
        .then(this.resize.bind(this))
        .then(this.concatenate.bind(this))
        .then(this.sort.bind(this));
    }

    setMetricReferences (datasets) {
      // All datasets will hold references to metrics in Metric family
      const setMetricReference = (datasetList) => {
        const updateRecords = (recordSet) => {
          recordSet.forEach((record) => {
            record.metrics.forEach((item) => {
              item.metric = _.find(this.metricGrouping.metrics, (familyMetric) => Comparison.areMetricsEqual(familyMetric, item));
            });
          });
        };

        datasetList.forEach((dataset) => {
          updateRecords(dataset.records);
          updateRecords(dataset.aggregateRecords);
        });
      };

      setMetricReference(datasets.actuals);
      setMetricReference(datasets.comparisons);
      setMetricReference(datasets.primary);
      return datasets;
    }

    collapse (datasets) {
      // Collapse each dataset to a set of complete data records
      const flattenLists = (datasetsList, dataType) => {
        const createGridRows = (records, index) => _.flatten(_.map(records, (record) =>
          _.flatten(_.map(record.metrics, (item) => DataRow.create({
            dataType: dataType || this.configuration.comparisons[index].datasetClass,
            granularity: _.defaults({ metric: Name.ofMetric(item.metric) }, record.granularity),
            metric: item.metric,
            values: item.values
          })))
        ));

        return _.map(datasetsList, (dataset, index) => {
          dataset.records = createGridRows(dataset.records, index);
          dataset.aggregateRecords = createGridRows(dataset.aggregateRecords, index);
          return dataset;
        });
      };
      return {
        actuals: flattenLists(datasets.actuals, 'actual'),
        comparisons: flattenLists(datasets.comparisons),
        primary: flattenLists(datasets.primary, 'primary')
      };
    }

    resize (datasets) {
      const resizeDatasets = (datasets, plans) => {
        const resizeRecords = (records, plan) => {
          const dateOffset = DateUtils.dateArrayComparator(
            this.dates,
            DateUtils.expansion(plan.startDate, plan.endDate, this.configuration.granularity.time)
          );
          Grid.resize(
            records,
            {
              append: dateOffset.postfix.count,
              prepend: dateOffset.prefix.count,
              property: 'cells',
              value: DataCell.create(null)
            }
          );
        };

        datasets.forEach((dataset, index) => {
          const plan = plans[index].plan;
          if (_.isNil(plan)) {
            return;
          }
          resizeRecords(dataset.records, plan);
          resizeRecords(dataset.aggregateRecords, plan);
        });
      };
      // Resize the primary and comparison data rows
      resizeDatasets(datasets.primary, [this.configuration.primary]);
      resizeDatasets(datasets.comparisons, this.configuration.comparisons);
      return datasets;
    }

    concatenate (datasets) {
      const concatenatedDataset = _.flatten(
        _.concat(
          datasets.actuals,
          datasets.comparisons,
          datasets.primary
        )
      );

      return {
        records: _.reduce(concatenatedDataset, (accumulator, value) => accumulator.concat(value.records), []),
        totals: _.reduce(concatenatedDataset, (accumulator, value) => accumulator.concat(value.aggregateRecords), [])
      };
    }

    sort (dataset) {
      const dataTypes = ['actual', 'primary', ...this.configuration.comparisons.map((comparison) => comparison.datasetClass)];
      return {
        records: Comparison.sort(dataset.records, dataTypes, this.grains.values(), this.metricGrouping.metrics),
        totals: Comparison.sort(dataset.totals, dataTypes, this.grains.values(), this.metricGrouping.metrics)
      };
    }
  }

  class PlanPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['metricsService', '$q'];
    }

    constructor (metricsService, $q) {
      super($q);
      this.metricsService = metricsService;
    }

    /**
     * Combines metric requests to different services from a metric family into one object
     *
     * @dates Array the range of dates being supported
     * @metricGrouping Object the grouping of forecast and actual metric
     * @configuration is a hash containing request metadata:
     *   @primary Object:
     *     @plan Object the primay plan that forecast metrics should be requested for
     *   @comparisons Array:
     *     @plan Object the comparison plan that forecast metrics should be requested for
     *   @granularity Object the granularity that should be applied to each request
     *   @filters Object the filters that should be applied to each request
     * @enabled
     *   @actuals Boolean a flag indicating whether requesting actuals is required
     *   @comparison Boolean a flag indicating whether requesting comparison plan data is required
     *
     * @returns Object representing the formed meta-data and promise results:
     *   {
     *     dates: Array
     *     grains: Object
     *     metricGroup: Object,
     *     enabled: Object,
     *     grid: Promise --> Object
     *       @records: Array of data rows
     *       @totals: Array of data rows
     *   }
     */
    collect (dates, metricGrouping, configuration, enabled) {
      // Make a deep clone of the configuration object as we need to modify the comparisons property to exclude unset comparisons.
      configuration = _.cloneDeep(configuration);
      configuration.comparisons = _.filter(configuration.comparisons, (comparison) => !_.isNil(comparison.plan));
      configuration.granularity.grains.granularities =
        this.metricsService.filterGrainsByPlan(configuration.primary.plan, metricGrouping.metricFamilyId, configuration.granularity.grains.granularities);
      configuration.filters = this.metricsService.filterFiltersByPlan(configuration.primary.plan, metricGrouping.metricFamilyId, configuration.filters);

      const startDate = _.head(dates),
            endDate = _.last(dates),
            transformer = new DataPackager(dates, configuration.granularity.grains, metricGrouping, configuration),
            dataPromise = transformer.transform(fetchMetrics.call(this, startDate, endDate, metricGrouping, configuration, enabled));

      return DataPackage.create({
        dates: dates,
        enabled: enabled,
        granularity: _.set(configuration.granularity, 'totalsGrains', Granularities.create().addMetricGrain()),
        plan: configuration.primary.plan,
        records: dataPromise.then((data) => data.records),
        showComparisonAs: configuration.modes.comparison.selected,
        showDataAs: configuration.modes.data.selected,
        showViewAs: configuration.modes.view.selected,
        title: Name.ofMetricFamily(metricGrouping),
        totals: dataPromise.then((data) => data.totals),
        type: Enums.DataPackage.PackageType.PLAN
      }, this.$q);
    }
  }

  angular.module('application.services').service('planPackager', PlanPackagerService);
})();
