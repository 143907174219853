(function () {
  'use strict';

  class GrainMappingRatioGroupModalController {
    removeRatio (destinationGrain) {
      this.grainMappingRatioGroup.removeRatio(destinationGrain);
      this._setGrainValuesEmpty();
    }

    addRatio () {
      const destinationGrain = {};
      this.ratioConfig.destinationGrain.forEach((grain) => destinationGrain[grain] = this.newGrainValues[grain]);
      this.grainMappingRatioGroup.addRatio(this.newGrainValues.ratio, destinationGrain);
      this._setGrainValuesEmpty();
    }

    getDestinationKeyPath (grainId) {
      return `destinationKey.${grainId}`;
    }

    isValidMapping () {
      return !_.some(this.ratioConfig.destinationGrain, (grain) => !_.includes(this.allowedGrainValues[grain], this.newGrainValues[grain]))
          && this._ratioSumWithinRange('lessThanOrEqualTo');
    }

    isValidGrainMappingGroup () {
      return !_.some(this.ratioConfig.sourceGrain, (grain) => !_.includes(this.allowedGrainValues[grain], this.grainMappingRatioGroup.sourceGrains[grain]))
           && this._ratioSumWithinRange('equal');
    }

    _ratioSumWithinRange (operator) {
      const sum = this.grainMappingRatioGroup.ratioValues.reduce(
        (sum, ratioValue) => sum + parseFloat(ratioValue.ratio),
        operator === 'equal' ? 0 : parseFloat(this.newGrainValues.ratio));

      return operator === 'equal' ? sum.toFixed(1) === this.ratioConfig.maxRatioValue.toFixed(1)
        : this.ratioConfig.maxRatioValue >= sum;
    }

    _setGrainValuesEmpty () {
      this.newGrainValues = {};
      this.ratioConfig.destinationGrain.forEach((grain) => this.newGrainValues[grain] = '');
      this.newGrainValues.ratio = '';
    }

    $onInit () {
      this.grainMappingRatioGroup = this.resolve.grainMappingRatioGroup;
      this.allowedGrainValues = this.resolve.allowedGrainValues;
      this.isCreate = this.resolve.isCreate;
      this.type = this.resolve.type;
      this.ratioConfig = this.resolve.ratioConfig;
      this.initialGrainMappingRatioGroup = _.cloneDeep(this.resolve.grainMappingRatioGroup);
      this._setGrainValuesEmpty();
    }

    onSubmit () {
      this.modalInstance.close(this.grainMappingRatioGroup);
    }
  }

  /**
   * Component for the grain mapping ratio group modals.
   * @name application.components.grain-mapping-ratio-group-modal
   */
  angular.module('application.components')
    .component('grainMappingRatioGroupModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with four properties:
         *    grainMappingRatioGroup - The base grain mapping ratio group object to be viewed and augmented.
         *    isCreate - Boolean to determine if the scenario is create or edit.
         *.   type - String to determine what type of grain mapping ratio group is being viewed.
         */
        resolve: '<'
      },
      controller: GrainMappingRatioGroupModalController,
      controllerAs: 'modalCtrl',
      templateUrl: 'templates/components/grain-mapping-ratio-group-modal.component.html'
    });
})();
