(function () {
  'use strict';

  class AnnouncementsService {
    static get $inject () {
      return ['alerts', '$location', 'portalStore'];
    }

    constructor (alerts, $location, portalStore) {
      this.alerts = alerts;
      this.$location = $location;
      this.portalStore = portalStore;
    }

    refresh () {
      return this.portalStore.getAnnouncements()
        .then((announcements) => _.filter(
          announcements,
          (announcement) => _.isEmpty(announcement.paths) || _.includes(announcement.paths, this.$location.path())
        ))
        .then((announcements) => announcements.map(
          (announcement) => this.alerts[announcement.messageType]({
            details: announcement.details,
            id: announcement.uniqueKey,
            message: announcement.message
          })
        ));
    }
  }

  angular.module('application.services').service('announcements', AnnouncementsService);
})();
