/* globals Enumeration, Enums, Mapper */
(function () {
  'use strict';
  const PROTECTED_PROPERTIES = ['properties', 'source'];

  class AbstractSourceBasedModel {
    constructor (source, properties, mutability = Enums.ModelMutability.IMMUTABLE) {
      if (_.isNil(source)) {
        throw new Error('AbstractSourceBasedModel: source must not be nil');
      }

      if (!Array.isArray(properties) || _.isEmpty(properties)) {
        throw new Error('AbstractSourceBasedModel: properties must be a non-empty array');
      }

      if (!Enumeration.isMemberValue(Enums.ModelMutability, mutability)) {
        throw new Error(`AbstractSourceBasedModel: unknown mutability value provided: ${mutability}`);
      }

      PROTECTED_PROPERTIES.forEach((property) => {
        if (_.includes(properties, property)) {
          throw new Error(`AbstractSourceBasedModel: properties contains a protected property: ${property}`);
        }
      });

      this.properties = properties;
      this.source = source;
      if (mutability === Enums.ModelMutability.IMMUTABLE) {
        this.source = Object.freeze(source);
      }

      Mapper.apply(source, this, properties);
    }

    static create (source, ...parameters) {
      if (!_.isNil(source)) {
        return new this(source, ...parameters);
      }
    }

    // Calls the create method with an empty source object and no parameters. Sub-classes support template-based creation via the constructor.
    static template () {
      return this.create({});
    }

    alias (property) {
      return property;
    }

    applySource () {
      Mapper.apply(this.source, this, this.properties);
    }

    assign (source) {
      if (this.isSourceFrozen()) {
        throw new Error('AbstractSourceBasedModel: source is frozen');
      }

      if (!_.isNil(source)) {
        Object.assign(this.source, source);
        Mapper.apply(source, this, this.properties);
      }
      return this;
    }

    clone () {
      return this.constructor.create(_.clone(this.source));
    }

    // Sub-class should use to expose a flag indicating whether this model came from storage or is a new instance.
    isExisting () {
      throw new Error('AbstractSourceBasedModel: override this method in a sub-class to use');
    }

    isMatch (property, pattern) {
      property = this.alias(property);
      return _.sop.match(this, [property, `source.${property}`], pattern);
    }

    isSourceEmpty () {
      return _.isEmpty(this.source);
    }

    isSourceFrozen () {
      return Object.isFrozen(this.source);
    }

    // Sub-class should use to expose a flag indicating whether this model is in a valid state of composition.
    isValid () {
      throw new Error('AbstractSourceBasedModel: override this method in a sub-class to use');
    }

    remove (...properties) {
      if (this.isSourceFrozen()) {
        throw new Error('AbstractSourceBasedModel: source is frozen');
      }

      _.forEach(properties, (property) => {
        _.unset(this.source, property);
        _.unset(this, property);
      });
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#toJSON()_behavior
    toJSON () {
      return this.source;
    }
  }

  window.AbstractSourceBasedModel = Object.freeze(AbstractSourceBasedModel);
})();
