/* globals Enums, Ready */
(function () {
  'use strict';
  const HEADER_ALIAS = 'alias';

  class UsageMetricsListController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      this.$scope = $scope;

      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.usageMetricsDataList),
        isLoadingFn: () => this.loading
      });
    }

    _loadData () {
      this.loading = true;
      // Clear any user input fields that should be cleared with new data being loaded
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);

      this.loading = false;
    }

    isHeaderAlias (header) {
      return _.lowerCase(header) === HEADER_ALIAS;
    }

    // This is used to convert the native Date() objects produced by
    // the XLSX API when it reads a timestamp column.
    parseValue (value) {
      return (value instanceof Date) ? value.toISOString() : value;
    }

    $onInit () {
      this._loadData();
    }
  }

  /**
   * Component to display list of usage metrics data rows
   * @name application.components.usage-metrics-list
   * @example
   * <usage-metrics-list/>
   */
  angular.module('application.components')
    .component('usageMetricsList', {
      bindings: {
        usageMetricsDataList: '<',
        usageMetricsHeadersList: '<'
      },
      controller: UsageMetricsListController,
      templateUrl: 'templates/components/usage-metrics-list.component.html'
    });
})();
