/* globals Configuration, Shortcut */
(function () {
  'use strict';
  const SHORTCUT_BASE_URL = Configuration.routes.shortcut.path;
  let shortcutData = {};

  function constructRoutesByPath () {
    const routesByPath = {};
    _.forEach(Configuration.routes, (route) => routesByPath[route.path] = route);
    return routesByPath;
  }

  class ShortcutService {
    static get $inject () {
      return ['$window'];
    }

    constructor ($window) {
      this.$window = $window;
      this.routesByPath = constructRoutesByPath();
    }

    constructScopedUrl (scope, baseUrl, params) {
      return `${this.$window.location.origin}/#!/scope/${scope}${baseUrl}?${encodeURI(params)}`;
    }

    getRouteByPath (baseUrl) {
      return this.routesByPath[Configuration.routing.getPath(baseUrl)];
    }

    getShortcutData () {
      const data = _.get(shortcutData, 'id');
      shortcutData = {};
      return data;
    }

    hasShortcutData () {
      return !_.isEmpty(shortcutData);
    }

    openShortcut (shortcut) {
      if (!Shortcut.isValid(shortcut)) {
        return;
      }
      const url = this.constructScopedUrl(shortcut.scope, SHORTCUT_BASE_URL, `baseUrl=${shortcut.baseUrl}&id=${shortcut.dataKey}`);
      this.$window.open(url, '_blank');
    }

    setShortcutData (id) {
      shortcutData = { id };
    }
  }

  angular.module('application.services').service('shortcut', ShortcutService);
})();
