/* globals IntersectionObserver */
(function () {
  'use strict';
  const WINDOW_ELEMENT = angular.element(window);
  const TARGETS = new Map();

  const OBSERVER = new IntersectionObserver(
    function (entries) {
      entries.forEach(function (entry) {
        const target = TARGETS.get(entry.target);
        if (_.isNil(target)) {
          throw new Error(`WindowScrollObserver: unexpected intersection entry observed: ${JSON.stringify(entry)}`);
        }
        if (entry.isIntersecting) {
          WINDOW_ELEMENT.on('resize scroll', target.handler);
          target.postBindingHandler();
        } else {
          WINDOW_ELEMENT.off('resize scroll', target.handler);
          target.postUnbindingHandler();
        }
      });
    }
  );

  class WindowScrollObserver {
    static addTarget (element, handler, postBindingHandler = _.noop, postUnbindingHandler = _.noop) {
      if (_.isNil(element)) {
        throw new Error('WindowScrollObserver: element must not be nil');
      }
      if (!_.isFunction(handler)) {
        throw new Error('WindowScrollObserver: handler must be a function');
      }
      if (!_.isFunction(postBindingHandler)) {
        throw new Error('WindowScrollObserver: postBindingHandler must be a function');
      }
      if (!_.isFunction(postUnbindingHandler)) {
        throw new Error('WindowScrollObserver: postUnbindingHandler must be a function');
      }

      const target = { element, handler, postBindingHandler, postUnbindingHandler };
      TARGETS.set(target.element, target);
      OBSERVER.observe(target.element);
      return target;
    }

    static hasTarget (target) {
      return TARGETS.has(target.element);
    }

    static removeTarget (target) {
      TARGETS.delete(target.element);
      WINDOW_ELEMENT.off('resize scroll', target.handler);
      OBSERVER.unobserve(target.element);
      target.postUnbindingHandler();
    }
  }

  window.WindowScrollObserver = Object.freeze(WindowScrollObserver);
})();
