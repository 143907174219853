/* globals AbstractElementComponent, Binding, Enums, PlanMetadata, Ready, Stateful */
(function () {
  'use strict';
  const COMPACT_SECTION_CLASS = Object.freeze({
    default: 'col-sm-3',
    draft: 'col-sm-3',
    planType: 'col-sm-4',
    scenario: 'col-sm-3',
    version: 'col-sm-2'
  });

  // Drafts are displayed in reverse of this order.
  // indexOf(FINAL) = 2 is displayed 1st, indexOf(BASELINE) = 0 is displayed 3rd
  // This assures that any drafts outside of this list are always displayed at the end, i.e., indexOf(XYZ) = -1
  const DRAFTS_ORDER = Object.freeze([
    Enums.Plan.DraftType.BASELINE,
    Enums.Plan.DraftType.PRE_FINAL,
    Enums.Plan.DraftType.FINAL
  ]);

  const _createScenarioObject = function (plan) {
    return _.pick(plan, ['scenario', 'subScenario']);
  };

  class PlanToViewSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', 'planConfiguration', 'planStore', '$q', 'shortcut'];
    }

    constructor ($element, planConfiguration, planStore, $q, shortcut) {
      super($element);
      this.planConfiguration = planConfiguration;
      this.planStore = planStore;
      this.$q = $q;
      this.shortcut = shortcut;

      Stateful.create({
        'dataState.drafts': () => Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.input.drafts),
          isLoadingFn: () => this.loading,
          isNoSelectionFn: () => _.isNil(this.selectedInput.draft)
        }),
        'dataState.plans': () => Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.input.planTypes),
          isLoadingFn: () => this.loading,
          isNoSelectionFn: () => _.isNil(this.selectedInput.planType)
        }),
        'dataState.scenarios': () => Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.input.scenarios),
          isLoadingFn: () => this.loading,
          isNoSelectionFn: () => _.isNil(this.selectedInput.scenario)
        }),
        'dataState.versions': () => Ready.create({
          isDataEmptyFn: () => _.isEmpty(this.input.versions),
          isLoadingFn: () => this.loading
        }),
        'input.drafts': () => [],
        'input.planTypes': () => [],
        'input.scenarios': () => [],
        'input.versions': () => [],
        loading: () => false,
        plans: () => ({}),
        selectedInput: () => ({})
      }).reset(this);
    }

    _loadData () {
      // Do not perform a data reload if 'waitForFilterFn' is true but the filters are missing.
      if (this.waitForFilterFn && _.isNil(this.filterFn)) {
        return;
      }

      // Do not perform a data reload if no date is defined.
      if (_.isEmpty(this.date)) {
        return;
      }

      // Do not perform data loading if component is initializing and a Shortcut is about to be loaded.
      // * This avoids fetching plans for the same date multiple times.
      // * This avoids race conditions between the component initialization and a possible fallback date scenario when loading the Shortcut.
      if (!this.state.isInitialized() && this.shortcut.hasShortcutData()) {
        return;
      }

      this.loading = true;
      this.selectedPlan = undefined;
      this.plans = {};
      this.planTypeNameMapping = {};
      this.input.planTypes.length = 0;
      this.input.drafts.length = 0;
      this.input.scenarios.length = 0;
      this.input.versions.length = 0;
      this.$q.all([this.planStore.plans(this.date), this.planConfiguration.planDefinition().list()])
        .then((data) => {
          const planMetadataList = _.filter(data[0], this.filterFn);
          const planDefinitionList = data[1];
          _.forEach(planMetadataList, (planMetadata) => {
            const planDefinition = planDefinitionList.find((definition) => definition.planType === planMetadata.type);
            planMetadata.name = _.isNil(planDefinition) ? planMetadata.displayName() : planDefinition.displayName;
            this.planTypeNameMapping[planMetadata.type] = planMetadata.name;
          });
          this._addToPlans(...planMetadataList);
          this._updateInitialPlanSelection();
        })
        .finally(() => this.loading = false);
    }

    /*
     * 'this.plans' stores all plans in a map structure and looks like the following:
     * {
     *   binTypeFcWeeklyPlan: {
     *     ...
     *   },
     *   dailyPlan: {
     *     ...
     *   },
     *   plStFcWeeklyPlan: {
     *     baselineDraft: {
     *       prod: {
     *         nonProd: {
     *           '1': <PLAN OBJECT>
     *         }
     *       }
     *     },
     *     finalDraft: {
     *       prod: {
     *         prod: {
     *           '1': <PLAN OBJECT>
     *         }
     *       }
     *     }
     *   }
     * }
     */
    _addToPlans (...plans) {
      plans.forEach((plan) => {
        if (_.isNil(plan)) {
          return;
        }
        // _.setWith(key, value, Object) makes sure those version number strings '1', '2', etc are still treated keys. _.set() would treat them array indexes.
        _.setWith(this.plans, [plan.type, plan.draft, plan.scenario, plan.subScenario, plan.version], plan, Object);
      });
    }

    /*
     * Looks for a plan amongst the known/fetched plans stored as map structure in 'this.plans'.
     * Parameter 'plan' can be provided as an array or an object.
     * If 'plan' is an array, all the properties/keys listed (strict order: PlanType, Draft, Scenario, SubScenario and Version) in the array are used for the look up.
     * If 'plan' is an object with a non-nil 'version' property, look up happens using the PlanType, Draft, Scenario, SubScenario and Version properties on the 'plan' object.
     * If 'plan' is an object with a nil 'version' property, all the possible versions for the [PlanType, Draft, Scenario, SubScenario] combination are extracted from the 'this.plans' map and the head of that versions list is used for plan look up.
     */
    _findPlan (plan) {
      if (_.isNil(plan)) {
        return;
      }
      if (Array.isArray(plan)) {
        return _.get(this.plans, plan);
      }
      const basicLookUpBy = [plan.type, plan.draft, plan.scenario, plan.subScenario];
      if (_.isNil(plan.version)) {
        return _.get(this.plans, [...basicLookUpBy, _.head(this._getPlansMapKeys(...basicLookUpBy))]);
      }
      basicLookUpBy.push(plan.version);
      return _.get(this.plans, basicLookUpBy);
    }

    /*
     * 'this.plans' stores all plans in a map structure with a hierarchical order of PlanType, Draft, Scenario / SubScenario and Version. The 'path' array accepted by this method expects that specific order for all look ups.
     * Empty/Nil path will return plan types. Invalid path will return no keys i.e. empty array.
     */
    _getPlansMapKeys (...path) {
      if (_.isEmpty(path)) {
        // If path is empty, return plan types.
        return Object.keys(this.plans);
      }
      return Object.keys(_.get(this.plans, path, []));
    }

    _loadPlanTypes () {
      this.input.planTypes.length = 0;
      this.input.planTypes = this._getPlansMapKeys();
    }

    _loadDrafts () {
      this.input.drafts.length = 0;
      this.input.drafts = _.orderBy(
        this._getPlansMapKeys(this.selectedInput.planType),
        [(draft) => DRAFTS_ORDER.indexOf(draft)],
        ['desc']
      );
    }

    _loadScenarios () {
      const selectedPlanType = this.selectedInput.planType;
      const selectedDraft = this.selectedInput.draft;
      this.input.scenarios.length = 0;
      this._getPlansMapKeys(selectedPlanType, selectedDraft).forEach(
        (scenario) => this._getPlansMapKeys(selectedPlanType, selectedDraft, scenario).forEach(
          (subScenario) => this.input.scenarios.push({ scenario, subScenario })));
    }

    _loadVersions () {
      this.input.versions.length = 0;
      this.input.versions = this._getPlansMapKeys(
        this.selectedInput.planType,
        this.selectedInput.draft,
        _.get(this.selectedInput.scenario, 'scenario'),
        _.get(this.selectedInput.scenario, 'subScenario')
      );
    }

    _updateInitialPlanSelection () {
      if (_.isEmpty(this.plans)) {
        // If there are no plans, make the 'onSelectionChange' callback with an undefined plan. Doing nothing may leave some dependent components in 'Loading' state indefinitely
        this.onSelected();
        return;
      }

      if (_.isNil(this.initialPlan)) {
        this.onSelectedPlanType();
        return;
      }

      const selectedPlan = this.initialPlan;
      const planFromPlansMap = this._findPlan(selectedPlan);
      if (selectedPlan.isValid()) {
        // A valid initial plan is provided indicating this is from a Shareable URL.
        if (!selectedPlan.equals(planFromPlansMap)) {
          // Add the plan to the plans map so it can be selected in the cascade of dropdowns.
          this._addToPlans(selectedPlan);
          // Reload plan types as per the updated plans map.
          this._loadPlanTypes();
        }
      }

      const selectedPlanArguments = [];
      if (!_.isNil(selectedPlan)) {
        selectedPlanArguments.push(
          selectedPlan.type,
          selectedPlan.draft,
          _createScenarioObject(selectedPlan),
          selectedPlan.version
        );
      }
      this.onSelectedPlanType(...selectedPlanArguments);
    }

    assignSectionClass (section) {
      return this.viewMode === 'compact' ? COMPACT_SECTION_CLASS[section] : COMPACT_SECTION_CLASS.default;
    }

    getPlanName (planType) {
      return this.planTypeNameMapping[planType] || _.startCase(planType);
    }

    isDisabled () {
      return _.isEmpty(this.plans) || super.isDisabled();
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (!_.isEmpty(this.plans)) {
        if (Binding.changes.only(changes, 'filterFn') && Binding.changes.none(changes.filterFn)) {
          // Do not perform a data reload if the only change is for the filters and they are still equivalent.
          return;
        }

        if (Binding.changes.only(changes, 'initialPlan')) {
          if (_.isNil(this.initialPlan)) {
            // Do not perform a data reload or selection if initialPlan has changed to nil.
            return;
          }

          if (!_.isNil(this.selectedPlan) && Binding.changes.current(changes.initialPlan) === this.selectedPlan) {
            // Do not perform a data reload or selection if the only change is that the initialPlan is the selectedPlan.
            return;
          }

          // Do not perform a data reload but do perform a selection if the only change is to the initialPlan.
          this._updateInitialPlanSelection();
          return;
        }
      }

      this._loadData();
    }

    onSelected (plan) {
      this.selectedPlan = plan;
      this.onSelectionChange({ plan: this.selectedPlan });
    }

    onSelectedPlanType (planType, draft, scenario, version) {
      if (_.isEmpty(this.input.planTypes)) {
        this._loadPlanTypes();
      }
      this.selectedInput.planType = _.isNil(planType) ? _.defaultTo(_.find(this.input.planTypes, (planType) => PlanMetadata.isPlStFcWeeklyPlan(planType)), _.head(this.input.planTypes)) : planType;
      if (!_.includes(this.input.planTypes, this.selectedInput.planType)) {
        // If planType is non-nil and not present in 'input.planTypes', it has come from an invalid initialPlan.
        this.selectedInput.planType = undefined;
      }
      // Reload drafts as per the updated planType.
      this._loadDrafts();
      this.onSelectedDraft(draft, scenario, version);
    }

    onSelectedDraft (draft, scenario, version) {
      if (_.isEmpty(this.input.drafts)) {
        this._loadDrafts();
      }
      this.selectedInput.draft = _.isNil(draft) ? _.head(this.input.drafts) : draft;
      if (!_.includes(this.input.drafts, this.selectedInput.draft)) {
        // If draft is non-nil and not present in 'input.drafts', it has come from an invalid initialPlan.
        this.selectedInput.draft = undefined;
      }
      // Reload scenarios as per the updated planType and draft.
      this._loadScenarios();
      this.onSelectedScenario(scenario, version);
    }

    onSelectedScenario (scenario, version) {
      if (_.isEmpty(this.input.scenarios)) {
        this._loadScenarios();
      }
      this.selectedInput.scenario = _.isNil(scenario) ? _.defaultTo(_.find(this.input.scenarios, (item) => _.isEqual(item, PlanMetadata.defaultScenario)), _.head(this.input.scenarios)) : scenario;
      if (!_.find(this.input.scenarios, this.selectedInput.scenario)) {
        // If scenario is non-nil and not present in 'input.scenarios', it has come from an invalid initialPlan.
        this.selectedInput.scenario = undefined;
      }
      // Reload versions as per the updated planType, draft and scenario/subScenario.
      this._loadVersions();
      // Reset the version to the most current upon a change in plan selection.
      if (this.selectedInput.version !== _.head(this.input.versions)) {
        this.selectedInput.version = undefined;
      }
      this.onSelectedVersion(version);
    }

    onSelectedVersion (version) {
      if (_.isEmpty(this.input.versions)) {
        this._loadVersions();
      }
      this.selectedInput.version = _.isNil(version) ? _.head(this.input.versions) : version;
      // Note: _.trim(undefined) returns ''. As a result, this _.trim() check should not be placed before the _.isNil() check above.
      if (_.trim(this.selectedInput.version) === '') {
        // If 'selectedInput.version' is still nil, this is as a result of an invalid initialPlan. Invalidate the selectedPlan and make the 'onSelectionChange' callback with an undefined plan.
        // If a white space / blank / empty string comes from the version number textbox, invalidate the selectedPlan and make the 'onSelectionChange' callback with an undefined plan.
        this.onSelected();
        return;
      }
      // Look for a matching plan now as we should have a valid non-nil non-blank version string here.
      let selectedPlan = this._findPlan([
        this.selectedInput.planType,
        this.selectedInput.draft,
        _.get(this.selectedInput.scenario, 'scenario'),
        _.get(this.selectedInput.scenario, 'subScenario'),
        this.selectedInput.version
      ]);
      if (!_.isNil(selectedPlan)) {
        // If a matching plan is found, then make the 'onSelectionChange' callback with this plan and exit.
        this.onSelected(selectedPlan);
        return;
      }

      // Execution reaching this point means that the 'version' is not a part of known/fetched plans.
      // Please note that a missing SharedURL plan is always added to the plans list/map and will be found above when looking for matching plans.
      // An alternate plan from the known/fetched plans with same planType, draft, scenario and subScenario needs to be selected and 'version' needs
      // to be hacked into that plan, while stale properties need to be cleared.

      if (_.isNil(selectedPlan) && !_.isNil(this.selectedPlan)) {
        // If no matching plan is found above and we have a non-nil selected plan, use it.
        selectedPlan = this.selectedPlan;
      }
      if (_.isNil(selectedPlan)) {
        // If 'selectedPlan' is still nil, get a plan matching the head of the versions list.
        selectedPlan = this._findPlan([
          this.selectedInput.planType,
          this.selectedInput.draft,
          _.get(this.selectedInput.scenario, 'scenario'),
          _.get(this.selectedInput.scenario, 'subScenario'),
          _.head(this.input.versions)
        ]);
      }
      if (_.isNil(selectedPlan)) {
        // If 'selectedPlan' is still nil, no plans were found with the same planType, draft, scenario and subScenario.
        // Invalidate the selectedPlan. Make the 'onSelectionChange' callback with an undefined plan.
        this.onSelected();
        return;
      }
      // Clone this selected plan before inserting the version number string from the text-box.
      selectedPlan = selectedPlan.clone();
      selectedPlan.clearVersionProperties();
      selectedPlan.assign({ version: this.selectedInput.version });

      this.onSelected(selectedPlan);
    }
  }

  angular.module('application.components')
    .component('planToViewSelector', {
      bindings: {
        /*
         * @date Non-ambiguous ISO date format string that represents the plan date that will be called for.
         */
        date: '<',
        /*
         * @filterFn (Optional) Function that determines which plans are displayed and selectable.
         * Defaults to no filter, meaning all plans received are displayed and selectable.
         * Example:
         *     // Filter for only final draft plans.
         *     (plan) => plan.isFinalDraft()
         */
        filterFn: '<',
        /*
         * @hideLabels (Optional) Boolean that determines if the labels ("Plan Type", "Draft Type", ect.) are displayed or hidden.
         */
        hideLabels: '<',
        /*
         * @initialPlan PlanMetadata object that will initially populate the selection fields.
         */
        initialPlan: '<',
        /*
         * @onSelectionChange is a callback to the parent whenever a new plan data in selected.
         * It provides the plan as the named argument: @plan.
         */
        onSelectionChange: '&',
        /*
         * @viewMode (Optional) String that represents the view mode. It could have the following values:
         *   full (DEFAULT)- All 4 sections are shown in equal width i.e. col-sm-3.
         *   compact - "Version" width decreases whereas 'Plan Type' width increases. Helps fit the selector in compact screen space.
         *   collapsed - Selector loads in a collapsed state, with only 'Plan Type' shown. (TODO)
         */
        viewMode: '<',
        /*
         * @waitForFilterFn (Optional) Boolean that halts loading plans if a valid filterFn has not been provided.
         */
        waitForFilterFn: '<'
      },
      controller: PlanToViewSelectorController,
      templateUrl: 'templates/components/plan-to-view-selector.component.html'
    });
})();
