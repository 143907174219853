/* globals Configuration */
(function () {
  'use strict';
  class ShareController {
    static get $inject () {
      return ['alerts', '$location', 'portalStore', '$routeParams', 'share'];
    }

    constructor (alerts, $location, portalStore, $routeParams, share) {
      const shareableUrlToken = $routeParams.st;
      let success = false;

      if (!_.isString(shareableUrlToken) || _.isEmpty(shareableUrlToken)) {
        $location.url(Configuration.routes.home.path);
        return;
      }

      portalStore.getShareableUrlData(shareableUrlToken)
        .then((data) => {
          if (_.find(Configuration.routes, ['path', data.baseUrl])) {
            success = true;
            share.setShareableData(data);
            $location.url(data.baseUrl);
          }
        })
        .catch(_.noop)
        .finally(() => {
          if (!success) {
            alerts.warning('The shared URL token provided is no longer valid and you have been redirected to the home page.');
            $location.url(Configuration.routes.home.path);
          }
        });
    }
  }
  angular.module('application.controllers').controller('ShareController', ShareController);
})();
