/* globals Configuration */
'use strict';
const application = angular.module('sop-portal', [
  'angular-clipboard',
  'angular-sortable-view',
  'application.components',
  'application.controllers',
  'application.directives',
  'application.filters',
  'application.services',
  'application.templates',
  'authentication.service',
  'navigation.service',
  'ngRoute',
  'ngSanitize',
  'ngTagsInput',
  'nvd3',
  'smart-table',
  'ui.bootstrap'
]);

application.constant('APPLICATION_SCOPE_UPDATE_EVENT_KEY', 'application-scope-update');

application.config(['$compileProvider', function ($compileProvider) {
  // As per: https://docs.angularjs.org/guide/production
  // Disable debug mode, comment directives, and css class directives
  // To enable debug mode, after loading the application, open a console and run: angular.reloadWithDebugInfo();
  $compileProvider.debugInfoEnabled(false);
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);
}]);
application.config(['$logProvider', function ($logProvider) {
  // Enable debug-level logging on localhost and beta environments
  $logProvider.debugEnabled(Configuration.environment.current().isDev());
}]);
application.config(['$locationProvider', function ($locationProvider) {
  $locationProvider.html5Mode(false).hashPrefix('!');
}]);
application.config(['$httpProvider', function ($httpProvider) {
  $httpProvider.interceptors.push('awsSignatureV4HttpInterceptor');
}]);
application.config(['$authenticationProvider', function ($authenticationProvider) {
  $authenticationProvider.configure({
    expirationProperty: 'expiration',
    extensions: {
      hasAccess (roles) {
        if (_.isEmpty(roles)) {
          return true;
        }
        const scope = Configuration.scopes.current().portalCode;
        return this.allowed(_.map(roles, (role) => role(scope)));
      },
      hasAdministratorAccess () {
        return this.hasAccess(Configuration.authorization.access.administrator);
      },
      hasDeveloperAccess () {
        return this.hasAccess(Configuration.authorization.access.developer);
      },
      hasFCWriteAccess () {
        return this.hasAccess(Configuration.authorization.access.fcWrite);
      },
      hasReadAccess () {
        return this.hasAccess(Configuration.authorization.access.read);
      },
      hasWriteAccess () {
        return this.hasAccess(Configuration.authorization.access.write);
      }
    },
    notAuthenticatedRedirectUrl: Configuration.routes.authenticate.path,
    notAuthorizedRedirectUrl: Configuration.routes.authorization.path,
    onLoginRedirectUrl: Configuration.routes.home.path,
    storageService: Configuration.storage.session,
    validationFunction: function (userRoles, allowedRoles) {
      // Validation fails if:
      //   1. The userRoles are empty
      //   2. The allowedRoles are empty
      //   3. The user does not have the read role for the current scope
      //   4. The user does not have any of the allowedRoles
      const scope = Configuration.scopes.current().portalCode;
      const readRoles = _.map(Configuration.authorization.access.read, (role) => role(scope));
      return !_.isEmpty(userRoles) &&
        !_.isEmpty(allowedRoles) &&
        _.some(readRoles, (role) => _.includes(userRoles, role)) &&
        _.some(allowedRoles, (role) => _.includes(userRoles, role));
    }
  });
}]);
application.config(['$navigationProvider', function ($navigationProvider) {
  $navigationProvider.configure({
    activeLinkDecorator: 'active',
    inactiveLinkDecorator: undefined,
    securityService: '$authentication'
  });
}]);
application.config(['tagsInputConfigProvider', function (tagsInputConfigProvider) {
  tagsInputConfigProvider.setDefaults('tagsInput', {
    addOnPaste: true,
    addOnSpace: true,
    minLength: 1,
    replaceSpacesWithDashes: false,
    spellcheck: false
  });
}]);
application.config(['$routeProvider', function ($routeProvider) {
  $routeProvider
    .when(...Configuration.routes.allocationTreeEditor.generateRouteDefinition('AllocationTreeController', 'templates/administration/allocation-tree.html'))
    .when(...Configuration.routes.announcements.generateRouteDefinition('AnnouncementsController', 'templates/administration/announcements.html'))
    .when(...Configuration.routes.authenticate.generateRouteDefinition('AuthenticateController', 'templates/loading.html'))
    .when(...Configuration.routes.authorization.generateRouteDefinition('AuthorizationController', 'templates/authorization.html'))
    .when(...Configuration.routes.chartLibrary.generateRouteDefinition('ChartLibraryController', 'templates/administration/chart-library.html'))
    .when(...Configuration.routes.componentLibrary.generateRouteDefinition('ComponentLibraryController', 'templates/administration/component-library.html'))
    .when(...Configuration.routes.dailyFlashMetrics.generateRouteDefinition('DailyFlashMetricsController', 'templates/administration/daily-flash-metrics.html'))
    .when(...Configuration.routes.dailyPlanViewer.generateRouteDefinition('PlanViewerController', 'templates/plan-viewer.html', { resolve: { planViewerProvider: 'dailyPlanViewerProvider' } }))
    .when(...Configuration.routes.developerTools.generateRouteDefinition('DeveloperToolsController', 'templates/administration/developer-tools.html'))
    .when(...Configuration.routes.filterLibrary.generateRouteDefinition('FilterLibraryController', 'templates/administration/filter-library.html'))
    .when(...Configuration.routes.grainMappings.generateRouteDefinition('GrainMappingConfigurationController', 'templates/administration/grain-mapping-configuration.html'))
    .when(...Configuration.routes.home.generateRouteDefinition('HomeController', 'templates/home.html'))
    .when(...Configuration.routes.manualBacklog.generateRouteDefinition('ManualBacklogController', 'templates/administration/manual-backlog.html'))
    .when(...Configuration.routes.modelMarketplace.generateRouteDefinition('ModelMarketplaceController', 'templates/administration/model-marketplace.html'))
    .when(...Configuration.routes.networkPlanner.generateRouteDefinition('NetworkPlannerController', 'templates/administration/network-planner.html'))
    .when(...Configuration.routes.networkViewer.generateRouteDefinition('NetworkViewerController', 'templates/network-viewer.html'))
    .when(...Configuration.routes.genericNetworkViewer.generateRouteDefinition('GenericNetworkViewerController', 'templates/generic-network-viewer.html'))
    .when(...Configuration.routes.pipelineServiceWorkflows.generateRouteDefinition('PipelineServiceWorkflowsController', 'templates/administration/pipeline-service-workflows.html'))
    .when(...Configuration.routes.planAudit.generateRouteDefinition('PlanAuditController', 'templates/administration/plan-audit.html'))
    .when(...Configuration.routes.planEditor.generateRouteDefinition('PlanEditorController', 'templates/plan-editor.html'))
    .when(...Configuration.routes.planOrchestration.generateRouteDefinition('PlanOrchestrationController', 'templates/plan-orchestration.html'))
    .when(...Configuration.routes.productLines.generateRouteDefinition('ProductLineConfigurationController', 'templates/administration/product-line-configuration.html'))
    .when(...Configuration.routes.profile.generateRouteDefinition('UserProfileController', 'templates/user-profile.html'))
    .when(...Configuration.routes.romScenarioManager.generateRouteDefinition('ROMScenarioManagerController', 'templates/administration/rom-scenario-manager.html'))
    .when(...Configuration.routes.scope.generateRouteDefinition('ScopeController', 'templates/loading.html'))
    .when(...Configuration.routes.share.generateRouteDefinition('ShareController', 'templates/loading.html'))
    .when(...Configuration.routes.shortcut.generateRouteDefinition('ShortcutController', 'templates/loading.html'))
    .when(...Configuration.routes.usageMetrics.generateRouteDefinition('UsageMetricsController', 'templates/administration/usage-metrics.html'))
    .when(...Configuration.routes.warehouses.generateRouteDefinition('WarehouseConfigurationController', 'templates/administration/warehouse-configuration.html'))
    .when(...Configuration.routes.weeklyPlanViewer.generateRouteDefinition('PlanViewerController', 'templates/plan-viewer.html', { resolve: { planViewerProvider: 'weeklyPlanViewerProvider' } }))
    .otherwise({ redirectTo: Configuration.routes.authenticate.path });
}]);
application.run([
  'APPLICATION_SCOPE_UPDATE_EVENT_KEY',
  'alerts',
  'announcements',
  '$authentication',
  'pageTitle',
  '$rootScope',
  'usageMetrics',
  'userProfile',
  '$window',
  function (APPLICATION_SCOPE_UPDATE_EVENT_KEY, alerts, announcements, $authentication, pageTitle, $rootScope, usageMetrics, userProfile, $window) {
    function changeScopeHandler (newScope) {
      const resolvedScope = Configuration.scopes.find(newScope);
      if (_.isNil(resolvedScope) || Configuration.scopes.current().is(resolvedScope)) {
        return;
      }
      Configuration.scopes.current(resolvedScope);
      // Log the user out without redirecting - this triggers the $onLogoutConfirmed event
      $authentication.logoutConfirmed(true);
      // Reload the entire application at its present location
      $window.location.reload();
    }

    function onLoginConfirmedHandler () {
      if (Configuration.scopes.isInAntarctica()) {
        // The user is in Antarctica and needs to be defaulted to a scope, if they have any.
        changeScopeHandler(_.head($authentication.profile().scopes));
        return;
      }
      usageMetrics.authenticate();
      userProfile.updateMostRecentLogin();
    }

    function routeChangeStartHandler (view) {
      if (_.hasIn(view, 'authorization')) {
        const scope = Configuration.scopes.current().portalCode;
        const roles = _.map(view.authorization, (role) => role(scope));
        // roles are empty in the case of the anonymous role, otherwise check for authorization
        if (!_.isEmpty(roles)) {
          $authentication.permit(roles);
        }
      }
    }

    function routeChangeSuccessHandler (view) {
      alerts.clear();
      pageTitle.update(view.route);
      if (!Configuration.scopes.isInAntarctica()) {
        announcements.refresh();
        usageMetrics.pageView(view.originalPath);
      }
    }

    // Force the writing of the current scope to storage.
    // SIM: https://sim.amazon.com/issues/SOP-5974
    Configuration.scopes.current(Configuration.scopes.current());

    $authentication.$onLoginConfirmed(() => onLoginConfirmedHandler());
    $rootScope.$on('$routeChangeStart', (event, next) => routeChangeStartHandler(next));
    $rootScope.$on('$routeChangeSuccess', (event, current) => routeChangeSuccessHandler(current));
    $rootScope.$on(APPLICATION_SCOPE_UPDATE_EVENT_KEY, (event, newScope) => changeScopeHandler(newScope));
  }
]);
