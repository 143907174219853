/* globals AbstractElementComponent */
(function () {
  'use strict';
  class ActionIconController extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element) {
      super($element);
    }
  }

  angular.module('application.components')
    .component('actionIcon', {
      bindings: {
        icon: '@',
        showButton: '<',
        tooltip: '@'
      },
      controller: ActionIconController,
      templateUrl: 'templates/components/action-icon.component.html'
    });
})();
