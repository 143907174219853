/* globals accounting, DateUtils, Enumeration, Enums */
(function () {
  'use strict';
  const BOOTSTRAP_COLS_PER_ROW = 12;
  const DEFAULT_JOIN_SEPARATOR = ', ';
  const EMPTY_STRING = '';
  const NAN_SENTINEL = 'NaN';
  const NO_VERSION_TEXT = 'No Version';
  const ONE_MINUTE_IN_SECONDS = 60;
  const ONE_HOUR_IN_SECONDS = 3600;
  const ONE_DAY_IN_SECONDS = 86400;
  const TZ_DIVIDER = '/';
  const DECIMAL_PLACES = Object.freeze({
    DEFAULT: 2,
    INTEGER: 0,
    RATIO: 5
  });

  function pad (value) {
    const padLength = 2;
    const padCharacter = '0';
    return _.padStart(value, padLength, padCharacter);
  }

  class Display {
    /*
     * Column Count: 1 => Column Width: 12, Row Width:  4, Row Offset: 4
     * Column Count: 2 => Column Width:  6, Row Width:  6, Row Offset: 3
     * Column Count: 3 => Column Width:  4, Row Width: 10, Row Offset: 1
     * Column Count: 4 => Column Width:  3, Row Width: 12, Row Offset: 0
     * Column Count: 5 => Column Width:  2, Row Width: 12, Row Offset: 0
     * Column Count: 6 => Column Width:  2, Row Width: 12, Row Offset: 0
     */
    static computeBootstrapRowDimensions (columnCount) {
      const columnWidth = _.round(BOOTSTRAP_COLS_PER_ROW / columnCount);
      let rowWidth = _.min([columnCount * 3, BOOTSTRAP_COLS_PER_ROW]);
      if (rowWidth % 2 !== 0) {
        ++rowWidth;
      }
      const rowOffset = (BOOTSTRAP_COLS_PER_ROW - rowWidth) / 2;
      return {
        column: {
          width: columnWidth
        },
        row: {
          offset: rowOffset,
          width: rowWidth
        }
      };
    }

    static data (values, displayType, packageType, displayOverrides) {
      const formatter = _.has(this, displayType) ? this[displayType] : this.integer;
      if (Array.isArray(values)) {
        return values.map((value) => this.default(formatter(value, undefined, displayOverrides), EMPTY_STRING));
      }
      return this.default(formatter(values, packageType, displayOverrides), EMPTY_STRING);
    }

    static date (value, format) {
      // moment construction falls back to js Date(), which is not reliable across all browsers and versions.
      // Non RFC2822/ISO date formats are discouraged and will be removed in an upcoming major release.
      // Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.
      const date = DateUtils.of(value);
      if (_.isNil(value) || !date.isValid() ) {
        return null;
      }
      // Because Amazon has it's own formula for calculating week numbers (https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers/)
      // Moment's format() method cannot be used for week numbers and should instead use IPT's.
      // https://sim.amazon.com/issues/SOP-10842
      if (format === Enums.DateFormat.WeekNumber || format === Enums.DateFormat.WkNumber) {
        return DateUtils.format(date, format);
      }
      format = !_.isString(format) || _.isEmpty(format.trim()) ? Enums.DateFormat.IsoDate : format.trim();
      if (Enumeration.isMemberKey(Enums.DateFormat, format)) {
        format = Enums.DateFormat[format];
      }
      return date.format(format);
    }

    static decimal (value, packageType, displayOverrides) {
      if (_.has(displayOverrides, Enums.PlanDisplayOverrides.DECIMAL_PLACES)) {
        return _.isFinite(value) ? accounting.formatNumber(value, displayOverrides.decimalPlaces) : NaN;
      }
      const decimalPlaces = packageType === Enums.DataPackage.EditType.RATIO ? DECIMAL_PLACES.RATIO : DECIMAL_PLACES.DEFAULT;
      return _.isFinite(value) ? accounting.formatNumber(value, decimalPlaces) : NaN;
    }

    static default (value, fallback) {
      if ((_.isString(value) && !_.isEmpty(value)) || _.isFinite(value) || _.isBoolean(value)) {
        return _.toString(value);
      }
      return fallback;
    }

    /* Generates the hours, minutes and seconds duration text equivalent (upto 23h 59m 59s) of a seconds duration integer.
     *
     * @param value (integer) the duration in seconds
     *
     */
    static duration (value, fallback = '-') {
      if (!_.isFinite(value)) {
        return fallback;
      }
      let durationResponse = '';
      const hours = Math.floor((value % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
      const minutes = Math.floor((value % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
      const seconds = Math.floor(value % ONE_MINUTE_IN_SECONDS);
      const showHours = hours > 0;
      const showMinutes = showHours || minutes > 0;
      const showSeconds = showMinutes || seconds >= 0;
      if (showHours) {
        durationResponse += `${pad(hours)}h `;
      }
      if (showMinutes) {
        durationResponse += `${pad(minutes)}m `;
      }
      if (showSeconds) {
        durationResponse += `${pad(seconds)}s`;
      }
      return durationResponse;
    }

    static ignore (value, ...ignoreValues) {
      if (_.isNil(value) || _.isEmpty(ignoreValues)) {
        return value;
      }
      if (_.includes(ignoreValues, value) || (_.includes(ignoreValues, NAN_SENTINEL) && _.isNaN(value))) {
        return;
      }
      return value;
    }

    static integer (value) {
      return _.isFinite(value) ? accounting.formatNumber(value, DECIMAL_PLACES.INTEGER) : NaN;
    }

    static join (array, separator = DEFAULT_JOIN_SEPARATOR) {
      return Array.isArray(array) ? array.join(separator) : array;
    }

    static percent (percentile, digits) {
      // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed
      // for information on limitation of toFixed(digits) and reason for enforcing digits range.
      if (!_.isFinite(digits)) {
        digits = 1;
      } else if (digits < 0) {
        digits = 0;
      } else if (digits > 20) {
        digits = 20;
      }

      if (_.isFinite(percentile)) {
        const percent = (percentile * 100).toFixed(digits).toString(10);
        return `${percent}%`;
      }

      return NaN;
    }

    static pluralize (value, count) {
      if (_.isString(value) && !_.isEmpty(value) && count !== 1) {
        return `${value}s`;
      }
      return value;
    }

    static possessivize (value) {
      if (_.isEmpty(value) || !_.isString(value)) {
        return value;
      }
      return _.endsWith(_.toLower(value), 's') ? `${value}'` : `${value}'s`;
    }

    static plus (value) {
      if (!_.isFinite(value) || value <= 0) {
        return value;
      }
      return `+${value}`;
    }

    static stringify (value) {
      if (_.isString(value) || _.isNil(value)) {
        return value;
      } else if (_.isError(value)) {
        return value.stack;
      }
      return JSON.stringify(value);
    }

    static timeZone (value) {
      // Returns a formatted time zone string with white spaces around '/' and replacing '_' with white space.
      if (!_.isString(value) || !value.includes(TZ_DIVIDER)) {
        return value;
      }
      const dividerIndex = value.indexOf(TZ_DIVIDER);
      // Example: 'America/Los_Angeles' is formatted to 'America / Los Angeles'
      return `${value.substring(0, dividerIndex)} ${TZ_DIVIDER} ${_.startCase(value.substring(dividerIndex + 1))}`;
    }

    static tuples (value, properties) {
      if (!Array.isArray(value) || !Array.isArray(properties)) {
        return value;
      }
      return value.map((item) => `[${properties.map((property) => item[property]).join(DEFAULT_JOIN_SEPARATOR)}]`).join(DEFAULT_JOIN_SEPARATOR);
    }

    static version (value) {
      if (_.isFinite(value) || (_.isString(value) && !_.isEmpty(value))) {
        return `v${value}`;
      }
      return NO_VERSION_TEXT;
    }
  }

  window.Display = Object.freeze(Display);
})();
