/* globals AbstractElementComponent, Enums */
(function () {
  'use strict';
  const ONE_SECOND_IN_MILLISECONDS = 1000;
  const ONE_MINUTE_IN_SECONDS = 60;
  const ONE_HOUR_IN_SECONDS = 3600;
  const ONE_DAY_IN_SECONDS = 86400;
  const TEXT_PLACEHOLDER = '%t';

  const pad = (value) => _.padStart(value, 2, '0');

  class CountdownController extends AbstractElementComponent {
    static get $inject () {
      return ['$element', '$interval', '$scope'];
    }

    constructor ($element, $interval, $scope) {
      super($element);
      this.$interval = $interval;
      this.$scope = $scope;
      this.countdown = {};
      this.show = {};
    }

    _cancelInterval () {
      this.$interval.cancel(this.timer);
    }

    _createInterval () {
      return this.$interval(() => {
        this._updateCountdown(this.countdown.total - 1);
        if (this.countdown.total === 0) {
          this.onTimeUp();
          if (this.restartOnTimeUp) {
            this._setInitialCountdown();
          } else {
            this._cancelInterval();
          }
        }
      }, ONE_SECOND_IN_MILLISECONDS);
    }

    _isTimeValidForCountdown () {
      if (!_.isFinite(this.timeInMilliseconds)) {
        throw new Error('CountdownComponent: timeInMilliseconds must be a finite integer');
      }
      if (this.timeInMilliseconds <= 0) {
        this._updateCountdown(0);
        this._cancelInterval();
        this.onTimeUp();
        return false;
      }
      return true;
    }

    _setInitialCountdown () {
      this._cancelInterval();
      // countdown.total is stored in seconds
      this._updateCountdown(this.timeInMilliseconds / ONE_SECOND_IN_MILLISECONDS);
      this.timer = this._createInterval();
    }

    _updateCountdown (total) {
      this.countdown.total = total;
      const hours = Math.floor((total % ONE_DAY_IN_SECONDS) / ONE_HOUR_IN_SECONDS);
      const minutes = Math.floor((total % ONE_HOUR_IN_SECONDS) / ONE_MINUTE_IN_SECONDS);
      const seconds = Math.floor(total % ONE_MINUTE_IN_SECONDS);
      this.show.hours = hours > 0;
      this.show.minutes = this.show.hours || minutes > 0;
      this.show.seconds = this.show.minutes || seconds > 0;
      this.countdown.hours = pad(hours);
      this.countdown.minutes = pad(minutes);
      this.countdown.seconds = pad(seconds);
    }

    render () {
      let result = '';
      if (this.show.hours) {
        result += `${this.countdown.hours}h `;
      }
      if (this.show.minutes) {
        result += `${this.countdown.minutes}m `;
      }
      if (this.show.seconds) {
        result += `${this.countdown.seconds}s`;
      }
      return this.text.replace(TEXT_PLACEHOLDER, result);
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      if (!this._isTimeValidForCountdown()) {
        return;
      }
      this._setInitialCountdown();
    }

    $onDestroy () {
      this._cancelInterval();
    }

    $onInit () {
      if (!_.isString(this.text) || !this.text.includes(TEXT_PLACEHOLDER)) {
        this.text = TEXT_PLACEHOLDER;
      }
      if (!this._isTimeValidForCountdown()) {
        return;
      }
      this.$scope.$on(Enums.BroadcastChannel.RESET_COUNTDOWN, this._setInitialCountdown.bind(this));
      this._setInitialCountdown();
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('countdown', {
      bindings: {
        onTimeUp: '&',
        restartOnTimeUp: '<',
        text: '@',
        timeInMilliseconds: '<',
        variant: '@',
        variantClass: '@'
      },
      controller: CountdownController,
      templateUrl: 'templates/components/countdown.component.html'
    });
})();
