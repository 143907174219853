/* globals AbstractSourceBasedModel, Configuration, FlowDefinition, Granularities */
(function () {
  'use strict';

  // See: https://code.amazon.com/packages/SandopUserAuth/blobs/mainline/--/src/com/amazon/sandop/user/auth/AccessLevel.java
  const ACCESS_ROLE_MAPPING = Object.freeze({
    ADMIN: Configuration.authorization.access.administrator,
    DEV: Configuration.authorization.access.developer,
    ['FC-WRITE']: Configuration.authorization.access.fcWrite,
    READ: Configuration.authorization.access.read,
    WRITE: Configuration.authorization.access.write
  });

  const PROPERTIES = Object.freeze([
    'bringYourOwnPlan',
    'displayName',
    'planHorizonInDays',
    'planType',
    'userWriteRole',
    {
      deserialize: (flows) => flows.map((flow) => FlowDefinition.create(flow)),
      key: 'flows'
    },
    {
      deserialize: (grains) => Granularities.create(grains).addImplicitGrains(),
      source: 'granularity.grainComponents',
      target: 'planGranularity'
    },
    {
      source: 'granularity.planGranularityName',
      target: 'planGranularityName'
    },
    {
      source: 'granularity.schemaName',
      target: 'schemaName'
    },
    {
      source: 'granularity.timeGranularityName',
      target: 'timeGranularityName'
    }
  ]);

  class PlanDefinition extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    static create (source) {
      return Object.freeze(new PlanDefinition(source));
    }

    editTypes () {
      return _.uniq(this.flows.flatMap((flow) => Array.isArray(flow.editTypes) ? flow.editTypes : []));
    }

    isAuditable () {
      return _.isString(this.userWriteRole) && !_.isEmpty(this.editTypes());
    }

    isBringYourOwnPlan () {
      return !!this.bringYourOwnPlan;
    }

    writeAccessRole () {
      if (!_.has(ACCESS_ROLE_MAPPING, this.userWriteRole)) {
        throw new Error(`PlanDefinition: Unsupported userWriteRole "${this.userWriteRole}" for planType "${this.planType}"`);
      }
      return ACCESS_ROLE_MAPPING[this.userWriteRole];
    }
  }

  window.PlanDefinition = Object.freeze(PlanDefinition);
})();
