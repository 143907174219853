/* globals Mapper */
(function () {
  'use strict';
  const manager = Mapper.create();

  class ViewService {
    static get $inject () {
      return ['$authentication', '$location', 'portalStore'];
    }

    constructor ($authentication, $location, portalStore) {
      this.$authentication = $authentication;
      this.$location = $location;
      this.portalStore = portalStore;
    }

    allViews (path, scope, options) {
      return this.portalStore.getUserViews(scope, options).then((allViews) => {
        if (!_.isNil(path)) {
          _.remove(allViews.personal, (view) => !view.isMatch(path));
          _.remove(allViews.public, (view) => !view.isMatch(path));
        }
        return allViews;
      });
    }

    apply (view) {
      return manager.apply(this.$location.path(), view);
    }

    create (name, isPublic = false) {
      const key = this.$location.path(),
            configuration = manager.collect(key);
      if (!_.isEmpty(configuration)) {
        configuration.baseUrl = key;
        configuration.owner = this.$authentication.profile().alias;
        return this.portalStore.upsertUserView(name, configuration, { isPublic });
      }
    }

    delete (view) {
      return this.portalStore.deleteUserView(view);
    }

    register (root, properties, permitCreateCallback, postApplyCallback) {
      return manager.register(this.$location.path(), root, properties, permitCreateCallback, postApplyCallback);
    }

    update (view) {
      return this.portalStore.upsertUserView(view.identifier, view.source, { isPublic: view.isPublic });
    }
  }

  angular.module('application.services').service('view', ViewService);
})();
