(function () {
  'use strict';

  class Identity {
    /* Gets the identity of the object. An identity is the 'id' (prefered) or 'name' property of an object.
     * Any argument that does not have an 'id' or 'name' property will be returned since its identity
     * is itself.
     *
     * @param object the object to get the identity of.
     *
     * @return the identity value of @object
     */
    static of (object) {
      if (_.isNil(object)) {
        return object;
      }
      // The identity in descending order of preference: id, key, name, self
      return object.id || object.key || object.name || object;
    }

    /* Checks whether a value matches the expected MD5 hash pattern (a string of hex characters (0-9, a-f, A-F) that is exactly 32 characters long).
     *
     * @return true if the value matches the expected pattern, false otherwise
     */
    static isMD5Hash (value) {
      return _.isString(value) && /^[a-fA-F0-9]{32}$/.test(value);
    }

    /* Checks whether a value matches the expected UUID v4 pattern (a string of hex characters (0-9, a-f, A-F) with 4 dashes in the exact format of x{8}-x(4)-x(4)-x(4)-x{12}).
     *
     * @return true if the value matches the expected pattern, false otherwise
     */
    static isUUIDv4 (value) {
      return _.isString(value) && /^[a-f0-9]{8}(-[a-f0-9]{4}){3}-[a-f0-9]{12}$/.test(value);
    }

    /* Generates an UUID v4 in JavaScript (RFC4122 compliant).
     * @credit https://gist.github.com/jcxplorer/823878
     *
     * @return the UUID v4 string
     */
    static uuid () {
      let uuid = '', i, random;
      for (i = 0; i < 32; ++i) {
        /* eslint-disable no-bitwise, no-mixed-operators */
        random = Math.random() * 16 | 0;
        if (i === 8 || i === 12 || i === 16 || i === 20) {
          uuid += '-';
        }
        uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
        /* eslint-enable no-bitwise, no-mixed-operators */
      }
      return uuid;
    }
  }

  window.Identity = Object.freeze(Identity);
})();
