/* globals AbstractSourceBasedModel, MarketplaceModelRun, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'draft',
    'endDate',
    'errorMessage',
    'executionId',
    'failedRuns',
    'forecastWeek',
    'forecastHorizon',
    'isBackTest',
    'outputS3Location',
    'predictionIntervalEnabled',
    'predictionInterval',
    'modelRunName',
    'modelName',
    'publishToPlanStore',
    'runningRuns',
    'runDateTime',
    'scenario',
    'startDate',
    'status',
    'subScenario',
    'succeededRuns',
    'user',
    'version',
    {
      deserialize: (bestModel) => MarketplaceModelRun.create(bestModel),
      key: 'bestModel'
    }
  ]);

  class MarketplaceRun extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.status);
    }

    isSuccessWithFailure () {
      return TaskStatus.isSuccessWithFailure(this.status);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.MarketplaceRun = Object.freeze(MarketplaceRun);
})();
