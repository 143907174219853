(function () {
  'use strict';
  const DEFAULT_FN = () => false;

  class Ready {
    /**
     * Create an instance of the Ready state tracking object.
     * @param functions a hash of optional function declarations to determine state:
     *   isDataEmptyFn: Function returns a Boolean indicating whether the state is in the data is empty ('no-data') state.
     *   isLoadingFn: Function returns a Boolean indicating whether the state is in the loading ('loading') state.
     *   isNoSelectionFn: Function returns a Boolean indicating whether the state is in the no selction ('no-selection') state.
     *   isWaitingFn: Function returns a Boolean indicating whether the state is in the waiting ('waiting') state.
     * NOTE: All functions will default to a function that always returns false, indicating that state is never reachable.
     * @return the instantiated Ready state tracking object.
     */
    constructor (functions) {
      this.isDataEmpty = DEFAULT_FN;
      this.isLoading = DEFAULT_FN;
      this.isNoSelection = DEFAULT_FN;
      this.isWaiting = DEFAULT_FN;
      functions = functions || {};
      _.forEach(functions, (value, property) => {
        if (property.length > 2 && _.endsWith(property, 'Fn')) {
          // 1. Prune off the 'Fn'
          const key = property.slice(0, -2);
          // 2. Set key in this instance to value if it is a function
          this[key] = _.isFunction(value) ? value : DEFAULT_FN;
        }
      });
    }

    static create (functions) {
      return new Ready(functions);
    }

    static once ($scope, isReadyFn, onReadyCallback) {
      const deregister = $scope.$watch(isReadyFn, function (isReady) {
        if (isReady) {
          deregister();
          onReadyCallback();
        }
      });
      return deregister;
    }

    isInitialized (value) {
      if (_.isBoolean(value)) {
        this.initialized = value;
      }
      return !!this.initialized;
    }

    isReady () {
      return this.state() === 'ready';
    }

    state () {
      if (this.isWaiting()) {
        return 'waiting';
      }
      if (this.isLoading()) {
        return 'loading';
      }
      if (this.isDataEmpty()) {
        return 'no-data';
      }
      if (this.isNoSelection()) {
        return 'no-selection';
      }
      return 'ready';
    }
  }

  window.Ready = Object.freeze(Ready);
})();
