/* globals Cell, Display, Filename, Granularities, Grid, HeaderCell, Ready */
(function () {
  'use strict';

  class ManualBacklogGridController {
    static get $inject () {
      return ['trader', 'transformerFactory'];
    }

    constructor (trader, transformerFactory) {
      this.trader = trader;
      this.transformerFactory = transformerFactory;
      this.gridData = {};
      this.masterset = {};
      this.state = Ready.create();
    }

    _buildAuditTitle (metadata) {
      return _.has(metadata, 'lastUpdatedBy') && _.has(metadata, 'lastUpdatedAt') ?
        `Last update by ${metadata.lastUpdatedBy} at ${metadata.lastUpdatedAt}` : '';
    }

    _fetchGrid () {
      this.gridData.header = Grid.header(
        this.package.title,
        () => Grid.defaultHeaderRowSupplier({
          dates: this.package.dates,
          granularity: {
            grains: Granularities.create()
              .addNodeGrain()
              .addProductLineGrain()
              .addSortTypeGrain()
          }
        })
      );
      this.package.ready()
        .then((data) => {
          this.masterset = data;
          this.gridData.body = Grid.body(this.masterset.records.length, this._generateBodyRows.bind(this));
        })
        // On error, provide an empty object to the grid service so it displays the no data available messaging
        .catch(() => this.gridData.body = Grid.body());
    }

    _generateBodyRows (start, end) {
      return this.masterset.records.slice(start, end).map((row) => ({
        data: row.values.map((value, index) => Cell.create(Display.data(value, 'integer'), this._buildAuditTitle(_.get(row, ['recordMetadata', index])), 'text-right')),
        headers: _.map(
          [row.granularity.node, row.granularity.productLine, row.granularity.sortType],
          (grain) => HeaderCell.create(grain, grain, 'granularity')
        )
      }));
    }

    download (format) {
      this.transformerFactory.toDocument(
        this.transformerFactory.transformerType.manualBacklog,
        format,
        [this.masterset],
        [
          {
            key: 'granularity',
            serialize: (granularity) => granularity.grains.names()
          },
          { key: 'dates' }
        ],
        [
          {
            key: 'granularity',
            serialize: (granularity, source, row) => granularity.grains.values().map((grain) => row.granularity[grain.id]),
            source: 'pkg'
          },
          {
            key: 'values',
            source: 'row'
          }
        ]
      ).then((workbook) => {
        this.trader.download(
          workbook,
          Filename.create('Manual Backlog'),
          this.trader.toExtensionType(format)
        );
      });
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._fetchGrid();
    }

    $onInit () {
      this._fetchGrid();
      this.state.isInitialized(true);
    }
  }

  angular.module('application.components')
    .component('manualBacklogGrid', {
      bindings: {
        package: '<'
      },
      controller: ManualBacklogGridController,
      templateUrl: 'templates/components/manual-backlog-grid.component.html'
    });
})();
