/* globals AbstractElementComponent, Binding, DateUtils, Enums */
(function () {
  'use strict';
  const DEFAULT_SELECTION = 1;
  const SUPPORTED_TIME_UNITS = Object.freeze([Enums.TimeUnit.DAY, Enums.TimeUnit.WEEK]);

  class HistoricalDateSelectorController extends AbstractElementComponent {
    constructor ($element) {
      super($element, {
        isDataEmptyFn: () => _.isEmpty(this.dates),
        isNoSelectionFn: () => _.isNil(this.selectedDate)
      });
    }

    _getDate (date) {
      return DateUtils.of(date).startOf(this.timeUnit);
    }

    _loadData () {
      this.dates = _.times(_.toNumber(this.numberOfHistoricalDates) + 1, (dateNumber) => ({
        date: DateUtils.fromOffset(this._getDate(this.primaryDate), dateNumber * -1, this.timeUnit),
        dateNumber: dateNumber,
        timeUnit: this.timeUnit
      }));
    }

    _setInitialDate () {
      const dateToSelect = _.find(this.dates, { date: this.initialDate }) || _.nth(this.dates, this.initialSelection || DEFAULT_SELECTION);
      if (_.isEqual(dateToSelect, this.selectedDate)) {
        return;
      }
      this.onSelectedDate(dateToSelect);
    }

    onSelectedDate (selectedDate) {
      this.selectedDate = selectedDate;
      this.onSelectionChange({ date: _.get(selectedDate, 'date') });
    }

    isDisabled () {
      return _.isNil(this.primaryDate) || _.isEmpty(this.dates) || super.isDisabled();
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }
      if (Binding.changes.has(changes.primaryDate) || Binding.changes.has(changes.timeUnit) || Binding.changes.has(changes.numberOfHistoricalDates)) {
        if (!SUPPORTED_TIME_UNITS.includes(this.timeUnit)) {
          throw new Error(`HistoricalDateSelectorComponent: timeUnit not supported: ${this.timeUnit}`);
        }
        this._loadData();
      }
      this._setInitialDate();
    }

    $onInit () {
      super.$onInit();
      this._setInitialDate();
    }
  }

  angular.module('application.components')
    .component('historicalDateSelector', {
      bindings: {
        // date is expected as a "yyyy-MM-dd" date string, not a Date object.
        // This is how we will be receiving dates from integrated services.
        initialDate: '<',
        // Nth date to be selected from the list of dates. (Will be ignored when initialDate is provided)
        initialSelection: '<',
        // This is used to decide the length of dropdown dates list.
        numberOfHistoricalDates: '<',
        onSelectionChange: '&',
        // This date is used to decide the start date for the list of dropdown dates.
        primaryDate: '<',
        // The unit of time to be selected against the primary date. (Must be in the supported time units list)
        timeUnit: '<'
      },
      controller: HistoricalDateSelectorController,
      templateUrl: 'templates/components/historical-date-selector.component.html'
    });
})();
