/* globals AbstractSourceBasedModel, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'message',
    'status',
    'taskId',
    'taskName'
  ]);

  class OrchestratorExecutionStepTask extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    isInProgress () {
      return TaskStatus.isInProgress(this.status);
    }

    isScheduled () {
      return TaskStatus.isScheduled(this.status);
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.status);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.OrchestratorExecutionStepTask = Object.freeze(OrchestratorExecutionStepTask);
})();
