/* globals AbstractElementComponent, Configuration, Enums */
(function () {
  'use strict';

  class MetricsDashboard extends AbstractElementComponent {
    static get $inject () {
      return ['$uibModal'];
    }

    constructor ($uibModal) {
      super();
      this.$uibModal = $uibModal;
      this.chartsLoadingComplete = false;
      this.scope = Configuration.scopes.current().portalCode;
      this.dashboardConfig = Configuration.metricsDashboard.forScope(this.scope);
      if (_.isNil(this.dashboardConfig)) {
        return;
      }
      const dashboardMetricFamilyGroups = Configuration.metricsDashboard.metricFamilyGroups.filter((group) => Object.keys(this.dashboardConfig.metricsData).includes(group));
      this.metricFamilyGroups = {
        list: dashboardMetricFamilyGroups,
        selected: _.head(dashboardMetricFamilyGroups)
      };
    }

    isDashboardConfigDaily () {
      if (_.isNil(this.dashboardConfig)) {
        return false;
      }
      return this.dashboardConfig.timeGranularity === Enums.TimeGranularity.DAILY;
    }

    openDashboardConfigurationModal () {
      this.$uibModal
        .open({
          component: 'dashboardConfigurationModal',
          resolve: {
            scope: () => this.scope
          },
          size: Enums.ModalSize.EXTRA_LARGE
        })
        .result
        .catch(_.noop);
    }
  }

  angular.module('application.components')
    .component('metricsDashboard', {
      bindings: {
        /*
         * @isActive Boolean a flag indicating whether this component is active or not. Metrics data is loaded only when component is active.
         */
        isActive: '<'
      },
      controller: MetricsDashboard,
      templateUrl: 'templates/components/metrics-dashboard.component.html'
    });
})();
