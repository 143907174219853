/* globals DateUtils, Enums, Grain, GrainMappingGroup, Ready */
(function () {
  'use strict';

  const DELETE_OPERATION = 'delete';
  const UPDATE_OPERATION = 'update';

  class GrainMappingGroupListController {
    static get $inject () {
      return ['alerts', 'overlay', 'planConfiguration', '$q', '$scope', '$uibModal'];
    }

    constructor (alerts, overlay, planConfiguration, $q, $scope, $uibModal) {
      this.alerts = alerts;
      this.overlay = overlay;
      this.planConfiguration = planConfiguration;
      this.$q = $q;
      this.$scope = $scope;
      this.$uibModal = $uibModal;

      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.grainMappingGroupList),
        isLoadingFn: () => this.loading
      });
    }

    _getGrainValues (grain) {
      if (grain === Enums.GrainIdentity.productLine) {
        return this.planConfiguration.productLine().list({ asOfDate: DateUtils.format(Enums.DateFormat.IsoDate) })
          .then((data) => _.map(data, 'name'))
          .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch Product Lines' }));
      }
      return this.planConfiguration.grainDefinition(grain, DateUtils.format(Enums.DateFormat.IsoDate))
        .then((data) => _.map(data.values, 'id'))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to fetch ${Grain.known[grain].name}s` }));
    }

    _loadData () {
      this.loading = true;
      this.grainMappingGroupList = [];
      this.allowedGrainValues = {
        grainA: [],
        grainB: []
      };
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.planConfiguration[this.groupType]().list().then((data) => {
        this.grainMapping = data;
        this.grainMappingGroupList = data.mapping;
        this.$q.all({
          grainAValues: this._getGrainValues(_.head(this.grainMapping.granularityKeys).id),
          grainBValues: this._getGrainValues(_.last(this.grainMapping.granularityKeys).id)
        }).then((data) => {
          this.allowedGrainValues.grainA = data.grainAValues;
          this.allowedGrainValues.grainB = data.grainBValues;
        });
      }).finally(() => this.loading = false);
    }

    _updateGroups (group) {
      this._mutateGroups(group, UPDATE_OPERATION);
    }

    _deleteGroups (group) {
      this._mutateGroups(group, DELETE_OPERATION);
    }

    _mutateGroups (group, operationType) {
      this.planConfiguration[this.groupType]()[operationType]([group])
        .then(() => this.alerts.success(`Successfully ${operationType}d ${_.startCase(this.groupType)}s`))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to ${operationType} ${_.startCase(this.groupType)}s` }))
        .finally(() => {
          this.overlay.hide();
          this._loadData();
        });
    }

    isAddDisabled () {
      return this.loading ||
        _.isEmpty(this.grainMappingGroupList) ||
        _.isEmpty(this.allowedGrainValues.grainA) ||
        _.isEmpty(this.allowedGrainValues.grainB);
    }

    openModal (event, isCreate, grainMappingGroup = GrainMappingGroup.create(this.grainMapping.granularityKeys)) {
      if (!_.isNil(event)) {
        event.stopPropagation();
      }
      this.$uibModal
        .open({
          component: 'grainMappingGroupModal',
          resolve: {
            allowedGrainValues: () => this.allowedGrainValues,
            grainMappingGroup: () => _.cloneDeep(grainMappingGroup),
            isCreate: () => isCreate,
            type: () => this.groupType
          },
          size: Enums.ModalSize.LARGE
        })
        .result.then((model) => {
          this.overlay.show(`Saving ${_.startCase(this.groupType)}: ${model.name}`);
          const grainMappingGroup = this.grainMapping.mapping.find((group) => model.name === group.name);

          if (_.isUndefined(grainMappingGroup)) {
            throw new Error(`There is no grain mapping that matches ${model.name}`);
          }

          // comparing the new grain mapping to the old grain mapping to find entries that have been added
          const grainMappingsToAdd = model.grainMappings.filter((newMapping) => !grainMappingGroup.grainMappings.some((oldMapping) => _.isEqual(newMapping, oldMapping)));

          if (grainMappingsToAdd.length > 0) {
            this._updateGroups(model);
          }

          // comparing the old grain mapping to the new grain mapping to find entries that do not exist anymore to be deleted
          const grainMappingsToDelete = grainMappingGroup.grainMappings.filter((oldMapping) => !model.grainMappings.some((newMapping) => _.isEqual(oldMapping, newMapping)));

          if (grainMappingsToDelete.length > 0) {
            const grainMappingsToDeleteRequestBody = GrainMappingGroup.create(this.grainMapping.granularityKeys, model.name, grainMappingsToDelete);
            this._deleteGroups(grainMappingsToDeleteRequestBody);
          }
        })
        .catch(_.noop);
    }

    $onChanges () {
      this._loadData();
    }

    $onInit () {
      this._loadData();
    }
  }

  /**
   * Component to display list of grain mapping groups
   * @name application.components.grain-mapping-group-list
   * @example
   * <grain-mapping-group-list></grain-mapping-group-list>
   */
  angular.module('application.components')
    .component('grainMappingGroupList', {
      bindings: {
        /*
         * @groupType String that determines which type of grain mapping group
         * is being displayed.
         */
        groupType: '<'
      },
      controller: GrainMappingGroupListController,
      templateUrl: 'templates/components/grain-mapping-group-list.component.html'
    });
})();
