/* globals AbstractPackagerService, AllocationTreeEditRecord, Configuration, DataPackage, Enums, Grain, Granularities, Identity, Name */
(function () {
  'use strict';

  const GRAINS_ORDER = Object.freeze(['node', 'secondaryNode', 'productLine', 'sortType', 'binType', 'businessEntity', 'networkInventoryGroup']);

  /*
   * Recursively creates all combinations from the list of filter selections.
   * Example: If the filters are {
   *   node: [ ABC1, ABC2 ],
   *   secondaryNode: [ ABC2, ABC3 ],
   *   productLine: [ 'Home' ],
   *   sortType: [ id: 'sortable', name: 'Sortable' ],
   * }
   * Following combinations will be created and added to the list of 'records'
   * [
   *   { node: 'ABC1', secondaryNode: 'ABC2', productLine: 'Home', sortType: 'sortable' }
   *   { node: 'ABC1', secondaryNode: 'ABC3', productLine: 'Home', sortType: 'sortable' }
   *   { node: 'ABC2', secondaryNode: 'ABC3', productLine: 'Home', sortType: 'sortable' }
   * ]
   * Ideally, 2 node selections and 2 secondaryNode selections should create 2 x 2 = 4 combinations.
   * In the above scenario, ABC2 => ABC2 combination was skipped as the node & secondaryNode values were the same.
   */
  const createFilterCombinations = (grainIndex, filters, record, records) => {
    if (grainIndex === GRAINS_ORDER.length) {
      records.push(AllocationTreeEditRecord.create(_.clone(record)));
      return;
    }
    const grain = GRAINS_ORDER[grainIndex];
    const filter = filters[grain];
    if (!filter) {
      createFilterCombinations(grainIndex + 1, filters, record, records);
      return;
    }
    filter.forEach((selection) => {
      const selectionValue = selection.id || selection;
      if (_.isEmpty(selectionValue) || _.includes(_.values(record), selectionValue)) {
        // Skip the combination if selectionValue already exists in record values
        return;
      }
      const newRecordData = { [grain]: selectionValue };
      if (grain === Enums.GrainIdentity.secondaryNode) {
        newRecordData.secondaryScope = selection.scope;
      }
      _.defaults(record, newRecordData);
      createFilterCombinations(grainIndex + 1, filters, record, records);
      // 'record' is used to create all combinations. Delete the added grain property as we backtrack
      delete record[grain];
      // Also delete the 'secondaryScope' property that may have been added for grain 'secondaryNode'
      if (grain === Enums.GrainIdentity.secondaryNode) {
        delete record.secondaryScope;
      }
    });
  };

  const insertCustomGrains = (granularities) => {
    const secondaryNodeGrain = Grain.create(Enums.GrainIdentity.secondaryNode);
    if (!granularities.hasGrain(secondaryNodeGrain)) {
      return granularities;
    }
    const customGranularities = _.cloneDeep(granularities);
    // If 'secondaryNode' is one of the granularities, insert 'secondaryScope' in the list right after 'secondaryNode'. This shows a 'Secondary Scope' column (right after 'Secondary FC') in the downloaded excel file. Can be used to show the same column on UI as well.
    customGranularities.addSecondaryScopeGrain(secondaryNodeGrain.filterIndex + 1);
    return customGranularities;
  };

  class DataPackager {
    constructor (flow, filters) {
      this.flow = flow;
      this.filters = filters;
    }

    transform (promise) {
      return promise
        .then(this.createRecords.bind(this))
        // If parsing the dataset fails, return an empty list so DataPackage.records is always an array
        .catch(() => []);
    }

    createRecords (datasets) {
      const records = [];
      createFilterCombinations(0, this.filters, {}, records);
      const treeData = _.flatten(datasets.map((dataset) => _.get(dataset, 'data', [])));
      records.forEach((record) => record.setExists(_.findIndex(treeData, record.toJSON()) > -1));
      return records;
    }
  }

  class AllocationTreePackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['allocationTree', '$q'];
    }

    constructor (allocationTree, $q) {
      super($q);
      this.allocationTree = allocationTree;
    }

    collect (flow, filters, editType) {
      const granularities = Granularities.create(_.sortBy(_.map(_.keys(filters), (grain) => Grain.create(grain)), 'filterIndex'));
      const scope = Configuration.scopes.current().code,
            transformer = new DataPackager(flow, filters),
            dataPromise = transformer.transform(this.$q.resolve(this.allocationTree.tree(scope, flow.id, filters, granularities, {}, true)));

      return DataPackage.create({
        editType: editType,
        filters: filters,
        flow: Identity.of(flow),
        granularity: insertCustomGrains(granularities),
        records: dataPromise.then((data) => data),
        scope: scope,
        title: Name.ofMetric(flow)
      }, this.$q);
    }
  }

  angular.module('application.services').service('allocationTreePackager', AllocationTreePackagerService);
})();
