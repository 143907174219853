/* globals Configuration, DateUtils, Enumeration, Enums, Ready */
(function () {
  'use strict';
  const COMPLETE_STATUS = 'DONE';
  const FAILURE_STATUS = 'ERROR';

  const PREFINAL_DRAFT = 'Prefinal';
  const FINAL_DRAFT = 'Final';

  const PROMOTE_MODIFICATION = 'PROMOTE';

  const ACTION_KEYS = Enumeration.create('PUBLISH', 'TRIGGER_FC_GASTANK').asStringValue();

  const PUBLISH_CONFIRMATION_MESSAGE = 'Are you sure you want to publish the latest Prefinal draft as the Final draft and notify all downstream systems? This will publish <strong>ALL</strong> edits to <strong>ALL</strong> flows by <strong>ALL</strong> users from the <strong>ENTIRE</strong> Prefinal draft. This action cannot be cancelled once started.';
  const TRIGGER_FC_GASTANK_MESSAGE = 'Are you sure you want to generate FC GasTank based on the latest Prefinal draft? This action cannot be cancelled once started.';

  class PlanModificationModalController {
    static get $inject () {
      return ['$authentication', 'gasTankAllocationService', 'orchestrator'];
    }

    constructor ($authentication, gasTankAllocationService, orchestrator) {
      this.$authentication = $authentication;
      this.gasTankAllocationService = gasTankAllocationService;
      this.orchestrator = orchestrator;

      this.actionKeys = ACTION_KEYS;
      this.loading = true;
      this.message = Object.freeze({
        publish: PUBLISH_CONFIRMATION_MESSAGE,
        triggerFCGasTank: TRIGGER_FC_GASTANK_MESSAGE
      });
      this.modifications = [];
      this.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty(this.modifications) || _.isNil(this.plan),
        isLoadingFn: () => this.loading
      });
    }

    loadData () {
      _.defaults(this, this.resolve.modalData);
      this.loading = true;
      this.modifications = [];
      if (_.isNil(this.plan)) {
        return;
      }
      this.orchestrator.modificationStatus(this.plan, this.uuid)
        .then((data) => this.modifications = data)
        .finally(() => this.loading = false);
    }

    srcVersion (planModification) {
      if (_.isNil(planModification)) {
        return;
      }
      return `${PREFINAL_DRAFT} v${planModification.srcVersion}`;
    }

    destVersion (planModification) {
      if (_.isNil(planModification)) {
        return;
      }
      const destVersion = planModification.destVersion;
      if (!_.isNil(destVersion)) {
        const draftType = planModification.modification === PROMOTE_MODIFICATION ? FINAL_DRAFT : PREFINAL_DRAFT;
        return `${draftType} v${planModification.destVersion}`;
      }
    }

    formatDate (date) {
      return _.isNil(date) ? undefined : DateUtils.format(date, Enums.DateFormat.IsoDateTime);
    }

    getPlanModificationDuration (planModification) {
      const startTime = planModification.startTime;
      const endTime = planModification.endTime;
      if (_.isNil(startTime) || _.isNil(endTime)) {
        return;
      }
      return DateUtils.difference(this.formatDate(endTime), this.formatDate(startTime), Enums.TimeUnit.SECOND);
    }

    onConfirmationSelection (action) {
      switch (action) {
        case ACTION_KEYS.PUBLISH:
          this.modalInstance.close({
            action: action,
            promise: this.orchestrator.promote(this.plan)
          });
          break;
        case ACTION_KEYS.TRIGGER_FC_GASTANK:
          this.modalInstance.close({
            action: action,
            promise: this.gasTankAllocationService.triggerAllocation(this.plan)
          });
          break;
      }
    }

    showPromote () {
      return this.$authentication.hasWriteAccess();
    }

    showTriggerFCGasTank () {
      return Configuration.activation.isAvailable('TriggerFCGasTank') &&
        this.$authentication.hasWriteAccess() &&
        !_.isNil(this.plan) &&
        this.plan.isFcWeeklyPlan();
    }

    statusDisplayClass (status) {
      switch (status) {
        case COMPLETE_STATUS:
          return 'text-success';
        case FAILURE_STATUS:
          return 'text-danger';
      }
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this.loadData();
    }

    $onInit () {
      this.loadData();
      this.state.isInitialized(true);
    }
  }

  angular.module('application.components')
    .component('planModificationModal', {
      bindings: {
        modalInstance: '<',
        resolve: '<'
      },
      controller: PlanModificationModalController,
      templateUrl: 'templates/components/plan-modification-modal.component.html'
    });
})();
