/* globals Configuration, DateUtils, Enums, PlanMetadata */
(function () {
  'use strict';
  /*
   * TODO: As of now, PlanMetadata isCapacityPlan() method is being used to verify plan types against a hardcoded list
   *       of plans being classified as Capacity plans. This is a temporarily solution and would exist on the Portal side
   *       until a namespace/tenant property is added in Plan Definition that classifies a plan as Capacity plan.
   */
  function capacityPlansFilter (planType) {
    return (Configuration.scopes.current().isOfTenantCapacity() && PlanMetadata.isCapacityPlan(planType))
      || (!Configuration.scopes.current().isOfTenantCapacity() && !PlanMetadata.isCapacityPlan(planType));
  }

  class PlanAuditController {
    static get $inject () {
      return ['alerts', 'orchestrator', 'overlay', 'planConfiguration', '$scope', '$window'];
    }

    constructor (alerts, orchestrator, overlay, planConfiguration, $scope, $window) {
      this.alerts = alerts;
      this.orchestrator = orchestrator;
      this.overlay = overlay;
      this.planConfiguration = planConfiguration;
      this.$scope = $scope;
      this.$window = $window;
      this.$onInit();
    }

    _setDateRange () {
      this.$scope.model.maxStartDate = DateUtils.fromOffset(this.$scope.model.filters.editEndDate, -1, Enums.TimeUnit.DAY);
      this.$scope.model.minEndDate = DateUtils.fromOffset(this.$scope.model.filters.editStartDate, 1, Enums.TimeUnit.DAY);
    }

    download () {
      if (!this.isSettingsValid()) {
        return;
      }
      this.overlay.show('Fetching audit trail');
      this.orchestrator.audit(PlanMetadata.create({ type: this.$scope.model.planDefinition.planType }), this.$scope.model.filters)
        .then((url) => this.$window.open(url, '_blank'))
        .catch((error) => this.alerts.error(error, Enums.AlertImportance.INTERRUPT))
        .finally(() => this.overlay.hide());
    }

    isSettingsValid () {
      return !_.isNil(this.$scope.model.planDefinition);
    }

    $onInit () {
      this.$scope.model = {
        filters: {
          editEndDate: DateUtils.format(Enums.DateFormat.IsoDate),
          editStartDate: DateUtils.fromOffset(DateUtils.of(), -1, Enums.TimeUnit.DAY)
        },
        maxEndDate: DateUtils.format(Enums.DateFormat.IsoDate)
      };
      this.$scope.methods = {
        download: this.download.bind(this),
        isSettingsValid: this.isSettingsValid.bind(this),
        selectEndDate: (date) => {
          this.$scope.model.filters.editEndDate = date;
          this._setDateRange();
        },
        selectStartDate: (date) => {
          this.$scope.model.filters.editStartDate = date;
          this._setDateRange();
        }
      };
      this.planConfiguration.planDefinition().list()
        .then((definitions) => this.$scope.model.planDefinitions = definitions.filter((definition) => definition.isAuditable() && capacityPlansFilter(definition.planType)));
    }
  }
  angular.module('application.controllers').controller('PlanAuditController', PlanAuditController);
})();
