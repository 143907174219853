/* globals AbstractDataViewController, Enumeration, MarketplaceModelDefinition */
(function () {
  'use strict';
  const DEFAULT_PREDICTION_INTERVAL_PERCENT = 95;

  const MODES = Enumeration
    .create('WEEKLY_PLANNING', 'BACK_TESTING')
    .asStringValue((key) => _.startCase(_.lowerCase(key)));

  class ModelMarketplaceController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', 'modelMarketplace', 'overlay', '$scope'];
    }

    constructor (alerts, modelMarketplace, overlay, $scope) {
      super($scope);
      this.alerts = alerts;
      this.modelMarketplace = modelMarketplace;
      this.overlay = overlay;
      this.$onInit();
    }

    _getModelParameters () {
      const result = {};
      if (this.$scope.methods.isAutoPilotEligible()) {
        return result;
      }
      _.forEach(
        _.concat(this.$scope.model.selectedModel.predictionModels, this.$scope.model.selectedModel.dataProcessingModel),
        (model) => {
          if (!_.isEmpty(model) && !_.isEmpty(model.modelParameters)) {
            result[model.modelName] = model.modelParameters;
          }
        }
      );
      return result;
    }

    _getRunBody () {
      const runBody = {
        backTesting: this.$scope.settings.mode.isBackTesting(),
        dataProcessingModelExist: this.$scope.model.isDataProcessingModelExist,
        modelDataFiles: this.$scope.model.selectedModel.modelDataFiles,
        modelParameters: this._getModelParameters(),
        predictionInterval: this.$scope.model.predictionInterval,
        predictionIntervalEnabled: this.$scope.model.isPredictionIntervalEnabled,
        predictionModelDataProvided: true,
        predictionModels: _.map(this.$scope.model.selectedModel.predictionModels, 'modelName'),
        runAutoPilot: this.$scope.methods.isAutoPilotEligible()
      };

      if (this.$scope.model.isDataProcessingModelExist) {
        runBody.dataProcessingModel = this.$scope.model.selectedModel.dataProcessingModel.modelName;
      }

      if (this.$scope.settings.mode.isBackTesting()) {
        runBody.startDate = this.$scope.model.backTesting.startDate;
        runBody.backTestingEndDate = this.$scope.model.backTesting.endDate;
        runBody.backTestingForecastWeek = this.$scope.model.backTesting.forecastWeek;
        runBody.modelRunName = this.$scope.model.backTesting.runName;
      } else {
        runBody.startDate = this.$scope.model.weeklyPlanning.startDate;
        runBody.forecastHorizon = this.$scope.model.weeklyPlanning.forecastHorizon;
        runBody.modelRunName = this.$scope.model.weeklyPlanning.runName;
      }
      return runBody;
    }

    isSettingsValid () {
      return this.$scope.methods.isValidFileSelection() && this.$scope.methods.isValidModelSelection();
    }

    submit () {
      this.overlay.show('Uploading files');
      this.modelMarketplace.uploadToS3(this.$scope.model.selectedModel.files)
        .then((uploadedS3Locations) => {
          this.$scope.model.selectedModel.modelDataFiles = uploadedS3Locations;
          this.overlay.message('Triggering model');
          return this.modelMarketplace.run(this._getRunBody())
            .then((response) => {
              this.alerts.success(`Triggered ${this.$scope.model.selectedModel.modelName} run successfully with execution Id: ${response.executionId}`);
              this.resetState();
              this.collapseSettings(true);
            });
        })
        .catch((error) => this.alerts.error(error))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      super.$onInit(
        ['MODEL_SELECTION', 'FILE_UPLOAD', 'EDIT_PARAMETERS'],
        {
          'model.backTesting': () => ({}),
          'model.backTesting.forecastWeek': () => 3,
          'model.backTesting.publishToPlanStore': () => false,
          'model.isDataProcessingModelExist': () => false,
          'model.isPredictionIntervalEnabled': () => false,
          'model.predictionInterval': () => DEFAULT_PREDICTION_INTERVAL_PERCENT,
          'model.predictionModelType': () => MarketplaceModelDefinition.type().PREDICTION_MODEL,
          'model.selectedModel': () => ({}),
          'model.weeklyPlanning': () => ({}),
          'model.weeklyPlanning.forecastHorizon': () => 13,
          'model.weeklyPlanning.maxForecastHorizon': () => 26,
          'model.weeklyPlanning.publishToPlanStore': () => false,
          'settings.isAutoPilotEnabled': () => true
        }
      );
      this.$scope.methods.isAutoPilotEligible = () => this.$scope.methods.isValidAutoPilotCondition() && this.$scope.settings.isAutoPilotEnabled;
      this.$scope.methods.isModelParametersEmpty = (model) => _.isEmpty(model) || _.isEmpty(model.modelParameters);

      //leaving this as a function for now, as more conditions will be added soon.
      this.$scope.methods.isValidAutoPilotCondition = () => this.$scope.settings.mode.isBackTesting();

      this.$scope.methods.isValidBackTesting = () => !_.isEmpty(this.$scope.model.selectedModel.predictionModels)
        && !_.isEmpty(this.$scope.model.backTesting.runName)
        && !_.isNil(this.$scope.model.backTesting.forecastWeek)
        && !_.isNil(this.$scope.model.backTesting.startDate)
        && !_.isNil(this.$scope.model.backTesting.endDate);

      this.$scope.methods.isValidFileSelection = () => this.$scope.methods.isValidModelSelection()
        && !_.isNil(this.$scope.model.selectedModel.files)
        && !_.isNil(this.$scope.model.selectedModel.dataSources)
        && Object.keys(this.$scope.model.selectedModel.files).length === this.$scope.model.selectedModel.dataSources.length;

      this.$scope.methods.isValidModelSelection = () => this.$scope.methods.isValidWeeklyModel() || this.$scope.methods.isValidBackTesting();

      this.$scope.methods.isValidWeeklyModel = () => !_.isEmpty(this.$scope.model.selectedModel.predictionModels)
        && !_.isEmpty(this.$scope.model.weeklyPlanning.runName)
        && !_.isNil(this.$scope.model.weeklyPlanning.forecastHorizon)
        && !_.isNil(this.$scope.model.weeklyPlanning.startDate);

      this.$scope.methods.onFileSelected = (file, fileType) => {
        if (_.isNil(this.$scope.model.selectedModel.files)) {
          this.$scope.model.selectedModel.files = {};
        }
        this.$scope.model.selectedModel.files[fileType] = file;
      };

      this.$scope.methods.onSelectedModelChange = (model) => this.$scope.model.selectedModel = model;

      this.$scope.methods.resetPredictionInterval = () => this.$scope.model.predictionInterval = DEFAULT_PREDICTION_INTERVAL_PERCENT;

      this.$scope.methods.setFileUploadStep = () => {
        if (!this.$scope.methods.isValidModelSelection()) {
          return;
        }
        this.$scope.methods.steps.set(this.$scope.STEPS.FILE_UPLOAD);
        this.$scope.model.selectedModel.dataSources = _.uniqBy(_.flatMap(_.map(this.$scope.model.selectedModel.predictionModels, 'dataSources')), 'fileKey');
      };

      this.$scope.methods.resetDataProcessingModel = () => this.$scope.model.selectedModel.dataProcessingModel = null;

      this.$scope.methods.resetState = () => this.resetState();

      this.$scope.settings.mode = {
        active: MODES.WEEKLY_PLANNING,
        isBackTesting: () => this.$scope.settings.mode.active === MODES.BACK_TESTING,
        isWeeklyPlanning: () => this.$scope.settings.mode.active === MODES.WEEKLY_PLANNING,
        list: Enumeration.values(MODES)
      };

      this.collapseSettings(true);

      this.$scope.modelOptions = {};

      this.modelMarketplace.models(MarketplaceModelDefinition.type().DATA_PROCESSING_MODEL).then(
        (models) => {
          this.$scope.modelOptions.dataProcessingModels = models;
        }
      );
    }
  }

  angular.module('application.controllers').controller('ModelMarketplaceController', ModelMarketplaceController);
})();
