/* globals AbstractSourceBasedModel, OrchestratorExecutionStep, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'awsExecutionArn',
    'currentStep',
    'executionId',
    'executionWeekDate',
    'scenario',
    'scope',
    'status',
    'user',
    'version'
  ]);

  class OrchestratorExecution extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.steps = _.map(source.steps, (step) => OrchestratorExecutionStep.create(step));
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.status, prefix);
    }
  }

  window.OrchestratorExecution = Object.freeze(OrchestratorExecution);
})();
