(function () {
  'use strict';
  const CURRENT_VALUE_PROPERTY = 'currentValue';
  const PREVIOUS_VALUE_PROPERTY = 'previousValue';

  class Changes {
    static current (target) {
      return _.get(target, CURRENT_VALUE_PROPERTY);
    }

    static has (target) {
      return _.has(target, PREVIOUS_VALUE_PROPERTY) && _.has(target, CURRENT_VALUE_PROPERTY);
    }

    static is (target, fn) {
      return fn(this.current(target));
    }

    static none (...targets) {
      return _.every(targets, (target) => _.isEqual(this.previous(target), this.current(target)) || _.sop.equalFunctions(this.previous(target), this.current(target)));
    }

    static only (changes, ...targets) {
      const keys = _.keys(changes);
      return keys.length === targets.length && _.isEmpty(_.xor(keys, targets));
    }

    static previous (target) {
      return _.get(target, PREVIOUS_VALUE_PROPERTY);
    }
  }
  Object.freeze(Changes);

  class Binding {
    static get changes () {
      return Changes;
    }
  }

  window.Binding = Object.freeze(Binding);
})();
