/* globals AbstractSourceBasedModel, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'executionId',
    'name',
    'modelMetrics',
    'modelParameters',
    'modelRunJobStatus',
    'modelRunJobCompletedTime',
    'outputS3Location'
  ]);

  class MarketplaceModelRun extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
    }

    isRunning () {
      return TaskStatus.isRunning(this.modelRunJobStatus);
    }

    isScheduled () {
      return TaskStatus.isScheduled(this.modelRunJobStatus);
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.modelRunJobStatus);
    }

    statusClass (prefix) {
      return TaskStatus.statusClass(this.modelRunJobStatus, prefix);
    }
  }

  window.MarketplaceModelRun = Object.freeze(MarketplaceModelRun);
})();
