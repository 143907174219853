'use strict';
angular.module('application.components')
  .component('tile', {
    bindings: {
      disabled: '@',
      hoverText: '@',
      icon: '@',
      subTitle: '@',
      title: '@'
    },
    templateUrl: 'templates/components/tile.component.html'
  });
