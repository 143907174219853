/* globals AbstractServiceEndpoint, Configuration, DailyFlashMetric, DataPipelineDelayStatus, DataPipelineWorkflow */
(function () {
  'use strict';

  const SANDOP_TENANT = 'SandOP';

  class DataPipelineService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['$q', 'request'];
    }

    constructor ($q, request) {
      super(request, Configuration.services.dataPipeline);
      this.$q = $q;
    }

    _getTenant (scope) {
      // Besides SandOP, the naming Convention for Tenant's is Camel Case.
      return scope.isOfTenantSandop() || scope.isOfTenantCapacity() ? SANDOP_TENANT : _.replace(scope.tenant.name, / /g, '');
    }

    getDailyFlashEmailMetrics (datasetDate, emailVariant) {
      return this.aws()
        .for('getDailyFlashEmailMetrics', Configuration.scopes.current().code, datasetDate, emailVariant)
        .get()
        .then((response) => ({
          emailSent: response.emailSent,
          metrics: _.map(response.metricList, (metric) => DailyFlashMetric.create(metric))
        }));
    }

    getDataDelayStatus (scope, date) {
      return this.aws()
        .for('getDataDelayStatus', scope, date)
        .get()
        .then((response) => DataPipelineDelayStatus.create(response));
    }

    getWorkflowExecutions (scopeCode, date, namespace, dataset) {
      const scope = Configuration.scopes.find(scopeCode);
      return this.aws()
        .for('getWorkflowExecutions', this._getTenant(scope), scope.code, date, namespace, dataset)
        .get()
        .then((response) => _.map(response.workflowExecutionsList, (workflow) => DataPipelineWorkflow.create(workflow)));
    }

    getWorkflowStatus (date, scope) {
      return this.aws()
        .for('getWorkflowStatus', this._getTenant(scope), scope.code, date)
        .get()
        .then((response) => _.map(response.workflowStatusList, (workflow) => DataPipelineWorkflow.create(workflow)));
    }

    overrideDailyFlashEmailMetrics (body) {
      return this.aws()
        .for('overrideDailyFlashEmailMetrics')
        .withBody(body, { doNotCompactArrays: true })
        .post();
    }

    putManualBacklog (records) {
      return this.aws()
        .for('putBacklogData')
        .withBody(records)
        .post();
    }

  }

  angular.module('application.services').service('dataPipeline', DataPipelineService);
})();
