/* globals Configuration, CloudWatchLogEvent, Identity */
(function () {
  'use strict';

  const LOG_GROUP = 'PortalWebClientLogs';

  class LoggerService {
    static get $inject () {
      return ['$log', 'portalStore', '$q'];
    }

    constructor ($log, portalStore, $q) {
      this.$log = $log;
      this.portalStore = portalStore;
      this.$q = $q;
    }

    _configureSession () {
      // If the log stream has been defined, first configure a new stream then send the log.
      if (!_.isNil(this._streamName)) {
        return this.$q.resolve(this._streamName);
      }
      const streamName = Identity.uuid();
      return this.portalStore.createLogStream(LOG_GROUP, streamName).then(() => this._streamName = streamName);
    }

    log (message) {
      if (Configuration.environment.current().isLocal()) {
        this.$log.info(message);
        return;
      }
      return this._configureSession().then((streamName) => this.portalStore.putLogEvent(
        LOG_GROUP,
        streamName,
        CloudWatchLogEvent.create(message),
        this._sequenceToken
      ).then((data) => this._sequenceToken = data.nextSequenceToken));
    }
  }

  angular.module('application.services').service('logger', LoggerService);
})();
