/* globals Configuration */
(function () {
  'use strict';
  const AWS_REGION = Object.freeze({
    EU_WEST_1: 'eu-west-1',
    NO_REGION: '',
    US_EAST_1: 'us-east-1',
    US_WEST_2: 'us-west-2'
  });
  const LOCAL_BASE_URL = `${window.location.protocol}//${window.location.host}`;
  const UNDEFINED_ENDPOINT = Object.freeze({
    region: AWS_REGION.US_WEST_2,
    url: ''
  });

  function endpoint (endpoint, region) {
    if (_.isNil(region)) {
      // A region of nil (undefined or null) represents localhost mock services
      // and the endpoint argument is the mock service endpoint
      return Object.freeze({
        region: AWS_REGION.US_WEST_2,
        url: LOCAL_BASE_URL + endpoint
      });
    }
    return Object.freeze({
      region: region,
      url: endpoint
    });
  }

  class ServiceEndpoint {
    constructor (key, token) {
      this.key = key;
      this.token = token;
      if (_.isUndefined(this.token)) {
        this.token = _.toUpper(_.kebabCase(this.key));
      }
      if (_.isNull(this.token)) {
        delete this.token;
      }
    }

    static create (key, token) {
      return Object.freeze(new ServiceEndpoint(key, token));
    }
  }
  Object.freeze(ServiceEndpoint);

  const SERVICES = {};
  const addServiceEndpoint = (key, token) => SERVICES[key] = ServiceEndpoint.create(key, token);
  addServiceEndpoint('allocation', 'ALLOCATION-SERVICE');
  addServiceEndpoint('allocationTree', 'ALLOCATION-TREE-SERVICE');
  addServiceEndpoint('bastion', null);
  addServiceEndpoint('dataActuals');
  addServiceEndpoint('dataPipeline');
  addServiceEndpoint('gasTankAllocationService');
  addServiceEndpoint('metricsService');
  addServiceEndpoint('modelMarketplace');
  addServiceEndpoint('orchestrator');
  addServiceEndpoint('planConfigurationStore');
  addServiceEndpoint('planStore');
  addServiceEndpoint('portalAuth');
  addServiceEndpoint('portalStore');
  addServiceEndpoint('romScenarioConfiguration', 'ROM-SCENARIO-CONFIGURATION-SERVICE');
  addServiceEndpoint('warehouseRegistry');
  Object.freeze(SERVICES);

  Configuration.defineProperty('services', SERVICES);

  const STACKS = Object.freeze({
    beta: {
      AQ: {
        bastion: endpoint('https://beta-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        portalAuth: endpoint('https://4mfvxwa4xe.execute-api.us-east-1.amazonaws.com/Beta-NA', AWS_REGION.US_EAST_1)
      },
      NA: {
        allocation: endpoint('https://cu5xzzl24g.execute-api.us-east-1.amazonaws.com/Beta', AWS_REGION.US_EAST_1),
        allocationTree: endpoint('https://pcif7u4dhi.execute-api.us-east-1.amazonaws.com/beta', AWS_REGION.US_EAST_1),
        bastion: endpoint('https://beta-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://7f9st0rpy3.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        dataPipeline: endpoint('https://dslrpzrf7l.execute-api.us-west-2.amazonaws.com/Beta', AWS_REGION.US_WEST_2),
        gasTankAllocationService: endpoint('https://hxfl93r83h.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        metricsService: endpoint('https://j4tat9wa2f.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        modelMarketplace: endpoint('https://epdi74tzg6.execute-api.us-west-2.amazonaws.com/beta', AWS_REGION.US_WEST_2),
        orchestrator: endpoint('https://d9y6liau1h.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planConfigurationStore: endpoint('https://6peokgayia.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planStore: endpoint('https://jooanh6oof.execute-api.us-east-1.amazonaws.com/Beta', AWS_REGION.US_EAST_1),
        portalAuth: endpoint('https://4mfvxwa4xe.execute-api.us-east-1.amazonaws.com/Beta-NA', AWS_REGION.US_EAST_1),
        portalStore: endpoint('https://n6lhrbsime.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        romScenarioConfiguration: endpoint('https://fz3pstwz4a.execute-api.us-east-1.amazonaws.com/beta', AWS_REGION.US_EAST_1),
        warehouseRegistry: endpoint('https://fkinnujhne.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2)
      }
    },
    gamma: {
      AQ: {
        bastion: endpoint('https://gamma-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        portalAuth: endpoint('https://5m45n7q8xj.execute-api.us-east-1.amazonaws.com/Gamma-NA', AWS_REGION.US_EAST_1)
      },
      EU: {
        allocation: endpoint('https://kwrq9vej5f.execute-api.eu-west-1.amazonaws.com/Gamma', AWS_REGION.EU_WEST_1),
        allocationTree: endpoint('https://y7l1tqsf7g.execute-api.eu-west-1.amazonaws.com/gamma', AWS_REGION.EU_WEST_1),
        bastion: endpoint('https://gamma-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://38mo7biccj.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        dataPipeline: endpoint('https://edvlzphj5l.execute-api.eu-west-1.amazonaws.com/Gamma', AWS_REGION.EU_WEST_1),
        gasTankAllocationService: endpoint('https://7udli69l52.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        metricsService: endpoint('https://6q82vrp7q3.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        modelMarketplace: endpoint('https://xcpcuzv2d2.execute-api.eu-west-1.amazonaws.com/gamma', AWS_REGION.EU_WEST_1),
        orchestrator: endpoint('https://lpqhw999kd.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        planConfigurationStore: endpoint('https://jcyad1r1k8.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        planStore: endpoint('https://qxbegz87rg.execute-api.eu-west-1.amazonaws.com/Gamma', AWS_REGION.EU_WEST_1),
        portalAuth: endpoint('https://5m45n7q8xj.execute-api.us-east-1.amazonaws.com/Gamma-NA', AWS_REGION.US_EAST_1),
        portalStore: endpoint('https://0qptscql5k.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://hsaaos5h25.execute-api.eu-west-1.amazonaws.com/gamma', AWS_REGION.EU_WEST_1),
        warehouseRegistry: endpoint('https://97p4gmpy6e.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1)
      },
      FE: {
        allocation: endpoint('https://ucc5h8o8ta.execute-api.us-west-2.amazonaws.com/Gamma', AWS_REGION.US_WEST_2),
        allocationTree: endpoint('https://byexker1uc.execute-api.us-west-2.amazonaws.com/gamma', AWS_REGION.US_WEST_2),
        bastion: endpoint('https://gamma-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://ghq7k6p532.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        dataPipeline: endpoint('https://uivx0yn3dh.execute-api.us-west-2.amazonaws.com/Gamma', AWS_REGION.US_WEST_2),
        gasTankAllocationService: endpoint('https://boo8f2cbb0.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        metricsService: endpoint('https://hdbolkcnv1.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        modelMarketplace: endpoint('https://6oj2kw24p7.execute-api.us-west-2.amazonaws.com/gamma', AWS_REGION.US_WEST_2),
        orchestrator: endpoint('https://07cri8gmyl.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planConfigurationStore: endpoint('https://c9q0ibrkri.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planStore: endpoint('https://y0agtqyhtd.execute-api.us-west-2.amazonaws.com/Gamma', AWS_REGION.US_WEST_2),
        portalAuth: endpoint('https://5m45n7q8xj.execute-api.us-east-1.amazonaws.com/Gamma-NA', AWS_REGION.US_EAST_1),
        portalStore: endpoint('https://0qptscql5k.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://w4w62zwptf.execute-api.us-west-2.amazonaws.com/gamma', AWS_REGION.US_WEST_2),
        warehouseRegistry: endpoint('https://e37b9kuyd7.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2)
      },
      NA: {
        allocation: endpoint('https://eo9hftxq75.execute-api.us-east-1.amazonaws.com/Gamma', AWS_REGION.US_EAST_1),
        allocationTree: endpoint('https://y6lq3vfsji.execute-api.us-east-1.amazonaws.com/gamma', AWS_REGION.US_EAST_1),
        bastion: endpoint('https://gamma-bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://17wl79ok45.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        dataPipeline: endpoint('https://hpxe848s85.execute-api.us-east-1.amazonaws.com/Gamma', AWS_REGION.US_EAST_1),
        gasTankAllocationService: endpoint('https://qf62tkvku1.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        metricsService: endpoint('https://1c353bx495.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        modelMarketplace: endpoint('https://iqgql813f7.execute-api.us-east-1.amazonaws.com/gamma', AWS_REGION.US_EAST_1),
        orchestrator: endpoint('https://46q73nu3f4.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        planConfigurationStore: endpoint('https://ani4gwdehe.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        planStore: endpoint('https://yxskfcnne2.execute-api.us-east-1.amazonaws.com/Gamma', AWS_REGION.US_EAST_1),
        portalAuth: endpoint('https://5m45n7q8xj.execute-api.us-east-1.amazonaws.com/Gamma-NA', AWS_REGION.US_EAST_1),
        portalStore: endpoint('https://0qptscql5k.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://0jgiy0hdzh.execute-api.us-east-1.amazonaws.com/gamma', AWS_REGION.US_EAST_1),
        warehouseRegistry: endpoint('https://vyha7dad4m.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1)
      }
    },
    local: {
      AQ: {
        bastion: endpoint('/mock/bastion'),
        portalAuth: endpoint('/mock/portal-auth')
      },
      NA: {
        allocation: endpoint('/mock/allocation'),
        allocationTree: endpoint('/mock/allocationTree'),
        bastion: endpoint('/mock/bastion'),
        dataActuals: endpoint('/mock/data-actuals'),
        dataPipeline: endpoint('/mock/data-pipeline'),
        gasTankAllocationService: endpoint('/mock/gas-tank-allocation-service'),
        metricsService: endpoint('/mock/metrics-service'),
        modelMarketplace: endpoint('/mock/model-marketplace'),
        orchestrator: endpoint('/mock/orchestrator'),
        planConfigurationStore: endpoint('/mock/plan-configuration-store'),
        planStore: endpoint('/mock/plan-store'),
        portalAuth: endpoint('/mock/portal-auth'),
        portalStore: endpoint('/mock/portal-store'),
        romScenarioConfiguration: endpoint('/mock/rom-scenario-configuration'),
        warehouseRegistry: endpoint('/mock/warehouse-registry')
      }
    },
    prod: {
      AQ: {
        bastion: endpoint('https://bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        portalAuth: endpoint('https://abc2baruq3.execute-api.us-west-2.amazonaws.com/Prod-FE', AWS_REGION.US_WEST_2)
      },
      EU: {
        allocation: endpoint('https://kdi9fr05ac.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        allocationTree: endpoint('https://0ietj6fp93.execute-api.eu-west-1.amazonaws.com/prod', AWS_REGION.EU_WEST_1),
        bastion: endpoint('https://bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://a3vm712544.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        dataPipeline: endpoint('https://xsm1d73m39.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        gasTankAllocationService: endpoint('https://mbadrosc9f.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        metricsService: endpoint('https://zed7n64hx3.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        modelMarketplace: endpoint('https://sery4m6du4.execute-api.eu-west-1.amazonaws.com/prod', AWS_REGION.EU_WEST_1),
        orchestrator: endpoint('https://4s9as24fk1.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        planConfigurationStore: endpoint('https://8y6j0catte.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        planStore: endpoint('https://8othe1kd9b.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1),
        portalAuth: endpoint('https://abc2baruq3.execute-api.us-west-2.amazonaws.com/Prod-FE', AWS_REGION.US_WEST_2),
        portalStore: endpoint('https://z979gmafl1.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://5ahykmg6zi.execute-api.eu-west-1.amazonaws.com/prod', AWS_REGION.EU_WEST_1),
        warehouseRegistry: endpoint('https://a7gi0ao8i6.execute-api.eu-west-1.amazonaws.com/Prod', AWS_REGION.EU_WEST_1)
      },
      FE: {
        allocation: endpoint('https://gyaqsufavd.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        allocationTree: endpoint('https://bb6q7pr638.execute-api.us-west-2.amazonaws.com/prod', AWS_REGION.US_WEST_2),
        bastion: endpoint('https://bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://ou8cs7lo8b.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        dataPipeline: endpoint('https://030w25ne4a.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        gasTankAllocationService: endpoint('https://ruzs6tm35f.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        metricsService: endpoint('https://wc14vi5w2l.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        modelMarketplace: endpoint(' https://s21aiv8wfi.execute-api.us-west-2.amazonaws.com/prod', AWS_REGION.US_WEST_2),
        orchestrator: endpoint('https://ujo9hcprsd.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planConfigurationStore: endpoint('https://y59lqugog3.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        planStore: endpoint('https://53jfmh9knl.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2),
        portalAuth: endpoint('https://abc2baruq3.execute-api.us-west-2.amazonaws.com/Prod-FE', AWS_REGION.US_WEST_2),
        portalStore: endpoint('https://z979gmafl1.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://l5xx4mnnsg.execute-api.us-west-2.amazonaws.com/prod', AWS_REGION.US_WEST_2),
        warehouseRegistry: endpoint('https://rgb4cbfrh8.execute-api.us-west-2.amazonaws.com/Prod', AWS_REGION.US_WEST_2)
      },
      NA: {
        allocation: endpoint('https://drc1wqku1j.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        allocationTree: endpoint('https://ugj8e1knwb.execute-api.us-east-1.amazonaws.com/prod', AWS_REGION.US_EAST_1),
        bastion: endpoint('https://bastion-sandop.corp.amazon.com', AWS_REGION.NO_REGION),
        dataActuals: endpoint('https://pki018cv5j.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        dataPipeline: endpoint('https://xbiebwtpg4.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        gasTankAllocationService: endpoint('https://knkchwew4g.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        metricsService: endpoint('https://hlacla1fcc.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        modelMarketplace: endpoint('https://kcrnfmygf8.execute-api.us-east-1.amazonaws.com/prod', AWS_REGION.US_EAST_1),
        orchestrator: endpoint('https://g4rser375f.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        planConfigurationStore: endpoint('https://dfwujapw27.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        planStore: endpoint('https://xd3i5b4lve.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        portalAuth: endpoint('https://abc2baruq3.execute-api.us-west-2.amazonaws.com/Prod-FE', AWS_REGION.US_WEST_2),
        portalStore: endpoint('https://z979gmafl1.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1),
        romScenarioConfiguration: endpoint('https://3qvpa4tvx7.execute-api.us-east-1.amazonaws.com/prod', AWS_REGION.US_EAST_1),
        warehouseRegistry: endpoint('https://hbpbohz26j.execute-api.us-east-1.amazonaws.com/Prod', AWS_REGION.US_EAST_1)
      }
    }
  });

  // Allocation Service: https://w.amazon.com/index.php/SandOP_Systems/Allocation_Service
  // Allocation Tree Service: https://w.amazon.com/index.php/SOPCoreForecast/EPM/AllocationTree/Design
  // Bastion: Bastion is not an AWS service and specifies no region due to this
  // Data Actuals: https://w.amazon.com/index.php/SandOP_Systems/Data_Actuals#HEndpoints
  // Data Ingestion: https://w.amazon.com/bin/view/SandOP_Systems/Data_Ingestion_Pipeline/Data_Ingestion_Service_API/#HEndpoints
  // Data Pipeline Service: https://w.amazon.com/bin/view/SCOT/Forecasting/SandOP/Components/DataIngestionPipeline/
  // Model Marketplace: https://w.amazon.com/index.php/SandOP_Systems/Prediction_Services/ModelMarketplace
  // Orchestator: https://w.amazon.com/index.php/SandOP_Systems/Orchestrator#API_Gateway_endpoints
  // Plan Configuration Store: https://w.amazon.com/index.php/SandOP_Systems/Plan_Configuration_Store#API_Gateway_Endpoint
  // Plan Store: https://w.amazon.com/index.php/SOPCoreForecast/Projects/PlanStore#Onboarding
  // Portal Store: https://w.amazon.com/index.php/SandOP_Systems/Portal_Store#Endpoints
  // Warehouse Registry: https://w.amazon.com/index.php/SandOP_Systems/Warehouse_Registry#Endpoints
  const ENDPOINTS = Object.freeze({
    beta: Object.freeze({
      AE: STACKS.beta.NA,
      AQ: STACKS.beta.AQ,
      AU: STACKS.beta.NA,
      BR: STACKS.beta.NA,
      CA: STACKS.beta.NA,
      CAPACITY_AE: STACKS.beta.NA,
      CAPACITY_AU: STACKS.beta.NA,
      CAPACITY_BR: STACKS.beta.NA,
      CAPACITY_CA: STACKS.beta.NA,
      CAPACITY_CL: STACKS.beta.NA,
      CAPACITY_CO: STACKS.beta.NA,
      CAPACITY_DE: STACKS.beta.NA,
      CAPACITY_EG: STACKS.beta.NA,
      CAPACITY_ES: STACKS.beta.NA,
      CAPACITY_EU: STACKS.beta.NA,
      CAPACITY_FR: STACKS.beta.NA,
      CAPACITY_GB: STACKS.beta.NA,
      CAPACITY_IN: STACKS.beta.NA,
      CAPACITY_IT: STACKS.beta.NA,
      CAPACITY_JP: STACKS.beta.NA,
      CAPACITY_MX: STACKS.beta.NA,
      CAPACITY_NG: STACKS.beta.NA,
      CAPACITY_NL: STACKS.beta.NA,
      CAPACITY_PL: STACKS.beta.NA,
      CAPACITY_SA: STACKS.beta.NA,
      CAPACITY_SE: STACKS.beta.NA,
      CAPACITY_SG: STACKS.beta.NA,
      CAPACITY_TR: STACKS.beta.NA,
      CAPACITY_US: STACKS.beta.NA,
      CAPACITY_ZA: STACKS.beta.NA,
      CL: STACKS.beta.NA,
      CO: STACKS.beta.NA,
      DE: STACKS.beta.NA,
      EG: STACKS.beta.NA,
      ES: STACKS.beta.NA,
      EU: STACKS.beta.NA,
      FR: STACKS.beta.NA,
      GB: STACKS.beta.NA,
      IN: STACKS.beta.NA,
      IT: STACKS.beta.NA,
      JP: STACKS.beta.NA,
      MX: STACKS.beta.NA,
      NA: STACKS.beta.NA,
      NG: STACKS.beta.NA,
      NL: STACKS.beta.NA,
      PHARMACY_US: STACKS.beta.NA,
      PL: STACKS.beta.NA,
      SA: STACKS.beta.NA,
      SE: STACKS.beta.NA,
      SG: STACKS.beta.NA,
      SORT_CENTER_CA: STACKS.beta.NA,
      SORT_CENTER_US: STACKS.beta.NA,
      SSD_US: STACKS.beta.NA,
      TR: STACKS.beta.NA,
      US: STACKS.beta.NA,
      ZA: STACKS.beta.NA
    }),
    /**
     * Get the endpoint related to the service key.
     *
     * @param serviceKey service key
     */
    endpoint: function (serviceKey) {
      const environment = Configuration.environment.current();
      if (environment.isUnknown()) {
        // If no environment is defined then we are running unit tests or in an uncataloged environment.
        // Uncataloged environments should fail miserably so they can be cataloged.
        // Unit tests need to return an object for AWS service endpoints and an empty base URL as a convenience for HTTP mocking.
        return UNDEFINED_ENDPOINT;
      }

      const scope = Configuration.scopes.current();
      const environmentConfiguration = _.get(this, environment.designator);
      const scopeConfiguration = _.get(environmentConfiguration, scope.portalCode);
      if (_.isNil(scopeConfiguration)) {
        throw new Error(`Regionalization: Environment configuration is undefined for ${scope.portalCode}`);
      }

      const endpoint = _.get(scopeConfiguration, serviceKey);
      if (_.isNil(endpoint)) {
        throw new Error(`Regionalization: Service ${serviceKey} is undefined for ${scope.portalCode}`);
      }

      return endpoint;
    },
    gamma: Object.freeze({
      AE: STACKS.gamma.EU,
      AQ: STACKS.gamma.AQ,
      AU: STACKS.gamma.FE,
      BR: STACKS.gamma.NA,
      CA: STACKS.gamma.NA,
      CAPACITY_AE: STACKS.gamma.EU,
      CAPACITY_AU: STACKS.gamma.FE,
      CAPACITY_BR: STACKS.gamma.NA,
      CAPACITY_CA: STACKS.gamma.NA,
      CAPACITY_CL: STACKS.gamma.NA,
      CAPACITY_CO: STACKS.gamma.NA,
      CAPACITY_DE: STACKS.gamma.EU,
      CAPACITY_EG: STACKS.gamma.EU,
      CAPACITY_ES: STACKS.gamma.EU,
      CAPACITY_EU: STACKS.gamma.EU,
      CAPACITY_FR: STACKS.gamma.EU,
      CAPACITY_GB: STACKS.gamma.EU,
      CAPACITY_IN: STACKS.gamma.EU,
      CAPACITY_IT: STACKS.gamma.EU,
      CAPACITY_JP: STACKS.gamma.FE,
      CAPACITY_MX: STACKS.gamma.NA,
      CAPACITY_NG: STACKS.gamma.EU,
      CAPACITY_NL: STACKS.gamma.EU,
      CAPACITY_PL: STACKS.gamma.EU,
      CAPACITY_SA: STACKS.gamma.EU,
      CAPACITY_SE: STACKS.gamma.EU,
      CAPACITY_SG: STACKS.gamma.FE,
      CAPACITY_TR: STACKS.gamma.EU,
      CAPACITY_US: STACKS.gamma.NA,
      CAPACITY_ZA: STACKS.gamma.EU,
      CL: STACKS.gamma.NA,
      CO: STACKS.gamma.NA,
      DE: STACKS.gamma.EU,
      EG: STACKS.gamma.EU,
      ES: STACKS.gamma.EU,
      EU: STACKS.gamma.EU,
      FR: STACKS.gamma.EU,
      GB: STACKS.gamma.EU,
      IN: STACKS.gamma.EU,
      IT: STACKS.gamma.EU,
      JP: STACKS.gamma.FE,
      MX: STACKS.gamma.NA,
      NA: STACKS.gamma.NA,
      NG: STACKS.gamma.EU,
      NL: STACKS.gamma.EU,
      PHARMACY_US: STACKS.gamma.NA,
      PL: STACKS.gamma.EU,
      SA: STACKS.gamma.EU,
      SE: STACKS.gamma.EU,
      SG: STACKS.gamma.FE,
      SORT_CENTER_CA: STACKS.gamma.NA,
      SORT_CENTER_US: STACKS.gamma.NA,
      SSD_US: STACKS.gamma.NA,
      TR: STACKS.gamma.EU,
      US: STACKS.gamma.NA,
      ZA: STACKS.gamma.EU
    }),
    local: Object.freeze({
      AE: STACKS.local.NA,
      AQ: STACKS.local.AQ,
      AU: STACKS.local.NA,
      BR: STACKS.local.NA,
      CA: STACKS.local.NA,
      CAPACITY_AE: STACKS.local.NA,
      CAPACITY_AU: STACKS.local.NA,
      CAPACITY_BR: STACKS.local.NA,
      CAPACITY_CA: STACKS.local.NA,
      CAPACITY_CL: STACKS.local.NA,
      CAPACITY_CO: STACKS.local.NA,
      CAPACITY_DE: STACKS.local.NA,
      CAPACITY_EG: STACKS.local.NA,
      CAPACITY_ES: STACKS.local.NA,
      CAPACITY_EU: STACKS.local.NA,
      CAPACITY_FR: STACKS.local.NA,
      CAPACITY_GB: STACKS.local.NA,
      CAPACITY_IN: STACKS.local.NA,
      CAPACITY_IT: STACKS.local.NA,
      CAPACITY_JP: STACKS.local.NA,
      CAPACITY_MX: STACKS.local.NA,
      CAPACITY_NG: STACKS.local.NA,
      CAPACITY_NL: STACKS.local.NA,
      CAPACITY_PL: STACKS.local.NA,
      CAPACITY_SA: STACKS.local.NA,
      CAPACITY_SE: STACKS.local.NA,
      CAPACITY_SG: STACKS.local.NA,
      CAPACITY_TR: STACKS.local.NA,
      CAPACITY_US: STACKS.local.NA,
      CAPACITY_ZA: STACKS.local.NA,
      CL: STACKS.local.NA,
      CO: STACKS.local.NA,
      DE: STACKS.local.NA,
      EG: STACKS.local.NA,
      ES: STACKS.local.NA,
      EU: STACKS.local.NA,
      FR: STACKS.local.NA,
      GB: STACKS.local.NA,
      IN: STACKS.local.NA,
      IT: STACKS.local.NA,
      JP: STACKS.local.NA,
      MX: STACKS.local.NA,
      NA: STACKS.local.NA,
      NG: STACKS.local.NA,
      NL: STACKS.local.NA,
      PHARMACY_US: STACKS.local.NA,
      PL: STACKS.local.NA,
      SA: STACKS.local.NA,
      SE: STACKS.local.NA,
      SG: STACKS.local.NA,
      SORT_CENTER_CA: STACKS.local.NA,
      SORT_CENTER_US: STACKS.local.NA,
      SSD_US: STACKS.local.NA,
      TR: STACKS.local.NA,
      US: STACKS.local.NA,
      ZA: STACKS.local.NA
    }),
    prod: Object.freeze({
      AE: STACKS.prod.EU,
      AQ: STACKS.prod.AQ,
      AU: STACKS.prod.FE,
      BR: STACKS.prod.NA,
      CA: STACKS.prod.NA,
      CAPACITY_AE: STACKS.prod.EU,
      CAPACITY_AU: STACKS.prod.FE,
      CAPACITY_BR: STACKS.prod.NA,
      CAPACITY_CA: STACKS.prod.NA,
      CAPACITY_CL: STACKS.prod.NA,
      CAPACITY_CO: STACKS.prod.NA,
      CAPACITY_DE: STACKS.prod.EU,
      CAPACITY_EG: STACKS.prod.EU,
      CAPACITY_ES: STACKS.prod.EU,
      CAPACITY_EU: STACKS.prod.EU,
      CAPACITY_FR: STACKS.prod.EU,
      CAPACITY_GB: STACKS.prod.EU,
      CAPACITY_IN: STACKS.prod.EU,
      CAPACITY_IT: STACKS.prod.EU,
      CAPACITY_JP: STACKS.prod.FE,
      CAPACITY_MX: STACKS.prod.NA,
      CAPACITY_NG: STACKS.prod.EU,
      CAPACITY_NL: STACKS.prod.EU,
      CAPACITY_PL: STACKS.prod.EU,
      CAPACITY_SA: STACKS.prod.EU,
      CAPACITY_SE: STACKS.prod.EU,
      CAPACITY_SG: STACKS.prod.FE,
      CAPACITY_TR: STACKS.prod.EU,
      CAPACITY_US: STACKS.prod.NA,
      CAPACITY_ZA: STACKS.prod.EU,
      CL: STACKS.prod.NA,
      CO: STACKS.prod.NA,
      DE: STACKS.prod.EU,
      EG: STACKS.prod.EU,
      ES: STACKS.prod.EU,
      EU: STACKS.prod.EU,
      FR: STACKS.prod.EU,
      GB: STACKS.prod.EU,
      IN: STACKS.prod.EU,
      IT: STACKS.prod.EU,
      JP: STACKS.prod.FE,
      MX: STACKS.prod.NA,
      NA: STACKS.prod.NA,
      NG: STACKS.prod.EU,
      NL: STACKS.prod.EU,
      PHARMACY_US: STACKS.prod.NA,
      PL: STACKS.prod.EU,
      SA: STACKS.prod.EU,
      SE: STACKS.prod.EU,
      SG: STACKS.prod.FE,
      SORT_CENTER_CA: STACKS.prod.NA,
      SORT_CENTER_US: STACKS.prod.NA,
      SSD_US: STACKS.prod.NA,
      TR: STACKS.prod.EU,
      US: STACKS.prod.NA,
      ZA: STACKS.prod.EU
    }),
    static: Object.freeze({
      // List of static endpoints that are non-scope specific.
      productLineHelp: 'https://w.amazon.com/bin/view/SCOT/Forecasting/SandOP/IPTUserHelp/ProductLineConfiguration/',
      releaseNotes: 'https://w.amazon.com/index.php/SandOP_Systems/Sales_and_Operations_Portal/Release_Notes',
      userFeedback: 'mailto:sandop-portal-feedback@amazon.com?subject=S%26OPortal%20User%20Feedback',
      userHelp: 'https://w.amazon.com/index.php/SandOP_Systems/Sales_and_Operations_Portal/User_Help',
      userIptBmDataAccessHelp: 'https://w.amazon.com/bin/view/SandopDEHome/NetworkViewer/',
      userIptBmMetricDefinitionHelp: 'https://w.amazon.com/bin/view/SandOP_Systems/Sales_and_Operations_Portal/User_Help/IPT-BM',
      userIptPlanViewerDataAccessHelp: 'https://w.amazon.com/bin/view/SandopDEHome/IPTplanviewersubscriptions/',
      userPermissionsHelp: 'https://w.amazon.com/bin/view/SandOP_Systems/Sales_and_Operations_Portal/User_Help/Permissions',
      userSIM: 'https://issues.amazon.com/issues/create?template=235ea1dd-c353-4e82-8661-87502cd90b65',
      userTT: 'https://river.amazon.com/?org=SCOTInventoryPlanningControl&buildingId=AUS13'
    }),
    staticOf: Object.freeze({
      badgephotos: (alias) => `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${alias}`,
      permissionsBindle: (role, stage) => `https://bindles${stage}.amazon.com/resource/sandop/IPT%20Tenant%20Scope%20Role%20Lock/${role}`,
      phonetool: (alias) => `https://phonetool.amazon.com/users/${alias}`,
      ttLink: (tickedId) => `https://tt.amazon.com/${tickedId}`,
      ttSearch: (searchText) => `https://tt.amazon.com/search?phrase_search_text=${searchText}&search=Search%21`
    })
  });

  Configuration.defineProperty('endpoints', ENDPOINTS);
})();
