/* globals AbstractElementComponent, Binding */
(function () {
  'use strict';

  class AbstractItemSelectorComponent extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element, stateFunctions, bindingChangesFunction) {
      super($element, stateFunctions);
      this.bindingChangesFunction = bindingChangesFunction;
    }

    static get templateUrl () {
      return 'templates/components/abstract-item-selector.component.html';
    }

    static bindings (additionalBindings) {
      return _.defaults(additionalBindings, {
        /* @configuration an object containing:
         *   date (optional) the as of date
         *   type (required) the namespace of the metric configuration
         * No data will be loaded until this property is non-nil.
         */
        configuration: '<',
        disablePreferences: '<',
        disableSearch: '<',
        initialSelections: '<',
        noDefaultSelections: '<',
        onLoadItems: '&',
        onSelectionChange: '&',
        singleItemOnly: '<'
      });
    }

    $onChanges (changes) {
      if (!this.state.isInitialized() || !_.isFunction(this.bindingChangesFunction)) {
        return;
      }

      if (Binding.changes.has(changes.configuration) || this.bindingChangesFunction(changes)) {
        this._loadData();
      }
    }
  }

  window.AbstractItemSelectorComponent = Object.freeze(AbstractItemSelectorComponent);
})();
