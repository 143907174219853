'use strict';
/**
 * Enables an element to be a file drag-n-drop target.
 *
 * Directive must be attached to an element that has an ng-model defined.
 *
 * @name application.directives.fileDrop
 * @example
 * <div file-drop ng-model="$ctrl.files" ng-change="$ctrl.fileSelectionHandler($ctrl.files)">
 */
angular.module('application.directives')
  .directive('fileDrop', function () {
    return {
      link: function ($scope, element, attrs, ngModel) {
        element.on('dragover', function (event) {
          event.stopPropagation();
          event.preventDefault();
          element.addClass('dragover');
          event.dataTransfer.dropEffect = 'copy';
        });

        element.on('dragleave', function (event) {
          event.stopPropagation();
          event.preventDefault();
          element.removeClass('dragover');
          event.dataTransfer.dropEffect = 'none';
        });

        element.on('drop', function (event) {
          event.stopPropagation();
          event.preventDefault();
          element.removeClass('dragover');
          ngModel.$setViewValue(event.dataTransfer.files);
        });

        element.on('$destroy', () => element.off());
      },
      require: 'ngModel',
      restrict: 'A',
      scope: false
    };
  });
