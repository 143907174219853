/* globals AbstractElementComponent, Configuration, Enumeration */
(function () {
  'use strict';
  const GAMMA_BINDLES_STAGE = '-gamma';
  const AUTH_ROLES = Enumeration
    .create('read', 'fcWrite', 'write', 'administrator', 'developer')
    .asExactValue('Read', 'FC Write', 'Write', 'Admin', 'Dev');

  class UserPermissions extends AbstractElementComponent {
    static get $inject () {
      return ['$authentication'];
    }

    constructor ($authentication) {
      super();
      this.$authentication = $authentication;
    }

    /*
     * Expected response structure:
     * {
     *    administrator: { scopePermission: false, scopeRole: 'us-admin' },
     *    developer: { scopePermission: false, scopeRole: 'dev' },
     *    fcWrite: { scopePermission: false, scopeRole: 'us-fc-write' },
     *    read: { scopePermission: true, scopeRole: 'us-read' },
     *    scopeLabel: 'United States of America',
     *    write: { scopePermission: false, scopeRole: 'us-write' }
     * }
     */
    _getScopeAuthDetails (scope) {
      const scopeAuthDetails = {
        scopeLabel: scope.name
      };
      this.getAuthRoleKeys().forEach((role) => {
        const scopeRole = Configuration.authorization.roles[role](scope.portalCode);
        scopeAuthDetails[role] = {
          scopePermission: _.includes(this.userRoles, scopeRole),
          scopeRole: scopeRole
        };
      });
      return scopeAuthDetails;
    }

    _loadData () {
      this.allScopes = _.sortBy(Configuration.scopes.allByTenant(this.tenant, true), ['portalCode']);
      this.userRoles = this.roles || this.$authentication.profile().roles;
      this.scopeRolesPermissionsMap = {};
      this.allScopes.forEach((scope) => this.scopeRolesPermissionsMap[scope.portalCode] = this._getScopeAuthDetails(scope));
    }

    getAuthRoleDisplayValue (role) {
      return AUTH_ROLES[role];
    }

    getAuthRoleKeys () {
      return Enumeration.keys(AUTH_ROLES).filter((role) => this.tenant === 'SANDOP' || role !== 'fcWrite');
    }

    getPermissionsHelpLink () {
      return Configuration.endpoints.static.userPermissionsHelp;
    }

    getPermissionsLink (role) {
      // Bindles only supports Gamma and Prod.
      const stage = Configuration.environment.current().isProd() ? '' : GAMMA_BINDLES_STAGE;
      return Configuration.endpoints.staticOf.permissionsBindle(role, stage);
    }

    getPermissionsTooltip (scope, role) {
      return `${scope} ${AUTH_ROLES[role]}`;
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('userPermissions', {
      bindings: {
        /*
         * @roles List of roles (strings) that the user has permissions for.
         */
        roles: '<',
        /*
         * @tenant String the tenant id string used to fetch scopes by tenant.
         */
        tenant: '<'
      },
      controller: UserPermissions,
      templateUrl: 'templates/components/user-permissions.component.html'
    });
})();
