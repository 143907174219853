/* globals AbstractTransformerService, DataPackage, Enums, Grain, Granularities, Name, PlanMetadata, Validate, XLSX */
(function () {
  'use strict';
  // ====== Transformers: More Than Meets The Eye ======
  //               ____          ____
  //              |oooo|        |oooo|
  //              |oooo| .----. |oooo|
  //              |Oooo|/\_||_/\|oooO|
  //              `----' / __ \ `----'
  //              ,/ |#|/\/__\/\|#| \,
  //             /  \|#|| |/\| ||#|/  \
  //            / \_/|_|| |/\| ||_|\_/ \
  //           |_\/    o\=----=/o    \/_|
  //           <_>      |=\__/=|      <_>
  //           <_>      |------|      <_>
  //           | |   ___|======|___   | |
  //          //\\  / |O|======|O| \  //\\
  //          |  |  | |O+------+O| |  |  |
  //          |\/|  \_+/        \+_/  |\/|
  //          \__/  _|||        |||_  \__/
  //                | ||        || |
  //               [==|]        [|==]
  //               [===]        [===]
  //                >_<          >_<
  //               || ||        || ||
  //               || ||        || ||
  //               || ||        || ||
  //             __|\_/|__    __|\_/|__
  // Wildcat    /___n_n___\  /___n_n___\
  // ===================================================
  const ERROR_TYPE = Object.freeze({
    INVALID_INPUT: 'INVALID_INPUT',
    MISSING_WORKBOOK_PROPERTY: 'MISSING_WORKBOOK_PROPERTY'
  });
  const WORKBOOK_METADATA_DEFINITION = Object.freeze([
    AbstractTransformerService.metadataProperty('dates'),
    AbstractTransformerService.metadataProperty('editType'),
    AbstractTransformerService.metadataProperty('filters'),
    AbstractTransformerService.metadataProperty(
      'granularity',
      (value) => _.mapValues(JSON.parse(value), (property) => _.has(property, 'granularities') ? Granularities.create(property.granularities) : property)
    ),
    AbstractTransformerService.metadataProperty('groupBy'),
    AbstractTransformerService.metadataProperty('metrics'),
    AbstractTransformerService.metadataProperty(
      'plan',
      (value) => PlanMetadata.create(JSON.parse(value)),
      (value) => _.isNil(value) ? undefined : JSON.stringify(value)
    ),
    AbstractTransformerService.metadataProperty('type')
  ]);

  class EditTransformerService extends AbstractTransformerService {
    static get $inject () {
      return ['alerts', '$q'];
    }

    constructor (alerts, $q) {
      super(alerts, $q, WORKBOOK_METADATA_DEFINITION);
    }

    _accumulatePackageRows (sheet, workbookProps) {
      const invalidInputs = [];
      const pkg = _.reduce(
        sheet,
        (rowAccumulator, row) => {
          // SIM: https://issues.amazon.com/issues/SOP-5711 - Excel uses an empty string for cells that have been edited to a blank value
          const editValues = workbookProps.dates.map((date) => Validate.isBlank(row[date]) ? null : row[date]);

          invalidInputs.push(...Validate.checkNonNumericals(editValues));
          rowAccumulator.records.push({
            granularity: _.reduce(workbookProps.granularity.grains.values(), (grainAccumulator, grain) => {
              grainAccumulator[grain.id] = row[grain.name];
              return grainAccumulator;
            }, {}),
            metric: this._findMatchingMetric(workbookProps.metrics, row.Metric, invalidInputs),
            values: editValues
          });
          return rowAccumulator;
        },
        DataPackage.create({
          dates: workbookProps.dates,
          editType: workbookProps.editType,
          filters: workbookProps.filters,
          granularity: workbookProps.granularity,
          groupBy: workbookProps.groupBy,
          metrics: workbookProps.metrics,
          plan: workbookProps.plan,
          records: [],
          title: Enums.XlsxSheet.EDITS,
          totals: [],
          type: workbookProps.type,
          viewGrainFilter: Enums.GrainFilter.IS_EDIT_TEMPLATE
        }, this.$q)
      );
      if (!_.isEmpty(invalidInputs)) {
        return invalidInputs;
      }
      return pkg;
    }

    _addWorkbookGrains (workbook, pkg) {
      // First, create a list of metric values: ['Metric', 'Metric1', 'Metric2', ...]
      const metricsColumn = [Grain.known.metric.name].concat(..._.map(pkg.metrics, 'displayName'));
      // Next, create a list of values for each grain present:
      // [
      //   ['Grain1', 'grain1 value1', 'grain1 value2', ...],
      //   ['Grain2', 'grain2 value1', 'grain2 value2', ...],
      //   ...
      // ]
      //
      const grainColumns = _.map(pkg.granularity.possibleGrainValues, (possibleGrainValues, grain) => [grain].concat(...possibleGrainValues));
      // Finally, merge all lists into a single list of lists and then transpose the result:
      // [
      //   ['Metric', 'Grain1', 'Grain2', ...],
      //   ['Metric1', 'grain1 value1', 'grain2 value1', ...],
      //   ['Metric2', 'grain1 value2', 'grain2 value2', ...],
      //   ...
      // ]
      // The transposition is performed to allow users to use the "Filter" feature in Excel.
      const content = _.zip(...[metricsColumn, ...grainColumns]);
      this.addSheetToWorkbook(workbook, Enums.XlsxSheet.GRAINS, content, this.contentFormats.ARRAY_OF_ARRAYS);
    }

    // As pointed out in this CR: https://code.amazon.com/reviews/CR-13642141, there is a potential case of this wrecking a
    // user upload as they may have multiple metrics that have different grains. However this is impossible currently as users are restricted
    // to making edits for only a single metric. If in the future this changes, the uploaded packages will need to have these extra grain
    // sequential ('-'s) removed after this step.
    _alignGrains (packages) {
      // Find the master set of granularities (the set that includes all grains). This set is guaranteed to be the granularity of one of the packages.
      const mostInclusiveGrainSet = _.maxBy(packages.map((pkg) => pkg.granularity.grains), (grain) => grain.values().length);
      packages.forEach((pkg) =>
        mostInclusiveGrainSet.values().forEach((grain) => {
          // Update all package granularities to the master set and set the grain value of missing grain to '-'.
          pkg.granularity.grains = _.cloneDeep(mostInclusiveGrainSet);
          pkg.records.forEach((record) => record.granularity[grain.id] = _.isNil(record.granularity[grain.id]) ? '-' : record.granularity[grain.id]);
        }));
    }

    _displayErrorMessages (errors, errorType) {
      errors.forEach((error) => {
        switch (errorType) {
          case ERROR_TYPE.INVALID_INPUT:
            this.alerts.danger(`"${error}" is not a valid input. Please correct the file and upload again.`);
            break;
          case ERROR_TYPE.MISSING_WORKBOOK_PROPERTY:
            this.alerts.danger(`Uploaded XLSX file is missing the required "${error}" property. Please make sure you are uploading a valid file.`);
            break;
          default:
            throw new Error(`EditTransformer: errorType is not supported: "${errorType}"`);
        }
      });
    }

    _extractContent (pkg, gridRowType, suppliers) {
      return _.transform(pkg[gridRowType], (content, gridRow) => {
        const row = [];
        suppliers.forEach((supplier) => {
          if (supplier.source === 'pkg') {
            row.push(this._extractValue(supplier, pkg, gridRow));
          }
          if (supplier.source === 'row') {
            row.push(this._extractValue(supplier, gridRow));
          }
        });
        content.push(_.flatten(row));
      }, []);
    }

    _extractValue (supplier, source, row) {
      if (_.isString(supplier)) {
        return supplier;
      }
      let value = source[supplier.key];
      if (_.has(supplier, 'serialize')) {
        value = supplier.serialize(value, source, row);
      }
      return value;
    }

    _findMatchingMetric (metrics, metricName, invalidInputs) {
      const metric = _.find(metrics, (metric) => Name.ofMetric(metric) === metricName);
      if (_.isNil(metric)) {
        invalidInputs.push(metricName);
      }
      return metric;
    }

    _generateBodyRows (pkg, suppliers) {
      return this._extractContent(pkg, 'records', suppliers);
    }

    _generateHeaderRows (pkg, suppliers) {
      return suppliers.map((supplier) => this._extractValue(supplier, pkg)).flat();
    }

    /* @Override
     * Converts dataPackages into a flat-workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToFlatWorkbook (packages, headerSuppliers, bodySuppliers, workbook = XLSX.utils.book_new()) {
      const worksheet = this.convertToWorksheet(packages, headerSuppliers, bodySuppliers);
      XLSX.utils.book_append_sheet(workbook, worksheet, Enums.XlsxSheet.DATA);
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorkbook (packages, headerSuppliers, bodySuppliers) {
      const workbook = XLSX.utils.book_new(),
            editPackages = _.cloneDeep(packages);

      editPackages.forEach((pkg) => {
        // Show only Edit rows in the 'Edits' tab
        pkg.records = _.filter(pkg.records, (record) => record.granularity.draft === Enums.DataPackage.RecordType.EDITS);
        // Hide the draft column for the edits sheet since all edits will be prefinal
        pkg.downloadGrainFilter = Enums.GrainFilter.IS_EDIT_TEMPLATE;
        pkg.granularity.grains.addMetricGrain(0);
      });

      XLSX.utils.book_append_sheet(workbook, this.convertToWorksheet(editPackages, headerSuppliers, bodySuppliers), Enums.XlsxSheet.EDITS);
      this.addWorkbookMetadata(workbook, _.head(editPackages));
      // Currently the "Grains" sheet is only required for the BYOP templates but
      // the standard edit templates could also benefit from this sheet. (https://sim.amazon.com/issues/SOP-11028)
      if (_.head(editPackages).type === Enums.DataPackage.PackageType.CREATE) {
        this._addWorkbookGrains(workbook, _.head(editPackages));
      }
      // Do not show Edit rows in the 'Data' tab
      packages.forEach((pkg) => pkg.records = _.filter(pkg.records, (record) => record.granularity.draft !== Enums.DataPackage.RecordType.EDITS));
      // Add the data sheet to the workbook for user reference
      this.convertToFlatWorkbook(packages, headerSuppliers, bodySuppliers, workbook);
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a worksheet, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorksheet (packages, headerSuppliers, bodySuppliers) {
      const content = [];

      this._alignGrains(packages);
      packages.forEach((pkg, index) => {
        // pkg.granularity references the granularity object defined in the controller.
        // If the metric grain is added it should not affect the granularity in the controller
        pkg.granularity = _.defaults({ grains: Granularities.create(pkg.granularity.grains.values()).addMetricGrain(0) }, pkg.granularity);
        if (index === 0) {
          content.push(this._generateHeaderRows(pkg, headerSuppliers));
        }
        content.push(...this._generateBodyRows(pkg, bodySuppliers));
      });
      return XLSX.utils.aoa_to_sheet(content);
    }

    /* Derives an array of packages from a workbook
     *
     * @param workbook the workbook to be converted into packages
     * @return a list of packages that can be displayed as grids
     */
    toPackages (workbook) {
      const workbookProps = this.extractWorkbookMetadata(workbook);
      if (_.isEmpty(workbookProps)) {
        return [];
      }

      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.EDITS], { raw: true });
      if (_.isEmpty(sheet)) {
        // This alert message, as with many others can be refactored into enums once the front end validations
        // have been implemented for manual backlog.
        this.alerts.danger('The system detected no edit data in the file you uploaded. Please update the file with edit data and upload again.', Enums.AlertImportance.INTERRUPT);
        return [];
      }

      const pkgs = [];
      const invalidInputs = [];
      const accumulation = this._accumulatePackageRows(sheet, workbookProps);
      if (Array.isArray(accumulation)) {
        // Package contained invalid inputs. Queue error message.
        invalidInputs.push(...accumulation);
      }
      pkgs.push(accumulation);
      if (!_.isEmpty(invalidInputs)) {
        this._displayErrorMessages(invalidInputs, ERROR_TYPE.INVALID_INPUT);
        return [];
      }
      return pkgs;
    }
  }

  angular.module('application.services').service('editTransformer', EditTransformerService);
})();
