/* globals Enums, Filename */
(function () {
  'use strict';
  class NetworkPlannerUploadsModalController {
    static get $inject () {
      return ['alerts', 'overlay', 'packagerFactory', 'trader', 'transformerFactory'];
    }

    constructor (alerts, overlay, packagerFactory, trader, transformerFactory) {
      this.alerts = alerts;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.trader = trader;
      this.transformerFactory = transformerFactory;
    }

    _collectWorkbook () {
      return this.packagerFactory.collect(this.packagerFactory.packagerType.create, this.plan.type, Enums.Plan.DraftType.BASELINE, this.plan.date);
    }

    _downloadWorkbook (format, grids, editType) {
      return this.transformerFactory.toDocument(
        this.transformerFactory.transformerType.edit,
        format,
        grids,
        [
          {
            key: 'granularity',
            serialize: (granularity, source) => granularity.grains.names(source.downloadGrainFilter)
          },
          { key: 'dates' }
        ],
        [
          {
            key: 'granularity',
            serialize: (granularity, source, row) => granularity.grains.values(source.downloadGrainFilter).map((grain) => row.granularity[grain.id]),
            source: 'pkg'
          },
          {
            key: 'values',
            source: 'row'
          }
        ],
        {
          edit: true
        }
      ).then((workbook) => this.trader.download(
        workbook,
        Filename.create().forPlan(_.head(grids).plan).add(editType),
        this.trader.toExtensionType(format)
      ));
    }

    download (format) {
      this.overlay.show('Fetching metrics');
      this._collectWorkbook()
        .then((grids) => this._downloadWorkbook(format, _.flatten(grids), Enums.DataPackage.EditType.UNIT))
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to download template file for "${this.displayName}".` }))
        .finally(() => this.overlay.hide());
      this.modalInstance.close();
    }

    onFileSelected (file) {
      this.trader.upload(file)
        .then((workbook) => {
          const dataPackages = this.transformerFactory.toPackages(this.transformerFactory.transformerType.edit, workbook);
          if (_.isEmpty(dataPackages)) {
            this.alerts.danger('Uploaded file was empty.');
            return;
          }
          const head = _.head(dataPackages);
          if (this.plan.scope !== head.plan.scope) {
            this.alerts.danger(`Expected upload for "${this.plan.scope}", but was instead for "${head.plan.scope}".`);
            return;
          }
          if (this.plan.type !== head.plan.type) {
            this.alerts.danger(`Expected upload for "${this.displayName}", but was instead for "${head.plan.displayName()}".`);
            return;
          }
          if (this.plan.date !== head.plan.date) {
            this.alerts.danger(`Expected upload for "${this.plan.date}", but was instead for "${head.plan.date}".`);
            return;
          }
          this.dataPackages = dataPackages;
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'The system is unable to interpret the file you uploaded.' }))
        .finally(() => this.modalInstance.close(this.dataPackages));
    }

    $onInit () {
      this.plan = this.resolve.plan;
      this.displayName = this.resolve.displayName;
    }
  }

  angular.module('application.components')
    .component('networkPlannerUploadsModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with 2 properties:
         *    displayName - String the display name of the plan
         *    plan - PlanMetadata the metadata of the baseline plan to be uploaded
         */
        resolve: '<'
      },
      controller: NetworkPlannerUploadsModalController,
      templateUrl: 'templates/components/network-planner-uploads-modal.component.html'
    });
})();
