/* globals AbstractItemSelectorComponent, Configuration */
(function () {
  'use strict';
  class ScopeItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', '$q'];
    }

    constructor ($element, $q) {
      super($element);
      this.$q = $q;
      this.scopesData = [];
    }

    _loadData () {
      this.scopesData.length = 0;
      const scopes = Configuration.scopes.all();
      _.filter(scopes, (scope) => scope.isComposite()).forEach((filteredScope) => {
        this.scopesData.push({
          id: filteredScope.portalCode,
          items: [filteredScope, filteredScope.subscopes].flat().map((subscope) => ({
            id: subscope.portalCode,
            name: subscope.portalCode
          })),
          name: filteredScope.portalCode
        });
      });
      this.scopesData.push({
        id: 'Others',
        items: _.map(_.filter(scopes, (scope) => !scope.isComposite()), (filteredScope) => ({
          id: filteredScope.portalCode,
          name: filteredScope.portalCode
        })),
        name: 'Others'
      });

      this.itemSelectorInterface = {
        canSelectAllItems: _.isEmpty,
        disableSort: true,
        displayMode: 'compact',
        items: () => this.$q.resolve(this.scopesData)
      };
    }
  }

  angular.module('application.components')
    .component('scopeItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings(),
      controller: ScopeItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
