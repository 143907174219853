/* globals AbstractDataViewController, Configuration, DataPackageList, DateUtils, Enumeration, Enums, Granularities, PlanMetadata, TimeGranularity */
(function () {
  'use strict';
  const WEEK_BEFORE_PLANNING_WEEK = 1;
  const NUMBER_OF_COLUMNS = 12;
  const PLAN_SELECTION_OPTIONS = Enumeration.create('SCENARIO_WEEK', 'PLAN_ID').asExactValue('Planning Week & Scenario', 'Plan ID');
  const SUMMARY_PLAN = 'Summary';
  const CLIENT = 'VisionTool';

  class NetworkPlannerController extends AbstractDataViewController {
    static get $inject () {
      return ['alerts', 'metricsService', 'orchestrator', 'overlay', 'packagerFactory', 'planConfiguration', 'planStore', '$q', '$scope', 'share', '$uibModal', 'view'];
    }

    constructor (alerts, metricsService, orchestrator, overlay, packagerFactory, planConfiguration, planStore, $q, $scope, share, $uibModal, view) {
      super($scope, share, view);
      this.alerts = alerts;
      this.metricsService = metricsService;
      this.orchestrator = orchestrator;
      this.overlay = overlay;
      this.packagerFactory = packagerFactory;
      this.planConfiguration = planConfiguration;
      this.planStore = planStore;
      this.$q = $q;
      this.$uibModal = $uibModal;
      this.$onInit();
    }

    _isAllEditablePlansAvailable () {
      return Object.values(this.$scope.model.editablePlans)
        .flat()
        .every((plan) => !_.isNil(_.get(this.$scope.model.baselinePlans[plan.type], 'version')));
    }

    _loadPlansFromEditTracking (planId) {
      this.$scope.editTrackingPlans = {};
      return this.orchestrator.getEditTracking({ planId })
        .then((editTrackingResponse) => {
          const planMetadataList = _.map(editTrackingResponse.parents, (parent) => PlanMetadata.create(parent));
          this.$scope.model.planningScenario = editTrackingResponse.planningScenario;
          this._setPlans(planMetadataList, this.$scope.editTrackingPlans, true, editTrackingResponse.planningScenario, Enums.Plan.DraftType.PRE_FINAL);
          return planMetadataList;
        });
    }

    _loadPlansFromPlanStore (planningWeek) {
      return this.planStore.plans(planningWeek);
    }

    _setPlans (planMetadataList, plans, overridePlans = true, planningScenario = Enums.Plan.Scenario.PROD, draft = Enums.Plan.DraftType.BASELINE) {
      _(Configuration.networkPlannerPlans).reject('isSummary').forEach((configuredPlan) => {
        const type = configuredPlan.type;
        if (!overridePlans && !_.isNil(plans[type]) && !_.isNil(plans[type].version)) {
          return;
        }
        plans[type] = _.defaultTo(
          planMetadataList.find((plan) => plan.draft === draft && plan.type === type && plan.scenario === Enums.Plan.Scenario.PROD && plan.subScenario === planningScenario),
          PlanMetadata.create({
            date: this.$scope.model.planningWeek,
            draft: draft,
            endDate: DateUtils.fromOffset(this.$scope.model.planningWeek, Configuration.planHorizonInWeeks[Configuration.scopes.current().code] - 1),
            scenario: Enums.Plan.Scenario.PROD,
            schemaName: this.$scope.model.planDefinitions[type].schemaName,
            scope: Configuration.scopes.current().code,
            startDate: this.$scope.model.planningWeek,
            subScenario: planningScenario,
            type: type
          })
        );
      });
    }

    _loadBaselinePlans (overridePlanningWeek, overridePlans = true) {
      const planningWeek = overridePlanningWeek || this.$scope.model.planningWeek;
      this.$scope.settings.baselinePlansLoaded = false;
      if (overridePlans) {
        this.$scope.model.baselinePlans = {};
      }
      this.overlay.show('Fetching metrics');
      const promise = this.$scope.methods.isPlanSelectionMethod('PLAN_ID') ?
        this._loadPlansFromEditTracking(this.$scope.model.planId) : this._loadPlansFromPlanStore(planningWeek);
      return promise
        .then((planMetadataList) => {
          this._setPlans(planMetadataList, this.$scope.model.baselinePlans, overridePlans);
          this.$scope.settings.baselinePlansLoaded = true;
        })
        .catch((error) => {
          this.alerts.danger({ details: error, message: 'Unable to fetch baseline plans.' });
          throw error;
        })
        .finally(() => this.overlay.hide());
    }

    _loadComparisonPlans () {
      this.$scope.comparisonPlans = {};
      this.overlay.show('Fetching comparison');
      const promise = this.$scope.methods.isComparisonPlanSelectionMethod('PLAN_ID') ?
        this._loadPlansFromEditTracking(this._getComparison().planId) : this._loadPlansFromPlanStore(this._getComparison().planningWeek);
      return promise
        .then((planMetadataList) => this._setPlans(planMetadataList, this.$scope.comparisonPlans, true, this._getComparison().planningScenario, Enums.Plan.DraftType.PRE_FINAL))
        .catch((error) => {
          this.alerts.danger({ details: error, message: 'Unable to fetch comparison plans.' });
          throw error;
        })
        .finally(() => this.overlay.hide());
    }

    _getSelectedPlan () {
      const planType = this.$scope.model.editablePlanOptionTypes[this.$scope.model.selectedPlan];
      return this.$scope.model.configuredPlansByType[planType];
    }

    _getComparison () {
      return this.$scope.model.comparison[this._getSelectedPlan().type];
    }

    _extractEdits (dataPackages, plans) {
      return this.packagerFactory.collect(
        this.packagerFactory.packagerType.networkPlannerEdits,
        _.cloneDeep(dataPackages),
        _.cloneDeep(this.$scope.model.dataPackages),
        plans
      );
    }

    _getEdits () {
      this.overlay.show('Fetching prefinal metrics');
      this.preFinalPlans = {};
      return this.planStore.plans(this.$scope.model.planningWeek)
        // Fetch latest metadata for preFinal draft plans
        .then((planMetadataList) => this._setPlans(planMetadataList, this.preFinalPlans, true, this.$scope.model.planningScenario, Enums.Plan.DraftType.PRE_FINAL))
        // Flatten all dataPackages and wait for the data to be ready
        .then(() => this.$q.all(_(this.$scope.planData).flatMapDeep().map((dataPackage) => dataPackage.ready()).value()))
        .then((dataPackages) => {
          const editedPackages = this._extractEdits(dataPackages, this.preFinalPlans);
          editedPackages.forEach((dataPackage) => dataPackage.filters = {});
          const groupedEdits = _.groupBy(editedPackages, 'plan.type');
          return _.transform(this.$scope.model.editablePlans, (edits, plans, flow) => {
            plans.forEach((plan) => {
              edits[flow] = _.isEmpty(edits[flow]) ? {} : edits[flow];
              edits[flow][plan.type] = {
                displayName: this.$scope.methods.getPlanDisplayName(this.preFinalPlans[plan.type]),
                isEdited: _.some(this.$scope.planData[plan.type], 'isEdited'),
                plan: groupedEdits[plan.type]
              };
            }, {});
          });
        })
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch preFinal metrics.' }))
        .finally(() => this.overlay.hide());
    }

    _initializeComparison () {
      return _(Configuration.networkPlannerPlans).reject('isSummary').transform((plans, plan) => {
        plans[plan.type] = {
          planningScenario: '',
          planningWeek: DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
          planSelectionMethod: PLAN_SELECTION_OPTIONS.SCENARIO_WEEK
        };
      }, {}).value();
    }

    _setupPlanData (flow, plans) {
      this.overlay.show('Fetching metrics');
      return this.$q.all(
        _(this.$scope.model.dataPackages[flow])
          .flatMapDeep(Object.values)
          .flatMapDeep(Object.values)
          .map((data) => data.ready()).value())
        .then(() => plans.forEach((plan) => {
          this.$scope.planData[plan.type] = this.$scope.model.grainValues[plan.splitBy].map((filter) => {
            const granularity = _.cloneDeep(this.$scope.model.dataPackages[flow][plan.type][filter.id].Baseline.granularity);
            granularity.grains.clear();
            plan.groupBy.forEach((grain) => granularity.grains[_.camelCase(`add-${grain}Grain`)]());
            const configuration = {
              dates: this.$scope.model.dateRange,
              filter: filter,
              granularity: granularity,
              plan: this.$scope.model.baselinePlans[plan.type],
              splitBy: plan.splitBy
            };
            const data = this.$scope.model.dataPackages[flow][plan.type][filter.id];
            data.Edits = _.isNil(data.Edits) ? _.cloneDeep(data.Baseline) : data.Edits;
            return this.packagerFactory.collect(
              this.packagerFactory.packagerType.networkPlanner,
              data.Baseline.records,
              data.Edits.records,
              _.get(data.Comparison, 'records'),
              configuration
            );
          });
        }))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to setup data for planning.' }))
        .finally(() => this.overlay.hide());
    }

    _loadData (flow, plans, planMetadata, draft) {
      plans.forEach((plan) => {
        const configuration = _.cloneDeep(this.$scope.model);
        const family = this.$scope.model.metricFamilies[plan.type];
        const filters = this.$scope.model.grainValues[plan.splitBy];

        configuration.granularity.grains = this.$scope.model.planDefinitions[plan.type].planGranularity.clone().removeGrain(plan.splitBy);
        configuration.granularity.plan = this.$scope.model.planDefinitions[plan.type].planGranularityName;
        configuration.forecast = { plan: planMetadata[plan.type] };

        const data = _.transform(filters, (data, filter) => {
          configuration.filters = { [plan.splitBy]: [filter] };
          data[filter.id] = this.packagerFactory.collect(
            this.packagerFactory.packagerType.networkPlannerViewer,
            this.$scope.model.dateRange,
            family,
            configuration,
            this.$scope.settings,
            filter.displayName
          );
        }, {});
        _.forEach(data, (dataPackage, filter) => {
          _.set(this.$scope.model.dataPackages[flow], `${plan.type}.${filter}.${draft}`, dataPackage);
        });
      });
      return this._setupPlanData(flow, plans);
    }

    _loadGrainValues () {
      const grainPromises = _(Object.values(this.$scope.model.editablePlans))
        .flatMap()
        .map('splitBy')
        .uniq()
        .transform((promises, grain) => promises[grain] = this.planConfiguration.grainDefinition(grain), {});
      return this.$q.all(grainPromises)
        .then((grainDefinitions) => _.forEach(grainDefinitions, (grainDefinition, grain) => this.$scope.model.grainValues[grain] = grainDefinition.values))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch grain values.' }));
    }

    _loadMetricFamilies () {
      const planTypes = _.map(_.filter(Configuration.networkPlannerPlans, 'isEditable'), 'type');
      const metricFamilyPromises = _.transform(planTypes, (promises, planType) => {
        promises[planType] = this.metricsService.metricFamiliesByGroups(planType, this.$scope.model.planningWeek);
      }, {});
      return this.$q.all(metricFamilyPromises)
        .then((metricFamilyGroups) => _.forEach(planTypes, (planType) => {
          this.$scope.model.metricFamilies[planType] = _(metricFamilyGroups[planType]).map('items').flatten().head();
        }))
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch metric families.' }));
    }

    _loadPlanDefinitions () {
      this.$scope.model.planDefinitions = {};
      this.overlay.show('Fetching metrics');
      return this.planConfiguration.planDefinition().list()
        .then((planDefinitions) => {
          _(Configuration.networkPlannerPlans).reject('isSummary').forEach((plan) => {
            this.$scope.model.planDefinitions[plan.type] = planDefinitions.find((definition) => definition.planType === plan.type);
          });
          this._setEditablePlanOptions();
        })
        .then(() => this._loadGrainValues())
        .then(() => this._loadMetricFamilies())
        .then(() => this._loadBaselinePlans())
        .catch((error) => this.alerts.danger({ details: error, message: 'Unable to fetch plan definitions.' }))
        .finally(() => this.overlay.hide());
    }

    _save (dataPackages, plan) {
      if (!DataPackageList.containsEdits(dataPackages)) {
        this.alerts.danger('Provided plan is empty, please make edits and re-upload.');
        this.overlay.hide();
        return;
      }
      if (!DataPackageList.containsValidEdits(dataPackages)) {
        this.alerts.danger('The file contains invalid edits, please correct and then re-upload.');
        this.overlay.hide();
        return;
      }

      this._upsertPlan(dataPackages, _.isNil(plan.version))
        .then((execution) => this.orchestrator.pollModification(plan, _.head(execution).uuid))
        .then(() => this._updateBaselinePlan(plan))
        .then(() => this.alerts.success(`Successfully saved ${plan.draft} for "${this.$scope.methods.getPlanDisplayName(plan)}".`))
        .catch((error) => this.alerts.danger({
          details: error,
          message: `Unable to save ${plan.draft} for "${this.$scope.methods.getPlanDisplayName(plan)}".`
        }))
        .finally(() => this.overlay.hide());
    }

    _setDateRange () {
      const startDate = DateUtils.fromOffset(this.$scope.model.planningWeek, -this.$scope.model.actuals.dateRangeLength, this.$scope.model.timeUnit);
      const endDate = DateUtils.fromOffset(this.$scope.model.planningWeek, this.$scope.model.planningHorizon - 1, this.$scope.model.timeUnit);
      this.$scope.model.dateRange = DateUtils.expansion(startDate, endDate, this.$scope.model.granularity.time);
    }

    _setEditablePlanOptions () {
      this.$scope.model.editablePlanOptions = _.transform(this.$scope.model.flows, (editablePlans, flow) => {
        editablePlans[flow.id] = _.map(this.$scope.model.editablePlans[flow.id], (plan) => {
          const displayName = _.get(this.$scope.model.planDefinitions[plan.type], 'displayName', _.startCase(plan.type));
          this.$scope.model.editablePlanOptionTypes[displayName] = plan.type;
          return displayName;
        });
        this.$scope.model.editablePlanOptionTypes[SUMMARY_PLAN] = SUMMARY_PLAN;
        editablePlans[flow.id].push(SUMMARY_PLAN);
      }, {});
    }

    _updateBaselinePlan (plan) {
      return this.planStore.planMetadata(plan)
        .then((planMetadata) => this.$scope.model.baselinePlans[plan.type] = planMetadata)
        .catch((error) => this.alerts.danger({
          details: error,
          message: `Failed to get updated baseline plan metadata for "${this.$scope.methods.getPlanDisplayName(plan)}".`
        }));
    }

    _upsertPlan (dataPackages, isCreate) {
      return isCreate ? this.orchestrator.create(dataPackages) : this.orchestrator.edit(dataPackages);
    }

    _constructSummary () {
      this.$scope.summary.clear();
      const actualsStartDate = DateUtils.fromOffset(this.$scope.model.planningWeek, -Configuration.networkPlannerDateRange.actuals);
      const actualsEndDate = DateUtils.fromOffset(this.$scope.model.planningWeek, -WEEK_BEFORE_PLANNING_WEEK);
      const actualsDefinition = `From <strong>${actualsStartDate}</strong> to <strong>${actualsEndDate}</strong>`;
      this.$scope.summary.addLeft('Actuals', actualsDefinition, 'dataset', 'actual');
      const forecastStartDate = this.$scope.model.planningWeek;
      const forecastEndDate = DateUtils.fromOffset(
        this.$scope.model.planningWeek,
        Configuration.networkPlannerDateRange.forecasts - WEEK_BEFORE_PLANNING_WEEK
      );
      const forecastDefinition = `From <strong>${forecastStartDate}</strong> to <strong>${forecastEndDate}</strong>`;
      this.$scope.summary.addRight('Forecast', forecastDefinition, 'dataset', 'primary');
      this.$scope.summary.constructList();
    }

    _validScenario (scenario) {
      const validScenario = _.replace(scenario, /\s/g, '');
      return validScenario !== Enums.Plan.Scenario.PROD ? validScenario : '';
    }

    $onInit () {
      super.$onInit(['MAIN_SELECTION'], {
        'model.actuals.dateRangeLength': () => Configuration.networkPlannerDateRange.actuals,
        'model.aggregateGroupByGrains': () => Granularities.create().addMetricGrain(),
        'model.baselinePlans': () => ({}),
        'model.comparison': () => this._initializeComparison(),
        'model.configuredPlans': () => _(Configuration.networkPlannerPlans).groupBy('flow').cloneDeep(),
        'model.configuredPlansByType': () => _(Configuration.networkPlannerPlans).reject('isSummary').transform((plans, plan) => plans[plan.type] = plan, {}).cloneDeep(),
        'model.dataPackages': () => ({}),
        'model.displayOnlyEdits': () => Configuration.networkPlannerSettings.displayOnlyEdits,
        'model.editablePlanOptions': () => ({}),
        'model.editablePlanOptionTypes': () => ({}),
        'model.editablePlans': () => _(Configuration.networkPlannerPlans).filter('isEditable').groupBy('flow').cloneDeep(),
        'model.flows': () => _.cloneDeep(Configuration.networkPlannerFlows),
        'model.grainValues': () => ({}),
        'model.granularity.time': () => Configuration.networkPlannerDateRange.timeGranularity,
        'model.metricFamilies': () => ({}),
        'model.planDefinitions': () => ({}),
        'model.planningHorizon': () => Configuration.networkPlannerDateRange.forecasts,
        'model.planningScenario': () => '',
        'model.planningWeek': () => DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
        'model.planningWeekMinDate': () => DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
        'model.planSelectionMethod': () => PLAN_SELECTION_OPTIONS.SCENARIO_WEEK,
        'model.planSelectionOptions': () => PLAN_SELECTION_OPTIONS,
        'model.timeUnit': () => TimeGranularity.toTimeUnit(Configuration.networkPlannerDateRange.timeGranularity),
        'planData': () => ({}),
        'settings.actuals': () => true,
        'settings.baselinePlansLoaded': () => false,
        'settings.dataLoaded': () => false,
        'settings.forecasts': () => true,
        'settings.includeYoYActuals.PPY': () => false,
        'settings.includeYoYActuals.PY': () => false
      });
      this._setDateRange();

      this.$scope.methods.getBaselineColSize = (plans) => Math.floor(NUMBER_OF_COLUMNS / plans.filter((plan) => !plan.isSummary).length);

      this.$scope.methods.getComparison = () => this._getComparison();

      this.$scope.methods.getPlanAvailability = (plan) => !_.isNil(plan.version) ? 'Available' : 'Not Available';

      this.$scope.methods.getPlanDisplayName = (plan) => _.get(this.$scope.model.planDefinitions[plan.type], 'displayName', plan.displayName());

      this.$scope.methods.getStartPlanningConfirmationMessage = () => {
        if (this.$scope.methods.isPlanSelectionMethod('PLAN_ID')) {
          return `Are you sure you want to start planning from plan id: "${this.$scope.model.planId}"?`;
        }
        if (!this._isAllEditablePlansAvailable() && this.$scope.model.planningWeek !== this.$scope.model.planningWeekMinDate) {
          return `Not all plans are available for "${this.$scope.model.planningWeek}", will fall back to "${this.$scope.model.planningWeekMinDate}". Are you sure you want to start planning?`;
        }
        return `Are you sure you want to start planning for "${this.$scope.model.planningWeek}"?`;
      };

      this.$scope.methods.getCloneConfirmationMessage = () => 'Are you sure you want to freeze finalDraft of last weeks fillrate plan to current weeks baselineDraft?';

      this.$scope.methods.isBaselinePlanUploadable = (plan) => plan.isBaselineUploadable;

      this.$scope.methods.isPlanSelectionMethod = (planSelectionMethod) => this.$scope.model.planSelectionMethod === PLAN_SELECTION_OPTIONS[planSelectionMethod];

      this.$scope.methods.isComparisonPlanSelectionMethod = (planSelectionMethod) => this._getComparison().planSelectionMethod === PLAN_SELECTION_OPTIONS[planSelectionMethod];

      this.$scope.methods.isPlanSelectionValid = () => {
        if (this.$scope.methods.isPlanSelectionMethod('PLAN_ID')) {
          return !_.isEmpty(this.$scope.model.planId);
        }
        if (this.$scope.methods.isPlanSelectionMethod('SCENARIO_WEEK')) {
          return !_.isEmpty(this.$scope.model.planningScenario) && !_.isEmpty(this.$scope.model.planningWeek);
        }
        return false;
      };

      this.$scope.methods.isSelectedPlanComparable = () => this.$scope.model.selectedPlan !== SUMMARY_PLAN && this._getSelectedPlan().isComparable;

      this.$scope.methods.loadData = (flows, plans, planMetadata, draft, setupFlow = true) => this.$q.all(flows.map((flow) => this._loadData(flow, plans[flow], planMetadata, draft)))
        .then(() => {
          if (!setupFlow) {
            return;
          }
          this.$scope.settings.dataLoaded = true;
          this.$scope.activeTab = _.head(this.$scope.model.flows).id;
          this.$scope.methods.setupFlow(this.$scope.activeTab);
        });

      this.$scope.methods.onComparisonPlanningWeekChange = (selectedDate) => {
        if (this._getComparison().planningWeek === selectedDate) {
          return;
        }
        this._getComparison().planningWeek = selectedDate;
      };

      this.$scope.methods.onPlanningWeekChange = (selectedDate) => {
        if (this.$scope.model.planningWeek === selectedDate) {
          return;
        }
        this.$scope.model.planningWeek = selectedDate;
        this._setDateRange();
        this._loadBaselinePlans();
      };

      this.$scope.methods.openExecutionsModal = () => {
        this.$uibModal.open({
          component: 'networkPlannerExecutionsModal',
          resolve: {
            planDefinitions: () => this.$scope.model.planDefinitions,
            planningWeek: () => this.$scope.model.planningWeek
          },
          size: Enums.ModalSize.EXTRA_LARGE
        }).result.then(_.noop, _.noop);
      };

      this.$scope.methods.openSubmitModal = () => {
        this._getEdits()
          .then((edits) => this.$uibModal.open({
            component: 'networkPlannerSubmitModal',
            resolve: {
              baselineDrafts: () => Object.values(this.$scope.model.baselinePlans),
              isDisplayOnlyEdited: this.$scope.model.displayOnlyEdits,
              planningWeek: () => this.$scope.model.planningWeek,
              plans: edits,
              scenario: () => this.$scope.model.planningScenario
            },
            size: Enums.ModalSize.EXTRA_LARGE
          }).result)
          .then((modalResult) => modalResult.submitPromises)
          .then(() => this.$scope.activeTab = 'main')
          .catch(_.noop);
      };

      this.$scope.methods.openUploadsModal = (plan) => {
        this.$uibModal.open({
          component: 'networkPlannerUploadsModal',
          resolve: {
            displayName: () => this.$scope.methods.getPlanDisplayName(plan),
            plan: plan
          },
          size: Enums.ModalSize.EXTRA_LARGE
        }).result.then((dataPackages) => {
          if (_.isEmpty(dataPackages)) {
            return;
          }
          this.overlay.show('Saving Baseline Drafts');
          this._save(dataPackages, plan);
        }, _.noop);
      };

      this.$scope.methods.setupFlow = (flow) => {
        this.$scope.model.selectedPlan = _.head(this.$scope.model.editablePlanOptions[flow]);
        this.$scope.methods.setupSelectedPlan(flow);
      };

      this.$scope.methods.setupSelectedPlan = (flow) => {
        const planType = this.$scope.model.editablePlanOptionTypes[this.$scope.model.selectedPlan];
        const plan = this.$scope.model.configuredPlans[flow].find((plan) => plan.type === planType);

        if (plan.isSummary) {
          this.$scope.data = plan.summaryPlans.map((summaryPlan) => this.packagerFactory.collect(
            this.packagerFactory.packagerType.networkPlannerSummary,
            this.$scope.planData[summaryPlan],
            { displayName: this.$scope.model.planDefinitions[summaryPlan].displayName, granularity: plan.summaryGranularity }
          ));
          return;
        }

        if (!plan.isDerived) {
          this.$scope.data = this.$scope.planData[planType];
          return;
        }

        const dependencies = _.flatMap(plan.dependencies, (dependency) => this.$scope.planData[dependency]);
        // Do not calculate plan if no edits have been made to any of the dependencies
        if (!_.some(dependencies, 'isEdited')) {
          this.$scope.data = this.$scope.planData[planType];
          return;
        }
        this.overlay.show('Calculating plan');
        const baseline = _.head(Object.values(this.$scope.model.dataPackages[flow][planType])).Baseline;
        const family = this.$scope.model.metricFamilies[plan.type];
        const derivedPlansPromise = this.packagerFactory.collect(
          this.packagerFactory.packagerType.networkPlanGenerator,
          this.$scope.model.baselinePlans[planType],
          _.groupBy(this._extractEdits(dependencies, this.$scope.model.baselinePlans), 'plan.type'),
          baseline.granularity,
          plan.splitBy,
          family.metrics.find((metric) => metric.type === _.capitalize(Enums.DataType.ACTUAL)),
          _.cloneDeep(this.$scope.planData[plan.type])
        );
        derivedPlansPromise
          .then((dataPackages) => {
            const groupedDataPackages = _.groupBy(dataPackages, `filters.${plan.splitBy}`);
            _.forEach(groupedDataPackages, (dataPackage, filter) => {
              this.$scope.model.dataPackages[flow][planType][filter].Edits = _.head(dataPackage);
            });
          })
          .then(() => this._setupPlanData(flow, [plan]))
          .then(() => this.$scope.data = this.$scope.planData[planType])
          .catch((error) => {
            this.alerts.danger({ details: error, message: 'Unable to calculate plan.' });
            this.$scope.model.selectedPlan = _.head(this.$scope.model.editablePlanOptions[flow]);
            this.$scope.data = this.$scope.planData[this.$scope.model.editablePlanOptionTypes[this.$scope.model.selectedPlan]];
          })
          .finally(() => this.overlay.hide());
      };

      this.$scope.methods.startPlanning = () => {
        if (this.$scope.methods.isPlanSelectionMethod('SCENARIO_WEEK')) {
          this.$scope.model.planId = '';
        }
        const validScenario = this._validScenario(this.$scope.model.planningScenario);
        if (this.$scope.methods.isPlanSelectionMethod('SCENARIO_WEEK') && _.isEmpty(validScenario)) {
          this.alerts.danger(`Invalid Scenario: '${this.$scope.model.planningScenario}'; Scenario can not be 'prod' or null`);
          this.$scope.model.planningScenario = '';
          return;
        }
        if (this.$scope.methods.isPlanSelectionMethod('SCENARIO_WEEK') && validScenario !== this.$scope.model.planningScenario) {
          this.alerts.warning(`Scenario: '${this.$scope.model.planningScenario}' contains whitespace, changing to '${validScenario}'`);
          this.$scope.model.planningScenario = validScenario;
        }
        this._constructSummary();
        const flows = Configuration.networkPlannerFlows.map((flow) => flow.id);
        flows.forEach((flow) => this.$scope.model.dataPackages[flow] = {});
        if (this.$scope.methods.isPlanSelectionMethod('SCENARIO_WEEK') && this._isAllEditablePlansAvailable()) {
          this.$scope.methods.loadData(flows, this.$scope.model.editablePlans, this.$scope.model.baselinePlans, Configuration.networkPlannerDrafts.BASELINE);
          return;
        }
        this._loadBaselinePlans(this.$scope.model.planningWeekMinDate, false)
          .then(() => this.$scope.methods.loadData(flows, this.$scope.model.editablePlans, this.$scope.model.baselinePlans, Configuration.networkPlannerDrafts.BASELINE))
          .then(() => {
            if (this.$scope.methods.isPlanSelectionMethod('PLAN_ID')) {
              return this.$scope.methods.loadData(flows, this.$scope.model.editablePlans, this.$scope.editTrackingPlans, Configuration.networkPlannerDrafts.EDITS);
            }
          })
          .catch((error) => this.alerts.danger({ details: error, message: 'Unable to load data for planning.' }));
      };

      this.$scope.methods.freezeFillRate = () => {
        this.overlay.show('freezing fillRate');
        const body = {
          client: CLIENT,
          destDraft: Enums.Plan.DraftType.BASELINE,
          destPlanDate: DateUtils.toSunday().format(Enums.DateFormat.IsoDate),
          destPlanType: Enums.Plan.PlanType. FILL_RATE_ORIGIN_LAG_NIG_WEEKLY_PLAN,
          properties: {},
          scope: Configuration.scopes.current().code,
          srcDraft: Enums.Plan.DraftType.FINAL,
          srcPlanDate: DateUtils.fromOffset(DateUtils.toSunday(), -1, Enums.TimeUnit.WEEK),
          srcPlanType: Enums.Plan.PlanType. FILL_RATE_ORIGIN_LAG_NIG_WEEKLY_PLAN
        };
        return this.orchestrator.clonePlan(body)
          .then((response) => this.alerts.success({
            details: JSON.stringify(response),
            message: 'fillRate plan freezed successfully'
          }))
          .catch((error) => this.alerts.danger({ details: error, message: 'unable to freeze fillRate plan' }))
          .finally(() => this.overlay.hide());
      };

      this.$scope.methods.updateComparison = (flow) => {
        const validComparisonScenario = this._validScenario(this._getComparison().planningScenario);
        if (_.isEmpty(validComparisonScenario)) {
          this.alerts.warning(`Comparison Scenario: '${this._getComparison().planningScenario}' is invalid; Scenarios must not be 'prod' or null`);
          return;
        }
        const granularity = this._getSelectedPlan().splitBy;
        const planData = this.$scope.planData[this._getSelectedPlan().type];
        const edits = _(this.packagerFactory.collect(this.packagerFactory.packagerType.networkPlannerSummary, planData, { granularity }))
          .flatMap('records')
          .groupBy(`granularity.${granularity}`)
          .value();
        const data = this.$scope.model.dataPackages[flow][this._getSelectedPlan().type];
        this.$scope.model.grainValues[granularity].forEach((grainValue) => data[grainValue.id].Edits.records = edits[grainValue.id]);
        this._loadComparisonPlans()
          .then(() => {
            if (_.isNil(this.$scope.comparisonPlans[this._getSelectedPlan().type].version)) {
              this.alerts.danger('Unable to load comparison plans.');
              return;
            }
            return this.$scope.methods.loadData([flow], { [flow]: [this._getSelectedPlan()] }, this.$scope.comparisonPlans, Configuration.networkPlannerDrafts.COMPARISON, false);
          })
          .then(() => this.$scope.data = this.$scope.planData[this._getSelectedPlan().type])
          .catch((error) => this.alerts.danger({ details: error, message: 'Unable to load comparison plans.' }));
      };

      this._loadPlanDefinitions();
    }
  }

  angular.module('application.controllers').controller('NetworkPlannerController', NetworkPlannerController);
})();
