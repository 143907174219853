/* globals AbstractElementComponent */
(function () {
  'use strict';

  class AbstractMetricsDashboardChartsComponent extends AbstractElementComponent {
    static get templateUrl () {
      return 'templates/components/metrics-dashboard-charts.component.html';
    }

    static bindings (additionalBindings) {
      return _.defaults(additionalBindings, {
        /*
         * @isActive Boolean a flag indicating whether this component is active or not. Metrics data is loaded only when component is active.
         */
        isActive: '<',
        /*
         * @metricFamilyGroup String the metric family group. Can be either "Inbound" or "Outbound". Defaults to "Inbound".
         */
        metricFamilyGroup: '<',
        /*
         * @onChartsLoadingComplete is a callback to the parent informing that charts data loading is complete.
         */
        onChartsLoadingComplete: '&',
        /*
         * @scope String scope portalCode value. Defaults to current scope.
         */
        scope: '@'
      });
    }
  }

  window.AbstractMetricsDashboardChartsComponent = Object.freeze(AbstractMetricsDashboardChartsComponent);
})();
