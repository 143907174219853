/* globals Enums, Identity */
(function () {
  'use strict';
  /* Computes equality for two hashes
   *
   * @param first the first hash to compare.
   * @param second the second hash to compare.
   * @param options hash of configuration properties.
   *   include: Array of keys that should be used to determine equality.
   *   exclude: Array of keys that should not be used to determine equality.
   * @return a Boolean indicating equality
   */
  const objectEquals = (first, second, options) => {
    if (_.isNil(first) && _.isNil(second)) {
      return true;
    }
    if (_.isNil(first) || _.isNil(second)) {
      return false;
    }

    options = options || {};
    if (options.include) {
      first = _.pick(first, options.include);
      second = _.pick(second, options.include);
    } else if (options.exclude) {
      first = _.sop.omit(first, options.exclude);
      second = _.sop.omit(second, options.exclude);
    }
    return _.isEqual(first, second);
  };

  class Comparison {
    /* Computes equality for two granularity instances, independent of planType
     *
     * @param firstGranularity the first granularity to compare.
     * @param secondGranularity the second granularity to compare.
     *
     * @return a Boolean indicating equality
     */
    static areGranularitiesEqual (firstGranularity, secondGranularity, options) {
      return objectEquals(firstGranularity, secondGranularity, options);
    }

    /* Computes equality for two identities. An identity is the 'id' (prefered) or 'name' property of an object.
     * Any argument that does not have an 'id' or 'name' property will be used as is for comparison. This allows
     * comparing an object with an 'id' or 'name' property to a String or Number to determine if the identity
     * is equal to the String or Number.
     *
     *
     * @param id1 the first identity to compare.
     * @param id2 the second identity to compare.
     *
     * @return a Boolean indicating equality
     */
    static areIdentityEqual (id1, id2) {
      return objectEquals(Identity.of(id1), Identity.of(id2));
    }

    /* Computes equality for two metrics based off their id and type
     *
     * @param firstMetric the first metric to compare.
     * @param secondMetric the second metric to compare.
     *
     * @return a Boolean indicating equality
     */
    static areMetricsEqual (firstMetric, secondMetric) {
      return objectEquals(firstMetric, secondMetric, { include: ['id', 'type'] });
    }

    /* Computes equality for two plans based off their predefined properties (draft, scenario, subScenario, and type)
     *
     * @param firstPlan the first plan to compare.
     * @param secondPlan the second plan to compare.
     * @param options hash of configuration properties.
     *
     * @return a Boolean indicating equality
     */
    static arePlansEqual (firstPlan, secondPlan, options = { include: ['draft', 'scenario', 'subScenario', 'type'] }) {
      return objectEquals(firstPlan, secondPlan, options);
    }

    /* Computes equality for two ratios based off their scenario, subScenario, and type.
     * If two ratioMetadata objects are supplied the ratioIdentifier will be extracted and used in the comparison
     *
     * @param firstRatio the first ratioMetadata or ratioIdentifier object
     * @param secondRatio the second ratioMetadata or ratioIdentifier object
     *
     * @return a Boolean indicating equality
     */
    static areRatiosEqual (firstRatio, secondRatio) {
      firstRatio = _.has(firstRatio, 'ratioIdentifier') ? firstRatio.ratioIdentifier : firstRatio;
      secondRatio = _.has(secondRatio, 'ratioIdentifier') ? secondRatio.ratioIdentifier : secondRatio;
      return objectEquals(firstRatio, secondRatio, { include: ['scenario', 'subScenario', 'type'] });
    }

    /* Sorts @data (array of objects) based on the granularity and metric sort order.
     *
     * @param data an array of objects:
     *   dataType: The type of data of the object (actual | comparison | primary)
     *   granularity: The list of grains for the object
     *   metric: The metric for the object
     * @param dataTypes an array of objects that can be matched by reference equality
     * @param grains an array of ordered objects each representing grain information:
     *   id: The grain id
     * @params metrics an array of ordered object each representing metric information:
     *   id: The metric id
     *   type: The metric type
     *
     * Assumptions:
     *   data is not nil
     *   dataTypes is not nil
     *   grains is not nil
     *   metrics is not nil
     */
    static sort (data, dataTypes, grains, metrics) {
      const comparators = _.map(grains, (grain) => {
        if (grain.id === Enums.GrainIdentity.metric) {
          // If the grain is 'metric', return the metric index.
          return (datum) => _.findIndex(metrics, { id: datum.metric.id, type: datum.metric.type });
        }
        // If not, return the grain id for standard sorting by the grain value.
        return (datum) => {
          const grainValue = datum.granularity[grain.id];
          // If the grain value is a numeric string, use the numeric value for sorting. If not, use the string value.
          return _.isFinite(_.toNumber(grainValue)) ? _.toNumber(grainValue) : grainValue;
        };
      });

      // Add the data type comparator as the last comparison function
      comparators.push((datum) => dataTypes.indexOf(datum.dataType));

      return _.sortBy(data, comparators);
    }
  }

  window.Comparison = Object.freeze(Comparison);
})();
