/* globals DateUtils, Enumeration, FileReader */
(function () {
  'use strict';
  const CONSTRAINT_TYPES = Object.freeze({
    BIN_GROUP_MAPPINGS: 'Bin Group Mappings',
    CAPACITY_SCALING_FACTOR: 'Capacity Scaling Factor',
    CROSSDOCK_INBOUND_TARGET: 'Crossdock Inbound Target',
    IDEAL_FACTOR: 'Ideal Factor',
    INITIAL_PLAN_ADJUST_TOLERANCE: 'Initial Plan Adjust Tolerance',
    NEW_FC_RAMP_UP_CURVE: 'New FC Ramp-Up Curve',
    VOLUME_LOWER_BOUND: 'Volume Lower Bound',
    VOLUME_UPPER_BOUND: 'Volume Upper Bound'
  });

  const SHEET_STATES = Enumeration.create('PREREVIEW', 'REVIEWED', 'UPLOADED', 'AUDITED', 'PROMOTED').asIndexValue();

  class ConstraintSheet {
    constructor () {
      this.file = null;
      this.constraintType = null;
      this.uploadDate = null;
      this.author = null;
      this.state = null;
    }

    isFilledOut () {
      return !_.isNil(this.file) &&
             !_.isNil(this.constraintType) &&
             !_.isNil(this.uploadDate) &&
             !_.isNil(this.author) &&
             !_.isNil(this.state);
    }

    readFile () {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsText(this.file);
      });
    }

    toMetadata () {
      return {
        author: this.author,
        constraintType: this.constraintType,
        fileName: this.file.name,
        uploadDate: DateUtils.of(this.uploadDate).toISOString()
      };
    }

    static get constraintTypes () {
      return CONSTRAINT_TYPES;
    }

    static get sheetStates () {
      return SHEET_STATES;
    }
  }

  window.ConstraintSheet = Object.freeze(ConstraintSheet);
})();
