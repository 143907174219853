/* globals AbstractChartComponent */
(function () {
  'use strict';
  // TODO: Move to MultiBarChart model is available/ready for use.
  const DEFAULT_OPTIONS = Object.freeze({
    chart: {
      margin: {
        left: 100
      },
      showControls: false,
      stacked: false,
      type: AbstractChartComponent.chartType.MULTI_BAR_CHART,
      xAxis: {
        axisLabel: 'X Axis'
      },
      yAxis: {
        axisLabel: 'Y Axis'
      }
    }
  });

  class MultiBarChartController extends AbstractChartComponent {
    constructor () {
      super();
    }

    _loadData () {
      this.validateConfiguration(this.configuration, AbstractChartComponent.chartType.MULTI_BAR_CHART);
      this.options = _.defaultsDeep({}, this.configuration, DEFAULT_OPTIONS, this.getDefaultChartOptions());
      this.setTitleSubtitleCaption(this.options, this.configuration);
    }
  }

  angular.module('application.components')
    .component('multiBarChart', {
      /*
       * @data an array of objects containing:
       *   color (optional) the color code string to use for this key.
       *   key the unique key string representing the dataset. Also used in the chart legend.
       *   values an array of objects containing X and Y coordinate values used to build the graph.
       *   Example:
       *   [
       *     { color: '#2ca02c', key: 'Base', values: [{ x: 10, y: 142,184,395 }, { x: 11, y: 153,872,132 }, ...] },
       *     { color: '#77BDFF', key: 'Compare 1', values: [{ x: 10, y: 145,663,840 }, { x: 11, y: 161,524,653 }, ...] },
       *     ...
       *   ]
       *
       * @configuration an object containing:
       *   chart (optional) an object containing chart properties to be overridden.
       *     showControls (optional) the boolean to display Grouped/Stacked controls or not. Defaults to false.
       *     stacked (optional) the boolean to display chart in stacked form or not. Defaults to false.
       *     xAxis.axisLabel (optional) the X-axis label of the chart. Defaults to 'X Axis'.
       *     yAxis.axisLabel (optional) the Y-axis label of the chart. Defaults to 'Y Axis'.
       *   caption (optional) the caption of the chart. Defaults to ''.
       *   subtitle (optional) the subtitle of the chart. Defaults to ''.
       *   title (optional) the title of the chart. Defaults to ''.
       *
       * @showData (Optional) When true, a Data link is available to view the data used by the chart. Otherwise, no Data link is displayed.
       *
       * @showOptions (Optional) When true, a Configuration link is available to view the configuration used by the chart. Otherwise, no Configuration link is displayed.
       */
      bindings: AbstractChartComponent.bindings(),
      controller: MultiBarChartController,
      templateUrl: AbstractChartComponent.templateUrl
    });
})();
