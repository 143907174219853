(function () {
  'use strict';
  class Mode {
    /**
     * Create an instance of the Mode state tracking object.
     * @param root the base object where binding is defined.
     * @param target the variable on root that is bound in the calling class for tracking state.
     * @param states the list of states that the Mode can be in.
     * @return the instantiated Mode state tracking object.
     */
    constructor (root, target, states) {
      this.root = root;
      this.target = target;
      this.canDereference = _.isObject(this.root) && _.isString(this.target);
      states = Array.isArray(states) ? states : [];
      states.forEach((state) => {
        const stateName = _.upperFirst(state);

        // Create is{state} function that returns true if the state is currently selected
        this[`is${stateName}`] = () => this.root[this.target] === state;

        // Create set{state} function that sets the value of the target variable to {state}
        this[`set${stateName}`] = () => this.root[this.target] = state;
      });
    }

    static create (root, target, states) {
      return new Mode(root, target, states);
    }

    any () {
      const states = _.flatten(_.toArray(arguments));
      return _.some(states, (state) => this.canDereference && this.root[this.target] === state);
    }

    get () {
      return this.canDereference ? this.root[this.target] : undefined;
    }

    set (state) {
      if (this.canDereference) {
        this.root[this.target] = state;
      }
    }
  }

  window.Mode = Object.freeze(Mode);
})();
