/* globals Configuration, Enums, Ready, ProductLine */
(function () {
  'use strict';
  class ProductLineListController {
    static get $inject () {
      return ['alerts', '$authentication', '$scope', 'overlay', 'planConfiguration', '$uibModal'];
    }

    constructor (alerts, $authentication, $scope, overlay, planConfiguration, $uibModal) {
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.$scope = $scope;
      this.overlay = overlay;
      this.planConfiguration = planConfiguration;
      this.$uibModal = $uibModal;
      this.state = Ready.create({
        isDataEmptyFn: () => _.size(this.productLinesList) <= 0,
        isLoadingFn: () => this.loading
      });
    }

    _loadData () {
      this.loading = true;
      this.productLinesList.length = 0;
      // Clear any user input fields that should be cleared with new data being loaded
      this.$scope.$broadcast(Enums.BroadcastChannel.CLEAR);
      this.planConfiguration.productLine().list({ includeInactive: true })
        .then((productLines) => this.productLinesList = productLines)
        .finally(() => this.loading = false);
    }

    canCreate () {
      return !Configuration.scopes.current().isComposite() && this.$authentication.hasAdministratorAccess();
    }

    canEdit (productLine) {
      return this.$authentication.hasAdministratorAccess() && productLine.isEditable;
    }

    generateIncludeExcludeListHtml (groupSet, type) {
      let html = '';
      if (_.isNil(groupSet[type])) {
        return html;
      }
      const includeList = groupSet[type].include,
            excludeList = groupSet[type].exclude,
            hasIncludes = Array.isArray(includeList) && !_.isEmpty(includeList),
            hasExcludes = Array.isArray(excludeList) && !_.isEmpty(excludeList);
      if (hasIncludes) {
        html += includeList.join(', ');
        if (hasExcludes) {
          html += ' | ';
        }
      }
      if (hasExcludes) {
        html += `<span class="text-danger">(${excludeList.join(', ')})</span>`;
      }
      return html;
    }

    openProductLineModal ($event, productLine = ProductLine.template()) {
      if (!_.isNil($event)) {
        $event.stopPropagation();
      }
      this.$uibModal
        .open({
          component: 'productLineModal',
          resolve: {
            productLine: () => productLine
          },
          size: Enums.ModalSize.EXTRA_LARGE
        })
        .result.then((modalResponse) => {
          this[modalResponse.mode](modalResponse.productLine);
        })
        .catch(_.noop);
    }

    delete (productLine) {
      this.overlay.show(`Removing product line: ${productLine.name}`);
      this.planConfiguration
        .productLine(productLine)
        .delete()
        .then(() => this.alerts.success(`Successfully removed product line: ${productLine.name}`))
        .then(() => this._loadData())
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to remove product line: ${productLine.name}` }))
        .finally(() => this.overlay.hide());
    }

    save (productLine) {
      this.overlay.show(`Saving product line: ${productLine.name}`);
      this.planConfiguration
        .productLine(productLine)
        .upsert()
        .then((response) => this.alerts.success(`Successfully saved product line: ${response.name}`))
        .then(() => this._loadData())
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to save product line: ${productLine.name}` }))
        .finally(() => this.overlay.hide());
    }

    $onInit () {
      this.productLinesList = [];
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('productLineList', {
      controller: ProductLineListController,
      templateUrl: 'templates/components/product-line-list.component.html'
    })
    .filter('productLineListFilter', function () {
      return (input, predicate) => {
        let plList = _.clone(input);
        if (!_.isEmpty(predicate)) {
          if (!_.isEmpty(predicate.name)) {
            plList = _.filter(plList, (pl) => pl.name.toLowerCase().includes(predicate.name.toLowerCase()));
          }
          if (!_.isEmpty(predicate.group)) {
            plList = _.filter(plList, (pl) => pl.group.toLowerCase().includes(predicate.group.toLowerCase()));
          }
          if (!_.isEmpty(predicate.businessEntity)) {
            plList = _.filter(plList, (pl) => pl.businessEntity.toLowerCase().includes(predicate.businessEntity.toLowerCase()));
          }
          if (!_.isEmpty(predicate.productLineOwner)) {
            plList = _.filter(plList, (pl) => pl.productLineOwner.toLowerCase().includes(predicate.productLineOwner.toLowerCase()));
          }
          if (!_.isEmpty(predicate.iog)) {
            plList = _.filter(plList, (pl) => {
              let iogString = '';
              if (!_.isNil(pl.definition.iog)) {
                if (!_.isNil(pl.definition.iog.exclude)) {
                  iogString += pl.definition.iog.exclude.join(',');
                }
                if (!_.isNil(pl.definition.iog.include)) {
                  iogString += pl.definition.iog.include.join(',');
                }
              }
              return iogString.includes(predicate.iog);
            });
          }
          if (!_.isEmpty(predicate.gl)) {
            plList = _.filter(plList, (pl) => {
              let glString = '';
              if (!_.isNil(pl.definition.gl)) {
                if (!_.isNil(pl.definition.gl.exclude)) {
                  glString += pl.definition.gl.exclude.join(',');
                }
                if (!_.isNil(pl.definition.gl.include)) {
                  glString += pl.definition.gl.include.join(',');
                }
              }
              return glString.includes(predicate.gl);
            });
          }
        }
        return plList;
      };
    });
})();
