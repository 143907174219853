/* globals DataCell, Mapper */
(function () {
  'use strict';

  const CELL_VALUE_PROPERTY = 'value';
  const PROPERTIES = Object.freeze([
    'dataType',
    'granularity',
    'metric'
  ]);

  class DataRow {
    constructor (source) {
      Mapper.apply(source, this, PROPERTIES);
      this.cells = _.map(source.values, (value) => value instanceof DataCell ? value : DataCell.create(value, source.dataType));
    }

    static create (source) {
      return new DataRow(source);
    }

    get values () {
      return _.map(this.cells, CELL_VALUE_PROPERTY);
    }

    get size () {
      return _.size(this.cells);
    }

    set values (values) {
      if (_.size(values) !== _.size(this.cells)) {
        throw new Error('DataRow: cannot set values as size of values and cells does not match');
      }
      this.cells.forEach((cell, i) => _.set(cell, CELL_VALUE_PROPERTY, values[i]));
    }
  }

  window.DataRow = Object.freeze(DataRow);
})();
