/* globals AbstractPackagerService, Configuration, DataCell, DataPackage, DateUtils, Enums, GrainDefinition, Granularities, Grid, Name */
(function () {
  'use strict';

  const MANUAL_BACKLOG_FILTER_GRAINS = Granularities.create().addNodeGrain().addProductLineGrain().addSortTypeGrain();
  const MANUAL_BACKLOG_RECORD_GRANULARITY = 'PRODUCTLINE-SORTTYPE-WAREHOUSE';

  class DataPackager {
    constructor (dates, metric, startDate, endDate, action) {
      this.dates = dates;
      this.endDate = endDate;
      this.metric = metric;
      this.startDate = startDate;
      this.action = action;
    }

    static create (dates, metric, startDate, endDate, action) {
      return new DataPackager(dates, metric, startDate, endDate, action);
    }

    transform (promise) {
      return promise
        .then((dataset) => ({ view: dataset }))
        .then(this.mapIdsToNames.bind(this))
        .then(this.resize.bind(this))
        .then(this.addGranularity.bind(this));
    }

    mapIdsToNames (dataset) {
      dataset.view.records.forEach((record) => _.set(record, 'granularity.sortType', GrainDefinition.getSortTypeName(record.granularity.sortType)));
      return dataset;
    }

    resize (dataset) {
      const resizeRecords = (records) => {
        const dateOffset = DateUtils.dateArrayComparator(
          this.dates,
          DateUtils.expansion(this.startDate, this.endDate)
        );
        Grid.resize(
          records,
          {
            append: dateOffset.postfix.count,
            prepend: dateOffset.prefix.count,
            property: 'cells',
            value: DataCell.create(null)
          }
        );
      };
      resizeRecords(dataset.view.records);
      return dataset;
    }

    addGranularity (dataset) {
      const addGranularity = (records, dataType) => {
        _.forEach(records, (record) => {
          record.granularity.draft = dataType;
          record.granularity.metric = Name.ofMetric(this.metric);
          record.metric = this.metric;
        });
      };
      addGranularity(dataset.view.records, Enums.UserAction.VIEW);
      return dataset.view.records;
    }
  }

  class ManualBacklogPackagerService extends AbstractPackagerService {
    static get $inject () {
      // Data Actuals is used in place of Metrics Service for recordMetadata details (lastUpdatedBy, lastUpdatedAt).
      return ['dataActuals', '$q'];
    }

    constructor (dataActuals, $q) {
      super($q);
      this.dataActuals = dataActuals;
    }

    _createCrossProductRecords (configuration, numberOfValues, metricDisplayName) {
      // The filter object must first be converted into a list of lists before being provided to cartesianCrossProduct().
      return _.sop.cartesianCrossProduct(...MANUAL_BACKLOG_FILTER_GRAINS.values()
        // Ensure each value is a string. If not, extract the name of the grain.
        .map((grain) => configuration.filters[grain.id].map((value) => Name.ofIdentity(value))))
        .map((combination) => ({
          // Convert the resulting combination lists back into filter objects.
          granularity: configuration.granularity.grains.values().reduce((object, grain, index) => Object.assign(object, { [grain.id]: grain.isMetricGrain() ? metricDisplayName : combination[index-1] }), { draft: Enums.DataPackage.RecordType.EDIT }),
          values: Array(numberOfValues).fill(null)
        }));
    }

    collect (dates, metric, configuration, action) {
      const body = {
        filters: configuration.filters,
        periodEndDate: configuration.endDate,
        periodStartDate: configuration.startDate
      };
      const promise = action === Enums.UserAction.VIEW ?
        DataPackager.create(dates, metric, configuration.startDate, configuration.endDate, action).transform(this.dataActuals.fetchActuals(metric.id, MANUAL_BACKLOG_RECORD_GRANULARITY, Enums.TimeGranularity.DAILY, body)) :
        this.$q.resolve(this._createCrossProductRecords(configuration, dates.length, metric.displayName));
      return DataPackage.create({
        dates: dates.map((date) => DateUtils.of(date).toDate()),
        granularity: configuration.granularity,
        records: promise,
        scope: Configuration.scopes.current().code,
        title: Name.ofMetric(metric),
        type: Enums.DataPackage.PackageType.MANUAL_BACKLOG
      }, this.$q);
    }
  }

  angular.module('application.services').service('manualBacklogPackager', ManualBacklogPackagerService);
})();
