/* globals AbstractElementComponent */
(function () {
  'use strict';
  class ConfirmationActionController extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element) {
      super($element);
      this.popover = {
        appendToBody: true,
        class: 'confirmation-popover',
        isOpen: false,
        template: 'templates/components/confirmation-popover.component.html',
        title: 'Are you sure?',
        trigger: 'outsideClick'
      };
    }

    isButtonVisible () {
      return _.isString(this.labelText);
    }

    isIconOnly () {
      return _.isString(this.icon) && _.isNil(this.labelText);
    }

    isLabelOnly () {
      return _.isString(this.labelText) && _.isNil(this.icon);
    }

    isYesDisabled () {
      return !this.confirmationCheck || !this.isUserConfirmationValid;
    }

    onDecisionNo () {
      this.popover.isOpen = false;
      this.onSelectionNo();
    }

    onDecisionYes () {
      this.popover.isOpen = false;
      this.onSelectionYes();
    }

    onUnderstandClick () {
      if (this.confirmationCheck) {
        return;
      }
      this.userConfirmation = '';
      this.isUserConfirmationValid = false;
    }

    validateConfirmationText () {
      this.isUserConfirmationValid = this.userConfirmation === this.confirmationInputText;
    }

    $onInit () {
      this.isUserConfirmationValid = _.isNil(this.confirmationInputText);
      this.confirmationCheck = _.isNil(this.confirmationInputText);
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('confirmationAction', {
      bindings: {
        /*
         * @confirmationCheckboxText (String) is the checkbox label text displayed next to the confirmation checkbox.
         * confirmationCheckboxText must be provided if @confirmationInputText is provided.
         */
        confirmationCheckboxText: '@',
        /*
         * @confirmationInputText (String - Optional) is the confirmation text that user needs to enter to confirm the YES action.
         * Additional confirmation checks are performed, only when confirmationInputText is provided.
         */
        confirmationInputText: '@',
        /*
         * @content (String - Optional) is the confirmation text content presented to the user before the NO & YES action buttons.
         * When content is not provided, user is only presented with the NO & YES action buttons, no confirmation message.
         */
        content: '<',
        /*
         * @icon (String) is the icon string representing the icon to be displayed. Optional when labelText is provided.
         * When icon is provided and no labelText, icon is displayed instead of a button.
         * When icon is not provided and just the labelText, button is displayed instead of an icon.
         * When both icon and labelText are provided, icon button is displayed i.e. button with an icon on it, instead of label text.
         */
        icon: '@',
        /*
         * @labelClasses (String) are the CSS classes to be used for the confirmation icon/button.
         */
        labelClasses: '@',
        /*
         * @labelText (String) is the label text string representing the text to be displayed on the button. Optional when icon is provided.
         * When labelText is provided and no icon, label text is displayed instead of an icon.
         * When labelText is not provided and just the icon, icon is displayed instead of a button.
         * When both labelText and icon are provided, icon button is displayed i.e. button with an icon on it, instead of label text.
         */
        labelText: '@',
        /*
         * @onSelectionNo (callback) is the callback when user makes a NO decision.
         */
        onSelectionNo: '&',
        /*
         * @onSelectionYes (callback) is the callback when user makes a YES decision.
         */
        onSelectionYes: '&',
        /*
         * @tooltip (String - Optional) is the tooltip displayed when user hovers mouse over the icon/button.
         */
        tooltip: '@'
      },
      controller: ConfirmationActionController,
      templateUrl: 'templates/components/confirmation-action.component.html'
    });
})();
