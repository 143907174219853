/* globals Enumeration, Ready, Stateful, Summary */
(function () {
  'use strict';

  class AbstractDataViewController {
    static get $inject () {
      return ['$scope', 'share', 'view'];
    }

    constructor ($scope, share, view) {
      if (_.isNil($scope)) {
        throw new Error('AbstractDataViewController: $scope must not be nil');
      }

      this.$scope = $scope;
      this.share = share;
      this.view = view;
    }

    get querySummary () {
      return this.$scope.summary;
    }

    addData (...data) {
      this.$scope.data.push(...data);
    }

    clearData () {
      this.$scope.data.length = 0;
      this.$scope.summary.clear();
      this.displaySummary(false);
    }

    collapseSettings (state) {
      this.$scope.settings.collapsed = state;
    }

    // Available to view at $scope.methods.displaySummary()
    displaySummary (state) {
      this.$scope.settings.displaySummary = state;
    }

    // Available to view at $scope.methods.download()
    download () {
      return false;
    }

    // Available to view at $scope.isDataEmpty()
    isDataEmpty () {
      return _.isEmpty(this.$scope.data);
    }

    // Available to view at $scope.methods.isValid()
    isSettingsValid () {
      return false;
    }

    // Available to view at $scope.methods.isCollapsed()
    isSettingsCollapsed () {
      return this.$scope.settings.collapsed;
    }

    // Available to view at $scope.methods.isLocked()
    isSettingsLocked () {
      return this.$scope.settings.locked;
    }

    // Available to view at $scope.methods.isSummaryDisplayed()
    isSummaryDisplayed () {
      return this.$scope.settings.displaySummary;
    }

    // Available to view at $scope.settings.steps.isActive(step)
    isStepActive (step) {
      return this.$scope.settings.steps.active === step;
    }

    lockSettings (state) {
      this.$scope.settings.locked = state;
    }

    /* Available to view at $scope.methods.mutation.fallback()
     * Performs a fallback mutation. This is done only when the "targetPath" property is missing and it needs a value to fall back to.
     * 1. Assigns "substitution" property or value to the "targetPath" property.
     *
     * @param source the source object on which the assign operation would be performed.
     * @param targetPath a string path on the source object.
     * @param fallbackPath a string path on the source object.
     */
    mutationFallback (source, targetPath, fallbackPath) {
      if (_.isNil(_.get(source, targetPath))) {
        _.set(source, targetPath, _.get(source, fallbackPath));
      }
    }

    /* Available to view at $scope.methods.mutation.reassign()
     * Performs a reassign mutation. This is done only when the "sourcePath" property is being moved to a new path.
     * 1. Assigns the "sourcePath" property value to the "targetPath" property.
     * 2. Unsets the "sourcePath" property.
     *
     * @param source the source object on which the assign and unset operations would be performed.
     * @param targetPath a string path on the source object.
     * @param sourcePath a string path on the source object.
     */
    mutationReassign (source, targetPath, sourcePath) {
      if (_.isNil(_.get(source, targetPath))) {
        _.set(source, targetPath, _.get(source, sourcePath));
        _.unset(source, sourcePath);
      }
    }

    /* Available to view at $scope.methods.mutation.recycle()
     * Performs a recycle mutation. This is done only when the 'sourcePath' property is being "repurposed", and not discarded.
     * 1. Assigns the 'sourcePath' property value to the 'targetPath' property.
     * 2. Assigns the provided 'recycleValue' value to the 'sourcePath' property.
     *
     * @param source the source object on which the assign and recycle operations would be performed.
     * @param targetPath a string path on the source object.
     * @param sourcePath a string path on the source object.
     * @param recycleValue a recycle value to be set at the 'sourcePath' property.
     */
    mutationRecycle (source, targetPath, sourcePath, recycleValue) {
      if (_.isNil(_.get(source, targetPath))) {
        _.set(source, targetPath, _.get(source, sourcePath));
        _.set(source, sourcePath, recycleValue);
      }
    }

    resetState () {
      this.$scope.stateful.reset(this.$scope);
    }

    // Available to view at $scope.methods.steps.set(step)
    setStep (step) {
      this.$scope.settings.steps.active = step;
      this.collapseSettings(false);
    }

    // Available to view at $scope.methods.submit()
    submit () {
      return false;
    }

    registerShare (definition) {
      if (_.isNil(this.share)) {
        throw new Error('AbstractDataViewController: share service is undefined and registration is not supported');
      }

      definition.push({
        action: () => this.lockSettings(true),
        persist: false
      });

      this.share.register(
        this.$scope,
        definition,
        this.isSettingsValid.bind(this),
        () => Ready.once(
          this.$scope,
          this.isSettingsValid.bind(this),
          () => {
            this.submit();
            this.lockSettings(false);
          }
        )
      );
    }

    registerView (definition) {
      if (_.isNil(this.view)) {
        throw new Error('AbstractDataViewController: view service is undefined and registration is not supported');
      }

      definition.push({
        action: () => this.lockSettings(true),
        persist: false
      });

      this.view.register(
        this.$scope,
        definition,
        this.isSettingsValid.bind(this),
        () => Ready.once(
          this.$scope,
          this.isSettingsValid.bind(this),
          () => {
            this.submit();
            this.lockSettings(false);
          }
        )
      );
    }

    $onInit (steps, properties) {
      if (!Array.isArray(steps) || _.isEmpty(steps)) {
        throw new Error('AbstractDataViewController: steps must be a non-empty Array');
      }

      if (_.isNil(properties)) {
        throw new Error('AbstractDataViewController: properties must not be nil');
      }

      this.$scope.STEPS = Enumeration.create(...steps).asIndexValue();
      this.$scope.stateful = Stateful.create(
        Object.assign(
          {
            data: () => [],
            isDataEmpty: () => this.isDataEmpty.bind(this),
            'methods.displaySummary': () => this.displaySummary.bind(this),
            'methods.download': () => this.download.bind(this),
            'methods.isCollapsed': () => this.isSettingsCollapsed.bind(this),
            'methods.isLocked': () => this.isSettingsLocked.bind(this),
            'methods.isSummaryDisplayed': () => this.isSummaryDisplayed.bind(this),
            'methods.isValid': () => this.isSettingsValid.bind(this),
            'methods.mutation.fallback': () => this.mutationFallback.bind(this),
            'methods.mutation.reassign': () => this.mutationReassign.bind(this),
            'methods.mutation.recycle': () => this.mutationRecycle.bind(this),
            'methods.steps.set': () => this.setStep.bind(this),
            'methods.submit': () => this.submit.bind(this),
            model: () => ({}),
            'model.filters': () => ({}),
            'settings.collapsed': () => false,
            'settings.displaySummary': () => false,
            'settings.locked': () => false,
            'settings.steps.active': () => 1,
            'settings.steps.isActive': () => this.isStepActive.bind(this),
            summary: () => Summary.create(),
            summaryInterface: () => ({})
          },
          properties
        )
      ).reset(this.$scope);
    }
  }

  window.AbstractDataViewController = Object.freeze(AbstractDataViewController);
})();
