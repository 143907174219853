/* globals AbstractItemSelectorComponent, Enums */
(function () {
  'use strict';
  class GrainItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'grainItemFactory'];
    }

    constructor ($element, grainItemFactory) {
      super($element);
      this.grainItemFactory = grainItemFactory;
    }

    _loadData () {
      const configuration = _.defaults(
        this.configuration,
        {
          excludeUnknown: this.excludeUnknown
        }
      );

      this.itemSelectorInterface = this.grainItemFactory.collect(this.grainType, configuration);
    }

    $onInit () {
      this.disableSearch = this.disableSearch || this.grainType === Enums.GrainIdentity.sortType;
      this.excludeUnknown = !!this.excludeUnknown;
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('grainItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        excludeUnknown: '<',
        grainType: '@'
      }),
      controller: GrainItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
