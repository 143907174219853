(function () {
  'use strict';

  const DEFAULT_MODEL_PROPERTY = 'model';

  class AbstractSourceBasedModelModal {
    constructor (modelProperty = DEFAULT_MODEL_PROPERTY) {
      this.modelProperty = modelProperty;
    }

    close () {
      this.modalInstance.close(this[this.modelProperty]);
    }

    dismiss () {
      this.modalInstance.dismiss();
    }

    isAdd () {
      return !this.isEdit();
    }

    isEdit () {
      return this[this.modelProperty].isExisting();
    }

    $onInit () {
      // Make a clone of the resolver model so we do not modify the original
      this[this.modelProperty] = this.resolve[this.modelProperty].clone();
    }

    setProperty (key, value) {
      this[this.modelProperty].assign({ [key]: value });
    }
  }

  window.AbstractSourceBasedModelModal = Object.freeze(AbstractSourceBasedModelModal);
})();
