/* globals Enums */
(function () {
  'use strict';
  const loremIpsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
    qui officia deserunt mollit anim id est laborum.`;

  class ComponentLibraryController {
    static get $inject () {
      return ['alerts', '$interval', 'overlay', '$scope', '$timeout'];
    }

    constructor (alerts, $interval, overlay, $scope, $timeout) {
      $scope.inputs = {
        flows: ['Arrivals', 'CustomerReturns', 'NewTransferIn'],
        switch: {
          counter: [
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 }
          ],
          historicalTimeUnits: [
            Enums.TimeUnit.DAY,
            Enums.TimeUnit.WEEK
          ],
          icons: [
            { html: '<i class="fa fa-plus" aria-hidden="true"></i>', value: true },
            { html: '<i class="fa fa-ban" aria-hidden="true"></i>', value: false }
          ],
          power: [
            { text: 'On', value: true },
            { text: 'Off', value: false }
          ],
          strings: ['A', 'B']
        }
      };
      $scope.actions = {
        alert: (type, importance) => {
          const message = {
            details: type === 'danger' ? loremIpsum : undefined,
            message: 'Note: For better review experience please use a 1600x1024 or higher resolution monitor.'
          };
          alerts[type](message, importance);
        },
        getConfirmationActionContent: (contentType, action) => {
          switch (contentType) {
            case 'Are_You_Sure':
              return `Are you sure you want to ${action}?`;
            case 'Yes_or_No':
              return 'Yes or <strong>No</strong>?';
            default:
              return 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit&hellip;';
          }
        },
        onRoutesSelectionChange: (list, selectAll) => {
          $scope.model.selectedRoutesList = list;
          $scope.model.routesSelectAll = selectAll;
        },
        onScopesSelectionChange: (list, selectAll) => {
          $scope.model.selectedScopesList = list;
          $scope.model.scopesSelectAll = selectAll;
        },
        overlay: (message, progressMax = 0) => {
          let progressTimer;
          let progressValue = 0;
          overlay.show(message, progressMax);
          if (progressMax > 0) {
            progressTimer = $interval(
              () => overlay.progress(++progressValue),
              1000
            );
          }
          $timeout(
            () => {
              if (progressTimer) {
                $interval.cancel(progressTimer);
              }
              overlay.hide();
            },
            (progressMax * 1000) + 3000
          );
        }
      };
      $scope.model = {
        checkbox: {
          isDisabled: false,
          isIndeterminate: false
        },
        fcWeeklyPlanGrouping: {},
        fileExtensions: ['.xlsx', '.csv', '.zip'],
        flow: {
          id: 'Arrivals'
        },
        historicalTimeUnit: Enums.TimeUnit.WEEK,
        planMetadata: {
          draft: 'finalDraft',
          scenario: Enums.Plan.Scenario.PROD,
          subScenario: Enums.Plan.Scenario.PROD,
          type: 'dailyPlan'
        },
        selectedTreeNodes: {},
        switch: {
          binary: true,
          counter: 3
        },
        timeUpCount: 0,
        userProfiles: [
          { alias: 'jsaundea', name: 'Justin Saunders' },
          { alias: 'marschus', name: 'Mark Schuster' },
          { alias: 'parsagar', name: 'Sagar Parab' },
          { alias: 'paulaugr', name: 'Paul Augros' },
          { alias: 'system' }
        ],
        usStatesCapitals: {
          AZ: { capital: 'Phoenix', state: 'Arizona' },
          CA: { capital: 'Sacramento', state: 'California' },
          CO: { capital: 'Denver', state: 'Colorado' },
          FL: { capital: 'Tallahassee', state: 'Florida' },
          GA: { capital: 'Atlanta', state: 'Georgia' },
          MA: { capital: 'Boston', state: 'Massachusetts' },
          NY: { capital: 'Albany', state: 'New York' },
          TX: { capital: 'Austin', state: 'Texas' },
          UT: { capital: 'Salt Lake City', state: 'Utah' },
          WA: { capital: 'Olympia', state: 'Washington' }
        }
      };
      $scope.legend = {
        data: [
          [{ class: 'dataset primary', definition: '04/09/2017 Prefinal', term: 'Plan' }],
          [{ class: 'dataset comparison', definition: '04/02/2017 Final', term: 'Comparison' }],
          [{ class: 'dataset actuals', definition: '03/26/2017 Actuals', term: 'Actuals' }]
        ],
        header: 'Legend'
      };
    }
  }
  angular.module('application.controllers').controller('ComponentLibraryController', ComponentLibraryController);
})();
