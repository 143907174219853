/* globals AbstractElementComponent, accounting, Enumeration */
(function () {
  'use strict';
  // TODO: Move to Enumerations as 'Enums.ChartType'
  const CHART_TYPE = Enumeration
    .create('LINE_CHART', 'MULTI_BAR_CHART', 'PIE_CHART')
    .asStringValue(_.camelCase);
  // TODO: Remove once Chart model is available/ready for use.
  const DEFAULT_OPTIONS = Object.freeze({
    chart: {
      clipEdge: true,
      duration: 200,
      height: 450,
      labelThreshold: 0.01,
      legend: {
        align: true,
        maxKeyLength: 50,
        rightAlign: true
      },
      margin: {
        bottom: 40,
        left: 20,
        right: 20,
        top: 40
      },
      showLabels: true,
      showLegend: true,
      useInteractiveGuideline: true,
      x: (d) => d.x,
      y: (d) => d.y,
      yAxis: {
        axisLabelDistance: -10,
        tickFormat: (d) => accounting.formatNumber(d),
        width: 120
      }
    }
  });

  class AbstractChartComponent extends AbstractElementComponent {
    static get $inject () {
      return ['$element'];
    }

    constructor ($element, stateFunctions) {
      super($element, stateFunctions);
    }

    static get chartType () {
      return CHART_TYPE;
    }

    static get templateUrl () {
      return 'templates/components/abstract-chart.component.html';
    }

    static bindings (additionalBindings) {
      return _.defaults(additionalBindings, {
        /* @configuration an object containing:
         *   chart (optional) an object containing chart properties to be overridden.
         *   caption (optional) the caption of the chart. Defaults to ''.
         *   subtitle (optional) the subtitle of the chart. Defaults to ''.
         *   title (optional) the title of the chart. Defaults to ''.
         */
        configuration: '<',
        data: '<',
        refresh: '<',
        /*
         * @showData (Optional) When true, a Data link is available to view the data used by the chart. Otherwise, no Data link is displayed.
         */
        showData: '<',
        /*
         * @showOptions (Optional) When true, a Configuration link is available to view the configuration used by the chart. Otherwise, no Configuration link is displayed.
         */
        showOptions: '<'
      });
    }

    getDefaultChartOptions () {
      return DEFAULT_OPTIONS;
    }

    setTitleSubtitleCaption (options, overrides) {
      const title = _.get(overrides, 'title');
      const subtitle = _.get(overrides, 'subtitle');
      const caption = _.get(overrides, 'caption');
      _.set(options, 'title.enable', !!title);
      _.set(options, 'title.text', title || '');
      _.set(options, 'subtitle.enable', !!subtitle);
      _.set(options, 'subtitle.text', subtitle || '');
      _.set(options, 'caption.enable', !!caption);
      _.set(options, 'caption.text', caption || '');
    }

    validateConfiguration (configuration, chartType) {
      if (_.has(configuration, 'chart.type')) {
        throw new Error(`${_.upperFirst(chartType)}: Provided configuration must not contain chart.type property.`);
      }
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  window.AbstractChartComponent = Object.freeze(AbstractChartComponent);
})();
