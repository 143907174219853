/* globals Configuration */
/***
 * This file defines various configuration blocks that are intended to be temporary solutions related to
 * the Group Ratio until a more optimal strategy for acquiring this information is made available.
 * This entire file can be viewed as a series of TODOs to find better ways to acquire this configuration.
 ***/
(function () {
  'use strict';

  // Object holding data mode enum strings
  const FC_BT_UT_TO_NCG_RATIO = Object.freeze({
    destinationGrain: [
      'networkCapacityGroup'
    ],
    maxRatioValue: 1.0,
    sourceGrain: [
      'node',
      'binType',
      'usageType'
    ]
  });

  const ratiosMap = new Map();
  ratiosMap.set('fcBtUtToNcgRatio', FC_BT_UT_TO_NCG_RATIO);

  Configuration.defineProperty('ratiosMap', ratiosMap);
})();
