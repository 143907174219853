/* globals Configuration, DataPackage, PlanMetadata */
(function () {
  'use strict';

  class NetworkPlannerSubmitModalController {
    static get $inject () {
      return ['alerts', 'orchestrator', 'overlay', '$authentication', '$q'];
    }

    constructor (alerts, orchestrator, overlay, $authentication, $q) {
      this.alerts = alerts;
      this.orchestrator = orchestrator;
      this.overlay = overlay;
      this.$authentication = $authentication;
      this.$q = $q;
    }

    /**
     * Update or insert a plan using orchestrator APIs
     * @param {DataPackage[]} dataPkgs DataPackages that represent a plan
     * @param {Boolean} isCreate insert if true, update otherwise
     * @returns {Promise} an orchestrator service promise
     * @private
     */
    _upsertPlan (dataPkgs, isCreate) {
      return isCreate ? this.orchestrator.create(dataPkgs) : this.orchestrator.edit(dataPkgs);
    }

    /**
     * Gather all orchestrator create/edit promises that need to be fulfilled
     * @param {Object} submittedPlans a map of {planType: DataPackage[]}
     * @returns {Object} a map of {planType: orchestrator create/edit Promise}
     * @private
     */
    _buildSubmitPromises (submittedPlans) {
      return _.transform(submittedPlans, (result, dataPkgs, planType) => {
        result[planType] = this._upsertPlan(dataPkgs, DataPackage.isCreate(_.head(dataPkgs)));
      }, {});
    }

    /**
     * Gather all orchestrator pollModification promises that need to be fulfilled
     * @param {Object} submittedPlans a map of {planType: DataPackage[]}
     * @param {Object} submitResponses a map of {planType: SubmitResponse}
     * @returns {Object} a map of <planType, orchestrator pollModification Promise>
     * @private
     */
    _buildPollPromises (submittedPlans, submitResponses) {
      return _.transform(submitResponses, (result, response, planType) => {
        const planMetadata = _.head(submittedPlans[planType]).plan;
        const uuid = _.head(response).uuid;
        result[planType] = this.orchestrator.pollModification(planMetadata, uuid);
      }, {});
    }

    /**
     * Build an array of submitted planMetadatas
     *    - Set the plans versions to be destVersion from pollResponses
     * @param {Object} submittedPlans a map of {planType: DataPackage[]}
     * @param {Object} pollResponses a map of {planType: PollResponse}
     * @returns {PlanMetadata[]} an array of PlanMetadata of the submitted plans
     * @private
     */
    _getSubmittedPlanMetas (submittedPlans, pollResponses) {
      return _.transform(submittedPlans, (result, dataPkgs, planType) => {
        const source = _.cloneDeep(_.head(dataPkgs).plan.source);
        source.version = pollResponses[planType].destVersion;
        result.push(PlanMetadata.create(source));
      }, []);
    }

    /**
     * Get planId and add <planId - associated plans> relationship using updateEditTracking API
     * @param {Object} submittedPlans a map of {planType: DataPackage[]}
     * @param {Object} pollResponses a map of {planType: PollResponse}
     * @returns {Promise} a map of {planType: pollingModificationStatus}
     * @private
     */
    _getPlanId (submittedPlans, pollResponses) {
      const submittedPlanMetas = this._getSubmittedPlanMetas(submittedPlans, pollResponses);

      // The parents' order is important since it is implicitly used to select prefinalDrafts before baselineDrafts
      // when building the plan relations in the next step
      const parents = _.concat(submittedPlanMetas, this.baselineDrafts);

      const planRelations = _(Configuration.networkPlannerPlans)
        .filter('isDerived')
        .map((plan) => ({
          childPlan: parents.find((parentPlan) => parentPlan.type === plan.type),
          parentPlans: plan.dependencies.map((planType) => parents.find((parent) => parent.type === planType))
        })).value();
      const input = {
        parents: parents,
        planDate: this.planningWeek,
        planningScenario: this.scenario,
        planRelations: planRelations,
        user: this.$authentication.profile().alias
      };
      return this.orchestrator.updateEditTracking(input);
    }

    /**
     * Get submitted plans from this.plans
     * @return {Object} a map of {planType: DataPackage[]}
     * @private
     */
    _getSubmittedPlans () {
      return _.transform(this.plans, (result, flow) => {
        _.forEach(flow, (planData, planType) => result[planType] = planData.plan);
      }, {});
    }

    /**
     * Save plans to S3 and planId to DDB
     */
    onSubmit () {
      this.overlay.show('Submitting Plans');
      const submittedPlans = this._getSubmittedPlans();
      const submitPromises = this.$q.all(this._buildSubmitPromises(submittedPlans))
        .then((submitResponses) => this.$q.all(this._buildPollPromises(submittedPlans, submitResponses)))
        .then((pollResponses) => this._getPlanId(submittedPlans, pollResponses))
        .then((response) => this.alerts.success(`<b>Plans Submitted Successfully</b><br>
                                                    Plan ID: ${response.planId}<br>
                                                    Scenario: ${this.scenario}<br>
                                                    Plan Date: ${this.planningWeek}`))
        .catch((error) => {
          this.alerts.danger({ details: error, message: 'Unable to submit plans.' });
          throw error;
        })
        .finally(() => this.overlay.hide());
      this.modalInstance.close({ submitPromises });
    }

    $onInit () {
      this.baselineDrafts = this.resolve.baselineDrafts;
      this.isDisplayOnlyEdited = this.resolve.isDisplayOnlyEdited;
      this.planningWeek = this.resolve.planningWeek;
      this.plans = this.resolve.plans;
      this.scenario = this.resolve.scenario;
      this.getPlanEditStatus = (plan) => plan.isEdited ? 'Edited' : 'Not Edited';
    }
  }

  angular.module('application.components')
    .component('networkPlannerSubmitModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with 5 properties:
         *    baselineDrafts - planMetadata[] - a list of planMetadatas for baselineDrafts
         *    isDisplayOnlyEdited - boolean - display only edited plans on the submit page if true
         *    planningWeek - string - the planning week from the main page
         *    plans - Object - containing all plans including non-edited plans
         *        {flow1: {planType1: {displayName: 'Shipment Creations Weekly Plan', isEdited: false, plan: [dataPkgs]}}}
         *        It may contain multiple flows and multiple planTypes for each flow
         *    scenario - string - the scenario from the main page (sub-scenario internally)
         */
        resolve: '<'
      },
      controller: NetworkPlannerSubmitModalController,
      templateUrl: 'templates/components/network-planner-submit-modal.component.html'
    });
})();
