/*  globals Enums */
(function () {
  'use strict';
  /**
   * Displays a configurable list of groups
   * @name application.components.group-list
   * @example
   * <group-list groups="yourData" all-groups="yourData" on-add="yourAddCallback" add-Enabled="booleanValue"
        add-help-template="templatePath" deleteEnabled="booleanValue" onDelete="yourDeleteCallback" drop-down-template="templatePath"/>
   */
  class GroupListController {
    static get $inject () {
      return ['$scope'];
    }

    constructor ($scope) {
      $scope.$on(Enums.BroadcastChannel.CLEAR, () => this.groupId = '');
    }

    getDeleteConfirmationMessage (groupName) {
      return `Are you sure you want to delete FC Group <strong>${groupName}</strong>? This action cannot be undone.`;
    }
  }

  angular.module('application.components')
    .component('groupList', {
      bindings: {
        addEnabled: '<',
        addHelpTemplate: '@',
        allGroups: '<',
        deleteEnabled: '<',
        dropDownTemplate: '@',
        groups: '<',
        onAdd: '&',
        onDelete: '&'
      },
      controller: GroupListController,
      templateUrl: 'templates/components/group-list.component.html'
    });
})();
