/* globals AbstractSourceBasedModel, Enums, TaskStatus */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'countFailed',
    'countRunning',
    'countSucceeded',
    'cutOffTime',
    'dataset',
    'displayStatus',
    'isMonitoredForDataDelays',
    'mostRecentStatus',
    'namespace',
    'scope',
    'triggers',
    'variant'
  ]);

  class DataIngestionWorkflow extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES);
      this.setDisplayStatus();
    }

    hasExecutions () {
      return (this.countSucceeded + this.countFailed + this.countRunning) > 0;
    }

    isSuccess () {
      return TaskStatus.isSuccess(this.mostRecentStatus);
    }

    isSuccessAfterFailure () {
      return TaskStatus.isSuccess(this.mostRecentStatus) && this.countFailed > 0;
    }

    // Determines if the workflow is isMonitoredForDataDelays OR isBeforeCutOffDate.
    isBeforeCutOff () {
      return !this.isMonitoredForDataDelays || this.cutOffTime.isBeforeCutOffDate;
    }

    setDisplayStatus () {
      let status = this.mostRecentStatus || Enums.TaskStatus.UNKNOWN;
      if (!this.hasExecutions()) {
        status = this.isBeforeCutOff() ? Enums.TaskStatus.NO_EXECUTIONS : Enums.TaskStatus.DELAYED;
      } else if (this.isSuccessAfterFailure()) {
        status = Enums.TaskStatus.SUCCESS_WITH_FAILURE;
      }
      this.displayStatus = _.startCase(_.lowerCase(status));
    }

    statusClass (prefix) {
      if (!this.hasExecutions()) {
        return TaskStatus.statusClass(this.isBeforeCutOff() ? Enums.TaskStatus.NO_EXECUTIONS : Enums.TaskStatus.DELAYED, prefix);
      }
      return TaskStatus.statusClass(this.mostRecentStatus, prefix);
    }
  }

  window.DataIngestionWorkflow = Object.freeze(DataIngestionWorkflow);
})();
