/* globals AbstractPackagerService, Configuration, DataCell, DataPackage, DataRow, Enums */
(function () {

  class NetworkPlannerEditsPackagerService extends AbstractPackagerService {

    static get $inject () {
      return ['$q'];
    }

    constructor ($q) {
      super($q);
    }

    _getRow (original, baseline, edits) {
      const originalCells = _.cloneDeep(original.cells).splice(Configuration.networkPlannerDateRange.actuals);
      const baselineCells = _.cloneDeep(_.head(baseline).cells).splice(Configuration.networkPlannerDateRange.actuals);
      const editCells = _.cloneDeep(_.head(edits).cells).splice(Configuration.networkPlannerDateRange.actuals);
      return _.map(originalCells, (cell, index) => {
        const value = cell.value * (editCells[index].value / baselineCells[index].value);
        return DataCell.create({ value: _.isFinite(value) ? value : 0 });
      });
    }

    _filterRecords (records, draft, groupBy) {
      return _(records)
        .filter((row) => row.granularity.draft === draft)
        .groupBy((row) => _.transform(groupBy, (grains, grain) => grains.push(row.granularity[grain]), [])).value();
    }

    _getRecords (dataPackage, originalDataPackage, plan) {
      const edits = this._filterRecords(dataPackage.records, Configuration.networkPlannerDrafts.EDITS, plan.groupBy);
      const baseline = this._filterRecords(dataPackage.records, Configuration.networkPlannerDrafts.BASELINE, plan.groupBy);
      return _.map(originalDataPackage.records, (row) => {
        const rowGroupBy = _.transform(plan.groupBy, (grains, grain) => grains.push(row.granularity[grain]), []);
        return DataRow.create({
          granularity: Object.assign({ [plan.splitBy]: dataPackage.filters[plan.splitBy] }, row.granularity),
          metric: row.metric,
          values: this._getRow(row, baseline[rowGroupBy], edits[rowGroupBy])
        });
      });
    }

    /**
     * Filters out non-edited rows from a list of dataPackages for the Network Planner
     *
     * @dataPackages DataPackagesList
     * @originalDataPackages Map<flow.planType.grainValue, DataPackagesList>
     * @editPlans Map from plan type to most recent PlanMetadata
     *
     * @returns Array (DataPackageList)
     */
    collect (dataPackages, originalDataPackages, editPlans) {
      const configuredPlans = _(Configuration.networkPlannerPlans).transform((plans, plan) => plans[plan.type] = plan, {}).cloneDeep();
      return _.map(dataPackages, (dataPackage) => {
        const splitByGrain = configuredPlans[dataPackage.plan.type].splitBy;
        const plan = Configuration.networkPlannerPlans.find((plan) => plan.type === dataPackage.plan.type);
        const originalDataPackage = originalDataPackages[plan.flow][plan.type][dataPackage.filters[splitByGrain]].Baseline;
        return DataPackage.create({
          dates: dataPackage.dates.splice(Configuration.networkPlannerDateRange.actuals),
          editType: Enums.DataPackage.EditType.UNIT,
          filters: dataPackage.filters,
          flow: dataPackage.flow,
          granularity: {
            grains: originalDataPackage.granularity.grains.clone()[_.camelCase(`add-${splitByGrain}Grain`)]()
          },
          groupBy: dataPackage.groupBy,
          metrics: dataPackage.metrics,
          plan: editPlans[dataPackage.plan.type],
          records: this._getRecords(dataPackage, originalDataPackage, configuredPlans[dataPackage.plan.type]),
          title: dataPackage.title,
          totals: [],
          type: _.isNil(editPlans[dataPackage.plan.type].version) ? Enums.DataPackage.PackageType.CREATE : Enums.DataPackage.PackageType.EDIT
        }, this.$q);
      });
    }
  }

  angular.module('application.services').service('networkPlannerEditsPackager', NetworkPlannerEditsPackagerService);
})();
