/* globals Enumeration, Enums, moment, TimeGranularity */
(function () {
  'use strict';

  const THURSDAY_AS_DAY_OF_WEEK = 4;
  const SATURDAY_AS_DAY_OF_WEEK = 6;
  const STANDARD_WEEKS_PER_YEAR = 52;

  function collectDates (collector, intersection, dateArray, indexStart, indexIncrement, countIncrement, isPrimary, isPrefix) {
    for (let index = indexStart; !_.includes(intersection, dateArray[index]); index += indexIncrement) {
      collector.count += countIncrement;
      if (isPrimary) {
        if (isPrefix) {
          collector.dates.push(dateArray[index]);
        } else {
          collector.dates.unshift(dateArray[index]);
        }
      }
    }
  }

  class DateUtils {
    /* Composes a date and a time part together to create a new moment
     *
     * @param date (optional) a Date object or non-ambiguous ISO date format string
     * @param time (optional) a Date object or non-ambiguous ISO time format string
     * @param timeZone (optional) a time zone string (e.g., "America/Los_Angeles")
     *
     * @return a new moment composed of the date and time together.
     */
    static compose (date, time, timeZone) {
      if (_.isNil(date)) {
        date = this.format(Enums.DateFormat.IsoDate, timeZone);
      }
      if (_.isNil(time)) {
        time = this.format(Enums.DateFormat.HourMinuteTime, timeZone);
      }
      return this.of(`${date} ${time}`, timeZone);
    }

    /* Determines the overlap between two arrays of date strings. Determines how many columns to remove (from the left or right)
     * and how many to add (to the left or right) with corresponding date values to be added.
     *
     * @param primaryArray: The primary array containing date strings to be used as a reference for comparison.
     * @param comparisonArray: The comparison array containing date strings to be compared to the reference Primary array.
     * @return an object with the attributes:
     *    prefix: Count and Dates of what needs to be added/removed at the beginning of the comparison array.
     *      count: Number of date strings that need to be added/removed at the beginning of the comparison array
     *      dates: Array of date strings that need to be added at the beginning of the comparison array. If count <= 0, dates array will be empty.
     *    postfix: Count and Dates of what needs to be added/removed at the end of the comparison array.
     *      count: Number of date strings that need to be added/removed at the end of the comparison array
     *      dates: Array of date strings that need to be added at the end of the comparison array. If count <= 0, dates array will be empty.
     *
     * Assumptions:
     *   date strings in both arrays are either daily dates or weekly dates
     *   date strings in both arrays are continuous dates(daily/weekly) with no missing dates/weeks
     *   date strings in both arrays are in the same order (older dates to newer dates)
     *
     * @return an object describing the necessary modifications to @comparisonArray.
     */
    static dateArrayComparator (primaryArray, comparisonArray) {
      let result = {
        postfix: { count: 0, dates: [] },
        prefix: { count: 0, dates: [] }
      };

      const intersectionArray = _.intersection(primaryArray, comparisonArray);
      if (intersectionArray.length === 0) {
        // No intersection means we need to empty the Comparison array and add all Primary date strings in it
        result = {
          postfix: { count: primaryArray.length, dates: primaryArray },
          prefix: { count: -comparisonArray.length, dates: [] }
        };
        return result;
      }

      // calculating the PREFIX section of the result
      // for PREFIX, arrays are scanned from index 0 to max index
      collectDates(result.prefix, intersectionArray, primaryArray, 0, 1, 1, true, true);
      if (result.prefix.count === 0) {
        collectDates(result.prefix, intersectionArray, comparisonArray, 0, 1, -1, false, true);
      }

      // calculating the POSTFIX section of the result
      // for POSTFIX, arrays are scanned from max index to index 0
      collectDates(result.postfix, intersectionArray, primaryArray, primaryArray.length - 1, -1, 1, true, false);
      if (result.postfix.count === 0) {
        collectDates(result.postfix, intersectionArray, comparisonArray, comparisonArray.length - 1, -1, -1, false, false);
      }

      return result;
    }

    /* Determines the difference between two dates in terms of @timeUnit.
     *
     * @param firstDate a Date object or non-ambiguous ISO date format string
     * @param secondDate a Date object or non-ambiguous ISO date format string
     * @param timeUnit the time granularity to calculate the difference Ex: 'weeks', 'days', 'hours'
     *
     * @return a Number that is the difference between @firstDate and @secondDate.
     */
    static difference (firstDate, secondDate, timeUnit) {
      return this.of(firstDate).diff(this.of(secondDate), timeUnit);
    }

    /* Determines the Unix epoch time (i.e. number of milliseconds that have elapsed since January 1, 1970).
     *
     * @param date (optional) a Date object or non-ambiguous ISO date format string
     *
     * @return the Unix epoch milliseconds for current time or provided date.
     */
    static epoch (date) {
      return this.of(date).valueOf();
    }

    /* Determines if two dates are equivalent in terms of @timeUnit.
     *
     * @param firstDate a Date object or non-ambiguous ISO date format string
     * @param secondDate a Date object or non-ambiguous ISO date format string
     * @param timeUnit the time granularity to calculate equivalence to Ex: 'weeks', 'days', 'hours'
     *
     * @return True if @firstDate and @secondDate are equivalent, false otherwise.
     */
    static equals (firstDate, secondDate, timeUnit) {
      return this.of(firstDate).isSame(this.of(secondDate), timeUnit);
    }

    /* Creates an array of dates spanning from @startDate to @endDate by the @timeGranularity provided.
     *
     * @param startDate a Date object or non-ambiguous ISO date format string
     * @param endDate a Date object or non-ambiguous ISO date format string
     * @param timeGranularity the time granularity to advance by between the dates ('daily' or 'weekly')
     *
     * @return an Array of ISO date time strings.
     */
    static expansion (startDate, endDate, timeGranularity) {
      const dates = [],
            current = this.of(startDate),
            end = this.of(endDate),
            unit = TimeGranularity.toTimeUnit(timeGranularity);

      if (!current.isValid() || !end.isValid()) {
        return [];
      }

      while (current.isSameOrBefore(end, Enums.TimeUnit.DAY)) {
        dates.push(current.format(Enums.DateFormat.IsoDate));
        current.add(1, unit);
      }

      return dates;
    }

    /* Converts a provided date string or object into a string of the provided format.
     * Note that moment defaults the format to ISO: [YYYY-MM-DDThh:mm:ssGMT offset]
     *
     * @param args can contain up to three arguments
     *   date: a Date object or non-ambiguous ISO date format string
     *   format: a member key or value from Enums.DateFormat
     *   timeZone: a time zone string (e.g., "America/Los_Angeles")
     *
     * @return a formatted date time string
     */
    static format (...args) {
      if (args.length > 3) {
        throw new Error('DateUtils: no more than three arguments accepted');
      }
      let date, format, timeZone;
      args.forEach((arg) => {
        if (Enumeration.isMemberValue(Enums.DateFormat, arg)) {
          format = arg;
        } else if (Enumeration.isMemberKey(Enums.DateFormat, arg)) {
          format = Enums.DateFormat[arg];
        } else if (_.isString(arg) && !_.isNil(moment.tz.zone(arg))) {
          timeZone = arg;
        } else if (!_.isNil(arg)) {
          date = arg;
        }
      });
      const instance = this.of(date, timeZone);
      // Because Amazon has it's own formula for calculating week numbers (https://w.amazon.com/bin/view/CalculatingAmazonWeekNumbers/)
      // Moment's format() method cannot be used for week numbers and should instead use IPT's.
      // https://sim.amazon.com/issues/SOP-10842
      switch (format) {
        case Enums.DateFormat.ISO8601:
          return instance.toISOString();
        case Enums.DateFormat.WkNumber:
          return `Wk ${this.toWeekNumber(instance)}`;
        case Enums.DateFormat.WeekNumber:
          return `Week ${this.toWeekNumber(instance)}`;
        default:
          return instance.format(format);
      }
    }

    /* Determines the date from @startDate by the number of days/weeks @offset.
     *
     * @param startDate a Date object or non-ambiguous ISO date format string
     * @param offset a whole number that represents the offset in days/weeks
     * @param timeUnit (optional) string that represents time unit i.e. day(d) or week(w). Defaults to week.
     *
     * @return an ISO date time String.
     */
    static fromOffset (startDate, offset, timeUnit = Enums.TimeUnit.WEEK) {
      const date = this.of(startDate);
      if (!date.isValid() || !_.isFinite(offset)) {
        return startDate;
      }
      return date.add(offset, timeUnit).format(Enums.DateFormat.IsoDate);
    }

    /* Determines the date based on week number.
     *
     * @param weekNumber a whole number that represents the week number of the year
     * @param date (optional) a Date object or non-ambiguous ISO date format string (defaults to current date)
     * @param startOfWeek (optional) a boolean that represents if the determined date (from week number) should be a Sunday date (defaults to true)
     *
     * @return the date from week number provided.
     */
    static fromWeekNumber (weekNumber, date, startOfWeek = true) {
      const dateFromWeekNumber = this.of(date).weekday(THURSDAY_AS_DAY_OF_WEEK).isoWeek(weekNumber);
      const result = startOfWeek ? this.toSunday(dateFromWeekNumber) : dateFromWeekNumber;
      return result.format(Enums.DateFormat.IsoDate);
    }

    /* Determines if @date is represented in @format.
     *
     * @param date a non-ambiguous date string
     * @param format String provided by the Enums.DateFormat
     *
     * @return true if the date is in the given format, false otherwise.
     */
    static isInFormat (date, format) {
      if (format === Enums.DateFormat.ISO8601 || !Enumeration.isMemberValue(Enums.DateFormat, format)) {
        throw new Error(`DateUtils: "${format}" is not a supported format`);
      }
      return !_.isNil(date) && !_.isNil(format) && moment(date, format, true).isValid();
    }

    /* Determines if the @targetDateTime is within the @startDateTime and @endDateTime, inclusive.
     *
     * @param startDateTime Date object or String representation of an ISO formatted beginning date of the range
     * @param endDateTime Date object or String representation of an ISO formatted ending date of the range
     * @param targetDateTime (optional) Date object or String representation of an ISO formatted date
                             to determine if it is within the range. Defaults to current date time
     * @param comparison String comparison mode to use (default is milliseconds)
     *
     * Assumptions:
     *   startDateTime is same or before endDateTime in chronology.
     *
     * @return true if the targetDateTime is within the range, false otherwise.
     */
    static isInRange (startDateTime, endDateTime, targetDateTime, comparison = Enums.TimeUnit.MILLISECONDS) {
      if (_.isNil(startDateTime) && _.isNil(endDateTime)) {
        // If both start and end are nil then the target is always considered to be within the range
        // as it indicates the range is undefined and hence all dates satisfy it.
        return true;
      }
      targetDateTime = _.isNil(targetDateTime) ? this.of() : this.of(targetDateTime);
      if (!_.isNil(startDateTime) && _.isNil(endDateTime)) {
        // Check if start is same or before target
        return this.of(startDateTime).isSameOrBefore(targetDateTime, comparison);
      }
      if (_.isNil(startDateTime) && !_.isNil(endDateTime)) {
        // Check if end is same or after target
        return this.of(endDateTime).isSameOrAfter(targetDateTime, comparison);
      }
      // Check if target is between start and end
      return targetDateTime.isBetween(this.of(startDateTime), this.of(endDateTime), comparison, '[]');
    }

    /* Determines if @date is today (the present date).
     *
     * @param date Date objects or non-ambiguous ISO date format string
     *
     * @return true if @date is today, false otherwise.
     */
    static isToday (date) {
      return this.equals(date, undefined, Enums.TimeUnit.DAY);
    }

    /* Determines if @date is a Saturday.
     *
     * @param date a Date object or non-ambiguous ISO date format string
     *
     * @return true if @date is Saturday, false otherwise.
     */
    static isSaturday (date) {
      return this.of(date).day() === SATURDAY_AS_DAY_OF_WEEK;
    }

    /* Returns the maximum (newest) date in the provided dates.
     *
     * @param dates Date objects or non-ambiguous ISO date format strings (can be in array)
     *
     * @return the maximum (newest) date in the list or undefined if no dates are provided.
     */
    static max (...dates) {
      if (_.isEmpty(dates)) {
        return;
      }
      return moment.max(_.flatten(dates).map((date) => this.of(date)));
    }

    /* Returns the minimum (oldest) date in the provided dates.
     *
     * @param dates Date objects or non-ambiguous ISO date format strings (can be in array)
     *
     * @return the minimum (oldest) date in the list or undefined if no dates are provided.
     */
    static min (...dates) {
      if (_.isEmpty(dates)) {
        return;
      }
      return moment.min(_.flatten(dates).map((date) => this.of(date)));
    }

    /* Converts @date into a moment object.
     *
     * @param args can contain up to two arguments
     *   date: a Date object or non-ambiguous ISO date format string
     *   timeZone: a time zone string (e.g., "America/Los_Angeles")
     *
     * @return the moment representation of date.
     *
     * Note: moment construction falls back to js Date(), which is not reliable across all browsers and versions.
     * Non RFC2822/ISO date formats are discouraged and will be removed in an upcoming major release.
     * Please refer to http://momentjs.com/guides/#/warnings/js-date/ for more info.
     */
    static of (...args) {
      if (args.length > 2) {
        throw new Error('DateUtils: no more than two arguments accepted');
      }
      let date, timeZone;
      args.forEach((arg) => {
        if (_.isString(arg) && !_.isNil(moment.tz.zone(arg))) {
          timeZone = arg;
        } else if (!_.isNil(arg)) {
          date = arg;
        }
      });
      return _.isString(timeZone) ? moment.tz(date, timeZone) : moment(date);
    }

    /* Determines the prior year date based on week number of the provided date.
     *
     * @param date a Date object or non-ambiguous ISO date format string
     * @param yearsBack (optional) a whole number that represents number of the years back  (defaults to 1)
     * @param startOfWeek (optional) a boolean that represents if the determined date should be a Sunday date (defaults to true)
     *
     * @return the prior year date based on week number of the provided date.
     */
    static priorYearDate (date, yearsBack = 1, startOfWeek = true) {
      if (yearsBack < 1) {
        throw new Error('DateUtils: a non-positive "yearsBack" value is not supported');
      }
      const priorYearDate = this.fromOffset(date, -1 * yearsBack, Enums.TimeUnit.YEAR);
      if (!startOfWeek) {
        return priorYearDate;
      }

      const WEEKS_DIFFERENCE_VALIDATION = Object.freeze({
        LOWER_BOUND: (STANDARD_WEEKS_PER_YEAR * yearsBack) - 3,
        UPPER_BOUND: (STANDARD_WEEKS_PER_YEAR * yearsBack) + 3
      });
      let priorYearSundayDate = this.fromWeekNumber(this.toWeekNumber(date), priorYearDate);
      const difference = this.difference(date, priorYearSundayDate, Enums.TimeUnit.WEEK);
      // Difference (in weeks) between the provided date and calculated 'priorYearSundayDate' is used to validate some edge cases.
      // For example: '2018-12-31' (Tuesday) even though belongs to the year 2018, is a part of week #1 of 2019. 2 years prior date from '2018-12-31' is '2016-12-31' (Saturday). '2016-12-31' belongs to the year 2016 and is a part of week #53 of 2016. This results in 'priorYearSundayDate' being miscalculated as the Sunday (startOfWeek = true) of week #1 of 2016 which is '2015-12-27'. That's approximately 3 years / 157 weeks prior date.
      // This is validated by making sure the difference is still within +/- 3 weeks of the expected approximately weeks difference based on 'yearsBack'.
      if (difference > WEEKS_DIFFERENCE_VALIDATION.UPPER_BOUND) {
        // 'priorYearDate' is adjusted by off setting it by +1 week
        priorYearSundayDate = this.fromWeekNumber(this.toWeekNumber(date), this.fromOffset(priorYearDate, 1, Enums.TimeUnit.WEEK));
      } else if (difference < WEEKS_DIFFERENCE_VALIDATION.LOWER_BOUND) {
        // 'priorYearDate' is adjusted by off setting it by -1 week
        priorYearSundayDate = this.fromWeekNumber(this.toWeekNumber(date), this.fromOffset(priorYearDate, -1, Enums.TimeUnit.WEEK));
      }
      return priorYearSundayDate;
    }

    /* Converts @date to the first Sunday prior Date object.
     *
     * @param date a Date object or non-ambiguous ISO date format string
     *
     * return moment object of the first Sunday prior to @date.
     */
    static toSunday (date) {
      return this.of(date).startOf(Enums.TimeUnit.WEEK);
    }

    /* Determines the week number of the year based on the provided @date.
     *
     * @param date (optional) a Date object or non-ambiguous ISO date format string
     *
     * @return the current week number or the week number for the provided @date
     */
    static toWeekNumber (date) {
      return this.of(date).weekday(THURSDAY_AS_DAY_OF_WEEK).isoWeek();
    }

    /* Converts @date into a moment object using UTC time.
     *
     * @param date a Date object or non-ambiguous ISO date format string
     *
     * @return a moment object that represents the date in UTC.
     */
    static utc (date) {
      return moment.utc(date);
    }
  }

  window.DateUtils = Object.freeze(DateUtils);
})();
