/* globals AbstractItemSelectorComponent, Configuration, Enums */
(function () {
  'use strict';
  const US = 'US';
  //https://issues.amazon.com/issues/SOP-17138
  const EXCLUDED_GROUPS = ['SSD', 'DC', 'Grocery DC'];

  const getOperationalPlanning = (type) => type === 'NetworkViewer' ? Enums.Plan.PlanType.DAILY_PLAN : type;

  const addAmazonNetworkGroup = (root) => {
    const excludedNodes = _(root.items)
      .filter((group) => Configuration.scopes.current().code === US && EXCLUDED_GROUPS.includes(group.id))
      .map('nodes').flatten().uniq().value();
    root.items.push({
      // "displayRank: 1" ensures "Amazon Network" precedes all other groups.
      displayRank: 1,
      id: 'Amazon Network',
      name: 'Amazon Network',
      nodes: _(root.items)
        .map('nodes').flatten().uniq()
        // This is a temporary solution to remove SSD,DC groups from Amazon Network [TT - V433312239]
        // The long term solution, to add an isEnabledForAmazonNetwork flag is being tracked here [SOP-12500]
        .filter((node) => !excludedNodes.includes(node)).value()
    });
    return root;
  };

  class WarehouseGroupItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', '$q', 'warehouses'];
    }

    constructor ($element, $q, warehouses) {
      super($element);
      this.$q = $q;
      this.warehouses = warehouses;
    }

    _loadData () {
      // If the configuration is not defined then do no further processing
      if (_.isEmpty(this.configuration)) {
        this.itemSelectorInterface = undefined;
        return;
      }

      this.itemSelectorInterface = {
        items: () => {
          const groupOptions = { asOfDate: this.configuration.date };
          const warehouseOptions = _.assign({ operationalCategory: getOperationalPlanning(this.configuration.type) }, groupOptions);
          if (Configuration.scopes.current().isComposite()) {
            // User is looking at an aggregate scope, show subscope names
            return this._scopesWithWarehouses(groupOptions, warehouseOptions);
          }
          // User is not looking at an aggregate scope, show FC group names
          return this._groupsWithWarehouses(groupOptions, warehouseOptions);
        },
        preferences: {
          hive: this.configuration.type,
          key: this.metricType
        }
      };
    }

    _getGroupNodes (groupId, warehouses) {
      return _.map(_.filter(warehouses, (warehouse) => _.includes(warehouse.groups, groupId)), 'warehouseId');
    }

    _getScopedGroupItem (scopeCode, group, warehouses, displayRank) {
      return {
        displayRank: displayRank,
        id: `${scopeCode} - ${group.groupId}`,
        name: `${Configuration.scopes.find(scopeCode).name} - ${group.groupId}`,
        nodes: group.isComposite ? _(group.subGroups).map((subGroupId) => this._getGroupNodes(subGroupId, warehouses)).flatten().uniq().value() : this._getGroupNodes(group.groupId, warehouses)
      };
    }

    _groupsWithWarehouses (groupOptions, warehouseOptions) {
      return this.$q.all([this.warehouses.groups(groupOptions), this.warehouses.warehouse().list(warehouseOptions)]).then((lists) => {
        const groups = lists[0];
        const warehouses = lists[1];
        const root = {
          id: 'All',
          items: _.filter(
            groups.map(
              (group) => ({
                // "displayRank: 2" ensures all composite groups follow "Amazon Network" and precede all non-composite groups.
                displayRank: group.isComposite ? 2 : undefined,
                id: group.groupId,
                name: group.groupId,
                nodes: group.isComposite ? _(group.subGroups).map((subGroupId) => this._getGroupNodes(subGroupId, warehouses)).flatten().uniq().value() : this._getGroupNodes(group.groupId, warehouses)
              })
            ),
            (group) => group.nodes.length > 0
          ),
          name: 'All'
        };

        addAmazonNetworkGroup(root);
        return [root];
      });
    }

    _scopesWithWarehouses (groupOptions, warehouseOptions) {
      // This method assumes the current scope is Composite.
      // Ideally, there should be a separate warehouse group item selector specific to Composite Scopes.
      // SIM: https://sim.amazon.com/issues/SOP-13683
      return this.$q.all({ groups: this.warehouses.groups(groupOptions), warehouses: this.warehouses.warehouse().list(warehouseOptions) }).then((warehouseData) => {
        const { groups, warehouses } = warehouseData;
        const groupedWarehouses = _.groupBy(warehouses, 'scope');
        // The first FC group selection will be all Networks within the Composite Scope.
        const amazonNetwork = {
          displayRank: 1,
          id: 'All',
          items: _.map(
            _.groupBy(warehouses, 'scope'),
            (warehouses, scopeCode) => ({
              id: scopeCode,
              name: Configuration.scopes.find(scopeCode).name,
              nodes: _.map(warehouses, 'warehouseId')
            })
          ),
          name: 'All'
        };
        addAmazonNetworkGroup(amazonNetwork);

        // The remaining FC group selections will be the individual FC groups crossed with all the Scopes, including the Composte Scope.
        const fcGroups = _.filter(groups.map((group) => {
          const items = _.map(groupedWarehouses, (scopedWarehouses, scopeCode) => this._getScopedGroupItem(scopeCode, group, scopedWarehouses));

          // Adding the Composite Scope as a Totals group; Also, "displayRank = 1" ensures the Composite Scope group precedes all other Scopes.
          items.push(this._getScopedGroupItem(Configuration.scopes.current().code, group, warehouses, 1));

          return {
            displayRank: group.isComposite ? 2 : undefined,
            id: group.groupId,
            items: _.filter(items, (scopeGroup) => scopeGroup.nodes.length > 0),
            name: group.groupId
          };
        }), (group) => group.items.length > 0);
        fcGroups.push(amazonNetwork);
        return fcGroups;
      });
    }

    $onInit () {
      super.$onInit();
      this.disableSearch = true;
    }
  }

  angular.module('application.components')
    .component('warehouseGroupItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        metricType: '<'
      }),
      controller: WarehouseGroupItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
