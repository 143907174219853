/* globals AbstractTransformerService, Configuration, DateUtils, Enumeration, Enums, Granularities, XLSX */
(function () {
  'use strict';
  // ====== Transformers: More Than Meets The Eye ======
  //                    /~@@~\,
  //  _______ . _\_\___/\ __ /\___|_|_ . _______
  // / ____  |=|      \  <_+>  /      |=|  ____ \
  // ~|    |\|=|======\\______//======|=|/|    |~
  //  |_   |    \      |      |      /    |    |
  //   \==-|     \     | ATOZ |     /     |----|~~)
  //   |   |      |    |      |    |      |____/~/
  //   |   |       \____\____/____/      /    / /
  //   |   |         {----------}       /____/ /
  //   |___|        /~~~~~~~~~~~~\     |_/~|_|/
  //    \_/        [/~~~~~||~~~~~\]     /__|\
  //    | |         |    ||||    |     (/|[[\)
  //    [_]        |     |  |     |
  //               |_____|  |_____|
  //               (_____)  (_____)
  //               |     |  |     |
  //               |     |  |     |
  //               |/~~~\|  |/~~~\|
  //               /|___|\  /|___|\
  //              <_______><_______>
  // ===================================================


  /* TODO: Refactor the following transformer, such that private helper methods shared by
   *       both this and the plan transformers are methods of the abstract transformer.
   *       For the time being, the following is functional and serves its purpose for UAT to begin.
   */
  const HEADER_KEYS = Enumeration.create('METRIC', 'NODE', 'PRODUCT_LINE', 'SORT_TYPE').asExactValue('Metric', 'FC', 'Product Line', 'Sort Type');
  const WORKBOOK_METADATA_DEFINITION = Object.freeze([
    AbstractTransformerService.metadataProperty('scope')
  ]);

  class ManualBacklogTransformerService extends AbstractTransformerService {
    static get $inject () {
      return ['alerts', '$authentication', '$q'];
    }

    constructor (alerts, $authentication, $q) {
      super(alerts, $q, WORKBOOK_METADATA_DEFINITION);
      this.$authentication = $authentication;
    }

    _accumulatePackageRows (sheet, workbookMetadata, valdationSets) {
      if (_.isEmpty(sheet)) {
        this.alerts.danger('The XLSX file contained no edits');
        return [];
      }

      const currentScope = Configuration.scopes.current().code;
      if (workbookMetadata.scope !== currentScope) {
        this.alerts.danger(`The file being uploaded is from scope "${workbookMetadata.scope}", whereas the current scope is "${currentScope}". Please try again after changing the scope to "${workbookMetadata.scope}" or upload the correct file.`);
        return [];
      }

      const pkg = {
        records: sheet.filter((row) => !_.isEmpty(this._extractRowDates(row))).reduce((records, row) =>
          records.concat(...this._extractRowDates(row).map((date) =>
            ({
              date: date,
              granularity: {
                node: row[HEADER_KEYS.NODE],
                productLine: row[HEADER_KEYS.PRODUCT_LINE],
                sortType: row[HEADER_KEYS.SORT_TYPE]
              },
              metricId: row[HEADER_KEYS.METRIC],
              value: row[date]
            }))),
        []),
        scope: currentScope,
        updatedBy: this.$authentication.profile().alias
      };
      pkg.records = this._mapNamesToIds(pkg.records, valdationSets.metrics, valdationSets.sortTypes);
      return [pkg];
    }

    _extractRowDates (row) {
      return Object.keys(row).filter((key) => DateUtils.isInFormat(key, Enums.DateFormat.IsoDate));
    }

    _extractValue (supplier, source, row) {
      if (_.isString(supplier)) {
        return supplier;
      }
      let value = source[supplier.key];
      if (_.has(supplier, 'serialize')) {
        value = supplier.serialize(value, source, row);
      }
      return value;
    }

    _generateHeaderRows (pkg, suppliers) {
      const headerRow = [];
      suppliers.forEach((supplier) => headerRow.push(this._extractValue(supplier, pkg)));
      return _.flatten(headerRow);
    }

    _generateBodyRows (pkg, suppliers) {
      const content = [];
      pkg.records.forEach((gridRow) => {
        const row = [];
        suppliers.forEach((supplier) => {
          if (supplier.source === 'pkg') {
            row.push(this._extractValue(supplier, pkg, gridRow));
          }
          if (supplier.source === 'row') {
            row.push(this._extractValue(supplier, gridRow));
          }
        });
        content.push(_.flatten(row));
      });
      return content;
    }

    _mapNamesToIds (records, metrics, sortTypes) {
      return records.map((record) => {
        _.set(record, 'metricId', _.get(_.find(metrics, (metric) => metric.displayName === record.metricId), 'id'));
        _.set(record, 'granularity.sortType', _.get(_.find(sortTypes, (sortType) => sortType.name === record.granularity.sortType), 'id'));
        return record;
      });
    }

    /* @Override
     * Converts dataPackages into a flat-workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToFlatWorkbook (packages, headerSuppliers, bodySuppliers, workbook = XLSX.utils.book_new()) {
      const worksheet = this.convertToWorksheet(packages, headerSuppliers, bodySuppliers);
      XLSX.utils.book_append_sheet(workbook, worksheet, Enums.XlsxSheet.DATA);
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a workbook, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorkbook (packages, headerSuppliers, bodySuppliers) {
      const workbook = XLSX.utils.book_new();
      const editPackages = _.cloneDeep(packages);

      editPackages.forEach((pkg) => {
        // Show only Edit rows in the 'Edits' tab
        pkg.records = _.filter(pkg.records, (record) => record.granularity.draft === Enums.DataPackage.RecordType.EDIT);
        // Hide the draft column for the edits sheet since all edits will be prefinal
        pkg.downloadGrainFilter = Enums.GrainFilter.IS_EDIT_TEMPLATE;
        pkg.granularity.grains.addMetricGrain(0);
      });

      XLSX.utils.book_append_sheet(workbook, this.convertToWorksheet(editPackages, headerSuppliers, bodySuppliers), Enums.XlsxSheet.EDITS);
      this.addWorkbookMetadata(workbook, _.first(editPackages));
      return workbook;
    }

    /* @Override
     * Converts dataPackages into a worksheet, structured according to the passed suppliers.
     *
     * @return a workbook object to be exported.
     */
    convertToWorksheet (packages, headerSuppliers, bodySuppliers) {
      const content = [];
      packages.forEach((pkg, index) => {
        // pkg.granularity references the granularity object defined in the controller.
        // If the metric grain is added it should not affect the granularity in the controller
        pkg.granularity = _.defaults({ grains: Granularities.create(pkg.granularity.grains.values()).addMetricGrain(0) }, pkg.granularity);
        if (index === 0) {
          content.push(this._generateHeaderRows(pkg, headerSuppliers));
        }
        content.push(...this._generateBodyRows(pkg, bodySuppliers));
      });
      return XLSX.utils.aoa_to_sheet(content, { dateNF: 'yyyy"-"mm"-"dd' });
    }

    /* @Override
     * Derives an array of packages from a workbook
     *
     * @param workbook the workbook to be converted into packages
     * @param validationSets an object containing all valid metrics, FCs, PLs, and STs
     * @return a list of packages that can be displayed as grids
     */
    toPackages (workbook, validationSets) {
      const workbookMetadata = this.extractWorkbookMetadata(workbook);
      if (_.isEmpty(workbookMetadata)) {
        return [];
      }
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[Enums.XlsxSheet.EDITS], { dateNF: 'yyyy"-"mm"-"dd' });
      return this._accumulatePackageRows(sheet, workbookMetadata, validationSets);
    }
  }

  angular.module('application.services').service('manualBacklogTransformer', ManualBacklogTransformerService);
})();
