'use strict';
/**
 * Directive to enable smart table search to trigger on ng-model changes
 *
 * @name application.directives.stModelSearch
 * @example
 * <input st-table st-model-search="$ctrl.property" ng-model="$ctrl.value"/>
 */
angular.module('application.directives')
  .directive('stModelSearch', ['$timeout', function ($timeout) {
    return {
      link: function (scope, element, attrs, requireList) {
        const stTable = requireList[0];
        const ngModel = requireList[1];
        let promise = null;

        function triggerSearch () {
          const value = ngModel.$modelValue;

          if (promise !== null) {
            $timeout.cancel(promise);
          }

          promise = $timeout(function () {
            stTable.search(value, attrs.stModelSearch || '');
            promise = null;
          }, attrs.stDelay);
        }

        scope.$watch(() => ngModel.$modelValue, triggerSearch);
      },
      require: ['^stTable', 'ngModel']
    };
  }]);
