/* globals Html */
'use strict';
/**
 * Enables direct DOM generation and appending to an element outside of the Angular context.
 *
 * Directive must be attached to a supported element (colgroup, thead, tbody, tfoot).
 *
 * @name application.directives.bindToDom
 * @example
 * <colgroup bind-to-dom bind-to="$ctrl.headerRows"></colgroup>
 */
angular.module('application.directives')
  .directive('bindToDom', function () {
    return {
      link: function ($scope, element, attrs) {
        const APPEND_BIND_METHOD = 'append',
              REPLACE_BIND_METHOD = 'replace';
        const BIND_METHODS = [APPEND_BIND_METHOD, REPLACE_BIND_METHOD];
        const BIND_TYPES_MAP = {
          colgroup: 'cols',
          tbody: 'trs',
          tfoot: 'trs',
          thead: 'trs'
        };
        Object.freeze(BIND_METHODS);
        Object.freeze(BIND_TYPES_MAP);

        let bindMethod = (element.attr(attrs.$attr.bindMethod) || REPLACE_BIND_METHOD).toLowerCase();
        const bindTo = element.attr(attrs.$attr.bindTo);
        const domGenerationFn = Html[BIND_TYPES_MAP[element.prop('tagName').toLowerCase()]];

        if (!_.includes(BIND_METHODS, bindMethod)) {
          bindMethod = REPLACE_BIND_METHOD;
        }

        if (!_.isEmpty(bindTo) && _.isFunction(domGenerationFn)) {
          $scope.$watch(bindTo, function (value) {
            const children = domGenerationFn(value);
            if (_.isEmpty(children)) {
              return;
            }
            if (bindMethod === REPLACE_BIND_METHOD) {
              // For the replace binding method, remove all contents and children of the element prior to appending new children.
              element.empty();
            }
            _.map(children, (child) => element.append(child));
          }, false);
        }
      },
      restrict: 'A',
      scope: false
    };
  });
