/* globals AbstractElementComponent, Configuration */
(function () {
  'use strict';
  class ScopeIconController extends AbstractElementComponent {
    _loadData () {
      this.scope = Configuration.scopes.find(this.scope) || Configuration.scopes.current();
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('scopeIcon', {
      bindings: {
        scope: '<',
        showName: '<'
      },
      controller: ScopeIconController,
      templateUrl: 'templates/components/scope-icon.component.html'
    });
})();
