/* globals AbstractElementComponent */
(function () {
  'use strict';

  /***
   * Binding in sub-component must contain:
   *
   *   require: {
   *     ngModel: '^ngModel'
   *   }
   */
  class AbstractBinaryElementComponent extends AbstractElementComponent {
    _setSelection (value) {
      this.selection = value;
      this.ngModel.$setViewValue(this.selection);
    }

    isDisabled () {
      const isDisabled = super.isDisabled();
      if (isDisabled && !_.isNil(this.disabledValue)) {
        this._setSelection(!!this.disabledValue);
      }
      return isDisabled;
    }

    onClick () {
      if (!this.isDisabled()) {
        this._setSelection(!this.selection);
      }
    }

    $onInit () {
      if (_.isNil(this.ngModel)) {
        throw new Error('AbstractBinaryElementComponent: ngModel controller binding must be defined in sub-component');
      }

      this.selection = false;
      this.ngModel.$render = () => this.selection = this.ngModel.$viewValue;
      super.$onInit();
    }
  }

  window.AbstractBinaryElementComponent = Object.freeze(AbstractBinaryElementComponent);
})();
