/* globals DateUtils, Enumeration, Enums */
(function () {
  'use strict';
  const VIEW_OPTION = Enumeration
    .create('ACTUALS', 'ACTUALS_FORECASTS', 'FORECASTS')
    .asStringValue((key) => _.startCase(key.toLowerCase()).replace(' ', ' + '));
  const FEATURE = Enumeration.create(
    'ACTUAL_COMPARISONS',
    'SUBTOTALS',
    'YEAR_OVER_YEAR'
  ).asStringValue();
  const UNDEFINED_STRING = 'Undefined';
  const SAVED_VIEW_PLAN_FIELDS = Object.freeze(['draft', 'scenario', 'subScenario', 'type']);

  class AbstractPlanViewerProviderService {
    get defaultComparisons () {
      throw new Error('AbstractPlanViewerProvider: defaultComparisons must be overwritten in a sub-class');
    }

    get defaultDatesBack () {
      throw new Error('AbstractPlanViewerProvider: defaultDatesBack must be overwritten in a sub-class');
    }

    get feature () {
      return FEATURE;
    }

    get stateDefinition () {
      throw new Error('AbstractPlanViewerProvider: stateDefinition must be overwritten in a sub-class');
    }

    get timeGrain () {
      throw new Error('AbstractPlanViewerProvider: timeGrain must be overwritten in a sub-class');
    }

    get timeUnit () {
      throw new Error('AbstractPlanViewerProvider: timeUnit must be overwritten in a sub-class');
    }

    get viewOption () {
      return VIEW_OPTION;
    }

    get savedViewPlanFields () {
      return SAVED_VIEW_PLAN_FIELDS;
    }

    _calculateActualsDateRangeLength (actualsEndDate, actualsStartDate) {
      return DateUtils.difference(actualsEndDate, actualsStartDate, this.timeUnit) + 1;
    }

    _calculateActualsMinStartDate (forecastDate, comparisonDatesBack) {
      return DateUtils.fromOffset(forecastDate, -1 * comparisonDatesBack, this.timeUnit);
    }

    _maintainActualsMovingWindow (scope, viewOption = scope.model.viewOptions.selected) {
      if (viewOption !== this.viewOption.ACTUALS) {
        return;
      }
      const windowLengthOffset = scope.settings.comparisonDatesBack - 1;
      scope.model.actuals.startDate.minDate = this._calculateActualsMinStartDate(scope.model.actuals.endDate.date, scope.settings.forecastDisplayLimit || windowLengthOffset);
      scope.model.actuals.endDate.maxDate = this.calculateActualsMaxEndDate(scope.model.actuals.startDate.date, scope.settings.forecastDisplayLimit - 1 || windowLengthOffset);
    }

    calculateActualsMaxEndDate (forecastDate, comparisonDatesBack) {
      // The maximum end date should be either the penultimate date of the forecast plan, or the latest date actuals are available.
      return DateUtils.min(
        DateUtils.fromOffset(forecastDate, comparisonDatesBack - 1, this.timeUnit),
        DateUtils.fromOffset(DateUtils.of().startOf(this.timeUnit), -1, this.timeUnit)
      ).format(Enums.DateFormat.IsoDate);
    }

    changeActualsEndDate (scope, date, viewOption) {
      if (date === scope.model.actuals.endDate.date) {
        return;
      }
      scope.model.actuals.endDate.date = date;
      scope.model.actuals.dateRangeLength = this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);

      // If actual comparisons are enabled, historic forecasts must be set too.
      if (scope.methods.isFeatureEnabled(this.feature.ACTUAL_COMPARISONS)) {
        this._setHistoricalForecastDate(scope);
      }
      this._maintainActualsMovingWindow(scope, viewOption);
    }

    changeActualsStartDate (scope, date, viewOption) {
      if (date === scope.model.actuals.startDate.date) {
        return;
      }
      scope.model.actuals.startDate.date = date;
      scope.model.actuals.datesBack = DateUtils.difference(scope.model.forecast.date, scope.model.actuals.startDate.date, this.timeUnit);
      scope.model.actuals.dateRangeLength = scope.model.actuals.datesBack === 0 ? 0 : this._calculateActualsDateRangeLength(scope.model.actuals.endDate.date, scope.model.actuals.startDate.date);
      this._maintainActualsMovingWindow(scope, viewOption);
    }

    configureMethods (controller) {
      const scope = controller.$scope;
      Object.assign(scope.methods, {
        changeActualsDateRange: (startDate, endDate) => {
          this.changeActualsStartDate(scope, startDate);
          this.changeActualsEndDate(scope, endDate);
        },
        changeComparisonDate: (comparison, date) => {
          if (controller.isSettingsLocked() || date === comparison.date) {
            return;
          }

          comparison.date = date;

          // Clear the comparison plan so it does not get interpreted as an initial plan by the selector.
          comparison.plan = undefined;
        },
        getForecastDisplayEndDate: () => _.has(scope.settings, 'forecastDisplayLimit')
          ? DateUtils.format(DateUtils.min(DateUtils.fromOffset(scope.model.forecast.plan.date, scope.settings.forecastDisplayLimit - 1, this.timeUnit), scope.model.forecast.plan.endDate), Enums.DateFormat.IsoDate)
          : scope.model.forecast.plan.endDate,
        getForecastDisplayStartDate: () => scope.settings.actuals ? scope.model.actuals.startDate.date : scope.model.forecast.plan.date,
        getPlanTypeDisplayName: (plan) => _.isNil(plan) ? UNDEFINED_STRING : _.startCase(plan.planType),
        isForecastComparePlanSelectable: (index) => scope.settings.forecastComparisons[index],
        isForecastCompareToggleDisabled: (index) => {
          if (scope.settings.forecastComparisons[index]) {
            return !_.isNil(scope.settings.forecastComparisons[index + 1]) && scope.settings.forecastComparisons[index + 1];
          }
          return !_.isNil(scope.settings.forecastComparisons[index - 1]) && !scope.settings.forecastComparisons[index - 1];
        },
        isValidForecastComparisonPlanSelection: () => _.every(
          scope.settings.forecastComparisons,
          (comparison, index) => !comparison || (!_.isNil(scope.model.forecastComparisons[index].plan) && scope.model.forecastComparisons[index].plan.isValid())
        ),
        isViewModeEnabled: () => {
          const isViewModeEnabled = scope.model.modes.data.selected === Enums.DataPackage.DataMode.UNIT && scope.model.modes.comparison.selected === Enums.DataPackage.ComparisonMode.UNIT;
          if (!isViewModeEnabled) {
            scope.model.modes.view.selected = Enums.DataPackage.ViewMode.GRID;
          }
          return isViewModeEnabled;
        },
        isViewOptionSelected: (viewOption) => viewOption === scope.model.viewOptions.selected
      });
    }
  }

  window.AbstractPlanViewerProviderService = Object.freeze(AbstractPlanViewerProviderService);
})();
