/* globals AbstractPackagerService, Comparison, DataPackage, DateUtils, Enums, Grain, Granularities, Grid, Name */
(function () {
  'use strict';
  const TITLE_DELIMATOR = ' / ';

  const constructFilters = (flatFilters) => _.reduce(flatFilters, (accumulator, filterObj) => {
    _.forEach(filterObj, (value, key) => {
      // Plan store does not support filtering by planning group or by business entity group (https://sim.amazon.com/issues/SOP-8006).
      // When constructing the filters do not add planning group or business entity group.
      if (key === Grain.known.planningGroup.id || key === Grain.known.businessEntityGroup.id) {
        return;
      }
      accumulator[key] = accumulator[key] || [];
      if (!_.includes(accumulator[key], value)) {
        accumulator[key].push(value);
      }
    });
    return accumulator;
  }, {});

  const fetchRecords = function (metric, configuration) {
    const body = {
            filters: constructFilters(configuration.allocations),
            groupBy: configuration.groupBy.values()
          },
          options = {
            draft: configuration.primary.plan.draft,
            flow: metric.id,
            version: configuration.primary.plan.version
          };
    return this.planStore.plan(configuration.primary.plan, body, options);
  };

  class DataPackager {
    constructor (dates, metric, configuration, $q) {
      this.$q = $q;
      this.groupBy = configuration.groupBy;
      this.allocations = configuration.allocations;
      this.editType = configuration.editType;
      this.plan = configuration.primary.plan;
      this.granularity = configuration.granularity;
      this.gridGrouping = configuration.gridGrouping;
      this.dates = dates;
      this.metric = metric;
    }

    transform (promise) {
      return promise
        .then(this.filter.bind(this))
        .then(this.addEditsRow.bind(this))
        .then(this.resize.bind(this))
        .then(this.addGranularity.bind(this))
        .then(this.concatenate.bind(this))
        .then(this.sort.bind(this))
        .then(this.separate.bind(this))
        .then(this.emit.bind(this));
    }

    filter (datasets) {
      datasets.records = _.map(this.allocations, (allocation) => {
        let matchingRecord = _.find(datasets.records, (record) => _.isEqual(allocation, record.granularity));
        if (_.isNil(matchingRecord)) {
          // If the plan does not return a record that the user selected in the allocation selector,
          // ensure an empty row shows up in the DataPackage.
          matchingRecord = {
            // Additional properties are added to the granularity object.
            // We need a new reference so the allocation object is not affected.
            granularity: _.clone(allocation),
            values: _.fill(Array(datasets.dates.length), 0)
          };
        }
        return matchingRecord;
      });
      return datasets;
    }

    addEditsRow (datasets) {
      datasets = {
        edits: _.cloneDeep(datasets),
        prefinal: datasets
      };
      datasets.edits.records = _.map(datasets.edits.records, (record) => {
        record.values = _.fill(record.values, null);
        return record;
      });
      return datasets;
    }

    resize (datasets) {
      const resize = (dateRange, plan) => {
        if (_.isNil(plan)) {
          return;
        }
        const result = DateUtils.dateArrayComparator(dateRange, plan.dates),
              options = {
                append: result.postfix.count,
                prepend: result.prefix.count,
                property: 'values'
              };
        Grid.resize(plan.records, options);
      };
      resize(this.dates, datasets.edits);
      resize(this.dates, datasets.prefinal);
      return datasets;
    }

    addGranularity (datasets) {
      const addGranularity = (datasets, dataType) => {
        _.forEach(datasets.records, (record) => {
          record.granularity.metric = Name.ofMetric(this.metric);
          record.granularity.draft = dataType;
          record.dataType = dataType;
          record.metric = this.metric;
        });
      };
      addGranularity(datasets.prefinal, Enums.DataPackage.RecordType.PREFINAL);
      addGranularity(datasets.edits, Enums.DataPackage.RecordType.EDITS);
      return datasets;
    }

    concatenate (datasets) {
      return _.concat(datasets.prefinal.records, datasets.edits.records);
    }

    sort (datasets) {
      return Comparison.sort(
        datasets,
        [Enums.DataPackage.RecordType.PREVIEW, Enums.DataPackage.RecordType.EDITS],
        this.groupBy.values(),
        [this.metric]
      );
    }

    separate (datasets) {
      return _.groupBy(datasets, (record) =>
        _.reduce(this.gridGrouping.values(), (accumulator, grain) => {
          accumulator.push(record.granularity[grain.id]);
          return accumulator;
        }, []).join(TITLE_DELIMATOR)
      );
    }

    emit (datasets) {
      const computeTotals = (records) => {
        const template = {
          granularity: {},
          metric: this.metric,
          values: Grid.totals(records, this.dates.length, { property: 'values' })
        };
        return [
          Enums.DataPackage.RecordType.PREFINAL,
          Enums.DataPackage.RecordType.PREVIEW,
          Enums.DataPackage.RecordType.DIFFERENCE
        ].map((dataType) => {
          const record = _.cloneDeep(template);
          record.dataType = dataType;
          record.granularity.draft = dataType;
          if (dataType === Enums.DataPackage.RecordType.DIFFERENCE) {
            record.values = _.fill(record.values, 0);
          }
          return record;
        });
      };

      return _.map(datasets, (records, datasetTitle) => DataPackage.create({
        dates: this.dates,
        editType: this.editType,
        filters: constructFilters(this.allocations),
        flow: this.metric,
        granularity: Object.assign(
          {},
          this.granularity,
          {
            grains: _.cloneDeep(this.groupBy).addDraftGrain(),
            totalsGrains: Granularities.create().addDraftGrain()
          }
        ),
        groupBy: this.groupBy.values(),
        metrics: [this.metric],
        plan: this.plan,
        records: records,
        title: [Name.ofMetric(this.metric), datasetTitle].join(TITLE_DELIMATOR),
        totals: computeTotals.call(this, records),
        type: Enums.DataPackage.PackageType.EDIT,
        viewGrainFilter: Enums.GrainFilter.IS_ARC
      }, this.$q));
    }
  }

  class WeeklyPlanPackagerService extends AbstractPackagerService {
    static get $inject () {
      return ['planStore', '$q'];
    }

    constructor (planStore, $q) {
      super($q);
      this.planStore = planStore;
    }

    collect (dates, metric, configuration) {
      const packager = new DataPackager(dates, metric, configuration, this.$q);
      return packager.transform(fetchRecords.call(this, metric, configuration));
    }
  }

  angular.module('application.services').service('weeklyPackager', WeeklyPlanPackagerService);
})();
