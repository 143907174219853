/* globals AbstractBinaryElementComponent */
(function () {
  'use strict';
  class CollapseSwitchController extends AbstractBinaryElementComponent {
    _isCollapsed () {
      return this.invertStates ? !this.selection : this.selection;
    }

    iconClass () {
      return this._isCollapsed() ? 'fa-chevron-right' : 'fa-chevron-down';
    }

    $onInit () {
      this.invertStates = !!this.invertStates;
      super.$onInit();
    }
  }

  angular.module('application.components')
    .component('collapseSwitch', {
      bindings: {
        // Value to set when the control is disabled
        disabledValue: '<',
        // Inverts the ngModel states so the model is expanded (true) or collapsed (false)
        invertStates: '<'
      },
      controller: CollapseSwitchController,
      require: {
        ngModel: '^ngModel'
      },
      templateUrl: 'templates/components/collapse-switch.component.html'
    });
})();
