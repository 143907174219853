/* globals Enumeration */
(function () {
  'use strict';
  const Enums = {};

  Enums.AlertImportance = Enumeration.create('INTERRUPT', 'NORMAL').asStringValue(_.toLower);

  Enums.AlertType = Enumeration.create('DANGER', 'INFO', 'SUCCESS', 'WARNING').asStringValue(_.toLower);

  Enums.AggregateType = Enumeration.create('SUBTOTAL', 'TOTALS').asStringValue(_.capitalize);

  Enums.BroadcastChannel = Enumeration.create('AUTO_REFRESH', 'CLEAR', 'RESET_COUNTDOWN').asStringValue(_.toLower);

  Enums.DataDelayCheckStatus = Enumeration.create('BEFORE_SLA', 'DELAY_RESOLVED', 'IN_DELAY', 'NO_DELAY').asStringValue();

  Enums.DataPackage = {};

  Enums.DataPackage.ComparisonMode = Enumeration.create('UNIT', 'PERCENT', 'DELTA').asStringValue(_.capitalize);

  Enums.DataPackage.DataMode = Enumeration.create('UNIT', 'PERCENT').asStringValue(_.capitalize);

  Enums.DataPackage.EditType = Enumeration.create('DELTA', 'RATIO', 'UNIT').asStringValue(_.capitalize);

  Enums.DataPackage.MetricDataType = Enumeration.create('DECIMAL', 'INTEGER', 'PERCENT').asStringValue(_.toLower);

  Enums.DataPackage.PackageType = Enumeration.create('COMPACT_PLAN', 'CREATE', 'EDIT', 'MANUAL_BACKLOG', 'NETWORK', 'PLAN').asStringValue();

  Enums.DataPackage.RecordType = Enumeration
    .create('CUMULATIVE', 'DIFFERENCE', 'EDITS', 'PREFINAL', 'PREVIEW')
    .asExactValue('cumulative', 'Difference', 'Edits', 'Prefinal', 'Preview');

  Enums.DataPackage.ViewMode = Enumeration.create('GRID', 'CHART').asStringValue(_.capitalize);

  Enums.DataType = Enumeration
    .create('ACTUAL', 'COMPARISON', 'PRIMARY', 'PYoY', 'PPYoY')
    .asExactValue('actual', 'comparison', 'primary', 'actualsPYoY', 'actualsPPYoY');

  Enums.DateFormat = Enumeration
    .create(
      'GmtOffsetDateTime',
      'TimeZoneAbbrDateTime',
      'HourMinuteTime',
      'ISO8601',
      'IsoDate',
      'IsoDateTime',
      'WeekNumber',
      'WkNumber',
      'XlsxDateNF'
    )
    .asExactValue(
      'YYYY-MM-DD HH:mm:ss [GMT]Z',
      'YYYY-MM-DD HH:mm:ss z',
      'HH:mm',
      // This is a special value that is matched in DateUtils.format() to call the .toISOString() method on a Date/moment object
      // https://momentjs.com/docs/#/displaying/as-iso-string/
      '[ISO8601]',
      'YYYY-MM-DD',
      'YYYY-MM-DD HH:mm:ss',
      '[Week] w',
      '[Wk] w',
      'YYYY-MM-DD"T"hh:mm:ss'
    );

  Enums.DisplayWidth = Enumeration
    .create(
      'SMALL',
      'MEDIUM_SMALL',
      'MEDIUM',
      'MEDIUM_LARGE',
      'LARGE',
      'XLARGE'
    )
    .asStringValue((key) => _.toLower(_.kebabCase(key)));

  Enums.DocumentFormat = Enumeration.create('CSV', 'FlatXLSX', 'XLSX', 'ZIP').asStringValue();

  Enums.GrainFilter = Enumeration
    .create('IS_EDIT_TEMPLATE', 'IS_ARC', 'IS_EDITABLE', 'IS_ENTITY_GRAIN', 'IS_METRIC_GRAIN', 'IS_NATIVE', 'IS_UPLOADABLE', 'IS_VIEW_EDIT')
    .asStringValue(_.camelCase);

  Enums.GrainIdentity = Enumeration
    .create(
      'binType',
      'binTypeGroup',
      'businessChannel',
      'businessEntity',
      'businessEntityGroup',
      'businessUnit',
      'customerType',
      'daysSupply',
      'draft',
      'entity',
      'lag',
      'metric',
      'networkCapacityGroup',
      'networkInventoryGroup',
      'networkInventoryGroupNetworkPlanning',
      'node',
      'nodeGroup',
      'nodeRegion',
      'origin',
      'pharmacySite',
      'planningGroup',
      'prescriptionType',
      'processType',
      'productLine',
      'productLineGroup',
      'productLineOwner',
      'scope',
      'scopeGroup',
      'secondaryScope',
      'secondaryScopeGroup',
      'secondaryNode',
      'secondaryNodeGroup',
      'secondaryNodeRegion',
      'sortCenter',
      'sortType',
      'subTeam',
      'subMetric',
      'usageType',
      'windowType',
      'feederType'
    )
    .asStringValue();

  Enums.ModelMutability = Enumeration.create('IMMUTABLE', 'MUTABLE').asStringValue();

  Enums.ModalSize = Enumeration.create('SMALL', 'LARGE', 'EXTRA_LARGE').asExactValue('sm', 'lg', 'xl');

  Enums.NodeGroupings = Enumeration.create('nodeRegion').asExactValue('FC Region');

  Enums.Plan = {};

  Enums.Plan.DisplayFormat = Enumeration.create('FULL', 'FULL_WITH_TIMESTAMP', 'LONG', 'SHORT', 'DRAFT', 'SCENARIO', 'VERSION').asStringValue();

  Enums.Plan.DraftType = Enumeration.create('BASELINE', 'PRE_FINAL', 'FINAL').asStringValue((key) => _.toLower(_.camelCase(key)).concat('Draft'));

  Enums.Plan.PlanType = Enumeration
    .create(
      'ACU_BT_IG_FC_WEEKLY_PLAN',
      'ACU_PL_ST_WEEKLY_PLAN',
      'ALLOCATED_CAPACITY_PLO_NIG_WEEKLY_PLAN',
      'ALPS_DAILY_PLAN',
      'BIAS_CORRECTED_PLO_NIG_BTG_WEEKLY_PLAN',
      'BIN_TYPE_FC_WEEKLY_PLAN',
      'BSF_FC_PL_ST_BT_WEEKLY_PLAN',
      'BSF_PL_ST_BT_WEEKLY_PLAN',
      'BSF_PLO_NIG_BTG_WEEKLY_PLAN',
      'CAP_SIGNAL_BIN_TYPE_FC_WEEKLY_PLAN',
      'CAP_SIGNAL_PL_ST_FC_WEEKLY_PLAN',
      'CAPACITY_BT_NIG_FC_WEEKLY_PLAN',
      'CAPACITY_BT_NIG_PLO_FC_WEEKLY_PLAN',
      'CAPACITY_BT_UT_FC_WEEKLY_PLAN',
      'CAPACITY_NCG_NIG_RATIO_WEEKLY_PLAN',
      'CONSTRAINT_IG_BTG_WEEKLY_PLAN',
      'CSC_ORIGIN_LAG_NIG_WEEKLY_PLAN',
      'DAILY_ORDER_PLAN',
      'DAILY_PLAN',
      'FILL_RATE_ORIGIN_LAG_NIG_WEEKLY_PLAN',
      'FLEXIBLE_GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN',
      'GAS_TANK_FC_BT_IG_WEEKLY_PLAN',
      'GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN',
      'ITR_BT_IG_FC_WEEKLY_PLAN',
      'LABOR_PLAN',
      'NETWORK_PL_PLAN',
      'NETWORK_PLAN',
      'PHARMACY_WEEKLY_PLAN',
      'PL_ST_FC_WEEKLY_PLAN',
      'PRE_NETWORK_NVF_PL_ST_WEEKLY_PLAN',
      'SHIPMENT_ARRIVALS_ORIGIN_LAG_NIG_WEEKLY_PLAN',
      'SORT_CENTER_SC_DAILY_PLAN',
      'TOTAL_FBA_NVF_NIG_WEEKLY_PLAN',
      'UNCONSTRAINED_BIAS_CORRECTED_PLO_NIG_BTG_WEEKLY_PLAN',
      'UNCONSTRAINED_GAS_TANK_PLO_NIG_BTG_WEEKLY_PLAN',
      'UNCONSTRAINED_NETWORK_PLAN'
    )
    .asStringValue(_.camelCase);

  Enums.Plan.Scenario = Enumeration.create('PROD', 'TEST').asStringValue(_.toLower);

  Enums.PlanDisplayOverrides = Enumeration.create('DECIMAL_PLACES').asStringValue(_.camelCase);

  Enums.PlanViewerId = Enumeration.create('dailyPlanViewer', 'weeklyPlanViewer').asStringValue();

  Enums.ShortcutType = Enumeration.create('SHAREABLE_URL', 'USER_VIEW').asStringValue(_.camelCase);

  Enums.StepFunctionStatus = Enumeration.create(
    'FAILED',
    'RUNNING',
    'SUCCEEDED'
  ).asStringValue();

  Enums.TaskStatus = Enumeration.create(
    'ABORTED',
    'DELAYED',
    'FAILED',
    'FAILURE',
    'IN_PROGRESS',
    'NO_EXECUTIONS',
    'RUNNING',
    'SCHEDULED',
    'SKIPPED',
    'SUCCEEDED',
    'SUCCESS',
    'SUCCESS_WITH_FAILURE',
    'TIMED_OUT',
    'UNKNOWN',
    'WAITING_FOR_DEPENDENCIES',
    'WAITING_FOR_RESOURCES'
  ).asStringValue();

  Enums.TimeGranularity = Enumeration.create('DAILY', 'WEEKLY').asStringValue();

  Enums.TimeUnit = Enumeration.create('MILLISECONDS', 'SECOND', 'MINUTE', 'HOUR', 'DAY', 'WEEK', 'MONTH', 'YEAR').asStringValue(_.toLower);

  Enums.UsageMetrics = Enumeration.create(
    'alias',
    'download',
    'errorMessage',
    'failure',
    'latency',
    'publish',
    'save',
    'scope',
    'submit',
    'success'
  ).asStringValue();

  Enums.UserAction = Enumeration.create('DOWNLOAD', 'VIEW').asStringValue(_.capitalize);

  Enums.WarehousePlanType = Enumeration
    .create('CAPACITY_PLAN', 'DAILY_PLAN', 'NETWORK_PLAN', 'WEEKLY_PLAN')
    .asExactValue('gasTankPloNigBtgWeeklyPlan', 'dailyPlan', 'networkPlan', 'plStFcWeeklyPlan');

  Enums.XlsxSheet = Enumeration.create('DATA', 'METADATA', 'EDITS', 'GRAINS', 'DEFAULT')
    .asExactValue(
      'Data',
      'Metadata',
      'Edits',
      'Grains',
      'Sheet1'
    );

  window.Enums = Object.freeze(Enums);
})();
