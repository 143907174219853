/* globals AbstractServiceEndpoint, Configuration, Enums, GrainDefinition, GrainMapping, GrainMappingRatio, PlanDefinition, ProductLine */
(function () {
  'use strict';
  class PlanDefinitionRequests extends AbstractServiceEndpoint {
    constructor (request) {
      super(request, Configuration.services.planConfigurationStore);
    }

    get (planType, asOfDate) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('plan-definition', planType)
        .withParams({ asOfDate })
        .get()
        .then((data) => PlanDefinition.create(data));
    }

    list (asOfDate) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('plan-definition')
        .withParams({ asOfDate })
        .get()
        .then((data) => data.map(PlanDefinition.create));
    }
  }

  class ProductLineRequests extends AbstractServiceEndpoint {
    constructor (request, productLine) {
      super(request, Configuration.services.planConfigurationStore);
      this.productLine = productLine;
    }

    add (options) {
      return this.aws()
        .for('product-line')
        .withParams(options)
        .withBody(this.productLine.source)
        .post()
        .then((productLine) => ProductLine.create(productLine));
    }

    delete (options) {
      return this.aws()
        .for('product-line')
        .withParams(options)
        .withBody(this.productLine.source)
        .delete()
        .then((productLine) => ProductLine.create(productLine));
    }

    list (options, filters = {}) {
      return this.aws()
        .withScope()
        .for('product-lines')
        .withParams(options)
        .withBody(filters)
        .post()
        .then((productLines) => _.map(productLines, (productLine) => ProductLine.create(productLine)));
    }

    update (options) {
      return this.aws()
        .for('product-line')
        .withParams(options)
        .withBody(this.productLine.source)
        .put()
        .then((productLine) => ProductLine.create(productLine));
    }

    upsert (options) {
      return this.productLine.isExisting() ? this.update(options) : this.add(options);
    }
  }

  class GrainMappingRatioRequest extends AbstractServiceEndpoint {
    constructor (request, type) {
      super(request, Configuration.services.planConfigurationStore);
      this.type = type;
    }

    list (options) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('group-ratio', this.type)
        .withParams(options)
        .withBody()
        .post()
        .then((groupRatios) => _.map(groupRatios, (ratio) => GrainMappingRatio.create(ratio)));
    }

    update (ratioGroups) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('group-ratio', this.type)
        .withBody(ratioGroups, { doNotFilter: true })
        .put();
    }

    create (ratioGroups) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('group-ratio', this.type, 'create')
        .withBody(ratioGroups, { doNotFilter: true })
        .post();
    }

    delete (ratioGroups) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('group-ratio', this.type)
        .withBody(ratioGroups, { doNotFilter: true })
        .delete();
    }
  }

  class GrainMappingRequest extends AbstractServiceEndpoint {
    constructor (request, type) {
      super(request, Configuration.services.planConfigurationStore);
      this.type = type;
    }

    list (options) {
      return this.aws()
        .withScope()
        .for('groups', this.type)
        .withParams(options)
        .get()
        .then((response) => GrainMapping.create(response));
    }

    update (groups) {
      const body = _.reduce(groups, (result, group) => _.set(result, group.name, group.grainMappings), {});
      return this.aws()
        .withScope()
        .for('groups', this.type)
        .withBody(body)
        .put();
    }

    delete (groups) {
      const body = _.reduce(groups, (result, group) => _.set(result, group.name, group.grainMappings), {});
      return this.aws()
        .withScope()
        .for('groups', this.type)
        .withBody(body)
        .withParams({ deleteEmpty: false }, {})
        .delete();
    }
  }

  class PlanConfigurationStoreService extends AbstractServiceEndpoint {
    static get $inject () {
      return ['request'];
    }

    constructor (request) {
      super(request, Configuration.services.planConfigurationStore);
    }

    binTypeGroup () {
      return new GrainMappingRequest(this.request, Enums.GrainIdentity.binTypeGroup);
    }

    // This name does not follow the existing pattern of non-abbreviated
    // grain ids as nodeBinTypeUsageTypeToNetworkCapacityGroupRatio() is
    // too long.
    fcBtUtToNcgRatio () {
      return new GrainMappingRatioRequest(this.request, 'fcBtUtToNcgRatio');
    }

    grainDefinition (grainType, asOfDate) {
      return this.aws()
        .withVersion('v2/scope')
        .withScope()
        .for('grain-definition', grainType)
        .withParams({ asOfDate })
        .get()
        .then((data) => GrainDefinition.create(data));
    }

    networkInventoryGroup () {
      return new GrainMappingRequest(this.request, Enums.GrainIdentity.networkInventoryGroup);
    }

    planDefinition () {
      return new PlanDefinitionRequests(this.request);
    }

    planningGroup () {
      return new GrainMappingRequest(this.request, Enums.GrainIdentity.planningGroup);
    }

    productLine (productLine) {
      return new ProductLineRequests(this.request, productLine);
    }
  }
  angular.module('application.services').service('planConfiguration', PlanConfigurationStoreService);
})();
