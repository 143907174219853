/* globals AbstractDataViewController, DateUtils, Enums */
(function () {
  'use strict';

  const DEFAULT_ACTUALS_DAYS_BACK = -8,
        DAYS_BACK_TO_MIN_DATE = -91;

  class GenericNetworkViewerController extends AbstractDataViewController {
    static get $inject () {
      return ['$scope', 'overlay', 'metricsService'];
    }

    constructor ($scope, overlay, metricsService) {
      super($scope);
      this.overlay = overlay;
      this.metricsService = metricsService;
      this.$onInit();
    }

    _setActualsDates () {
      const primaryDate = this.$scope.model.primary.date;
      this.$scope.model.actuals.date = DateUtils.fromOffset(primaryDate, DEFAULT_ACTUALS_DAYS_BACK, Enums.TimeUnit.DAY);
      this.$scope.model.actuals.maxDate = primaryDate;
      this.$scope.model.actuals.minDate = DateUtils.fromOffset(primaryDate, DAYS_BACK_TO_MIN_DATE, Enums.TimeUnit.DAY);
    }

    isSettingsValid () {
      return !_.isEmpty(this.$scope.model.metricFamilies) && !_.isEmpty(this.$scope.model.filters.sortCenter);
    }

    submit () {
      // If the settings are not in a valid configuration for submitting then no action should be performed
      if (!this.isSettingsValid()) {
        return;
      }
      this.overlay.show('Fetching metrics');
    }

    $onInit () {
      const TODAY = DateUtils.format(Enums.DateFormat.IsoDate);

      super.$onInit(['PLAN_SELECTION', 'FILTER_SELECTION'], {
        'model.actuals': () => ({}),
        'model.actuals.date': () => DateUtils.fromOffset(TODAY, DEFAULT_ACTUALS_DAYS_BACK, Enums.TimeUnit.DAY),
        'model.actuals.maxDate': () => TODAY,
        'model.actuals.minDate': () => DateUtils.fromOffset(TODAY, DAYS_BACK_TO_MIN_DATE, Enums.TimeUnit.DAY),
        'model.configuration': () => ({}),
        'model.metricFamilyGroup': () => 'Outbound',
        'model.primary': () => ({}),
        'settings.actuals': () => true
      });

      this.$scope.methods.onPrimaryDateSelectionChange = (selectedDate) => {
        if (this.$scope.model.primary.date !== selectedDate) {
          this.$scope.model.primary.date = selectedDate;
          this.$scope.model.primary.lastUpdateByTimeInISO = DateUtils.compose(selectedDate).toISOString();
        }
        this._setActualsDates();

        if (!_.isNil(selectedDate) && this.$scope.model.configuration.date !== selectedDate) {
          this.$scope.model.configuration = {
            date: selectedDate,
            type: this.metricsService.NETWORK_VIEWER_SORT_CENTER_NAMESPACE
          };
        }
      };
    }
  }
  angular.module('application.controllers').controller('GenericNetworkViewerController', GenericNetworkViewerController);
})();
