(function () {
  'use strict';
  // XLSX sheet names must be less than 31 characters. The other 3 characters are reserved for the sheet's index in the workbook
  const MAX_SHEET_NAME_LENGTH = 28;
  const ORDINALS = Object.freeze(['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth', 'ninth', 'tenth']);

  class Name {
    /* Converts provided ordinal string to a cardinal value.
     *
     * @param ordinal a case-insensitive ordinal string based on which a cardinal value is returned.
     *
     * return ordinal as is if ordinal represents value > 10.
     * return cardinal value if ordinal represents value <= 10.
     */
    static ofCardinal (ordinal) {
      if (!_.isString(ordinal) || !ORDINALS.includes(ordinal.toLowerCase())) {
        return ordinal;
      }
      return ORDINALS.indexOf(ordinal.toLowerCase());
    }

    static ofIdentity (object) {
      if (_.isNil(object)) {
        return object;
      }
      // The name in descending order of preference: name, id, self
      return object.name || object.id || object;
    }

    static ofMetric (object, parentObject) {
      if (_.isNil(object)) {
        return object;
      }
      if (_.isString(object)) {
        return _.startCase(object);
      }
      if (!_.isNil(object) && !_.isNil(parentObject)) {
        const parentName = this.ofMetricFamily(parentObject);
        const metricName = this.ofMetric(object);
        return parentName === metricName ? parentName : `${parentName} / ${metricName}`;
      }
      if (_.has(object, 'displayName')) {
        return object.displayName;
      }
      if (_.has(object, 'name')) {
        return object.name;
      }
      if (_.has(object, 'id')) {
        return _.startCase(object.id);
      }
    }

    static ofMetricFamily (object) {
      return this.ofMetric(object);
    }

    static ofMetricFamilyGroup (object) {
      return this.ofMetric(object);
    }

    /* Converts provided number to an ordinal string.
     *
     * @param value a cardinal value based on ordinal value is returned
     * @param asNumeric (optional) boolean that represents if ordinal output should be in '1st' or 'first' format
     *
     * return value as is if value is non-finite or negative number.
     * return non-numeric ordinal if value <= 10 and 'asNumeric' is false or not provided.
     * return numeric ordinal if value > 10 or 'asNumeric' is true.
     */
    static ofOrdinal (value, asNumeric) {
      if (!_.isFinite(value) || (_.isFinite(value) && value < 0)) {
        return value;
      }
      if (asNumeric || value > 10) {
        const index = _.inRange(value % 100, 11, 14) ? 0 : value % 10;
        return value + ORDINALS[index].slice(-2);
      }
      return ORDINALS[value];
    }

    static ofSheet (title, suffix) {
      if (_.isNil(title)) {
        return title;
      }
      if (_.isString(title) && _.isNil(suffix)) {
        return _.sop.removeIllegalFileSystemChars(title);
      }
      const trimmedTitle = title.substring(0, MAX_SHEET_NAME_LENGTH).trim();
      return _.sop.removeIllegalFileSystemChars(`${trimmedTitle}~${suffix}`);
    }

    static ofTask (task) {
      if (_.isNil(task)) {
        return task;
      }
      if (_.has(task, 'taskName')) {
        return task.taskName;
      }
    }
  }

  window.Name = Object.freeze(Name);
})();
