/* globals Blob, Enums, File, Filename, FileReader, saveAs, XLSX */
(function () {
  'use strict';
  class TraderService {
    static get $inject () {
      return ['$q'];
    }

    constructor ($q) {
      this.$q = $q;
      this.extension = {
        CSV: {
          contentType: 'text/csv;charset=utf-8',
          convert: (worksheet, conversionOptions) => _.isString(worksheet) ? worksheet : XLSX.utils.sheet_to_csv(worksheet, conversionOptions),
          fileType: 'CSV'
        },
        XLSX: {
          contentType: 'application/octet-stream',
          convert: (workbook, conversionOptions) => XLSX.write(workbook, Object.assign(conversionOptions, { bookType: 'xlsx', type: 'array' })),
          fileType: 'XLSX'
        },
        ZIP: {
          contentType: 'application/octet-stream',
          convert: _.identity,
          fileType: 'ZIP'
        }
      };
    }

    /* Turns a workbook into a blob object and starts the download.
     *
     * @param workbook a workbook object
     * @param fileName the name of the file (without extension)
     * @param extension the extension/format of the file
     *
     * Assumptions:
     *   workbook is a valid Workbook or Worksheet object
     *   filename is a valid filename string
     *   extension is defined in @this.extension hash
     *   conversionOptions any XLSX parsing options https://www.npmjs.com/package/xlsx#parsing-options
     */
    download (workbook, filename, extension, conversionOptions = {}) {
      if (!_.includes(_.values(this.extension), extension)) {
        throw new Error(`Trader: unknown download extension provided: "${extension}"`);
      }
      if (!(filename instanceof Filename)) {
        throw new Error('Trader: filename must be an instance of Filename');
      }
      saveAs(
        new Blob([extension.convert(workbook, conversionOptions)], { type: extension.contentType }),
        filename.withExtension(extension.fileType).toString()
      );
    }

    /* Maps a UI format string to an extenstion type object.
     *
     * @param format the format string.
     * @return the extension type object that maps from the UI format string.
     */
    toExtensionType (format) {
      switch (format) {
        case Enums.DocumentFormat.CSV:
          return this.extension.CSV;
        case Enums.DocumentFormat.FlatXLSX:
          return this.extension.XLSX;
        case Enums.DocumentFormat.XLSX:
          return this.extension.XLSX;
        case Enums.DocumentFormat.ZIP:
          return this.extension.ZIP;
      }
      throw new Error(`Trader: format is not supported: "${format}"`);
    }

    /* Turns an input file into an XLSX workbook.
     *
     * @param file the file to be turned into a workbook. Must be a .xlsx file
     */
    upload (file) {
      if (!(file instanceof File)) {
        throw new Error('Trader: input must be of type "File"');
      }
      const deferred = this.$q.defer();
      const reader = new FileReader();
      reader.onload = (e) => deferred.resolve(XLSX.read(e.target.result, { cellDates: true, cellText: false, type: 'array' }));
      reader.readAsArrayBuffer(file);
      return deferred.promise;
    }
  }

  angular.module('application.services').service('trader', TraderService);
})();
