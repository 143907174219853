/* globals AbstractItemSelectorComponent, Binding, Granularities */
(function () {
  'use strict';
  const PREFERENCES_KEY = 'granularities';

  class GrainGroupBySelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'planConfiguration', 'preferences'];
    }

    constructor ($element, planConfiguration, preferences) {
      super($element);
      this.planConfiguration = planConfiguration;
      this.preferences = preferences;
    }

    _loadData () {
      // If the configuration is not defined then do no further processing
      if (_.isEmpty(this.configuration)) {
        return;
      }

      this.interface = {
        hive: this.configuration.type,
        key: PREFERENCES_KEY
      };
      this.allGranularities = Granularities.create();
      this.availableGranularities = Granularities.create();
      this.preferences.get(this.preferences.USER, this.configuration.type, PREFERENCES_KEY)
        .then((preferences) => {
          if (_.isNil(this.initialSelections) && !_.isNil(preferences)) {
            this.initialSelections = Granularities.create(preferences.selections);
          }
        })
        .finally(() => {
          this.planConfiguration.planDefinition().get(this.configuration.type, this.configuration.date).then((data) => {
            this.availableGranularities.clear();
            this.planGrain = data.planGranularityName;
            this.timeGrain = data.timeGranularityName;
            this.allGranularities = data.planGranularity.clone();
            this.selectedGranularities = data.planGranularity.clone();
            this._updateInitialSelections();
          });
        });
    }

    _updateInitialSelections () {
      if (_.isNil(this.initialSelections)) {
        this._selectionChanged();
        return;
      }

      // Remove aggregateGroupByGrains from the initialSelections to ensure parity with internal representation of selectedGranularities
      const initialSelections = this.initialSelections.clone().remove(this.aggregateGroupByGrains);

      if (!this.selectedGranularities.equals(initialSelections)) {
        this.selectedGranularities = initialSelections;
        this.availableGranularities = this.allGranularities.clone().remove(this.selectedGranularities);
        this._selectionChanged();
      }
    }

    _selectionChanged () {
      this.onSelectionChange({
        allGrains: this.allGranularities,
        // Prepend aggregateGroupByGrains to the selectedGranularities
        grains: this.selectedGranularities.clone().add(0, this.aggregateGroupByGrains),
        planGrain: this.planGrain,
        timeGrain: this.timeGrain
      });
    }

    moveAll (from, to) {
      to.granularities = to.granularities.concat(from.granularities);
      from.clear();
      this._selectionChanged();
    }

    onSort () {
      this._selectionChanged();
    }

    swap (grain) {
      // Angular-sortable-view does not correctly handle programmatic changes to the underlying list it is tracking,
      // but it does correctly handle the entire list being replaced with a new list.
      if (this.availableGranularities.hasGrain(grain)) {
        this.availableGranularities.removeGrain(grain);
        this.selectedGranularities.granularities = this.selectedGranularities.granularities.concat([grain]);
      } else {
        // Grain is in the selectedGranularities
        this.selectedGranularities.removeGrain(grain);
        this.availableGranularities.granularities = this.availableGranularities.granularities.concat([grain]);
      }
      this._selectionChanged();
    }

    $onChanges (changes) {
      if (!this.state.isInitialized()) {
        return;
      }

      if (Binding.changes.has(changes.configuration)) {
        this._loadData();
      } else if (Binding.changes.has(changes.initialSelections)) {
        this._updateInitialSelections();
      }
    }
  }

  angular.module('application.components')
    .component('grainGroupBySelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        /*
         * @aggregateGroupByGrains (Optional) Granularities object that specifies any table partitioning grains.
         * These grains are prepended (in the order provided) to selectedGranularities.
         */
        aggregateGroupByGrains: '<'
      }),
      controller: GrainGroupBySelectorController,
      controllerAs: 'selectorCtrl',
      templateUrl: 'templates/components/grain-group-by-selector.component.html'
    });
})();
