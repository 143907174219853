(function () {
  'use strict';

  class GrainMappingRatioGroup {
    constructor (granularity, ratioValue, ratioConfig) {
      if (!_.isNil(granularity)) {
        this.sourceGrains = granularity.sourceGrain;
      } else {
        this.sourceGrains = {};
        this._setEmptySourceGrains(ratioConfig);
      }
      this.ratioValues = [];
      if (!_.isNil(ratioValue)) {
        this.addRatio(ratioValue, granularity.destinationGrain);
      }
    }

    _setEmptySourceGrains (ratioConfig) {
      ratioConfig.sourceGrain.forEach((grain) => this.sourceGrains[grain] = '');
    }

    static create (granularity, ratioValue, ratioConfig) {
      return new GrainMappingRatioGroup(granularity, ratioValue, ratioConfig);
    }

    addRatio (ratioValue, destinationGrain) {
      // If the ratio is already present, set it to the provided value.
      const foundRatioValue = this.findRatio(destinationGrain);
      if (!_.isNil(foundRatioValue)) {
        foundRatioValue.ratio = ratioValue;
        return;
      }
      // Otherwise add the ratio.
      this.ratioValues.push({
        destinationKey: destinationGrain,
        ratio: ratioValue
      });
    }

    findRatio (destinationGrain) {
      return this.ratioValues.find((ratioValue) => _.isEqual(ratioValue.destinationKey, destinationGrain));
    }

    removeRatio (destinationGrain) {
      _.remove(this.ratioValues, (ratioValue) => _.isEqual(ratioValue.destinationKey, destinationGrain));
    }
  }
  window.GrainMappingRatioGroup = Object.freeze(GrainMappingRatioGroup);
})();
