/* globals DateUtils, Validate */
(function () {
  'use strict';
  class CloudWatchLogEvent {
    constructor (message, timestamp = DateUtils.epoch().toString()) {
      if (!_.isString(message) || Validate.isBlank(message)) {
        throw new Error(`CloudWatchLogEvent: message must be a non-blank string: "${message}"`);
      }

      if (!_.isString(timestamp)) {
        throw new Error(`CloudWatchLogEvent: timestamp must be a string: "${timestamp}"`);
      }

      this.message = message;
      this.timestamp = timestamp;
    }

    static create (message, timestamp) {
      return new CloudWatchLogEvent(message, timestamp);
    }
  }

  window.CloudWatchLogEvent = Object.freeze(CloudWatchLogEvent);
})();
