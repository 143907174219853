/* globals AbstractElementComponent, Ready */
(function () {
  'use strict';

  class MostRecentLoginsController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$authentication', 'userProfile'];
    }

    constructor (alerts, $authentication, userProfile) {
      super();
      this.alerts = alerts;
      this.$authentication = $authentication;
      this.userProfile = userProfile;

      this.state = Ready.create({
        isDataEmptyFn: () => !_.isUndefined(this.recentLogins) && _.isEmpty(this.recentLogins),
        isLoadingFn: () => this.loading
      });
    }

    _fetchRecentLogins (options) {
      this.loading = true;
      this.userProfile.getRecentLogins(options)
        .then((recentLogins) => this.recentLogins = recentLogins)
        .catch((error) => this.alerts.danger({ details: error, message: `Unable to load recent logins for user '${this.alias}'. Please check error details before retrying.` }))
        .finally(() => this.loading = false);
    }

    _loadData () {
      this.recentLogins = undefined;
      if (this.alias !== this.$authentication.profile().alias) {
        // Make the userProfile.getRecentLogins() call right away if alias does not match the authenticated user alias.
        this._fetchRecentLogins({ alias: this.alias });
        return;
      }
      // Check for user profile update in progress and add a listener if alias matches the authenticated user alias.
      if (!this.userProfile.isProfileUpdateInProgress()) {
        this._fetchRecentLogins();
      }
      this.userProfile.$onProfileUpdate(() => this._fetchRecentLogins());
    }

    $onChanges () {
      if (!this.state.isInitialized()) {
        return;
      }
      this._loadData();
    }
  }

  angular.module('application.components')
    .component('recentLogins', {
      bindings: {
        alias: '<'
      },
      controller: MostRecentLoginsController,
      templateUrl: 'templates/components/recent-logins.component.html'
    });
})();
