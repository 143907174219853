(function () {
  'use strict';

  class GrainMappingGroupModalController {
    addMapping () {
      // First the mapping is added the list of mappings and then the
      // new values are reset to their default values.
      this.grainMappingGroup.addMapping(this.newGrainValues.grainA, this.newGrainValues.grainB);
      this._resetGrainValues();
    }

    removeMapping (grainA, grainB) {
      this.grainMappingGroup.removeMapping(grainA, grainB);
      this._resetGrainValues();
    }

    _resetGrainValues () {
      this.newGrainValues.grainA = '';
      this.newGrainValues.grainB = '';
    }

    isValidMapping () {
      return !this.grainMappingGroup.hasMapping(this.newGrainValues.grainA, this.newGrainValues.grainB) &&
        _.includes(this.allowedGrainValues.grainA, this.newGrainValues.grainA) &&
        _.includes(this.allowedGrainValues.grainB, this.newGrainValues.grainB);
    }

    isValidGrainMappingGroup () {
      return !_.isEqual(this.initialGrainMappingGroup, this.grainMappingGroup);
    }

    $onInit () {
      this.allowedGrainValues = this.resolve.allowedGrainValues;
      this.grainMappingGroup = this.resolve.grainMappingGroup;
      this.isCreate = this.resolve.isCreate;
      this.type = this.resolve.type;

      this.initialGrainMappingGroup = _.cloneDeep(this.resolve.grainMappingGroup);
      this.newGrainValues = {
        grainA: '',
        grainB: ''
      };
    }

    onSubmit () {
      this.modalInstance.close(this.grainMappingGroup);
    }
  }

  /**
   * Component for the grain mapping group modals.
   * @name application.components.grain-mapping-group-modal
   */
  angular.module('application.components')
    .component('grainMappingGroupModal', {
      bindings: {
        /*
         * @modalInstance is an instance of $uibModal (ui.bootstrap.modal)
         * which is a service to create modal windows.
         */
        modalInstance: '<',
        /*
         * @resolve is expected to be an object with four properties:
         *    allowedGrainValues - Object containing allowed grain value lists.
         *    grainMappingGroup - The base grain mapping group object to be viewed and augmented.
         *    isCreate - Boolean to determine if the scenario is create or edit.
         *.   type - String to determine what type of grain mapping group is being viewed.
         */
        resolve: '<'
      },
      controller: GrainMappingGroupModalController,
      controllerAs: 'modalCtrl',
      templateUrl: 'templates/components/grain-mapping-group-modal.component.html'
    });
})();
