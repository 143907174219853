/* globals Enums, Ready */
(function () {
  'use strict';

  class PlanOrchestrationController {
    static get $inject () {
      return ['alerts', 'orchestrator', '$scope'];
    }

    constructor (alerts, orchestrator, $scope) {
      this.alerts = alerts;
      this.orchestrator = orchestrator;
      this.$scope = $scope;

      $scope.model = {
        execution: null,
        planningWeekDate: undefined
      };

      $scope.state = Ready.create({
        isDataEmptyFn: () => _.isEmpty($scope.model.execution),
        isLoadingFn: () => _.isNil($scope.model.execution)
      });

      $scope.actions = {
        onDateSelection: (newPlanningWeekDate) => {
          if ($scope.model.planningWeekDate !== newPlanningWeekDate) {
            $scope.model.planningWeekDate = newPlanningWeekDate;
            this._loadData();
          }
        }
      };
    }

    _loadData () {
      this.$scope.model.execution = null;
      this.orchestrator
        .execution(this.$scope.model.planningWeekDate, Enums.Plan.Scenario.PROD)
        .then((data) => this.$scope.model.execution = data)
        .catch((error) => {
          this.$scope.model.execution = {};
          this.alerts.error(error);
        });
    }
  }
  angular.module('application.controllers').controller('PlanOrchestrationController', PlanOrchestrationController);
})();
