/* globals AbstractItemSelectorComponent, Binding */
(function () {
  'use strict';
  class MetricItemSelectorController extends AbstractItemSelectorComponent {
    static get $inject () {
      return ['$element', 'metricsService'];
    }

    constructor ($element, metricsService) {
      super(
        $element,
        { isWaitingFn: () => _.isEmpty(this.configuration) },
        (changes) => !Binding.changes.is(changes.metricFamilyGroup, _.isNil)
      );

      this.metricsService = metricsService;
    }

    _define (promise, hive, key) {
      return {
        items: () => promise,
        preferences: { hive, key }
      };
    }

    _loadData () {
      if (this.state.isWaiting()) {
        this.itemSelectorInterface = undefined;
        return;
      }

      switch (this.variant) {
        case 'category-selection':
          this.itemSelectorInterface = this._define(this._metricCategoriesByFamily(), this.configuration.type, `metricCategories.${this.metricFamilyGroup}`);
          break;
        case 'family-selection':
          this.itemSelectorInterface = this._define(this._metricFamiliesByGroups(), this.configuration.type, 'metricFamilies');
          break;
        case 'manual-backlog-selection':
          this.itemSelectorInterface = this._define(this._metricsByGroups(), this.configuration.type, 'metrics');
          break;
        case 'metric-selection':
          this.itemSelectorInterface = this._define(this._metricsByGroups());
          break;
      }
    }

    _metricCategoriesByFamily () {
      return this.metricsService.metricCategoriesByFamily(this.configuration.type, this.configuration.date, this.metricFamilyGroup);
    }

    _metricFamiliesByGroups () {
      return this.metricsService.metricFamiliesByGroups(this.configuration.type, this.configuration.date);
    }

    _metricsByGroups () {
      return this.metricsService.metricsByGroups(this.configuration.type, this.configuration.date, this.filterFn);
    }
  }

  angular.module('application.components')
    .component('metricItemSelector', {
      bindings: AbstractItemSelectorComponent.bindings({
        filterFn: '<',
        metricFamilyGroup: '<',
        variant: '@'
      }),
      controller: MetricItemSelectorController,
      templateUrl: AbstractItemSelectorComponent.templateUrl
    });
})();
