/* globals AbstractPhonetoolComponent */
(function () {
  'use strict';

  class PhonetoolController extends AbstractPhonetoolComponent {
    isPopoverEnabled () {
      return !this.disablePopover;
    }
  }

  angular.module('application.components')
    .component('phonetool', {
      bindings: AbstractPhonetoolComponent.bindings({
        /*
         * @disablePopover (Optional) When true, the popover effect on the user alias link is disabled.
         */
        disablePopover: '<'
      }),
      controller: PhonetoolController,
      templateUrl: 'templates/components/phonetool.component.html'
    });
})();
