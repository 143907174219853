/* globals AbstractElementComponent, Enums, File */
(function () {
  'use strict';
  class FileSelectorController extends AbstractElementComponent {
    static get $inject () {
      return ['alerts', '$element', '$scope'];
    }

    constructor (alerts, $element, $scope) {
      super($element);
      this.alerts = alerts;
      this.$scope = $scope;
    }

    _isFileExtensionSupported (fileName) {
      if (_.isString(this.acceptedExtensions)) {
        return fileName.toLowerCase().endsWith(this.acceptedExtensions.toLowerCase());
      }
      if (Array.isArray(this.acceptedExtensions)) {
        return this.acceptedExtensions.some((accepted) => fileName.toLowerCase().endsWith(accepted.toLowerCase()));
      }
      return false;
    }

    computeClass (size = 'small') {
      return `drop-box-${size}`;
    }

    fileSelectionHandler (files) {
      // While files is a list, it will only ever have one element for this usecase.
      // This is only because of the <input type="file"> element returns files as a FileList object.
      // Since the file selector component does not use the "multiple" attribute, users can only select one file.
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
      const file = _.head(files);
      if (file instanceof File) {
        if (this._isFileExtensionSupported(file.name)) {
          this.onFileSelected({ file });
          if (this.fileInformationDisplay) {
            this.fileSelected = file;
          }
        } else {
          this.alerts.warning(`Filetype does not match: ${this.acceptedExtensions}`, Enums.AlertImportance.INTERRUPT);
        }
      }
    }

    fileCancelledHandler () {
      delete this.model.files;
      delete this.fileSelected;
      this.onFileCancelled();
    }

    $onInit () {
      this.$scope.$on(Enums.BroadcastChannel.CLEAR, () => delete this.fileSelected);
    }

    showDropZone () {
      return !this.fileInformationDisplay || _.isNil(this.fileSelected);
    }

    showSelectedFile () {
      return this.fileInformationDisplay && this.fileSelected instanceof File;
    }
  }

  angular.module('application.components')
    .component('fileSelector', {
      bindings: {
        /*
         * @acceptedExtensions Either a string or string list of accepted file extensions.
         */
        acceptedExtensions: '<',
        /*
         * @fileInformationDisplay When true, additional file details will be displayed in the selector.
         */
        fileInformationDisplay: '<',
        /*
         * @onFileCancelled is a callback to the parent when a selected file has been cancelled.
         */
        onFileCancelled: '&',
        /*
         * @onFileSelected is a callback to the parent when a file has been successfully selected.
         */
        onFileSelected: '&',
        /*
         * @size (Optional) The display size of the file selector.
         */
        size: '@?'
      },
      controller: FileSelectorController,
      templateUrl: 'templates/components/file-selector.component.html'
    });
})();
