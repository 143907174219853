/* globals AbstractSourceBasedModel, DailyFlashMetricValidator, Enums */
(function () {
  'use strict';

  const PROPERTIES = Object.freeze([
    'approver',
    'failedValidators',
    'isValidated',
    'name',
    'rootCause',
    'value',
    'valueDisplayType'
  ]);

  class DailyFlashMetric extends AbstractSourceBasedModel {
    constructor (source) {
      super(source, PROPERTIES, Enums.ModelMutability.MUTABLE);
      this.failedValidators = this.failedValidators.map((failedValidator) => DailyFlashMetricValidator.create(failedValidator));
    }

    validate (approver, rootCause) {
      this.source.approver = approver;
      this.source.rootCause = rootCause;
      this.source.isValidated = true;
      this.applySource();
    }

    invalidate () {
      this.source.approver = '';
      this.source.rootCause = '';
      this.source.isValidated = false;
      this.applySource();
    }
  }

  window.DailyFlashMetric = Object.freeze(DailyFlashMetric);
})();
