/* globals Configuration, moment */
(function () {
  'use strict';

  const STORAGE_SCOPE_PREFERENCE_KEY = 'configuration.scope';

  class Tenant {
    constructor (id, name, code, displayRank) {
      this.id = id;
      this.name = name;
      this.code = code;
      this.displayRank = displayRank;
    }

    static create (id, name, code, displayRank) {
      return new Tenant(id, name, code, displayRank);
    }

    is (tenant) {
      if (tenant instanceof Tenant) {
        return tenant === this;
      }
      if (!_.isString(tenant)) {
        return false;
      }
      return tenant === this.id;
    }
  }

  // IPT Tenants
  const SANDOP = Tenant.create('SANDOP', 'S&amp;OP', 'SP', 1);
  const CAPACITY = Tenant.create('CAPACITY', 'Capacity', 'CP', 2);
  const PHARMACY = Tenant.create('PHARMACY', 'Pharmacy', 'PP', 3);
  const SSD = Tenant.create('SSD', 'SSD', 'SSD', 4);
  const SORT_CENTER = Tenant.create('SORT_CENTER', 'Sort Center', 'SC', 5);

  const TENANT = Object.freeze({ CAPACITY, PHARMACY, SANDOP, SORT_CENTER, SSD });

  class Tenants {
    /**
     * Get the list of all IPT Tenants sorted by their display rank.
     */
    static all () {
      return _.sortBy(TENANT, ['displayRank']);
    }

    /**
     * Get the Tenant object identified by @scopeCode.
     */
    static find (tenant) {
      if (tenant instanceof Tenant) {
        return tenant;
      }
      if (!_.isString(tenant)) {
        return;
      }
      return _.find(TENANT, (iptTenant) => iptTenant.is(tenant));
    }
  }

  class Scope {
    constructor (code, portalCode, tenant, name, timeZone, icon, subscopes) {
      this.code = code.toUpperCase();
      this.portalCode = portalCode.toUpperCase();
      this.tenant = tenant;
      this.name = name;
      this.icon = icon;
      this.subscopes = subscopes;
      this.timeZone = timeZone;
      _.forEach(this.subscopes, (subscope) => subscope.parent = this);
    }

    static create (code, portalCode, tenant, name, timeZone, icon, subscopes) {
      return new Scope(code, portalCode, tenant, name, timeZone, icon, subscopes);
    }

    get region () {
      return this.parent || this;
    }

    contains (code) {
      let result = this.portalCode === code;
      if (!result && Array.isArray(this.subscopes)) {
        result = _.some(this.subscopes, { portalCode: code });
      }
      return result;
    }

    is (scopeCode) {
      if (scopeCode instanceof Scope) {
        return scopeCode === this;
      }
      if (!_.isString(scopeCode)) {
        return false;
      }
      return scopeCode.toUpperCase() === this.portalCode;
    }

    isAny (scopeCodes) {
      return Array.isArray(scopeCodes) && _.some(scopeCodes, this.is.bind(this));
    }

    isComposite () {
      return !_.isEmpty(this.subscopes);
    }

    isOfTenant (tenant) {
      return this.tenant.is(tenant);
    }

    isOfTenantCapacity () {
      return this.tenant.is(TENANT.CAPACITY);
    }

    isOfTenantPharmacy () {
      return this.tenant.is(TENANT.PHARMACY);
    }

    isOfTenantSandop () {
      return this.tenant.is(TENANT.SANDOP);
    }

    isOfTenantSSD () {
      return this.tenant.is(TENANT.SSD);
    }

    isOfTenantSortCenter () {
      return this.tenant.is(TENANT.SORT_CENTER);
    }
  }

  // Application initial scope
  // This is a non-selectable scope and is not registered to any environment.
  // Antarctica/Troll is TZ +00
  const ANTARCTICA = Scope.create('AQ', 'AQ', undefined, 'Antarctica', 'Antarctica/Troll', '🇦🇶');
  // S&OP - Regular Scopes
  const AE = Scope.create('AE', 'AE', TENANT.SANDOP, 'United Arab Emirates', 'Asia/Dubai', '🇦🇪');
  const AU = Scope.create('AU', 'AU', TENANT.SANDOP, 'Australia', 'Australia/Melbourne', '🇦🇺');
  const BR = Scope.create('BR', 'BR', TENANT.SANDOP, 'Brazil', 'America/Sao_Paulo', '🇧🇷');
  const CA = Scope.create('CA', 'CA', TENANT.SANDOP, 'Canada', 'America/Vancouver', '🇨🇦');
  const CL = Scope.create('CL', 'CL', TENANT.SANDOP, 'Chile', 'America/Santiago', '🇨🇱');
  const CO = Scope.create('CO', 'CO', TENANT.SANDOP, 'Colombia', 'America/Bogota', '🇨🇴');
  const DE = Scope.create('DE', 'DE', TENANT.SANDOP, 'Germany', 'Europe/Berlin', '🇩🇪');
  const EG = Scope.create('EG', 'EG', TENANT.SANDOP, 'Egypt', 'Africa/Cairo', '🇪🇬');
  const ES = Scope.create('ES', 'ES', TENANT.SANDOP, 'Spain', 'Europe/Madrid', '🇪🇸');
  const FR = Scope.create('FR', 'FR', TENANT.SANDOP, 'France', 'Europe/Paris', '🇫🇷');
  const GB = Scope.create('GB', 'GB', TENANT.SANDOP, 'United Kingdom', 'Europe/London', '🇬🇧');
  const IN = Scope.create('IN', 'IN', TENANT.SANDOP, 'India', 'Asia/Kolkata', '🇮🇳');
  const IT = Scope.create('IT', 'IT', TENANT.SANDOP, 'Italy', 'Europe/Rome', '🇮🇹');
  const JP = Scope.create('JP', 'JP', TENANT.SANDOP, 'Japan', 'Asia/Tokyo', '🇯🇵');
  const MX = Scope.create('MX', 'MX', TENANT.SANDOP, 'Mexico', 'America/Mexico_City', '🇲🇽');
  const NG = Scope.create('NG', 'NG', TENANT.SANDOP, 'Nigeria', 'Africa/Lagos', '🇳🇬');
  const NL = Scope.create('NL', 'NL', TENANT.SANDOP, 'Netherlands', 'Europe/Amsterdam', '🇳🇱');
  const PL = Scope.create('PL', 'PL', TENANT.SANDOP, 'Poland', 'Europe/Warsaw', '🇵🇱');
  const SA = Scope.create('SA', 'SA', TENANT.SANDOP, 'Kingdom of Saudi Arabia', 'Asia/Riyadh', '🇸🇦');
  const SE = Scope.create('SE', 'SE', TENANT.SANDOP, 'Sweden', 'Europe/Paris', '🇸🇪');
  const SG = Scope.create('SG', 'SG', TENANT.SANDOP, 'Singapore', 'Asia/Singapore', '🇸🇬');
  const TR = Scope.create('TR', 'TR', TENANT.SANDOP, 'Turkey', 'Europe/Istanbul', '🇹🇷');
  const US = Scope.create('US', 'US', TENANT.SANDOP, 'United States of America', 'America/Los_Angeles', '🇺🇸');
  const ZA = Scope.create('ZA', 'ZA', TENANT.SANDOP, 'South Africa', 'Africa/Johannesburg', '🇿🇦');
  // S&OP - Composite Scopes
  const EU = Scope.create('EU', 'EU', TENANT.SANDOP, 'Europe', 'Europe/Berlin', '🇪🇺', [DE, ES, FR, IT, NL, PL, SE]);
  const NA = Scope.create('NA', 'NA', TENANT.SANDOP, 'North America', 'America/Los_Angeles', '🏳️‍', [CA, MX, US]);
  // Capacity - Regular Scopes
  const CAPACITY_AE = Scope.create('AE', 'CAPACITY_AE', TENANT.CAPACITY, 'United Arab Emirates', 'Asia/Dubai', '🇦🇪');
  const CAPACITY_AU = Scope.create('AU', 'CAPACITY_AU', TENANT.CAPACITY, 'Australia', 'Australia/Melbourne', '🇦🇺');
  const CAPACITY_BR = Scope.create('BR', 'CAPACITY_BR', TENANT.CAPACITY, 'Brazil', 'America/Sao_Paulo', '🇧🇷');
  const CAPACITY_CA = Scope.create('CA', 'CAPACITY_CA', TENANT.CAPACITY, 'Canada', 'America/Vancouver', '🇨🇦');
  const CAPACITY_CL = Scope.create('CL', 'CAPACITY_CL', TENANT.CAPACITY, 'Chile', 'America/Santiago', '🇨🇱');
  const CAPACITY_CO = Scope.create('CO', 'CAPACITY_CO', TENANT.CAPACITY, 'Colombia', 'America/Bogota', '🇨🇴');
  const CAPACITY_DE = Scope.create('DE', 'CAPACITY_DE', TENANT.CAPACITY, 'Germany', 'Europe/Berlin', '🇩🇪');
  const CAPACITY_EG = Scope.create('EG', 'CAPACITY_EG', TENANT.CAPACITY, 'Egypt', 'Africa/Cairo', '🇪🇬');
  const CAPACITY_ES = Scope.create('ES', 'CAPACITY_ES', TENANT.CAPACITY, 'Spain', 'Europe/Madrid', '🇪🇸');
  const CAPACITY_FR = Scope.create('FR', 'CAPACITY_FR', TENANT.CAPACITY, 'France', 'Europe/Paris', '🇫🇷');
  const CAPACITY_GB = Scope.create('GB', 'CAPACITY_GB', TENANT.CAPACITY, 'United Kingdom', 'Europe/London', '🇬🇧');
  const CAPACITY_IN = Scope.create('IN', 'CAPACITY_IN', TENANT.CAPACITY, 'India', 'Asia/Kolkata', '🇮🇳');
  const CAPACITY_IT = Scope.create('IT', 'CAPACITY_IT', TENANT.CAPACITY, 'Italy', 'Europe/Rome', '🇮🇹');
  const CAPACITY_JP = Scope.create('JP', 'CAPACITY_JP', TENANT.CAPACITY, 'Japan', 'Asia/Tokyo', '🇯🇵');
  const CAPACITY_MX = Scope.create('MX', 'CAPACITY_MX', TENANT.CAPACITY, 'Mexico', 'America/Mexico_City', '🇲🇽');
  const CAPACITY_NG = Scope.create('NG', 'CAPACITY_NG', TENANT.CAPACITY, 'Nigeria', 'Africa/Lagos', '🇳🇬');
  const CAPACITY_NL = Scope.create('NL', 'CAPACITY_NL', TENANT.CAPACITY, 'Netherlands', 'Europe/Amsterdam', '🇳🇱');
  const CAPACITY_PL = Scope.create('PL', 'CAPACITY_PL', TENANT.CAPACITY, 'Poland', 'Europe/Warsaw', '🇵🇱');
  const CAPACITY_SA = Scope.create('SA', 'CAPACITY_SA', TENANT.CAPACITY, 'Kingdom of Saudi Arabia', 'Asia/Riyadh', '🇸🇦');
  const CAPACITY_SE = Scope.create('SE', 'CAPACITY_SE', TENANT.CAPACITY, 'Sweden', 'Europe/Paris', '🇸🇪');
  const CAPACITY_SG = Scope.create('SG', 'CAPACITY_SG', TENANT.CAPACITY, 'Singapore', 'Asia/Singapore', '🇸🇬');
  const CAPACITY_TR = Scope.create('TR', 'CAPACITY_TR', TENANT.CAPACITY, 'Turkey', 'Europe/Istanbul', '🇹🇷');
  const CAPACITY_US = Scope.create('US', 'CAPACITY_US', TENANT.CAPACITY, 'United States of America', 'America/Los_Angeles', '🇺🇸');
  const CAPACITY_ZA = Scope.create('ZA', 'CAPACITY_ZA', TENANT.CAPACITY, 'South Africa', 'Africa/Johannesburg', '🇿🇦');
  // Capacity - Composite Scopes
  const CAPACITY_EU = Scope.create('EU', 'CAPACITY_EU', TENANT.CAPACITY, 'Europe', 'Europe/Berlin', '🇪🇺', [CAPACITY_DE, CAPACITY_ES, CAPACITY_FR, CAPACITY_IT, CAPACITY_NL, CAPACITY_PL, CAPACITY_SE]);
  // Pharmacy - Regular Scopes
  const PHARMACY_US = Scope.create('PHARMACY_US', 'PHARMACY_US', TENANT.PHARMACY, 'United States of America', 'America/Los_Angeles', '🇺🇸');
  // SSD - Regular Scopes
  const SSD_US = Scope.create('SSD_US', 'SSD_US', TENANT.SSD, 'United States of America', 'America/Los_Angeles', '🇺🇸');
  // Sort Center US - Regular Scopes
  const SORT_CENTER_US = Scope.create('SORT_CENTER_US', 'SORT_CENTER_US', TENANT.SORT_CENTER, 'United States of America', 'America/Los_Angeles', '🇺🇸');
  // Sort Center CA- Regular Scopes
  const SORT_CENTER_CA = Scope.create('SORT_CENTER_CA', 'SORT_CENTER_CA', TENANT.SORT_CENTER, 'Canada', 'America/Vancouver', '🇨🇦');

  const SCOPES = Object.freeze([
    EU,
    NA,
    AE,
    AU,
    BR,
    CL,
    CO,
    EG,
    GB,
    IN,
    JP,
    NG,
    SA,
    SG,
    TR,
    ZA,
    CAPACITY_EU,
    CAPACITY_AE,
    CAPACITY_AU,
    CAPACITY_BR,
    CAPACITY_CA,
    CAPACITY_CL,
    CAPACITY_CO,
    CAPACITY_EG,
    CAPACITY_GB,
    CAPACITY_IN,
    CAPACITY_JP,
    CAPACITY_MX,
    CAPACITY_NG,
    CAPACITY_SA,
    CAPACITY_SG,
    CAPACITY_TR,
    CAPACITY_US,
    CAPACITY_ZA,
    PHARMACY_US,
    SSD_US,
    SORT_CENTER_CA,
    SORT_CENTER_US
  ]);

  let currentScope;

  class Scopes {
    /**
     * Get the list of scopes in the current environment.
     */
    static all (includeSubscopes = false) {
      return includeSubscopes ? SCOPES.map((scope) => scope.isComposite() ? [...scope.subscopes, scope] : scope, []).flat() : SCOPES;
    }

    /**
     * Get the list of scopes in the current environment by @tenant.
     */
    static allByTenant (tenant, includeSubscopes = false) {
      return this.all(includeSubscopes).filter((scope) => scope.isOfTenant(tenant));
    }

    /**
     * Get the current scope object if called with a nil @scopeCode.
     * Set the current scope object and return it if called with a @scopeCode.
     */
    static current (scopeCode) {
      // Load from: self, session storage, local storage, and finally default to US.
      const currentScopeCode = currentScope ||
        Configuration.storage.session.get(STORAGE_SCOPE_PREFERENCE_KEY) ||
        Configuration.storage.local.get(STORAGE_SCOPE_PREFERENCE_KEY);
      currentScope = this.find(currentScopeCode);
      if (_.isNil(currentScope)) {
        currentScope = ANTARCTICA;
      }

      if (!_.isNil(scopeCode)) {
        const newScope = this.find(scopeCode);
        // Antarctica cannot be programmatically set or written to storage.
        if (!_.isNil(newScope) && !newScope.is(ANTARCTICA)) {
          currentScope = newScope;
          Configuration.storage.local.set(STORAGE_SCOPE_PREFERENCE_KEY, newScope.portalCode);
          Configuration.storage.session.set(STORAGE_SCOPE_PREFERENCE_KEY, newScope.portalCode);
          moment.tz.setDefault(currentScope.timeZone);
        }
      }

      return currentScope;
    }

    /**
     * Get the scope object identified by @scopeCode.
     */
    static find (scopeCode) {
      if (scopeCode instanceof Scope) {
        return scopeCode;
      }
      if (!_.isString(scopeCode)) {
        return;
      }
      return this.all(true).find((scope) => scope.is(scopeCode));
    }

    static isInAntarctica () {
      return this.current().is(ANTARCTICA);
    }

    /**
     * Reset state and known storage values.
     */
    static reset () {
      currentScope = undefined;
      Configuration.storage.local.remove(STORAGE_SCOPE_PREFERENCE_KEY);
      Configuration.storage.session.remove(STORAGE_SCOPE_PREFERENCE_KEY);
    }

    /**
     * Get unique tenants from scope codes.
     */
    static uniqueTenantsFromScopes (scopeCodes) {
      if (!Array.isArray(scopeCodes) || _.isEmpty(scopeCodes) || !_.every(scopeCodes, _.isString)) {
        return [];
      }
      const uniqueTenantScopes = _.uniqBy(_.map(scopeCodes, (scopeCode) => this.find(scopeCode)), 'tenant').filter((scope) => !_.isNil(scope));
      return _.sortBy(_.map(uniqueTenantScopes, 'tenant'), ['displayRank']);
    }
  }

  Configuration
    .defineProperty('scopes', Scopes)
    .defineProperty('tenants', Tenants);
})();
