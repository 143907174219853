/* globals Configuration */
(function () {
  'use strict';

  let localStorage;
  let sessionStorage;

  class Storage {
    constructor (storage) {
      this.storage = storage;
    }

    clear () {
      this.storage.clear();
    }

    get (key) {
      return angular.fromJson(this.storage.getItem(key));
    }

    has (key) {
      return !_.isNil(this.get(key));
    }

    remove (key) {
      this.storage.removeItem(key);
    }

    set (key, value) {
      if (_.isNil(value)) {
        this.remove(key);
      } else {
        this.storage.setItem(key, angular.toJson(value));
      }
      return value;
    }
  }

  class InMemoryStorage extends Storage {
    constructor () {
      super({});
    }

    clear () {
      this.storage = {};
    }

    get (key) {
      return angular.fromJson(this.storage[key]);
    }

    remove (key) {
      delete this.storage[key];
    }

    set (key, value) {
      if (_.isNil(value)) {
        this.remove(key);
      } else {
        this.storage[key] = angular.toJson(value);
      }
      return value;
    }
  }

  class ApplicationStorage {
    constructor () {
      if (window.UnitTest) {
        // The unit test environment always uses in-memory storage as this circumvents using the HTML5 storage standards
        localStorage = new InMemoryStorage();
        sessionStorage = new InMemoryStorage();
      } else {
        localStorage = new Storage(window.localStorage);
        sessionStorage = new Storage(window.sessionStorage);
      }
    }

    get default () {
      return sessionStorage;
    }

    get local () {
      return localStorage;
    }

    get session () {
      return sessionStorage;
    }

    clear () {
      this.default.clear();
    }

    get (key) {
      return this.default.get(key);
    }

    has (key) {
      return this.default.has(key);
    }

    remove (key) {
      this.default.remove(key);
    }

    set (key, value) {
      this.default.set(key, value);
    }
  }

  Configuration.defineProperty('storage', new ApplicationStorage());
})();
