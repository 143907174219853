/* globals CryptoJS, DateUtils, URL */
// @credit https://github.com/danieljoos/aws-sign-web
(function () {
  'use strict';

  /**
   * AWS Signature V4 Implementation
   */
  const AWS_ACCESS_KEY = 'accessKeyId';
  const AWS_API_KEY = 'apiKey';
  const AWS_SECRET_KEY = 'secretAccessKey';
  const AWS_SESSION_TOKEN = 'sessionToken';
  const AWS_REGION = 'region';
  const DEFAULT_CONFIG = Object.freeze({
    defaultAcceptType: 'application/json',
    defaultContentType: 'application/json',
    payloadSerializer: (data) => JSON.stringify(data),
    service: 'execute-api'
  });

  // This rather dense function is parsing the query paramters in the search part of the URL, if any.
  //   1. matches a string starting with '?'
  //   2. drops the '?' and splits the remainder on '&'
  //   3. returns a hash of all args that are of the form (key)=(value)
  // Note that repeated args will overwrite any previous value in the hash.
  const extractQueryParams = (search) => /^\??(.*)$/.exec(search)[1].split('&').reduce(
    (result, arg) => {
      arg = /^(.+)=(.*)$/.exec(arg);
      if (arg) {
        result[arg[1]] = arg[2];
      }
      return result;
    },
    {}
  );

  /**
   * Parse the given URI.
   * @param {string} uri The URI to parse.
   * @returns JavaScript object with the parse results:
   *   protocol: The URI protocol part.
   *   host: Host part of the URI.
   *   path: Path part of the URI.
   *   queryParams: Query parameters as JavaScript object.
   */
  const parseUri = (uri) => {
    const parser = new URL(uri);
    return {
      host: parser.host.replace(/^(.*):((80)|(443))$/, '$1'),
      path: parser.pathname,
      protocol: parser.protocol,
      queryParams: extractQueryParams(parser.search)
    };
  };

  /**
   * Hash the given input using SHA-256 algorithm.
   * The options can be used to control the in-/output of the hash operation.
   * @param {*} input Input data.
   * @param {object} options Options object:
   *   hexOutput: Output the hash with hex encoding (default: `true`).
   *   textInput: Interpret the input data as text (default: `true`).
   * @returns The generated hash
   */
  const hash = (input, options) => {
    options = _.merge({ hexOutput: true, textInput: true }, options);
    const hash = CryptoJS.SHA256(input); // eslint-disable-line new-cap
    return options.hexOutput ? hash.toString(CryptoJS.enc.Hex) : hash;
  };

  /**
   * Create the HMAC of the given input data with the given key using the SHA-256
   * hash algorithm.
   * The options can be used to control the in-/output of the hash operation.
   * @param {string} key Secret key.
   * @param {*} input Input data.
   * @param {object} options Options object:
   *   hexOutput: Output the hash with hex encoding (default: `true`).
   *   textInput: Interpret the input data as text (default: `true`).
   * @returns The generated HMAC.
   */
  const hmac = (key, input, options) => {
    options = _.merge({ hexOutput: true, textInput: true }, options);
    const hmac = CryptoJS.HmacSHA256(input, key, { asBytes: true }); // eslint-disable-line new-cap
    return options.hexOutput ? hmac.toString(CryptoJS.enc.Hex) : hmac;
  };

  /**
   * Create the AWS compliant set of date strings required for signing.
   * See: http://docs.aws.amazon.com/general/latest/gr/sigv4-date-handling.html
   * @param {Date} the date to use for signing.
   * @returns The generated set (long, short) of date strings.
   */
  const amzDate = (dateObj) => {
    const utc = DateUtils.utc(dateObj);
    return {
      long: utc.format('YYYYMMDD[T]HHmmss[Z]'),
      short: utc.format('YYYYMMDD')
    };
  };

  // Pepare headers and payload
  const prepare = (self, ws) => {
    const headers = {
      accept: self.config.defaultAcceptType,
      'content-type': self.config.defaultContentType,
      host: ws.uri.host,
      'x-amz-date': ws.amzDate.long
    };
    // Optional API Key
    if (_.has(self.config, AWS_API_KEY)) {
      headers['x-api-key'] = self.config[AWS_API_KEY];
    }
    // Optional Payload
    ws.request.method = ws.request.method.toUpperCase();
    if (ws.request.body) {
      ws.payload = ws.request.body;
    } else if (ws.request.data && _.isFunction(self.config.payloadSerializer)) {
      ws.payload = self.config.payloadSerializer(ws.request.data);
    } else {
      delete headers['content-type'];
    }
    // Headers
    ws.request.headers = _.merge(
      headers,
      Object.keys(ws.request.headers || {}).reduce((normalized, key) => {
        normalized[key.toLowerCase()] = ws.request.headers[key];
        return normalized;
      }, {})
    );
    ws.sortedHeaderKeys = Object.keys(ws.request.headers).sort();
    // Remove content-type parameters as some browsers might change them on send
    if (ws.request.headers['content-type']) {
      ws.request.headers['content-type'] = ws.request.headers['content-type'].split(';')[0];
    }
    // Merge params to query params
    if (typeof ws.request.params === 'object') {
      _.merge(ws.uri.queryParams, ws.request.params);
    }
  };

  // Convert the request to a canonical format.
  const buildCanonicalRequest = (self, ws) => {
    /* eslint-disable prefer-template */
    ws.signedHeaders = ws.sortedHeaderKeys.map((key) => key.toLowerCase()).join(';');
    ws.canonicalRequest = String(ws.request.method).toUpperCase() + '\n' +
      // Canonical URI:
      encodeURI(ws.uri.path) + '\n' +
      // Canonical Query String:
      Object.keys(ws.uri.queryParams).sort().map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(ws.uri.queryParams[key])).join('&') + '\n' +
      // Canonical Headers:
      ws.sortedHeaderKeys.map((key) => key.toLocaleLowerCase() + ':' + ws.request.headers[key]).join('\n') + '\n\n' +
      // Signed Headers:
      ws.signedHeaders + '\n' +
      // Hashed Payload
      hash(ws.payload ? ws.payload : '');
    /* eslint-enable prefer-template */
  };

  // Construct the string that will be signed.
  const buildStringToSign = (self, ws) => {
    ws.credentialScope = [ws.amzDate.short, self.config[AWS_REGION], self.config.service, 'aws4_request'].join('/');
    ws.stringToSign = `AWS4-HMAC-SHA256\n${ws.amzDate.long}\n${ws.credentialScope}\n${hash(ws.canonicalRequest)}`;
  };

  // Calculate the signature
  const calculateSignature = (self, ws) => {
    const signKey = hmac(
      hmac(
        hmac(
          hmac(`AWS4${self.config[AWS_SECRET_KEY]}`, ws.amzDate.short, { hexOutput: false }),
          self.config[AWS_REGION],
          { hexOutput: false, textInput: false }
        ),
        self.config.service,
        { hexOutput: false, textInput: false }
      ),
      'aws4_request',
      { hexOutput: false, textInput: false }
    );
    ws.signature = hmac(signKey, ws.stringToSign, { textInput: false });
  };

  // Build the signature HTTP header using the data in the working set.
  const buildSignatureHeader = (self, ws) =>
    ws.authorization = `AWS4-HMAC-SHA256 Credential=${self.config[AWS_ACCESS_KEY]}/${ws.credentialScope}, SignedHeaders=${ws.signedHeaders}, Signature=${ws.signature}`;

  class AwsSignatureV4Service {
    /**
    * Checks whether the config object contains the required AWS credential properties and that those properties are non-empty strings.
    * @param {object} config The configuration object.
    * @returns True if the config object contains all expected properties. False otherwise.
    */
    hasAwsCredentials (config) {
      return _.every([AWS_ACCESS_KEY, AWS_SECRET_KEY, AWS_SESSION_TOKEN, AWS_REGION], (property) =>
        _.has(config, property) && _.isString(config[property]) && !_.isEmpty(config[property]));
    }

    /**
    * Create signature headers for the given request.
    * Configuration must specify the AWS credentials used for the signing operation:
    *   awsaccessKeyId: The AWS IAM access key ID.
    *   awssecretKey: The AWS IAM secret key.
    *   sessionToken: The AWS IAM session token, required for temporary credentials (always used by Portal service calls).
    * Request must be in the format (same as $http service):
    * ```
    * request = {
    *   headers: { ... },
    *   method: 'GET',
    *   url: 'http://...',
    *   params: { ... },
    *   data: ...
    * };
    * ```
    * @param {object} config The configuration object.
    * @param {object} request The request to create the signature for. Is not modified.
    * @param {Date} optional date to use for signing.
    * @returns Signed request headers.
    */
    sign (config, request, signDate) {
      if (!this.hasAwsCredentials(config)) {
        throw new Error('AWS Signature V4 requires AccessKeyID, SecretKey, SessionToken, and Region');
      }
      const instance = {
        config: _.merge({}, DEFAULT_CONFIG, config)
      };
      const workingSet = {
        amzDate: amzDate(signDate || new Date()),
        request: _.merge({}, request),
        uri: parseUri(request.url)
      };
      prepare(instance, workingSet);
      buildCanonicalRequest(instance, workingSet);
      buildStringToSign(instance, workingSet);
      calculateSignature(instance, workingSet);
      buildSignatureHeader(instance, workingSet);
      return {
        Accept: workingSet.request.headers.accept,
        Authorization: workingSet.authorization,
        'Content-Type': workingSet.request.headers['content-type'],
        'x-amz-date': workingSet.request.headers['x-amz-date'],
        'x-amz-security-token': instance.config[AWS_SESSION_TOKEN],
        'x-api-key': instance.config[AWS_API_KEY] || undefined
      };
    }
  }

  angular.module('application.services').service('awsSignatureV4', AwsSignatureV4Service);
})();
