(function () {
  'use strict';

  class Validate {
    /* Finds the list of propeties that do not exist on an object
     *
     * @param object the object whose properties will be checked.
     * @param properties the array of properties that should exist on the object.
     *
     * @return an array of missing properties
     */
    static checkMissingProperties (object, properties) {
      if (_.isNil(object) || _.isNil(properties)) {
        throw new Error('Validate: object and properties must not be nil');
      }
      return _.filter(properties, (property) => !_.has(object, property));
    }

    /*
     * Determines if a value is non-numerical (non-finite or non-nil).
     *
     * @param value the value to validate.
     *
     * @return true if the value is non-numerical, false otherwise
     */
    static checkNonNumerical (value) {
      return !(_.isFinite(value) || _.isNil(value));
    }

    /*
     * Finds all of the non-numerical values in an array.
     *
     * @param values the array of values to validate.
     *
     * @return an array of non-Numbers
     */
    static checkNonNumericals (values) {
      if (!Array.isArray(values)) {
        return [];
      }
      return _.filter(values, this.checkNonNumerical);
    }

    /*
     * Determines if the property of object is a function.
     *
     * @param object the object to evaluate
     * @param property the property of object to evaluate
     *
     * @return true if the property of object is a function, false otherwise
     */
    static hasFunctionValue (object, property) {
      return _.isFunction(_.get(object, property));
    }

    /*
     * Determines if the property of object is a non-blank string.
     *
     * @param object the object to evaluate
     * @param property the property of object to evaluate
     *
     * @return true if the property of object is a non-blank string, false otherwise
     */
    static hasNonBlankStringValue (object, property) {
      return _.has(object, property) && _.isString(object[property]) && !this.isBlank(object[property]);
    }

    /*
     * Determines if the value is blank (nil, empty string, or string only containing whitespace).
     *
     * @param value the value to evaluate
     *
     * @return true if the value is blank, false otherwise
     */
    static isBlank (value) {
      return _.isNil(value) || (_.isString(value) && _.isEmpty(value.trim()));
    }
  }

  window.Validate = Object.freeze(Validate);
})();
